import { Injectable } from '@angular/core';

@Injectable()
export class DomUtilService {

  constructor() { }

  outerWidthWithMargin(el): number {
    if (el) {
      let width = el.offsetWidth;
      const style = getComputedStyle(el);

      if (!el.classList.contains('hidden')) {
        width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
      }
      return width;
    } else {
      return 0;
    }
  }
}
