import { Injectable, OnInit } from '@angular/core';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { UserRestService } from '../rest-services/user-rest.service';
import { UserUtilService } from '../services/user/user-util.service';
import { ServiceMetricsRestService } from '../rest-services/service-metrics-rest.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AssignedCustomer } from '../models/assigned-customer';
import { User } from '../models/user';
import { roles } from '../core-constants.service';
import * as _ from 'lodash';
import { Equipment } from '../models/equipment/equipment';
import { EquipmentRestService } from '../rest-services/equipment-rest.service';
import { StateService } from '../services/state.service';
import { ImpersonationCacheService } from '../services/cache/impersonation-cache.service';

export const DASHBOARD = '/dashboard';

@Injectable({
  providedIn: 'root'
})
export class CheckPermissionOrRoleService implements OnInit {

  userCountry = new ReplaySubject<string>();
  rolesToCheck: any;

  isLoadedSubject = new BehaviorSubject<boolean>(false);
  sprComponentPermissionTab = new ReplaySubject<boolean>(1);

  numberOfTabs = 0;
  reportingLink: string;
  psrLink: string;

  showDashboardTab = new ReplaySubject<boolean>(1);
  showEquipmentTab = new ReplaySubject<boolean>(1);
  showOpenTicketsTab = new ReplaySubject<boolean>(1);
  showPlannedActivityTab = new ReplaySubject<boolean>(1);
  showPSRTab = new ReplaySubject<boolean>(1);
  showPSRandRWF = new ReplaySubject<boolean>(1);
  showContractsTab = new ReplaySubject<boolean>(1);
  showCustAdminTab = new ReplaySubject<boolean>(1);
  showImpersonationTab = new ReplaySubject<boolean>(1);
  showOrderTab = new ReplaySubject<boolean>(1);
  showPartnerOrderTab = new ReplaySubject<boolean>(1);
  showReportingLink: boolean;
  showInvoicesTab = new ReplaySubject<boolean>(1);
  showSystemUpdatesTab = new ReplaySubject<boolean>(1);
  showSecurityTab = new ReplaySubject<boolean>(1);
  showAdvisoriesTab = new ReplaySubject<boolean>(1);
  isLoaded: boolean;
  createTicketRole = new ReplaySubject<boolean>(1);
  headerTranslateLabel: string;
  currentStateName: string;
  equipmentDocumentOverviewComponent = new ReplaySubject<boolean>();
  showTeamplayWidget = new ReplaySubject<boolean>();

  protected readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private configService: CountryConfigRestService,
    private userRestService: UserRestService,
    private userUtilService: UserUtilService,
    private metricsService: ServiceMetricsRestService,
    private equipmentRestService: EquipmentRestService,
    private impersonationCacheService: ImpersonationCacheService,
    private stateService: StateService) {
  }

  ngOnInit(): void {
    this.initProperties();
  }

  loadingPermission() {
    const config$ = this.configService.getConfig();
    const assignedCustomers$ = this.userRestService.getAssignedCustomers();
    const equipments$ = this.equipmentRestService.getEquipment();
    const user$ = this.userRestService.getUser();

    combineLatest([config$, assignedCustomers$, equipments$, user$]).pipe(
      tap((responses) => {
        this.userCountry.next(responses[3].country);
      }),
      switchMap(([config, assignedCustomers, equipments, user]) => {
        return this.checkUserRoles(config, assignedCustomers, equipments, user);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  /**
   * @description
   * Check the user roles.
   */
  checkUserRoles(config, assignedCustomers: AssignedCustomer[], equipments: Equipment[], user: User): Observable<any> {
    return this.userUtilService.checkUserRoles(roles).pipe(
      tap((checkRolesResponse) => {
        let numberOfTabs = 0;

        const rolesSet = Object.keys(checkRolesResponse).filter(key => checkRolesResponse[key]);
        if (rolesSet.length === 2 && rolesSet.includes('userRole') && rolesSet.includes('securityAdvisoryViewXiaRole')) {
          this.showDashboardTab.next(false);
        } else {
          this.showDashboardTab.next(true);
          numberOfTabs += 1;
        }

        numberOfTabs = this.checkEquipmentsRole(checkRolesResponse, numberOfTabs);
        numberOfTabs = this.checkTicketsRole(checkRolesResponse, numberOfTabs);
        numberOfTabs = this.checkActivitiesRole(checkRolesResponse, numberOfTabs);

        /**
         * Check if any equipment in the equipment list belongs to MODALITY_PSR_ALLOWED provided in
         * config file.
         */
        const allowedModalities = config['MODALITY_PSR_ALLOWED'].split(',');
        if (equipments.length > 0 && !_.isEmpty(allowedModalities)) {
          let isAllowedByModality = false;
          _.forEach(equipments, (equipment: Equipment) => {
            if (_.includes(allowedModalities, equipment.modality)) {
              isAllowedByModality = true;
              return false;
            }
          });

          if (this.hasPermission(config.PSR_FEATURE_AVAILABLE) && isAllowedByModality) {
            this.showPSRTab.next(true);
            numberOfTabs += 1;
            this.psrLink = '/psr';
          } else {
            this.showPSRTab.next(false);
          }

          if (this.hasPermission(config.FEATURE_TOGGLE_REMOTE_WORK_FORCE)) {
            this.equipmentRestService.getRemoteWorkForceEquipmentWhiteListExists().subscribe(
              whiteListExists => {
                if (whiteListExists) {
                  if (this.psrLink) {
                    this.showPSRandRWF.next(true);
                  } else {
                    this.showPSRTab.next(true);
                    numberOfTabs += 1;
                    this.psrLink = '/psr/rwf';
                  }
                }
              });
          }
        }

        numberOfTabs = this.checkOrderRole(config, user, numberOfTabs);
        numberOfTabs = this.checkInvoicesRole(checkRolesResponse, config, numberOfTabs);
        numberOfTabs = this.checkSystemUpdatesRole(checkRolesResponse, numberOfTabs);
        numberOfTabs = this.checkSecurityRole(checkRolesResponse, config, numberOfTabs);
        numberOfTabs = this.checkAdvisoriesRole(checkRolesResponse, config, numberOfTabs);
        numberOfTabs = this.checkImpersonationRole(checkRolesResponse, numberOfTabs);
        numberOfTabs = this.checkAdministrationRole(checkRolesResponse, numberOfTabs);
        numberOfTabs = this.checkContractRole(checkRolesResponse, config, numberOfTabs, assignedCustomers);
        numberOfTabs = this.checkPartnerOrdersRole(checkRolesResponse, assignedCustomers, numberOfTabs);


        this.sprComponentPermissionTab.next(this.hasPermission(config.TOGGLE_SPR_WIDGET_CONFIGURATION));
        this.equipmentDocumentOverviewComponent.next(this.hasPermission(config.TOGGLE_EQUIPMENT_DOCUMENT_OVERVIEW));
        this.showTeamplayWidget.next(this.hasPermission(config.FEATURE_TOGGLE_DISPLAY_DASHBOARD_WIDGET_TP));
        this.numberOfTabs = numberOfTabs;
        this.isLoaded = true;
        this.isLoadedSubject.next(true);
      })
    );
  }

  /**
   * The tab CONTRACT_TITLE shall be visible if the user has the
   * role ER_CONTRACT_VIEWCONTRACT and
   * if (the country has a 1:N contract model (CONTRACT_MODEL_ONE_MANY = true)
   * OR the customer is a LD customer).
   */
  private checkContractRole(checkRolesResponse: any, config: any, numberOfTabs: number, assignedCustomers: AssignedCustomer[]) {

    if (checkRolesResponse.viewContractRole && (this.hasPermission(config.CONTRACT_MODEL_ONE_MANY) ||
      assignedCustomers.some(customer => customer.isLD))) {
      this.showContractsTab.next(true);
      numberOfTabs += 1;
    } else {
      this.showContractsTab.next(false);
    }
    return numberOfTabs;
  }

  private checkOrderRole(config, user: User, numberOfTabs: number): number {
    if (_.startsWith(user.gid, 'Z') && this.hasPermission(config.ORDER2ORDER_FEATURE_AVAILABLE)) {
      this.showOrderTab.next(true);
      numberOfTabs += 1;
    } else {
      this.showOrderTab.next(false);
    }
    return numberOfTabs;
  }

  private checkAdvisoriesRole(checkRolesResponse, config, numberOfTabs: number): number {
    if (this.hasPermission(config.SECURITY_ADVISORY_PAGE_FEATURE_TOGGLE)) {
      if (!this.hasPermission(config.FEATURE_TOGGLE_ADVISORY_SUBSCRIPTION) &&
        !this.hasPermission(config.FEATURE_TOGGLE_XIA_VIEW_ADVISORIES)) {
        this.showAdvisoriesTab.next(true);
        numberOfTabs += 1;
      } else {
        if (checkRolesResponse.securityAdvisoryViewRole || checkRolesResponse.securityAdvisoryViewXiaRole) {
          this.showAdvisoriesTab.next(true);
          numberOfTabs += 1;
        }
      }
    } else {
      this.showAdvisoriesTab.next(false);
    }
    return numberOfTabs;
  }

  private checkAdministrationRole(checkRolesResponse, numberOfTabs: number): number {
    if (checkRolesResponse.countryAdminRole || checkRolesResponse.masterAdminRole
      || checkRolesResponse.customerAdminRole) {
      this.showCustAdminTab.next(true);
      numberOfTabs += 1;
    } else {
      this.showCustAdminTab.next(false);
    }
    return numberOfTabs;
  }

  private checkPartnerOrdersRole(checkRolesResponse: any, assignedCustomers: AssignedCustomer[], numberOfTabs: number): number {
    if (checkRolesResponse.partnerOrdersRole && assignedCustomers.some(customer => (customer.typeId === 2) || (customer.typeId === 3))) {
      this.showPartnerOrderTab.next(true);
      numberOfTabs += 1;
    } else {
      this.showPartnerOrderTab.next(false);
    }
    return numberOfTabs;
  }

  private checkInvoicesRole(checkRolesResponse, config, numberOfTabs: number): number {
    if (checkRolesResponse.viewInvoicesRole && this.hasPermission(config.INVOICES_AVAILABLE)) {
      this.showInvoicesTab.next(true);
      numberOfTabs += 1;
    } else {
      this.showInvoicesTab.next(false);
    }
    return numberOfTabs;
  }

  private checkSecurityRole(checkRolesResponse, config, numberOfTabs): number {
    if (checkRolesResponse.cybersecurityDashboardRole && this.hasPermission(config.SHOW_SECURITY_INFO_TAB)) {
      this.showSecurityTab.next(true);
      numberOfTabs += 1;
    } else {
      this.showSecurityTab.next(false);
    }
    return numberOfTabs;
  }

  private checkEquipmentsRole(checkRolesResponse, numberOfTabs: number) {
    if (checkRolesResponse.viewEquipmentRole) {
      this.showEquipmentTab.next(true);
      numberOfTabs += 1;
    } else {
      this.showEquipmentTab.next(false);
    }
    return numberOfTabs;
  }

  private checkTicketsRole(checkRolesResponse, numberOfTabs: number): number {
    if (checkRolesResponse.viewTicketRole) {
      this.showOpenTicketsTab.next(true);
      numberOfTabs += 1;
    } else {
      this.showOpenTicketsTab.next(false);
    }
    return numberOfTabs;
  }

  private checkSystemUpdatesRole(checkRolesResponse, numberOfTabs: number): number {
    if (checkRolesResponse.itAdminRole) {
      this.showSystemUpdatesTab.next(true);
      numberOfTabs += 1;
    } else {
      this.showSystemUpdatesTab.next(false);
    }
    return numberOfTabs;
  }

  private checkImpersonationRole(checkRolesResponse, numberOfTabs: number): number {
    if (checkRolesResponse.impersonateUserRole) {
      this.showImpersonationTab.next(true);
      numberOfTabs += 1;
      this.preLoadCustomerImpersonationTabData();
    } else {
      this.showImpersonationTab.next(false);
    }
    return numberOfTabs;
  }

  private preLoadCustomerImpersonationTabData() {
    this.userCountry.pipe(
      mergeMap((userCountry: string) => {
        return forkJoin([this.impersonationCacheService.getGroupListByCountry(userCountry),
          this.impersonationCacheService.getCustomerListByCountry(userCountry)])
      })
    ).subscribe();
  }

  private initProperties() {

    this.reportingLink = '';
    // initially set to 1 due to dashboard tab
    this.numberOfTabs = 0;
    this.showReportingLink = false;
    this.isLoaded = false;

    // default set to dashboard
    this.headerTranslateLabel = 'MY_DASHBOARD';
    // please check description of this method
    this.currentStateName = this.stateService.getStateNameFromWindowLocation();
  }

  hasPermission(value: any) {
    return _.isEqual(value, 'true');
  }

  private checkActivitiesRole(checkRolesResponse: any, numberOfTabs: number): number {
    if (checkRolesResponse.viewPlannedActivityRole || checkRolesResponse.viewPlannedTrainingRole) {
      this.showPlannedActivityTab.next(true);
      numberOfTabs += 1;
    } else {
      this.showPlannedActivityTab.next(false);
    }
    return numberOfTabs;
  }
}
