import { Toast } from '../../models/toast';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ToasterService {
  // Observable toast sources
  private toastSource = new Subject<Toast>();

  // Observable toast streams
  toastSource$ = this.toastSource.asObservable();

  constructor() {
  }

  // Service toast commands
  emitToast(toast: Toast) {
    this.toastSource.next(toast);
  }


  /**
   * convenience method
   * @param isBodyTranslationKey
   * @param body
   */
  showSuccessToaster(isBodyTranslationKey, body) {
    const toast = {
      type: 'success',
      isBodyTranslationKey: isBodyTranslationKey,
      body: body
    };

    // show a success toaster message
    this.emitToast(toast);
  }
}
