import { Component, OnInit } from '@angular/core';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { UserUtilService } from '../../../core/services/user/user-util.service';

@Component({
  selector: 'hl-cookie-policy',
  templateUrl: './cookie-policy.component.html'
})
export class CookiePolicyComponent implements OnInit {

  linkCookiePolicy = '';
  showCookies = false;

  constructor(private configService: CountryConfigRestService,
    private userUtilService: UserUtilService,
    private userRestService: UserRestService) { }

  ngOnInit() {
    this.init();
  }

  init() {
    const config$ = this.configService.getConfig();
    const user$ = this.userUtilService.getUser();

    combineLatest([config$, user$]).subscribe((responses) => {

      const config = responses[0];
      this.linkCookiePolicy = config.LINK_COOKIE_POLICY;

      // get user properties
      const user = responses[1];
      let render = false;

      // only set render to true if element should be visible (config)
      if (_.isEqual(config.SHOW_COOKIE_POLICY, 'true')) {
        render = true;
      }

      // don't render if cookies already accepted
      if (render && user.cookiesAccepted) {
        render = false;
      }

      // show element if conditions are met
      if (render) {
        this.showCookies = true;
      }
    });
  }

  ok() {
    this.userRestService.postCookieAccepted()
      .subscribe(() => {
        this.showCookies = false;
      });
  }
}
