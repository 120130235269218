import { TicketActionsComponent } from '../../../shared/components/ticket-actions/ticket-actions.component';
import { TicketViewModel } from '../../../core/view-models/ticket-view-model';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { Ticket } from '../../../core/models/tickets/ticket';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { TicketsConstantsService } from '../../../core/services/tickets/tickets-constants.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { BaseEquipmentDetailListView } from '../../../core/base-class/base-equipment-detail-list-view';
import { DatePipeWrapperPipe } from '../../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { SelectOption } from '../../../core/models/select-option';
import { OverlayComponent } from '../../../shared/components/overlay/overlay.component';
import { StateService } from '../../../core/services/state.service';
import { BrowserStateService } from '../../../core/services/browser-state.service';
import { Subscription } from 'rxjs';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { SortDirection } from '../../../shared/sorting/sort-object';
import { LogisticMilestonesUtilService } from '../../../core/services/logistic-milestones/logistic-milestones-util.service';
import { map, takeUntil } from 'rxjs/operators';
import { RefreshItemEventService } from '../../../core/component-communication-services/refresh-item-event/refresh-item-event.service';

@Component({
  selector: 'hl-equipment-ticket-history',
  templateUrl: './equipment-ticket-history.component.html'
})
export class EquipmentTicketHistoryComponent
  extends BaseEquipmentDetailListView<TicketViewModel>
  implements OnInit, OnDestroy {
  static readonly COMPONENTS_INVOKING_UPDATE_TICKET_LIST_COUNT = 3;
  datePatternForGenericBackEndRequest = 'DD.MM.YYYY';

  @ViewChild('actions', {static: false})
  actions: TicketActionsComponent;
  @ViewChild('detailOverlay', {static: false})
  detailOverlay: OverlayComponent;

  @ViewChild('overviewTab', {static: false})
  overviewTab: ElementRef;
  @ViewChild('historyTab', {static: false})
  historyTab: ElementRef;
  @ViewChild('deliveryTab', {static: false})
  deliveryTab: ElementRef;

  showDangerForPatients: boolean;

  // Skeleton for selected multiSelect dropdown
  selectedDropDownList = {
    typeID: [],
    problemSeverityDescription: []
  };

  dangerForPatient = [];
  dangerForPatientOptions = [];
  ticketTypes = [];
  operationalStateDescriptions: SelectOption[];
  selectedProblemSeverityID: string[] = [];
  selectedStatus = [];
  statusOptions = [];
  openNotificationsIdentifiers = [];
  closedNotificationsIdentifiers = [];

  rollingMonthsFromFilterTickets = 1;
  initialFromDateSet = false;
  initialToDateSet = false;

  srrVivo = 'false';
  srrVitro = 'false';

  // item for ticket actions (update, close, handover)
  ticketItemForActions: TicketViewModel;

  operationalStateFilterOptions: string[];
  showFilters = false;

  currentStateName: string;

  ticketStatusYellow: string[];
  ticketStatusRed: string[];

  updateTicketListInvokedCount = 0;

  loadTicketsSubscription: Subscription = null;
  routeQuerySubscription: Subscription;

  deliveryMilestonesAvailable = false;
  isDeliveryMilestonesAllowedForCountry = false;
  isSelectedItemOpen = false;
  refreshButtonRender = false;

  static getState(currentStateName: string) {
    if (currentStateName.endsWith('-logs')) {
      return 'logs';
    } else if (currentStateName.endsWith('-delivery')) {
      return 'delivery';
    } else {
      return 'overview';
    }
  }

  constructor(
    private ticketsConstantsService: TicketsConstantsService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private ticketsUtilService: TicketsUtilService,
    private router: Router,
    private stateService: StateService,
    private browserStateService: BrowserStateService,
    private milestonesUtilService: LogisticMilestonesUtilService,
    private refreshItemEventService: RefreshItemEventService,
    public route: ActivatedRoute,
    public lifeNetUtilService: LifeNetUtilService,
    configService: CountryConfigRestService,
    equipmentUtilService: EquipmentUtilService,
    activatedRoute: ActivatedRoute,
    filterUtilService: FilterUtilService
  ) {
    super(
      filterUtilService,
      configService,
      lifeNetUtilService,
      equipmentUtilService,
      activatedRoute
    );
  }

  ngOnInit() {
    super.init();
    this.stateService.getActiveStateName().subscribe(stateName => {
      this.currentStateName = stateName;
    });
  }

  ngOnDestroy(): void {
    super.destroy();
    this.ticketsUtilService.selectedClosedTicket = null;
    this.loadTicketsSubscription.unsubscribe();

    if (this.routeQuerySubscription) {
      this.routeQuerySubscription.unsubscribe();
    }
  }

  /**
   * @description Additional properties to be initialized for derived class
   */
  afterInitProperties() {
    this.currentStateName = this.stateService.getStateNameFromWindowLocation();
    this.ticketItemForActions = null;
    if (!this.sortSkeleton) {
      this.sortSkeleton = this.ticketsConstantsService.getSortSkeletonForHistory();
    }
    this.showDangerForPatients = false;
    this.operationalStateFilterOptions = [];
  }

  /**
   * @description Additional config properties initialized in derived class
   */
  afterConfigProperties(config) {
    this.srrVivo = config.SERVICE_REPORT_RENDER_VIVO;
    this.srrVitro = config.SERVICE_REPORT_RENDER_VITRO;
    this.operationalStateFilterOptions = config.OPERATIONAL_STATE_FILTER_OPTIONS.split(',');

    this.rollingMonthsFromFilterTickets = _.parseInt(config.ROLLING_MONTHS_FROM_FILTER_TICKETS);
    this.refreshButtonRender = _.isEqual(config.NOTIFICATIONS_REFRESH_BUTTON_FEATURE_TOGGLE, 'true');

    // For initial loading set from based on rolling months
    if (!this.initialFromDateSet && !this.dateRange.fromDate) {
      const fromDate = new Date();

      // set from-datepicker to 6 months before.
      fromDate.setMonth(
        fromDate.getMonth() - this.rollingMonthsFromFilterTickets
      );

      this.dateRange.fromDate = fromDate;
      this.initialFromDateSet = true;
    }
    if (!this.initialToDateSet && !this.dateRange.toDate) {
      this.dateRange.toDate = new Date();
      this.initialToDateSet = true;
    }

    this.closedNotificationsIdentifiers = config.STATUS_IDENTIFIER_CLOSED_NOTIFICATIONS.split(',');
    this.openNotificationsIdentifiers = config.STATUS_IDENTIFIER_OPEN_NOTIFICATIONS.split(',');

    this.ticketStatusYellow = config.TICKET_STATUS_YELLOW.split(',');
    this.ticketStatusRed = config.TICKET_STATUS_RED.split(',');

    this.isDeliveryMilestonesAllowedForCountry = _.isEqual(config.TOGGLE_NOTIFICATION_LOGISTIC_MILESTONES, 'true');

    /**
     * Hide danger for patient.
     * Note:- All configuration should be handled here for danger for patients
     */
    const dangerForPatient = config.TICKET_DANGER_FOR_PATIENT_RENDER;
    if (_.isEqual(dangerForPatient, 'false')) {
      _.remove(this.sortSkeleton.items, {title: 'PATIENT_SITUATION'});
    } else {
      this.showDangerForPatients = true;
    }

    this.updateTicketListInvokedCount = 0;
    this.updateTicketList();
  }

  setQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.setSortParams(params);

      const ticketStatusParam = params['ticketStatus'];
      if (ticketStatusParam) {
        this.selectedStatus.push(ticketStatusParam);
      }

      const searchParam = params['search'];
      if (searchParam) {
        this.searchInput = searchParam;
        this.dateRange.fromDate = null;
        this.dateRange.toDate = null;
      }

      if (this.equipmentItem && !(params['ticketNumber'] && params['ticketType']) && !_.isEmpty(params)) {
        this.router.navigate(
          ['/equipment', this.equipmentItem.key, 'ticketHistory'],
          {replaceUrl: true}
        );
      }

    });
  }

  setSortParams(params: any) {
    let sortSkeletonChanged = false;
    const sortSkeleton = this.ticketsConstantsService.getSortSkeletonForHistory();

    const sortDirParam = params['sortDir'];
    if (sortDirParam) {
      if (sortDirParam === 'asc') {
        sortSkeleton.sortObject.sortDir = SortDirection.ASC;
      }
      if (sortDirParam === 'desc') {
        sortSkeleton.sortObject.sortDir = SortDirection.DESC;
      }
      sortSkeletonChanged = true;
    }

    const sortByParam = params['sortBy'];
    if (sortByParam) {
      sortSkeleton.sortObject.sortBy = sortByParam;
      sortSkeletonChanged = true;
    }

    if (sortSkeletonChanged) {
      this.sortSkeleton = sortSkeleton;
    }

  }

  /**
   *
   * @description
   * Updates the ticket list
   */
  updateTicketList() {
    if (!this.isAllowedUpdateTicketList()) {
      return;
    }
    this.isLoaded = false;
    this.viewModelList = [];
    this.rawList = [];
    this.filteredLengthWithoutPagination = 0;
    this.loadTicketsHistory();
  }

  /**
   * Controls invoking of updateTicketList().
   * Allows first call, then subsequent calls are disabled until COMPONENTS_INVOKING_UPDATE_TICKET_LIST_COUNT
   * is reached. Then it again allows further calls.
   * Purpose is to call updateTicketList() only once during ticket history initial load.
   */
  isAllowedUpdateTicketList(): boolean {
    if (this.updateTicketListInvokedCount === 0) {
      this.updateTicketListInvokedCount++;
      return true;
    } else if (this.updateTicketListInvokedCount >= 1 &&
      this.updateTicketListInvokedCount < EquipmentTicketHistoryComponent.COMPONENTS_INVOKING_UPDATE_TICKET_LIST_COUNT) {
      this.updateTicketListInvokedCount++;
      return false;
    } else {
      return true;
    }
  }

  /**
   *
   * @description
   * Get the tickets history for the equipment
   */
  loadTicketsHistory() {
    if (this.loadTicketsSubscription != null) {
      this.loadTicketsSubscription.unsubscribe();
    }

    const dateFromFormatted = this.formatDate(this.dateRange.fromDate, this.datePatternForGenericBackEndRequest, '01.01.1970');
    const dateToFormatted = this.formatDate(this.dateRange.toDate, this.datePatternForGenericBackEndRequest, '01.01.2070');

    this.loadTicketsSubscription = this.ticketsUtilService
      .getTicketsHistoryViewModelList(
        this.equipmentItem,
        dateFromFormatted !== 'Invalid date' ? dateFromFormatted : null,
        dateToFormatted !== 'Invalid date' ? dateToFormatted : null
      ).pipe(
        map(ticketsList => ticketsList.map(ticket => ({
          ...ticket,
          modality: ticket.modalityTranslation
        })))
      ).subscribe(ticketsResponse => {
        this.rawList = _.clone(ticketsResponse);
        this.viewModelList = ticketsResponse;
        this.initAdvancedFilterDropDownList(ticketsResponse);
        this.showFilters = true;

        if (this.route.firstChild) {
          const key = this.route.firstChild.snapshot.params.ticketKey;
          if (key) {
            const item = this.viewModelList.find(i => i.ticketKey === key);
            if (item) {
              this.openDetailOverlay(item);
              return;
            }
          }
          this.router.navigate(
            ['/equipment', this.equipmentItem.key, 'ticketHistory'],
            {replaceUrl: true}
          );
        }
        this.applyFilterChanges();
      }, () => {
        this.applyFilterChanges();
      });
  }

  /**
   * @description
   * remove loading spinner and call onAdvancedFilterChange
   */
  applyFilterChanges() {
    this.isLoaded = true;
    this.onAdvancedFilterChange();
  }

  /**
   *
   * @description
   * init dropdown options for the advanced filter section
   */
  initAdvancedFilterDropDownList(dataSet) {
    // set the danger for patient drop down options
    this.dangerForPatientOptions = this.ticketsConstantsService.getDangerForPatientsSkeleton();

    // set the ticketTypes
    this.ticketTypes = this.ticketsUtilService.getTicketTypes(dataSet);

    // operational state
    this.ticketsUtilService.getAllowedOperationalStateInList(
      this.viewModelList,
      this.operationalStateFilterOptions)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => this.operationalStateDescriptions = data);

    // status (open/closed)
    this.ticketsConstantsService.getStatusSkeleton().subscribe(response => {
      this.statusOptions = response;
    });

    // add missing open status specially for US
    if (this.rawList.length > 0) {
      const openStatuses = new Set(this.statusOptions[0].value.split(','));
      for (const ticket of this.rawList) {
        if (this.openNotificationsIdentifiers.includes(ticket.ticketStatus)) {
          openStatuses.add(ticket.ticketStatus);
        }
      }
      this.statusOptions[0].value = Array.from(openStatuses).join(',');
    }

    this.setQueryParams();
  }

  getPageSizeConfig(config): string {
    return config.OPEN_TICKETS_LIST_PAGE_SIZE;
  }

  /**
   * @description Filter object with different filters
   */
  getFilterObject(): any {
    this.selectedDropDownList.problemSeverityDescription = this.ticketsUtilService.mapProblemIdToProblemDescription(
      this.selectedProblemSeverityID, this.operationalStateDescriptions);

    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: ['ticketNumber', 'description']
      },
      advancedMultiSelect: this.selectedDropDownList,
      ticketStatus: this.selectedStatus,
      dangerForPatient: this.dangerForPatient,
      orderBy: this.sortSkeleton.sortObject
    };
  }

  /**
   *
   * @param ticketItem | Ticket View Model
   * @param action | action type (convert, update,close)
   */
  action(ticketItem, action) {
    this.ticketItemForActions = ticketItem;
    this.actions.openTicketActionModal(action);
  }

  getExportList = () => {
    if (this.listWithoutPagination) {
      const exportListViewModel = _.cloneDeep(this.listWithoutPagination);
      _.forEach(exportListViewModel, item => {
        const propertiesToMerge = [
          'customerName',
          'department',
          'customerDescription'
        ];
        item = this.lifeNetUtilService.mergeObjects(
          this.equipmentItem,
          item,
          propertiesToMerge
        );
        if (item.completedDate) {
          item.completedDate = this.datePipeWrapperPipe.transform(
            item.completedDate,
            this.datePattern
          );
        }
      });
      return exportListViewModel;
    }
    return [];
  };

  trackByFn(index, item: TicketViewModel) {
    return item['ticketKey'];
  }

  openDetailOverlay(item: Ticket) {
    if (!item) {
      return;
    }
    this.setClosedSelectedTicket(item);
    const index = this.viewModelList.findIndex(
      i => i.ticketKey === item.ticketKey
    );
    this.selectedItem = this.viewModelList[index];
    this.selectedItemIndex = index;

    this.navigate(true, true);

    this.detailOverlay.show();
  }

  onClickUpdateSelectionByIndex(index: number) {
    this.setClosedSelectedTicket(this.viewModelList[index]);

    this.selectedItem = this.viewModelList[index];
    this.selectedItemIndex = index;

    if (this.currentStateName.includes('delivery')) {
      this.navigate(true);
    } else {
      this.navigate();
    }
  }

  navigateOnPaginationSwitch(state: string) {
    this.browserStateService.setUserNavigation();
    this.router.navigate([
      '/equipment',
      this.equipmentItem.key,
      'ticketHistory',
      this.selectedItem.ticketKey,
      state ? state : 'overview'
    ], {
      queryParams: (state && state === 'delivery') ?
        {
          'ticketNumber': this.selectedItem.ticketNumber,
          'ticketType': this.selectedItem.typeID
        } : undefined
    }).then(() => {
      this.browserStateService.resetUserNavigation();
    });
  }

  setClosedSelectedTicket(ticket: Ticket) {
    this.ticketsUtilService.selectedClosedTicket = null;
    if (this.closedNotificationsIdentifiers.includes(ticket.ticketStatus)) {
      this.ticketsUtilService.selectedClosedTicket = ticket;
    }
  }

  onTabClick(key, state) {
    this.browserStateService.setUserNavigation();
    if (state === 'delivery') {
      this.currentStateName = 'equipment-ticketHistory-delivery';
    }
    this.router.navigate([key, state], {
      relativeTo: this.route, replaceUrl: true, queryParams: state === 'delivery' ?
        {
          'ticketNumber': this.selectedItem.ticketNumber,
          'ticketType': this.selectedItem.typeID
        } : undefined
    }).then(() => {
      this.browserStateService.resetUserNavigation();
    });
  }

  onAdvancedFilterChange() {
    this.filteredLengthWithoutPagination = 0;
    super.onAdvancedFilterChange();
  }

  toggleOpenClosed() {
    this.selectedProblemSeverityID = [];
    this.onAdvancedFilterChange();
    this.ticketsUtilService.getAllowedOperationalStateInList(
      this.viewModelList,
      this.operationalStateFilterOptions)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => this.operationalStateDescriptions = data);
  }

  hasOperationalStates(): Boolean {
    return !!this.operationalStateDescriptions && this.operationalStateDescriptions.length > 1;
  }

  private formatDate(date: Date | null, format: string, ifEmpty = ''): string {
    return date instanceof Date
      ? this.datePipeWrapperPipe.transform(date.toISOString(), format)
      : ifEmpty;
  }

  navigate(navigateToOverview?: boolean, initialNavigation?: boolean): void {
    this.deliveryMilestonesAvailable = false;
    this.isSelectedItemOpen = this.openNotificationsIdentifiers.includes(this.selectedItem.ticketStatus);

    if (!this.isSelectedItemOpen || !this.milestonesUtilService.isTicketTypeAllowed(this.selectedItem.typeID)) {
      this.navigationWrapper(navigateToOverview);
      return;
    }

    if (initialNavigation) {
      this.navigationWrapper(navigateToOverview);
    }

    if (this.isDeliveryMilestonesAllowedForCountry) {
      this.milestonesUtilService.getDeliveryMilestones(this.selectedItem.ticketNumber).subscribe(response => {
        this.deliveryMilestonesAvailable = response.length > 0;

        if (!initialNavigation) {
          this.navigationWrapper(navigateToOverview && !this.deliveryMilestonesAvailable);
        }
      });
    }
  }

  navigationWrapper(navigateToOverview: boolean) {
    this.navigateOnPaginationSwitch(
      (navigateToOverview) ?
        'overview' :
        EquipmentTicketHistoryComponent.getState(this.currentStateName)
    );
  }

  refreshTicket() {
    this.refreshItemEventService.emitRefreshItem();
  }
}
