import {Injectable} from '@angular/core';

@Injectable()
export class ReportsConstantsService {

  constructor() {
  }

  getUptimeReportSkeleton() {
    return {
      details: {
        param_1: {
          label: 'LABEL_ENGINEERS_ONSITE',
          option: 'noOfServiceEvents'
        },
        param_2: {
          label: 'LABEL_UPTIME_PCP',
          option: 'pcpUptime'
        },
        param_3: {
          label: 'LABEL_DOWNTIME_PCP',
          option: 'pcpDowntimeHrs'
        },
        param_4: {
          label: 'LABEL_SYSTEM_AVAILABLE',
          option: 'systemAvailability'
        },
        param_5: {
          label: 'LABEL_SYSTEM_UNAVAILABLE',
          option: 'systemUnavailableHrs'
        }
      },
      totals: {
        param_1: {
          label: 'LABEL_TOTAL_ENGINEERS_ONSITE',
          option: 'noOfServiceEvents'
        },
        param_2: {
          label: 'LABEL_TOTAL_UPTIME_PCP',
          option: 'pcpUptime'
        },
        param_3: {
          label: 'LABEL_TOTAL_DOWNTIME_PCP',
          option: 'pcpDowntimeHrs'
        },
        param_4: {
          label: 'LABEL_TOTAL_SYSTEM_AVAILABLE',
          option: 'systemAvailability'
        },
        param_5: {
          label: 'LABEL_TOTAL_SYSTEM_UNAVAILABLE',
          option: 'systemUnavailableHrs'
        }
      }
    };
  }

  getEducationReportSkeleton() {
    return {
      details: {
        param_1: {
          label: 'LABEL_SALES_ORDER',
          option: 'salesOrder'
        },
        param_2: {
          label: 'LABEL_BILLING_STATUS',
          option: 'billingStatus'
        },
        param_3: {
          label: 'LABEL_EDUCATION_DESC',
          option: 'educationDescription'
        },
        param_4: {
          label: 'GENERIC_LABEL_TICKET_NUMBER',
          option: 'ticketNumber'
        },
        param_5: {
          label: 'LABEL_TICKET_PLANNED_START',
          option: 'plannedStartDate'
        }
      },
      totals: {
        param_1: {
          label: 'LABEL_AMOUNT_PURCHASED',
          label_totals: 'LABEL_TOTAL_AMOUNT_PURCHASED',
          option: 'amountPurchased'
        },
        param_2: {
          label: 'LABEL_AMOUNT_USED',
          label_totals: 'LABEL_TOTAL_AMOUNT_USED',
          option: 'amountUsed'
        },
        param_3: {
          label: 'LABEL_AMOUNT_BALANCE',
          label_totals: 'LABEL_TOTAL_AMOUNT_BALANCE',
          option: 'amountBalance'
        }
      }
    };
  }

  getPerformanceSummaryReportSkeleton() {
    return {
      downtimes: {
        param_1: {
          label: 'LABEL_DOWNTIME_PCP',
          option: 'downtimeHrs'
        },
        param_2: {
          label: 'LABEL_UPTIME_PCP',
          option: 'uptime'
        },
        param_3: {
          label: 'LABEL_DOWNTIME_HOURS',
          option: 'totalRepairTime'
        },
        param_4: {
          label: 'LABEL_NUMBER_SERVICE_EVENTS',
          option: 'noOfServiceEvents'
        }
      },
      serviceVisits: {
        param_1: {
          label: 'LABEL_NUMBER_SERVICE_EVENTS',
          option: 'totalRepairTime'
        },
        param_2: {
          label: 'LABEL_ENGINEERS_ONSITE',
          option: 'noOfServiceEvents'
        }
      },
      serviceHours: {
        param_1: {
          label: 'LABEL_TOTAL_REPAIR_TIME',
          option: 'totalRepairTime'
        },
        param_2: {
          label: 'LABEL_MEAN_REPAIR_TIME',
          option: 'meanRepairTime'
        },
        param_3: {
          label: 'LABEL_NUMBER_SERVICE_EVENTS',
          option: 'noOfServiceEvents'
        }
      },
      serviceCalls: {
        param_1: {
          label: 'LABEL_NUMBER_SERVICE_EVENTS',
          option: 'noOfServiceEvents'
        },
        param_2: {
          label: 'LABEL_TOTAL_REPAIR_TIME',
          option: 'noOfServiceCalls'
        }
      }
    };
  }

  getPerformanceSummaryCollapseObject() {
    return [{
      modality: 'downtimes',
      collapsed: true
    }, {
      modality: 'serviceVisits',
      collapsed: true
    }, {
      modality: 'serviceHours',
      collapsed: true
    }, {
      modality: 'serviceCalls',
      collapsed: true
    }];
  }

  getRemoteActivityReportSkeleton() {
    return {
      details: {
        param_1: {
          label: 'LABEL_NUMBER_SERVICE_EVENTS',
          option: 'serviceEventCount'
        },
        param_2: {
          label: 'LABEL_ENGINEERS_ONSITE',
          option: 'serviceEventOnSite'
        },
        param_3: {
          label: 'LABEL_REMOTE_FIX',
          option: 'serviceEventRemote'
        },
        param_4: {
          label: 'LABEL_FIX_RATE',
          option: 'remoteFixRate'
        }
      },
      totals: {
        param_1: {
          label: 'LABEL_TOTAL_SERVICE_EVENTS',
          option: 'serviceEventCount'
        },
        param_2: {
          label: 'LABEL_TOTAL_ENGINEERS_ONSITE',
          option: 'serviceEventOnSite'
        },
        param_3: {
          label: 'LABEL_TOTAL_REMOTE_FIX',
          option: 'serviceEventRemote'
        },
        param_4: {
          label: 'LABEL_TOTAL_FIX_RATE',
          option: 'remoteFixRate'
        }
      }
    };
  }
}
