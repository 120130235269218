import { Injectable } from '@angular/core';
import { SortSkeleton } from '../../../shared/sorting/sort-skeleton';
import { SortDirection } from '../../../shared/sorting/sort-object';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import {
  Piis,
  SecurityNotifications
} from '../../models/securityNotifications/security-notifications';
import * as _ from 'lodash';
import { gray, green, red, yellow } from '../../core-constants.service';
import { Equipment } from 'app/core/models/equipment/equipment';
import { DropdownOptions } from '../../models/dropdown-options';
import { TranslateService } from '@ngx-translate/core';
import { Observable , Subject } from 'rxjs';
import { SecurityEquipmentViewModel } from '../../../shared/components/accordion-with-equipments/accordion-with-equipments.component';
import { RemoveLeadingZeroPipe } from '../../../shared/pipes/removeLeadingZero/remove-leading-zero.pipe';
import { intersectionWith } from 'lodash';

export const SECURITY_SEVERITY_CRITICAL = 'SECURITY_SEVERITY_CRITICAL';
export const SECURITY_SEVERITY_HIGH = 'SECURITY_SEVERITY_HIGH';
export const SECURITY_SEVERITY_MEDIUM = 'SECURITY_SEVERITY_MEDIUM';
export const SECURITY_SEVERITY_LOW = 'SECURITY_SEVERITY_LOW';
export const SECURITY_SEVERITY_NONE = 'SECURITY_SEVERITY_NONE';
export const SECURITY_EVALUATION_ONGOING = 'SECURITY_EVALUATION_ONGOING';
export const SECURITY_SEVERITY_UNKNOWN = 'SECURITY_SEVERITY_UNKNOWN';
export enum securityNotificationsSeverityTypes {
  UNKNOWN = '-1',
  NONE = '0',
  LOW = '1',
  MEDIUM = '2',
  HIGH = '3',
  CRITICAL = '4'
}

@Injectable()
export class SecurityNotificationsService {

  private closeSecurityOverlaySubject = new Subject<void>();
  changeSecurityIdSubject = new Subject<string>();
  private _securityId: string;

  private evaluationOngoing;

  static evaluationOptions() {
    return [
      {
        name: SECURITY_SEVERITY_CRITICAL,
        minScore: 9.0
      },
      {
        name: SECURITY_SEVERITY_HIGH,
        minScore: 7.0
      },
      {
        name: SECURITY_SEVERITY_MEDIUM,
        minScore: 4.0
      },
      {
        name: SECURITY_SEVERITY_LOW,
        minScore: 0.1
      },
      {
        name: SECURITY_SEVERITY_NONE,
        minScore: 0.0
      },
      {
        name: SECURITY_EVALUATION_ONGOING,
        minScore: -1
      }];
  }

  static getSeverityForTranslation(severity: securityNotificationsSeverityTypes) {
    switch (severity) {
      case securityNotificationsSeverityTypes.NONE:
        return SECURITY_SEVERITY_NONE;
      case securityNotificationsSeverityTypes.LOW:
        return 'SECURITY_SEVERITY_LOW';
      case securityNotificationsSeverityTypes.MEDIUM:
        return 'SECURITY_SEVERITY_MEDIUM';
      case securityNotificationsSeverityTypes.HIGH:
        return 'SECURITY_SEVERITY_HIGH';
      case securityNotificationsSeverityTypes.CRITICAL:
        return 'SECURITY_SEVERITY_CRITICAL';
      case securityNotificationsSeverityTypes.UNKNOWN:
      default:
        return 'SECURITY_SEVERITY_UNKNOWN';
    }
  }

  static getSecurityDetailStatus(score: number): string {
    if (score >= 7) {
      return red;
    }
    if (score >= 4) {
      return yellow;
    }
    if (score >= 0) {
      return green;
    }
    return gray;
  }

  constructor(
    private configService: CountryConfigRestService,
    private translateService: TranslateService,
    private removeLeadingZeroPipe: RemoveLeadingZeroPipe
  ) {
    this.configService.getConfig().subscribe(configResponse => {
      this.evaluationOngoing = configResponse.SECURITY_NOTIFICATIONS_EVALUATION_ONGOING;
    });
  }

  getMultiSelectEvaluationOptions(): DropdownOptions[] {
    const multiSelectOptions: DropdownOptions[] = [];
    SecurityNotificationsService.evaluationOptions().forEach(option => {
      multiSelectOptions.push(
        {
          // tslint:disable-next-line:ban
          title: this.translateService.instant(option.name),
          value: option.name
        }
      );
    });
    return multiSelectOptions;
  }

  getNumberOfEvaluationResults(piis: Piis[]): number {
    let result = 0;

    _.forEach(piis, item => {
      if (item.cvssProduct !== this.evaluationOngoing) {
        result++;
      }
    });

    return result;

  }

  getSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'publication',
        sortDir: SortDirection.DESC,
        thenSortBy: [{
          sortBy: 'cvssoverallscore',
          sortDir: SortDirection.DESC
        }, {
          sortBy: 'name',
          sortDir: SortDirection.ASC
        }]
      },
      items: [{
        title: 'SECURITY_PUBLICATION_DATE',
        value: 'publication',
        thenSortBy: [{
          sortBy: 'cvssoverallscore',
          sortDir: SortDirection.DESC
        }, {
          sortBy: 'name',
          sortDir: SortDirection.ASC
        }]
      }, {
        title: 'SECURITY_LABEL_SCORE_RANGE',
        value: 'cvssoverallscore',
        thenSortBy: [{
          sortBy: 'publication',
          sortDir: SortDirection.DESC
        }, {
          sortBy: 'name',
          sortDir: SortDirection.ASC
        }]
      }]
    };
  }

  get closeSecurityOverlay$(): Observable<void> {
    return this.closeSecurityOverlaySubject.asObservable();
  }

  emitCloseSecurityOverlayEvent() {
    this.closeSecurityOverlaySubject.next();
  }

  get changeSecurityIdSubject$(): Observable<string> {
    return this.changeSecurityIdSubject.asObservable();
  }

  emitChangeSecurityIdSubject(securityId: string) {
    this.changeSecurityIdSubject.next(securityId);
  }

  get securityId(): string {
    return this._securityId;
  }

  set securityId(value: string) {
    this._securityId = value;
  }

  getLinkItems(item: SecurityNotifications) {
    return item.cves.map(cve => {
      return { text: cve.name, link: cve.link };
    });
  }

  createSeverityDropDownList(): DropdownOptions[] {
    const severityOptions = [];

    severityOptions.push({
      value: securityNotificationsSeverityTypes.NONE,
      title: '0.0'
    });
    severityOptions.push({
      value: securityNotificationsSeverityTypes.LOW,
      title: '0.1 - 3.9'
    });
    severityOptions.push({
      value: securityNotificationsSeverityTypes.MEDIUM,
      title: '4.0 - 6.9'
    });
    severityOptions.push({
      value: securityNotificationsSeverityTypes.HIGH,
      title: '7.0 - 8.9'
    });
    severityOptions.push({
      value: securityNotificationsSeverityTypes.CRITICAL,
      title: '9.0 - 10.0'
    });
    severityOptions.push({
      value: securityNotificationsSeverityTypes.UNKNOWN,
      // tslint:disable-next-line:ban
      title: this.translateService.instant('SECURITY_SEVERITY_UNKNOWN')
    });

    return severityOptions;
  }

  getSeverity (cvssoverallscore: string) {
    const score = parseFloat(cvssoverallscore);
    if (isNaN(score)) {
      return securityNotificationsSeverityTypes.UNKNOWN;
    }
    if (score < 0.1) {
      return securityNotificationsSeverityTypes.NONE;
    }
    if (score < 4.0) {
      return securityNotificationsSeverityTypes.LOW;
    }
    if (score < 7.0) {
      return securityNotificationsSeverityTypes.MEDIUM;
    }
    if (score < 9.0) {
      return securityNotificationsSeverityTypes.HIGH;
    }
    if (score <= 10.0) {
      return securityNotificationsSeverityTypes.CRITICAL;
    }
    return securityNotificationsSeverityTypes.UNKNOWN;
  }

  isEquipmentConnectedToPII(eq: SecurityEquipmentViewModel|Equipment, pii: Piis) {
    return this.isMaterialNumberTheSame(eq, pii) && this.isBusinessUnitTheSame(eq, pii) &&
      this.isSoftwareVersionTheSame(eq, pii);
  }

  isMaterialNumberTheSame(eq: SecurityEquipmentViewModel|Equipment, pii: Piis): boolean {
    return (eq.cmdbEquipment && eq.cmdbEquipment.materialNumberConsolidated ? eq.cmdbEquipment.materialNumberConsolidated :
      this.removeLeadingZeroPipe.transform(eq.materialNumber)) === this.removeLeadingZeroPipe.transform(pii.materialNumber);
  }

  isBusinessUnitTheSame(eq: SecurityEquipmentViewModel|Equipment, pii: Piis): boolean {
    return ((eq.cmdbEquipment && eq.cmdbEquipment.modalityCode && eq.cmdbEquipment.modalityCode.trim()) ?
      (eq.cmdbEquipment.modalityCode) :
      (eq.modality === null ? '' : eq.modality)) === pii.businessLine;
  }

  isSoftwareVersionTheSame(eq: SecurityEquipmentViewModel|Equipment, pii: Piis): boolean {
    return ((eq.cmdbEquipment && eq.cmdbEquipment.softwareVersion && eq.cmdbEquipment.softwareVersion.trim()) ?
      (eq.cmdbEquipment.softwareVersion) :
      (eq.softwareVersion === null ? '' : eq.softwareVersion)) === pii.version;
  }

  filterByMyEquipment(dataset: any[], { isMyEquipmentChecked, myEquipmentList }): any[] {
    if (dataset.length === 0 || !isMyEquipmentChecked) {
      return dataset;
    }

    return dataset.filter(row => intersectionWith(myEquipmentList as any[], row.piis as any[],
      // this is not related to RxJS however tslint seems to think it is
      // tslint:disable-next-line: rxjs-no-unsafe-scope
      this.isEquipmentConnectedToPII.bind(this)).length !== 0);
  }

  formatCvssProduct(cvssProduct: string): string {
    const product = parseFloat(cvssProduct);
    if (isNaN(product)) {
      return cvssProduct;
    }
    return product.toFixed(1);
  }
}
