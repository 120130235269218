import { EmailFrequency, UserSettings } from '../../../core/models/UserSettings';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import * as _ from 'lodash';
import { OverlayCommunicationService } from '../../../core/component-communication-services/overlay-close/overlay-communication.service';
import { CollapsableDirective } from '../../directives/collapsable/collapsable.directive';
import { roles } from '../../../core/core-constants.service';
import { UserUtilService } from '../../../core/services/user/user-util.service';
import { combineLatest, Observable } from 'rxjs';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { map } from 'rxjs/operators';
import { MessagingCenterService } from '../../../core/services/messaging-center/messaging-center.service';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';

@Component({
  selector: 'hl-create-user-notifications-page',
  templateUrl: './create-user-notifications-page.component.html'
})
export class CreateUserNotificationsPageComponent implements OnInit {
  isLoaded = false;
  isChanged = false;
  isRoleChanged = false;
  userSettingsOrig: UserSettings;
  userSettings: UserSettings;
  showAsuWeeklyEmail$: Observable<boolean>;
  isSecurityAdvisoriesViewRoleEditingDisabled = false;
  showSubscribeSecurityAdvisories = false;
  showSecurityAdvisoriesRole = false;

  @ViewChild(CollapsableDirective, { static: false }) collapsable: CollapsableDirective;

  @Input()
  instanceId = 1;

  constructor(private httpUserService: UserRestService,
    private userUtilService: UserUtilService,
    private configService: CountryConfigRestService,
    private overlayCommunicationService: OverlayCommunicationService,
    private toasterService: ToasterService,
    private messagingCenterService: MessagingCenterService) {
    this.userSettings = {
      'subprocessorNotificationSubscribed': false,
      'emailFrequency': EmailFrequency.NEVER,
      'securityAdvisoriesSubscribed': false,
      'securityAdvisoriesViewRole': false
    };
  }

  ngOnInit(): void {
    this.init();
  }

  private init() {
    const rolesToCheck = {
      itAdminRole: roles.itAdminRole,
      securityAdvisoryViewRole: roles.securityAdvisoryViewRole,
      securityAdvisoryViewXiaRole: roles.securityAdvisoryViewXiaRole,
      cybersecurityDashboardRole: roles.cybersecurityDashboardRole,
      securityAdvisoryAuthorRole: roles.securityAdvisoryAuthorRole
    };
    this.showAsuWeeklyEmail$ = combineLatest([this.userUtilService.checkUserRoles(rolesToCheck), this.configService.getConfig()])
      .pipe(
        map ((responses) => {
        const userRoles = responses[0];
        this.setSecurityAdvisoriesParams(userRoles, responses[1]);
        return userRoles && userRoles.itAdminRole;
    }));

    this.httpUserService.getUserSettings().subscribe(
      result => {
        this.userSettingsOrig = result;
        this.userSettings = _.cloneDeep(this.userSettingsOrig);
        this.isLoaded = true;
      }
    );
  }

  private setSecurityAdvisoriesParams(userRoles, config) {

    const toggleSecurityAdvisories = _.isEqual(config.TOGGLE_SECURITY_ADVISORIES_MESSAGING_CENTER, 'true');
    const toggleSecurityAdvisoriesRole = _.isEqual(config.FEATURE_TOGGLE_ADVISORY_SUBSCRIPTION, 'true');
    const toggleSecurityAdvisoriesXiaRole = _.isEqual(config.FEATURE_TOGGLE_XIA_VIEW_ADVISORIES, 'true');

    if (toggleSecurityAdvisories && toggleSecurityAdvisoriesXiaRole) {
      if (userRoles && userRoles.securityAdvisoryViewXiaRole) {
        this.showSubscribeSecurityAdvisories = true;
        return;
      }
    }

    if (toggleSecurityAdvisories && toggleSecurityAdvisoriesRole) {
      this.showSubscribeSecurityAdvisories = userRoles && userRoles.securityAdvisoryViewRole;
      this.showSecurityAdvisoriesRole = true;
      this.isSecurityAdvisoriesViewRoleEditingDisabled = userRoles &&
        (userRoles.itAdminRole || userRoles.cybersecurityDashboardRole || userRoles.securityAdvisoryAuthorRole) &&
        userRoles.securityAdvisoryViewRole;
      return;
    }

    if (toggleSecurityAdvisories) {
      this.showSubscribeSecurityAdvisories = true;
      return;
    }

  }

  private collapseAll() {
    this.collapsable.toggleAllPanels(false);
  }

  resetElements(): void {
    this.init();
    this.collapseAll();
  }

  setChanged() {
    if (this.showSecurityAdvisoriesRole && !this.userSettings.securityAdvisoriesViewRole) {
      this.userSettings.securityAdvisoriesSubscribed = false;
    }
    this.isChanged = !_.isEqual(this.userSettingsOrig, this.userSettings);
    this.isRoleChanged = !_.isEqual(this.userSettingsOrig.securityAdvisoriesViewRole, this.userSettings.securityAdvisoriesViewRole);
  }

  save() {
    this.isLoaded = false;
    this.httpUserService.postUserSettings(this.userSettings).subscribe(
      () => {
        this.isLoaded = true;
        this.isChanged = false;
        this.notifyUserReLogin();
        this.userSettingsOrig = _.cloneDeep(this.userSettings);
        this.overlayCommunicationService.emitCloseOverlayAfterSave(null);
        this.messagingCenterService.emitRefreshMessagingCenter();
      }
    );
  }

  notifyUserReLogin() {
    if (this.isRoleChanged) {
      this.toasterService.showSuccessToaster(true, 'TEXT_ROLE_CHANGED_LOGOUT_AND_LOGIN_AGAIN');
    }
  }

  close() {
    if (this.isChanged) {
      this.userSettings = _.cloneDeep(this.userSettingsOrig);
      this.isChanged = false;
    }
    this.overlayCommunicationService.emitCloseOverlayAfterSave(null);
  }

  isAsuWeeklyEmailChecked() {
    return this.userSettings.emailFrequency === EmailFrequency.WEEKLY;
  }

  asuWeeklyEmailChanged() {
    this.userSettings.emailFrequency = this.isAsuWeeklyEmailChecked() ? EmailFrequency.NEVER : EmailFrequency.WEEKLY;
    this.setChanged();
  }
}
