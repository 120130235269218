import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { CreatePsrTemplateEventService } from '../../../core/component-communication-services/create-psr-template/create-psr-template-event.service';
import { PsrTemplateByModality } from '../../../core/models/professionalServiceRequest/psr-template-by-modality';
import { SelectOption } from '../../../core/models/select-option';

@Component({
  selector: 'hl-create-psr-templates',
  templateUrl: './create-psr-templates.component.html'
})
export class CreatePsrTemplatesComponent implements OnInit {
  @Input()
  psrTemplates: PsrTemplateByModality[];
  selectedTemplate: SelectOption;
  isActive = false;
  isDropdownOpen = false;
  _value = '';
  @Input()
  set value(val: string) {
    this._value = val;
    if (this._value) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  get value() {
    return this._value;
  }

  @HostListener('document.click', ['$event'])
  clickout(event) {
    if (!this.el.nativeElement.contains(event.target) && this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }

  constructor(
    private createPsrTemplateEventService: CreatePsrTemplateEventService,
    private el: ElementRef
  ) {}

  ngOnInit() {}

  dropdownToggle(event: any) {
    const target: Element = event.target;
    if (!target.attributes.getNamedItem('disabled')) {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  }

  setSelected(value: SelectOption) {
    this.selectedTemplate = value;
    this.value = value == null ? '' : value.value.title;
    this.onTemplateSelected();
  }

  onTemplateSelected() {
    this.createPsrTemplateEventService.emitPSRTemplateSelected(
      this.selectedTemplate
    );
  }
}
