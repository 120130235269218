import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'hl-nav-list-link',
  templateUrl: './nav-list-link.component.html'
})
export class NavListLinkComponent implements OnInit {

  @Input() elements: number;
  @Input() currentIndex: number;
  @Input() currentPaginationItems: number;
  @Input() title: string;

  // for notifying parent component to update current selection
  @Output() navLinkUpdate = new EventEmitter<number>();

  @Output() paginationUpdate = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  doNext() {
    if (this.elements > 0 && this.currentIndex + 1 !== this.elements) {
      if (this.currentIndex + 2 >= this.currentPaginationItems) {
        this.paginationUpdate.emit();
      }
      this.navLinkUpdate.emit(this.getTargetIndex(this.currentIndex + 1));
    }
  }

  doPrev() {
    if (this.elements > 0 && this.currentIndex !== 0) {
      this.navLinkUpdate.emit(this.getTargetIndex(this.currentIndex - 1));
    }
  }

  getTargetIndex(index) {
    let targetIndex = index;
    if (index > this.elements - 1) {
      targetIndex = 0;
    } else {
      if (index < 0) {
        targetIndex = this.elements - 1;
      }
    }
    return targetIndex;
  }
}
