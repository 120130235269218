import { RescheduleActivityModalComponent } from '../../../shared/modal-popup/reschedule-activity-modal/reschedule-activity-modal.component';
import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { ActivitiesConstantsService } from '../../../core/services/activities/activities-constants.service';
import { ActivitiesUtilService } from '../../../core/services/activities/activities-util.service';
import { Activities } from '../../../core/models/activities/activities';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseEquipmentDetailListView } from '../../../core/base-class/base-equipment-detail-list-view';

import * as _ from 'lodash';
import { DatePipeWrapperPipe } from '../../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { CalendarOptions } from '../../../core/models/activities/calendar-options';
import { CalendarUtilService } from '../../../core/utils/calendar-util.service';
import { NotifStatus } from '../../../core/core-constants.service';
import { ActivitiesCacheService } from '../../../core/services/cache/activities-cache.service';
import { OverlayComponent } from '../../../shared/components/overlay/overlay.component';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { StateService } from '../../../core/services/state.service';
import { BrowserStateService } from '../../../core/services/browser-state.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { FilterObject } from '../../../shared/filtering/filter-object';
import { ActivitiesStatusDescriptionTranslationService } from '../../../core/services/activities/activities-status-description-translation-service';
import { RefreshItemEventService } from '../../../core/component-communication-services/refresh-item-event/refresh-item-event.service';

@Component({
  selector: 'hl-equipment-activities',
  templateUrl: './equipment-activities.component.html'
})
export class EquipmentActivitiesComponent
  extends BaseEquipmentDetailListView<ActivitiesViewModel>
  implements OnInit, OnDestroy {

  @ViewChild('rescheduleModal', { static: false })
  rescheduleModal: RescheduleActivityModalComponent;
  @ViewChild('detailOverlay', { static: false })
  detailOverlay: OverlayComponent;

  @ViewChild('overviewTab', { static: false })
  overviewTab: ElementRef;
  @ViewChild('historyTab', { static: false })
  historyTab: ElementRef;

  OPEN_ACTIVITY = [];
  CLOSE_ACTIVITY = [];

  showClosedActivities: boolean;
  showActivityHistoryTab = false;
  config: any;
  currentStateName: string;

  // Skeleton for selected multiSelect dropdown
  selectedDropDownList = {
    type: []
  };

  activityTypes = [];
  activityTypesLoaded = false;

  // for reschedule modal
  selectedActivityForModal: ActivitiesViewModel;

  // for closed activity modal
  closedActivityForModal: Activities;
  calendarStartDate: string;

  isCalendarVisible: boolean;
  isCalendarMonthView: boolean;
  isCalendarYearView: boolean;
  isCalendarWeekView: boolean;
  calendarOptions: CalendarOptions = null;
  refreshButtonRender = false;

  activityStatus = [];
  selectedStatus = [];
  statusOptions = [];
  selectedOption = [];

  constructor(
    public lifeNetUtilService: LifeNetUtilService,
    configService: CountryConfigRestService,
    equipmentUtilService: EquipmentUtilService,
    activatedRoute: ActivatedRoute,
    private router: Router,
    private activitiesUtilService: ActivitiesUtilService,
    private activitiesConstantsService: ActivitiesConstantsService,
    private calendarUtilService: CalendarUtilService,
    private ticketsUtilService: TicketsUtilService,
    private activitiesCacheService: ActivitiesCacheService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private stateService: StateService,
    private browserStateService: BrowserStateService,
    private refreshItemEventService: RefreshItemEventService,
    public route: ActivatedRoute,
    private descriptionTranslationService: ActivitiesStatusDescriptionTranslationService,
    filterUtilService: FilterUtilService
  ) {
    super(
      filterUtilService,
      configService,
      lifeNetUtilService,
      equipmentUtilService,
      activatedRoute
    );
  }

  ngOnInit() {
    super.init();
    this.stateService.getActiveStateName().subscribe(stateName => {
      this.currentStateName = stateName;
    });
  }

  /**
   * @description Additional properties to be initialized for derived class
   */
  afterInitProperties(): void {
    this.currentStateName = this.stateService.getStateNameFromWindowLocation();
    this.selectedActivityForModal = null;
    this.closedActivityForModal = null;

    this.showClosedActivities = true;

    this.sortSkeleton = this.activitiesConstantsService.getSortSkeletonForEquipmentDetail();

    this.activitiesUtilService.getActivityTypes().subscribe(typesResponse => {
      this.activityTypes = typesResponse;
      this.activityTypesLoaded = true;
    });

    this.equipmentItem.modality = this.equipmentItem.modalityTranslation;

    this.isCalendarVisible = false;
    this.isCalendarMonthView = true;
    this.isCalendarYearView = false;
    this.isCalendarWeekView = false;

    this.activitiesCacheService.setCustomerNumber(this.equipmentItem.customerNumber);
    this.setSearchInputFromQueryParam(this.router);
  }

  /**
   * Toggle between OPEN/CLOSE/ALL
   */
  toggleOpenClosed() {
    this.activitiesUtilService
      .getActivityStatusDropDownOptions(this.getSelectedNotifStatus(this.selectedOption)).pipe(takeUntil(this.unsubscribe$))
      .subscribe(statusDropDownOption => {
        this.activityStatus = this.activitiesUtilService.getAvailableActivities(this.rawList, statusDropDownOption);
      });
    this.selectedStatus.splice(0, this.selectedStatus.length);
    this.onAdvancedFilterChange();
  }

  /**
   * Method calculate NotifStatus from selected arrays
   * @param selected
   */
  getSelectedNotifStatus(selected: string[]): NotifStatus {
    if (selected && selected.length === 1) {
      if (selected[0] === '1') {
        return NotifStatus.OPEN;
      }
      if (selected[0] === '2') {
        return NotifStatus.CLOSED;
      }
    }
    return NotifStatus.ALL;
  }

  /**
   * Get array of enabled Activities for selected NotifStatus
   * @param status
   */
  getOpenClosedActivity(status: NotifStatus): string[] {
    switch (status) {
      case NotifStatus.OPEN:
        return this.OPEN_ACTIVITY;
      case NotifStatus.CLOSED:
        return this.CLOSE_ACTIVITY;
      case NotifStatus.ALL:
        return this.OPEN_ACTIVITY.concat(this.CLOSE_ACTIVITY);
    }
  }

  /**
   * @description Additional config properties initialized in derived class
   */
  afterConfigProperties(config: any): void {
    this.config = config;

    this.showActivityHistoryTab = this.activitiesUtilService.showActivityHistoryTab(config);

    this.OPEN_ACTIVITY = config.STATUS_IDENTIFIER_OPEN_NOTIFICATIONS.split(',');
    this.CLOSE_ACTIVITY = config.STATUS_IDENTIFIER_CLOSED_NOTIFICATIONS.split(',');
    this.refreshButtonRender = _.isEqual(config.NOTIFICATIONS_REFRESH_BUTTON_FEATURE_TOGGLE, 'true');

    this.statusOptions = this.activitiesConstantsService.getOpenCloseSkeleton();

    this.loadPlannedActivitiesList();
  }

  loadPlannedActivitiesList() {
    this.isLoaded = false;
    this.activitiesUtilService
      .getPlannedActvWithClosedActvForEquipment(
        this.equipmentItem.key,
        this.showClosedActivities
      )
      .pipe(finalize(() => {
        this.isLoaded = true;
        this.onAdvancedFilterChange();
        this.activitiesUtilService
          .getActivityStatusDropDownOptions(this.getSelectedNotifStatus(this.selectedOption)).pipe(takeUntil(this.unsubscribe$))
          .subscribe(statusDropDownOption => {
            this.activityStatus = this.activitiesUtilService.getAvailableActivities(this.rawList, statusDropDownOption);
          });
      }))
      .subscribe(activitiesResponse => {
        this.rawList = _.clone(activitiesResponse);
        this.calendarOptions = this.calendarUtilService.getCalendarOptions(
          this.rawList
        );

        this.activitiesUtilService.addSortDateField(this.rawList);
        this.activitiesUtilService.fillActivityStatusDescriptions(this.rawList);

        if (this.activatedRoute.firstChild) {
          const key = this.activatedRoute.firstChild.snapshot.params.ticketKey;
          if (key) {
            const item = this.viewModelList.find(i => i.ticketKey === key);
            if (item) {
              this.openDetailOverlay(item);
              return;
            }
          }
          this.router.navigate(
            ['/equipment', this.equipmentItem.key, 'activity'],
            {replaceUrl: true}
          );
        }
      });
  }

  getPageSizeConfig(config): string {
    return config.PLANNED_ACTIVITY_LIST_PAGE_SIZE;
  }

  getFilterObject(): FilterObject {
    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: ['ticketNumber', 'shortText', 'qmtext']
      },
      dateRange: {
        rangeOfDate: this.dateRange,
        propertyKey: ['plannedStart', 'completedDate', 'dueDate']
      },
      advancedMultiSelect: this.selectedDropDownList,
      orderBy: this.sortSkeleton.sortObject,
      activitiesStatus:  this.selectedStatus,
      activitiesOpenClosed: this.getOpenClosedActivity(this.getSelectedNotifStatus(this.selectedOption))
    };
  }

  isActivityClosed(activityStatus: string): boolean {
    return _.includes(this.CLOSE_ACTIVITY, activityStatus);
  }

  /**
   * @description
   * Opens the reschedule modal
   */
  reschedule(activity: Activities) {
    // we need to pass activitiesViewModel object
    this.selectedActivityForModal = _.assign(activity, {
      productName: this.equipmentItem.productName,
      myEquipmentName: this.equipmentItem.myEquipmentName,
      siemensId: this.equipmentItem.siemensId,
      department: this.equipmentItem.department,
      street: this.equipmentItem.street,
      city: this.equipmentItem.city,
      zip: this.equipmentItem.zip,
      modality: this.equipmentItem.modality,
      modalityTranslation: this.equipmentItem.modalityTranslation,
      pmDescription: ''
    });
    this.descriptionTranslationService.getActivityStatusDescriptionTranslation(activity).subscribe(
      value => this.selectedActivityForModal.pmDescription = value
    );
    this.rescheduleModal.show();
  }

  getExportList = () => {
    if (this.listWithoutPagination && this.equipmentItem) {
      const exportListViewModel = _.cloneDeep(this.listWithoutPagination);
      _.forEach(exportListViewModel, item => {
        const propertiesToMerge = [
          'customerName',
          'customerDescription',
          'productName',
          'siemensId',
          'myEquipmentName',
          'street',
          'zip',
          'city',
          'state',
          'department'
        ];
        item = this.lifeNetUtilService.mergeObjects(
          this.equipmentItem,
          item,
          propertiesToMerge
        );

        item['activityDueDate'] = this.datePipeWrapperPipe.transform(
          new Date(item.dueDate),
          this.datePattern
        );
        if (!_.isEmpty(this.equipmentItem.modality)) {
          item['modality'] = this.equipmentItem.modality;
        }
        if (item.completedDate) {
          item.completedDate = this.datePipeWrapperPipe.transform(
            item.completedDate,
            this.datePattern
          );
        }
        if (item.performedDate) {
          item.performedDate = this.datePipeWrapperPipe.transform(
            item.performedDate,
            this.datePattern
          );
        }
      });
      return exportListViewModel;
    }
    return [];
  }

  /**
   *
   * @description
   * toggles the calendar view
   */
  toggleCalendar(view) {
    if (view === 0 && this.isCalendarVisible) {
      return;
    }
    if (view === 1 && !this.isCalendarVisible) {
      return;
    }
    this.isCalendarVisible = !this.isCalendarVisible;

    // set back search term
    if (this.isCalendarVisible) {
      this.searchInput = '';
      // call on filter change, to see the change
      this.onAdvancedFilterChange();
    }
  }

  /**
   *
   * @description
   * toggles the calendar view to toggle month and year view
   */
  toggleCalendarViews(view) {
    this.isCalendarMonthView = view === 0;
    this.isCalendarYearView = view === 1;
    this.isCalendarWeekView = view === 2;
  }

  trackByFn(index, item: ActivitiesViewModel) {
    return item['ticketKey'];
  }

  openDetailOverlay(item: ActivitiesViewModel) {
    const index = this.viewModelList.findIndex(
      i => i.ticketKey === item.ticketKey
    );
    if (index !== -1) {
      this.onClickUpdateSelectionByIndex(index);
      this.detailOverlay.show();
    }
  }

  onClickUpdateSelectionByIndex(index: number) {
    this.activitiesUtilService.selectedActivity = null;
    if (this.isActivityClosed(this.viewModelList[index].activityStatus)) {
      this.activitiesUtilService.selectedActivity = this.viewModelList[index];
      this.activitiesUtilService.selectedActivity.zip = this.equipmentItem.zip;
      this.activitiesUtilService.selectedActivity.street = this.equipmentItem.street;
      this.activitiesUtilService.selectedActivity.city = this.equipmentItem.city;
      this.activitiesUtilService.selectedActivity['state'] = this.equipmentItem.state;
    }

    this.selectedItem = this.viewModelList[index];
    this.selectedItemIndex = index;
    const state = this.currentStateName.endsWith('-history') ? 'history' : 'overview';
    if (state === 'history') {
      this.historyTab.nativeElement.click();
    } else {
      this.overviewTab.nativeElement.click();
    }
    this.browserStateService.setUserNavigation();
    this.router
      .navigate([this.selectedItem.ticketKey, state], {
        relativeTo: this.route
      })
      .then(() => {
        this.browserStateService.resetUserNavigation();
      });
  }

  onTabClick(key, state) {
    this.activitiesUtilService.navigateToTab(key, state, this.route);
  }

  showPlannedActivityStartDate(activity: ActivitiesViewModel) {
    return this.activitiesUtilService.showPlannedActivityStartDate(activity);
  }

  ngOnDestroy(): void {
    super.destroy();
    this.activitiesUtilService.selectedActivity = null;
  }

  refreshActivity() {
    this.refreshItemEventService.emitRefreshItem();
  }
}
