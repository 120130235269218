import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ImpersonationCommunicationService } from './core/component-communication-services/impersonation/impersonation-communication.service';
import { merge, Subscription } from 'rxjs';
import { UserRestService } from './core/rest-services/user-rest.service';
import { UserUtilService } from './core/services/user/user-util.service';
import { CountryConfigRestService } from './core/rest-services/country-config-rest.service';
import { BaseMainNavRoleCheck } from './core/base-class/base-main-nav-role-check';
import { EquipmentRestService } from './core/rest-services/equipment-rest.service';
import { StateService } from './core/services/state.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LifeNetUtilService } from './core/utils/life-net-util.service';
import { DomUtilService } from './core/utils/dom-util.service';
import { ImpersonationCacheService } from './core/services/cache/impersonation-cache.service';
import { BrowserStateService } from './core/services/browser-state.service';
import { ServiceMetricsRestService } from './core/rest-services/service-metrics-rest.service';
import { TicketsUtilService } from './core/services/tickets/tickets-util.service';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Equipment } from './core/models/equipment/equipment';
import { CheckPermissionOrRoleService } from './core/auth-guards/check-permission-or-role.service';

@Component({
  selector: 'hl-main-nav',
  templateUrl: './main-nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainNavComponent extends BaseMainNavRoleCheck
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  @ViewChild('navContainer', { static: false })
  navContainerEl: ElementRef;
  @ViewChild('navAddFunc', { static: false })
  navAddFuncEl: ElementRef;
  @ViewChild('navItemMore', { static: false })
  navItemMoreEl: ElementRef;
  @ViewChild('navBarList', { static: false })
  navBarListEl: ElementRef;
  @ViewChild('navBarListMore', { static: false })
  navBarListMoreEl: ElementRef;
  @ViewChild('navBarMoreToggle', { static: false })
  navBarMoreToggleEl: ElementRef;
  @ViewChild('navBarToggleBtn', { static: false })
  navBarToggleBtnEl: ElementRef;
  @ViewChild('createTicketModal', { static: false })
  createTicketModal;
  @ViewChild('createUploadModal', { static: false })
  createUploadModal;
  @ViewChild('createUploadModal1', { static: false })
  createUploadModal1;
  @ViewChild('reportModal', { static: false })
  reportModal;

  isNavbarOpen = false;
  isMoreDropdownOpen = false;
  selectedEquipment: string;
  splitUrl = [];

  // subscription
  showCreateTicketModalSubscription: Subscription;
  openCreateTicketModal = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calcWidth();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (
      !this.navBarMoreToggleEl.nativeElement.contains(event.target) &&
      this.isMoreDropdownOpen
    ) {
      this.isMoreDropdownOpen = false;
    }
    if (event.target.classList.contains('navigation-bar__link')) {
      this.isNavbarOpen = false;
    }
    this.calcWidth();
  }

  constructor(
    configService: CountryConfigRestService,
    userRestService: UserRestService,
    userUtilService: UserUtilService,
    metricsService: ServiceMetricsRestService,
    router: Router,
    state: StateService,
    equipmentRestService: EquipmentRestService,
    lifeNetUtilService: LifeNetUtilService,
    private route: ActivatedRoute,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private el: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private domService: DomUtilService,
    private ticketsUtilService: TicketsUtilService,
    browserStateService: BrowserStateService,
    cdr: ChangeDetectorRef,
    public checkPermissionOrRoleService: CheckPermissionOrRoleService
  ) {
    super(
      configService,
      userRestService,
      userUtilService,
      metricsService,
      router,
      state,
      equipmentRestService,
      lifeNetUtilService,
      browserStateService,
      cdr,
      checkPermissionOrRoleService
    );
  }

  ngOnInit() {
    // all initialization is done in Base Class
    this.init();
    // get selected equipment to select correct equipment in create ticket
    this.setSelectedEquipment(this.router.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setSelectedEquipment(event.url);
      }
    });
    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  setSelectedEquipment(url: string) {
    this.splitUrl = url.split('/');
    if (this.splitUrl.length > 2 && this.splitUrl[1] === 'equipment') {
      this.selectedEquipment = this.splitUrl[2];
      this.changeDetectorRef.detectChanges();
    } else {
      this.selectedEquipment = '';
      this.changeDetectorRef.detectChanges();
    }
    if (this.openCreateTicketModal && this.selectedEquipment) {
      this.createTicketModal.preselectEquipmentInDeeplink(this.selectedEquipment);
    }
  }

  setSelectedEquipmentFromEquipmentIdentifier(equipmentIdentifier: string) {
    this.equipmentRestService.getEquipment().subscribe((items: Equipment[]) => {
      for (const eq of items) {
        if (eq.siemensId === equipmentIdentifier) {
          this.selectedEquipment = eq.key;
          this.changeDetectorRef.detectChanges();
          this.createTicketModal.preselectEquipmentInDeeplink(this.selectedEquipment);
          return;
        }
      }
    });
  }

  setPropertiesFromQueryParams() {
    this.route.queryParams.subscribe(params => {
      const createTicketModal = params['createTicket'];
      if (this.checkPermissionOrRoleService.createTicketRole && createTicketModal) {
        this.openCreateTicketModal = true;
        if (params['equipmentIdentifier']) {
          this.setSelectedEquipmentFromEquipmentIdentifier(params['equipmentIdentifier']);
        }
        const url = this.router.url;
        this.router.navigateByUrl(url.substring(0, url.indexOf('?')));
      }
    });
  }

  tryOpenCreateTicketModal() {
    if (this.openCreateTicketModal) {
      try {
        this.createTicketModal.show();
        this.openCreateTicketModal = false;
      } catch (e) {}
    }
  }

  ngAfterViewInit(): void {
    this.checkPermissionOrRoleService.isLoadedSubject.subscribe(isLoaded => {
      if (isLoaded) {
        this.changeDetectorRef.detectChanges();
        this.calcWidth();
      }
    });
  }

  ngAfterViewChecked(): void {
    this.calcWidth();
    this.tryOpenCreateTicketModal();
  }

  ngOnDestroy() {
    if (this.showCreateTicketModalSubscription) {
      this.showCreateTicketModalSubscription.unsubscribe();
    }
    super.destroy();
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    merge(
      this.impersonationCommunicationService.onCountryLanguageChange$,
      this.impersonationCommunicationService.onImpersonationChange$
    )
    .pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.checkPermissionOrRoleService.isLoaded = false;
        this.moveAllElementsToNavbar();
        this.init();
      }
    );

    this.stateService.getActiveRouteTitle().subscribe(titleName => {
      if (titleName) {
        this.headerTranslateLabel = titleName;
      }
    });

    // BugFix 5156: avoid create ticket modal reappearing by adding 'take' function with '1' arg
    // in order to emit the event only once
    this.showCreateTicketModalSubscription = this.ticketsUtilService.onShowCreateTicketModal$.pipe(take(1)).subscribe(
      equipmentKey => {
        this.createTicketModal.showModalAndSetFields(equipmentKey);
      }
    );
  }

  toggleDropdown() {
    this.isMoreDropdownOpen = !this.isMoreDropdownOpen;
  }

  calcWidth() {
    if (!this.checkPermissionOrRoleService.isLoaded) {
      return;
    }

    // TODO consider another way to find out if navbar in mobile mode, this one is ugly
    if (
      getComputedStyle(this.navBarToggleBtnEl.nativeElement).display !== 'none'
    ) {
      this.moveAllElementsToNavbar();
      this.renderer.addClass(this.navItemMoreEl.nativeElement, 'hidden');
      return;
    }

    const containerStyle = getComputedStyle(this.navContainerEl.nativeElement);
    const containerWidth =
      this.navContainerEl.nativeElement.offsetWidth -
      parseFloat(containerStyle.paddingLeft) -
      parseFloat(containerStyle.paddingRight) -
      this.navAddFuncEl.nativeElement.offsetWidth;

    const moreEl = this.navItemMoreEl.nativeElement;
    const moreWidth = this.domService.outerWidthWithMargin(moreEl);
    const menuListEl = this.el.nativeElement.querySelectorAll(
      '.navigation-bar__item:not(.more):not(.navigation-bar__item-more)'
    );
    const listMore = this.navBarListMoreEl.nativeElement;

    let navWidth = 0;
    menuListEl.forEach(el => {
      navWidth += this.domService.outerWidthWithMargin(el);
    });

    if (navWidth + moreWidth >= containerWidth) {
      if (menuListEl.length > 0) {
        const lastItem = menuListEl[menuListEl.length - 1];
        this.renderer.setAttribute(
          lastItem,
          'width',
          this.domService.outerWidthWithMargin(lastItem).toString()
        );
        this.moveElementToMoreList(lastItem);
        this.calcWidth();
      }
    } else {
      const firstMoreElement = listMore.querySelector('.more');
      const moreElementCount = listMore.querySelectorAll('.more').length;

      if (firstMoreElement) {
        const firstMoreElementWidth = parseFloat(
          firstMoreElement.getAttribute('width')
        );

        let widthOfNewNav = navWidth + firstMoreElementWidth;
        if (moreElementCount > 1) {
          widthOfNewNav += moreWidth;
        }

        if (widthOfNewNav < containerWidth) {
          this.moveElementToNavbar(firstMoreElement);
          if (moreElementCount > 1) {
            this.calcWidth();
          }
        }
      }
    }

    if (moreEl.querySelectorAll('.menu__item').length > 0) {
      this.renderer.removeClass(moreEl, 'hidden');
    } else {
      this.renderer.addClass(moreEl, 'hidden');
    }

    if (this.navBarListMoreEl.nativeElement.querySelectorAll('.is-active').length > 0) {
      this.renderer.addClass(this.navBarMoreToggleEl.nativeElement, 'is-active');
    } else {
      this.renderer.removeClass(this.navBarMoreToggleEl.nativeElement, 'is-active');
    }
  }

  moveAllElementsToNavbar() {
    const listMore = this.navBarListMoreEl.nativeElement;
    const moreElements = listMore.querySelectorAll('.more');

    moreElements.forEach(el => {
      this.moveElementToNavbar(el);
    });
  }

  moveElementToNavbar(el) {
    const list = this.navBarListEl.nativeElement;
    const moreEl = this.navItemMoreEl.nativeElement;

    this.renderer.removeClass(el, 'more');
    this.renderer.removeClass(el, 'margin-0');
    this.renderer.removeClass(el.querySelector('a'), 'menu__item');
    this.renderer.addClass(el, 'navigation-bar__item');
    this.renderer.addClass(el.querySelector('a'), 'navigation-bar__link');
    this.renderer.insertBefore(list, el, moreEl);
  }

  moveElementToMoreList(el) {
    const listMore = this.navBarListMoreEl.nativeElement;

    this.renderer.addClass(el, 'more');
    this.renderer.addClass(el, 'margin-0');
    this.renderer.addClass(el.querySelector('a'), 'menu__item');
    this.renderer.removeClass(el, 'navigation-bar__item');
    this.renderer.removeClass(el.querySelector('a'), 'navigation-bar__link');

    const firstMoreElement = listMore.querySelector('.more');
    if (firstMoreElement) {
      this.renderer.insertBefore(listMore, el, firstMoreElement);
    } else {
      this.renderer.appendChild(listMore, el);
    }
  }

  toggleNavbar() {
    this.isNavbarOpen = !this.isNavbarOpen;
  }
}
