import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hl-progress-indicator',
  templateUrl: './progress-indicator.component.html'
})
export class ProgressIndicatorComponent implements OnInit {

  @Input() loaded: boolean;
  @Input() showLabel: boolean;
  @Input() label: string;

  constructor() { }

  ngOnInit() {
  }

}
