import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { Equipment } from '../../../core/models/equipment/equipment';
import { Subscription } from 'rxjs';
import { AddRemoveEquipmentEventService } from '../../../core/component-communication-services/add-remove-equipment-event/add-remove-equipment-event.service';

@Component({
  selector: 'hl-is-my-equipment',
  templateUrl: './is-my-equipment.component.html'
})
export class IsMyEquipmentComponent implements OnInit, OnDestroy {

  private _key: string;
  private addRemoveEquipmentEventSubscription: Subscription;
  private isButtonDisabled = false;

  get key(): string {
    return this._key;
  }

  @Input()
  set key(key: string) {
    this._key = key;
    this.checkState();
  }

  @Input()
  isVisible = false;

  equipment: Equipment;

  constructor(
    private equipmentRestService: EquipmentRestService,
    private addRemoveEquipmentEvent: AddRemoveEquipmentEventService
  ) { }

  ngOnInit(): void {
    this.addRemoveEquipmentEventSubscription = this.addRemoveEquipmentEvent.addRemoveEquipmentSource$.subscribe(
      (arg?: any) => {
        if (this._key === arg['key']) {
          this.isVisible = !this.isVisible;
          this.isButtonDisabled = false;
        }
      }
    );
  }

  checkState() {
    this.isVisible = false;
    this.equipment = {} as Equipment;
    this.equipment.key = this.key;
    this.equipmentRestService.getMyProfile().subscribe((myProfileResponse) => {
      if (_.includes(myProfileResponse.equipment, this.key)) {
        this.isVisible = true;
      }
    });
  }

  disableButton(): void {
    this.isButtonDisabled = true;
  }

  ngOnDestroy(): void {
    if (this.addRemoveEquipmentEventSubscription) {
      this.addRemoveEquipmentEventSubscription.unsubscribe();
    }
  }

}
