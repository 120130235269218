import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { WelcomeComponent } from './welcome.component';

const welcomeRoutes: Routes = [
    {
        path: 'welcome',
        component: WelcomeComponent,
        data: { stateName: 'welcome'}
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(welcomeRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class WelcomeRoutingModule { }
