
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerContacts } from '../../../core/models/customer/customer-contacts';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { User } from '../../../core/models/user';
import { TicketsRestService } from '../../../core/rest-services/tickets-rest.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';

let instance = 0;
@Component({
  selector: 'hl-contact-area',
  templateUrl: './contact-area.component.html'
})
export class ContactAreaComponent implements OnInit, OnChanges {
  // we will pass in detail from Create component
  @Input()
  contactForm: FormGroup;
  @Input()
  isFormSubmitted: boolean;
  @Input()
  isDisabled: boolean;
  @Input()
  labelContact = 'TICKET_LABEL_CONTACT';
  @Input()
  showAdditionalContacts = true;
  @Input()
  loadUserInfo = true;

  id: number;
  // initially selected additional contact is null
  selectedContactInfo = null;

  salutationLabel = '';
  titleLabel = '';

  ticketSalutations: DropdownOptions[] = [];
  ticketTitles: DropdownOptions[] = [];
  ticketAdditionalContacts: DropdownOptions[] = [];

  constructor(
    private ticketsRestService: TicketsRestService,
    private ticketsUtilService: TicketsUtilService,
    private userRestService: UserRestService,
    private cd: ChangeDetectorRef
  ) {
    // have different ids when the component is used more times in one place
    this.id = ++instance;
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contactForm'] && !changes['contactForm'].firstChange) {
      this.init();
      this.cd.detectChanges();
    }
  }

  init() {
    this.loadUserInfoAndMapSalutationTitle();
  }

  changeAdditionalContact() {
    // selectedContactInfo will be full object
    // https://stackoverflow.com/a/35945293
    if (!_.isEmpty(this.selectedContactInfo)) {
      this.setFormControlValues(this.selectedContactInfo);
    }
  }

  /**
   *
   * @description
   * Get the user information.
   * Note:- map the corresponding id for salutation/title to be used in form data.
   * This is required for SAP PI-> BE.
   */
  loadUserInfoAndMapSalutationTitle() {
    this.selectedContactInfo = null;
    const user$ = this.userRestService.getUser();
    const salutations$ = this.ticketsRestService
      .getContactsSalutation().pipe(
        map(ticketSalutations => ticketSalutations.map(ticketSalutation => ({
          value: ticketSalutation.salutationId,
          title: ticketSalutation.salutationDescription
        })))
      );
    const titles$ = this.ticketsRestService
      .getContactsTitle().pipe(
        map(ticketTitles => ticketTitles.map(ticketTitle => ({
          value: ticketTitle.titleId,
          title: ticketTitle.titleDescription
        })))
      );
    const additionalContact$ = this.ticketsUtilService.getMappedAdditionalContacts();

    forkJoin(
      user$,
      salutations$,
      titles$,
      additionalContact$
    ).subscribe(responses => {
      const user = responses[0];

      // assign the allowed salutations
      this.ticketSalutations = responses[1];

      // assign the allowed titles
      this.ticketTitles = responses[2];

      // assign the additional contacts
      this.ticketAdditionalContacts = responses[3];

      // Note:- Call at the end so that ticket titles and ticket salutation are set, before use inside
      // setFormControlValues
      if (this.loadUserInfo) {
        this.setFormControlValues(user);
      }
      this.cd.detectChanges();
    });
  }

  /**
   * @description Set the form control name values
   * @param contact | this can be user info during initial load otherwise the customer contacts when
   * changing additional contacts
   */
  setFormControlValues(contact: User | CustomerContacts) {
    this.salutationLabel = contact.salutation;
    this.titleLabel = contact.title;

    // do the mapping for salutation as required for SAP PI
    const salutationMappedVal = this.ticketsUtilService.getMappedPropertyIdDataset(
      this.ticketSalutations,
      'salutationDescription',
      contact.salutation,
      'salutationId'
    );

    // do the mapping for title as required for SAP PI
    const titleMappedVal = this.ticketsUtilService.getMappedPropertyIdDataset(
      this.ticketTitles,
      'titleDescription',
      contact.title,
      'titleId'
    );

    // set the form control name values
    this.contactForm.patchValue({
      contactFirstName: contact.firstName,
      contactLastName: contact.lastName,
      contactEmail: contact.email,
      contactPhone: contact.phone,
      contactSalutation: salutationMappedVal,
      contactTitle: titleMappedVal
    });
  }
}
