import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class UpdateEquipmentEventService {

  // Observable updateEquipment sources
  private updateEquipmentSource = new Subject<any>();

  // Observable updateEquipment streams
  updateEquipmentSourceSource$ = this.updateEquipmentSource.asObservable();

  constructor() { }

  // Service updateEquipment commands
  emitEquipmentUpdated() {
    this.updateEquipmentSource.next();
  }

}
