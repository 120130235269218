import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const TIMEPICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimePickerComponent),
  multi: true
};

@Component({
  selector: 'hl-time-picker',
  templateUrl: './time-picker.component.html',
  providers: [TIMEPICKER_VALUE_ACCESSOR]
})
export class TimePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  disabled = false;
  @Input()
  labelText: string;
  @Input()
  timeModel: Date | String = null;
  @Output()
  timeModelChange: EventEmitter<String> = new EventEmitter<String>();

  // Auto convert the timeModel value from a string to a date
  @Input()
  convertModelValue = false;

  @Input()
  ngModel;

  @Input()
  isRequired = false;

  @Input()
  appendToElement: HTMLElement;

  timePattern = 'HH:mm'; // default pattern
  timeId =
    'time_' +
    Math.random()
      .toString(36)
      .substring(2);

  private unsubscribe$: Subject<void> = new Subject();

  constructor(private configService: CountryConfigRestService) {
  }

  ngOnInit() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.timePattern = configResponse.GENERIC_TIME_PATTERN;

      if (this.convertModelValue && typeof this.timeModel === 'string') {
        this.timeModel = moment(this.timeModel, this.timePattern).toDate();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onTimePickerSelect(newValue) {
    this.timeModel = this.convertModelValue
      ? moment(newValue, this.timePattern).toDate()
      : newValue;
    this.timeModelChange.emit(newValue);
    this.newTimeSet();
  }

  formatDate = (date: any): string => {
    return moment(date).format(this.timePattern);
  }

  // called, when this component decides, that there is a new date picked or entered
  newTimeSet(): void {
    this.onModelChange(this.timeModel);
    this.onModelTouched(this.timeModel);
  }

  onModelChange: Function = (_: any) => {
  }
  onModelTouched: Function = () => {
  }

  /**
   * called if the value of the parent component changes
   * @param value
   */
  writeValue(value: any): void {
    if (value) {
      this.timeModel = this.convertModelValue
        ? moment(value, this.timePattern).toDate()
        : value;
      this.newTimeSet();
    } else {
      this.timeModel = null;
      this.newTimeSet();
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
