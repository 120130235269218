import { Injectable } from '@angular/core';
import { UpdateStatusColor } from '../../view-models/system-update-base-view-model';
import { SortSkeleton } from '../../../shared/sorting/sort-skeleton';
import { SortDirection } from '../../../shared/sorting/sort-object';

@Injectable()
export class SystemUpdatesConstantsService {

  constructor() {
  }

  getUpdateStatesSkeleton() {
    return [{
      title: 'SYSTEM_UPDATES_STATUS_PENDING',
      value: 'PENDING',
      color: UpdateStatusColor.Green.toString()
    }, {
      title: 'SYSTEM_UPDATES_STATUS_OVERDUE',
      value: 'OVERDUE',
      color: UpdateStatusColor.Yellow.toString()
    }, {
      title: 'SYSTEM_UPDATES_STATUS_LONG_OVERDUE',
      value: 'LONG_OVERDUE',
      color: UpdateStatusColor.Red.toString()
    }, {
      title: 'SYSTEM_UPDATES_STATUS_COMPLETED',
      value: 'COMPLETED',
      color: UpdateStatusColor.Grey.toString()
    }];
  }

  getEquipmentUpdateStatusesSkeleton() {
    return [{
      title: 'SYSTEM_UPDATES_EQ_STATUS_PENDING',
      value: 'PENDING',
      color: UpdateStatusColor.Green.toString()
    }, {
      title: 'SYSTEM_UPDATES_EQ_STATUS_OVERDUE',
      value: 'OVERDUE',
      color: UpdateStatusColor.Yellow.toString()
    }, {
      title: 'SYSTEM_UPDATES_EQ_STATUS_LONG_OVERDUE',
      value: 'LONG_OVERDUE',
      color: UpdateStatusColor.Red.toString()
    }, {
      title: 'SYSTEM_UPDATES_EQ_STATUS_REFUSED',
      value: 'REFUSED',
      color: UpdateStatusColor.Grey.toString()
    }, {
      title: 'SYSTEM_UPDATES_EQ_STATUS_FAILED',
      value: 'FAILED',
      color: UpdateStatusColor.Grey.toString()
    }, {
      title: 'SYSTEM_UPDATES_EQ_STATUS_PERFORMED',
      value: 'PERFORMED',
      color: UpdateStatusColor.Grey.toString()
    }, {
      title: 'SYSTEM_UPDATES_EQ_STATUS_NO_ACTION_REQUIRED',
      value: 'NO_ACTION_REQUIRED',
      color: UpdateStatusColor.Grey.toString()
    }];
  }

  getSystemUpdatesSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'title',
        sortDir: SortDirection.DESC
      },
      items: [{
        title: 'GENERIC_LABEL_OPTION_DOWNLOAD_TITLE',
        value: 'title'
      }, {
        title: 'GENERIC_LABEL_UPDATE_STATUS',
        value: 'updateStatusRank'
      }]
    };
  }

  getEquipmentSysUpdatesSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'dueDate',
        sortDir: SortDirection.ASC
      },
      items: [{
        title: 'GENERIC_LABEL_OPTION_DOWNLOAD_TITLE',
        value: 'title'
      }, {
        title: 'LABEL_DUE_DATE',
        value: 'dueDate'
      }]
    };
  }

  getAsuStatusSkeleton() {
    return [{
      title: 'ACFM',
      value: 'ACFM'
    }, {
      title: 'APER',
      value: 'APER'
    }, {
      title: 'AIFA',
      value: 'AIFA'
    }];
  }
}
