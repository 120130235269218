import { Component, OnInit } from '@angular/core';
import { SecurityAdvisory } from 'app/core/models/security-advisory';
import { SecurityAdvisoriesRestService } from 'app/core/rest-services/security-advisories-rest.service';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';

@Component({
  selector: 'hl-security-advisories-widget',
  templateUrl: './security-advisories-widget.component.html'
})
export class SecurityAdvisoriesWidgetComponent implements OnInit {
  securityAdvisories$: Observable<SecurityAdvisory[]>;
  datePattern = '';

  constructor(private securityAdvisoryRestService: SecurityAdvisoriesRestService,
    private countryConfigRestService: CountryConfigRestService) {
  }

  ngOnInit() {
    this.datePattern = 'DDMMYYYY';
    this.securityAdvisories$ = this.countryConfigRestService.getConfig().pipe(
      flatMap(config => {
          this.datePattern = config.GENERIC_DATE_PATTERN;
          return this.securityAdvisoryRestService.getActiveSecurityAdvisoriesWithLimit(
            config.DASHBOARD_SECURITY_ADVISORIES_COUNT);
        }
      )
    );
  }
}
