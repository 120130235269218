import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentNode } from '../models/equipment/documents/document-node';
import { DatePipeWrapperPipe } from '../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { SkbDetails } from '../models/equipment/documents/documentDetails/skb-details';
import { environment } from '../../../environments/environment.prod';
import { WindowService } from '../window.service';

@Injectable()
export class SkbDocumentGeneratorService {

  constructor(private client: HttpClient,
    private datePipe: DatePipeWrapperPipe,
    private windowService: WindowService) {
  }

  setImageSources(skbDetails: SkbDetails): SkbDetails {
    const details = skbDetails;
    const images = skbDetails.images;

    if (images && Object.entries(images).length > 0) {
      Object.entries(details)
        .filter(([, value]) => typeof value === 'string')
        .forEach(([detailKey, value]) => {
          const result = value.match(/fileId=([^\\"]+)/);

          if (result) {
            result.forEach(r => {
              const fileIdAtt = r.split('=');
              const fileId = Object.keys(images).find(key => key === fileIdAtt[1]);

              if (fileId) {
                value = value.replace(r, images[fileId]);
                details[detailKey] = value;
              }
            });
          }
        });
    }

    return details;
  }

  generate(documentNode: DocumentNode) {
    const skbTemplateStyle = 'assets/data/templates/skb-document-template.styles.css';
    const skbTemplateIcon = 'assets/images/favicon.png';
    const templateStyling = this.windowService.nativeWindow.location.origin + '/' + (environment.version || '') + skbTemplateStyle;
    const templateIcon = this.windowService.nativeWindow.location.origin + '/' + (environment.version || '') + skbTemplateIcon;

    documentNode.details = this.setImageSources(documentNode.details);

    this.client.get(environment.version + 'assets/data/templates/skb-document-template.html',
      {responseType: 'text'})
      .subscribe(t => {
        t = t.replace('#name', documentNode.name);
        t = t.replace('#docId', documentNode.documentId);
        t = t.replace('#softwareVersion', documentNode.details.softwareVersions);
        t = t.replace('#appliesTo', documentNode.details.appliesTo);
        t = t.replace('#products', documentNode.details.products);
        t = t.replace('#description', documentNode.details.description);
        t = t.replace('#rootCause', documentNode.details.rootCause);
        t = t.replace('#resolution', documentNode.details.resolution);
        t = t.replace('#infoForCustomer', documentNode.details.informationForCustomer);
        t = t.replace('#date', this.datePipe.transform(new Date(), 'DD.MM.YYYY'));

        t = t.replace('#templateStyling', templateStyling);
        t = t.replace('#templateIcon', templateIcon);

        const blob = new Blob([t], {type: 'text/html'});
        const blobURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = blobURL;
        downloadLink.setAttribute('target', '_blank');
        document.body.appendChild(downloadLink);

        setTimeout(() => {
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }, null);
      });
  }
}
