import { ContractsEquipmentUsage } from '../app/core/models/contracts/contracts-equipment-usage';

const currentDate = new Date();
const currentMonth = currentDate.getUTCMonth();

const monthlyExamCountSeriesPast = [223, 171, 101, 299, 405, 257, 240, 375, 241, 120, 250, 247];
const cumulativeExamCountSeriesPast = [];
monthlyExamCountSeriesPast.reduce((a,b,i) => { return cumulativeExamCountSeriesPast[i] = a + b; }, 0);

const monthlyExamCountSeries = [123, 471, 121, 292, 325, 112, 140, 289, 201, 220, 150, 90].map((el, c) => c < currentMonth ? el : 0);
let cumulativeExamCountSeries = [];
monthlyExamCountSeries.reduce((a,b,i) => { return cumulativeExamCountSeries[i] = a + b; }, 0);
cumulativeExamCountSeries = cumulativeExamCountSeries.filter((_el, c) => c < currentMonth);

const annualExamAllowanceSeries = [3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000];
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const CdEqUsage: ContractsEquipmentUsage[] = [
  {
    monthlyExamCountSeries: monthNames.map((el, c) => { return { name: el, value: monthlyExamCountSeriesPast[c] } }),
    cumulativeExamCountSeries: monthNames.map((el, c) => { return { name: el, value: cumulativeExamCountSeriesPast[c] } }),
    annualExamAllowanceSeries: monthNames.map((el, c) => { return { name: el, value: annualExamAllowanceSeries[c] } }),
    contractStartDay: `${currentDate.getUTCFullYear()-1}-01-01` 
  },
  {
    monthlyExamCountSeries: monthNames.map((el, c) => { return { name: el, value: monthlyExamCountSeries[c] } }),
    cumulativeExamCountSeries: cumulativeExamCountSeries.map((el, c) => { return { name: monthNames[c], value: el } }),
    annualExamAllowanceSeries: monthNames.map((el, c) => { return { name: el, value: annualExamAllowanceSeries[c] } }),
    contractStartDay: `${currentDate.getUTCFullYear()}-01-01` 
  },
];
