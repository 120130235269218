import { Component, EventEmitter, Output, Renderer2 } from '@angular/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';

@Component({
  selector: 'hl-state-down-modal',
  templateUrl: './state-down-modal.component.html'
})
export class StateDownModalComponent extends BaseModalPopup {

  @Output() onClickOk = new EventEmitter<any>();

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  ok() {
    this.hide();
    this.onClickOk.emit();
  }
}
