import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { DomUtilService } from '../../../core/utils/dom-util.service';

@Component({
  selector: 'hl-tabs',
  templateUrl: './tabs.component.html'
})
export class TabsComponent implements OnInit, AfterViewInit, OnDestroy {
  private items: any;
  @Input()
  isMyInstitutions = false;
  private isDropdownOpen = false;
  private eventListeners = [];
  @Input()
  moreEnabled = false;
  routerEvents$: Subscription;
  private menuItemClassName = 'menu__item';
  private menuItemClassSelector = '.' + this.menuItemClassName;
  private tabsItemClassName = 'tabs__item';
  private tabsItemClassSelector = '.' + this.tabsItemClassName;

  @ViewChild('tabsContainer', {static: false})
  tabsContainerEl: ElementRef;
  @ViewChild('tabsItemMore', {static: false})
  tabsItemMoreEl: ElementRef;
  @ViewChild('tabsList', {static: false})
  tabsListEl: ElementRef;
  @ViewChild('tabsListMore', {static: false})
  tabsListMoreEl: ElementRef;
  @ViewChild('tabsToggleMore', {static: false})
  tabsToggleMoreEl: ElementRef;
  @ViewChild('tabsActiveLine', {static: false})
  tabsActiveLineEl: ElementRef;

  private observer: MutationObserver;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (this.moreEnabled) {
      this.calcWidth();
    }
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (
      this.moreEnabled && this.isDropdownOpen &&
      !this.tabsToggleMoreEl.nativeElement.contains(event.target)
    ) {
      this.isDropdownOpen = false;
    }
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private domService: DomUtilService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.routerEvents$ = this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        setTimeout(() => {
          this.initActiveItem();
        }, 100);
      }
    });
  }

  ngAfterViewInit() {
    if (this.moreEnabled) {
      this.calcWidth();
    }

    this.items = this.el.nativeElement.querySelectorAll(
      this.tabsItemClassSelector + ':not(.dropdown), ' + this.menuItemClassSelector
    );

    this.items.forEach(el => {
      const clickListener = this.renderer.listen(el, 'click', event => {
        this.setActiveLine(el);
      });
      this.eventListeners.push(clickListener);
    });

    setTimeout(() => {
      this.initActiveItem();
    }, 100);
  }

  ngOnDestroy() {
    for (const listener of this.eventListeners) {
      listener();
    }
    this.routerEvents$.unsubscribe();
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  initActiveItemTimeout() {
    setTimeout(() => {
      this.initActiveItem();
    }, 1000);
  }
  
  initActiveItem() {
    const activeItem = this.el.nativeElement.querySelector('.is-active');
    if (activeItem) {
      this.setActiveLine(activeItem);
    }
  }

  setActiveLine(item) {
    if (item.classList.contains(this.menuItemClassName)) {
      item = this.tabsItemMoreEl.nativeElement;
    }
    const width = item.offsetWidth;
    const left = item.offsetLeft;
    this.renderer.setStyle(
      this.tabsActiveLineEl.nativeElement,
      'width',
      width + 'px'
    );
    this.renderer.setStyle(
      this.tabsActiveLineEl.nativeElement,
      'left',
      left + 'px'
    );
  }

  toggleDropdown(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  calcWidth() {
    const containerStyle = getComputedStyle(this.tabsContainerEl.nativeElement);
    const containerWidth =
      this.tabsContainerEl.nativeElement.offsetWidth -
      parseFloat(containerStyle.paddingLeft) -
      parseFloat(containerStyle.paddingRight);
    const moreEl = this.tabsItemMoreEl.nativeElement;
    const moreWidth = this.domService.outerWidthWithMargin(moreEl);
    let menuListEl = this.el.nativeElement.querySelectorAll(
      this.tabsItemClassSelector + ':not(' + this.menuItemClassSelector + '):not(.dropdown)'
    );
    const list = this.tabsListEl.nativeElement;
    const listMore = this.tabsListMoreEl.nativeElement;

    let navWidth = 0;
    menuListEl.forEach(el => {
      navWidth += this.domService.outerWidthWithMargin(el);
    });
    if (navWidth + moreWidth >= containerWidth) {
      if (menuListEl.length > 0) {
        const lastItem = menuListEl[menuListEl.length - 1];

        this.renderer.setAttribute(
          lastItem,
          'width',
          this.domService.outerWidthWithMargin(lastItem).toString()
        );
        this.renderer.removeClass(lastItem, this.tabsItemClassName);
        this.renderer.addClass(lastItem, this.menuItemClassName);

        const firstMoreElement = listMore.querySelector(this.menuItemClassSelector);
        if (firstMoreElement) {
          this.renderer.insertBefore(listMore, lastItem, firstMoreElement);
        } else {
          this.renderer.appendChild(listMore, lastItem);
        }

        this.calcWidth();
        this.initActiveItem();
      }
    } else {
      let firstMoreElement = listMore.querySelector(this.menuItemClassSelector);
      const firstMoreElementCount = listMore.querySelectorAll(this.menuItemClassSelector)
        .length;

      if (firstMoreElement) {
        const firstMoreElementWidth = parseFloat(
          firstMoreElement.getAttribute('width')
        );
        let widthOfNewNav = navWidth + firstMoreElementWidth;
        if (firstMoreElementCount > 1) {
          widthOfNewNav += moreWidth;
        }

        if (widthOfNewNav < containerWidth) {
          this.renderer.removeClass(firstMoreElement, this.menuItemClassName);
          this.renderer.addClass(firstMoreElement, this.tabsItemClassName);
          this.renderer.insertBefore(list, firstMoreElement, moreEl);
          firstMoreElement = listMore.querySelector(this.menuItemClassSelector);
          this.initActiveItem();
        }
        menuListEl = this.el.nativeElement.querySelectorAll(
          this.tabsItemClassSelector + ':not(' + this.menuItemClassSelector + '):not(.dropdown)'
        );

        let tabsWidth = 0;
        menuListEl.forEach(el => {
          tabsWidth += this.domService.outerWidthWithMargin(el);
        });

        const dropdownRightClass = 'dropdown--right';
        const dropdownLeftClass = 'dropdown--right-unused';
        if (firstMoreElement != null) {
          if (tabsWidth + parseInt(firstMoreElement.getAttribute('width')) >
            this.domService.outerWidthWithMargin(this.tabsContainerEl.nativeElement)) {
            this.renderer.removeClass(this.tabsItemMoreEl.nativeElement, dropdownLeftClass);
            this.renderer.addClass(this.tabsItemMoreEl.nativeElement, dropdownRightClass);
          } else {
            this.renderer.removeClass(this.tabsItemMoreEl.nativeElement, dropdownRightClass);
            this.renderer.addClass(this.tabsItemMoreEl.nativeElement, dropdownLeftClass);
          }
        }
      }

      if (!this.observer) {
        this.observer = new MutationObserver(() => this.calcWidth());

        this.observer.observe(list, {
          childList: true
        });
      }
    }

    if (moreEl.querySelectorAll(this.menuItemClassSelector).length > 0) {
      this.renderer.removeClass(moreEl, 'hidden');
    } else {
      this.renderer.addClass(moreEl, 'hidden');
    }
  }
}
