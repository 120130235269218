import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WindowService } from '../core/window.service';

@Component({
  selector: 'hl-equipment-details',
  template: ''
})
export class EquipmentDetailsComponent implements OnInit {

  private readonly DEEP_LINK_TYPE_PARAM_PART = '&deepLinkType=';

  constructor(private route: ActivatedRoute, private windowService: WindowService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const siemensEquipmentId = this.findKey('siemensEquipmentId', params);
      const materialNo = this.findKey('materialNo', params);
      const serialNo = this.findKey('serialNo', params);
      const country = this.findKey('country', params);
      const eosyear = this.findKey('eosyear', params);
      const createTicket = this.findKey('createTicket', params);
      const advisoryId = this.findKey('advisoryId', params);

      if (eosyear) {
        this.windowService.nativeWindow.location.replace(`/rest/v1/deepLink?eosyear=${params[eosyear]}${this.DEEP_LINK_TYPE_PARAM_PART}equipment_eos`);
      } else if (advisoryId) {
        this.windowService.nativeWindow.location.replace(`/rest/v1/deepLink?id=${params[advisoryId]}${this.DEEP_LINK_TYPE_PARAM_PART}security_advisory`);
      } else if (((!!materialNo && !!serialNo) || !!siemensEquipmentId) && country) {
        this.windowService.nativeWindow.location.replace('/rest/v1/deepLink' + this.makeCaseInsensitiveUrlParams(params) + `${this.DEEP_LINK_TYPE_PARAM_PART}equipment_self`);
      } else if (createTicket) {
        this.windowService.nativeWindow.location.replace(`/rest/v1/deepLink?createTicket=${params[createTicket]}${this.DEEP_LINK_TYPE_PARAM_PART}create_ticket`);
      } else {
        this.windowService.nativeWindow.location.replace('/');
      }
    });
  }

  private makeCaseInsensitiveUrlParams(params): string {
    const siemensEquipmentId = this.findKey('siemensEquipmentId', params);
    const materialNo = this.findKey('materialNo', params);
    const serialNo = this.findKey('serialNo', params);
    const country = this.findKey('country', params);
    const customer = this.findKey('customer', params);
    const search = this.findKey('search', params);
    const tab = this.findKey('tab', params);
    const createTicket = this.findKey('createTicket', params);

    let searchString = '?';
    if (materialNo) {
      searchString += 'materialNo=' + params[materialNo] + '&';
    }
    if (serialNo) {
      searchString += 'serialNo=' + params[serialNo] + '&';
    }
    if (siemensEquipmentId) {
      searchString += 'siemensEquipmentId=' + params[siemensEquipmentId] + '&';
    }
    if (country) {
      searchString += 'country=' + params[country] + '&';
    }

    if (customer) {
      searchString += 'customer=' + params[customer] + '&';
    }

    if (search) {
      searchString += 'search=' + params[search] + '&';
    }

    if (tab) {
      searchString += 'tab=' + params[tab] + '&';
    }

    if (createTicket) {
      searchString += 'createTicket=' + params[createTicket] + '&';
    }

    return searchString.slice(0, -1);

  }

  private findKey(find, obj) {
    if (obj.constructor === Object) {
      obj = Object.keys(obj);
    }
    for (let i = 0; i < obj.length; i++) {
      if (find.toLowerCase() === obj[i].toLowerCase()) {
        return obj[i];
      }
    }
    return false;
  }
}
