import { Component, ViewChild } from '@angular/core';
import { CreateUserNotificationsPageComponent } from '../../shared/overlays/create-user-notifications-page/create-user-notifications-page.component';

@Component({
  selector: 'hl-notifications-tab',
  templateUrl: './notifications-tab.component.html'
})
export class NotificationsTabComponent {
  @ViewChild('notificationsPage', {static: false}) notificationsPage: CreateUserNotificationsPageComponent;
}
