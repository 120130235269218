import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const ClassNames = {
  CURRENT: 'is-selected',
  FILLED: 'has-results'
};

const Keycode = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13
};

@Component({
  selector: 'hl-search-field',
  templateUrl: './search-field.component.html'
})
export class SearchFieldComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  protected searchEventEmitter: EventEmitter<any> = new EventEmitter();

  @Input()
  autocomplete = false;
  @Input()
  autocompleteMinLength = 1;
  @Input()
  autocompleteWaitMs = 200;
  @Input()
  autocompleteItems$: Observable<any>;
  @Output()
  autocompleteOnSelect: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  autocompleteItemTemplate: TemplateRef<any>;
  @Input()
  useCollection = false;
  @Input()
  statusColorMap: any;
  @Input()
  searchFieldClasses: any;
  @Input()
  placeholder: string;
  @Input()
  searchInput: string;
  @Input()
  dataCy: string;
  @Output()
  searchInputChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('searchContainer', { static: false })
  searchContainerEl: ElementRef;
  @ViewChild('autocompleteMenu', { static: false })
  autocompleteMenuEl: ElementRef;
  @ViewChild('searchInputField', { static: false })
  searchInputEl: ElementRef;

  currentSelectedItemNum = -1;
  autocompleteItems = [];
  private allItems = [];
  private readonly searchLimit = 5;
  private numberOfItemsToShow = 0;

  constructor(private renderer: Renderer2,
    private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.autocomplete) {
      this._subscriptions.push(
        this.autocompleteItems$.pipe(
          debounceTime(this.autocompleteWaitMs)
        ).subscribe((items: any) => {
          this.showItems(items);
        })
      );
    }
    this.searchEventEmitter.pipe(
      debounceTime(this.autocomplete ? 0 : 200)
    ).subscribe(searchText =>
      this.searchInputChange.emit(searchText)
    );
  }

  ngOnDestroy() {
    for (const sub of this._subscriptions) {
      sub.unsubscribe();
    }
  }

  onChange(newInput: string) {
    this.searchInput = newInput.trim();
    this.searchEventEmitter.emit(this.searchInput);
  }

  onKeyup(event: any) {
    if (!this.autocomplete) {
      return;
    }

    switch (event.keyCode) {
      case Keycode.UP:
        this.previousAutocompleteItem();
        return;
      case Keycode.DOWN:
        this.nextAutocompleteItem();
        return;
      case Keycode.ENTER:
        if (this.currentSelectedItemNum >= 0) {
          this.onItemClick(this.allItems[this.currentSelectedItemNum]);
        }
        return;
      default:
        break;
    }
  }

  scrollToCurrentItem() {
    const currentItem = this.autocompleteMenuEl.nativeElement.children[
      this.currentSelectedItemNum
      ];
    if (currentItem) {
      this.autocompleteMenuEl.nativeElement.scrollTop = currentItem.offsetTop;
    }
  }

  previousAutocompleteItem() {
    const itemCount = this.allItems.length;

    if (itemCount === 0) {
      return;
    }

    if (this.currentSelectedItemNum < 0) {
      this.currentSelectedItemNum = this.autocompleteItems.length - 1;
    } else {
      this.currentSelectedItemNum--;
    }

    this.scrollToCurrentItem();
  }

  nextAutocompleteItem() {
    const itemCount = this.allItems.length;

    if (itemCount === 0) {
      return;
    }

    if (this.currentSelectedItemNum >= itemCount) {
      this.currentSelectedItemNum = 0;
    } else {
      this.currentSelectedItemNum++;
    }
    this.scrollToCurrentItem();
  }

  resetAutocomplete() {
    this.currentSelectedItemNum = -1;
    this.autocompleteItems = [];
    this.allItems = [];
    this.numberOfItemsToShow = 0;
  }

  onItemClick(item) {
    this.resetAutocomplete();
    this.autocompleteOnSelect.emit(item);
  }

  showItems(items: any[]): void {
    this.resetAutocomplete();

    if (items.length === 0 || this.isSearchTextTooShort()) {
      this.hideAutocomplete();
    } else {
      this.showAutocomplete();
      this.allItems = items;
      this.loadMore();
    }
    this.scrollToTop();
    this.changeDetector.detectChanges();
  }

  private isSearchTextTooShort() {
    return (this.searchInput ? this.searchInput.length : 0) < this.autocompleteMinLength;
  }

  protected showAutocomplete() {
    this.renderer.addClass(
      this.searchContainerEl.nativeElement,
      ClassNames.FILLED
    );
  }

  protected hideAutocomplete() {
    this.renderer.removeClass(
      this.searchContainerEl.nativeElement,
      ClassNames.FILLED
    );
  }

  public setFocusOnInput(): void {
    setTimeout(() => this.searchInputEl.nativeElement.focus(), 800);
  }

  loadMore() {
    this.numberOfItemsToShow += this.searchLimit;
    this.autocompleteItems = this.allItems.slice(0, this.numberOfItemsToShow);
  }

  private scrollToTop() {
    this.currentSelectedItemNum = 0;
    this.scrollToCurrentItem();
  }
}
