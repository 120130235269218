import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class RefreshItemEventService {

  private refreshItemSource = new Subject<any>();

  refreshItemSource$ = this.refreshItemSource.asObservable();

  constructor() {
  }

  emitRefreshItem() {
    this.refreshItemSource.next();
  }

}
