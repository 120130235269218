import { Component, OnDestroy, OnInit } from '@angular/core';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { SelectOption } from '../../../core/models/select-option';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'hl-create-sao-download',
  templateUrl: './create-sao-download.component.html'
})
export class CreateSaoDownloadComponent implements OnInit, OnDestroy {
  links: SelectOption[];
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private configService: CountryConfigRestService) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.links = [];
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      if (config.SAO_DOWNLOAD_LINKS) {
        config.SAO_DOWNLOAD_LINKS.split(',').map(item => {
          const linkOption = item.split('#');
          this.links.push({
            title: linkOption[0],
            value: linkOption[1]
          });
        });
      }
    });
  }
}
