import { Pipe, PipeTransform } from '@angular/core';
import {
  ExtendedNestedDropdownOption,
  NestedDropdownOption
} from '../../../core/models/dropdown-options';

@Pipe({
  name: 'indeterminateCheckbox',
  pure: false
})
export class IndeterminateCheckboxPipe implements PipeTransform {

  transform(option: NestedDropdownOption | ExtendedNestedDropdownOption): boolean {
    return !!(option && option.isSelected) && this.isIndeterminate(option);
  }

  isIndeterminate(option: NestedDropdownOption | ExtendedNestedDropdownOption): boolean {
    if (!option || ((!option.children || !option.children.length) &&
      (!(option as ExtendedNestedDropdownOption).extendedChildren || !(option as ExtendedNestedDropdownOption).extendedChildren.length))) {
      return false;
    }
    const hasChecked = (option.children || (option as ExtendedNestedDropdownOption).extendedChildren).some(ch => ch.isSelected);
    const hasUnchecked = (option.children || (option as ExtendedNestedDropdownOption).extendedChildren).some(ch => !ch.isSelected);

    return (hasChecked && hasUnchecked) || (hasChecked &&
      (option.children || (option as ExtendedNestedDropdownOption).extendedChildren).some(ch => this.isIndeterminate(ch)));
  }
}
