import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { EnvironmentConfigRestService } from './environment-config-rest.service';
import { map } from 'rxjs/operators';
import { configGLNfile, configGLNfileFromAssets } from '../languages';
import { configPathAssets, configRestEndPoint } from '../core-constants.service';
import { ConfigLoaderService } from '../../config-loader.service';

@Injectable()
export class CountryConfigRestService {

  private config = new ReplaySubject<any>(1);
  private countryLocale: string;

  private static consolidateEnvSpecificConfig(env: string, config: any): any {
    const envSuffix = '.' + env;
    for (const key in config) {
      if (config.hasOwnProperty(key) && key.endsWith(envSuffix)) {
        config[key.replace(envSuffix, '')] = config[key];
      }
    }
    return config;
  }

  constructor(
    private http: CacheableHttpClient,
    private environmentConfigRestService: EnvironmentConfigRestService,
    private configLoaderService: ConfigLoaderService
  ) {}

  getConfig(): Observable<any> {
    return this.config.asObservable();
  }

  /**
   * ATTENTION: may only be called from dashboard (we don't maintain a list)
   * @param fn function for subscribing
   */
  subscribeWhenConfigIsSet(fn: (country: string) => void): void {
    this.config.subscribe(fn);
  }

  setConfig(countryCode): void {
    this.countryLocale = countryCode;
    this.getSpecificCountryConfig(countryCode).subscribe(config => this.config.next(config));
  }

  getSpecificCountryConfig(country: string): Observable<any> {
    if (!this.configLoaderService.isConfigServerAvailable) {
      country = country ? country : this.countryLocale;
      const configurationFilePath = `${configPathAssets}config-${country}.json`;
      return forkJoin([this.http.get(configurationFilePath), this.environmentConfigRestService.getEnvironmentConfig()])
        .pipe(
          map(([config, env]) => CountryConfigRestService.consolidateEnvSpecificConfig(env.ENVIRONMENT, config)));
    }
    return this.http.get(configRestEndPoint + (country || this.countryLocale));
  }

  getGlnConfig(): Observable<any> {
    const glnAddress = this.configLoaderService.isConfigServerAvailable ? configRestEndPoint + configGLNfile
      : configPathAssets + configGLNfileFromAssets;
    return this.http.get(glnAddress);
  }
}
