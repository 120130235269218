import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { roles } from '../../../core/core-constants.service';
import { Piis, SecurityNotifications } from '../../../core/models/securityNotifications/security-notifications';
import {
  SecurityNotificationsService,
  securityNotificationsSeverityTypes
} from '../../../core/services/security-notifications/security-notifications.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { UserUtilService } from '../../../core/services/user/user-util.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';

@Component({
  selector: 'hl-security-detail',
  templateUrl: './security-detail.component.html'
})
export class SecurityDetailComponent implements OnInit, OnDestroy {
  @Input()
  currentNotification: SecurityNotifications;
  @Input()
  loading = false;
  myEquipmentChecked = false;
  overlaySearchInput = '';

  numberOfEquipmentsForOverlay: number;
  myEquipmentsList = [];
  datePattern = '';

  private readonly unsubscribe$: Subject<void> = new Subject();
  updateSecurityDetailStatusSubscription: Subscription;
  status: string;
  multiSelectEvaluationOptions: DropdownOptions[];
  selectedDropDownList = {
    evaluation: []
  };
  showReadMore = false;

  constructor(
    private myFiltersService: MyFiltersAdapterService,
    private equipmentUtilService: EquipmentUtilService,
    private userUtilService: UserUtilService,
    private securityNotificationsService: SecurityNotificationsService,
    private configService: CountryConfigRestService,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.registerEventListeners();
    this.loadMyEquipmentData();
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.datePattern = configResponse.GENERIC_DATE_PATTERN;
    });
    this.updateSecurityDetailStatusSubscription = this.equipmentUtilService.updateSecurityDetailStatusEmitter.subscribe(status => {
      this.showReadMore = false;
      this.changeDetector.detectChanges();
      setTimeout(() => {
        this.status = status;
        if (this.currentNotification.cves && this.currentNotification.cves.length > 0) {
          this.showReadMore = true;
        }
        this.changeDetector.detectChanges();
      }, 100);
    });
  }

  loadMyEquipmentData() {
    this.multiSelectEvaluationOptions = this.securityNotificationsService.getMultiSelectEvaluationOptions();
  }

  registerEventListeners() {
    this.myFiltersService.status$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      ({ overallSwitch, equipment }) => {
        this.myEquipmentChecked = overallSwitch;
        this.myEquipmentsList = equipment.map(e => e.key);
      }
    );
  }

  setNumberOfEquipmentsForOverlay(numberOfAffctedEquipments: number) {
    this.numberOfEquipmentsForOverlay = numberOfAffctedEquipments;
  }

  isMyEquipmentChecked(): boolean {
    return this.myEquipmentChecked;
  }

  getMyEquipmentProfileList(): string[] {
    return this.myEquipmentsList;
  }

  openUrl(link: string) {
    window.open(link);
  }

  checkUserHasViewEquipmentRole() {
    return this.userUtilService.checkUserRoles({
      checkViewEquipmentRole: roles.viewEquipmentRole
    });
  }

  getSeverity(severity: securityNotificationsSeverityTypes): string {

    return SecurityNotificationsService.getSeverityForTranslation(severity);
  }

  getEvaluationResult(piis: Piis[]): number {
    return this.securityNotificationsService.getNumberOfEvaluationResults(piis);
  }

  capitalizeFirstLetter(string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.updateSecurityDetailStatusSubscription) {
      this.updateSecurityDetailStatusSubscription.unsubscribe();
    }
  }

  getLinkItems(item: SecurityNotifications) {
    return this.securityNotificationsService.getLinkItems(item);
  }

  onFilterChange() {
    const search = this.overlaySearchInput;
    this.overlaySearchInput = null;
    this.changeDetector.detectChanges();
    this.overlaySearchInput = search;
    this.changeDetector.detectChanges();
  }
}
