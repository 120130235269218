import { ContractsUtilService } from '../../../core/services/contracts/contracts-util.service';
import { ContractsConstantsService } from '../../../core/services/contracts/contracts-constants.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';
import { BaseEquipmentDetailListView } from '../../../core/base-class/base-equipment-detail-list-view';
import { LogService } from '../../../core/services/log/log.service';
import { ContractsViewModel } from '../../../core/view-models/contracts-view-model';
import { OverlayComponent } from '../../../shared/components/overlay/overlay.component';
import { BrowserStateService } from '../../../core/services/browser-state.service';
import { EMPTY, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { GetContractStatusColorPipe } from '../../../shared/pipes/get-contract-status-color/get-contract-status-color.pipe';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hl-equipment-contracts',
  templateUrl: './equipment-contracts.component.html'
})
export class EquipmentContractsComponent
  extends BaseEquipmentDetailListView<any>
  implements OnInit {
  @ViewChild('detailOverlay', { static: false })
  detailOverlay: OverlayComponent;

  showOneToManyContractModel: boolean;
  showHistoricCheckBox: boolean;
  historicContractsChecked: boolean;
  currentStateName: string;
  statusColorClass = '';
  contractTypes = [];
  selectedContractTypes = [];

  contractGroupNumberRender: boolean;
  getContractStatusColor = new GetContractStatusColorPipe();
  oneToOneModelTranslations: { [key: string]: string; };

  constructor(
    private contractsConstantsService: ContractsConstantsService,
    private contractsUtilService: ContractsUtilService,
    private logService: LogService,
    private browserStateService: BrowserStateService,
    private translateService: TranslateService,
    private router: Router,
    public route: ActivatedRoute,
    public lifeNetUtilService: LifeNetUtilService,
    configService: CountryConfigRestService,
    equipmentUtilService: EquipmentUtilService,
    activatedRoute: ActivatedRoute,
    filterUtilService: FilterUtilService
  ) {
    super(
      filterUtilService,
      configService,
      lifeNetUtilService,
      equipmentUtilService,
      activatedRoute
    );
  }

  ngOnInit() {
    super.init();
    this.loadTranslationsForOneToOneContractModel();
  }

  /**
   * @description Additional properties to be initialized for derived class
   */
  afterInitProperties() {
    this.historicContractsChecked = false;
    this.showHistoricCheckBox = false;
    this.showOneToManyContractModel = false;
    this.contractGroupNumberRender = false;
    this.setSearchInputFromQueryParam(this.router);
  }

  /**
   * @description Additional config properties initialized in derived class
   */
  afterConfigProperties(config) {
    let isConfigOneToNModel = false;

    // contract model type
    if (_.isEqual(config.CONTRACT_MODEL_ONE_MANY, 'true')) {
      isConfigOneToNModel = true;
    }

    // Check if show historic is shown or not
    if (_.isEqual(config.SHOW_HISTORIC_CONTRACTS_FILTER, 'true')) {
      this.showHistoricCheckBox = true;
    }

    if (_.isEqual(config.CONTRACT_GROUP_NUMBER_RENDER, 'true')) {
      this.contractGroupNumberRender = true;
      this.sortSkeleton = this.contractsConstantsService.getSortSkeletonForEquipment();
    } else {
      this.sortSkeleton = this.contractsConstantsService.getSortSkeletonForEquipmentNoContractGroupNum();
    }

    // Scenario-2 ONE_MANY= false and equipment is LD.
    if (
      isConfigOneToNModel ||
      this.equipmentUtilService.checkIsLdEquipment(this.equipmentItem.key)
    ) {
      this.showOneToManyContractModel = true;
    }

    if (!(_.isEmpty(this.selectedContractTypes))) {
      this.selectedContractTypes = [];
    }

    this.loadContractList(this.showOneToManyContractModel)
      .subscribe(() => this.setFilterDropDown());
  }

  private setFilterDropDown() {
    const dropDownListStructure = {
      contractTypes: 'contractTypeDescription'
    };
    const dropDownOptions = this.filterUtilService.computeDropdownOptions(
      this.rawList,
      dropDownListStructure
    );
    this.contractTypes = dropDownOptions.contractTypes;
  }

  /**
   * @description
   * Get the list of contracts
   */
  loadContractList(isOneToManyModel): Observable<any> {
    if (isOneToManyModel) {
      // Initially showHistoric is false
      return this.loadOneToManyContracts(this.historicContractsChecked);
    } else {
      return this.loadOneToOneContracts();
    }
  }

  /**
   *
   * @description
   * Get the contract details for one to one model
   */
  loadOneToOneContracts(): Observable<any> {
    this.isLoaded = false;

    if (this.equipmentItem.contractNumber) {
      // Get the one to one contract detail
      return this.contractsUtilService
        .getContractsDetails(this.equipmentItem.contractNumber)
        .pipe(finalize(() => {
          this.isLoaded = true;
        }), tap(contractDetailsResponse => {
          this.loadContracts(contractDetailsResponse);
        }));
    } else {
      // equipment may not have contract Number
      this.isLoaded = true;
      this.logService.info('Equipment does not have contract number filled');
      return EMPTY;
    }
  }

  /**
   *
   *
   * @description
   * Get the contracts list for the selected equipment
   */
  loadOneToManyContracts(showHistoric): Observable<any> {
    this.isLoaded = false;

    // Get the related contracts for the equipments
    return this.contractsUtilService
      .getEquipmentContracts(this.equipmentItem.key, showHistoric)
      .pipe(finalize(() => {
        this.isLoaded = true;

        // need to filter the list
        this.onAdvancedFilterChange();
      }), tap(contractDetailsResponse => {
        this.rawList = _.clone(contractDetailsResponse);
        this.loadContracts(contractDetailsResponse);
      }));
  }

  /**
   * Remove contractGroupNumber property
   * form contract list items if it should not be rendered
   */
  removeContractGroupNumberIfNotRender() {
    for (const contract of this.viewModelList) {
      if (!this.contractGroupNumberRender) {
        delete (contract as ContractsViewModel).contractGroup;
      }
    }
  }

  /**
   *
   * @description
   * Re-load the list with historic | non-historic items
   */
  reloadContractsList() {
    this.loadOneToManyContracts(this.historicContractsChecked).subscribe();
  }

  getPageSizeConfig(config): string {
    return config.CONTRACT_LIST_PAGE_SIZE;
  }

  /**
   * @description Filter object with different filters
   */
  getFilterObject(): any {
    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: [
          'contractName',
          'contractTypeDescription',
          'contractNumber',
          'productName',
          'siemensId'
        ]
      },
      contractTypeDescription: this.selectedContractTypes,
      orderBy: this.sortSkeleton.sortObject
    };
  }

  /**
   *
   * @private {Array} exp | the export array
   * @private {object} elem | the element object to be pushed to the export array
   * @returns {Array} exp
   *
   * @description
   * sets the export array from equipment contract list data
   */
  getContractDetailsExport = () => {
    return this.contractsUtilService.generateContractDetailsExport([], this.getTranslatedContractValues(this.oneToOneModelTranslations));
  }

  trackByFn(index, item: ContractsViewModel) {
    return item['contractNumber'];
  }

  openDetailOverlay(item: ContractsViewModel) {
    if (!item) {
      return;
    }
    const index = this.viewModelList.findIndex(
      i => i.contractNumber === item.contractNumber
    );
    this.onClickUpdateSelectionByIndex(index);
    this.detailOverlay.show();
  }

  onClickUpdateSelectionByIndex(index: number) {
    this.selectedItem = this.viewModelList[index];
    this.contractsUtilService.selectedItem = this.selectedItem;
    this.selectedItemIndex = index;
    this.browserStateService.setUserNavigation();
    this.statusColorClass = this.getContractStatusColor.transform(this.selectedItem);
    this.router
      .navigate([this.selectedItem.contractNumber, 'overview'], {
        relativeTo: this.route
      })
      .then(() => {
        this.browserStateService.resetUserNavigation();
      });
  }

  private loadContracts(contractDetailsResponse) {
    this.viewModelList = contractDetailsResponse;
    this.removeContractGroupNumberIfNotRender();
    if (this.route.firstChild && this.route.firstChild.snapshot.params.id) {
      const key = this.route.firstChild.snapshot.params.id;
      const item = this.viewModelList.find(i => i.contractNumber === key);
      this.openDetailOverlay(item);
    }
  }

  private loadTranslationsForOneToOneContractModel() {
    this.translateService
      .get(['CONTRACT_TYPE', 'CONTRACT_NUMBER', 'CONTRACT_GROUP_NUMBER',
        'GENERIC_LABEL_PRODUCT_NAME', 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID'])
      .subscribe( (translate: {[key: string]: string }) => {
        this.oneToOneModelTranslations = translate;
      });
  }

  private getTranslatedContractValues(translate: { [p: string]: string }) {
    let exportData = [];
    if (this.equipmentItem.contractType) { exportData.push({value: this.equipmentItem.contractType, label: translate.CONTRACT_TYPE}); }
    if (this.equipmentItem.contractNumber) {
      exportData.push({value: this.equipmentItem.contractNumber, label: translate.CONTRACT_NUMBER});
    }
    if (this.contractGroupNumberRender && this.equipmentItem.contractGroupNumber) {
      exportData.push({value: this.equipmentItem.contractGroupNumber, label: translate.CONTRACT_GROUP_NUMBER});
    }
    if (this.equipmentItem.productName) {
      exportData.push({value: this.equipmentItem.productName, label: translate.GENERIC_LABEL_PRODUCT_NAME});
    }
    if (this.equipmentItem.siemensId) {
      exportData.push({value: this.equipmentItem.siemensId, label: translate.GENERIC_LABEL_SIEMENS_EQUIPMENT_ID});
    }
    exportData = [...exportData, ...this.viewModelList];
    return exportData;
  }
}
