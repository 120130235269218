import { UpdateStatus } from '../models/update-status';
import { SystemUpdateDetail } from '../models/systemUpdates/system-update-detail';
import { AffectedEquipmentViewModel } from './affected-equipment-view-model';
import { SystemUpdateBaseViewModel } from './system-update-base-view-model';
import { Instructions } from '../models/systemUpdates/instructions';
import { PackageInfoViewModel } from './package-info-view-model';
import { environment } from '../../../environments/environment.prod';

export class SystemUpdateDetailViewModel extends SystemUpdateBaseViewModel {
  public updateNumber: string;
  public equipmentItems: AffectedEquipmentViewModel[];
  public packageInfo: PackageInfoViewModel;
  public instructions: Instructions;
  public imageLink: string;

  constructor(model: SystemUpdateDetail) {
    super(model);
    this.updateNumber = model.updateNumber;
    this.equipmentItems = model.equipmentItems.map(affectedEquipment =>
      new AffectedEquipmentViewModel(affectedEquipment));
    if (model.packageInfo) {
      this.packageInfo = new PackageInfoViewModel(model.packageInfo);
    }
    this.instructions = model.instructions;
    const iconRegular = environment.version + 'assets/images/asu_icon_regular.svg';
    const iconLock = environment.version + 'assets/images/asu_icon_lock.svg';
    this.imageLink = model.cyberSecurity ? iconLock : iconRegular;
  }

  public isRedStatus() {
    return (this.updateStatus === UpdateStatus.LongOverdue);
  }
}
