import { RouterModule, Routes } from '@angular/router';
import { UserSettingsComponent } from './user-settings.component';
import { NgModule } from '@angular/core';
import { NotificationsTabComponent } from './notifications-tab/notifications-tab.component';
import { MyCustomersTabComponent } from './my-customers-tab/my-customers-tab.component';
import { MyFiltersTabComponent } from './my-filters-tab/my-filters-tab.component';

const userSettingsRoutes: Routes = [
  {
    path: 'settings',
    component: UserSettingsComponent,
    children: [
      {
        path: 'notifications',
        component: NotificationsTabComponent,
        data: {stateName: 'settings-notifications'}
      },
      {
        path: 'myCustomers',
        component: MyCustomersTabComponent,
        data: {stateName: 'settings-myCustomers'}
      },
      {
        path: 'myFilters',
        component: MyFiltersTabComponent,
        data: {stateName: 'settings-myFilters'}
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(userSettingsRoutes)],
  exports: [RouterModule]
})
export class UserSettingsRoutingModule {}
