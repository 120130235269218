import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfigurationSaveEventValue } from '../../models/service-metrics-configuration-save-event';

@Injectable()
export class SprConfigurationSaveEvent {

  private serviceMetricsConfigSaveSource = new Subject<ConfigurationSaveEventValue>();

  serviceMetricsConfigSaveSource$ = this.serviceMetricsConfigSaveSource.asObservable();

  emitSaveDialog(configurationSaveEventValue: ConfigurationSaveEventValue) {
    this.serviceMetricsConfigSaveSource.next(configurationSaveEventValue);
  }
}
