import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { NewsWrapper } from '../models/news/news-wrapper';

@Injectable()
export class NewsRestService {

  constructor(private http: CacheableHttpClient) {
  }

  public getNewsWrapper(newsDataRestUrl: string): Observable<NewsWrapper> {
    return this.http.get<NewsWrapper>(newsDataRestUrl, {cache: false});
  }
}
