import { Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseModalPopup } from './base-modal-popup';

export abstract class BaseFormModalPopup extends BaseModalPopup {

  @ViewChild('modalForm', { static: false }) modalForm: NgForm;

  // form initially not submitted
  isFormSubmitted = false;
  showValidationMessage = false;

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  abstract postFormData();

  hide() {
    this.showValidationMessage = false;
    this.isFormSubmitted = false;
    super.hide();
  }

  ok() {

    // set the boolean flag for form submission to true for showing validation errors.
    this.isFormSubmitted = true;

    // If the form is valid, then make a request to BE
    if (this.modalForm.valid) {
      this.showValidationMessage = false;

      // show the spinner when form is submitted
      this.showSpinner = true;

      // call for BE request handled in derived class
      this.postFormData();

    } else {
      this.showValidationMessage = true;
    }
  }

  isNotEmpty(value: string) {
    return (value !== '') && ((value || '').trim().length !== 0);
  }
}
