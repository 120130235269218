import { TextareaComponent } from '../textarea.component';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'hl-spr-textarea',
  templateUrl: './spr-textarea.component.html'
})
export class SprTextareaComponent extends TextareaComponent implements AfterViewInit {
  @Output()
  valueChange: EventEmitter<String> = new EventEmitter<String>();
  @ViewChild('textAreaField', {static: false})
  textAreaField: ElementRef;

  constructor() {
    super();
  }

  private _textAreaValue = '';

  get textAreaValue() {
    return this._textAreaValue;
  }

  @Input()
  set textAreaValue(val: string) {
    this._textAreaValue = val;
  }

  onChange(newValue: any) {
    this.valueChange.emit(newValue);
  }

  ngAfterViewInit(): void {
    this.valueChange.emit(undefined);
  }
}
