import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { restEndPoint } from '../core-constants.service';
import { EmailFeature } from '../models/manage-emails';

export const featureEmailBaseRestName = restEndPoint + 'feature/emails';
export const saveFeatureEmail = featureEmailBaseRestName + '/save';


@Injectable()
export class ManageEmailsRestService {

  constructor(private http: HttpClient) {}

  getFeatureEmailsByCountry(): Observable<EmailFeature[]> {
    return this.http.get<EmailFeature[]>(featureEmailBaseRestName);
  }

  changeFeatureEmailsByCountry(emailFeatures: EmailFeature[]): Observable<any> {
    return this.http.put(saveFeatureEmail, emailFeatures);
  }
}
