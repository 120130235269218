import { Component, Input } from '@angular/core';
import { CustomerUtilService } from '../../../core/services/customer/customer-util.service';

@Component({
  selector: 'hl-is-ld-customer',
  templateUrl: './is-ld-customer.component.html'
})
export class IsLdCustomerComponent {

  @Input() customerId: string;

  constructor(private customerUtilService: CustomerUtilService) {
  }

  isLdCustomer() {
    return this.customerUtilService.isLdCustomer(this.customerId);
  }
}
