import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ImpersonationCommunicationService } from '../../core/component-communication-services/impersonation/impersonation-communication.service';
import { LastViewedEquipment } from '../../core/models/last-viewed-equipment';
import { EquipmentRestService } from '../../core/rest-services/equipment-rest.service';

@Component({
  selector: 'hl-last-viewed-widget',
  templateUrl: './last-viewed-widget.component.html'
})
export class LastViewedWidgetComponent implements OnInit, OnDestroy {

  lastViewedEquipmentList: LastViewedEquipment[];
  isLoaded = false;

  // subscription
  countryLanguageChangeSubscription: Subscription;

  constructor(private equipmentRestService: EquipmentRestService,
    private impersonationCommunicationService: ImpersonationCommunicationService) { }

  ngOnInit() {
    this.init();

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  ngOnDestroy() {
    if (this.countryLanguageChangeSubscription) {
      this.countryLanguageChangeSubscription.unsubscribe();
    }
  }

  init() {
    this.lastViewedEquipmentList = [];
    this.loadLastViewedEquipmentList();
  }

  /**
    *
    * @description
    * Register all events which are broad casted, emitted
    */
  registerEventListeners() {

    this.countryLanguageChangeSubscription =
      this.impersonationCommunicationService.onCountryLanguageChange$.subscribe(() => {
        this.init();
      });

  }

  /**
   * Load the last viewed list by making a BE request
   */
  loadLastViewedEquipmentList() {
    this.equipmentRestService.getLastViewedEquipment().pipe(
      finalize(() => {

        // irrespective of success or error
        this.isLoaded = true;
      }))
      .subscribe((lastViewedResponse) => {
        this.lastViewedEquipmentList = lastViewedResponse;
      });
  }
}
