import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

/**
 * the angular datePipe does strange things on mobile phones (shows time +1 hour)
 * so we use moment js.
 * Initially we thought of implementing a wrapper, but it is a standalone pipe now,
 * that only falls back to angular values, if no pattern is given
 */
@Pipe({
  name: 'datePipeWrapper'
})
export class DatePipeWrapperPipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      // do nothing -> fallback to DatePipe
    } else {
      const mValue = moment(value);
      if (args) {
        let pattern = '';
        if (Array.isArray(args)) {
          pattern = args[0];
        } else {
          pattern = args;
        }
        const result = mValue.format(pattern);

        return result;
      }
    }
    return super.transform(value, args);
  }
}


