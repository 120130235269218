import { Component, OnDestroy, OnInit } from '@angular/core';
import { clone } from 'lodash';
import { OperatorFunction, Subject, Subscription } from 'rxjs';
import { finalize, mapTo, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AlignHeightEventService } from '../../core/component-communication-services/align-height-event/align-height-event.service';
import { ImpersonationCommunicationService } from '../../core/component-communication-services/impersonation/impersonation-communication.service';
import { TranslateService } from '@ngx-translate/core';
import { StatusCountViewModel } from '../../core/models/status-count-view-model';
import { ActivitiesUtilService } from '../../core/services/activities/activities-util.service';
import { WidgetHeightCacheService } from '../../core/services/cache/widget-height-cache.service';
import { DataDiagram } from '../../diagram/diagram';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';

@Component({
  selector: 'hl-activity-status-widget',
  templateUrl: './activity-status-widget.component.html'
})
export class ActivityStatusWidgetComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject();

  isLoaded: boolean;
  isHeightEventTriggered: boolean;
  statusItems: StatusCountViewModel[];
  diagramData: DataDiagram[];
  contentHeight: string;

  totalLabel: string;
  totalCount: number;
  translations: { [key: string]: string };

  constructor(private activitiesUtilService: ActivitiesUtilService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private alignHeightEventService: AlignHeightEventService,
    private widgetHeightCacheService: WidgetHeightCacheService,
    private translateService: TranslateService,
    private myFiltersService: MyFiltersAdapterService) {
  }

  ngOnInit() {
    this.myFiltersService.filterEquipmentKeys$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.init());
    this.registerEventListeners();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  registerEventListeners() {
    this.impersonationCommunicationService.onCountryLanguageChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.init());

    const widgetHeightFromCache = this.widgetHeightCacheService.getHeight();

    if (!widgetHeightFromCache) {
      this.alignHeightEventService.alignHeightSource$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((height: number) => {
          this.widgetHeightCacheService.setHeight(height);
          this.updateContentHeight(height);
        });
    } else {
      this.updateContentHeight(widgetHeightFromCache);
    }
  }

  init() {
    this.isLoaded = false;
    this.isHeightEventTriggered = false;
    this.statusItems = [];

    this.loadActivityStatus();
  }

  loadActivityStatus() {
    this.myFiltersService.filterEquipmentKeys$.pipe(
      switchMap(equipmentKeys => this.activitiesUtilService.loadFilteredActivityStatus(equipmentKeys)),
      this.translateActivityStatusTitle(),
      finalize(() => this.isLoaded = true),
    ).subscribe(result => {
      this.statusItems = clone(result);
      this.setDiagramData();
      this.isLoaded = true;
    });
  }

  setDiagramData() {
    this.diagramData = [];
    this.checkForAllLabel();
    this.statusItems.forEach(item => {
      const data: DataDiagram = {
        color: item.class,
        value: item.count,
        name: item.title,
        routerLink: '/activities',
        queryParams: {status: item.title}
      };
      if (item.status !== 'All') {
        this.diagramData.push(data);
      } else {
        this.totalCount = item.count;
        this.totalLabel = this.translations[item.title];
      }
    });
  }

  private checkForAllLabel() {
    let containsAllLabel = false;
    let numberOfActivities = 0;
    this.statusItems.forEach(item => {
      if (item.status === 'All') {
        containsAllLabel = true;
        return;
      }
      numberOfActivities += item.count;
    });
    if (!containsAllLabel) {
      this.statusItems.push({
        title: 'GENERIC_LABEL_TOTAL',
        count: numberOfActivities,
        class: 'All',
        status: 'All'
      });
    }
  }

  /**
   * Content height for the activity widget.
   * @param {string} contentHeight
   */
  updateContentHeight(contentHeight: number) {
    this.contentHeight = contentHeight + 'px';
    this.isHeightEventTriggered = true;
  }

  private translateActivityStatusTitle(): OperatorFunction<StatusCountViewModel[], StatusCountViewModel[]> {
      return switchMap(statusCounts => this.translateService.get(statusCounts.map(r => r.title))
        .pipe(
          tap((translations: {[key: string]: string}) => this.translations = translations),
          mapTo(statusCounts)
        )
    );
  }
}
