import { SystemUpdatesDownloadComponent } from './system-updates-download/system-updates-download.component';
import { SystemUpdatesComponent } from './system-updates.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UpdatesAuthGuardService } from '../core/auth-guards/updates/updates-auth-guard.service';

const updatesRoute: Routes = [
  {
    path: 'updates',
    component: SystemUpdatesComponent,
    children: [
      {
        path: '',
        component: SystemUpdatesDownloadComponent,
        data: {stateName: 'updates', title: 'SYSTEM_UPDATES'}
      },
      {
        path: ':id',
        component: SystemUpdatesDownloadComponent,
        data: {stateName: 'updates-overview', title: 'SYSTEM_UPDATES'}
      }
    ],
    canActivate: [UpdatesAuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(updatesRoute)],
  exports: [RouterModule]
})
export class SystemUpdatesRoutingModule {
}
