import { forkJoin, identity, merge, Observable } from 'rxjs';
import { map, skipWhile, tap } from 'rxjs/operators';

export const enhance =
  <TSource, TEnhancer, TResult>(enhancer$: Observable<TEnhancer>, project?: ([source, result]: [TSource, TEnhancer]) => TResult) => {
    project = project || (identity as ([source, result]: [TSource, TEnhancer]) => TResult);
    return (source$: Observable<TSource>) => {
      let joinEmited = false;
      const join$ = forkJoin([source$, enhancer$]).pipe(
        map(project),
        tap(() => joinEmited = true)
      );
      // tslint:disable-next-line: rxjs-no-unsafe-scope
      const sourceFiltered$ = source$.pipe(skipWhile(() => joinEmited));
      return merge(join$, sourceFiltered$);
    };
  };
