import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';

@Component({
  selector: 'hl-confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent extends BaseModalPopup {

  @Output() handleConfirmation: EventEmitter<any> = new EventEmitter();
  @Input() title;
  @Input() text;

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  ok() {
    this.showSpinner = true;
    this.handleConfirmation.emit();
  }
}
