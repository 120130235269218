import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { ActivitiesUtilService } from '../../../core/services/activities/activities-util.service';
import { ActivitiesCacheService } from '../../../core/services/cache/activities-cache.service';
import { ActivitiesRestService } from '../../../core/rest-services/activities-rest.service';
import { RefreshItemEventService } from '../../../core/component-communication-services/refresh-item-event/refresh-item-event.service';
import { LogService } from '../../../core/services/log/log.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TicketViewModel } from '../../../core/view-models/ticket-view-model';

@Component({
  selector: 'hl-activities-overview',
  templateUrl: './activities-overview.component.html'
})
export class ActivitiesOverviewComponent implements OnInit, OnDestroy {

  @ViewChild('rescheduleModal', { static: false }) rescheduleModal;
  @ViewChild('scheduleModal', { static: false }) scheduleModal;
  @ViewChild('updateModal', { static: false }) updateModal;

  datePattern = '';
  dateTimePattern = '';
  viewModelActivity: ActivitiesViewModel;
  addressFields: any;
  private readonly unsubscribe$: Subject<void> = new Subject();
  isLoaded = false;
  updateActivity: TicketViewModel;

  constructor(private activatedRoute: ActivatedRoute,
    private activitiesUtilService: ActivitiesUtilService,
    private configService: CountryConfigRestService,
    private lifeNetUtilService: LifeNetUtilService,
    private activitiesCacheService: ActivitiesCacheService,
    private activitiesRestService: ActivitiesRestService,
    private refreshItemEventService: RefreshItemEventService,
    private logService: LogService) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.addressFields = [];
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.setConfigProperties(configResponse);
      this.getActivityViewModel(configResponse);
    });
    this.refreshItemEventService.refreshItemSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.refreshActivity();
    }, (some) => {
      this.logService.debug('what is the problem? 11' + some);
    });
  }

  setConfigProperties(config) {

    // get the locale date pattern
    this.datePattern = config.GENERIC_DATE_PATTERN;
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;
  }

  getActivityViewModel(config) {
    this.updateActivity = null;
    this.activatedRoute.params.subscribe(
      (params: any) => {
        const id = params['ticketKey'] || params['id'];
        if (this.activitiesUtilService.selectedActivity) {
          this.isLoaded = true;
          this.viewModelActivity = this.activitiesUtilService.selectedActivity;
          this.addressFields =
            this.lifeNetUtilService.getAddressFields(this.viewModelActivity, config.ADDRESS_FIELD_ORDER, false);
        } else {
          this.activitiesUtilService.getSingleActivity(id, this.activitiesCacheService.getSelectedOpenClosedStatus())
            .subscribe((activityVMResponse) => {
              this.isLoaded = true;
              this.viewModelActivity = activityVMResponse;
              if (activityVMResponse) {
                this.addressFields =
                  this.lifeNetUtilService.getAddressFields(activityVMResponse, config.ADDRESS_FIELD_ORDER, false);
              }
            });
        }
        this.createUpdateActivityItem();
      }
    );
  }

  /**
   *
   * @description
   * Refresh activity overview after clearing cache
   */
  refreshActivity() {
    this.unsubscribe$.next();

    this.isLoaded = false;
    const url = this.activitiesRestService.activitiesDetailsRestName.replace(/:id/g, this.viewModelActivity.ticketKey);
    this.activitiesRestService.clearCache(url);

    this.init();
  }

  createUpdateActivityItem() {
    this.updateActivity = {
      ticketKey: this.viewModelActivity.ticketKey,
      ticketNumber: this.viewModelActivity.ticketNumber,
      customerNumber: this.viewModelActivity.customerNumber,
      equipmentKey: this.viewModelActivity.equipmentKey,
      typeID: this.viewModelActivity.type
    } as TicketViewModel;
  }
}
