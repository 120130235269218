import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContractsViewModel } from '../../../core/view-models/contracts-view-model';
import { ContractsUtilService } from '../../../core/services/contracts/contracts-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { ItemDetailViewModel } from '../../../core/view-models/item-detail-view-model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hl-contract-list-item',
  templateUrl: './contract-list-item.component.html'
})
export class ContractListItemComponent implements OnInit, OnDestroy {
  @Input()
  item: ContractsViewModel;
  @Input()
  extendClass: string;

  contractDetailList: ItemDetailViewModel[];
  datePattern = '';
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private contractsUtilService: ContractsUtilService,
    private configService: CountryConfigRestService
  ) {
  }

  ngOnInit() {
    this.contractsUtilService
      .getContractsDetails(this.item.contractNumber)
      .subscribe(contractDetails => {
        this.contractDetailList = contractDetails;
      });

    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.datePattern = configResponse.GENERIC_DATE_PATTERN;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
