import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class IadvizeChatService {

  private onIAdvizeCustomObjectBuild: Subject<any> = new Subject();
  onIAdvizeCustomObjectBuildChange$ = this.onIAdvizeCustomObjectBuild.asObservable();

  constructor() {
  }

  emitIAdvizeCustomObjectBuild(iAdvizeObject: any) {
    this.onIAdvizeCustomObjectBuild.next(iAdvizeObject);
  }
}
