import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hl-ticket-detail-history-and-event-logs',
  templateUrl: './ticket-detail-history-and-event-logs.component.html'
})
export class TicketDetailHistoryAndEventLogsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
