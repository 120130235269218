import { CreateSaoEntryPageComponent } from '../shared/overlays/create-sao-entry-page/create-sao-entry-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EquipmentContractsComponent } from './equipment-detail/equipment-contracts/equipment-contracts.component';
import { EquipmentTicketHistoryComponent } from './equipment-detail/equipment-ticket-history/equipment-ticket-history.component';
import { EquipmentSystemInformationComponent } from './equipment-detail/equipment-system-information/equipment-system-information.component';
import { EquipmentActivitiesComponent } from './equipment-detail/equipment-activities/equipment-activities.component';

import { EquipmentComponent } from './equipment.component';
import { EquipmentOptionsUpgradesComponent } from './equipment-detail/equipment-options-upgrades/equipment-options-upgrades.component';
import { EquipmentSystemUpdatesComponent } from './equipment-detail/equipment-system-updates/equipment-system-updates.component';
import { EquipmentSecurityComponent } from './equipment-detail/equipment-security/equipment-security.component';
import { TicketDetailHistoryAndEventLogsComponent } from '../tickets/ticket-details/ticket-detail-history-and-event-logs/ticket-detail-history-and-event-logs.component';
import { TicketDetailOverviewTabComponent } from '../tickets/ticket-details/ticket-detail-overview-tab/ticket-detail-overview-tab.component';
import { SystemUpdatesDownloadComponent } from '../system-updates/system-updates-download/system-updates-download.component';
import { ActivitiesOverviewComponent } from '../shared/components/activities-overview/activities-overview.component';
import { ContractsOverviewComponent } from '../shared/components/contracts-overview/contracts-overview.component';
import { TicketDetailEventLogsTabComponent } from 'app/tickets/ticket-details/ticket-detail-event-logs-tab/ticket-detail-event-logs-tab.component';
import { TicketDetailDeliveryMilestonesTabComponent } from 'app/tickets/ticket-details/ticket-detail-delivery-milestones-tab/ticket-detail-delivery-milestones-tab.component';
import { ActivitiesEventLogsComponent } from '../shared/components/activities-event-logs/activities-event-logs.component';
import { EquipmentTrainingsComponent } from './equipment-detail/equipment-trainings/equipment-trainings.component';
import { EquipmentDocumentsTabComponent } from './equipment-detail/equipment-documents/equipment-documents-tab.component';
import { EquipmentEnvironmentComponent } from './equipment-detail/equipment-environment/equipment-environment.component';
import { EquipmentsAuthGuardService } from '../core/auth-guards/equipments/equipments-auth-guard.service';
import { EquipmentTeamplayComponent } from './equipment-detail/equipment-teamplay/equipment-teamplay.component';

const equipmentRoutes: Routes = [
  {
    path: 'equipment',
    component: EquipmentComponent,
    children: [
      {
        path: ':id/activity',
        component: EquipmentActivitiesComponent,
        data: {
          stateName: 'equipment-activity',
          title: 'ALL_SIEMENS_EQUIPMENTS'
        },
        children: [
          {
            path: ':ticketKey/overview',
            component: ActivitiesOverviewComponent,
            data: {
              stateName: 'equipment-activities-overview',
              title: 'GEPLANTE_TERMINE'
            }
          },
          {
            path: ':ticketKey/history',
            component: ActivitiesEventLogsComponent,
            data: {
              stateName: 'equipment-activities-history',
              title: 'OPEN_TICKETS'
            }
          }
        ]
      },
      {
        path: ':id/update',
        component: EquipmentSystemUpdatesComponent,
        data: {
          stateName: 'equipment-update',
          title: 'ALL_SIEMENS_EQUIPMENTS'
        },
        children: [
          {
            path: ':updateNumber',
            component: SystemUpdatesDownloadComponent,
            data: {
              stateName: 'equipment-update-overview',
              title: 'ALL_SIEMENS_EQUIPMENTS'
            }
          }
        ]
      },
      {
        path: ':id/security',
        component: EquipmentSecurityComponent,
        data: {
          stateName: 'equipment-security',
          title: 'ALL_SIEMENS_EQUIPMENTS'
        }
      },
      {
        path: ':id/info',
        component: EquipmentSystemInformationComponent,
        data: {stateName: 'equipment-info', title: 'ALL_SIEMENS_EQUIPMENTS'},
        children: [
          {
            path: ':id/createsao',
            component: CreateSaoEntryPageComponent,
            data: {stateName: 'createsao', title: 'ALL_SIEMENS_EQUIPMENTS'}
          }
        ]
      },
      {
        path: ':id/documents',
        component: EquipmentDocumentsTabComponent,
        data: {
          stateName: 'equipment-documents',
          title: 'ALL_SIEMENS_EQUIPMENTS'
        },
        children: [
          {
            path: '**',
            redirectTo: ''
          }
        ]
      },
      {
        path: ':id/trainings',
        component: EquipmentTrainingsComponent,
        data: {
          stateName: 'equipment-trainings',
          title: 'ALL_SIEMENS_EQUIPMENTS'
        }
      },
      {
        path: ':id/contractModel',
        component: EquipmentContractsComponent,
        data: {
          stateName: 'equipment-contractModel',
          title: 'ALL_SIEMENS_EQUIPMENTS'
        },
        children: [
          {
            path: ':id/overview',
            component: ContractsOverviewComponent,
            data: {
              stateName: 'equipment-contractModel-overview',
              title: 'ALL_SIEMENS_EQUIPMENTS'
            }
          }
        ]
      },
      {
        path: ':id/ticketHistory',
        component: EquipmentTicketHistoryComponent,
        data: {
          stateName: 'equipment-ticketHistory',
          title: 'ALL_SIEMENS_EQUIPMENTS'
        },
        children: [
          {
            path: ':ticketKey/overview',
            component: TicketDetailOverviewTabComponent,
            data: {
              stateName: 'equipment-ticketHistory-overview',
              title: 'OPEN_TICKETS'
            }
          },
          {
            path: ':ticketKey/history',
            component: TicketDetailHistoryAndEventLogsComponent,
            data: {
              stateName: 'equipment-ticketHistory-history',
              title: 'OPEN_TICKETS'
            }
          },
          {
            path: ':ticketKey/logs',
            component: TicketDetailEventLogsTabComponent,
            data: {
              stateName: 'equipment-ticketHistory-logs',
              title: 'OPEN_TICKETS'
            }
          },
          {
            path: ':ticketKey/delivery',
            component: TicketDetailDeliveryMilestonesTabComponent,
            data: {
              stateName: 'equipment-ticketHistory-delivery',
              title: 'OPEN_TICKETS'
            }
          }
        ]
      },
      {
        path: ':id/optionsUpgrades',
        component: EquipmentOptionsUpgradesComponent,
        data: {
          stateName: 'equipment-optionsUpgrades',
          title: 'ALL_SIEMENS_EQUIPMENTS'
        }
      },
      {
        path: ':id/environment',
        component: EquipmentEnvironmentComponent,
        data: {
          stateName: 'equipment-environment',
          title: 'ALL_SIEMENS_EQUIPMENTS'
        }
      },
      {
        path: ':id/teamplay',
        component: EquipmentTeamplayComponent,
        data: {
          stateName: 'equipment-teamplay',
          title: 'ALL_SIEMENS_EQUIPMENTS'
        }
      }
    ],
    canActivate: [EquipmentsAuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(equipmentRoutes)],
  exports: [RouterModule]
})
export class EquipmentRoutingModule {
}
