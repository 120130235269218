import { Injectable } from '@angular/core';
import { SprConfigurationWidgetRestService } from '../core/rest-services/spr-configuration-widget-rest.service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { SprWidget } from '../core/models/spr/configuration-spr';
import { switchMap, take } from 'rxjs/operators';
import { SprDashboardFilter } from '../core/models/spr/spr-filter';

@Injectable()
export class SprDashboardService {
  private filter$: ReplaySubject<SprDashboardFilter> = new ReplaySubject<SprDashboardFilter>(1);
  private loadedFilter$: Subject<SprDashboardFilter> = new Subject<SprDashboardFilter>();
  private sprWidgets$: Subject<SprWidget[]> = new Subject<SprWidget[]>();

  constructor(private sprConfigurationWidgetRestService: SprConfigurationWidgetRestService) {
  }

  applyFilter(updateFilter = false) {
    this.filter$.pipe(
      take(1),
      switchMap(filter => this.sprConfigurationWidgetRestService.loadDashboardWidgetsWithFilter(filter)))
    .subscribe(dashboardGraphs => {
      if (updateFilter && dashboardGraphs.filter) {
        this.loadedFilter$.next(dashboardGraphs.filter);
      }

      this.sprWidgets$.next(dashboardGraphs.graphs);
    });
  }

  updateFilter(filter: SprDashboardFilter): void {
    this.filter$.next(filter);
  }

  getFilter(): Observable<SprDashboardFilter> {
    return this.filter$.asObservable();
  }

  getLoadedFilter(): Observable<SprDashboardFilter> {
    return this.loadedFilter$.asObservable();
  }

  getWidgets(): Observable<SprWidget[]> {
    return this.sprWidgets$.asObservable();
  }
}
