import { Component } from '@angular/core';

@Component({
  selector: 'hl-not-authorized',
  templateUrl: './not-authorized.component.html'
})
export class NotAuthorizedComponent {

  constructor() { }
}
