import { Component, EventEmitter, Output, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { UserUtilService } from '../../../core/services/user/user-util.service';

@Component({
  selector: 'hl-intro-feature-modal',
  templateUrl: './intro-feature-modal.component.html'
})
export class IntroFeatureModalComponent extends BaseModalPopup {

  introContent: any;
  currentIndex = 0;
  loaded = false;
  @Output() introChecked = new EventEmitter<any>();

  constructor(renderer: Renderer2,
    private translateService: TranslateService,
    private userUtilService: UserUtilService) {
    super(renderer);
  }

  show() {
    /*
     * In order to parse a JSON object, we need double quote marks.
     * Since during the export of double quote marks, they are replaced with single quotes, we either had to:
     * 1. Replace the quotes in the locale file with \u0022, or
     * 2. Handle it here, like follows
     */
    this.translateService.get('INTRODUCTION_FEATURE_CONTENT').subscribe(introductionFeatureContent => {
      const jsonObject = introductionFeatureContent.replace(/'/g, '"');
      this.introContent = JSON.parse(jsonObject);
      this.loaded = true;
      super.show();
    });
  }

  ok() {
    super.hide();
  }

  next() {
    if (this.currentIndex === this.introContent.length - 1) {
      this.hide();
    } else if (this.currentIndex < this.introContent.length - 1) {
      this.currentIndex++;
    }
  }

  back() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  hide() {
    this.userUtilService.postIntroModalClosed().pipe(finalize(() => {
      this.introChecked.emit();
    })).subscribe(() => {
      super.hide();
    });
  }

  lastSlide() {
    return this.currentIndex === this.introContent.length - 1;
  }
}
