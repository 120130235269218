import { Injectable } from '@angular/core';
import { SecurityApplianceOrder } from '../models/securityApplianceOrders/security-appliance-order';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { restEndPoint } from '../core-constants.service';

// SAO rest endpoints
const createSaoRestName = restEndPoint + 'saOrder';
const getOrderRestName = restEndPoint + 'saOrder/allowedSaoEquipments';

@Injectable()
export class SecurityApplianceOrderRestService {

  constructor(private http: CacheableHttpClient) {
  }

  createSAO(saoRequest: SecurityApplianceOrder): Observable<any> {
    return this.http.post(createSaoRestName, saoRequest);
  }

  getAllowedOrders() {
    return this.http.get<string[]>(getOrderRestName, {cache: false});
  }
}
