import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'ticketStatus'
})
export class TicketStatusPipe implements PipeTransform {

  transform(dataset: any, selectedValue: any): any {
    let filtered = [];

    if (_.isEmpty(selectedValue)) {
      filtered = dataset;
    } else {
      let valuesString = '';
      for (let i = 0; i < selectedValue.length; i++) {
        valuesString += selectedValue[i] + ',';
      }
      const closedTicketIds = valuesString.split(',');
      _.forEach(closedTicketIds, id => {
        const t = _.filter(dataset, {ticketStatus: id});
        filtered = filtered.concat(t);
      });
    }
    return filtered;
  }
}
