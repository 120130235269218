import { Component, ElementRef, EventEmitter, HostListener, Input, IterableDiffers, OnInit, Output } from '@angular/core';
import { MultiSelectboxComponent } from '../multi-selectbox/multi-selectbox.component';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from '../../../core/services/log/log.service';

@Component({
  selector: 'hl-search-multi-selectbox',
  templateUrl: './search-multi-selectbox.component.html'
})
export class SearchMultiSelectboxComponent extends MultiSelectboxComponent implements OnInit {

  @Input() isDropdownOpen = false;
  @Output() docClicked = new EventEmitter<boolean>();
  selectBoxOpened = false;
  searchInput: string;
  allOptions: DropdownOptions[];

  constructor(translate: TranslateService,
    logService: LogService,
    elementRef: ElementRef<any>,
    differs: IterableDiffers,
    private filterUtilService: FilterUtilService) {
    super(translate, logService, elementRef, differs);
  }

  ngOnInit() {
    super.ngOnInit();

    this.allOptions = this.options;
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickDoc(event) {
    if (this.selectBoxOpened && !this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.docClicked.emit(true);
      return;
    }
    this.selectBoxOpened = true;
  }

  onSearchInputChange() {
    const filterObject = {
      searchValue: this.searchInput,
      searchColumns: ['title', 'value']
    };

    this.options = this.filterUtilService.applyIndividualFilter(this.allOptions, filterObject, 'search');
  }

  closeSearchDropdown(event?) {
    if (event) {
      this.isDropdownOpen = false;
      this.docClicked.emit(true);
    }
  }
}
