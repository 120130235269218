import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class DeleteGroupEventService {

    // Observable deleteGroup sources
    private deleteGroupSource = new Subject<any>();

    // Observable deleteGroup streams
    deleteGroupSource$ = this.deleteGroupSource.asObservable();

    constructor() { }

    emitGroupDeleted(country: string) {
        this.deleteGroupSource.next(country);
    }

}
