import { ContractsDetail } from '../models/contracts/contracts-detail';
import { Contracts } from '../models/contracts/contracts';
import { HttpParams } from '@angular/common/http';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { restEndPoint } from '../core-constants.service';
import { ContractsEquipmentUsage } from '../models/contracts/contracts-equipment-usage';

@Injectable()
export class ContractsRestService {
    // contracts
    contractsRestName = restEndPoint + 'contracts';
    contractsIndividualRestName = restEndPoint + 'contracts/:id';
    contractsEquipmentUsageRestName = this.contractsIndividualRestName + '/usageData';

    constructor(private http: CacheableHttpClient) { }

    /**
     * @description get list of contracts from BE (/rest/v1/contracts?statusFilter=1,2,3)
     */
    getContracts(requestParams): Observable<Contracts[]> {
        const httpParams = new HttpParams()
            .set('statusFilter', requestParams);
        return this.http.get<Contracts[]>(this.contractsRestName, {params: httpParams});
    }

    /**
     * @description get contract details
     */
    getContractsDetails(key: string): Observable<ContractsDetail[]> {
        const url = this.contractsIndividualRestName.replace(/:id/, key);
        return this.http.get<ContractsDetail[]>(url);
    }

    getContractsEquipmentUsage(contractNumber: string): Observable<ContractsEquipmentUsage[]> {
        const url = this.contractsEquipmentUsageRestName.replace(/:id/, contractNumber);
      return this.http.get<ContractsEquipmentUsage[]>(url, {cache: false});
    }

}
