import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { ActivatedRoute } from '@angular/router';
import { DocumentsSourceOption } from '../../../core/models/equipment/documents/documents-source-option';
import { Equipment } from '../../../core/models/equipment/equipment';
import { DocumentSourceRestService } from '../../../core/rest-services/document-source-rest.service';
import { CheckPermissionOrRoleService } from '../../../core/auth-guards/check-permission-or-role.service';

@Component({
  selector: 'hl-equipment-documents-tab',
  templateUrl: './equipment-documents-tab.component.html'
})
export class EquipmentDocumentsTabComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();

  isLoaded = false;
  showDocumentsWrapper = false;
  selectedEquipment: Equipment;
  documentsSourceOptions: DocumentsSourceOption[];
  equipmentId;

  constructor(
    private equipmentUtilService: EquipmentUtilService,
    private sourceRestService: DocumentSourceRestService,
    private activatedRoute: ActivatedRoute,
    private checkPermissionOrRoleService: CheckPermissionOrRoleService
  ) {
  }

  ngOnInit(): void {
    this.checkPermissionOrRoleService.equipmentDocumentOverviewComponent.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value) {
          this.activatedRoute.params.pipe(
            tap(() => this.isLoaded = false),
            switchMap((params: any) => {
              this.equipmentId = params['id'];
              return this.equipmentUtilService.getEquipmentRest();
            }),
            switchMap((equipmentResponse: Equipment[]) => {
              const eq = equipmentResponse.filter(equip => equip.key === this.equipmentId)[0];
              this.selectedEquipment = eq ? eq : equipmentResponse[0];
              return this.sourceRestService.getDocumentsSourceOptions(this.selectedEquipment.key);
            }),
            finalize(() => this.isLoaded = true)
          ).subscribe((options: DocumentsSourceOption[]) => {
            this.documentsSourceOptions = (options && options.length > 0) ? options : null;
            const hasMoreOptions = options && options.length > 1;
            this.showDocumentsWrapper = !hasMoreOptions;
            this.isLoaded = true;
          });
        } else {
          this.isLoaded = true;
          this.showDocumentsWrapper = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  shouldShowDocumentsWrapper(): boolean {
    return this.isLoaded && this.showDocumentsWrapper;
  }

  shouldShowCards(): boolean {
    return this.isLoaded && !this.showDocumentsWrapper;
  }
}
