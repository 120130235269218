import { combineLatest, Observable } from 'rxjs';
import { UserRestService } from '../../rest-services/user-rest.service';
import { Injectable } from '@angular/core';
import { find, forEach, isEqual } from 'lodash';
import { User } from '../../models/user';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { map } from 'rxjs/operators';
import { roles } from '../../core-constants.service';
import { UserAccountViewModel } from '../../../customer-administration/user-accounts/user-account-view-model';

@Injectable()
export class UserUtilService {

  constructor(private userRestService: UserRestService,
              private configService: CountryConfigRestService) {
  }

  checkUserRoles(userRolesToBeChecked): Observable<any> {
    return this.userRestService.getUserRoles().pipe(
      map(userRolesResponse => {
        const checkedUserRoles = {};
        forEach(userRolesToBeChecked, (value, key) => {
          checkedUserRoles[key] = !!find(userRolesResponse, { identifier: value });
        });
        return checkedUserRoles;
      })
    );
  }

  getUser(): Observable<User> {
    return this.userRestService.getUser();
  }

  showIntroModal(): Observable<boolean> {
    return combineLatest([
      this.configService.getConfig(),
      this.userRestService.showIntroModal()
    ]).pipe(
      map(([config, showModal]) =>
        isEqual(config.INTRODUCTION_FEATURE_AVAILABLE, 'true') && !showModal.shown
      )
    );
  }

  postIntroModalClosed(): Observable<any> {
    return this.userRestService.postIntroModalClosed();
  }

  checkUserHasImpersonateUserRole() {
    return this.checkUserRoles({
      checkUserImpersonationRole: roles.impersonateUserRole
    });
  }

  getAllRolesSkeleton() {
    const rolesSkeleton = [];

    forEach(Object.keys(roles), key => {
        const roleView = {
          title: roles[key],
          value: key
        };
        rolesSkeleton.push(roleView);
    });

    return rolesSkeleton;
  }

  getUserAccounts(showCity = false, showState = false): Observable<UserAccountViewModel[]> {
    return this.userRestService.getUserAccounts().pipe(map(accounts => accounts.map(account => ({
      ...account,
      roleList: account.roles.join(', '),
      customerGroupNames: account.customerGroups.map(g => g.groupName),
      customerGroupNameList: account.customerGroups.map(g => g.groupName).join(', '),
      showCity: showCity && account.customers.some(customer => !!customer.city),
      showState: showState && account.customers.some(customer => !!customer.state),
      expanded: false
    } as UserAccountViewModel))));
  }

  getRolesTranslatedAvaliable() {
    return this.userRestService.getTranslatedRoles();
  }
}
