import { Observable } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { WindowService } from '../window.service';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class StateService {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private windowService: WindowService
    ) {
  }

  /**
   * Returns the state name of the active route
   *
   * Note: From https://toddmotto.com/dynamic-page-titles-angular-2-router-events#final-code
   */
  getActiveStateName(): Observable<string> {

    return this.getRouteConfigDataProperty('stateName');
  }

  /**
   * Returns the translation title for header
   *
   * Note: From https://toddmotto.com/dynamic-page-titles-angular-2-router-events#final-code
   */
  getActiveRouteTitle(): Observable<string> {

    return this.getRouteConfigDataProperty('title');
  }

  /**
   * @description
   * Get the specific property of data object in route config.
   * For e.g. if route config is
   * {
        path: ':id/overview',
        component: EquipmentOverviewComponent,
        data: {stateName: 'equipment-overview', title: 'ALL_SIEMENS_EQUIPMENTS'}
      }
   *
   * it returns value for stateName, title
   * @param {string} propertyName
   * @returns {Observable<any>}
   */
  getRouteConfigDataProperty(propertyName: string) {

    return this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => {
          const navigationEnd = event as NavigationEnd;
          let snapshot = this.route.snapshot;
          let activated = this.route.firstChild;
          if (activated != null) {
            while (activated != null) {
              snapshot = activated.snapshot;
              activated = activated.firstChild;
            }
          }
          switch (propertyName) {
            case 'stateName':
              return snapshot.data[ 'stateName' ] || this.getStateNameFromUrl(navigationEnd.urlAfterRedirects);
            case 'title':
              return snapshot.data[ 'title' ];
            default:
          }
        })
      );
  }

  /**
   * Fallback to get name from url when no stateName is defined in route data
   *
   * @param {any} url
   * @returns {string}
   */
  getStateNameFromUrl(url): string {

    let name: string;

    const states = url.split('/');

    // take the last part
    name = states[ states.length - 1 ];

    // remove params added with '?'
    name = name.replace(/\?.*$/, '');

    return name;
  }

  /**
   * translates the state to url by replacing '-' in the state with '/', e.g. invoices-service to invoices/service.
   * @param {string} state e.g. invoices-service
   * @returns {string} url e.g. invoices/service
   */
  getUrlFromStateName(state: string): string {
    return  state.replace('-', '/');
  }

  /**
   * Note:- Important
   * During initial loading or refresh (F5), getting active state name from
   * this.state.getActiveStateName is not triggered, hence we get wrong value during initial load (F5)
   * In order to fix this, we get the location path name and then set state name and there after, state name is
   * set from router state subscription.
   * @returns {any}
   */
  getStateNameFromWindowLocation() {

    const pathName = this.windowService.nativeWindow.location.pathname;
    const pathNameSplit = pathName.split('/');

    // for e.g. /equipment/DE_1014839632/overview
    if (_.isEqual(pathNameSplit.length, 4)) {
      return pathNameSplit[1] + '-' + pathNameSplit[3];
    } else if (_.isEqual(pathNameSplit.length, 5)) { // for e.g. /invoices/service/US_200766235/overview
      return pathNameSplit[1] + '-' + pathNameSplit[2];
    } else if (_.isEqual(pathNameSplit.length, 6)) { // for e.g. /equipment/US_SIMULATOR1/ticketHistory/US_SIMULATOR5/overview
      return pathNameSplit[1] + '-' + pathNameSplit[3] + '-' + pathNameSplit[5];
    } else { // for e.g /dashboard
      return pathNameSplit[1];
    }
  }

}
