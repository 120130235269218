import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecurityAdvisoriesNotificationsRestService } from '../../rest-services/security-advisories-notifications-rest.service';
import { SecurityAdvisoryNotification } from '../../models/security-advisory-notification';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SecurityAdvisoryNotificationService {

  advisoryNotificationUpdateSubject = new Subject();

  showCreateAdvisoryNotificationModalEmitter = new EventEmitter<SecurityAdvisoryNotification>();

  constructor(private securityAdvisoriesNotificationsRestService: SecurityAdvisoriesNotificationsRestService) { }

  getAllSecurityAdvisoryNotification(): Observable<SecurityAdvisoryNotification[]> {
    return this.securityAdvisoriesNotificationsRestService.getSecurityAdvisoriesNotifications();
  }

  deleteNotification(notificationId: number) {
    this.clearCache();
    return this.securityAdvisoriesNotificationsRestService.deleteNotification(notificationId);
  }

  createOrEditNotification(body, isEdit: boolean): Observable<any> {
    return isEdit ? this.securityAdvisoriesNotificationsRestService.editNotification(body) :
      this.securityAdvisoriesNotificationsRestService.createNotification(body);
  }

  clearCache() {
    this.securityAdvisoriesNotificationsRestService.clearAdvisoriesNotificationCache();
  }

  emitAdvisoryNotificationUpdated() {
    this.advisoryNotificationUpdateSubject.next();
  }

  showCreateNotificationModal(advisoryNotification: SecurityAdvisoryNotification) {
    this.showCreateAdvisoryNotificationModalEmitter.emit(advisoryNotification);
  }
}
