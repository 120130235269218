import { Component, Input, OnInit, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { Contact } from '../../../core/models/contact';
import { Equipment } from '../../../core/models/equipment/equipment';
import { User } from '../../../core/models/user';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { EquipmentConstantsService } from '../../../core/services/equipment/equipment-constants.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'hl-options-recommend-modal',
  templateUrl: './options-recommend-modal.component.html'
})
export class OptionsRecommendModalComponent extends BaseFormModalPopup implements OnInit, OnChanges {

  @Input() equipmentItem: Equipment;

  translationErrorMessage = 'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';
  statusColorMap: any;

  contact: Contact = {
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: ''
  };

  clinicalSpecialities: any[];
  /** array of numbers */
  selectedClinicalFields = [''];

  formData = {
    clinicalFields: [''],  // the selected fields
    contact: this.contact,
    additionalInformation: '',
    application: [],
    testStartDate: new Date(),
    remoteInstallation: '',
    contactMe: false,
    termsAndCondition: false
  };

  constructor(protected userRestService: UserRestService,
    protected equipmentRestService: EquipmentRestService,
    protected equipmentConstantsService: EquipmentConstantsService,
    protected toasterService: ToasterService,
    protected configService: CountryConfigRestService,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.initProperties();
  }

  initProperties() {
    this.clinicalSpecialities = this.equipmentConstantsService.getClinicalFieldSkeleton();
    this.initForm();

    this.statusColorMap = {};
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      const greenStatus = configResponse.EQUIPMENT_STATUS_GREEN;
      const redStatus = configResponse.EQUIPMENT_STATUS_RED;
      const yellowStatus = configResponse.EQUIPMENT_STATUS_YELLOW;

      // compute the status color mapping from country config
      this.statusColorMap[greenStatus] = 'green';
      this.statusColorMap[redStatus] = 'red';
      this.statusColorMap[yellowStatus] = 'yellow';
    });
  }

  initForm() {
    this.formData.contact = {
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      contactPhone: ''
    };

    this.userRestService.getUser().subscribe((user: User) => {
      this.formData.contact = {
        contactFirstName: user.firstName,
        contactLastName: user.lastName,
        contactEmail: user.email,
        contactPhone: user.phone
      };
    });

    this.formData.application = [];
    this.formData.clinicalFields = [];
    this.selectedClinicalFields = [];
    this.formData.additionalInformation = '';
    this.formData.testStartDate = null;
    this.formData.remoteInstallation = '';
    this.formData.contactMe = false;
    this.formData.termsAndCondition = false;
  }

  /**
   * for the selected ones, move the translation into the formData
   */
  translateClinicalFields() {
    this.selectedClinicalFields.forEach((clinicalFieldId) => {
      this.formData.clinicalFields.push(this.clinicalSpecialities.find((i) => {
        return i.value === clinicalFieldId;
      }).title);
    });
  }

  postFormData() {
    this.resolveObservable(this.equipmentRestService.postRecommentation(this.equipmentItem.key, this.getPreparedPostBody()));
  }

  resolveObservable(observable: Observable<any>) {
    observable.pipe(finalize(() => {
      this.isFormSubmitted = false;

      // reset form
      this.initForm();

      // close modal
      this.hide();
    }))
      .subscribe(() => {
        this.toasterService.showSuccessToaster(true, 'GET_RECOMMENDATION_EMAIL_SUCCESS');
      });
  }

  getPreparedPostBody(): object {
    this.translateClinicalFields();

    return _.omit(this.formData, ['application', 'testStartDate', 'remoteInstallation',
      'contactMe', 'termsAndCondition']);
  }

  validateClinicalSpecialities() {
    this.modalForm.form.setErrors(this.selectedClinicalFields.length === 0 && { err: true});
  }
}
