import { EventLog } from '../models/event-log';
import { Subscription } from 'rxjs';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { LifeNetUtilService } from '../utils/life-net-util.service';
import { ActivatedRoute } from '@angular/router';
import { LogService } from '../services/log/log.service';
import { StringUtilService } from '../utils/string-util.service';
import { NotifStatus } from '../core-constants.service';

export abstract class BaseItemDetailView<T> {

  datePattern = '';
  dateTimePattern = '';

  isLoaded: boolean;
  longText;
  eventLogs: EventLog[];

  // subscriptions
  activatedRouteParamSubscription: Subscription;
  itemsUtilServiceSubscription: Subscription;
  configServiceSubscription: Subscription;

  configService: CountryConfigRestService;
  lifeNetUtilService: LifeNetUtilService;
  activatedRoute: ActivatedRoute;
  logService: LogService;
  stringUtilService: StringUtilService;

  constructor(configService: CountryConfigRestService,
    lifeNetUtilService: LifeNetUtilService,
    activatedRoute: ActivatedRoute,
    logService: LogService,
    stringUtilService: StringUtilService) {

    this.configService = configService;
    this.lifeNetUtilService = lifeNetUtilService;
    this.activatedRoute = activatedRoute;
    this.logService = logService;
    this.stringUtilService = stringUtilService;
  }

  abstract afterInitProperties(): void;

  abstract afterConfigProperties(config: any);

  abstract afterLoadViewModelItem(config: any): void;

  init() {

    this.initProperties();
    this.configServiceSubscription = this.configService.getConfig().subscribe((configResponse) => {
      this.setConfigProperties(configResponse);
      this.setViewModelItemsForList(configResponse);
    }, (some) => {
      this.logService.debug('what is the problem?13 ' + some);
    });
  }

  destroy() {

    if (this.activatedRouteParamSubscription) {
      this.activatedRouteParamSubscription.unsubscribe();
    }

    if (this.itemsUtilServiceSubscription) {
      this.itemsUtilServiceSubscription.unsubscribe();
    }

    if (this.configServiceSubscription) {
      this.configServiceSubscription.unsubscribe();
    }
  }

  initProperties() {

    this.longText = [];
    this.eventLogs = [];

    // additional properties for the derived class
    this.afterInitProperties();

  }

  setConfigProperties(config) {

    // get the locale date pattern
    this.datePattern = config.GENERIC_DATE_PATTERN;
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;

    // additional config properties for derived class
    this.afterConfigProperties(config);

  }

  setViewModelItemsForList(config: any) {
    this.activatedRouteParamSubscription = this.activatedRoute.params.subscribe(
      (params: any) => {

        const id = params['ticketKey'] || params['id'];
        this.isLoaded = false;

        // Note:- since only router change is again triggered, we need to reset variables
        this.initProperties();
        this.setViewModelItems(config, id);
      }, (some) => {
        this.logService.debug('what is the problem? 2 ' + some);
      });
  }

  abstract setViewModelItems(config: any, id: string);

  setViewModelProperties(config, item) {

    this.setLongTextAndEventLogs(item);

    // additional setting for derived class
    this.afterLoadViewModelItem(config);

  }

  setLongTextAndEventLogs(item) {

    // set and parse longText for line breaks
    if (item.longText) {

      this.longText =
        this.stringUtilService.parseAndSplitInputStringToLines(item.longText);
    }

    this.eventLogs = item.eventLogs;
  }

  abstract getNotifStatus(): NotifStatus;
}
