import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { SeriesData } from './diagram';
import { BarVerticalComponent } from '@swimlane/ngx-charts';

@Component({
  selector: 'hl-widget-diagram-bars',
  template: `    
    <div class="diagram">
      <div class="aspect-ratio-4x3 aspect-ratio-md-1x1 aspect-ratio-lg-4x3 display-flex">
        <hl-diagram-bar-vertical-candles #chart [view]="view ? view : ''" [roundDomains]="true" [roundEdges]="false" [gradient]="gradient"
          [customColors]="customColors" [results]="results" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="false"
          [showGridLines]="showGridLines" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel" [yScaleMax]="yScaleMax" [scheme]="scheme" [yAxisTickFormatting]="yAxisTickFormatting"
          [animations]="animations" [barPadding]="barPadding" (select)="onSelected($event)">
          <ng-template #tooltipTemplate let-model="model">
            <div class="area-tooltip-container font-size-small" >
              {{model.extra.highestAvgSystemLabel}}<br>
              {{model.extra.avgSystemModalityLabel}}<br>
              {{model.extra.lowestAvgSystemLabel}}
            </div>
          </ng-template>
        </hl-diagram-bar-vertical-candles>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetDiagramBarsComponent {
  @ViewChild('chart', {static: false})
  private chart: BarVerticalComponent;

  @Input() scheme: any;
  @Input() yAxisTickFormatting: any;
  @Input() animations: boolean;
  /**
   * Show the x axis names
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showXAxis = true;
  /**
   * show the y axis names
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showYAxis = true;
  /**
   * show Gridlines
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showGridLines = true;
  /**
   * Show label for x axis (need a value in xAxisLabel)
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showXAxisLabel = false;
  /**
   * Show label for y axis (need a value in yAxsisLabel)
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showYAxisLabel = false;
  /**
   * Label x axsis
   *
   * @type {string}
   * @memberof LclDiagramBarsComponent
   */
  @Input() xAxisLabel: string;
  /**
   * Label y axis
   *
   * @type {string}
   * @memberof LclDiagramBarsComponent
   */
  @Input() yAxisLabel: string;
  /**
   * set a scale maxium
   *
   * @type {number}
   * @memberof LclDiagramBarsComponent
   */
  @Input() yScaleMax: number;
  /**
   * Colors aof the bars are gradient
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() gradient = true;
  /**
   * Show a label of the value in the top aof a chart
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showDataLabel = false;

  /**
   * Switch chart colors
   *
   * @memberof LclDiagramDoughnutComponent
   */
  @Input() useMonochrome = false;
  /**
   * Results for the diagram rendering
   *
   * @type {SeriesData[]}
   * @memberof DiagramComponent
   */
  @Input() results: SeriesData[] = [];
  /**
   * Options for a view of the diagram as array with [width,height]
   *
   * @type {[number, number]}
   * @memberof DiagramComponent
   */
  @Input() view: number[] = null;

  /**
   * Emitted when any bar is clicked. The data contains the name of clicked series.
   */
  @Output() onSelect: EventEmitter<{ series }> = new EventEmitter<{ series }>();

  /**
   * Placeholder array for colors
   *
   * @type {object[]}
   * @memberof DiagramComponent
   */
  @Input() customColors: object[] = [];

  @ContentChild('tooltipTemplate', {static: false}) tooltipTemplate: TemplateRef<any>;

  /**
   * Creates an instance of DiagramComponent.
   * @memberof DiagramComponent
   */
  constructor() {
  }

  get barPadding(): number {
    return this.chart && this.chart.width ? Math.ceil(this.chart.width / this.results.length * .7) : 45;
  }

  onSelected($event) {
    this.onSelect.emit($event);
  }
}
