import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toHtml'
})
export class ToHtmlPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return (value || '').replace(/\n|\r\n/g, '<br>');
  }

}
