import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';

interface HttpErrorType {
  partOfUrl: string;
  status: number;
}

@Injectable()
export class HttpIgnoredErrors {

  private ignoredErrors: HttpErrorType[] = [];

  public putErrorType(partOfUrl: string, status: number) {
    if (!this.isIgnoredAlready({partOfUrl, status})) {
      this.ignoredErrors.push({partOfUrl, status});
    }
  }

  isIgnoredAlready(errorType: HttpErrorType): boolean {
    return this.ignoredErrors.find(ignoredError => ignoredError.status === errorType.status
      && ignoredError.partOfUrl === errorType.partOfUrl) !== undefined;
  }

  public shallBeIgnored(response: HttpErrorResponse): boolean {
    return this.ignoredErrors.find(ignoredError => response.status === ignoredError.status
      && _.includes(response.url, ignoredError.partOfUrl)) !== undefined;
  }
}
