import { Observable } from 'rxjs';
import { DeleteFavoriteModalComponent } from '../shared/modal-popup/delete-favorite-modal/delete-favorite-modal.component';
import { RenameFavoriteModalComponent } from '../shared/modal-popup/rename-favorite-modal/rename-favorite-modal.component';
import { AddFavoriteModalComponent } from '../shared/modal-popup/add-favorite-modal/add-favorite-modal.component';
import { TicketActionsComponent } from '../shared/components/ticket-actions/ticket-actions.component';
import { UserUtilService } from '../core/services/user/user-util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketsConstantsService } from '../core/services/tickets/tickets-constants.service';
import { EquipmentUtilService } from '../core/services/equipment/equipment-util.service';
import { TicketsUtilService } from '../core/services/tickets/tickets-util.service';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { TicketViewModel } from '../core/view-models/ticket-view-model';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { LifeNetUtilService } from '../core/utils/life-net-util.service';

import { cloneDeep, filter, forEach, intersection, isEmpty, isEqual, remove, values } from 'lodash';
import { BaseListView } from '../core/base-class/base-list-view';
import { StateService } from '../core/services/state.service';
import { SelectOption } from '../core/models/select-option';
import { NotifStatus } from '../core/core-constants.service';
import { TicketsCacheService } from '../core/services/cache/tickets-cache.service';
import { DateUtilService } from '../core/utils/date-util.service';
import { LogService } from '../core/services/log/log.service';
import { takeUntil } from 'rxjs/operators';
import { OverlayComponent } from '../shared/components/overlay/overlay.component';
import { DatePipeWrapperPipe } from '../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { BrowserStateService } from '../core/services/browser-state.service';
import { FilterUtilService } from '../core/utils/filter-util.service';
import { ModalityUtilService } from 'app/core/utils/modality-util.service';
import { RefreshItemEventService } from '../core/component-communication-services/refresh-item-event/refresh-item-event.service';
import { LogisticMilestonesUtilService } from '../core/services/logistic-milestones/logistic-milestones-util.service';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';

@Component({
  selector: 'hl-tickets',
  templateUrl: './tickets.component.html'
})
export class TicketsComponent extends BaseListView<TicketViewModel> implements AfterViewInit, OnDestroy {

  @ViewChild('actions', { static: false })
  actions: TicketActionsComponent;
  @ViewChild('detailOverlay', { static: false })
  detailOverlay: OverlayComponent;
  @ViewChild('overviewTab', { static: false })
  overviewTab: ElementRef;
  @ViewChild('historyTab', { static: false })
  historyTab: ElementRef;
  @ViewChild('addFavoriteModal', { static: false })
  addFavoriteModal: AddFavoriteModalComponent;
  @ViewChild('renameFavoriteModal', { static: false })
  renameFavoriteModal: RenameFavoriteModalComponent;
  @ViewChild('deleteFavoriteModal', { static: false })
  deleteFavoriteModal: DeleteFavoriteModalComponent;
  @ViewChild(InfiniteScrollDirective, { static: false })
  infiniteScroll: InfiniteScrollDirective;

  showDangerForPatients: boolean;
  showServiceReport: boolean;

  rollingMonthsFromFilterTickets: string;
  equipmentList = [];

  // Skeleton for selected multiSelect dropdown
  selectedDropDownList = {
    productName: [],
    myEquipmentName: [],
    problemSeverityDescription: [],
    siemensEquipmentId: [],
    typeID: [],
    modality: [],
    customerName: []
  };

  selectedProblemSeverityID: string[] = [];

  // options for multiSelect dropdown
  optionsDropDownList = {
    products: [],
    myEquipmentNames: [],
    siemensEquipmentIds: [],
    modalities: [],
    customerNames: []
  };

  dangerForPatient = [];
  dangerForPatientOptions = [];
  ticketTypes = [];
  operationalStateDescriptions: SelectOption[] = [];
  closedNotificationsIdentifiers = [];
  openNotificationsIdentifiers = [];

  openCloseOptions: SelectOption[];
  openCloseOptionsSelected: string[] = [NotifStatus.OPEN];
  openCloseSelected: NotifStatus;

  // item for ticket actions (update, close, handover)
  ticketItemForActions: TicketViewModel;
  operationalStateFilterOptions: string[];

  statusOptions: any[] = [];

  listType = 'tickets';

  ticketStatusYellow: string[];
  ticketStatusRed: string[];
  toggleCustomerName = false;
  customerNameLength = undefined;
  refreshButtonRender = false;

  deliveryMilestonesAvailable = false;
  isDeliveryMilestonesAllowedForCountry = false;
  isSelectedItemOpen = false;

  dateFilterChanged = false;

  constructor(
    router: Router,
    private route: ActivatedRoute,
    private ticketsUtilService: TicketsUtilService,
    private ticketsConstantsService: TicketsConstantsService,
    private ticketsCacheService: TicketsCacheService,
    private dateUtilsService: DateUtilService,
    private refreshItemEventService: RefreshItemEventService,
    public lifeNetUtilService: LifeNetUtilService,
    configService: CountryConfigRestService,
    equipmentUtilService: EquipmentUtilService,
    userUtilService: UserUtilService,
    stateService: StateService,
    logService: LogService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    browserStateService: BrowserStateService,
    filterUtilService: FilterUtilService,
    private modalityUtil: ModalityUtilService,
    private milestonesUtilService: LogisticMilestonesUtilService,
    private myFiltersService: MyFiltersAdapterService
  ) {
    super(
      configService,
      equipmentUtilService,
      userUtilService,
      stateService,
      logService,
      router,
      browserStateService,
      filterUtilService
    );
  }

  ngAfterViewInit() {
    this.init();
  }

  init() {
    super.init();
  }

  registerEventListeners() {
    this.myFiltersService.status$.pipe(takeUntil(this.unsubscribe$)).subscribe(status => {
      this.myEquipmentProfileList = status.equipment.map(e => e.key);
      this.myEquipmentChecked = status.overallSwitch;
      this.optionsDropDownList.modalities =
        this.filterUtilService.computeModalityOptions(this.rawList, this.getFilterObject().myEquipment);
      this.onAdvancedFilterChange();
    });
  }

  ngOnDestroy() {
    this.ticketsCacheService.setSelectedOpenClosedStatus(NotifStatus.OPEN);
  }

  getEmptyListUrl(): string {
    return '/tickets';
  }

  openDetailOverlay(item: TicketViewModel) {
    this.updateSelectedItemAndAddress(item);
    const state = this.currentStateName.endsWith('-history')
      ? 'history'
      : 'overview';
    if (this.historyTab && this.overviewTab) {
      if (state === 'history') {
        this.historyTab.nativeElement.click();
      } else {
        this.overviewTab.nativeElement.click();
      }
    }
    this.detailOverlay.show();
  }

  /**
   * @description Additional properties to be initialized for derived class
   */
  afterInitProperties() {
    this.ticketItemForActions = null;

    this.sortSkeleton = this.ticketsConstantsService.getSortSkeleton();
    this.showDangerForPatients = false;
    this.showServiceReport = false;

    this.openCloseOptions = this.ticketsConstantsService.getOpenCloseSkeleton();
    this.openCloseSelected = this.ticketsCacheService.getSelectedOpenClosedStatus();
    this.operationalStateFilterOptions = [];
    this.equipmentUtilService
      .getEquipmentViewModelList()
      .subscribe(response => {
        this.equipmentList = response;
      });
    this.toggleCustomerName = false;
  }

  /**
   * @description Additional config properties to be initialized for derived class
   */
  afterConfigProperties(config) {
    // Check if service report is shown or not
    if (isEqual(config.SERVICE_REPORT_RENDER, 'true')) {
      this.showServiceReport = true;
    }

    this.closedNotificationsIdentifiers = config.STATUS_IDENTIFIER_CLOSED_NOTIFICATIONS.split(',');
    this.openNotificationsIdentifiers = config.STATUS_IDENTIFIER_OPEN_NOTIFICATIONS.split(',');
    this.ticketStatusYellow = config.TICKET_STATUS_YELLOW.split(',');
    this.ticketStatusRed = config.TICKET_STATUS_RED.split(',');

    this.refreshButtonRender = isEqual(config.NOTIFICATIONS_REFRESH_BUTTON_FEATURE_TOGGLE, 'true');

    /**
     * Hide danger for patient.
     * Note:- All configuration should be handled here for danger for patients
     */
    const dangerForPatient = config.TICKET_DANGER_FOR_PATIENT_RENDER;
    if (isEqual(dangerForPatient, 'false')) {
      remove(this.sortSkeleton.items, { title: 'PATIENT_SITUATION' });
    } else {
      this.showDangerForPatients = true;
    }

    this.searchInput = '';

    this.rollingMonthsFromFilterTickets =
      config.ROLLING_MONTHS_FROM_FILTER_CLOSED_TICKETS;
    this.operationalStateFilterOptions = config.OPERATIONAL_STATE_FILTER_OPTIONS.split(
      ','
    );

    // status (open/closed)
    this.ticketsConstantsService.getStatusSkeleton().subscribe(response => {
      this.statusOptions = response;
    });

    this.initFilterByStatusSelect();
    this.onAdvancedFilterChange();

    // check if closed tickets are there for a country
    if (isEqual(config.SHOW_FILTER_CLOSED_SERVICE_TICKETS, 'true')) {
      this.preLoadClosedTickets();
    }
    this.toggleCustomerName = isEqual(config.TOGGLE_CUSTOMER_NAME_TICKET_LIST, 'true');
    this.customerNameLength = config.LENGTH_CUSTOMER_NAME_DISPLAY > 0 ? config.LENGTH_CUSTOMER_NAME_DISPLAY : undefined;

    if (!this.toggleCustomerName) {
      remove(this.sortSkeleton.items, { title: 'GENERIC_LABEL_CUSTOMER_NAME' });
    }
    this.isDeliveryMilestonesAllowedForCountry = isEqual(config.TOGGLE_NOTIFICATION_LOGISTIC_MILESTONES, 'true');
  }

  onDateFilterChange() {
    this.dateFilterChanged = true;
  }

  preLoadClosedTickets() {
    // pre-loading closed activities in the back ground, for handling history back as agreed with Bastian
    this.ticketsUtilService
      .getTicketsViewModelList(NotifStatus.CLOSED)
      .pipe(this.modalityUtil.translateModality())
      .subscribe(
        () => {
          // just pre-loading for location back (Note:- please don't change this implementation until you know it)
        },
        () => {
          this.logService.log('Loading of closed tickets is not possible');
        }
      );
  }

  getPageSizeConfig(config): string {
    return config.OPEN_TICKETS_LIST_PAGE_SIZE;
  }

  /**
   * @description Get and initialize view model for tickets list by merging tickets and equipment
   */
  loadViewModelList(): Observable<TicketViewModel[]> {
    const notifStatus = this.ticketsCacheService.getSelectedOpenClosedStatus();

    return this.ticketsUtilService.getTicketsViewModelList(notifStatus)
      .pipe(this.modalityUtil.translateModality());
  }

  afterInitViewModelList() {
    this.registerEventListeners();
    this.handleNavigation();
  }

  handleNavigation() {
    if (this.route.firstChild) {
      this.onAdvancedFilterChange();
      const key = this.route.firstChild.snapshot.params.id;
      if (key) {
        const item = this.viewModelList.find(i => i.ticketKey === key);
        this.openDetailOverlay(item);
      }
    }
  }

  /**
   * @description Initialize the advanced drop down list option
   */
  initAdvancedFilterDropDownList() {
    this.dangerForPatientOptions = this.ticketsConstantsService.getDangerForPatientsSkeleton();

    this.ticketTypes = this.ticketsUtilService.getTicketTypes(this.rawList);

    this.ticketsUtilService.getOperationalStateDescriptions(this.operationalStateFilterOptions)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.operationalStateDescriptions = data;
        this.reloadOperationalStateDescriptions();
      });

    const dropDownListStructure = {
      products: 'productName',
      myEquipmentNames: 'myEquipmentName',
      siemensEquipmentIds: 'siemensEquipmentId',
      modalities: 'modality',
      customerNames: 'customerName'
    };

    const dropDownOptions = this.filterUtilService.computeDropdownOptions(
      this.rawList,
      dropDownListStructure
    );
    this.optionsDropDownList.products = dropDownOptions.products;
    this.optionsDropDownList.myEquipmentNames = dropDownOptions.myEquipmentNames;
    this.optionsDropDownList.siemensEquipmentIds = dropDownOptions.siemensEquipmentIds;
    this.optionsDropDownList.modalities = dropDownOptions.modalities;
    this.optionsDropDownList.customerNames = dropDownOptions.customerNames;
    this.onAdvancedFilterChange();
  }

  reloadOperationalStateDescriptions() {
    if (this.operationalStateDescriptions) {
      const operationalStateDescsInList =
        this.filterUtilService.getListOfPropertyValuesFromListOfObject(this.rawList, 'problemSeverityID');

      this.operationalStateDescriptions = this.operationalStateDescriptions.filter(item =>
        !isEmpty(intersection(item.value.split(','), values(operationalStateDescsInList))));
    }
  }

  /**
   * @description Filter object with different filters
   */
  getFilterObject(): any {
    this.selectedDropDownList.problemSeverityDescription = this.ticketsUtilService
      .mapProblemIdToProblemDescription(this.selectedProblemSeverityID, this.operationalStateDescriptions);

    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: [
          'productName',
          'siemensEquipmentId',
          'ticketNumber',
          'description',
          'myEquipmentName',
          'customerName'
        ]
      },
      myEquipment: {
        isMyEquipmentChecked: this.myEquipmentChecked,
        keyName: 'equipmentKey',
        myEquipmentList: this.myEquipmentProfileList
      },
      advancedMultiSelect: this.selectedDropDownList,
      orderBy: this.sortSkeleton.sortObject,
      dateRange: {
        rangeOfDate: this.dateRange,
        propertyKey: 'ticketCreationTimestamp'
      },
      dangerForPatient: this.dangerForPatient,
      openCloseOptionsSelected: this.openCloseOptionsSelected,
      selectedProblemSeverityID: this.selectedProblemSeverityID
    };
  }

  navigate() {
    this.isDeliveryMilestonesAvailable();
    const currentStateNames = this.currentStateName.split('-');
    // check if not default value and has a specific state name
    if (currentStateNames.length > 1) {
      this.router.navigate([
        '/tickets',
        this.selectedItem.ticketKey,
        currentStateNames[1]
      ], {
        queryParams: currentStateNames[1] === 'delivery' ?
          {
            'ticketNumber': this.selectedItem.ticketNumber,
            'ticketType': this.selectedItem.typeID
          } : undefined
      });
    } else {
      this.router.navigate([
        '/tickets',
        this.selectedItem.ticketKey,
        'overview'
      ]);
    }
  }

  /**
   *
   * @param ticketItem | Ticket View Model
   * @param action | action type (convert, update,close)
   */
  action(ticketItem, action) {
    this.ticketItemForActions = ticketItem;
    this.actions.openTicketActionModal(action);
  }

  /**
   * @description set properties from router query params
   */
  setPropertiesFromQueryParams() {
    this.route.queryParams.subscribe(
      params => {
        // get ticket identifier
        const ticketIdentifier = params['ticketIdentifier'];
        if (ticketIdentifier) {
          this.searchInput = ticketIdentifier;
          this.initialListMode = false;
        }

        const operationalState = params['operationalState'];
        if (operationalState) {
          this.selectedProblemSeverityID.push(operationalState);
        }

      },
      some => {
        this.logService.debug('what is the problem? 6 ' + some);
      }
    );
  }

  /**
   * @description additional properties to set from selected favorite
   * @param favoriteObj
   */
  setDerivedBoundPropertiesFromFavorite(favoriteObj: any) {
    if (favoriteObj) {
      if (favoriteObj['dangerForPatient']) {
        this.dangerForPatient = favoriteObj['dangerForPatient'];
      }

      if (favoriteObj['openCloseOptionsSelected']) {
        this.openCloseOptionsSelected = favoriteObj['openCloseOptionsSelected'];
      }

      this.onFilterChange();

      if (favoriteObj['advancedMultiSelect']) {
        this.selectedDropDownList = favoriteObj['advancedMultiSelect'];
        // remove selected values taken from applied filter which are not in optionsLists
        this.selectedDropDownList.productName = intersection(
          this.selectedDropDownList.productName,
          this.optionsDropDownList.products.map(p => p.value)
        );
        this.selectedDropDownList.myEquipmentName = intersection(
          this.selectedDropDownList.myEquipmentName,
          this.optionsDropDownList.myEquipmentNames.map(p => p.value)
        );
        this.selectedDropDownList.siemensEquipmentId = intersection(
          this.selectedDropDownList.siemensEquipmentId,
          this.optionsDropDownList.siemensEquipmentIds.map(p => p.value)
        );
        this.selectedDropDownList.modality = intersection(
          this.selectedDropDownList.modality,
          this.optionsDropDownList.modalities.map(p => p.value)
        );
        this.selectedDropDownList.typeID = intersection(
          this.selectedDropDownList.typeID,
          this.ticketTypes.map(p => p.value)
        );
      }

      if (favoriteObj['selectedProblemSeverityID']) {
        this.selectedProblemSeverityID = favoriteObj['selectedProblemSeverityID'];
      }
    }
  }

  /**
   *
   * Note:- Cannot be moved to Base List
   * https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
   * @param index
   * @param {TicketViewModel} item
   * @returns {any}
   */
  trackByFn(index, item: TicketViewModel) {
    return item['ticketKey'];
  }

  /**
   * @description toggle open / closed dropdown for ticket type
   */
  toggleOpenClosed(option: any) {
    if (this.openCloseSelected === option) {
      return;
    }
    this.openCloseSelected = option;

    this.ticketsCacheService.setSelectedOpenClosedStatus(this.openCloseSelected);

    // to unsubscribe, because on init() everything is subscribed again
    this.unsubscribeAll();

    // save last search phrase
    const lastSearch = this.searchInput;

    // this did't help this.router.navigate(['/tickets']);
    this.init();

    // load last search phrase back to the search input field
    this.searchInput = lastSearch;
  }

  onFilterChange() {
    if (this.openCloseOptionsSelected && this.openCloseOptionsSelected.length === 1) {
      this.toggleOpenClosed(this.openCloseOptionsSelected[0]);
    } else {
      this.toggleOpenClosed(NotifStatus.ALL);
    }
  }

  /**
   * @description clearing all the advanced filters and setting the rolling month filter, if the ticket status is closed
   */
  initFilterByStatusSelect() {
    this.selectedDropDownList = {
      productName: [],
      myEquipmentName: [],
      problemSeverityDescription: [],
      siemensEquipmentId: [],
      typeID: [],
      modality: [],
      customerName: []
    };
    this.dangerForPatient = [];

    if (this.dateFilterChanged && this.dateRange.fromDate === null && this.dateRange.toDate === null) {
      this.dateFilterChanged = false;
    }

    if (!this.dateFilterChanged) {
      if (isEqual(this.openCloseSelected, NotifStatus.CLOSED)) {
        const currentDate = new Date();
        const fromDate = this.dateUtilsService.getDateByRollingMonth(
          currentDate,
          this.rollingMonthsFromFilterTickets
        );
        this.dateRange.fromDate = fromDate;
        this.dateRange.toDate = currentDate;
      } else {
        this.dateRange.fromDate = null;
        this.dateRange.toDate = null;
      }
    }
    this.onAdvancedFilterChange();
  }

  getExportList = () => {
    if (this.listWithoutPagination && this.equipmentList) {
      const exportListViewModel = cloneDeep(this.listWithoutPagination);
      forEach(exportListViewModel, item => {
        const selectedItem = filter(this.equipmentList, {
          key: item.equipmentKey
        });

        if (!isEmpty(selectedItem)) {
          const propertiesToMerge = [
            'customerName',
            'department',
            'customerDescription'
          ];
          item = this.lifeNetUtilService.mergeObjects(
            selectedItem[0],
            item,
            propertiesToMerge
          );
        }

        const modalityDescription = filter(
          this.optionsDropDownList.modalities,
          { value: item.modality }
        );
        if (!isEmpty(modalityDescription)) {
          item['modality'] = modalityDescription[0].title;
        }
        if (item.completedDate) {
          item['completedDate'] = this.datePipeWrapperPipe.transform(
            new Date(item['completedDate']),
            this.datePattern
          );
        }
      });
      return exportListViewModel;
    }
    return [];
  }

  onTabClick(key, state) {
    this.browserStateService.setUserNavigation();
    this.router.navigate([key, state], {
      relativeTo: this.route, replaceUrl: true, queryParams: state === 'delivery' ?
        {
          'ticketNumber': this.selectedItem.ticketNumber,
          'ticketType': this.selectedItem.typeID
        } : undefined
    }).then(() => {
      this.browserStateService.resetUserNavigation();
    });
  }

  refreshTicket() {
    this.refreshItemEventService.emitRefreshItem();
  }

  isDeliveryMilestonesAvailable() {
    this.deliveryMilestonesAvailable = false;
    if (this.isDeliveryMilestonesAllowedForCountry && this.milestonesUtilService.isTicketTypeAllowed(this.selectedItem.typeID)) {
      this.milestonesUtilService.getDeliveryMilestones(this.selectedItem.ticketNumber).subscribe(response => {
        this.deliveryMilestonesAvailable = response.length > 0;
        this.navigateToOverview();
      });
    } else {
      this.navigateToOverview();
    }
  }

  navigateToOverview() {
    if (this.currentStateName === 'tickets-delivery' && this.deliveryMilestonesAvailable === false) {
      this.currentStateName = 'overview';
      this.onTabClick(this.selectedItem.ticketKey, 'overview');
    }
  }

  updateSelectionAndAddressByIndex(selectedIndex: number) {
    this.updateSelectedItemAndAddress(this.viewModelList[selectedIndex]);
  }

  private updateSelectedItemAndAddress(item: TicketViewModel) {
    this.onClickUpdateSelectedItem(item);

    this.isSelectedItemOpen = this.openNotificationsIdentifiers.includes(this.selectedItem.ticketStatus);
    this.ticketsUtilService.selectedTicketAddress = {
      locationName: item.locationName,
      city: item.city,
      street: item.street,
      state: item.state,
      zipCode: item.zipCode
    };
  }

  loadMore(extraItems?: number) {
    super.loadMore(extraItems);
    this.infiniteScroll.ngOnDestroy();
    this.infiniteScroll.setup();
  }
}
