import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router, RouterOutlet } from '@angular/router';
import { DocumentsSourceOption } from '../../../../core/models/equipment/documents/documents-source-option';

@Component({
  selector: 'hl-equipment-documents-overlay',
  templateUrl: './equipment-documents-overlay.component.html'
})
export class EquipmentDocumentsOverlayComponent implements OnInit {

  @Input() currentStateName: string;
  @Output() currentStateNameChange = new EventEmitter();

  @Input() documentsSourceOptions: DocumentsSourceOption[];
  @ViewChild('outlet', {static: true}) routerOutlet: RouterOutlet;
  baseRoutingUrl: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      const routerUrlLastSegment = this.router.url.includes('/') ? this.router.url.slice(this.router.url.lastIndexOf('/') + 1) : undefined;
      if (routerUrlLastSegment && this.documentsSourceOptions.some(option => option.id === routerUrlLastSegment)) {
        this.baseRoutingUrl = this.router.url.slice(0, this.router.url.lastIndexOf('/'));
      } else {
        this.baseRoutingUrl = this.router.url;
      }
    });

    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart && e.snapshot.data.stateName.startsWith('equipment-documents')) {
        this.routerOutlet.deactivate();
      }
    });
  }

  changeCurrentStateName(optionName: string) {
    this.currentStateNameChange.emit(
      this.activatedRoute.routeConfig.data.stateName + ((!!optionName) ? ('-' + optionName) : ''));
  }

  isTabActive(option: DocumentsSourceOption): boolean {
    return this.currentStateName.endsWith('-' + option.id);
  }
}
