import { TicketDetailEventLogsTabComponent } from './ticket-details/ticket-detail-event-logs-tab/ticket-detail-event-logs-tab.component';
import { TicketDetailOverviewTabComponent } from './ticket-details/ticket-detail-overview-tab/ticket-detail-overview-tab.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { TicketsComponent } from './tickets.component';
import { TicketDetailHistoryAndEventLogsComponent } from './ticket-details/ticket-detail-history-and-event-logs/ticket-detail-history-and-event-logs.component';
import { TicketDetailDeliveryMilestonesTabComponent } from './ticket-details/ticket-detail-delivery-milestones-tab/ticket-detail-delivery-milestones-tab.component';
import { TicketsAuthGuardService } from '../core/auth-guards/tickets/tickets-auth-guard.service';

const ticketsRoutes: Routes = [
  {
    path: 'tickets',
    component: TicketsComponent,
    children: [
      {
        path: ':id/overview',
        component: TicketDetailOverviewTabComponent,
        data: {stateName: 'tickets-overview'}
      },
      {
        path: ':id/history',
        component: TicketDetailHistoryAndEventLogsComponent,
        data: {stateName: 'tickets-history-and-logs'}
      },
      {
        path: ':id/logs',
        component: TicketDetailEventLogsTabComponent,
        data: {stateName: 'tickets-logs'}
      },
      {
        path: ':id/delivery',
        component: TicketDetailDeliveryMilestonesTabComponent,
        data: {stateName: 'tickets-delivery'}
      }
    ],
    canActivate: [TicketsAuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(ticketsRoutes)],
  exports: [RouterModule]
})
export class TicketsRoutingModule {
}
