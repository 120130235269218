import {
  DeactivateEquipmentModalComponent,
  DeactivateFormModel
} from '../shared/modal-popup/deactivate-equipment-modal/deactivate-equipment-modal.component';
import { DeleteFavoriteModalComponent } from '../shared/modal-popup/delete-favorite-modal/delete-favorite-modal.component';
import { RenameFavoriteModalComponent } from '../shared/modal-popup/rename-favorite-modal/rename-favorite-modal.component';
import { AddFavoriteModalComponent } from '../shared/modal-popup/add-favorite-modal/add-favorite-modal.component';
import { UserUtilService } from '../core/services/user/user-util.service';
import { combineLatest, Observable } from 'rxjs';
import { UpdateEquipmentEventService } from '../core/component-communication-services/update-equipment-event/update-equipment-event.service';
import { CENTRICARE_EQUIPMENT_STATUS_CRITICAL, EquipmentConstantsService } from '../core/services/equipment/equipment-constants.service';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { EquipmentViewModel } from '../core/view-models/equipment-view-model';
import { EquipmentUtilService } from '../core/services/equipment/equipment-util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentRestService } from '../core/rest-services/equipment-rest.service';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { cloneDeep, difference, filter, find, forEach, isEqual, remove } from 'lodash';
import { BaseListView } from '../core/base-class/base-list-view';
import { green, red, roles, yellow } from '../core/core-constants.service';
import { StateService } from '../core/services/state.service';
import { LogService } from '../core/services/log/log.service';
import { EditEquipmentInfoModalComponent } from '../shared/modal-popup/edit-equipment-info-modal/edit-equipment-info-modal.component';
import { LifeNetUtilService } from '../core/utils/life-net-util.service';
import { SystemUpdatesService } from '../core/services/system-updates/system-updates-service';
import { CreateTicketEventService } from '../core/component-communication-services/create-ticket-event/create-ticket-event.service';
import { TicketsConstantsService } from '../core/services/tickets/tickets-constants.service';
import { BrowserStateService } from '../core/services/browser-state.service';
import { FilterUtilService } from '../core/utils/filter-util.service';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DateRange } from 'app/core/models/date-range';
import { Favorite } from 'app/core/models/favorite';
import { EquipmentListViewModel } from '../core/view-models/equipment-list-view-model';
import { EquipmentInformation } from '../core/models/equipment/equipment-information';
import { TranslateService } from '@ngx-translate/core';
import { DocumentSourceRestService } from '../core/rest-services/document-source-rest.service';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';
import { enhance } from 'app/core/operators';
import { TeamplayRestService } from '../core/rest-services/teamplay-rest.service';
import { TabsComponent } from '../shared/components/tabs/tabs.component';

const rolesToCheck = {
  checkITAdminRole: roles.itAdminRole,
  checkCybersecurityDashboardRole: roles.cybersecurityDashboardRole,
  checkViewTicketRole: roles.viewTicketRole,
  checkPlannedActivityRole: roles.viewPlannedActivityRole,
  checkViewContractRole: roles.viewContractRole
};

const translateKeys = {
  FILTER_CONTRACT_AVAILABILITY_YES: 'FILTER_CONTRACT_AVAILABILITY_YES',
  FILTER_CONTRACT_AVAILABILITY_NO: 'FILTER_CONTRACT_AVAILABILITY_NO'
};

const EQUIPMENT_URL = '/equipment';

@Component({
  selector: 'hl-equipment',
  templateUrl: './equipment.component.html'
})
export class EquipmentComponent extends BaseListView<EquipmentListViewModel | EquipmentInformation>
  implements OnInit, OnDestroy {
  @ViewChild('editInfoModal', {static: false})
  editInfoModal: EditEquipmentInfoModalComponent;
  @ViewChild('deactivateModal', {static: false})
  deactivateModal: DeactivateEquipmentModalComponent;
  @ViewChild('addFavoriteModal', {static: false})
  addFavoriteModal: AddFavoriteModalComponent;
  @ViewChild('renameFavoriteModal', {static: false})
  renameFavoriteModal: RenameFavoriteModalComponent;
  @ViewChild('deleteFavoriteModal', {static: false})
  deleteFavoriteModal: DeleteFavoriteModalComponent;
  @ViewChild('equipmentTabs', {static: false})
  equipmentTabs: TabsComponent;

  statusColorMap: any;

  // operational states variables
  operationalStatusConfig: any;
  operationalStatus = [];
  selectedOperationalStatus = [];

  viewport: string;
  isGoogleMapsFullView = false;
  showDocumentsTab = false;
  showSystemUpdatesTab = false;
  showSecurityTabConfig = false;
  showSecurityTabRole = false;
  showTrainingsTab = false;
  checkAllStates = true;
  redirectToTickets = false;
  deepLinkTab: string;
  deepLinkSearch: string;

  eosDateRange: DateRange = {
    fromDate: null,
    toDate: null
  };

  // Skeleton for selected multiSelect dropdown
  selectedDropDownList = {
    productName: [],
    myEquipmentName: [],
    department: [],
    street: [],
    city: [],
    customerName: [],
    modalityTranslation: [],
    contractAvailability: [],
    srsStatus: []
  };

  // options for multiselect dropdown
  optionsDropDownList = {
    products: [],
    myEquipmentNames: [],
    streets: [],
    cities: [],
    customerNames: [],
    departments: [],
    modalities: [],
    contractAvailabilities: [],
    srsStatuses: []
  };

  selectedEquipmentKey: string;
  listType = 'equipment';
  showCmdbMaterialNumberInExport = false;
  showEndOfSupportFilter = false;
  toggleCustomerName = false;
  viewTicketRole = false;
  viewPlannedActivitiesRole = false;
  viewContractRole = false;
  showEquipmentOptionsTab = false;
  showEquipmentEnvironmentTab = false;
  showTeamplayTab = false;
  isCentriCareToggleEnabled = false;

  customerNameLength = undefined;
  private dontNavigateToFirst: boolean;

  private translate: { [key: string]: string } = {};
  private isAllEquipmentLoaded = false;

  constructor(
    private equipmentRestService: EquipmentRestService,
    router: Router,
    private equipmentConstantsService: EquipmentConstantsService,
    private updateEquipmentEventService: UpdateEquipmentEventService,
    private route: ActivatedRoute,
    equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService,
    userUtilService: UserUtilService,
    stateService: StateService,
    logService: LogService,
    private systemUpdatesService: SystemUpdatesService,
    private createTicketEventService: CreateTicketEventService,
    browserStateService: BrowserStateService,
    filterUtilService: FilterUtilService,
    private ticketsConstantsService: TicketsConstantsService,
    public lifeNetUtilService: LifeNetUtilService,
    private sourceRestService: DocumentSourceRestService,
    private translateService: TranslateService,
    private myFiltersService: MyFiltersAdapterService,
    private teamplayRestService: TeamplayRestService
  ) {
    // base class instantiation
    super(
      configService,
      equipmentUtilService,
      userUtilService,
      stateService,
      logService,
      router,
      browserStateService,
      filterUtilService
    );
  }

  @HostListener('window:resize', ['$event'])
  resizeWindow(event) {
    this.setNavigateToFirst();
    this.selectFirstEquipment();
  }

  ngOnInit() {
    if (this.route.firstChild) {
      this.route.firstChild.params.pipe(takeUntil(this.unsubscribe$)).subscribe(
        params => {
          if (this.rawList && this.rawList.length > 0) {
            this.selectedItem = find(
              this.rawList,
              equipment => equipment.key === params.id
            );
          }
          // saving this one for later, until the viewModelList is loaded
          this.selectedEquipmentKey = params.id;
        }
      );
    }
    this.setNavigateToFirst();

    this.translateService
      .get(Object.keys(translateKeys))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(translate => this.translate = translate);

    this.userUtilService
      .checkUserRoles(rolesToCheck)
      .subscribe(checkRolesResponse => {
        if (checkRolesResponse.checkITAdminRole) {
          this.showSystemUpdatesTab = true;
        }
        if (checkRolesResponse.checkCybersecurityDashboardRole) {
          this.showSecurityTabRole = true;
        }
        if (checkRolesResponse.checkViewTicketRole) {
          this.viewTicketRole = true;
        }
        if (checkRolesResponse.checkPlannedActivityRole) {
          this.viewPlannedActivitiesRole = true;
        }
        if (checkRolesResponse.checkViewContractRole) {
          this.viewContractRole = true;
        }
        super.init();
      });

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();

    this.equipmentUtilService.showEditModalEmitter.subscribe(emit => {
      this.editInfoModal.addressFields = emit.addressFields;
      this.editInfoModal.equipmentEditItem = emit.selectedEquipment;
      this.editInfoModal.show();
    });

    this.equipmentUtilService.showDeactivateModalEmitter.subscribe(emit => {
      this.deactivateModal.equipment = emit;
      this.deactivateModal.formData = new DeactivateFormModel(false, '', '');
      this.deactivateModal.show();
    });

    this.createTicketEventService.createTicketEmitter.subscribe(emit => {
        // refresh equipment status
        this.viewModelList.forEach((item: EquipmentListViewModel) => {
          if (item.key === emit.selectedEquipment.key) {
            switch (emit.severity) {
              case '1':
                item.status = '1';  // red
                break;
              case '2':
                if (item.status !== '1') {   // yellow
                  item.status = '2';
                }
                break;
              case '3':
                if (item.status === '') {   // green
                  item.status = '0';
                }
                break;
            }

            this.selectedEquipmentKey = item.key;
          }
        });
      }
    );

    this.checkEquipmentTab();
  }

  checkEquipmentTab() {
    const sensorDataExists$ = this.equipmentRestService.getEquipmentEnvironmentDataExists();
    const config$ = this.configService.getConfig();

    combineLatest([sensorDataExists$, config$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([sensorDataExists, config]) => {
        this.showEquipmentEnvironmentTab = sensorDataExists && isEqual(config.TOGGLE_ENVIRONMENTAL_CONDITIONS, 'true');
        this.checkTeamplayTab(config);
      });
  }

  ngOnDestroy() {
    super.destroy();
  }

  getEmptyListUrl(): string {
    return EQUIPMENT_URL;
  }

  /**
   * @description Additional properties to be initialized for derived class
   */
  afterInitProperties() {
    this.sortSkeleton = this.equipmentConstantsService.getSortSkeleton();
    this.operationalStatus = this.equipmentConstantsService.getOperationalStatesSkeleton();

    // status color mapping
    this.statusColorMap = {};
    this.operationalStatusConfig = {};

  }

  /**
   * @description Additional config properties to be initialized for derived class
   */
  afterConfigProperties(config) {
    const greenStatus = config.EQUIPMENT_STATUS_GREEN;
    const redStatus = config.EQUIPMENT_STATUS_RED;
    const yellowStatus = config.EQUIPMENT_STATUS_YELLOW;

    // compute the status color mapping from country config
    this.statusColorMap[greenStatus] = green;
    this.statusColorMap[redStatus] = red;
    this.statusColorMap[yellowStatus] = yellow;

    // operational states from country config
    this.operationalStatusConfig.green = greenStatus;
    this.operationalStatusConfig.red = redStatus;
    this.operationalStatusConfig.yellow = yellowStatus;

    if (isEqual(config.SHOW_SECURITY_INFO_TAB, 'true')) {
      this.showSecurityTabConfig = true;
    }

    if (isEqual(config.TOGGLE_EQUIPMENT_TRAINING_TAB, 'true')) {
      this.showTrainingsTab = true;
    }

    if (isEqual(config.MATERIAL_NO_RENDER, 'true')) {
      this.showCmdbMaterialNumberInExport = true;
    }

    if (isEqual(config.EQUIPMENT_END_OF_SUPPORT_SHOW, 'true')) {
      this.showEndOfSupportFilter = true;
    }

    if (isEqual(config.SHOW_EQUIPMENT_OPTIONS_TAB, 'true')) {
      this.showEquipmentOptionsTab = true;
    }

    // US LifeNet feature
    const showCustName = config.TOGGLE_CUSTOMER_NAME_EQUIPMENT_LIST;
    if (isEqual(showCustName, 'false')) {
      remove(this.sortSkeleton.items, {
        title: 'GENERIC_LABEL_CUSTOMER_NAME'
      });
    }
    this.toggleCustomerName = isEqual(config.TOGGLE_CUSTOMER_NAME_EQUIPMENT_LIST, 'true');
    this.customerNameLength = config.LENGTH_CUSTOMER_NAME_DISPLAY > 0 ? config.LENGTH_CUSTOMER_NAME_DISPLAY : 'undefined';

    this.isCentriCareToggleEnabled = isEqual(
      config.TOGGLE_CENTRICARE,
      'true'
    );

    if (this.isCentriCareToggleEnabled) {
      this.operationalStatus.push({
        title: 'EQUIPMENT_STATUS_NEEDS_ATTENTION_CRITICAL',
        value: 'redCritical',
        color: red
      });

      this.statusColorMap[CENTRICARE_EQUIPMENT_STATUS_CRITICAL] = red;
      this.operationalStatusConfig.redCritical = CENTRICARE_EQUIPMENT_STATUS_CRITICAL;
    }
  }

  /**
   * @description Get and initialize view model for equipment list by merging equipment and equipment status
   */
  loadViewModelList(): Observable<EquipmentListViewModel[]> {
    return this.equipmentUtilService.getEquipmentRest().pipe(
      enhance(this.equipmentUtilService.getNativeEquipmentStatus(), ([equipment, status]) => {
        const findObject = {
          findKey: 'equipmentKey',
          findValue: 'key',
          propertiesToMerge: ['status']
        };
        return this.lifeNetUtilService.mapToViewModel([status, equipment], findObject);
      }),
      map(resp => resp as EquipmentViewModel[]),
      map(equipment => equipment.map(e => ({
        ...e,
        customerName: (e.customerName || '').trim(),
        statusOrder: this.getStatusOrder(e.status),
        endOfSupport: e.cmdbEquipment && e.cmdbEquipment.eosDate ? e.cmdbEquipment.eosDate : undefined
      }))),
      tap(() => this.isLoaded = true)
    );
  }

  getStatusOrder(status: string) {
    return !status ? null
      : status === '2' ? 1
        : status === '1' ? 2
          : 0;
  }

  /**
   * @description Get and initialize my siemens profile equipments
   */
  afterInitViewModelList() {
    this.onAdvancedFilterChange();
    this.selectFirstEquipment();
  }

  /**
   * @description Initialize the advanced drop down list option
   */
  initAdvancedFilterDropDownList() {

    // properties to be filtered from dataset
    const dropDownListStructure = {
      products: 'productName',
      myEquipmentNames: 'myEquipmentName',
      streets: 'street',
      cities: 'city',
      customerNames: 'customerName',
      departments: 'department',
      contractAvailabilities: 'contractAvailability',
      srsStatuses: 'srsStatus',
      modalities: 'modalityTranslation'
    };

    this.rawList.forEach((rawItem: EquipmentInformation) => {
      rawItem.contractAvailability = this.translate[
        rawItem.contractType
          ? translateKeys.FILTER_CONTRACT_AVAILABILITY_YES
          : translateKeys.FILTER_CONTRACT_AVAILABILITY_NO
        ];
    });
    const dropDownOptions = this.filterUtilService.computeDropdownOptions(
      this.rawList,
      dropDownListStructure
    );
    this.optionsDropDownList.products = dropDownOptions.products;
    this.optionsDropDownList.myEquipmentNames = dropDownOptions.myEquipmentNames;
    this.optionsDropDownList.streets = dropDownOptions.streets;
    this.optionsDropDownList.cities = dropDownOptions.cities;
    this.optionsDropDownList.customerNames = dropDownOptions.customerNames;
    this.optionsDropDownList.departments = dropDownOptions.departments;
    this.optionsDropDownList.srsStatuses = dropDownOptions.srsStatuses;
    this.optionsDropDownList.modalities = dropDownOptions.modalities;
    this.optionsDropDownList.contractAvailabilities = dropDownOptions.contractAvailabilities;
  }

  getExportList = () => {
    const exportList = cloneDeep(this.listWithoutPagination);
    forEach(exportList, (item: EquipmentInformation) => {
      if (this.showCmdbMaterialNumberInExport && item.cmdbEquipment && item.cmdbEquipment.materialNumberConsolidated) {
        item['cmdbMaterialNumberConsolidated'] = item.cmdbEquipment.materialNumberConsolidated;
      }
      item.softwareVersion = EquipmentUtilService.getSoftwareVersion(item);
      item.modality = item.modalityTranslation;
      this.equipmentUtilService.getEquipmentStatus(item.key).subscribe(
        status => {
          item['status'] = status;
        }
      );
    });
    return exportList;
  }

  /**
   * @description
   * set filter for operational state and customer name when view is initialized
   * from dashboard by either clicking on the status widget, or on a infoWindow
   * link from the status google map.
   */
  setPropertiesFromQueryParams(config: any) {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      // get equipment identifier
      const equipmentIdentifier = params['equipmentIdentifier'];
      if (equipmentIdentifier || equipmentIdentifier === '') {
        this.searchInput = '"' + equipmentIdentifier + '"';
        this.initialListMode = false;
        this.onFilterChange();
        const search = params['search'];
        const tab = params['tab'];
        if (search) {
          this.deepLinkSearch = search;
        }
        if (tab) {
          this.deepLinkTab = tab;
        }
      }

      // get operational state
      const operationalState = params['operationalState'];
      if (operationalState && !isEqual(operationalState, 'All')) {
        this.checkAllStates = false;
        if (this.isCentriCareToggleEnabled) {
          this.operationalStatus.filter(status => status.value.startsWith(operationalState))
            .map(status => status.value)
            .forEach(value => this.selectedOperationalStatus.push(value));
        } else {
          this.selectedOperationalStatus.push(operationalState);
        }
      }

      const eosYear = params['eosyear'];
      if (eosYear && eosYear.length === 4) {
        this.eosDateRange.fromDate = new Date(eosYear, 0, 1);
        this.eosDateRange.toDate = new Date(eosYear, 11, 31);
      } else if (eosYear && eosYear.length === 10) {
        const yearSeparator = 'to';
        const years = eosYear.split(yearSeparator);
        this.eosDateRange.fromDate = new Date(years[0], 0, 1);
        this.eosDateRange.toDate = new Date(years[1], 11, 31);
      }

      // get customerName
      const customerName = params['customerIdentifier'];
      if (customerName) {
        this.initialListMode = false;
        this.selectedDropDownList.customerName.push(customerName);
      }
      // get showTicketsTab
      const showTicketsTab = params['showTicketsTab'];
      if (showTicketsTab) {
        this.redirectToTickets = true;
      }
      const equipmentSystemNames = params['equipmentSystemNames'];
      if (equipmentSystemNames) {
        this.searchInput = equipmentSystemNames;
        this.initialListMode = false;
        this.onFilterChange();
        this.deepLinkSearch = this.searchInput;
        this.deepLinkTab = 'teamplay';
      }
    });
  }

  /**
   * @description Filter object with different filters
   */
  getFilterObject(): any {
    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: [
          'key',
          'customerNumber',
          'productName',
          'myEquipmentName',
          'siemensId',
          'department',
          'street',
          'city',
          'serialNumber',
          'customerName',
          'customerDescription'
        ]
      },
      myEquipment: {
        isMyEquipmentChecked: this.myEquipmentChecked,
        keyName: 'key',
        myEquipmentList: this.myEquipmentProfileList
      },
      operationalStatus: {
        status: this.selectedOperationalStatus,
        config: this.operationalStatusConfig
      },
      advancedMultiSelect: this.selectedDropDownList,
      orderBy: this.sortSkeleton.sortObject,
      dateRange: {
        rangeOfDate: this.dateRange,
        propertyKey: 'installedOn'
      },
      endOfSupport: {
        rangeOfDate: this.eosDateRange,
        propertyKey: 'endOfSupport'
      }
    };
  }

  navigate() {
    // add to last viewed equipment
    this.equipmentRestService.postLastViewedEquipment(this.selectedItem.key);

    if (this.redirectToTickets) {
      this.ticketsConstantsService.getStatusSkeleton().pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
        const ticketOpenStatusValue = response[0].value;
        const queryParams = {
          ticketStatus: ticketOpenStatusValue,
          sortBy: 'problemSeverity',
          sortDir: 'asc'
        };

        this.router
          .navigate([EQUIPMENT_URL, this.selectedItem.key, 'ticketHistory'],
            {queryParams, skipLocationChange: true})
          .then(() => this.checkDocumentsTabForEquipment(this.selectedItem, 'ticketHistory'));
        this.redirectToTickets = false;
      });
    }
    if (this.deepLinkTab) {
      let queryParams = {};
      if (this.deepLinkSearch) {
        queryParams = {
          search: this.deepLinkSearch,
          tab: this.deepLinkTab
        };
      }
      combineLatest([this.sourceRestService.getDocumentsSourceOptions(this.selectedItem.key),
        this.teamplayRestService.isTeamplayAvailable()
      ]).pipe(takeUntil(this.unsubscribe$)).subscribe(([options, isAvailable]) => {
        this.showDocumentsTab = !!(options && options.length > 0);
        this.showTeamplayTab = isAvailable;
        this.equipmentTabs && this.equipmentTabs.initActiveItemTimeout();
        this.tryNavigateToTab(queryParams);
        this.deepLinkTab = null;
      });
      return;
    }

    // check if not default value and has a specific state name
    if (this.currentStateName.split('-').length > 1) {
      this.router.navigate([
        EQUIPMENT_URL,
        this.selectedItem.key,
        this.currentStateName.split('-')[1]
      ]).then(() => this.checkDocumentsTabForEquipment(this.selectedItem, this.currentStateName.split('-')[1]));
    } else {
      this.router.navigate([EQUIPMENT_URL, this.selectedItem.key, 'info'])
        .then(() => this.checkDocumentsTabForEquipment(this.selectedItem, 'info'));
    }
  }

  tryNavigateToTab(queryParams: any) {
    let invalidTab = true;
    switch (this.deepLinkTab) {
      case 'ticketHistory': {
        if (this.viewTicketRole) {
          invalidTab = false;
        }
        break;
      }
      case 'activity': {
        if (this.viewPlannedActivitiesRole) {
          invalidTab = false;
        }
        break;
      }
      case 'contractModel': {
        if (this.viewContractRole) {
          invalidTab = false;
        }
        break;
      }
      case 'documents': {
        if (this.showDocumentsTab) {
          invalidTab = false;
        }
        break;
      }
      case 'update': {
        if (this.showSystemUpdatesTab) {
          invalidTab = false;
        }
        break;
      }
      case 'security': {
        if (this.showSecurityTab()) {
          invalidTab = false;
        }
        break;
      }
      case 'optionsUpgrades': {
        if (this.showEquipmentOptionsTab) {
          invalidTab = false;
        }
        break;
      }
      case 'trainings': {
        if (this.showTrainingsTab) {
          invalidTab = false;
        }
        break;
      }
      case 'teamplay': {
        if (this.showTeamplayTab) {
          invalidTab = false;
        }
        break;
      }
      case 'info': {
        invalidTab = false;
        break;
      }
    }
    if (invalidTab) {
      this.router.navigate([EQUIPMENT_URL, this.selectedItem.key, 'info']);
    } else {
      this.router
        .navigate([EQUIPMENT_URL, this.selectedItem.key, this.deepLinkTab],
          {queryParams, skipLocationChange: true});
    }
  }

  checkDocumentsTabForEquipment(equipment, tabWasNavigatedTo: string) {
    this.sourceRestService.getDocumentsSourceOptions(equipment.key).subscribe(options => {
      this.showDocumentsTab = !!(options && options.length > 0);
      this.equipmentTabs && this.equipmentTabs.initActiveItemTimeout();
      if (tabWasNavigatedTo === 'documents' && !this.showDocumentsTab) {
        this.router.navigate([EQUIPMENT_URL, this.selectedItem.key, 'info']);
      }
    });
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    this.updateEquipmentEventService.updateEquipmentSourceSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.isItemClickable = false;
        this.initLoadViewModelList();
      }
    );

    this.myFiltersService.status$.pipe(takeUntil(this.unsubscribe$)).subscribe(({overallSwitch, equipment}) => {
      const oldEquipmentKeys = this.myEquipmentProfileList as string[];
      const newEquipmentKeys = equipment.map(e => e.key);
      const needsReload = !this.isAllEquipmentLoaded && overallSwitch
        && difference(newEquipmentKeys, oldEquipmentKeys).length !== 0;
      const needsLoadAll = !overallSwitch && !this.isAllEquipmentLoaded;

      this.myEquipmentProfileList = newEquipmentKeys;
      this.myEquipmentChecked = overallSwitch;

      if (needsReload || needsLoadAll) {
        this.initLoadViewModelList();
        if (needsLoadAll) {
          this.isAllEquipmentLoaded = true;
        }
      } else {
        this.onAdvancedFilterChange();
      }
      this.selectFirstEquipment();
    });

    this.systemUpdatesService.onEquipmentLinkClick.pipe(
      switchMap(() => this.configService.getConfig()),
      takeUntil(this.unsubscribe$)
    ).subscribe(configResponse => {
      this.setConfigProperties(configResponse);
      this.initLoadViewModelList();
      this.isConfigLoaded = true;
    });
  }

  onFilterChange() {
    this.onAdvancedFilterChange();
    this.selectFirstEquipment();
  }

  setNavigateToFirst() {
    this.viewport = window
      .getComputedStyle(document.body, ':after')
      .getPropertyValue('content')
      .replace(/['",]/g, '');
    this.viewport !== 'xl' ? this.dontNavigateToFirst = true : this.dontNavigateToFirst = false;
  }

  selectFirstEquipment() {
    if (!this.isLoaded || this.dontNavigateToFirst) {
      return;
    }

    const viewport: string = window.getComputedStyle(document.body, ':after').getPropertyValue('content').replace(/['",]/g, '');

    if (
      this.listWithoutPagination.length > 0 &&
      !!this.selectedItem &&
      this.listWithoutPagination.findIndex(r => r.key === this.selectedItem.key) !== -1
    ) {
      return;
    }

    if (this.viewModelList.length > 0 && (viewport === 'lg' || viewport === 'xl')) {
      this.selectedEquipmentKey = this.viewModelList[0].key;
      this.onClickUpdateSelectedItem(this.viewModelList[0]);
    } else {
      this.backToEquipmentList();
    }
  }

  /**
   * @description additional properties to set from selected favorite
   * @param favoriteObj
   */
  setDerivedBoundPropertiesFromFavorite(favoriteObj: any) {
    if (favoriteObj) {
      if (favoriteObj['operationalStatus']) {
        this.selectedOperationalStatus = favoriteObj.operationalStatus.status;
      }

      if (favoriteObj['advancedMultiSelect']) {
        this.selectedDropDownList = favoriteObj['advancedMultiSelect'];
      }
    }
  }

  /**
   *
   * Note:- Cannot be moved to Base List
   * https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
   * @param index
   * @param {EquipmentViewModel} item
   * @returns {any}
   */
  trackByFn(index, item: EquipmentViewModel) {
    return item['key'];
  }

  showSecurityTab() {
    return this.showSecurityTabConfig && this.showSecurityTabRole;
  }

  backToEquipmentList() {
    this.selectedItem = null;
    this.selectedEquipmentKey = null;
    this.router.navigate([EQUIPMENT_URL]);
  }

  applyFavorite(favorite: Favorite) {
    const filterToBeApplied = JSON.parse(favorite.filter);
    if (filterToBeApplied.endOfSupport && filterToBeApplied.endOfSupport.rangeOfDate) {
      this.eosDateRange = filterToBeApplied.endOfSupport.rangeOfDate;
      if (filterToBeApplied.endOfSupport.rangeOfDate.fromDate) {
        this.eosDateRange.fromDate = new Date(
          filterToBeApplied.endOfSupport.rangeOfDate.fromDate
        );
      }
      if (filterToBeApplied.endOfSupport.rangeOfDate.toDate) {
        this.eosDateRange.toDate = new Date(
          filterToBeApplied.endOfSupport.rangeOfDate.toDate
        );
      }
    }
    super.applyFavorite(favorite);
    this.selectFirstEquipment();
  }

  onClickUpdateSelectedItem(selectedItem: EquipmentListViewModel | EquipmentInformation) {
    this.selectedEquipmentKey = selectedItem.key;
    super.onClickUpdateSelectedItem(selectedItem);
  }

  isSelected(item: EquipmentViewModel): boolean {
    return this.selectedEquipmentKey && this.selectedEquipmentKey === item.key;
  }

  checkTeamplayTab(config: any) {
    this.showTeamplayTab = false;
    if (!isEqual(config.FEATURE_TOGGLE_DISPLAY_DASHBOARD_WIDGET_TP, 'true')) {
      return;
    }
    this.teamplayRestService.isTeamplayAvailable().pipe(takeUntil(this.unsubscribe$))
      .subscribe((isAvailable) => {
        this.showTeamplayTab = isAvailable;
      });
  }
}
