import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CustomerViewModel } from '../../view-models/customer-view-model';
import { CustomerGroupViewModel } from '../../view-models/customer-group-view-model';
import { ClearCacheUtilService } from '../../utils/clear-cache-util.service';

@Injectable()
export class ImpersonationCommunicationService {

  // add a customer to be impersonated
  private onCustomerAddedSource: Subject<CustomerViewModel> = new Subject();
  // Observable customer to be impersonated
  onCustomerAdded$ = this.onCustomerAddedSource.asObservable();

  // add a customer-group to be impersonated
  private onGroupAddedSource: Subject<CustomerGroupViewModel> = new Subject();
  // Observable customer-group to be impersonated
  onGroupAdded$ = this.onGroupAddedSource.asObservable();

  // notify impersonation
  private onImpersonationChangeSource: Subject<any> = new Subject();
  // Observable impersonate change
  onImpersonationChange$ = this.onImpersonationChangeSource.asObservable();

  // notify on country/language change
  private onCountryLanguageChangeSource: Subject<void> = new Subject<void>();
  // Observable country language change
  onCountryLanguageChange$ = this.onCountryLanguageChangeSource.asObservable();

  // remove a customer from list of customers to be impersonated
  private onCustomerRemovedSource: Subject<CustomerViewModel> = new Subject();
  // Observable customer removal change
  onCustomerRemovedChange$ = this.onCustomerRemovedSource.asObservable();

  // remove a customer from list of customers to be impersonated
  private onCustomerGroupRemovedSource: Subject<CustomerGroupViewModel> = new Subject();
  // Observable customer removal change
  onCustomerGroupRemovedChange$ = this.onCustomerGroupRemovedSource.asObservable();

  constructor(private clearCacheUtilService: ClearCacheUtilService) {
  }

  emitAddCustomerToBeImpersonated(customer: CustomerViewModel) {
    this.onCustomerAddedSource.next(customer);
  }

  emitAddGroupToBeImpersonated(customerGroup: CustomerGroupViewModel) {
    this.onGroupAddedSource.next(customerGroup);
  }

  emitImpersonationChange() {
    this.clearCacheUtilService.clear();
    this.onImpersonationChangeSource.next();
  }

  emitCountryLanguageChange() {
    this.onCountryLanguageChangeSource.next();
  }

  emitCustomerHasBeenRemoved(customer: CustomerViewModel) {
    this.onCustomerRemovedSource.next(customer);
  }

  emitCustomerGroupHasBeenRemoved(group: CustomerGroupViewModel) {
    this.onCustomerGroupRemovedSource.next(group);
  }
}
