import { finalize, takeUntil } from 'rxjs/operators';
import { ImpersonationCommunicationService } from '../../core/component-communication-services/impersonation/impersonation-communication.service';
import { CountryConfigRestService } from '../../core/rest-services/country-config-rest.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NewsViewModel } from '../../core/view-models/news-view-model';
import { NewsService } from '../../core/services/news.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'hl-news-widget',
  templateUrl: './news-widget.component.html'
})
export class NewsWidgetComponent implements OnInit, OnDestroy {

  newsDatePattern: string;
  newsExternalLink: string;
  newsCount: number;
  isLoaded = false;
  newsList: NewsViewModel[];
  newsDataRestUrl: string;

  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(private configService: CountryConfigRestService,
    private newsService: NewsService,
    private impersonationCommunicationService: ImpersonationCommunicationService) {
  }

  ngOnInit() {
    this.init();

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {

    this.newsList = [];
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.newsDatePattern = configResponse.NEWS_DATE_PATTERN;
      this.newsExternalLink = configResponse.NEWS_EXTERNAL_PAGE;
      this.newsCount = configResponse.DASHBOARD_NEWS_COUNT;
      this.newsDataRestUrl = configResponse.URL_NEWS_DATA;
      // load news
      this.loadNews();
    });
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    this.impersonationCommunicationService.onCountryLanguageChange$
      .pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.init();
    });
  }

  /**
   * Load the news list by making a BE request
   */
  loadNews() {
    this.newsService.getNews(this.newsDataRestUrl).pipe(
      finalize(() => {

        // irrespective of success or error
        this.isLoaded = true;
      }))
      .subscribe((newsResponse) => {
        this.newsList = newsResponse.slice(0, this.newsCount);
      });
  }

}
