import { BrowserModule, EventManager } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { EquipmentModule } from './equipment/equipment.module';
import { TicketsModule } from './tickets/tickets.module';
import { WelcomeModule } from './welcome/welcome.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header.component';
import { ToolBarComponent } from './tool-bar.component';
import { MainNavComponent } from './main-nav.component';
import { ErrorInterceptor } from './core/interceptors/error-interceptor';
import { CacheRequestInterceptor } from './core/interceptors/cache-request-interceptor';
import { MessagingCenterComponent } from './messaging-center/messaging-center.component';
import { TimepickerModule } from 'ngx-bootstrap';
import { SystemUpdatesModule } from './system-updates/system-updates.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EquipmentDetailsModule } from './equipment-details/equipment-details.module';
import { HttpIgnoredErrors } from './core/interceptors/http-ignored-errors';
import { UserSettingsModule } from './user-settings/user-settings.module';
import { ConfigLoaderService } from './config-loader.service';
import { CacheableHttpClient } from './core/services/cache/cacheable-http-client';
import { HttpCacheNewService } from './core/services/cache/http-cache-new.service';
import { FrontPagesModule } from './front-pages/front-pages.module';
import { CancelRequestInterceptor } from './core/interceptors/cancel-request-interceptor';
import { HttpCancelUtilService } from './core/utils/http-cancel-util.service';
import { AuthGuardService } from './core/services/user/auth-guard.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CheckPermissionOrRoleService } from './core/auth-guards/check-permission-or-role.service';
import { CustomEventManager } from './core/event-manager/custom-event-manager';
import { MockHttpBackendProvider } from '../cdmockdata/mock-http-backend';

export function createTranslateLoader() {
  return AppModule.translateHttpLoader;
}

export function initialConfigLoader(http: HttpClient, config: ConfigLoaderService) {
  return () =>
    config.load().then(response => {
      if (response) {
        AppModule.translateHttpLoader = new TranslateHttpLoader(http, response.translation,
          response.suffix);
      }
    })
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ToolBarComponent,
    MainNavComponent,
    MessagingCenterComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    SharedModule,
    DashboardModule,
    WelcomeModule,
    FrontPagesModule,
    EquipmentModule,
    TicketsModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader
      }
    }),
    TimepickerModule.forRoot(),
    AppRoutingModule,
    SystemUpdatesModule,
    BrowserAnimationsModule,
    EquipmentDetailsModule,
    UserSettingsModule,
    DragDropModule
  ],
  providers: [
    MockHttpBackendProvider,
    ConfigLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialConfigLoader,
      deps: [HttpClient, ConfigLoaderService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    HttpIgnoredErrors,
    CacheableHttpClient,
    HttpCacheNewService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CancelRequestInterceptor,
      multi: true
    },
    HttpCancelUtilService,
    CheckPermissionOrRoleService,
    AuthGuardService,
    {
      provide: EventManager,
      useClass: CustomEventManager
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  static translateHttpLoader: TranslateHttpLoader;
}
