import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class AlignHeightEventService {

  // Observable align height sources
  private alignHeightSource = new ReplaySubject(1);

  // Observable align height streams
  alignHeightSource$ = this.alignHeightSource.asObservable();

  constructor() {
  }

  /**
   * Emits height to all listeners.
   * @param {number} height
   */
  emitHeight(height: number) {
    this.alignHeightSource.next(height);
  }

}
