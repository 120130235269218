import { NavigationExtras, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { DataDiagram, GeneratedDataDiagram, NOT_AVAILABLE } from './diagram';

@Component({
  selector: 'hl-diagram-doughnut',
  templateUrl: './diagram-doughnut.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class DiagramDoughnutComponent implements OnChanges, AfterViewInit {

  @Input() useMonochrome = false;
  @Input() valueBeforeLabel = true;
  @Input() dataInput: DataDiagram[] = [];
  @Input() results: object[] = [];
  @Input() labels = true;
  @Input() legend = false;
  @Input() view: [number, number];
  @Input() doughnut = true;
  @Input() arcWidth = 0.3;
  @Input() totalLabel?: string;
  @Input() total?: any;
  @Input() totalUnit?: string;
  @Input() totalRouterLink: string;
  @Input() queryParams: NavigationExtras;
  @Input() backgroundColor = 'white';
  @Input() hideLegends = false;
  @Input() aspectRatio = 'aspect-ratio-4x3 aspect-ratio-md-1x1 aspect-ratio-lg-4x3';
  @Output() onNavigated: EventEmitter<any> = new EventEmitter();
  @Input() fullScale = false;
  @Input() disableItemsClick = false;

  data: GeneratedDataDiagram[];
  customColors: object[] = [];
  maxLabelLength = 50;

  static getItemColor(color) {
    switch (color) {
      case 'green':
        return '#2ecc71';
      case 'red':
        return '#e7001d';
      case 'yellow':
        return '#ffd200';
      default:
        return color;
    }
  }

  constructor(private router: Router) {
  }

  /**
   * Init of DiagramComponent
   *
   * @memberof DiagramComponent
   */
  ngOnChanges(): void {
    this.generateResults();
  }

  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
  }

  /**
   * Generate the items
   *
   * @memberof DiagramComponent
   */
  generateResults(): void {
    this.results = [];
    this.dataInput.forEach(item => {
      const itemToPush = {name: item.name, value: item.value};
      const foundDuplicateNames = this.results.filter(element => {
        const o = element as { name: string, value: number };
        return o.name.trim() === item.name;
      });
      // Chart adds colours to values according to name attribute. However, if there
      // are 2 values with same name, chart is not able to draw them with different colours.
      if (foundDuplicateNames.length > 0) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < foundDuplicateNames.length; i++) {
          itemToPush.name += ' ';
        }
      }

      this.results.push(itemToPush);

      if (item.color !== '' && !this.useMonochrome) {
        this.customColors.push({
          name: itemToPush.name,
          value: DiagramDoughnutComponent.getItemColor(item.color)
        });
      }
      if (item.colorMonochrome !== '' && this.useMonochrome) {
        this.customColors.push({
          name: itemToPush.name,
          value: item.colorMonochrome
        });
      }
    });
  }

  /**
   * Generate a new label, the data array came on the runtime and a placeholder is declared in the head
   *
   * @param {string} input
   * @returns {string}
   * @memberof LclDiagramDoughnutComponent
   */
  labelFormatting(input: string): string {
    const i: GeneratedDataDiagram = this.data.find(
      item => item.data.name === input
    );
    return i.data.value + ' ' + i.data.name;
  }

  onSelectItem(event: any) {
    if (this.disableItemsClick) { return; }
    const item = this.dataInput.find(
      (i: DataDiagram) => i.name === event.name.trim()
    );
    if (item) {
      this.navigate(item.routerLink, item.queryParams);
    }
  }

  getDiagramClass(): string {
    return `${this.aspectRatio} display-flex bg-color-${this.backgroundColor}`;
  }

  navigate(link: string, queryParams?: NavigationExtras) {
    if (queryParams) {
      this.router.navigate([link], {
        queryParams
      });
    } else {
      this.router.navigate([link]);
    }
    this.onNavigated.emit({link, queryParams});
  }

  hasValue() {
    return this.total !== NOT_AVAILABLE;
  }

  getMargins(): number[] {
    return this.fullScale ? [0, 0, 0, 0] : [];
  }

  getDiagramAdditionalClass() {
    const hideLegendsClass = this.hideLegends ? 'diagram--no-legend' : '';
    return hideLegendsClass + ' ' + (this.disableItemsClick ? 'diagram--default-cursor' : '');
  }
}
