import { CustomerUtilService } from './customer-util.service';
import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomerAdministrationActivate implements CanActivate {

  constructor(private customerUtilService: CustomerUtilService) {
  }

  canActivate() {

    return this.customerUtilService.checkCustomerAdministrationRoles().pipe(map(checkRoleRespone => {
      return _.some(checkRoleRespone, checkedRole => _.isEqual(checkedRole, true));
    }));

  }
}
