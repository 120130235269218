import { OrderByPipe } from '../../shared/pipes/order-by/order-by.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ActivitiesViewModel } from '../view-models/activities-view-model';
import { CalendarOptions } from '../models/activities/calendar-options';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { SortDirection } from '../../shared/sorting/sort-object';

@Injectable()
export class CalendarUtilService {

  constructor(private translateService: TranslateService,
    private orderByPipe: OrderByPipe) { }

  getCalendarOptions(dataSet: ActivitiesViewModel[]): CalendarOptions {
    return this.calculateCalendarDateRange(dataSet);
  }

  /**
   *
   * @description
   * Set the min date and max date for calendar range
   * Min Date and Max Date are defined based on 3 scenarios from Bastian.
   * 1)MIN=MAX activity month in past -> MIN month=activity month/MAX month=current month.
   * 2)MIN=MAX activity month in future -> MIN month=current/MAX month=activity month
   * 3)MIN=MAX activity month is current month -> MIN month=current/MAX month=current
   * Scenario 4 and 5 are default.
   */
  private calculateCalendarDateRange(data: ActivitiesViewModel[]): CalendarOptions {

    // tslint:disable-next-line:ban
    const daysTrans = this.translateService.instant('DAYS');
    // tslint:disable-next-line:ban
    const monthsTrans = this.translateService.instant('MONTHS');

    // init the calendar options
    const calendarOptions: { [k: string]: any } = {};

    calendarOptions.weekStart = 1; // optional, start of week, 0: Sunday, 1: Monday, default: 0)
    calendarOptions.defaultDate = new Date(); // usually today
    calendarOptions.weekdays = daysTrans.split(',');
    calendarOptions.months = monthsTrans.split(',');

    if (data && data.length > 0) {

      // set the min date and max date for calendar view.
      const sortedData = this.orderByPipe.transform(data, {
        sortBy: 'plannedStartDate',
        sortDir: SortDirection.ASC
      });

      const currentDate = new Date();

      // Note: Assign here type to any so that date manipulation can be done
      let minDate: any;
      let maxDate: any;

      // start of date range to display
      minDate = new Date(sortedData[0].plannedStartDate);

      // end of date range to display
      maxDate = new Date(sortedData[sortedData.length - 1].plannedStartDate);

      /**
       * Scenario: 3
       * First of all we need to check if min date and max date are current month.
       * Only then check other condition.
       */
      if (this.checkDateRangeCurrentMonth(minDate, maxDate, currentDate)) {
        minDate = new Date(minDate).setMonth(minDate.getMonth() - 1);
        maxDate = new Date(maxDate).setMonth(maxDate.getMonth() + 1);
      } else if (this.checkDateRangeIsInPast(minDate.getTime(), maxDate.getTime(), // Scenario: 1
        currentDate.getTime())) {
        maxDate = currentDate;
        minDate = new Date(minDate).setDate(minDate.getDate() - 1);
      } else if (this.checkDateRangeIsInFuture(minDate.getTime(), maxDate.getTime(), // Scenario:2
        currentDate.getTime())) {
        minDate = currentDate;
        maxDate = new Date(maxDate).setDate(maxDate.getDate() + 1);
      } else { // Scenario: 4
        minDate = new Date(minDate).setDate(minDate.getDate() - 1);
        maxDate = new Date(maxDate).setDate(maxDate.getDate() + 1);
      }

      calendarOptions.minDate = minDate;
      calendarOptions.maxDate = maxDate;

    } else {
      calendarOptions.minDate = new Date();  // start of date range to display
      calendarOptions.maxDate = new Date(); // end of date range to display
    }
    return calendarOptions as CalendarOptions;
  }

  /**
   * @ngdoc method
   * @name checkDateRangeCurrentMonth
   *
   * @description
   * Check if the min date and max date range of list data is current month, year.
   */
  private checkDateRangeCurrentMonth(minDate, maxDate, currentDate) {

    let dateRangeInCurrentMonth = false;
    if (((_.isEqual(minDate.getMonth(), currentDate.getMonth())) &&
      (_.isEqual(minDate.getFullYear(), currentDate.getFullYear())) &&
      (_.isEqual(maxDate.getMonth(), currentDate.getMonth())) &&
      (_.isEqual(maxDate.getFullYear(), currentDate.getFullYear())))) {
      dateRangeInCurrentMonth = true;
    }
    return dateRangeInCurrentMonth;
  }

  /**
   *
   * @param minDateTimeStamp
   * @param maxDateTimeStamp
   * @param currentDateTimeStamp
   * @returns {boolean}
   *
   * @description
   * Check if the min date and max date range is in past of current month.
   */
  private checkDateRangeIsInPast(minDateTimeStamp, maxDateTimeStamp, currentDateTimeStamp) {
    let dateRangeInPast = false;
    if (_.lt(minDateTimeStamp, currentDateTimeStamp) &&
      _.lt(maxDateTimeStamp, currentDateTimeStamp)) {
      dateRangeInPast = true;
    }
    return dateRangeInPast;
  }

  /**
   *
   * @param minDateTimeStamp
   * @param maxDateTimeStamp
   * @param currentDateTimeStamp
   * @returns {boolean}
   *
   * @description
   * Check if the min date and max date range is in future of future month.
   */
  private checkDateRangeIsInFuture(minDateTimeStamp, maxDateTimeStamp, currentDateTimeStamp) {
    let dateRangeInFuture = false;
    if (_.gt(minDateTimeStamp, currentDateTimeStamp) &&
      _.gt(maxDateTimeStamp, currentDateTimeStamp)) {
      dateRangeInFuture = true;
    }
    return dateRangeInFuture;
  }

}
