export const humi = [
  27.5,
  27.5,
  25.5,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.5,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  23.5,
  23.0,
  23.5,
  24.0,
  23.5,
  24.5,
  24.5,
  25.0,
  25.5,
  25.0,
  26.5,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.5,
  28.0,
  27.5,
  28.0,
  28.0,
  28.0,
  28.0,
  27.5,
  27.0,
  27.0,
  26.5,
  24.0,
  25.5,
  25.0,
  25.0,
  25.0,
  24.5,
  24.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.0,
  23.0,
  24.0,
  24.0,
  24.0,
  26.0,
  26.0,
  26.0,
  26.5,
  26.5,
  26.5,
  26.0,
  26.0,
  25.5,
  25.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  27.5,
  27.5,
  27.5,
  27.5,
  28.0,
  27.5,
  28.0,
  28.0,
  27.5,
  28.0,
  28.0,
  28.5,
  28.5,
  29.0,
  29.0,
  29.0,
  29.0,
  30.0,
  30.5,
  30.5,
  31.0,
  31.5,
  28.5,
  31.5,
  31.5,
  32.0,
  32.0,
  32.5,
  32.0,
  31.5,
  32.0,
  31.5,
  31.5,
  30.5,
  30.5,
  30.5,
  30.0,
  29.5,
  28.0,
  27.0,
  26.0,
  26.0,
  26.0,
  26.5,
  26.0,
  26.0,
  26.0,
  26.0,
  26.0,
  26.0,
  26.0,
  26.5,
  25.5,
  26.0,
  26.0,
  26.0,
  26.0,
  26.0,
  26.0,
  26.0,
  26.0,
  26.0,
  26.0,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  26.5,
  27.0,
  26.5,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.0,
  27.5,
  27.5,
  28.0,
  27.5,
  27.5,
  27.5,
  27.5,
  27.5,
  27.5,
  28.0,
  27.5,
  28.0,
  28.0,
  27.5,
  26.5,
  28.0,
  28.5,
  29.0,
  29.0,
  29.0,
  29.5,
  27.0,
  29.5,
  27.0,
  27.0,
  27.5,
  27.5,
  27.5,
  27.5,
  27.5,
  28.0,
  28.5,
  29.0,
  28.5,
  27.5,
  27.5,
  27.5,
  27.0,
  26.5,
  26.0,
  26.0,
  26.0,
  26.5,
  26.5,
  26.5,
  26.5,
  26.0,
  25.0,
  24.5,
  24.5,
  25.0,
  25.5,
  26.0,
  26.0,
  26.0,
  26.0,
  26.0,
  26.5,
  27.5,
  28.0,
  28.5,
  28.5,
  28.5,
  28.0,
  28.0,
  27.5,
  27.5,
  27.5,
  27.0,
  25.5,
  25.5,
  25.5,
  25.0,
  24.5,
  24.5,
  24.5,
  24.0,
  24.0,
  24.0,
  23.0,
  22.5,
  23.0,
  23.5,
  23.5,
  24.0,
  24.0,
  23.5,
  23.5,
  23.5,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  23.5,
  24.0,
  24.0,
  24.0,
  24.5,
  24.5,
  24.5,
  24.5,
  23.5,
  23.5,
  23.5,
  23.0,
  23.0,
  23.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.0,
  25.5,
  25.5,
  25.5,
  25.0,
  25.0,
  25.5,
  26.0,
  25.5,
  25.0,
  26.5,
  27.0,
  27.0,
  28.5,
  28.0,
  28.0,
  26.5,
  26.0,
  23.0,
  23.0,
  23.0,
  23.0,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  21.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.5,
  22.0,
  22.5,
  22.5,
  23.0,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.0,
  23.5,
  23.5,
  23.5,
  22.5,
  21.5,
  22.0,
  22.0,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  24.0,
  24.0,
  24.0,
  24.0,
  24.0,
  24.5,
  23.5,
  23.0,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.5,
  24.0,
  24.0,
  23.5,
  23.0,
  22.5,
  22.5,
  22.5,
  23.5,
  23.5,
  23.5,
  23.5,
  23.0,
  23.0,
  23.0,
  23.0,
  23.0,
  23.0,
  23.0,
  23.0,
  23.0,
  22.0,
  22.0,
  22.5,
  23.5,
  23.5,
  23.5,
  24.0,
  24.5,
  24.5,
  26.0,
  28.0,
  27.5,
  28.0,
  28.0,
  28.0,
  28.0,
  28.0,
  28.0,
  28.0,
  28.0,
  28.0,
  28.0,
  27.5,
  27.5,
  27.5,
  28.0,
  26.5,
  29.0,
  31.0,
  30.0,
  30.0,
  30.5,
  31.0,
  31.0,
  31.0,
  31.0,
  31.0,
  32.0,
  32.5,
  32.5,
  32.5,
  32.5,
  32.5,
  28.5,
  32.5,
  32.5,
  32.5,
  33.0,
  33.5,
  33.5,
  33.0,
  33.5,
  33.5,
  34.5,
  34.5,
  35.5,
  35.5,
  36.0,
  36.0,
  36.5,
  36.5,
  36.5,
  36.0,
  36.5,
  35.0,
  34.5,
  33.5,
  33.5,
  33.0,
  33.0,
  33.0,
  33.5,
  33.5,
  33.5,
  33.0,
  33.5,
  33.5,
  33.5,
  33.5,
  33.5,
  33.5,
  33.5,
  33.5,
  34.0,
  33.5,
  33.5,
  33.5,
  33.5,
  33.0,
  33.5,
  33.5,
  33.5,
  33.5,
  33.5,
  33.5,
  33.5,
  33.5,
  33.5,
  33.5,
  34.0,
  34.0,
  34.0,
  33.5,
  34.0,
  34.0,
  34.0,
  34.0,
  34.0,
  34.0,
  33.5,
  34.0,
  34.0,
  34.0,
  34.0,
  34.5,
  34.5,
  34.0,
  34.0,
  34.0,
  34.0,
  34.0,
  34.0,
  34.0,
  34.5,
  34.5,
  34.5,
  34.5,
  34.5,
  35.5,
  34.5,
  35.0,
  35.0,
  35.0,
  35.0,
  35.0,
  35.5,
  36.0,
  36.0,
  36.0,
  36.0,
  36.0,
  35.5,
  35.5,
  35.0,
  35.0,
  35.5,
  35.5,
  34.5,
  34.5,
  34.5,
  35.0,
  34.0,
  34.5,
  34.0,
  33.0,
  30.5,
  29.5,
  29.5,
  29.5,
  29.5,
  29.5,
  29.5,
  29.5,
  29.5,
  29.5,
  30.0,
  30.5,
  30.0,
  30.5,
  29.5,
  29.5,
  29.5,
  29.0,
  29.0,
  29.0,
  29.5,
  29.0,
  29.0,
  29.0,
  28.5,
  27.5,
  28.0,
  28.0,
  28.0,
  28.5,
  28.5,
  28.5,
  28.5,
  28.5,
  28.5,
  29.0,
  29.0,
  29.0,
  29.0,
  29.5,
  28.0,
  28.0,
  27.5,
  27.5,
  28.0,
  28.0,
  28.0,
  28.0,
  28.0,
  28.0,
  28.5,
  28.5,
  28.5,
  26.5,
  29.5,
  29.5,
  29.5,
  29.5,
  29.5,
  30.0,
  29.5,
  30.0,
  30.0,
  30.0,
  30.5,
  30.0,
  30.0,
  29.0,
  29.5,
  30.5,
  31.0,
  31.5,
  32.0,
  32.5,
  33.0,
  33.0,
  32.0,
  32.0,
  31.0,
  31.0,
  30.5,
  31.0,
  31.0,
  31.0,
  31.5,
  27.0,
  31.0,
  31.0,
  31.0,
  31.5,
  31.5,
  31.5,
  31.5,
  31.5,
  32.0,
  32.0,
  32.5,
  31.5,
  32.0,
  31.5,
  31.5,
  29.5,
  29.0,
  27.5,
  27.5,
  27.5,
  27.0,
  26.5,
  26.5,
  26.0,
  26.0,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  26.0,
  26.0,
  25.5,
  25.5,
  25.5,
  26.0,
  26.0,
  26.0,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.0,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5,
  25.5
];
