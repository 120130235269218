import { Injectable } from '@angular/core';
import { EquipmentRestService } from '../../core/rest-services/equipment-rest.service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { ticketsAllRestName } from '../../core/rest-services/ticket-rest-constants.service';
import { equipmentRestName, equipmentStatusRestName } from '../../core/rest-services/equipment-rest-constants.service';
import { HttpCancelUtilService } from '../../core/utils/http-cancel-util.service';
import { EquipmentUtilService } from 'app/core/services/equipment/equipment-util.service';

@Injectable()
export class MyEquipmentSwitchService {

  private showOnlyMineSubject = new ReplaySubject<boolean>(1);
  private loadAllDataSubject = new Subject<boolean>();
  private loaded = false;
  private onlyMyEquipment = false;
  private loadedAllEquipments = false;

  constructor(
    private equipmentRestService: EquipmentRestService,
    private httpCancelUtilService: HttpCancelUtilService,
    private equipmentUtilService: EquipmentUtilService) {
  }

  get showOnlyMine$(): Observable<boolean> {
    return this.showOnlyMineSubject.asObservable();
  }

  get loadAllData$(): Observable<boolean> {
    return this.loadAllDataSubject.asObservable();
  }

  get isInitialized(): boolean {
    return this.loaded;
  }

  isOnlyMyEquipment(): boolean {
    return this.onlyMyEquipment;
  }

  initialize() {
    this.equipmentRestService.getMyProfileChecked()
      .subscribe(({ checked }) => {
        this.onlyMyEquipment = checked;
        this.loadedAllEquipments = !this.onlyMyEquipment;
        this.loaded = true;
        this.showOnlyMineSubject.next(checked);
      }, err => this.showOnlyMineSubject.error(err));
  }

  destroy() {
    this.showOnlyMineSubject.complete();
    this.loadAllDataSubject.complete();
  }

  toggleFilter(isChecked: boolean) {
    this.onlyMyEquipment = isChecked;
    this.manageCache();
    this.showOnlyMineSubject.next(isChecked);
  }

  toggle(isChecked: boolean) {
    this.equipmentRestService.postMyProfileChecked(isChecked)
      .subscribe(() => {
        this.toggleFilter(isChecked);
      }, err => {
        this.showOnlyMineSubject.next(!isChecked);
        this.showOnlyMineSubject.error(err);
        this.loaded = false;
      });
  }

  private manageCache() {
    if (!this.loadedAllEquipments) {
      this.loadedAllEquipments = true;
      this.httpCancelUtilService.cancelPendingRequests();
      this.equipmentRestService.clearCache(equipmentRestName);
      this.equipmentRestService.clearCache(equipmentStatusRestName);
      this.equipmentRestService.clearCache(ticketsAllRestName);
    }
    this.loadAllDataSubject.next(true);
  }
}
