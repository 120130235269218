import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  commitUpload,
  computeHeaders,
  prepareUpload,
  rollbackUpload,
  updateExists,
  uploadInfo,
  validateHowToFile,
  validatePackageInfoFile
} from './asu-rest-constants.service';
import { Observable } from 'rxjs';
import {
  AsuUploadInfo, UpdateCommit,
  UpdateExists,
  UpdateLocked,
  ValidationResult
} from '../models/systemUpdates/asu-upload-request';
import { UploadRequest } from '../models/upload-request';

@Injectable()
export class AsuUploadRestService {

  constructor(private http: HttpClient) {
  }

  prepareUpload(uiNumber: string): Observable<any> {
    return this.http.post(prepareUpload, null, {params: new HttpParams().set('updateNumber', uiNumber)});
  }

  computeHeaders(uiNumber: string, fName: string, hash: string, mTime:
    string, fSize: string, isMetadata: string): Observable<UploadRequest> {
    return this.http.get<UploadRequest>(computeHeaders, {
      params: new HttpParams().set('updateNumber', uiNumber).set('fileName', fName).set('sha256', hash).set('mTime', mTime)
        .set('size', fSize).set('isMetadata', isMetadata)
    });
  }

  checkUpdateExist(uiNumber: string): Observable<UpdateExists> {
    return this.http.get<UpdateExists>(updateExists, {params: new HttpParams().set('updateNumber', uiNumber)});
  }

  acquireLock(uiNumber: string, asuUploadInfo: AsuUploadInfo): Observable<UpdateLocked> {
    return this.http.post<UpdateLocked>(this.formatUploadInfoRestPoint(uiNumber), asuUploadInfo);
  }

  validateHowToFile(file: FormData): Observable<ValidationResult> {
    return this.http.post<ValidationResult>(validateHowToFile, file);
  }

  validatePackageInfoFile(file: FormData): Observable<ValidationResult> {
    return this.http.post<ValidationResult>(validatePackageInfoFile, file);
  }

  commitUpload(uiNumber: string, packageUpToDate: boolean): Observable<UpdateCommit> {
    return this.http.post<UpdateCommit>(commitUpload, null, {
      params: new HttpParams()
        .set('updateNumber', uiNumber)
        .set('packageUpToDate', packageUpToDate ? 'true' : 'false')});
  }

  rollbackUploadCancelled(uiNumber: string): Observable<any> {
    return this.http.post(rollbackUpload, null, {
      params: new HttpParams()
        .set('updateNumber', uiNumber).set('rollbackReason', 'CANCELLED')
    });
  }

  rollbackUploadFailed(uiNumber: string): Observable<any> {
    return this.http.post(rollbackUpload, null, {
      params: new HttpParams()
        .set('updateNumber', uiNumber).set('rollbackReason', 'FAILED')
    });
  }

  getUploadInfo(uiNumber: string): Observable<AsuUploadInfo> {
    return this.http.get<AsuUploadInfo>(this.formatUploadInfoRestPoint(uiNumber));
  }

  private formatUploadInfoRestPoint(uiNumber: string): string {
    return uploadInfo.replace(/:uiNumber/gi, uiNumber);
  }
}
