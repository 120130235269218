import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SeriesData } from './diagram';
import { BarVerticalComponent } from '@swimlane/ngx-charts';

@Component({
  selector: 'hl-diagram-bars',
  template: `
    <ngx-charts-bar-vertical #chart [view]="view ? view : ''" [roundDomains]="true" [roundEdges]="false" [gradient]="gradient"
      [customColors]="customColors" [results]="results" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="false"
      [showGridLines]="showGridLines" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel" [yScaleMax]="yScaleMax" [scheme]="scheme" [yAxisTickFormatting]="yAxisTickFormatting"
      [animations]="animations" [barPadding]="barPadding" (select)="onSelected($event)"></ngx-charts-bar-vertical>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagramBarsComponent {
  @ViewChild('chart', {static: false})
  private chart: BarVerticalComponent;

  @Input() scheme: any;
  @Input() yAxisTickFormatting: any;
  @Input() animations: boolean;
  /**
   * Show the x axis names
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showXAxis = true;
  /**
   * show the y axis names
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showYAxis = true;
  /**
   * show Gridlines
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showGridLines = true;
  /**
   * Show label for x axis (need a value in xAxisLabel)
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showXAxisLabel = false;
  /**
   * Show label for y axis (need a value in yAxsisLabel)
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showYAxisLabel = false;
  /**
   * Label x axsis
   *
   * @type {string}
   * @memberof LclDiagramBarsComponent
   */
  @Input() xAxisLabel: string;
  /**
   * Label y axis
   *
   * @type {string}
   * @memberof LclDiagramBarsComponent
   */
  @Input() yAxisLabel: string;
  /**
   * set a scale maxium
   *
   * @type {number}
   * @memberof LclDiagramBarsComponent
   */
  @Input() yScaleMax: number;
  /**
   * Colors aof the bars are gradient
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() gradient = true;
  /**
   * Show a label of the value in the top aof a chart
   *
   * @memberof LclDiagramBarsComponent
   */
  @Input() showDataLabel = false;

  /**
   * Switch chart colors
   *
   * @memberof LclDiagramDoughnutComponent
   */
  @Input() useMonochrome = false;
  /**
   * Results for the diagram rendering
   *
   * @type {SeriesData[]}
   * @memberof DiagramComponent
   */
  @Input() results: SeriesData[] = [];
  /**
   * Options for a view of the diagram as array with [width,height]
   *
   * @type {[number, number]}
   * @memberof DiagramComponent
   */
  @Input() view: number[] = null;

  /**
   * Emitted when any bar is clicked. The data contains the name of clicked series.
   */
  @Output() onSelect: EventEmitter<{ series }> = new EventEmitter<{ series }>();

  /**
   * Placeholder array for colors
   *
   * @type {object[]}
   * @memberof DiagramComponent
   */
  customColors: object[] = [];

  /**
   * Creates an instance of DiagramComponent.
   * @memberof DiagramComponent
   */
  constructor() {
  }

  get barPadding(): number {
    return this.chart.width ? Math.ceil(this.chart.width / this.results.length * .7) : 45;
  }

  onSelected($event) {
    this.onSelect.emit($event);
  }
}
