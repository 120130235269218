import { Component, Renderer2 } from '@angular/core';
import { SelectOption } from '../../../core/models/select-option';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { WindowService } from '../../../core/window.service';
import { EnvironmentConfigRestService } from '../../../core/rest-services/environment-config-rest.service';
import { ImpersonationUtilsService } from '../../../core/utils/impersonation-utils.service';


@Component({
  selector: 'hl-registration-modal',
  templateUrl: './registration-modal-component.html'
})
export class RegistrationModalComponent extends BaseModalPopup {
  window = this.windowService.nativeWindow;

  selectedCountry: string;
  countries: SelectOption[];
  listOfCountryCodes: string[];

  constructor(renderer: Renderer2,
    private countryConfig: CountryConfigRestService,
    private environmentConfigRestService: EnvironmentConfigRestService,
    private impersonationUtilService: ImpersonationUtilsService,
    private windowService: WindowService) {
    super(renderer);
    this.init();
  }


  init() {
    this.environmentConfigRestService.getEnvironmentConfig().subscribe((configResponse) => {
      this.listOfCountryCodes = configResponse.REGISTRATION_COUNTRY_LIST.split(',');
      this.updateTranslations();
    });
  }

  show(event?: Event) {
    this.selectedCountry = undefined;
    super.show(event);
  }

  updateTranslations() {
    this.countries = this.impersonationUtilService.generateCountryTranslationMapping(
      this.listOfCountryCodes,
      false
    );
  }

  ok() {
    this.countryConfig.getSpecificCountryConfig(this.selectedCountry).subscribe(config => {
      this.window.location.href = config.LINK_REGISTER_WELCOME;
    });
  }
}
