import { Pipe, PipeTransform } from '@angular/core';
import { AvailabilityOptions } from 'app/core/models/optionsUpgrades/availability-options';

@Pipe({
  name: 'getItemAvailabilityOptions'
})
export class GetItemAvailabilityOptionsPipe implements PipeTransform {

  availabilityStates: AvailabilityOptions[] = [
    {
      title: 'OPTION_AVAILABILITY_LEGEND_ALREADYINSTALLED',
      value: 'AlreadyInstalled',
      color: 'bold'
    },
    {
      title: 'OPTION_AVAILABILITY_LEGEND_WITHOUTPREREQUISITE',
      value: 'WithoutPrerequisites',
      color: 'green'
    },
    {
      title: 'OPTION_AVAILABILITY_LEGEND_WITHPREREQUISITE',
      value: 'WithPrerequisites',
      color: 'yellow'
    }
  ];

  transform(item: string) {
    return this.availabilityStates.find(state => state.value === item);
  }
}
