
export class TeamplayWidgetViewModel {
  public modalityName: string;
  public color: string;
  public avgNumberOfExams: number;
  public avgExamDurationMin: number;
  public highestAvgSystemTime: number;
  public highestAvgSystemNames: string[];
  public lowestAvgSystemTime: number;
  public lowestAvgSystemNames: string[];

  static addColors(data: TeamplayWidgetViewModel[]): TeamplayWidgetViewModel[] {
    const colors = ['#ec6602', '#009999', '#1a1a1a', '#3abfed', '#7a162d', '#2b2483'];
    let index = 0;
    for (const item of data) {
      item.color = colors[index++];
    }
    return data;
  }
}
