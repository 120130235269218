import { CacheableHttpRequest } from '../../models/cacheable-http-request';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpCache } from '../../models/http-cache';
import { find, isEqual, remove } from 'lodash';

@Injectable()
export class HttpCacheService {

  cache: HttpCache[] = [];

  constructor() { }

  /**
   * retrieve a HttpResponse from the cache.
   *
   * @param {(HttpRequest<any>)} request
   * @returns {(HttpResponse<any> | null)}
   * @memberof HttpCacheService
   */
  get(request: CacheableHttpRequest<any>): HttpResponse<any> | null {
    const cachedResponse = find(this.cache, { request });
    if (cachedResponse) {
      return cachedResponse.response;
    }
    return null;
  }

  /**
   * stores a given request-response pair in the internal cache.
   *
   * @param {HttpRequest<any>} request
   * @param {HttpResponse<any>} response
   * @memberof HttpCacheService
   */
  put(request: CacheableHttpRequest<any>, response: HttpResponse<any>): void {

    // first check, if do already have an entry for that request and if so: update it
    const cachedRequest = find(this.cache, { request });

    if (cachedRequest) {
      cachedRequest.response = response;
    } else {
      this.cache.push({ request, response });
    }
  }

  /**
   * clears cashed object for an individual request. Can also be used to clear
   * all requests matching a url string.
   *
   * @param {(HttpRequest<any> | string)} request
   * @memberof HttpCacheService
   */
  clearIndividualRequest(request: CacheableHttpRequest<any> | string): void {
    if (request instanceof CacheableHttpRequest) {
      remove(this.cache, { request });
    } else {
      remove(this.cache, obj => isEqual(obj.request.url, request));
    }
  }

  /**
   * the method 'modifyBody' you are passing as a parameter should return the modified body - this
   * body will then replace the former body inside the cache
   *
   * @param {CacheableHttpRequest<any> | string} request
   * @param {(toBeModified: HttpResponse<any>) => any} modifyBody
   */
  modifyEntry<T>(request: CacheableHttpRequest<any> | string, modifyBody: (toBeModified: HttpResponse<T>) => T): void {
    if (request instanceof CacheableHttpRequest) {
      const cachedResponse = find(this.cache, { request });
      if (cachedResponse) {
        const newBody = modifyBody(cachedResponse.response);
        this.replaceInCache(cachedResponse, newBody, request);
      } else {
        // I think it does not makes sense to call something, if there is nothing in the cache
        // modifyBody(null);
      }
    } else {
      let requestObj: CacheableHttpRequest<any> = null;

      const cachedResponse = find(this.cache, obj => {
        if ((isEqual(obj.request.url, request))) {
          requestObj = obj.request;
          return true;
        }
        return false;
      });
      if (cachedResponse) {
        const newBody = modifyBody(cachedResponse.response);
        this.replaceInCache(cachedResponse, newBody, requestObj);
      } else {
        // // I think it does not makes sense to call something, if there is nothing in the cache
        // modifyBody(null);
      }
    }
  }

  /**
   * replaced the response with a cloned response with the new body
   * @param cachedResponse
   * @param newBody
   * @param {CacheableHttpRequest<any>} request
   */
  private replaceInCache(cachedResponse: any, newBody: any,
    request: CacheableHttpRequest<any>) {
    const newResponse = cachedResponse.response.clone({
      body: newBody,
      headers: cachedResponse.response.headers,
      status: cachedResponse.response.status,
      statusText: cachedResponse.response.statusText,
      url: cachedResponse.response.url
    });
    this.put(request, newResponse);
  }

  /**
   * clears the internal cache completely
   *
   * @memberof HttpCacheService
   */
  clearAll(): void {
    this.cache = [];
  }
}
