import { PackageInfo } from '../models/systemUpdates/package-info';

export class PackageInfoViewModel {
  public installationDuration: string;
  public rebootRequired: boolean;
  public packageSize: string;
  public packageName: string;
  public content: string;

  constructor(model: PackageInfo) {
    this.installationDuration = model.installationDuration;
    this.rebootRequired = model.rebootRequired;
    this.packageSize = model.packageSize;
    this.packageName = model.packageName;
    this.content = model.content;
  }

  public get reboot(): string {
    if (this.rebootRequired) {
      return 'GENERIC_LABEL_YES';
    } else {
      return 'GENERIC_LABEL_NO';
    }
  }

  public get packageNameWithoutExtension(): string {
    return this.packageName.indexOf('.') !== -1
      ? this.packageName.substring(0, this.packageName.lastIndexOf('.'))
      : this.packageName;
  }
}
