import { Pipe, PipeTransform } from '@angular/core';
import {ContractsViewModel} from '../../../core/view-models/contracts-view-model';

@Pipe({
  name: 'getContractStatusColor'
})
export class GetContractStatusColorPipe implements PipeTransform {

  contractsTypes = [];

    constructor() {
      this.contractsTypes[1] = 'status-green';
      this.contractsTypes[2] = 'status-yellow';
      this.contractsTypes[3] = 'status-red';
  }

  transform(item: ContractsViewModel) {
    // if item is defined
    if (item && item.contractStatusId) {
      const colorClass = this.contractsTypes[parseInt(item.contractStatusId, 10)];
      return colorClass === undefined ? '' : colorClass;
    }
    return '';
  }

}
