import { SearchFieldComponent } from '../search-field/search-field.component';
import { Component } from '@angular/core';


@Component({
  selector: 'hl-timezone',
  templateUrl: './timezone.component.html'
})
export class TimezoneComponent extends SearchFieldComponent {

  onChange(newInput: string) {
    super.onChange(newInput);
    this.showAutocomplete();
  }

  onFocus() {
    this.showAutocomplete();
  }

  onBlur() {
    this.hideAutocomplete();
  }
}
