import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { Subject } from 'rxjs';
import { ImpersonationCommunicationService } from '../../core/component-communication-services/impersonation/impersonation-communication.service';
import { NotifStatus, roles } from '../../core/core-constants.service';
import { SelectOption } from '../../core/models/select-option';
import { ticketsAllRestName } from '../../core/rest-services/ticket-rest-constants.service';
import { TicketsRestService } from '../../core/rest-services/tickets-rest.service';
import { TicketsUtilService } from '../../core/services/tickets/tickets-util.service';
import { UserUtilService } from '../../core/services/user/user-util.service';
import { FilterUtilService } from '../../core/utils/filter-util.service';
import { TicketViewModel } from '../../core/view-models/ticket-view-model';
import { TicketActionsComponent } from '../../shared/components/ticket-actions/ticket-actions.component';
import { SortDirection } from '../../shared/sorting/sort-object';
import { clone, includes, isEqual, parseInt } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';

@Component({
  selector: 'hl-tickets-widget',
  templateUrl: './tickets-widget.component.html'
})
export class TicketsWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('actions', {static: false})
  @Input()
  isFullWidth = false;

  actions: TicketActionsComponent;

  rawList: TicketViewModel[];
  viewModelList: TicketViewModel[];
  wholeViewModelList: TicketViewModel[];

  datePattern = '';
  dateTimePattern = '';

  myEquipmentProfileList = [];
  myEquipmentChecked = false;
  isLoaded: boolean;
  isEquipmentLoaded: boolean;
  widgetListSize = 3;

  ticketItemForActions: TicketViewModel;

  operationalStateDescriptions: SelectOption[] = [];
  ticketStatusYellow: string[];
  ticketStatusRed: string[];
  toggleCustomerName = false;
  customerNameLength = undefined;
  private operationalStateFilterOptions: string[];

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private configService: CountryConfigRestService,
    private ticketsUtilService: TicketsUtilService,
    private userUtilService: UserUtilService,
    private ticketsRestService: TicketsRestService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private filterUtilService: FilterUtilService,
    private myFiltersService: MyFiltersAdapterService
  ) {
  }

  ngOnInit() {
    this.init();

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.initProperties();
      this.setConfigProperties(configResponse);
      this.loadViewModelTicket();
    });
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    this.impersonationCommunicationService.onCountryLanguageChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.init();
        this.onAdvancedFilterChange();
        this.isLoaded = true;
      }
    );

    this.myFiltersService.status$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(myEquipment => {
        this.myEquipmentChecked = myEquipment.overallSwitch;
        this.myEquipmentProfileList = myEquipment.equipment.map(e => e.key);
        this.onAdvancedFilterChange();
        this.isEquipmentLoaded = true;
      });
  }

  initProperties() {
    this.isLoaded = false;
    this.isEquipmentLoaded = false;

    this.ticketItemForActions = null;

    this.datePattern = 'ddmmyyyy';
    this.dateTimePattern = 'ddmmyyyy hh:mm';

    this.rawList = [];
    this.viewModelList = [];
  }

  setConfigProperties(config) {
    // get the locale date pattern
    this.datePattern = config.GENERIC_DATE_PATTERN;
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;

    this.ticketStatusYellow = config.TICKET_STATUS_YELLOW.split(',');
    this.ticketStatusRed = config.TICKET_STATUS_RED.split(',');

    this.operationalStateFilterOptions = config.OPERATIONAL_STATE_FILTER_OPTIONS.split(',');

    // pagination size for ticket widget in dashboard
    this.widgetListSize = parseInt(config.OPEN_TICKETS_WIDGET_LIST_PAGE_SIZE, 10);

    this.toggleCustomerName = isEqual(config.TOGGLE_CUSTOMER_NAME_TICKET_LIST, 'true');
    this.customerNameLength = config.LENGTH_CUSTOMER_NAME_DISPLAY > 0 ? config.LENGTH_CUSTOMER_NAME_DISPLAY : undefined;
  }

  /**
   * @description Get and initialize view model for equipment list by merging equipment and equipment status
   */
  loadViewModelTicket() {
    this.ticketsUtilService.getTicketsViewModelList(NotifStatus.OPEN)
      .subscribe(viewModelResponse => {
        // clone deep not to change original
        this.rawList = clone(viewModelResponse);
        this.checkUserHasViewEquipmentRole();
      });
  }

  checkUserHasViewEquipmentRole() {
    // object to check roles
    const rolesToCheck = {
      checkViewEquipmentRole: roles.viewEquipmentRole
    };

    this.userUtilService.checkUserRoles(rolesToCheck).subscribe(
      checkRolesResponse => {
        // enable if view equipment role found
        if (checkRolesResponse.checkViewEquipmentRole) {
          // get my siemens equipment profile
            this.ticketsUtilService.getOperationalStateDescriptions(this.operationalStateFilterOptions)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(data => {
              this.operationalStateDescriptions = data;
            });
        }
        this.isLoaded = true;
        this.onAdvancedFilterChange();
      },
      () => {
        // for issue ISHL-578 (not done with finally())
        this.onAdvancedFilterChange();
        this.isLoaded = true;
      }
    );
  }

  /**
   * @description Filtering of view model list with different filters
   */
  onAdvancedFilterChange() {
    const filterObject = {
      myEquipment: {
        isMyEquipmentChecked: this.myEquipmentChecked,
        keyName: 'equipmentKey',
        myEquipmentList: this.myEquipmentProfileList
      },
      orderBy: {
        sortBy: 'problemSeverity',
        sortDir: SortDirection.ASC,
        thenSortBy: [{
          sortBy: 'ticketCreationTimestamp',
          sortDir: SortDirection.DESC
        }]
      }
    };

    const limitToFilterObject = {
      limitTo: this.widgetListSize
    };

    // initial value for filtered result with my siemens equipment and order by
    this.wholeViewModelList = this.filterUtilService.getListAfterApplyingFilterPipes(
      this.rawList,
      filterObject
    );

    // view model list with pagination
    this.viewModelList = this.filterUtilService.getListAfterApplyingFilterPipes(
      this.wholeViewModelList,
      limitToFilterObject
    );
  }

  refreshTicket() {
    this.viewModelList = [];
    this.isLoaded = false;
    this.ticketsRestService.clearCache(ticketsAllRestName);
    this.loadViewModelTicket();
  }

  /**
   *
   * @param ticketItem | Ticket View Model
   * @param action | action type (convert, update,close)
   */
  action(ticketItem, action) {
    this.ticketItemForActions = ticketItem;
    this.actions.openTicketActionModal(action);
  }

  getNumberOfTicketsForStatus(value: string): number {
    return this.wholeViewModelList.filter(item => includes(value.split(','), item.problemSeverityID)).length;
  }

  getNumberOfAllTickets(): number {
    return this.wholeViewModelList.length;
  }

  goToTicket(ticket: TicketViewModel): void {
    this.router.navigate(['/tickets', ticket.ticketKey, 'overview'], {
      queryParams: {ticketIdentifier: ticket.ticketNumber}
    });
  }

  trackByFn(item: TicketViewModel) {
    return item['ticketKey'];
  }
}
