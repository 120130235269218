import { Component, Input, Renderer2 } from '@angular/core';
import { OptionsRecommendModalComponent } from '../options-recommend-modal/options-recommend-modal.component';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { EquipmentConstantsService } from '../../../core/services/equipment/equipment-constants.service';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import * as _ from 'lodash';
import { OptionsUpgradesViewModel } from '../../../core/view-models/options-upgrades-view-model';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';

@Component({
  selector: 'hl-options-get-quote-modal',
  templateUrl: './options-get-quote-modal.component.html'
})
export class OptionsGetQuoteModalComponent extends OptionsRecommendModalComponent {

  /** the name of the option, for which the modal is opened */
  _option: OptionsUpgradesViewModel;

  @Input()
  set option(option: OptionsUpgradesViewModel) {
    this._option = option;

    this.updatePreselection();
  }

  get option(): OptionsUpgradesViewModel {
    return this._option;
  }

  options: DropdownOptions[];
  selectedOptions: string[];

  constructor(userRestService: UserRestService,
    equipmentRestService: EquipmentRestService,
    equipmentConstantsService: EquipmentConstantsService,
    toasterService: ToasterService,
    renderer: Renderer2,
    protected equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService) {
    super(userRestService,
      equipmentRestService,
      equipmentConstantsService,
      toasterService,
      configService,
      renderer);
  }

  initForm() {
    this.options = [];
    this.selectedOptions = this.formData.application;

    super.initForm();

    this.equipmentRestService.getOptionsAndUpgrades(this.equipmentItem.key)
      .subscribe((list) => {
        const optionUpgradesList = _.clone(this.equipmentUtilService.mergeAndExtendOptionsUpgrades(list));
        this.initDropDownList(optionUpgradesList);
      });
  }

  initDropDownList(optionUpgradesList: OptionsUpgradesViewModel[]) {
    _.forEach(optionUpgradesList, (optionUpgrade) => {
      if (!this.options.some(o => o.title === optionUpgrade.name)) {
        this.options.push({value: optionUpgrade.name, title: optionUpgrade.name});
      }
    });

    this.options = _.sortBy(this.options, (i) => i.title.toLowerCase());

    // has also to be done here - otherwise, if the modal is opened again after it has been sent
    // for the same option - this option is no longer preselected
    this.updatePreselection();
  }

  postFormData() {
    this.resolveObservable(this.equipmentRestService.postGetQuote(this.equipmentItem.key, this.getPreparedPostBody()));
  }

  // this is called before the post happens
  getPreparedPostBody(): object {
    return _.omit(this.formData, ['clinicalFields', 'testStartDate', 'remoteInstallation',
      'contactMe', 'termsAndCondition']);
  }

  updatePreselection() {
    if (this.option) {
      this.formData.application = [];
      this.formData.application.push(this.option.name);
    }
  }

  validateOptionSelection() {
    this.modalForm.form.setErrors(this.selectedOptions.length === 0 && {err: true});
  }
}
