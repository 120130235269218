import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'dangerForPatient'
})
export class DangerForPatientPipe implements PipeTransform {

  transform(dataset: any, selectedValue: any): any {

    const filtered = [];

    if (_.isEmpty(selectedValue)) {
      return dataset;
    }

    selectedValue.forEach(valueInBoolean => {
      _.filter(dataset, { dangerForPatient: valueInBoolean}).forEach(item => {
        filtered.push(item);
      });
    });
    return filtered;
  }

}
