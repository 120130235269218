import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'diagramCalculateHeightPipe'
})
export class DiagramCalculateHeightPipe implements PipeTransform {
  transform(numberOfBars: number, minBarsForDynamicSizing: number): number {
    if (!numberOfBars) {
      return 0;
    }

    const minHeight = numberOfBars > minBarsForDynamicSizing ? 480 : 500;
    const barSumHeightToChartHeightRatio = 0.8;
    return numberOfBars > minBarsForDynamicSizing ?
      minHeight + (numberOfBars - minBarsForDynamicSizing) * minHeight / minBarsForDynamicSizing * barSumHeightToChartHeightRatio :
      minHeight;
  }
}
