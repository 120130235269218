import { combineLatest, merge, Subject } from 'rxjs';
import { ImpersonationCommunicationService } from './core/component-communication-services/impersonation/impersonation-communication.service';
import { SelectOption } from './core/models/select-option';
import { ImpersonationRestService } from './core/rest-services/impersonation-rest.service';
import { User } from './core/models/user';

import { UserUtilService } from './core/services/user/user-util.service';
import { LifeNetUtilService } from './core/utils/life-net-util.service';
import { ImpersonationUtilsService } from './core/utils/impersonation-utils.service';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { EnvironmentConfigRestService } from './core/rest-services/environment-config-rest.service';
import { DropdownOptions } from './core/models/dropdown-options';
import { AdditionalContactsComponent } from './shared/components/additional-contacts/additional-contacts.component';
import { ChatUtilService } from './core/services/chat/chat-util.service';
import { SwitchLanguageModalComponent } from './shared/modal-popup/switch-language-modal/switch-language-modal.component';
import { FilterUtilService } from './core/utils/filter-util.service';
import { SelectEquipmentModalComponent } from './shared/modal-popup/select-equipment-modal/select-equipment-modal.component';
import { roles } from './core/core-constants.service';
import { CountryConfigRestService } from './core/rest-services/country-config-rest.service';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'hl-tool-bar',
  templateUrl: './tool-bar.component.html'
})
export class ToolBarComponent implements OnInit, OnDestroy {

  @ViewChild('contactModal', {static: false})
  contactModalView: AdditionalContactsComponent;

  @ViewChild('changeLanguageCountryModal', {static: false})
  switchLanguageModalComponent: SwitchLanguageModalComponent;

  @ViewChild('selectEquipmentModal', {static: false})
  selectEquipmentModalComponent: SelectEquipmentModalComponent;

  @ViewChild('dropDownToggle', {static: false}) dropDownToggleEl: ElementRef;

  isLoaded: boolean;
  isCountryLangSwitchCollapsed: boolean;
  showCountries: boolean;
  showCountryLanguageDropDown: boolean;

  selectedCountry: string;
  selectedLocale: string;
  selectedLanguage: string;
  countries: DropdownOptions[];
  languages: SelectOption[];
  siemensHealthcareLink: string;

  user: User = null;

  isDropdownOpen = false;
  canImpersonateToEquipment = false;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (
      this.isDropdownOpen && !this.dropDownToggleEl.nativeElement.contains(event.target)
    ) {
      this.isDropdownOpen = false;
    }
  }

  constructor(
    private impersonationUtilService: ImpersonationUtilsService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private lifeNetUtilService: LifeNetUtilService,
    private userUtilService: UserUtilService,
    private impersonationRestService: ImpersonationRestService,
    private environmentConfigRestService: EnvironmentConfigRestService,
    private chatUtilService: ChatUtilService,
    private filterUtilService: FilterUtilService,
    private configService: CountryConfigRestService
  ) {
  }

  ngOnInit() {
    this.init();

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.initProperties();
    this.userUtilService.getUser()
      .pipe(
        tap(user => this.user = user),
        switchMap(() => this.checkAndSetCountryLanguageSwitchBasedOnUserAndImpersonation()),
        finalize(() => this.isLoaded = true)
      )
      .subscribe(() => {
        this.chatUtilService.triggerChatScripts();
      });
    this.loadEnvironmentConfig();
    this.checkUserRoles();
  }

  checkUserRoles() {
    const rolesToCheck = {
      canViewEquipment: roles.viewEquipmentRole,
      canImpersonate: roles.impersonateUserRole
    };

    combineLatest([this.userUtilService.checkUserRoles(rolesToCheck), this.configService.getConfig()])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((responses) => {
        const userRoles = responses[0];
        const config = responses[1];
        this.canImpersonateToEquipment =
          userRoles && userRoles.canImpersonate && userRoles.canViewEquipment &&
          _.isEqual(config.TOGGLE_EQUIPMENT_IMPERSONATION, 'true');
      });
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    merge(
      this.impersonationCommunicationService.onImpersonationChange$,
      this.impersonationCommunicationService.onCountryLanguageChange$)
      .pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.init();
      }
    );
  }

  initProperties() {
    this.isLoaded = false;
    this.isCountryLangSwitchCollapsed = true;
    this.showCountries = false;
    this.showCountryLanguageDropDown = false;
  }

  checkAndSetCountryLanguageSwitchBasedOnUserAndImpersonation() {
    // get list of currently impersonated customers from the data share service
    return this.impersonationRestService
      .getImpersonatedCustomers()
      .pipe(tap(customerResponse => {
        const distinctListOfImpersonatedCountries = this.getListOfImpersonatedCustCountries(
          customerResponse
        );

        // list of allowed languages for the country assigned to user
        const listOfLanguagesForUserCountry = this.impersonationUtilService.getListOfAllowedLanguagesForUserCountry(
          this.user.countries,
          this.user.country
        );

        if (this.user.countries.length >= 1) {
          this.setCountryAndLocaleAndLanguages();
        }

        /**
         * The country and language drop down is shown for 4 scenarios.
         *
         * Scenario 1) - BE is selected and BE customer assigned (also FR and GB customers)
         * -> available countries BE, FR and GB
         * -> Both language and country drop down is shown.
         *
         * Scenario 3) - BE is selected and no customers assigned.
         * -> No country drop down.
         * -> Only language drop down with (Dutch, French as language
         *  and not English if he has only one country in /users/self).
         *
         * Scenario 4) DE customer logs in, multiple countries in /users/self but no customers assigned (or only DE customers assigned)
         * -> No toolbar drop down.
         */

        if (
          listOfLanguagesForUserCountry &&
          listOfLanguagesForUserCountry.length >= 1 &&
          distinctListOfImpersonatedCountries &&
          distinctListOfImpersonatedCountries.length > 1
        ) {
          this.showCountryLanguageDropDown = true;
          this.showCountries = true;

          this.countries = this.impersonationUtilService.generateCountryTranslationMapping(
            distinctListOfImpersonatedCountries,
            false
          );
        } else if (
          listOfLanguagesForUserCountry &&
          listOfLanguagesForUserCountry.length >= 1 &&
          distinctListOfImpersonatedCountries &&
          (distinctListOfImpersonatedCountries.length === 0 ||
            distinctListOfImpersonatedCountries.length === 1)
        ) {
          this.showCountryLanguageDropDown = true;

          this.countries = this.impersonationUtilService.generateCountryTranslationMapping(
            distinctListOfImpersonatedCountries,
            false
          );
        }
      }));
  }

  getListOfImpersonatedCustCountries(data) {
    const impersonatedCustomerCountries = this.filterUtilService.getListOfPropertyValuesFromListOfObject(
      data.customers,
      'country'
    );
    const impersonatedCustomerGroupsCountries = this.filterUtilService.getListOfPropertyValuesFromListOfObject(
      data.customerGroups,
      'country'
    );

    return _.union(
      impersonatedCustomerCountries,
      impersonatedCustomerGroupsCountries
    );
  }

  /**
   *
   * @description
   * set the selected country, locale and list of languages.
   *
   * Note: This should be called only during initial load and there after
   * selection of locale value should be made based on users default language in the
   * countries list -> /users/self
   */
  setCountryAndLocaleAndLanguages() {
    this.selectedCountry = this.user.country;
    this.selectedLocale = this.impersonationUtilService.getLocaleWithFallbackCheck(
      this.user.language,
      this.user.country,
      this.user.countries
    );
    this.selectedLanguage = this.user.language;
    this.languages = this.impersonationUtilService.generateLanguageTranslationMappingAndAddDefaultLang(
      this.user.countries,
      this.selectedCountry
    );
  }

  updateLanguageOnCountryChange() {
    this.languages = this.impersonationUtilService.generateLanguageTranslationMappingAndAddDefaultLang(
      this.user.countries,
      this.selectedCountry
    );

    const selectedLocale = this.impersonationUtilService.getDefaultLanguageForCountry(
      this.user.countries,
      this.selectedCountry
    );

    if (selectedLocale) {
      this.selectedLocale = selectedLocale;
    }
  }

  loadEnvironmentConfig() {
    this.environmentConfigRestService
      .getEnvironmentConfig()
      .subscribe(environmentConfigResponse => {
        this.siemensHealthcareLink =
          environmentConfigResponse.MY_HEALTHCARE_LINK;
      });
  }

  showGlobeIcon(): boolean {
    return (
      (this.countries && this.countries.length > 1) ||
      (this.languages && this.languages.length > 1)
    );
  }

  showContactInfoModal(event: Event) {
    event.preventDefault();
    this.contactModalView.show();
  }

  showSwitchLanguageModal(event: Event) {
    event.preventDefault();
    this.switchLanguageModalComponent.show();
  }

  showSelectEquipmentModal(event: Event) {
    event.preventDefault();
    this.selectEquipmentModalComponent.show();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
