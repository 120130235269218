import { AddRootClassesService } from '../../../core/component-communication-services/add-root-classes/add-root-classes.service';
import { Directive, Renderer2 } from '@angular/core';

@Directive({
  selector: '[hlAddRootClasses]'
})
export class AddRootClassesDirective {

  constructor(private addRootClassService: AddRootClassesService,
    private renderer: Renderer2) {
    this.init();
  }

  init() {
    this.addRootClassService.addRootClassSource$.subscribe((classString) => {
      this.renderer.addClass(document.getElementsByTagName('html')[0], classString);
    });

    this.addRootClassService.removeRootClass$.subscribe((classString) => {
      this.renderer.removeClass(document.getElementsByTagName('html')[0], classString);
    });
  }
}
