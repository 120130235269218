import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { EquipmentEnvironmentData } from '../app/core/models/equipment/equipment-environment';
import { temp } from 'cdmockdata/cd-temp.mock';
import { humi } from 'cdmockdata/cd-humi.mock';
@Injectable({ providedIn: 'root' })
export class EnvironmentDataMock {
  request: HttpRequest<any>;
  requestData: {
    sensorId: number;
    parameter: string;
    timezone: string;
    dateFrom: number;
    dateTo: number;
  };

  public getData(): EquipmentEnvironmentData | null {
    console.log(this.request);
    this.requestData = this.request.body;
    switch (this.requestData.parameter) {
      case 'Temperature':
        return this.generateTemperature(
          this.requestData.dateFrom,
          this.requestData.dateTo
        );
        break;
      case 'Humidity':
        return this.generateHumidity(
          this.requestData.dateFrom,
          this.requestData.dateTo
        );
        break;
      default:
        return null;
        break;
    }
  }

  private generateTemperature(
    dateFrom: number,
    dateTo: number
  ): EquipmentEnvironmentData {
    const data: EquipmentEnvironmentData = {
      data: [],
      unit: '\\u00b0'
    };
    let n = 0;
    while (dateFrom < dateTo) {
      data.data.push({
        name: new Date(dateFrom).toString(),
        value: temp[n] ? temp[n] : temp[0]
      });
      dateFrom = dateFrom + 60 * 60 * 1000;
      n++;
    }
    return data;
  }

  private generateHumidity(
    dateFrom: number,
    dateTo: number
  ): EquipmentEnvironmentData {
    const data: EquipmentEnvironmentData = {
      data: [],
      unit: '%'
    };
    let n = 0;
    while (dateFrom < dateTo) {
      data.data.push({
        name: new Date(dateFrom).toString(),
        value: humi[n] ? humi[n] : humi[0]
      });
      dateFrom = dateFrom + 120 * 60 * 1000;
      n++;
    }
    return data;
  }
}
