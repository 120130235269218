import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hl-read-more',
  templateUrl: './read-more.component.html'
})
export class ReadMoreComponent implements OnChanges, OnInit {
  text: string;
  @Input()
  textTranslation: string;
  @Input()
  maxLength = 100;
  currentText: string;
  hideToggle = true;
  @Input()
  beforeText = '';
  @ViewChild('toggle', { static: false })
  toggleButton: ElementRef;

  public isCollapsed = true;

  constructor(
    private elementRef: ElementRef,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.translateService.get(this.textTranslation).subscribe((translated: string) => {
      this.text = translated;
      this.determineView();
    });
  }

  toggleView(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.blur();
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }

  determineView() {
    if (this.text.length <= this.maxLength) {
      this.currentText = this.text;
      this.isCollapsed = false;
      this.hideToggle = true;
      return;
    }
    this.hideToggle = false;
    if (this.isCollapsed === true) {
      this.currentText = this.text.substring(0, this.maxLength) + '...';
    } else if (this.isCollapsed === false) {
      this.currentText = this.text;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.text) {
      this.determineView();
    }
    if (changes.textTranslation) {
      this.translateService.get(this.textTranslation).subscribe((translated: string) => {
        this.text = translated;
        this.isCollapsed = true;
        this.determineView();
      });
    }
  }

  blur() {
    if (this.toggleButton) {
      this.toggleButton.nativeElement.blur();
    }
  }
}
