import { Pipe, PipeTransform } from '@angular/core';
import { TableCellType, TableHeaderCell } from '../../../spr/configuration/table/configuration-table.component';

@Pipe({
  name: 'sprTableTextInputTypePipe'
})
export class SprTableTextInputTypePipe implements PipeTransform {
  transform(headerCell: TableHeaderCell): boolean {
    return !!headerCell && headerCell.cellType === TableCellType.TEXT_INPUT;
  }
}
