import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NewsViewModel } from '../view-models/news-view-model';
import { NewsRestService } from '../rest-services/news-rest.service';
import { map } from 'rxjs/operators';

@Injectable()
export class NewsService {

  private static fixUrl(newsUrl: string, restUrl: string): string {
    if (newsUrl && newsUrl.charAt(0) === '/') {
      const domain = restUrl.substring(0, restUrl.indexOf('/', restUrl.indexOf('//') + 2));
      return domain + newsUrl;
    }
    return newsUrl;
  }

  constructor(private newsRestService: NewsRestService) {
  }

  public getNews(newsDataRestUrl: string): Observable<NewsViewModel[]> {
    return this.newsRestService.getNewsWrapper(newsDataRestUrl).pipe(map(response =>
      response.data.map(element => new NewsViewModel(
        NewsService.fixUrl(element.attributes.url, newsDataRestUrl),
        element.attributes.title,
        element.attributes.description,
        element.attributes.date
      ))));
  }
}
