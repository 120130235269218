import { DropdownOptions } from '../../../core/models/dropdown-options';
import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  IterableDiffers,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import * as _ from 'lodash';
import { LogService } from '../../../core/services/log/log.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectboxComponent),
  multi: true
};

@Component({
  selector: 'hl-multi-selectbox',
  templateUrl: './multi-selectbox.component.html',
  providers: [MULTISELECT_VALUE_ACCESSOR]
})
export class MultiSelectboxComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  private _options: DropdownOptions[]; // dropdown list options
  private _translateKeys?: boolean; // translate keys
  private _allChecked?: boolean; // should all checkbox checked or not

  differ: any;
  translatedLabel: string;
  isDropdownOpen = false;
  isDropUp = false;
  isAllChecked = false;
  _value: string[] = [];
  isValuesSettingsInProcess = false;

  dropdownMaxHeight = 200;

  @Input()
  disabled = false;
  @Input()
  isRequired = false;
  @Input()
  label: string; // translation key
  @Input()
  showIcon?: boolean; // For operational state icon to be shown
  @Input()
  showAllCheckItem = true;
  @Input()
  dataCy?: string;
  @Input()
  dynamicOptions = false;
  @Input()
  orderValues = false;
  @Output()
  valueChange = new EventEmitter(true);

  @ViewChild('selectbox', {static: false})
  selectboxEl: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkDropUp();
  }

  @Input()
  set value(val: string[]) {
    if (this.isValuesSettingsInProcess) {
      return;
    }
    this.isValuesSettingsInProcess = true;
    this._value = val;
    if (this._value === undefined || this._value === null) {
      this._value = [];
    }
    this.onModelChange(this.value);
    this.onModelTouched();
    this.checkAllSelected();
    this.isValuesSettingsInProcess = false;
  }

  get value() {
    return this._value;
  }

  @Input()
  set options(options: DropdownOptions[]) {
    this._options = options;
    this.updateTranslations();
    this.checkAllSelected();
  }

  get options(): DropdownOptions[] {
    return this._options;
  }

  @Input()
  set translateKeys(translateKeys: boolean) {
    this._translateKeys = translateKeys;
    this.updateTranslations();
  }

  @Input()
  set allChecked(allChecked: boolean) {
    this._allChecked = allChecked;
  }

  get allChecked(): boolean {
    return this._allChecked;
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (this.isDropdownOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  constructor(
    protected translate: TranslateService,
    private logService: LogService,
    public elementRef: ElementRef,
    differs: IterableDiffers
  ) {
    this.differ = differs.find([]).create(null);
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.options &&
      !changes.options.firstChange &&
      !_.isEqual(changes.options.previousValue, changes.options.currentValue) && // _.isEqual checks structural equality unlike ===
      this.allChecked
    ) {
      this.selectAll();
    }
    if (this._value.length > 0 && !this.dynamicOptions) {
      this.filterNonExistingValues();
    }
    this.isValuesSettingsInProcess = false;
  }

  getOption(value: string) {
    return this.options.find(item => item.value === value);
  }

  init() {
    this.isValuesSettingsInProcess = false;
    if (this.label) {
      this.translate.get(this.label).subscribe((res: string) => {
        this.translatedLabel = res;
      });
    } else {
      this.translatedLabel = '';
    }
    if (this.allChecked) {
      this.selectAll();
    }
    // this is needed in case some of the saved filters
    // with all values selected is applied
    this.checkAllSelected();
  }

  isSelected(option: DropdownOptions): boolean {
    return this.value && this.value.indexOf(option.value) > -1;
  }

  setSelected(event: Event, option: DropdownOptions) {
    event.stopPropagation();

    if (!this.value) {
      this.value = [];
    }
    const index = this.value.indexOf(option.value);
    if (index > -1) {
      this.value.splice(index, 1);
    } else if (!option.disabled) {
      this.value.push(option.value);
    }

    if (this.orderValues) {
      this.orderValueBasedOnOptions();
    }

    this.value = this.value.slice();

    this.checkAllSelected();
    this.valueChange.emit(this._value);
  }

  selectAllWithUserClick(event: Event) {
    event.stopPropagation();
    this.selectAll();
    this.value = [...this.value]; // this will trigger the change detector
    this.valueChange.emit(this._value);
  }

  private selectAll() {
    const optionLength = this._options.length;
    const currentValuesLength = this._value.filter(val => this._options.some(opt => opt.value === val)).length;

    if (currentValuesLength < optionLength) {
      _.forEach(this._options, option => {
        if (!_.includes(this.value, option.value)) {
          this.value.push(option.value);
        }
      });
    } else if (this._value.length > currentValuesLength && currentValuesLength === optionLength) {
      _.forEach(this._options, option => {
        const idx = this.value.findIndex(d => d === option.value);
        if (idx !== -1) {
          this.value.splice(idx, 1);
        }
      });
    } else {
      this.value.length = 0; // clear the array without reassigning
    }

    if (this.orderValues) {
      this.orderValueBasedOnOptions();
    }

    this.checkAllSelected();
  }

  private orderValueBasedOnOptions() {
    this.value.sort((a, b) => {
      const aIndex = this.options.findIndex(o => o.value === a);
      const bIndex = this.options.findIndex(o => o.value === b);
      if (aIndex < bIndex) {
        return -1;
      } else if (aIndex > bIndex) {
        return 1;
      }
      return 0;
    });
  }

  private checkAllSelected() {
    this.isAllChecked = this.options && this.value && this.options.every(opt => this.value.includes(opt.value));
  }

  private updateTranslations() {
    if (!_.isEmpty(this._options) && this._translateKeys) {
      const translationKeys = this._options.map(option => option.title);
      this.translate.get(translationKeys).subscribe(res => {
        for (const option of this._options) {
          option.title = res[option.title];
        }
      });
    }
  }

  toggleDropdown(event?) {
    if (event.target.matches('.multi-selectbox__button-wrapper')) {
      return;
    }
    this.isDropdownOpen = !this.isDropdownOpen;
    this.checkDropUp();
  }

  closeDropdown(event?) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = false;
  }

  checkDropUp() {
    const rect = this.selectboxEl.nativeElement.getBoundingClientRect();
    this.isDropUp =
      rect.top + rect.height + this.dropdownMaxHeight >= window.innerHeight;
  }

  onModelChange: Function = () => {
  };
  onModelTouched: Function = () => {
  };

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
    this.isValuesSettingsInProcess = false;
  }

  /**
   * Filter values which do not exist in options.
   * Can happen when there is only one item with the specific value
   * and this item's value is changed.
   */
  filterNonExistingValues() {
    const filteredValue = this._value.filter(item =>
      this.options.find(option => option.value === item)
    );
    if (this._value.length !== filteredValue.length) {
      this._value = filteredValue;
      this.valueChange.emit(this._value);
      this.onModelChange(this.value);
    }
  }
}
