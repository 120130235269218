import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hl-button-row',
  templateUrl: './button-row.component.html'
})
export class ButtonRowComponent {
  @Input()
  iconVisible = true;
  @Input()
  multiButtonLabelVisible = true;
  @Input()
  label: string;
  @Input()
  dataCy?: string;
  @Input()
  enableUnselect = true;
  @Output()
  valueChange = new EventEmitter<string>();

  constructor(private translate: TranslateService) {
  }

  private _options;

  get options(): DropdownOptions[] {
    return this._options;
  }

  @Input()
  set options(options: DropdownOptions[]) {
    this._options = options;
  }

  private _translateKeys?: boolean;

  @Input()
  set translateKeys(translateKeys: boolean) {
    this._translateKeys = translateKeys;
    this.updateTranslations();
  }

  private _value: string;

  get value() {
    return this._value;
  }

  @Input()
  set value(val: string) {
    this._value = val;
  }

  isSelected(option: string): boolean {
    return this._value === option;
  }

  setSelected(event: Event, option: DropdownOptions) {
    event.stopPropagation();

    if (this.value && this.value === option.value && this.enableUnselect) {
      this.value = '';
    } else if (this.value && this.value === option.value && !this.enableUnselect) {
      return;
    } else {
      this.value = option.value;
    }
    this.valueChange.emit(this._value);
  }

  private updateTranslations() {
    if (this._options && this._translateKeys) {
      const translationKeys = this._options.map(option => option.title);
      this.translate.get(translationKeys).subscribe(res => {
        for (const option of this._options) {
          option.title = res[option.title];
        }
      });
    }
  }
}
