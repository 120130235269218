import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TimelineMilestone } from '../../../core/models/timeline-milestone';

@Component({
  selector: 'hl-timeline',
  templateUrl: './timeline.component.html'
})
export class TimelineComponent implements OnInit {

  @ViewChild('timeline', { static: false })
  timeline: ElementRef;

  @Input()
  timelineMilestones: TimelineMilestone[];

  readonly MOBILE_WIDTH_SIZE = 768;

  isMobile = false;

  @HostListener('window:resize', ['$event'])
  getIsMobile() {
    const width = window.innerWidth;
    this.isMobile = width < this.MOBILE_WIDTH_SIZE;
  }

  ngOnInit() {
    this.getIsMobile();
  }

  isFirstInMilestones(i: number): boolean {
    if (this.isMilestonesOutOfIndex(i)) {
      return false;
    }
    return (i === 0);
  }

  isLastInMilestones(i: number): boolean {
    if (this.isMilestonesOutOfIndex(i)) {
      return false;
    }
    return (i === (this.timelineMilestones.length - 1));
  }

  hasMoreItemsThanOne(): boolean {
    return this.timelineMilestones && this.timelineMilestones.length > 1;
  }

  isFirstInMoreThanOneMilestones(i: number): boolean {
    if (this.isMilestonesOutOfIndex(i) || !this.hasMoreItemsThanOne()) {
      return false;
    }
    return this.isFirstInMilestones(i);
  }

  shouldShowBorder(i: number): boolean {
    if (this.isMilestonesOutOfIndex(i)) {
      return false;
    }
    return (!this.isFirstInMilestones(i) && !this.isLastInMilestones(i));
  }

  shouldShowLeftHalfBorder(i: number): boolean {
    if (this.isMilestonesOutOfIndex(i)) {
      return false;
    }
    return (this.hasMoreItemsThanOne() && this.isLastInMilestones(i));
  }

  shouldShowRightHalfBorder(i: number): boolean {
    if (this.isMilestonesOutOfIndex(i)) {
      return false;
    }
    return (this.hasMoreItemsThanOne() && this.isFirstInMilestones(i));
  }

  private isMilestonesOutOfIndex(i: number) {
    return !this.timelineMilestones || i < 0 || i >= this.timelineMilestones.length;
  }
}
