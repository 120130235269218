import { Router } from '@angular/router';
import { CheckPermissionOrRoleService } from '../check-permission-or-role.service';
import { GeneralRoleCanActivateTabsService } from '../general-role-can-activate-tabs.service';
import { ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SprAuthGuardService extends GeneralRoleCanActivateTabsService {

  constructor(router: Router, checkPermissionOrRoleService: CheckPermissionOrRoleService) {
    super(router, checkPermissionOrRoleService);
  }

  protected enableTab: ReplaySubject<boolean> = this.checkPermissionOrRoleService.sprComponentPermissionTab;

}
