import { ImpersonationCommunicationService } from '../../core/component-communication-services/impersonation/impersonation-communication.service';
import { ActivitiesUtilService } from '../../core/services/activities/activities-util.service';
import { UserUtilService } from '../../core/services/user/user-util.service';
import { plannedActivity, plannedActivityAndTraining, plannedTraining, roles } from '../../core/core-constants.service';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { ActivitiesViewModel } from '../../core/view-models/activities-view-model';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SortDirection } from '../../shared/sorting/sort-object';
import { FilterUtilService } from '../../core/utils/filter-util.service';
import { filter, map, mapTo, switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';
import { filter as _filter, isEqual, parseInt } from 'lodash';

@Component({
  selector: 'hl-activities-widget',
  templateUrl: './activities-widget.component.html'
})
export class ActivitiesWidgetComponent implements OnInit, OnDestroy {

  @Input() type: string = plannedActivityAndTraining;
  @Input() headerLabel: string;
  @Input() noItemFoundLabel: string;

  viewModelList: ActivitiesViewModel[];

  datePattern = '';
  dateTimePattern = '';

  // my equipment profile variables
  myEquipmentProfileList = [];
  myEquipmentChecked = false;
  isLoaded: boolean;
  paginationItems = 3;

  toggleCustomerName = false;
  customerNameLength = undefined;

  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(private router: Router,
    private configService: CountryConfigRestService,
    private userUtilService: UserUtilService,
    private activitiesUtilService: ActivitiesUtilService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private filterUtilService: FilterUtilService,
    private myFiltersService: MyFiltersAdapterService) {
  }

  ngOnInit() {
    this.init();
    this.registerEventListeners();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.initProperties();
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).pipe(
      tap(config => this.setConfigProperties(config)),
      switchMap(config => this.checkUserRoles().pipe(
        filter(showActivities => showActivities && !!this.type),
        mapTo(config))
      ),
      switchMap(config => this.loadViewModelList(config)),
      takeUntil(this.unsubscribe$)
    ).subscribe(([activitiesResponse, equipmentKeys]) => {
      const result = this.filterUtilService.getListAfterApplyingFilterPipes(activitiesResponse, {
        orderBy: {
          sortBy: 'plannedStart',
          sortDir: SortDirection.ASC
        },
        limitTo: this.paginationItems
      });

      this.viewModelList = _filter(result, a => equipmentKeys.indexOf(a.equipmentKey) !== -1);
    });
  }

  registerEventListeners() {
    this.impersonationCommunicationService.onCountryLanguageChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.init());
  }

  initProperties() {
    this.datePattern = 'ddmmyyyy';
    this.dateTimePattern = 'ddmmyyyy hh:mm';

    this.isLoaded = false;
    this.viewModelList = [];
  }

  setConfigProperties(config) {
    this.datePattern = config.GENERIC_DATE_PATTERN;
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;

    // Note:- only type 'activity', 'training' or 'all' is set at a time
    if (isEqual(this.type, plannedActivity)) {
      this.paginationItems = parseInt(config.NR_PLANNED_ACTIVITIES, 10);
    } else if (isEqual(this.type, plannedTraining)) {
      this.paginationItems = parseInt(config.NR_TRAINING_ACTIVITIES, 10);
    } else if (isEqual(this.type, plannedActivityAndTraining)) {
      this.paginationItems = parseInt(config.NR_PLANNED_ACTIVITIES, 10);
    }

    this.toggleCustomerName = isEqual(config.TOGGLE_CUSTOMER_NAME_ACTIVITY_LIST, 'true');
    this.customerNameLength = config.LENGTH_CUSTOMER_NAME_DISPLAY > 0 ? config.LENGTH_CUSTOMER_NAME_DISPLAY : undefined;
  }

  checkUserRoles() {
    const rolesToCheck = {
      checkViewPlannedActvRole: roles.viewPlannedActivityRole,
      checkViewPlannedTrngRole: roles.viewPlannedTrainingRole,
      checkViewEquipmentRole: roles.viewEquipmentRole
    };

    return this.userUtilService.checkUserRoles(rolesToCheck)
      .pipe(map(checkRolesResponse => {
        const hasRoleAndAdequateType =
          (isEqual(this.type, plannedActivity) && checkRolesResponse.checkViewPlannedActvRole) ||
          (isEqual(this.type, plannedTraining) && checkRolesResponse.checkViewPlannedTrngRole);

        let showActivity = false;
        if (hasRoleAndAdequateType) {
          showActivity = true;
        }

        if (isEqual(this.type, plannedActivityAndTraining)) {
          if (checkRolesResponse.checkViewPlannedActvRole && checkRolesResponse.checkViewPlannedTrngRole) {
            showActivity = true;
          } else if (checkRolesResponse.checkViewPlannedActvRole) {
            this.type = plannedActivity;
            showActivity = true;
          } else if (checkRolesResponse.checkViewPlannedTrngRole) {
            this.type = plannedTraining;
            showActivity = true;
          }
        }

        return showActivity;
      }));
  }

  loadViewModelList(config) {
    const activities$ = this.activitiesUtilService.getDashboardPlannedActv(this.type, config);
    return combineLatest([activities$, this.myFiltersService.filterEquipmentKeys$]).pipe(
      // tslint:disable-next-line: rxjs-no-unsafe-scope
      tap(() => this.isLoaded = true)
    );
  }

  goToActivities(activity: ActivitiesViewModel) {
    this.router.navigate(['/activities', activity.ticketKey, 'overview'], {
      queryParams: {
        activityType: activity.type,
        ticketIdentifier: activity.ticketNumber
      }
    });
  }
}
