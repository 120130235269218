import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SortSkeleton } from '../../../sorting/sort-skeleton';
import { LifeNetUtilService } from '../../../../core/utils/life-net-util.service';
import { EquipmentViewModel } from '../../../../core/view-models/equipment-view-model';
import { EquipmentListViewModel } from '../../../../core/view-models/equipment-list-view-model';
import { EquipmentUtilService } from '../../../../core/services/equipment/equipment-util.service';
import { green, red, yellow } from '../../../../core/core-constants.service';
import {
  CENTRICARE_EQUIPMENT_STATUS_CRITICAL,
  EquipmentConstantsService
} from '../../../../core/services/equipment/equipment-constants.service';
import { CountryConfigRestService } from '../../../../core/rest-services/country-config-rest.service';
import { BaseListView } from '../../../../core/base-class/base-list-view';
import { EquipmentInformation } from '../../../../core/models/equipment/equipment-information';
import { EquipmentRestService } from '../../../../core/rest-services/equipment-rest.service';
import { Router } from '@angular/router';
import { UserUtilService } from '../../../../core/services/user/user-util.service';
import { StateService } from '../../../../core/services/state.service';
import { LogService } from '../../../../core/services/log/log.service';
import { BrowserStateService } from '../../../../core/services/browser-state.service';
import { FilterUtilService } from '../../../../core/utils/filter-util.service';
import { Observable } from 'rxjs';
import { without } from 'lodash';

@Component({
  selector: 'hl-equipment-selection-for-new-system-update',
  templateUrl: './equipment-selection-for-new-system-update.component.html'
})
export class EquipmentSelectionForNewSystemUpdateComponent extends BaseListView<EquipmentListViewModel | EquipmentInformation>
  implements OnInit, OnDestroy {
  @Output()
  equipmentAddedToTheUpdate = new EventEmitter<EquipmentListViewModel | EquipmentInformation>();
  searchInput: string;
  sortSkeleton: SortSkeleton;
  isLoaded: boolean;
  filteredLengthWithoutPagination = 0;
  statusColorMap: any;
  toggleCustomerName: boolean;
  customerNameLength: any;

  constructor(
    public lifeNetUtilService: LifeNetUtilService,
    equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService,
    router: Router,
    userUtilService: UserUtilService,
    stateService: StateService,
    logService: LogService,
    browserStateService: BrowserStateService,
    filterUtilService: FilterUtilService,
    private equipmentRestService: EquipmentRestService,
    private equipmentConstantsService: EquipmentConstantsService) {
    super(
      configService,
      equipmentUtilService,
      userUtilService,
      stateService,
      logService,
      router,
      browserStateService,
      filterUtilService
    );
  }

  ngOnInit() {
    super.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  trackByFn(index, item: EquipmentViewModel) {
    return item['key'];
  }

  getFilterObject(): any {
    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: [
          'key',
          'customerNumber',
          'productName',
          'myEquipmentName',
          'siemensId',
          'department',
          'street',
          'city',
          'serialNumber',
          'customerName',
          'customerDescription'
        ]
      },
      orderBy: this.sortSkeleton.sortObject
    };
  }

  afterConfigProperties(config: any): void {
    this.statusColorMap[config.EQUIPMENT_STATUS_GREEN] = green;
    this.statusColorMap[config.EQUIPMENT_STATUS_RED] = red;
    this.statusColorMap[config.EQUIPMENT_STATUS_YELLOW] = yellow;
    this.statusColorMap[CENTRICARE_EQUIPMENT_STATUS_CRITICAL] = red;
  }

  afterInitProperties(): void {
    this.statusColorMap = {};
    this.sortSkeleton = this.equipmentConstantsService.getSortSkeletonForNewSystemUpdateEquipments();
  }

  afterInitViewModelList(): void {
    this.onAdvancedFilterChange();
  }

  getEmptyListUrl(): string {
    return '';
  }

  initAdvancedFilterDropDownList(): void {
  }

  loadViewModelList(): Observable<(EquipmentListViewModel | EquipmentInformation)[]> {
    return this.equipmentUtilService.getEquipmentViewModelList();
  }

  navigate(): void {
  }

  setDerivedBoundPropertiesFromFavorite(favorite: any): void {
  }

  setPropertiesFromQueryParams(config: any) {
  }

  public addEquipmentToList(equipment: EquipmentListViewModel | EquipmentInformation) {
    this.rawList.push(equipment);
    this.onAdvancedFilterChange();
  }

  removeEquipmentFromList(equipment: EquipmentListViewModel | EquipmentInformation) {
    this.equipmentAddedToTheUpdate.emit(equipment);
    this.rawList = without(this.rawList, equipment);
    this.viewModelList = without(this.viewModelList, equipment);
    this.listWithoutPagination = without(this.listWithoutPagination, equipment);
    if (this.viewModelList.length < this.numberPagination / 2) {
      this.loadMore(this.numberPagination);
    }
    this.onAdvancedFilterChange();
  }

  setEquipmentSelection(equipmentList: (EquipmentListViewModel | EquipmentInformation)[]) {
    this.rawList = equipmentList;
    this.onAdvancedFilterChange();
  }

  resetForm() {
    this.searchInput = '';
    this.initLoadViewModelList();
  }
}
