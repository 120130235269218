import { HttpCacheService } from '../services/cache/http-cache.service';
import { Favorite } from '../models/favorite';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { restEndPoint } from '../core-constants.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class FavoriteRestService {
    favoritesRestName = restEndPoint + 'favorites';
    favoriteDeleteRestName = restEndPoint + 'favorites/:id';

  constructor(private http: CacheableHttpClient,
    private cacheService: HttpCacheService) {
  }

  getFavorites(): Observable<Favorite[]> {
      return this.http.get<Favorite[]>(this.favoritesRestName);
  }

  postFavorite(body: any): Observable<any> {
      this.clearCache(this.favoritesRestName);
      return this.http.post(this.favoritesRestName, body);
  }

  deleteFavorite(id: number) {
      this.clearCache(this.favoritesRestName);
      const url = this.favoriteDeleteRestName.replace(/:id/g, id.toString());
    return this.http.delete(url);
  }

  clearCache(restName: string) {
    if (!environment.newCacheableHttpClient) {
      this.cacheService.clearIndividualRequest(restName);
    }
    this.http.clearCache(restName);
  }

}
