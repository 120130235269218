import { IcCode } from '../../../core/models/ic-code';
import { Component, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, flatMap, map, takeUntil } from 'rxjs/operators';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { DangerForPatients } from '../../../core/core-constants.service';
import { Router } from '@angular/router';
import { AsuFeedbackResult } from '../../../core/models/systemUpdates/asu-feedback-result';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { IcCodeRestService } from '../../../core/rest-services/ic-code-rest.service';
import { SystemUpdatesRestService } from '../../../core/rest-services/system-updates-rest.service';
import { TicketsCacheService } from '../../../core/services/cache/tickets-cache.service';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { LogService } from '../../../core/services/log/log.service';
import { ConfigLoaderService } from '../../../config-loader.service';

@Component({
  selector: 'hl-ic-code-modal',
  templateUrl: './ic-code-modal.component.html'
})
export class IcCodeModalComponent extends BaseModalPopup {

  icCode: IcCode;
  equipmentInfo: string;
  showAsuError = false;
  asuErrorLabel: string;

  constructor(renderer: Renderer2,
    private router: Router,
    private ticketsUtilService: TicketsUtilService,
    private translateService: TranslateService,
    private configService: CountryConfigRestService,
    private configLoaderService: ConfigLoaderService,
    private ticketCacheService: TicketsCacheService,
    private systemUpdateRestService: SystemUpdatesRestService,
    private icCodeRestService: IcCodeRestService,
    private logService: LogService) {
    super(renderer);
  }

  ok() {
  }

  public show() {
    if (this.icCode.locale) {
      this.translateService.use(this.configLoaderService.isConfigServerAvailable ?
        this.icCode.locale : 'locale-' + this.icCode.locale).subscribe(() => this.showModal());
    } else {
      this.showModal();
    }
  }

  private showModal() {
    if (this.icCode.successful) {
      const equipmentName = this.icCode.productName + ' ' + this.icCode.siemensId;

      this.translateService.get('ICCODE_SUCCESS_EQUIPMENT_INFO').subscribe(
        equipmentInfoRaw => this.equipmentInfo = equipmentInfoRaw.replace(/#EQUIPMENT#/, equipmentName)
      );
    }
    this.updateAsuStatus();
    super.show();
  }

  public shouldShow(fragment: string) {
    if (!fragment) {
      return false;
    }

    const match = fragment.match(/iccode=([^&]+)/);

    if (!match || match[1].length === 0) {
      return false;
    }
    let icCodeParsed: IcCode;
    try {
      const icCodeObject = JSON.parse(decodeURIComponent(match[1]));
      icCodeParsed = Object.assign(new IcCode(), icCodeObject) as IcCode;
    } catch (SyntaxError) {
      this.logService.error('The IC code value is wrong');
      return false;
    }

    if (!icCodeParsed.isValid()) {
      this.logService.error('The IC code value is wrong');
      return false;
    }
    this.icCode = icCodeParsed;
    return true;
  }

  transitToCreateTicket(event: MouseEvent) {
    event.preventDefault();
    const target = event.target as HTMLElement;
    if (!target || target.tagName !== 'A') {
      return;
    }

    this.showSpinner = true;
    this.setCreateTicketValues().subscribe(equipmentKey => {
      this.hide();
      this.ticketsUtilService.emitShowCreateTicketDialog(equipmentKey);
    });
  }

  setCreateTicketValues(): Observable<any> {
    const getCategoryKey = cat => `LABEL_EVENT_CATEGORY_${cat}`;
    const longTextKey = 'ICCODE_CREATE_TICKET_LONG_TEXT';
    const categoryKeys = ['P', 'S', 'R', 'E'].map(getCategoryKey);
    const errorMessage = this.icCode.getErrorMessageKey();
    const {updateNumber = ''} = this.icCode;

    const translate$ = this.translateService.get([errorMessage, longTextKey, ...categoryKeys]);
    const config$ = this.configService.getConfig();
    const systemUpdate$ = updateNumber
      ? this.systemUpdateRestService.getSystemUpdateDetail(updateNumber).pipe(
        catchError(() => of(null)))
      : of(null);

    return combineLatest([translate$, config$, systemUpdate$])
      .pipe(
        flatMap(([translations, config, update]) => {
          const longText = translations[longTextKey]
            .replace(/#UPDATE_NUMBER#/, updateNumber)
            .replace(/#CATEGORY#/, update && translations[getCategoryKey(update.category)] || '')
            .replace(/#PACKAGE_NAME#/, update && update.packageInfo ? update.packageInfo.packageName : '')
            .replace(/#CONTENT#/, update && update.packageInfo ? update.packageInfo.content : '');

          this.ticketCacheService.setPrefilledFields({
            details: {
              problemSeverityID: config.ICCODE_CREATE_TICKET_PROBLEM_SEVERITY_ID,
              dangerForPatients: DangerForPatients.FALSE,
              description: translations[errorMessage],
              longText
            }
          });
          return of(this.icCode.equipmentKey);
        }),
        takeUntil(this.unsubscribe$)
      );
  }

  hide() {
    super.hide();
    this.router.navigateByUrl(this.router.url.replace(/\#.+$/, ''));
  }

  public shouldShowIcCode() {
    return this.icCode && this.icCode.containsValidValue();
  }

  getContactText(): Observable<string> {
    const phoneNumberKey = this.icCode.getPhoneNumberKey();
    const phoneNumberDefaultKey = IcCode.getDefaultPhoneNumberKey();
    return combineLatest([
      this.configService.getConfig(),
      this.translateService.get('ICCODE_ERROR_CONTACT')
    ]).pipe(
      map(([config, contact]) => {
        const phoneNumber = config[phoneNumberKey] ? config[phoneNumberKey] : config[phoneNumberDefaultKey];
        return contact.replace(/#PHONE_NUMBER#/,
          `<b><a href="tel:${phoneNumber}">${phoneNumber}</a></b>`);
      })
    );
  }

  getErrorTicketText() {
    return this.translateService.get('ICCODE_ERROR_TICKET').pipe(map(text => {
      const reg = /<b>(.*)<\/b>/.exec(text);

      if (reg) {
        return text.replace(reg[1], '<a href="#">' + reg[1] + '</a>');
      }
    }));
  }

  updateAsuStatus() {
    if (!_.isEmpty(this.icCode.equipmentKey) && !_.isEmpty(this.icCode.updateNumber) &&
      (this.icCode.status !== IcCode.getIcCodeErrorTypeEqNotAssigned())) {
      this.icCodeRestService.getAsuFeedback(this.icCode).subscribe((result: AsuFeedbackResult) => {
        this.displayAsuStatus(result);
      });
    }
  }

  displayAsuStatus(result: AsuFeedbackResult) {
    switch (result.statusCode) {
      case '01':
        this.setAsuErrorLabel('ASU_FEEDBACK_ERROR_TYPE_UP_OR_CI_NUMBER_NOT_FOUND');
        break;
      case '02':
        this.setAsuErrorLabel('ASU_FEEDBACK_ERROR_TYPE_STATUS_NOT_EXIST_IN_SAP');
        break;
      case '03':
        this.setAsuErrorLabel('ASU_FEEDBACK_ERROR_TYPE_STATUS_NOT_FOUND_IN_SAP');
        break;
      case '04':
        this.setAsuErrorLabel('ASU_FEEDBACK_ERROR_TYPE_STATUS_NOT_SET_DUE_DO_QUALITY_RANKING');
        break;
    }
  }

  setAsuErrorLabel(errorLabel: string) {
    this.asuErrorLabel = errorLabel;
    this.showAsuError = true;
  }
}
