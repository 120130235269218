import {
  Component,
  Input,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';

export interface LinkItem {
  text: string,
  link: string
}

@Component({
  selector: 'hl-read-more-arrays',
  templateUrl: './read-more-arrays.component.html'
})
export class ReadMoreArraysComponent implements OnInit {

  @Input()
  array: LinkItem[];
  @Input()
  maxItems = 3;
  currentArray = [];
  hideToggle = true;
  isCollapsed = true;
  @ViewChild('toggle', { static: false })
  toggleButton: ElementRef;

  ngOnInit(): void {
    if (this.array) {
      this.determineView();
    }
  }

  toggleView(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.blur();
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }

  determineView() {
    if (this.array.length <= this.maxItems) {
      this.currentArray = this.array;
      this.isCollapsed = false;
      this.hideToggle = true;
      return;
    }
    this.hideToggle = false;
    if (this.isCollapsed === true) {
      this.currentArray = this.array.slice(0, this.maxItems);
    } else {
      this.currentArray = this.array;
    }
  }

  blur() {
    if (this.toggleButton) {
      this.toggleButton.nativeElement.blur();
    }
  }

}
