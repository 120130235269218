import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ControlContainer, FormGroup, NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'hl-textarea',
  templateUrl: './textarea.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TextareaComponent {
  @Input()
  parentForm: FormGroup;
  @Input()
  name: string;
  @Input()
  placeholderText = '';
  @Input()
  labelText: string;
  @Input()
  maxlength: number;
  @Input()
  isActive = false;
  @Input()
  isFocused = false;
  @Input()
  isLarge = false;
  @Input()
  isRequired = false;
  @Input()
  isValid = false;
  @Input()
  isInvalid = false;
  @Input()
  isDisabled = false;
  @Input()
  isReadonly = false;
  @Input()
  rows = 3;
  @Input()
  textareaContent = '';
  @Input()
  noWhiteSpace = false;
  @Output()
  textareaContentChange: EventEmitter<String> = new EventEmitter<String>();

  @ViewChild('textarea', { static: false })
  model: NgModel;

  textareaId =
    'textarea_' +
    Math.random()
      .toString(36)
      .substring(2);

  constructor() {}

  onFocus() {
    this.isFocused = true;
    this.isActive = true;
  }

  onBlur() {
    this.isFocused = false;
    this.isActive = false;
  }

  onChange(newValue: any) {
    this.textareaContent = newValue;
    this.textareaContentChange.emit(this.textareaContent);
  }

  onAddText(newValue: string) {
    this.textareaContent = this.textareaContent + newValue;
  }

  onDeleteText() {
    this.textareaContent = '';
  }
}
