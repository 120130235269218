import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSizeFormat'
})
export class FileSizeFormatPipe implements PipeTransform {
  // returns only one decimal value (rounded up)
  private static getFileSizeLabelInUnit(value: any, relativeTokB: number, unit: string) {
    const parseItem = (Math.ceil(value / (100 * relativeTokB)) / 10).toFixed(1);
    return '[' + parseItem.replace('.', ',') + ` ${unit}]`;
  }

  transform(value: any, args?: any): any {
    if (value % 10000 < value) {
      return FileSizeFormatPipe.getFileSizeLabelInUnit(value, 1000, 'MB');
    }
    return FileSizeFormatPipe.getFileSizeLabelInUnit(value, 1, 'kB');
  }
}
