import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { WindowService } from '../../../core/window.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'hl-locked-upload-update-modal',
  templateUrl: './locked-upload-update-modal.component.html'
})
export class LockedUploadUpdateModalComponent extends BaseModalPopup implements OnInit {

  updateLockMessage: Observable<string>;
  @Input()
  lockedUserGID: string;
  @Input()
  updateNumber: string;
  @Output()
  rollbackAndClean: EventEmitter<string> = new EventEmitter();
  @Output()
  rollbackAndUpload: EventEmitter<string> = new EventEmitter();
  @Output()
  uploadSuccessSpinner: EventEmitter<boolean> = new EventEmitter();
  @Output()
  disableFileSelection: EventEmitter<boolean> = new EventEmitter();
  @Output()
  isUpdateLockedByOtherUser: EventEmitter<boolean> = new EventEmitter();

  window = this.windowService.nativeWindow;
  userGID: string;
  unlockEnabled = false;
  emitSuccessSpinner = false;

  constructor(private userRestService: UserRestService,
    private translate: TranslateService,
    private windowService: WindowService,
    renderer: Renderer2) {
    super(renderer);
  }

  rollbackClean() {
    this.rollbackAndClean.emit(this.updateNumber);
    this.hide();
  }

  rollbackUpload() {
    this.rollbackAndUpload.emit(this.updateNumber);
    this.hide();
  }

  ok() {
  }

  hidePanel() {
    this.emitSuccessSpinner = true;
    this.hide();
  }

  hide() {
    super.hide();
    this.disableFileSelection.emit(false);
    if (this.emitSuccessSpinner) {
      this.uploadSuccessSpinner.emit(false);
    }
  }

  show() {
    this.unlockEnabled = false;
    this.userRestService.getUser().subscribe((userResponse) => {
      this.userGID = userResponse.gid;
    });

    (this.lockedUserGID === this.userGID) ?
      (this.updateLockMessage = this.translate.get('SYSTEM_UPDATES_UPLOAD_LOCKED_BY_ME').pipe(
        tap(() => this.unlockEnabled = true)
      ))
      :
      this.updateLockMessage = this.translate.get('SYSTEM_UPDATES_UPLOAD_LOCKED_BY_USER').pipe(
        map(value => value.replace(/#USER_GID#/g, this.lockedUserGID)),
        tap(() => this.isUpdateLockedByOtherUser.emit(true))
      );

    super.show();
  }

  ngOnInit(): void {
  }
}
