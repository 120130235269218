import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard.component';
import { NewsWidgetComponent } from './news-widget/news-widget.component';
import { LastViewedWidgetComponent } from './last-viewed-widget/last-viewed-widget.component';
import { EquipmentStatusWidgetComponent } from './equipment-status-widget/equipment-status-widget.component';
import { EquipmentStatusMapComponent } from './equipment-status-map/equipment-status-map.component';
import { TicketsWidgetComponent } from './tickets-widget/tickets-widget.component';
import { ActivitiesWidgetComponent } from './activities-widget/activities-widget.component';
import { ActivityStatusWidgetComponent } from './activity-status-widget/activity-status-widget.component';
import { AnnouncementWidgetComponent } from './announcement-widget/announcement-widget.component';
import { FooterComponent } from './footer/footer.component';
import { SecurityAdvisoriesWidgetComponent } from './security-advisories-widget/security-advisories-widget.component';
import { TeamplayWidgetComponent } from './teamplay-widget/teamplay-widget.component';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    EquipmentStatusWidgetComponent
  ],
  declarations: [
    DashboardComponent,
    NewsWidgetComponent,
    TeamplayWidgetComponent,
    LastViewedWidgetComponent,
    EquipmentStatusWidgetComponent,
    EquipmentStatusMapComponent,
    TicketsWidgetComponent,
    ActivitiesWidgetComponent,
    ActivityStatusWidgetComponent,
    AnnouncementWidgetComponent,
    FooterComponent,
    SecurityAdvisoriesWidgetComponent
  ]
})
export class DashboardModule { }
