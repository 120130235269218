import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { restEndPoint } from '../core-constants.service';
import { Observable } from 'rxjs';

@Injectable()
export class BlacklistRestService {
  blacklistRestName = restEndPoint + 'blacklist';

  constructor(private http: CacheableHttpClient) {
  }

  getWebshopCatalogBlacklist(): Observable<string[]> {
    return this.http.get<string[]>(this.blacklistRestName);
  }
}
