import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PopoverDirective } from 'ngx-bootstrap';
import * as _ from 'lodash';
import { some } from 'lodash';
import { merge, Observable, Subject, Subscription, timer } from 'rxjs';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { BrowserStateService } from '../core/services/browser-state.service';
import { MessagesRestService } from '../core/rest-services/messages-rest.service';
import {
  AdvisoryCybersecurityNotificationMessageType,
  SecurityAdvisoryNotificationMessageType,
  SubprocessorNotificationMessageType,
  SystemUpdateMessageType
} from '../core/models/message';
import { UserUtilService } from '../core/services/user/user-util.service';
import { roles } from '../core/core-constants.service';
import { MessageViewModel } from '../core/view-models/message-view-model';
import { ImpersonationCommunicationService } from '../core/component-communication-services/impersonation/impersonation-communication.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { User } from '../core/models/user';
import { MessagingCenterService } from '../core/services/messaging-center/messaging-center.service';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';
import { SecurityAdvisoriesService } from 'app/core/services/security-advisories/security-advisories-service';
import { OverlayComponent } from 'app/shared/components/overlay/overlay.component';

const {itAdminRole, userRole, securityAdvisoryViewRole, securityAdvisoryAuthorRole, securityAdvisoryViewXiaRole} = roles;
const rolesToCheck = {
  itAdminRole,
  userRole,
  securityAdvisoryViewRole,
  securityAdvisoryAuthorRole,
  securityAdvisoryViewXiaRole
};

@Component({
  selector: 'hl-messaging-center',
  templateUrl: './messaging-center.component.html'
})
export class MessagingCenterComponent implements OnInit, OnDestroy {

  @ViewChild(PopoverDirective, {static: false})
  @ViewChild('dropDownToggle', {static: false}) dropDownToggleEl: ElementRef;
  @ViewChild('advisoryNotificationOverlay', {static: false})
  advisoryNotificationOverlay: OverlayComponent;
  private _seenMessages: MessageViewModel[] = [];
  private _unseenMessages: MessageViewModel[] = [];

  private _timer$: Subscription;

  private _isLoaded = false;
  private _isAuthorized = false;
  private _startTime: number;
  private _timeElapsed: number;
  private hasViewSecurityAdvisoriesRole = false;
  private hasAdvisoryAuthorRole = false;
  selectedMessage: MessageViewModel;

  private _labels = {};
  private _config = {};

  isDropdownOpen = false;

  user: User = null;

  private readonly unsubscribe$: Subject<void> = new Subject();
  private readonly unsubscribeMerge$: Subject<void> = new Subject();

  get seenMessages(): MessageViewModel[] {
    this._seenMessages = this.toggleSecurityAdvisories(this._seenMessages);
    return this._seenMessages;
  }

  get unseenMessages(): MessageViewModel[] {
    this._unseenMessages = this.toggleSecurityAdvisories(this._unseenMessages);
    return this._unseenMessages;
  }

  get isLoaded(): boolean {
    return this._isLoaded;
  }

  get isAuthorized(): boolean {
    return this._isAuthorized;
  }

  get config(): any {
    return this._config;
  }

  get labels(): any {
    return this._labels;
  }

  get labelNumberOfNewEventsDetailed() {
    return this.translateService.get('LABEL_NUMBER_OF_NEW_EVENTS_DETAILED').pipe(map(label => {
      return label.replace('#COUNT#', this.countOfUnreadMessages());
    }));
  }

  get labelSubprocessorListUpdated() {
    return this.translateService.get('LABEL_MY_NOTIFICATION_SUBPROCESSOR_LIST_UPDATED').pipe(map(label => {
      const country = this.user.country;
      return label.replace('#COUNTRY#', country);
    }));
  }

  get timeElapsed(): number {
    return this._timeElapsed;
  }

  set timeElapsed(timeElapsed: number) {
    this._timeElapsed = timeElapsed;
  }

  constructor(private router: Router,
    private configService: CountryConfigRestService,
    private browserStateService: BrowserStateService,
    private messagingCenterService: MessagingCenterService,
    private messagesRestService: MessagesRestService,
    private translateService: TranslateService,
    private userUtilService: UserUtilService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private myFiltersService: MyFiltersAdapterService,
    private securityAdvisoriesService: SecurityAdvisoriesService) {
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (!this.dropDownToggleEl ||
      !this.isDropdownOpen ||
      event.target.className === 'icon-reload' ||
      event.target.id && event.target.id === 'numberOfNewEventsDetailed' ||
      // the target can be e.g. SVGPathElement, which doesn't contain 'includes' function
      event.target.className && event.target.className['includes'] && event.target.className.includes('message-head-padding') ||
      this.dropDownToggleEl.nativeElement.contains(event.target)) {
      return;
    }
    this.isDropdownOpen = false;
    this.resumePolling();
  }

  ngOnInit() {
    this.authorize().pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
        this._config = {
          datePattern: config.GENERIC_DATE_PATTERN,
          messagesLimit: parseInt(config.MESSAGING_CENTER_PAGE_SIZE, 10),
          intervalSec: parseInt(config.MESSAGING_CENTER_POLL_INTERVAL_SEC, 10),
          toggleSecurityAdvisories: _.isEqual(config.TOGGLE_SECURITY_ADVISORIES_MESSAGING_CENTER, 'true'),
          toggleAdvisorySubscription: _.isEqual(config.FEATURE_TOGGLE_ADVISORY_SUBSCRIPTION, 'true'),
          toggleXiaViewAdvisories: _.isEqual(config.FEATURE_TOGGLE_XIA_VIEW_ADVISORIES, 'true')
        };

        if (this.config.messagesLimit !== 0) {
          this.startTimer(0);
        }

        this.emitUnsubscribeMerge();
        merge(this.impersonationCommunicationService.onImpersonationChange$,
          this.impersonationCommunicationService.onCountryLanguageChange$)
          .pipe(takeUntil(this.unsubscribeMerge$))
          .subscribe(() => this.startTimer(0));
      });
    });

    this.myFiltersService.filterEquipmentKeys$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.refreshMessagesClick();
      }
    );

    this.messagingCenterService.refreshMessagingCenter$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.refreshMessagesClick();
      });

    this.userUtilService.getUser().subscribe(userResponse => {
      this.user = userResponse;
    });
  }

  ngOnDestroy() {
    if (this._timer$) {
      this._timer$.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribeMerge$.next();
    this.unsubscribeMerge$.complete();
  }

  refreshMessages(): void {
    this._isLoaded = false;
    this.messagesRestService.getMessages()
      .subscribe(messages => {
        this._seenMessages = messages.seenMessages;
        this._unseenMessages = messages.unseenMessages;
        this._isLoaded = true;
      });
  }

  refreshMessagesClick(): void {
    this.refreshMessages();
    this._startTime = new Date().getTime();
    this._timeElapsed = 0;
  }

  startTimer(initialDelay: number) {
    this._timer$ = timer(initialDelay, this.config.intervalSec * 1000)
      .subscribe(() => {
        this.refreshMessages();
      });
    this._startTime = new Date().getTime();
  }

  pausePolling(): void {
    // save elapsed time for later polling resume and unsubscribe timer
    this._timeElapsed = new Date().getTime() - this._startTime;
    this._timer$.unsubscribe();
  }

  resumePolling(): void {
    // compute initial delay for polling resume
    if (this._timeElapsed === 0) {
      this._timeElapsed = new Date().getTime() - this._startTime;
    }
    let initialDelay = this.config.intervalSec * 1000 - this._timeElapsed;
    if (initialDelay < 0) {
      initialDelay = 0;
    }
    this.startTimer(initialDelay);
  }

  handleNavigation($event, message: MessageViewModel) {
    $event.preventDefault();
    this.markEventMessageAsSeen(message);
    this.isDropdownOpen = false;

    if (this.isSystemUpdate(message)) {
      this.navigateToSystemUpdates(message);
    } else if (this.isSecurityAdvisoryNotification(message)) {
      this.navigateToSecurityAdvisory(message, '/advisories/');
    } else if (this.isAdvisoryCybersecurityNotification(message)) {
      if (this.hasAdvisoryAuthorRole && this.securityAdvisoriesService.isAdminView()) {
        this.navigateToSecurityAdvisory(message, '/advisories/notifications/');
      } else {
        this.openAdvisoryNotificationOverlay(message);
      }
    }
  }

  markEventMessageAsSeen(message: MessageViewModel) {
    this.messagesRestService.postViewedEventMessage(message)
      .subscribe(() => this.refreshMessagesClick());
  }

  countOfUnreadMessages(): number {
    return this.unseenMessages.length;
  }

  private authorize(): Observable<boolean> {
    return this.userUtilService
      .checkUserRoles(rolesToCheck)
      .pipe(
        filter(checkedRoles => {
          this._isAuthorized = some([checkedRoles.itAdminRole, checkedRoles.userRole]);
          this.hasAdvisoryAuthorRole = checkedRoles.securityAdvisoryAuthorRole;
          this.hasViewSecurityAdvisoriesRole = checkedRoles.securityAdvisoryViewRole || checkedRoles.securityAdvisoryViewXiaRole;
          return this._isAuthorized;
        })
      );
  }

  private navigateToSystemUpdates(message: MessageViewModel) {
    if (message.messageType === SystemUpdateMessageType) {
      this.browserStateService.setUserNavigation();
      this.router.navigate(
        ['/updates'],
        {queryParams: {'searchTerm': message.identifier}}).then(() => {
        this.browserStateService.resetUserNavigation();
      });
    }
  }

  private navigateToSecurityAdvisory(message: MessageViewModel, baseUrl: string) {
    this.browserStateService.setUserNavigation();
    this.router.navigate(
      [baseUrl + message.identifier + '/overview']).then(() => {
      this.browserStateService.resetUserNavigation();
    });
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.pausePolling();
    } else {
      this.resumePolling();
    }
  }

  isSystemUpdate(message: MessageViewModel): boolean {
    return message.messageType === SystemUpdateMessageType;
  }

  isSubprocessorNotification(message: MessageViewModel): boolean {
    return message.messageType === SubprocessorNotificationMessageType;
  }

  isSecurityAdvisoryNotification(message: MessageViewModel): boolean {
    return message.messageType === SecurityAdvisoryNotificationMessageType;
  }

  isAdvisoryCybersecurityNotification(message: MessageViewModel): boolean {
    return message.messageType === AdvisoryCybersecurityNotificationMessageType;
  }

  private openAdvisoryNotificationOverlay(message: MessageViewModel) {
    this.selectedMessage = message;
    this.advisoryNotificationOverlay.show();
  }

  private emitUnsubscribeMerge() {
    if (this.unsubscribeMerge$.observers.length > 0) {
      this.unsubscribeMerge$.next();
    }
  }

  private toggleSecurityAdvisories(messages: MessageViewModel[]): MessageViewModel[] {
    if (!this.showSecurityAdvisories()) {
      messages = messages.filter(
        message => !this.isSecurityAdvisoryNotification(message) && !this.isAdvisoryCybersecurityNotification(message));
    }
    return messages;
  }

  private showSecurityAdvisories(): boolean {
    let showAdvisories = false;
    if (this.config.toggleSecurityAdvisories) {
      showAdvisories = !((this.config.toggleAdvisorySubscription || this.config.toggleXiaViewAdvisories) &&
        !this.hasViewSecurityAdvisoriesRole);
    }
    return showAdvisories;
  }
}
