import {
  Directive,
  Renderer2,
  ElementRef,
  AfterViewInit,
  HostListener
} from '@angular/core';

@Directive({
  selector: '[hlFilterNoScroll]'
})
export class FilterNoScrollDirective implements AfterViewInit {
  filterPanel: Element;
  filterContent: Element;
  multiSelectboxesClass = '.filter-panel .multi-selectbox';
  selectBoxes: NodeList;

  @HostListener('document:click', ['$event'])
  onclick(event) {
    if (this.filterContent) {
      this.checkSelectboxesClick(event);
    }
  }

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.filterPanel = document.querySelector('.filter-panel__container');
    this.filterContent = document.querySelector('.filter-panel--no-scroll');
  }

  ngAfterViewInit(): void {
    this.selectBoxes = document.querySelectorAll(this.multiSelectboxesClass);
  }

  checkSelectboxesClick(event): any | Element {
    /** if after view init have a empty array */
    this.selectBoxes = document.querySelectorAll(this.multiSelectboxesClass);

    const array: Element[] = [];
    let ele: Element;
    [].forEach.call(this.selectBoxes, (item: Element) => {
      array.push(item);
    });
    ele = array.find(item => item.contains(event.target));
    if (ele && ele.classList.contains('is-open')) {
      this.removeScroll();
    } else {
      this.addScroll();
    }
    return array.find(item => item.contains(event.target));
  }

  removeScroll(): void {
    if (this.filterPanel.scrollTop > 0) {
      this.renderer.setStyle(
        this.filterContent,
        'top',
        `-${this.filterPanel.scrollTop}px`
      );
    }
    this.renderer.setStyle(this.filterContent, 'position', 'relative');

    if (this.filterPanel.classList.contains('filter-panel--scroll')) {
      this.renderer.removeClass(this.filterPanel, 'filter-panel--scroll');
    }
    return;
  }

  addScroll(): void {
    if (!this.filterPanel.classList.contains('filter-panel--scroll')) {
      this.renderer.removeStyle(this.filterContent, 'top');
      this.renderer.addClass(this.filterPanel, 'filter-panel--scroll');
    }
    return;
  }
}
