import {Component, OnInit} from '@angular/core';
import {AnnouncementRestService} from '../../core/rest-services/announcement-rest.service';
import {Announcement, AnnouncementType} from '../../core/models/announcement';

export const announcementEmailPattern = /([_\-a-zA-Z0-9]+(\.[_\-a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})+)/gim;
export const announcementPhonePattern = /(\d{3,4}\s{0,1}-{0,1}\d{3,4}\s{0,1}-{0,1}\d{3,4})/gim;

@Component({
  selector: 'hl-announcement-widget',
  templateUrl: './announcement-widget.component.html'
})
export class AnnouncementWidgetComponent implements OnInit {

  announcement: Announcement;

  get showAnnouncement(): boolean {
    return !!this.announcement;
  }

  get announcementType(): AnnouncementType {
    return this.announcement.type;
  }

  constructor(private announcementRestService: AnnouncementRestService) { }

  ngOnInit() {
    this.announcementRestService.getAnnouncement().subscribe(announcement => {
      this.announcement = announcement;
      if (this.announcement) {
        this.announcement.description = this.addStylesToLinks(announcement.description);
      }
    });
  }

  addStylesToLinks(str: string) {
    const domElement = document.createElement('div');
    domElement.insertAdjacentHTML('beforeend', str);

    const urlLinks = domElement.querySelectorAll('a[href^="http"]');
    const emailLinks = domElement.querySelectorAll('a[href^="mailto:"]');
    const phoneNumberLinks = domElement.querySelectorAll('a[href^="tel:"]');

    this.addIcons(urlLinks, 'icon-extern');
    this.addIcons(emailLinks, 'icon-email');
    this.addIcons(phoneNumberLinks, 'icon-tel');

    return domElement.innerHTML;
  }

  addIcons(emailLinks, icon) {
    for (let i = 0; i < emailLinks.length; i++) {
      const el = emailLinks[i];
      el.appendChild(this.getIcon(icon));
    }

  }

  getIcon(iconClass: string) {
    const iconNode = document.createElement('i');
    iconNode.classList.add('button--link');
    iconNode.classList.add(iconClass);
    return iconNode;
  }


  linkify(str: string) {
    const emailPattern = announcementEmailPattern;
    const phonePattern = announcementPhonePattern;
    // replace any HTML tags
    str = str.replace(/<\/?[^>]+(>|$)/g, '');
    str = str.replace(emailPattern, '<a class="text-link" href="mailto:$1">$1</a>');
    str = str.replace(phonePattern, '<a class="text-link" href="tel:$1">$1</a>');
    return str;
  }
}
