import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { EquipmentRestService } from 'app/core/rest-services/equipment-rest.service';
import { EquipmentEnvironmentCommunicationService } from './equipment-environment-communication/equipment-environment-communication.service';
import { DropdownOptions } from 'app/core/models/dropdown-options';

@Component({
  selector: 'hl-equipment-environment',
  templateUrl: './equipment-environment.component.html'
})
export class EquipmentEnvironmentComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  equipmentKey$ = this.route.params.pipe(map(p => p.id));
  sensorOptions: DropdownOptions[] = [];
  sensors = [] as string[];
  parameterOptions: DropdownOptions[] = [];
  parameters = [] as string[];

  get visibleSensors() {
    return this.sensorOptions.filter(so => this.sensors.indexOf(so.value) !== -1 && this.parameters.length !== 0);
  }

  get visibleParameters() {
    return this.parameterOptions.filter(po => this.parameters.indexOf(po.value) !== -1);
  }

  constructor(
    private route: ActivatedRoute,
    private equipmentRestService: EquipmentRestService,
    private commService: EquipmentEnvironmentCommunicationService
  ) {}

  ngOnInit() {
    this.equipmentKey$.pipe(
      switchMap(key => this.equipmentRestService.getEquipmentEnvironmentFilterOptions(key)),
      takeUntil(this.unsubscribe$)
    ).subscribe(({ sensors, parameters }) => {
      this.sensorOptions = Object.keys(sensors).map(key => ({ title: key, value: sensors[key] }));
      this.parameterOptions = Object.keys(parameters).map(key => ({
        title: 'GENERIC_LABEL_' + key.toUpperCase(),
        value: parameters[key]
      }));
      setTimeout(() => {
        this.sensors = Object.values(sensors);
        this.parameters = [];
      }, 0);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectionChanged() {
    if (this.sensors.length === 0 || this.parameters.length === 0) {
      this.commService.updateDomain({ source: '', domain: null });
    }
  }
}
