import { Injectable } from '@angular/core';
import { DeliveryMilestones } from '../models/tickets/delivery-milestones';
import { Observable } from 'rxjs';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { HttpCacheService } from '../services/cache/http-cache.service';
import { restEndPoint } from '../core-constants.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LogisticRestService {

  private deliveryMilestone = restEndPoint + 'logistic/deliveryMilestones/:ticketNumber';

  constructor(private http: CacheableHttpClient,
    private cacheService: HttpCacheService) {
  }

  getDeliveryMilestones(ticketNumber: string): Observable<DeliveryMilestones[]> {
    return this.http.get<DeliveryMilestones[]>(this.updateRestName(ticketNumber));
  }

  clearCache(ticketNumber: string) {
    const restName = this.updateRestName(ticketNumber);
    this.http.clearCache(restName);
    if (!environment.newCacheableHttpClient) {
      this.cacheService.clearIndividualRequest(restName);
    }
  }

  private updateRestName(ticketNumber: string): string {
    return this.deliveryMilestone.replace(/:ticketNumber/, ticketNumber);
  }
}
