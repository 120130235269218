import { ImpersonationCommunicationService } from '../../../core/component-communication-services/impersonation/impersonation-communication.service';
import { ContactRestService } from '../../../core/rest-services/contact-rest.service';
import { AdditionalContacts } from '../../../core/models/additional-contacts';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';
import { ChatTermsModalComponent } from '../../modal-popup/chat-terms-modal/chat-terms-modal.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'hl-additional-contacts',
  templateUrl: './additional-contacts.component.html'
})
export class AdditionalContactsComponent implements OnInit, OnDestroy {
  @ViewChild('chatTermsModal', { static: false })
  chatTermsModal: ChatTermsModalComponent;

  isContactCollapsed: boolean;
  additionalContacts: AdditionalContacts[];
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  isChatAvailable: boolean;
  public isShown = false;

  constructor(
    private configService: CountryConfigRestService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private contactsRestService: ContactRestService
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @description Initialize of call stack
   */
  init() {
    this.emitUnsubscribe();
    this.isContactCollapsed = true;
    this.additionalContacts = [];
    this.isChatAvailable = false;
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.isChatAvailable = _.isEqual(
        configResponse.CHAT_FEATURE_AVAILABLE,
        'true'
      );

      // get the additional contacts
      if (
        _.isEqual(configResponse.SHOW_CUSTOMER_CONTACT_IN_CONTACT_BOX, 'true')
      ) {
        this.contactsRestService
          .getAdditionalContacts()
          .subscribe(contactsResponse => {
            this.additionalContacts = contactsResponse;
          });
      }
    });
    this.registerEventListeners();
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    this.impersonationCommunicationService.onImpersonationChange$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.init();
      }
    );

    this.impersonationCommunicationService.onCountryLanguageChange$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.init();
      }
    );
  }

  showChatActionModal() {
    this.chatTermsModal.show();
    this.isContactCollapsed = true;
    this.hide();
  }

  show() {
    this.isShown = true;
  }

  hide() {
    this.isShown = false;
  }

  private emitUnsubscribe() {
    if (this.unsubscribe$.observers.length > 0) {
      this.unsubscribe$.next();
    }
  }
}
