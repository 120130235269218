import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightValidation'
})
export class HighlightValidationPipe implements PipeTransform {

  transform(selection: (string | any[]), enableHighlight: boolean = true): boolean {
    return enableHighlight && !(selection && (!Array.isArray(selection) || selection.length));
  }
}
