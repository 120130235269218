import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AddRemoveFavoriteEventService {

  // Observable addRemoveFavorite sources
  private addRemoveFavoriteSource = new Subject<any>();

  // Observable addRemoveFavorite streams
  addRemoveFavoriteSource$ = this.addRemoveFavoriteSource.asObservable();

  constructor() { }

  // Service addRemoveFavorite commands
  emitFavoriteAddedOrRemoved() {
    this.addRemoveFavoriteSource.next();
  }

}
