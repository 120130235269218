import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LogService } from '../../../core/services/log/log.service';
import { CentriCareSeverities } from '../../../core/core-constants.service';

@Component({
  selector: 'hl-create-ticket-availability-area',
  templateUrl: './create-ticket-availability-area.component.html'
})
export class CreateTicketAvailabilityAreaComponent
  implements OnInit, OnDestroy {
  @Input()
  availabilityForm: FormGroup;
  @Input()
  isFormSubmitted: boolean;
  @Input()
  isCentriCareActive = false;
  @Input()
  selectedProblemSeverityId: string;
  time: Date;
  onRBActive: boolean;
  showDateTimePicker = false;

  private availabilityFormBackup: FormGroup;

  constructor(
    private logService: LogService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    // if the component becomes invisible (e.g. because the user selects a ticketType, for which the
    // system availability is not selectable) then none of the values should be validated as 'required'
    // otherwise the form cannot be submitted
    this.removeAllValidators();
  }

  init() {
    this.availabilityFormBackup = this.availabilityForm;
    this.availabilityForm.get('date').disable();
    this.onRBActive = true;

    // adding the required validator by removing and adding the control
    this.availabilityForm.removeControl('cbAvailabilityForService');
    this.availabilityForm.addControl(
      'cbAvailabilityForService',
      new FormControl('', Validators.required)
    );

    // set validators because they have not been set initially in createForm()
    this.setValidators();
    this.recheckAvailability();
  }

  setValidators(event?) {
    let id = '';
    if (event) {
      id = event.target.attributes.id.value;
    } else {
      id = this.availabilityForm.get('cbAvailabilityForService').value;
    }

    if (id === 'onDate') {
      this.availabilityForm.removeControl('date');
      this.availabilityForm.removeControl('time');
      this.availabilityForm.addControl(
        'date',
        new FormControl('', Validators.required)
      );
      this.availabilityForm.addControl(
        'time',
        new FormControl('', Validators.required)
      );
      this.availabilityForm.get('date').enable();
      this.availabilityForm.patchValue({
        date: null,
        time: new Date()
      });

      this.onRBActive = false;
      this.showDateTimePicker = true;
    } else {
      // Disable it -> UI Disabling
      this.availabilityForm.get('date').disable();
      this.availabilityForm.removeControl('date');
      this.availabilityForm.removeControl('time');
      this.availabilityForm.addControl('date', new FormControl(''));
      this.availabilityForm.addControl('time', new FormControl(''));
      // Disable the newly added DateControl since it is enabled by default
      this.availabilityForm.get('date').disable();

      this.availabilityForm.patchValue({
        date: null,
        time: new Date()
      });
      this.onRBActive = true;
      this.showDateTimePicker = false;
    }
  }

  removeAllValidators() {
    if (this.availabilityFormBackup) {
      this.availabilityFormBackup.removeControl('cbAvailabilityForService');
      this.availabilityFormBackup.addControl(
        'cbAvailabilityForService',
        new FormControl('')
      );
      this.availabilityFormBackup.removeControl('date');
      this.availabilityFormBackup.removeControl('time');
      this.availabilityFormBackup.addControl('date', new FormControl(''));
      this.availabilityFormBackup.addControl('time', new FormControl(''));
    } else {
      this.logService.log('strange - the availabilityForm is not set?');
    }
  }

  selectCbAvailabilityForService(value: string) {
    this.availabilityForm.get('cbAvailabilityForService').patchValue(value);
    this.setValidators();
  }

  recheckAvailability() {
    if (this.isCentriCareActive) {
      this.availabilityForm.get('cbAvailabilityForService').patchValue(
        [CentriCareSeverities.NOT_OPERATIONAL, CentriCareSeverities.PARTIALLY_OPERATIONAL_CRITICAL]
          .includes(this.selectedProblemSeverityId as CentriCareSeverities) ? 'immediately' : ''
      );
    }
  }
}
