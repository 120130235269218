import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import * as _ from 'lodash';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { ServiceMetricsRestService } from '../../rest-services/service-metrics-rest.service';
import { CheckPermissionOrRoleService, DASHBOARD } from '../check-permission-or-role.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingAuthGuardService implements CanActivate {

  private isServiceMetricsAvailable: boolean;

  constructor(
    private router: Router,
    private configService: CountryConfigRestService,
    private metricsService: ServiceMetricsRestService,
    private checkPermissionOrRoleService: CheckPermissionOrRoleService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const isReporting = _.isEqual(route.data.stateName, 'reporting');

    return combineLatest([this.configService.getConfig(),
      this.metricsService.isServiceMetricsAvailable(),
      this.checkPermissionOrRoleService.sprComponentPermissionTab]).pipe(map(([config, serviceMetrics, spr]) => {
      this.isServiceMetricsAvailable = serviceMetrics.available;
      if (this.isServiceMetricsAvailable && !!spr) {
        return this.router.parseUrl(DASHBOARD);
      }
      const isReportingAvailable = isReporting ? this.checkReporting(config) : this.checkMetrics();
      return (isReportingAvailable && !!spr) ? this.router.parseUrl(DASHBOARD) : isReportingAvailable;
    }));
  }

  private checkReporting(config): boolean | UrlTree {
    return this.isServiceMetricsAvailable ? this.router.parseUrl(DASHBOARD) : this.checkConfig(config);
  }

  private checkConfig(config): boolean | UrlTree {
    return _.isEqual(config.LIFENET_OWN_REPORTS_AVAILABLE, 'true') ? true :
      this.router.parseUrl(DASHBOARD);
  }

  private checkMetrics(): boolean | UrlTree {
    return this.isServiceMetricsAvailable ? true : this.router.parseUrl(DASHBOARD);
  }

}
