import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Injectable } from '@angular/core';

@Injectable()
export class GenerateExcelRestService {

  constructor(private http: CacheableHttpClient) { }

  postExcelData(restEndpoint: string, data: any): any {
    return this.http.post(restEndpoint, data, {responseType: 'arraybuffer'});
  }
}
