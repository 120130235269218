import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseEquipmentDetailListView } from '../../../../../core/base-class/base-equipment-detail-list-view';
import { restEndPoint } from '../../../../../core/core-constants.service';
import { DropdownOptions } from '../../../../../core/models/dropdown-options';
import { EqDocumentType } from '../../../../../core/models/equipment/eq-document-type';
import { EquipmentDetails } from '../../../../../core/models/equipment/equipment-details';
import { CountryConfigRestService } from '../../../../../core/rest-services/country-config-rest.service';
import { EquipmentRestService } from '../../../../../core/rest-services/equipment-rest.service';
import { EquipmentConstantsService } from '../../../../../core/services/equipment/equipment-constants.service';
import { EquipmentUtilService } from '../../../../../core/services/equipment/equipment-util.service';
import { UserUtilService } from '../../../../../core/services/user/user-util.service';
import { FilterUtilService } from '../../../../../core/utils/filter-util.service';
import { LifeNetUtilService } from '../../../../../core/utils/life-net-util.service';
import { EqAttachmentViewModel } from '../../../../../core/view-models/eq-attachment-view-model';
import { EqDocumentViewModel } from '../../../../../core/view-models/eq-document-view-model';
import { AddOmnitureAndRouterStateNameDirective } from '../../../../../shared/directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';
import { DocumentFile } from '../../../../../core/models/equipment/documents/document-file';
import { DocumentSourceRestService } from '../../../../../core/rest-services/document-source-rest.service';
import { ToasterService } from '../../../../../core/component-communication-services/toaster/toaster.service';
import { DownloadUtilService } from '../../../../../core/utils/download-util.service';

const translateKeys = {
  LABEL_FILE_DOCUMENTS: 'LABEL_FILE_DOCUMENTS',
  DMS_WEBSERVICE_TAB: 'DMS_WEBSERVICE_TAB',
  NWE_PORTAL_DOCUMENT_TYPE: 'NWE_PORTAL_DOCUMENT_TYPE',
  EQUIPMENT_DOCUMENT_MAT_NAME: 'EQUIPMENT_DOCUMENT_MAT_NAME'
};

const equipmentRestEndpoint = restEndPoint + 'equipments/';

@Component({
  selector: 'hl-equipment-documents-simple-list',
  templateUrl: './equipment-documents-simple-list.component.html'
})
export class EquipmentDocumentsSimpleListComponent extends BaseEquipmentDetailListView<EqAttachmentViewModel>
  implements OnInit, OnDestroy {
  readonly DMS_DOCLIB_SOURCE_ID = 'dms-doclib';

  @Input() scrollableContainerClass: string;
  @Input() documents: DocumentFile[];
  @Input() useParentRoute: boolean;
  @Input() currentSourceId: string;

  documentsList: EqAttachmentViewModel[];
  documentTypeOptions: DropdownOptions[];
  private myCheckImpersonationRoleSubscription: Subscription;
  private hasImpersonationRole;
  documentsZipFileNameSuffix;

  private toggleEquipmentDocumentOverview = false;

  // Skeleton for selected multiSelect dropdown
  selectedDropDownList = {
    docTypeName: []
  };
  private translate: { [key: string]: string };

  static getFileName(filename: string) {
    if (EquipmentDocumentsSimpleListComponent.getFileExtension(filename)) {
      return filename.substr(
        0,
        filename.length - EquipmentDocumentsSimpleListComponent.getFileExtension(filename).length - 1
      );
    }
    return filename;
  }

  static getFileExtension(filename: string) {
    if (filename.split('.').length > 1) {
      return filename.split('.').pop();
    }
    return null;
  }

  constructor(
    activatedRoute: ActivatedRoute,
    equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService,
    private router: Router,
    public lifeNetUtilService: LifeNetUtilService,
    private equipmentRestService: EquipmentRestService,
    private sourceRestService: DocumentSourceRestService,
    private equipmentConstantsService: EquipmentConstantsService,
    private translateService: TranslateService,
    private userUtilService: UserUtilService,
    private toasterService: ToasterService,
    private downloadUtilService: DownloadUtilService,
    filterUtilService: FilterUtilService) {
    super(filterUtilService, configService, lifeNetUtilService, equipmentUtilService, activatedRoute);
  }

  ngOnInit() {
    this.translateService.get(Object.keys(translateKeys)).subscribe(translate => {
      this.translate = translate;
      super.init();
    });
  }

  ngOnDestroy() {
    super.destroy();
    if (this.myCheckImpersonationRoleSubscription) {
      this.myCheckImpersonationRoleSubscription.unsubscribe();
    }
  }

  afterConfigProperties(config: any): void {

    if (_.isEqual(config.TOGGLE_EQUIPMENT_DOCUMENT_OVERVIEW, 'true')) {
      this.toggleEquipmentDocumentOverview = true;
      this.populateOptionsAndRawList();
    } else {
      this.myCheckImpersonationRoleSubscription = this.userUtilService.checkUserHasImpersonateUserRole()
        .subscribe(impersonationResponse => {
          this.hasImpersonationRole = impersonationResponse.checkUserImpersonationRole;

          const documents$ = this.getDocumentsObservable(config);
          const webServiceDocs$ = this.getWebServiceObservable(config);
          const nwePortalDocs$ = this.getNweDocumentsObservable(config);
          const patchDocs$ = this.getPatchDocumentsObservable(config);

          if (!(_.isEmpty(this.selectedDropDownList.docTypeName))) {
            this.selectedDropDownList.docTypeName = [];
          }

          forkJoin(documents$, webServiceDocs$, nwePortalDocs$, patchDocs$)
            .pipe(finalize(() => {
              this.isLoaded = true;
            }))
            .subscribe(([docs, webservices, nweDocs, patches]) => {
              this.handleResponses(config, docs, webservices, nweDocs, patches);
            });
        });
    }
  }

  afterInitProperties(): void {
    this.isLoaded = false;
    this.documentsList = [];
    this.sortSkeleton =
      this.currentSourceId === this.DMS_DOCLIB_SOURCE_ID ?
        this.equipmentConstantsService.getSortSkeletonSimpleListDocLib()
        : this.equipmentConstantsService.getSortSkeletonSimpleListDocument();
    this.documentTypeOptions = [];
    this.documentsZipFileNameSuffix = '_' + this.translate[translateKeys.LABEL_FILE_DOCUMENTS] + '.zip';
    this.setSearchInputFromQueryParam(this.router);
  }

  getFilterObject(): any {
    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: [
          'filename',
          'docTypeName'
        ]
      },
      advancedMultiSelect: this.selectedDropDownList,
      orderBy: this.sortSkeleton.sortObject
    };
  }

  populateOptionsAndRawList(): void {
    const mappedDocuments = this.documents ? this.documents.map((doc: DocumentFile) => ({
      ...doc,
      filename: doc.name,
      filesize: doc.fileSize,
      link: doc.downloadLink,
      isAccessible: doc.isAccessible
    })) : [];
    this.rawList = mappedDocuments as any[];
    this.addDocumentTypeOptions();
    this.onAdvancedFilterChange();
    this.isLoaded = true;
  }

  handleResponses(config, docs?, webservices?, nweDocs?, patches?) {
    if (docs) {
      this.handleFileFolderDocumentsResponse(docs, config);
    }
    if (webservices) {
      this.handleWebserviceDocumentsResponse(webservices);
    }
    if (nweDocs) {
      this.handleNwePortalDocumentsResponse(nweDocs);
    }
    if (patches) {
      this.handlePatchDocuments(patches);
    }
    this.rawList = this.documentsList;
    this.onAdvancedFilterChange();
  }

  /**
   * file folder documents
   * @param config
   * @returns {Observable<any>}
   */
  getDocumentsObservable(config: any): Observable<any> {
    if (_.isEqual(config.DMS_FILESHARE_RENDER, 'true')) {
      return this.equipmentRestService.getDocuments(this.equipmentItem.key);
    } else {
      return of([]);
    }
  }

  getWebServiceObservable(config: any): Observable<any> {
    if (_.isEqual(config.DMS_WEBSERVICE_RENDER, 'true')) {
      return this.equipmentUtilService.getDetailsById(this.equipmentItem.key);
    } else {
      return of([]);
    }
  }

  getNweDocumentsObservable(config: any): Observable<any> {
    if (this.shouldShowNwePortalDocuments(config)) {
      return this.equipmentRestService.getNwePortalDocuments(this.equipmentItem.key);
    } else {
      return of([]);
    }
  }

  getPatchDocumentsObservable(config: any): Observable<any> {
    if (_.isEqual(config.DMS_FILESHARE_MAT_RENDER, 'true')) {
      // more than one tab rendered dynamically
      return this.equipmentRestService.getPatchDocuments(this.equipmentItem.key);
    } else {
      return of([]);
    }
  }

  private shouldShowNwePortalDocuments(config: any) {
    if (_.isEqual(config.NWE_PORTAL_REST_CALL, 'false')) {
      return false;
    }
    if (_.isEqual(config.SHOW_NWE_PORTAL_DOCUMENTS_TO_EXTERNAL, 'true')) {
      return true;
    } else {
      return this.hasImpersonationRole;
    }
  }

  /**
   * @description
   * Load the file system documents my making request to BE.
   */
  handleFileFolderDocumentsResponse(response: EqDocumentType[], config) {
    const fileSystemFolders = _.clone(response);
    _.forEach(fileSystemFolders, (folderData: EqDocumentType, i) => {
      if (folderData.documents.length > 0) {
        _.forEach(folderData.documents, documentData => {
          const documentDataView: EqDocumentViewModel = _.cloneDeep(
            documentData
          );
          documentDataView.link = equipmentRestEndpoint + this.equipmentItem.key + '/documents/' +
            documentData.hashkey + '?docType=' + folderData.docType;
          const documentListItem: EqAttachmentViewModel = {
            filename: EquipmentDocumentsSimpleListComponent.getFileName(documentDataView.document),
            filesize: documentDataView.size,
            base64: null,
            creatorName: null,
            creationDateTime: documentDataView.creationDateTime,
            lastModifiedDateTime: documentDataView.lastModifiedDateTime,
            ticketNumber: null,
            link: documentDataView.link,
            downloadLink: documentDataView.link,
            docType: folderData.docType,
            docTypeName: config['EQUIPMENT_DOCUMENT_TYPE.' + (i + 1) + '.NAME'],
            fileExtension: EquipmentDocumentsSimpleListComponent.getFileExtension(documentDataView.document)
          };
          this.documentsList.push(documentListItem);
        });
      }
    });
    this.addDocumentTypeOptions();
  }

  handleWebserviceDocumentsResponse(response: EquipmentDetails) {
    if (response.attachments && response.attachments.length > 0) {
      _.forEach(response.attachments, documentData => {
        const documentDataView: EqAttachmentViewModel = _.cloneDeep(
          documentData
        );
        if (_.isEmpty(documentData.downloadLink)) {
          documentDataView.link =
            equipmentRestEndpoint + this.equipmentItem.key + '/attachments/' + documentData.filename;
        } else {
          // the downloadLink contains the encrypted parameters of the downloadUrl
          documentDataView.link = this.downloadUtilService.generateDownloadLink(
            'equipments', {
              id: this.equipmentItem.key,
              name: documentData.filename,
              payload: documentData.downloadLink,
              available: documentData.isAccessible
            });
        }
        documentDataView.docType = 'DMS_WEBSERVICE';
        documentDataView.docTypeName = this.translate[translateKeys.DMS_WEBSERVICE_TAB];
        documentDataView.fileExtension = EquipmentDocumentsSimpleListComponent.getFileExtension(
          documentDataView.filename
        );
        documentDataView.filename = EquipmentDocumentsSimpleListComponent.getFileName(documentDataView.filename);
        this.documentsList.push(documentDataView);
      });
    }
    this.addDocumentTypeOptions();
  }

  handleNwePortalDocumentsResponse(response: EqAttachmentViewModel[]) {
    _.forEach(response, documentData => {
      const documentDataView: EqAttachmentViewModel = _.cloneDeep(
        documentData
      );
      const restBaseUrl =
        equipmentRestEndpoint + this.equipmentItem.key;
      documentDataView.fileExtension = EquipmentDocumentsSimpleListComponent.getFileExtension(documentDataView.filename);
      documentDataView.docType = 'NWE_PORTAL_DOCUMENT_TYPE';
      documentDataView.docTypeName = this.translate[translateKeys.NWE_PORTAL_DOCUMENT_TYPE];
      documentDataView.link =
        restBaseUrl +
        '/nwedocuments/' +
        documentDataView.filename +
        '?u=' +
        encodeURIComponent(documentDataView.downloadLink);
      documentDataView.filename = EquipmentDocumentsSimpleListComponent.getFileName(documentDataView.filename);
      this.documentsList.push(documentDataView);
    });
    this.addDocumentTypeOptions();
  }

  handlePatchDocuments(response: EqDocumentType[]) {
    const documentTypes = _.clone(response);
    _.forEach(documentTypes, (documentType: EqDocumentType) => {
      if (documentType.documents.length > 0) {
        _.forEach(documentType.documents, documentData => {
          const documentDataView: EqDocumentViewModel = _.cloneDeep(
            documentData
          );
          documentDataView.link = equipmentRestEndpoint
            + this.equipmentItem.key + '/materialDocuments/' + documentData.hashkey;
          const documentListItem: EqAttachmentViewModel = {
            filename: EquipmentDocumentsSimpleListComponent.getFileName(documentDataView.document),
            filesize: documentDataView.size,
            base64: null,
            creatorName: null,
            creationDateTime: documentDataView.creationDateTime,
            lastModifiedDateTime: documentDataView.lastModifiedDateTime,
            ticketNumber: null,
            link: documentDataView.link,
            downloadLink: documentDataView.link,
            docType: 'EQUIPMENT_DOCUMENT_MAT_NAME',
            docTypeName: this.translate[translateKeys.EQUIPMENT_DOCUMENT_MAT_NAME],
            fileExtension: EquipmentDocumentsSimpleListComponent.getFileExtension(documentDataView.document)
          };
          this.documentsList.push(documentListItem);
        });
      }
    });
    this.addDocumentTypeOptions();
  }

  addDocumentTypeOptions() {
    (this.toggleEquipmentDocumentOverview ? this.documents : this.documentsList).forEach(item => {
      if (
        !this.documentTypeOptions.some(
          option => option.title === item.docTypeName
        )
      ) {
        this.documentTypeOptions.push({
          title: item.docTypeName,
          value: item.docTypeName
        });
      }
    });
  }

  hasMoreThanOneDocumentTypeOptions(): boolean {
    return !!this.documentTypeOptions && this.documentTypeOptions.length > 1;
  }

  getInfiniteScrollContainerClassName(): string {
    return this.scrollableContainerClass || '.equipment-docs-view-scroll';
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  redirectToDownloadDocument(item: EqAttachmentViewModel) {
    this.triggerOmnitureTracking(item);
    if (this.currentSourceId === this.DMS_DOCLIB_SOURCE_ID) {
      this.sourceRestService.getResponseFromUrl(item.link).subscribe(() => {
      }, error => {
        if (error['status'] === 301 && error.error && error.error.url) {
          this.downloadUtilService.downloadFileByUrl(item.filename + '.' + item.fileExtension, error.error.url);
        }
      });
    } else {
      this.downloadUtilService.downloadFileByUrl(item.filename + '.' + item.fileExtension, item.link, item.isAccessible);
    }
  }

  triggerOmnitureTracking(item: EqAttachmentViewModel) {
    AddOmnitureAndRouterStateNameDirective
      .triggerDocumentsDownloadTracking(item.filename + '.' + item.fileExtension, item.link);
  }

}
