import { Location } from '@angular/common';
import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { SubscriptionLike } from 'rxjs';
import { BrowserStateService } from '../../../core/services/browser-state.service';
import { LogService } from '../../../core/services/log/log.service';
import { WindowService } from '../../../core/window.service';

@Directive({
  selector: '[hlHandleLocationChangeOnBrowserBack]'
})
export class HandleLocationChangeOnBrowserBackDirective implements OnInit, OnDestroy {

  @Input() urlId: string;
  @Input() filteredList: any;
  @Input() selectedItem: any;
  @Output() onBrowserBackSelection = new EventEmitter<any>();
  @Input() urlKeySegmentIndex = 0;

  window = this.windowService.nativeWindow;

  locationSubscription: SubscriptionLike;

  constructor(private windowService: WindowService,
    private logService: LogService,
    private browserStateService: BrowserStateService,
    private location: Location) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.locationSubscription.unsubscribe();
  }

  init() {
    this.locationSubscription = this.location.subscribe((popState) => {
      if (this.browserStateService.getUserNavigation()) {
        return;
      }
      const findPredicate = {};
      const splitUrl = popState.url.split('/');
      if (this.urlKeySegmentIndex === 0) {
        this.urlKeySegmentIndex = splitUrl.length - 2;
      }
      const splitUrlIdentifier = splitUrl[this.urlKeySegmentIndex];

      /**
       * This time out is added because router event subscription is executed before setting
       * input (for e.g selectedItem)
       * Note: Use of input setter or ngOnChange still executes after router event subscription.
       *
       * the selectedItem is updated here later - that's why the timeout is used - but the better
       * way would be, to wait until also the inputSetter had been called
       */
      setTimeout(() => {
        this.logService.log('Trigger browser back call of navigating through list items only');

        findPredicate[this.urlId] = splitUrlIdentifier;
        let browserBackSelectedItem = _.find(this.filteredList, findPredicate);

        if (!browserBackSelectedItem) {
          // maybe the predicate is URL encoded ...
          findPredicate[this.urlId] = decodeURIComponent(splitUrlIdentifier);
          browserBackSelectedItem = _.find(this.filteredList, findPredicate);
        }

        if (!browserBackSelectedItem) {
          // maybe the predicate is a number
          findPredicate[this.urlId] = parseInt(splitUrlIdentifier, 10);
          if (typeof findPredicate[this.urlId] === 'number') {
            browserBackSelectedItem = _.find(this.filteredList, findPredicate);
          }
        }
        this.onBrowserBackSelection.emit(browserBackSelectedItem);
      }, 0);
    });
  }
}
