import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[hlNoWhiteSpace]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: SpaceValidator,
    multi: true
  }]
})
export class SpaceValidator implements Validator {

  /**
   * Validate if the value is filled only with blank spaces
   * @param control
   */
  @Input() hlNoWhiteSpace: boolean;

  static noWhiteSpace(control: FormControl) {
    return !((control.value || '').trim().length === 0) ? null : {'whitespace': true};
  }

  validate(control: FormControl) {

    return this.hlNoWhiteSpace ? SpaceValidator.noWhiteSpace(control) : null;

  }
}
