import { forkJoin, Observable } from 'rxjs';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LogoutService } from '../component-communication-services/logout/logout.service';
import { UserRestService } from '../rest-services/user-rest.service';
import { Injectable } from '@angular/core';
import { extend, find, forEach, isArray, isEqual, pick } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterUtilService } from './filter-util.service';
import { map } from 'rxjs/operators';
import { ConfigLoaderService } from '../../config-loader.service';

@Injectable()
export class LifeNetUtilService {

  constructor(private userRestService: UserRestService,
    private logoutService: LogoutService,
    private router: Router,
    private translateService: TranslateService,
    private configService: CountryConfigRestService,
    private filterUtilService: FilterUtilService,
    private configLoaderService: ConfigLoaderService) {
  }

  /**
   *
   * @param sourceFrom | BE response model from which properties are extracted
   * @param sourceTo | BE response model to which extracted properties are merged
   * @param objectToFindAndMerge | contains the search key, value and properties to be merged
   *
   * @description Generate the view models by merging properties from different BE response.
   * For e.g. merging equipment status and equipment list
   */
  createViewModels(sourceFrom$: Observable<any>, sourceTo$: Observable<any>, objectToFindAndMerge: any): Observable<any> {
    return forkJoin([sourceFrom$, sourceTo$]).pipe(map(responses => {
      return this.mapToViewModel(responses, objectToFindAndMerge);
    }));
  }

  mapToViewModel(responses: [any, any], objectToFindAndMerge: any) {
    if (responses[0] && responses[1]) {
      let mergedObject = {};

      // check if it's an array or just an object
      if (isArray(responses[1])) {

        const mergedList = [];
        forEach(responses[1], (item) => {

          const extractedProperty = this.filterUtilService.findPropertiesToMerge(item, objectToFindAndMerge, responses[0]);
          mergedList.push(extend(extractedProperty, item));
        });

        mergedObject = mergedList;

      } else {

        // if it is an object
        const extractedProperty = this.filterUtilService.findPropertiesToMerge(responses[1],
          objectToFindAndMerge, responses[0]);
        mergedObject = extend(extractedProperty, responses[1]);

      }
      return mergedObject;
    }

  }

  /**
   * A lot of merging was needed for the Export List Story-1366.
   * This method, takes as input two objects, and an array of strings.
   * the first object sourceFrom, is from where the fields in the propertiesToMerge object will be copied.
   * the second object sourceTo, is where the fields will be copied into.
   */
  mergeObjects(objectFrom, objectTo, propertiesToMerge: string[]): any {
    const mergedObject = objectTo;
    // the objectFrom / objectTo will never be arrays.
    forEach(propertiesToMerge, property => {
      mergedObject[property] = objectFrom[property];
    });
    return mergedObject;
  }

  /**
   * @ngdoc method
   * @name getAddressFields
   *
   * @param viewModel | passed entity model (equipmentViewModel, activitiesViewModel)
   * @param addressOrder
   * @param configurableModalEditProp | for configuring equipment detail editing
   *
   * @description
   * Generate list based on a given order
   */
  getAddressFields(viewModel, addressOrder, configurableModalEditProp) {

    const resultSet = [];
    const addressList = addressOrder.split(',');

    forEach(addressList, (item) => {

      const tempObj: { [k: string]: any } = {};

      if (isEqual(item, 'street')) {
                // tslint:disable-next-line:ban
        tempObj.label = this.translateService.instant('GENERIC_LABEL_STREET');
        tempObj.value = viewModel[item];
      } else if (isEqual(item, 'zip')) {
                // tslint:disable-next-line:ban
        tempObj.label = this.translateService.instant('GENERIC_LABEL_POSTAL_CODE');
        tempObj.value = viewModel[item];
      } else if (isEqual(item, 'city')) {
                // tslint:disable-next-line:ban
        tempObj.label = this.translateService.instant('GENERIC_LABEL_CITY');
        tempObj.value = viewModel[item];
      }

      // New feature for US LifeNet
      // append state if filled
      if (isEqual(item, 'city') && viewModel['state']) {
        tempObj.value = tempObj.value + ', ' + viewModel['state'];
      }

      // Note:- Configuration done only for equipment edit (overview), we need these properties
      if (configurableModalEditProp) {
        tempObj.maxLength = configurableModalEditProp[item].maxLength;
        tempObj.attribute = item;
      }

      resultSet.push(tempObj);
    });

    return resultSet;
  }

  /**
   * Removes the caches, session log out from SAML
   */
  invalidateSession() {

    /*
     * Check if the promise is resolved or not. If yes then we need to invalidate the
     * saml session by calling the link url send by BE.
     */
    this.userRestService.logout().subscribe((logoutResponse) => {

      this.logoutService.emitLogout(logoutResponse);

      // always route to welcome page
      this.router.navigate(['/welcome']);
    }, (error: HttpErrorResponse) => {
      this.router.navigate(['/welcome']);
    });
  }

  /**
   * @description
   * Pick the property value from an object after a predicate find.
   */
  pickPropertyFromObject(dataSet, findObjectToExtract, propertyToExtract) {

    let extractedProperty = null;

    if (dataSet && findObjectToExtract && propertyToExtract) {
      const extractedPropertyObject = pick(find(dataSet, findObjectToExtract), propertyToExtract);
      extractedProperty = extractedPropertyObject[propertyToExtract];
    }
    return extractedProperty;
  }

  /**
   *
   * @param {string} langCountryCode | selected locale
   */
  setTranslationFile(langCountryCode: string): Observable<any> {
    return this.translateService.use(this.configLoaderService.getTranslateFile(langCountryCode));
  }

  /**
   *
   * @param {string} countryCode | country code for configuration file
   * sets the configuration file according to the chosen language/country
   */
  setConfigurationFile(countryCode: string) {
    this.configService.setConfig(countryCode);
  }

  getTechnicalDisplayListCount(): Observable<any> {
    return this.configService.getConfig().pipe(map(configResponse => {
        let technicalDisplay = false;
        if (isEqual(configResponse.TECHNICAL_DISPLAY_LIST_COUNT, 'true')) {
          technicalDisplay = true;
        }
        return technicalDisplay;
      }
    ));
  }
}
