import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SecurityApplianceOrderUtilService } from 'app/core/services/security-appliance-order/security-appliance-order-util.service';
import { ActivatedRoute } from '@angular/router';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { ToasterService } from 'app/core/component-communication-services/toaster/toaster.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hl-create-sa-modal',
  templateUrl: './create-sa-modal.component.html',
  styles: []
})
export class CreateSaModalComponent extends BaseModalPopup implements OnInit {
  @Input()
  equipmentId: string;
  @Input()
  statusColorMap: any;
  createSaoForm: FormGroup;
  showValidationMessage = false;
  isFormSubmitted = false;
  equipmentKey: string;
  @Output()
  close = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private saoUtilService: SecurityApplianceOrderUtilService,
    private route: ActivatedRoute,
    private configService: CountryConfigRestService,
    private toasterService: ToasterService,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  ok() {
    this.isFormSubmitted = false;
    this.showValidationMessage = false;
    this.hide();
    this.close.emit(true);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.initFormGroup();
    this.initFromQueryParams();
  }

  private initFormGroup() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.createForm(configResponse);
    });
  }

  createForm(config) {
    const emailRegEx = new RegExp(config.EMAIL_VALIDATION_REGEX);
    const emailLength = _.parseInt(config.EMAIL_VALIDATION_LENGTH);

    this.createSaoForm = this.fb.group({
      product: this.fb.group({
        equipmentKey: [
          this.equipmentId ? this.equipmentId : '',
          Validators.required
        ],
        customerNumber: [''],
        ipAddress: ['', Validators.required],
        ipAddressFirewall: ['', Validators.required],
        srsConnection: ['', Validators.required],
        targetPorts: ['', Validators.required]
      }),
      order: this.fb.group({
        additionalOption: ['false']
      }),
      contact: this.fb.group({
        contactEmail: [
          '',
          [Validators.maxLength(emailLength), Validators.pattern(emailRegEx)]
        ],
        contactFirstName: ['', [Validators.required]],
        contactLastName: ['', [Validators.required]],
        contactPhone: [''],
        contactSalutation: [''],
        contactTitle: ['']
      }),
      contactIT: this.fb.group({
        itAdministratorName: ['', Validators.required],
        itAdministratorPhone: ['', Validators.required],
        itAdministratorEmail: [
          '',
          [
            Validators.required,
            Validators.maxLength(emailLength),
            Validators.pattern(emailRegEx)
          ]
        ],
        siemensContact: ['']
      }),
      shipping: this.fb.group({
        receiverName: ['', Validators.required],
        customerName: ['', Validators.required],
        department: ['', Validators.required],
        building: ['', Validators.required],
        street: ['', Validators.required],
        postalCode: ['', Validators.required],
        city: ['', Validators.required]
      }),
      billing: this.fb.group({
        receiverName: ['', Validators.required],
        customerName: ['', Validators.required],
        department: ['', Validators.required],
        building: ['', Validators.required],
        street: ['', Validators.required],
        postalCode: ['', Validators.required],
        city: ['', Validators.required],
        isActive: ['false', Validators.required]
      })
    });
  }

  createSao() {
    this.isFormSubmitted = true;
    if (this.createSaoForm.valid) {
      this.showValidationMessage = false;
      this.showSpinner = true;

      this.saoUtilService.createSao(this.createSaoForm.value).subscribe(
        () => {
          this.toasterService.showSuccessToaster(
            true,
            'TEXT_SAO_SEND_ORDER_SUCCESS'
          );
          // this.saoUtilService.back(this.equipmentKey);
          this.ok();
          this.goBackToEquipment();
        },
        () => {
          this.showSpinner = false;
          const toast = {
            type: 'error',
            isBodyTranslationKey: true,
            body: 'TEXT_SAO_ERROR_EMAIL_SENDING'
          };
          this.toasterService.emitToast(toast);
        }
      );
    } else {
      this.showValidationMessage = true;
    }
  }

  goBackToEquipment() {
    this.saoUtilService.back(this.equipmentId);
  }

  cancelCreateSao() {
    this.ok();
    this.goBackToEquipment();
  }

  private initFromQueryParams() {
    if (this.equipmentId) {
      this.createSaoForm.patchValue({
        product: { equipmentKey: this.equipmentId }
      });
    }
  }
}
