import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';

@Pipe({
  name: 'getTicketProblemSeverityColor',
  pure: false
})
export class GetTicketProblemSeverityColorPipe implements PipeTransform, OnDestroy {

  private unsubscribe$ = new Subject();

  ticketStatus = {
    ticketStatusRed: [],
    ticketStatusYellow: []
  };

  constructor(private configService: CountryConfigRestService) {
    this.configService.getConfig().pipe(
       takeUntil(this.unsubscribe$)
    )
    .subscribe((configResponse) => {
      this.setConfigProperties(configResponse);
    });
  }

  setConfigProperties(config) {
    this.ticketStatus.ticketStatusRed = config.TICKET_STATUS_RED.split(',');
    this.ticketStatus.ticketStatusYellow = config.TICKET_STATUS_YELLOW.split(',');
  }

  transform(problemSeverityID: string , args?: any): any {
    if (_.includes(this.ticketStatus.ticketStatusRed, problemSeverityID)) {
      return 'red';
    } else if (_.includes(this.ticketStatus.ticketStatusYellow, problemSeverityID)) {
      return 'yellow';
    } else {
      return 'green';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
