import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ServiceMetricsFilter } from '../../core/models/service-metrics-request';
import { ServiceMetricsRestService } from '../../core/rest-services/service-metrics-rest.service';
import { DownloadUtilService } from '../../core/utils/download-util.service';
import { DatePipeWrapperPipe } from '../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { finalize, takeUntil } from 'rxjs/operators';
import { CountryConfigRestService } from '../../core/rest-services/country-config-rest.service';
import { Subject } from 'rxjs';
import { excelContentType } from '../../shared/components/excel-export/excel-export-from-be.component';

@Component({
  selector: 'hl-metrics-export',
  templateUrl: './metrics-export.component.html'
})
export class MetricsExportComponent implements OnInit, OnDestroy {
  @Input()
  filter: ServiceMetricsFilter;

  private datePattern: string;
  private fileName: string;
  public isLoading: boolean;

  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private serviceMetricsRestService: ServiceMetricsRestService,
    private downloadUtilService: DownloadUtilService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private configService: CountryConfigRestService
    ) {
  }

  ngOnInit(): void {
    this.initConfigProperties();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initConfigProperties() {
    this.isLoading = false;

    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      this.datePattern = config.GENERIC_DATE_PATTERN;
      this.fileName = config.SPR_EXPORT_FILENAME;
    });
  }

  export(): void {
    this.isLoading = true;
    this.serviceMetricsRestService.exportServiceMetrics(this.filter).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      result => {
        const dateName = (this.datePipeWrapperPipe.transform(
          new Date(),
          this.datePattern
        ) as string).replace(/\./g, '-');

        this.downloadUtilService.generateBlobAndDownload(result, excelContentType, this.fileName + '_' + dateName);
      }
    );
  }
}
