import { Router } from '@angular/router';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AccordionWithEquipmentsData } from '../../../core/models/accordion-with-equipments';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import {
  Piis,
  SecurityNotifications
} from '../../../core/models/securityNotifications/security-notifications';
import { EquipmentViewModel } from '../../../core/view-models/equipment-view-model';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { BrowserStateService } from '../../../core/services/browser-state.service';
import { green, red, yellow } from '../../../core/core-constants.service';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { Subject } from 'rxjs';
import {
  SECURITY_EVALUATION_ONGOING,
  SECURITY_SEVERITY_CRITICAL,
  SECURITY_SEVERITY_HIGH,
  SECURITY_SEVERITY_LOW,
  SECURITY_SEVERITY_MEDIUM,
  SECURITY_SEVERITY_NONE,
  SecurityNotificationsService
} from '../../../core/services/security-notifications/security-notifications.service';
import { takeUntil } from 'rxjs/operators';
import { EquipmentCmdb } from '../../../core/models/equipment/equipment-cmdb';
import { uniqBy } from 'lodash';

export interface SecurityEquipmentViewModel extends EquipmentViewModel {
  label?: string;
  cvss?: string;
  evaluationResult?: string;
  publication?: string;
  cmdbEquipment?: EquipmentCmdb;
}

@Component({
  selector: 'hl-accordion-with-equipments',
  templateUrl: './accordion-with-equipments.component.html'
})
export class AccordionWithEquipmentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  featureToggle = false;
  @Input()
  data: SecurityNotifications;
  @Input()
  search?: string;
  @Input()
  evaluations?: string[];
  @Input()
  evaluation?: string;
  @Input()
  myEquipmentChecked?: boolean;
  @Input()
  myEquipmentProfileList?: string[];
  @Output()
  onNumberOfEquipmentLoaded = new EventEmitter<number>();
  equipmentStatusColorMap = [];
  overlayAccordionData: AccordionWithEquipmentsData[];
  equipments: SecurityEquipmentViewModel[];
  initialized = false;
  numberOfEquipments: number;
  datePattern = '';
  private readonly unsubscribe$: Subject<void> = new Subject();

  viewModelList: SecurityEquipmentViewModel[];
  options = [];

  searchObject = {
    searchValue: '',
    searchColumns: [
      'productName'
    ]
  };

  myEquipment = {
    isMyEquipmentChecked: false,
    keyName: 'key',
    myEquipmentList: []
  };

  constructor(private configService: CountryConfigRestService,
    private equipmentUtilService: EquipmentUtilService,
    private router: Router,
    private browserStateService: BrowserStateService,
    private filterUtilService: FilterUtilService,
    private securityNotificationsService: SecurityNotificationsService) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.overlayAccordionData = [];

    this.options = SecurityNotificationsService.evaluationOptions();
    this.options.forEach(option => {
      this.overlayAccordionData.push(
        {
          name: option.name,
          isExpanded: false,
          minScore: option.minScore,
          equipments: []
        }
      );
    });

    this.equipmentUtilService.getEquipmentViewModelList().subscribe(response => {
      this.equipments = response;
      this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
        this.datePattern = configResponse.GENERIC_DATE_PATTERN;
        this.equipmentStatusColorMap[configResponse.EQUIPMENT_STATUS_GREEN] =
          green;
        this.equipmentStatusColorMap[configResponse.EQUIPMENT_STATUS_RED] = red;
        this.equipmentStatusColorMap[configResponse.EQUIPMENT_STATUS_YELLOW] =
          yellow;
        this.initialized = true;
        this.setAccordionData();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.initialized) {
      this.setAccordionData();
    }
  }

  setAccordionData(): void {
    this.clearEquipments();
    this.addEquipments();
    this.applySearchFilter();
    this.onNumberOfEquipmentLoaded.emit(this.numberOfEquipments);
  }

  clearEquipments(): void {
    if (this.featureToggle) {
      this.viewModelList = [];
    } else {
      this.overlayAccordionData.forEach(data => {
        data.equipments = [];
      });
    }
  }

  applySearchFilter(): void {
    this.searchObject.searchValue = this.search;
    this.myEquipment.isMyEquipmentChecked = this.myEquipmentChecked;
    this.myEquipment.myEquipmentList = this.myEquipmentProfileList;

    this.numberOfEquipments = 0;
    if (this.featureToggle) {
      this.viewModelList = this.filterUtilService.applyIndividualFilter(
        this.viewModelList,
        this.searchObject,
        'search'
      );
      this.viewModelList = this.filterUtilService.applyIndividualFilter(
        this.viewModelList,
        this.myEquipment,
        'myEquipment'
      );
      this.viewModelList = this.filterUtilService.applyIndividualFilter(
        this.viewModelList,
        this.evaluations,
        'label'
      );
      this.numberOfEquipments += this.viewModelList.length;
    } else {
      for (const data of this.overlayAccordionData) {
        data.equipments = this.filterUtilService.applyIndividualFilter(
          data.equipments,
          this.searchObject,
          'search'
        );
        data.equipments = this.filterUtilService.applyIndividualFilter(
          data.equipments,
          this.myEquipment,
          'myEquipment'
        );
        this.numberOfEquipments += data.equipments.length;
      }
      this.sortData();
    }
  }

  addEquipments(): void {
    this.data.piis.forEach(pii => {
      this.equipments.forEach(eq => {
        if ( this.securityNotificationsService.isEquipmentConnectedToPII(eq, pii)) {
          if (this.featureToggle) {
            this.addDataToEquipment(eq, pii);
          } else {
            this.addEquipmentToAccordionData(eq, pii);
          }
        }
      });
    });
    if (this.featureToggle) {
      this.viewModelList = uniqBy(this.viewModelList, 'key');

    } else {
      for (const data of this.overlayAccordionData) {
        data.equipments = uniqBy(data.equipments, 'key');
      }
    }
  }

  addDataToEquipment(equipment: SecurityEquipmentViewModel, pii: Piis): void {
    const cscc = this.getCsccFromString(pii.cvssProduct);
    for (const option of this.options) {
      if (cscc >= option.minScore) {
        equipment.cvss = this.securityNotificationsService.formatCvssProduct(pii.cvssProduct) + ' - ' + pii.cvssProductVector;
        equipment.publication = this.data.publication;
        equipment.label = this.getLabel(option.minScore);
        break;
      }
    }
    this.viewModelList.push(equipment);
  }

  addEquipmentToAccordionData(equipment: SecurityEquipmentViewModel, pii: Piis): void {
    const cscc = this.getCsccFromString(pii.cvssProduct);
    for (const data of this.overlayAccordionData) {
      if (cscc >= data.minScore) {
        equipment.cvss = this.securityNotificationsService.formatCvssProduct(pii.cvssProduct) + ' - ' + pii.cvssProductVector;
        equipment.publication = this.data.publication;
        data.equipments.push(equipment);
        break;
      }
    }
  }

  getCsccFromString(paCscc: string): number {
    let cscc = parseFloat(paCscc);
    if (isNaN(cscc)) {
      cscc = -1;
    }
    return cscc;
  }

  /**
   * The Affected Systems list shall be sorted by:
   •  Resulting CVSS Info product value as following: <number> - <vector> DESC
   •  Affected Systems with the same Resulting CVSS Info value shall be sorted by Equipment ID ASC additionally.
   */
  sortData(): void {
    this.overlayAccordionData.forEach(data => {
      if (data.equipments.length > 0) {
        data.equipments.sort((eq1, eq2) => {
          if (eq1.cvss === eq2.cvss) {
            if (eq1.siemensId < eq2.siemensId) {
              return -1;
            }
            if (eq1.siemensId > eq2.siemensId) {
              return 1;
            }
            return 0;
          }
          return this.getCsccFromString(eq2.cvss) - this.getCsccFromString(eq1.cvss);
        });
      }
    });
  }

  navigateToEquipment(equipment: SecurityEquipmentViewModel): void {
    this.securityNotificationsService.emitCloseSecurityOverlayEvent();
    this.browserStateService.setUserNavigation();
    this.router
      .navigate(['/equipment', equipment.key, 'security'], {
        queryParams: {
          equipmentIdentifier: equipment.siemensId
        }
      })
      .then(() => {
        this.browserStateService.resetUserNavigation();
      });
    this.securityNotificationsService.securityId = this.data.id;
    this.securityNotificationsService.emitChangeSecurityIdSubject(this.data.id);
  }

  private getLabel(score: number) {
    switch (score) {
      case 9.0:
        return SECURITY_SEVERITY_CRITICAL;
      case 7.0:
        return SECURITY_SEVERITY_HIGH;
      case 4.0:
        return SECURITY_SEVERITY_MEDIUM;
      case 0.1:
        return SECURITY_SEVERITY_LOW;
      case 0.0:
        return SECURITY_SEVERITY_NONE;
      case -1:
        return SECURITY_EVALUATION_ONGOING;
    }
  }
}
