import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, SlicePipe } from '@angular/common';
import { FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
// modules for translation
import { TranslateModule } from '@ngx-translate/core';
import { NgWormholeModule } from 'ng-wormhole';
// Components
import { UserNameComponent } from './components/user-name/user-name.component';
import { ProgressIndicatorComponent } from './components/progress-indicator/progress-indicator.component';
import { IframeLogoutComponent } from './components/iframe-logout/iframe-logout.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { MultiSelectboxComponent } from './components/multi-selectbox/multi-selectbox.component';
import { ListSorterComponent } from './components/list-sorter/list-sorter.component';
import { ExcelExportComponent } from './components/excel-export/excel-export.component';
import { EquipmentImageComponent } from './components/equipment-image/equipment-image.component';
import { IsMyEquipmentComponent } from './components/is-my-equipment/is-my-equipment.component';
import { NavListLinkComponent } from './components/nav-list-link/nav-list-link.component';
import { ListCounterComponent } from './components/list-counter/list-counter.component';
import { CalendarIconComponent } from './components/calendar-icon/calendar-icon.component';
import { ActivitiesStatusMapComponent } from './components/activities-status-map/activities-status-map.component';
import { ContactEditorComponent } from './components/contact-editor/contact-editor.component';
import { CreateTicketDetailAreaComponent } from './form-group/create-ticket-detail-area/create-ticket-detail-area.component';
import { CreateTicketRequestAreaComponent } from './form-group/create-ticket-request-area/create-ticket-request-area.component';
import { ContactAreaComponent } from './form-group/contact-area/contact-area.component';
import { AttachmentComponent } from './form-group/attachment/attachment.component';
import { UploadComponent } from './form-group/upload/upload.component';
import { CreateTicketListOfTicketsComponent } from './form-group/create-ticket-list-of-tickets/create-ticket-list-of-tickets.component';
import { IsLdCustomerComponent } from './components/is-ld-customer/is-ld-customer.component';
import { EquipmentActvCountComponent } from './components/equipment-actv-count/equipment-actv-count.component';
import { AdditionalContactsComponent } from './components/additional-contacts/additional-contacts.component';
import { TicketsHistoryComponent } from './components/tickets-history/tickets-history.component';
import { TicketsOverviewComponent } from './components/tickets-overview/tickets-overview.component';
import { TicketActionsComponent } from './components/ticket-actions/ticket-actions.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { CreateTicketConfirmPatientDataComponent } from './form-group/create-ticket-confirm-patient-data/create-ticket-confirm-patient-data.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { SimulatorCheckComponent } from './components/simulator-check/simulator-check.component';
// Pipes
import { SafeDomPipe } from './pipes/safe-dom/safe-dom.pipe';
import { MultiSelectPipe } from './pipes/multi-select/multi-select.pipe';
import { SearchPipe } from './pipes/search/search.pipe';
import { OperationalStatusPipe } from './pipes/operational-status/operational-status.pipe';
import { MyEquipmentPipe } from './pipes/my-equipment/my-equipment.pipe';
import { OrderByPipe } from './pipes/order-by/order-by.pipe';
import { RemoveLeadingZeroPipe } from './pipes/removeLeadingZero/remove-leading-zero.pipe';
import { DateRangePipe } from './pipes/date-range/date-range.pipe';
import { DangerForPatientPipe } from './pipes/danger-for-patient/danger-for-patient.pipe';
import { TicketStatusPipe } from './pipes/ticket-status/ticket-status.pipe';
import { FileSizeFormatPipe } from './pipes/file-size-format/file-size-format.pipe';
import { ActivitiesStatusPipe } from './pipes/activities-status/activities-status.pipe';
import { ActivityDateDiffPipe } from './pipes/activity-date-diff/activity-date-diff.pipe';
import { ClinicalFieldsPipe } from './pipes/clinical-fields/clinical-fields.pipe';
import { GroupTypePipe } from './pipes/group-type/group-type.pipe';
import { ContractAvailabilityPipe } from './pipes/contract-availability/contract-availability.pipe';
import { AddPercentPipe } from './pipes/add-percent/add-percent.pipe';
// Directives
import { AddRootClassesDirective } from './directives/add-root-classes/add-root-classes.directive';
import { AddOmnitureAndRouterStateNameDirective } from './directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';
import { ItemShowHideHandlerDirective } from './directives/item-show-hide-handler/item-show-hide-handler.directive';
import { AddRemoveEquipmentToMyProfileDirective } from './directives/add-remove-equipment-to-my-profile/add-remove-equipment-to-my-profile.directive';
import { GoogleMapsDirective } from './directives/google-maps/google-maps.directive';
import { ScrollTopDirective } from './directives/scroll-top/scroll-top.directive';
import { HandleLocationChangeOnBrowserBackDirective } from './directives/handle-location-change-on-browser-back/handle-location-change-on-browser-back.directive';
// Popups/Modals
import { DeactivateEquipmentModalComponent } from './modal-popup/deactivate-equipment-modal/deactivate-equipment-modal.component';
import { EditComponentModalComponent } from './modal-popup/edit-component-modal/edit-component-modal.component';
import { EditEquipmentInfoModalComponent } from './modal-popup/edit-equipment-info-modal/edit-equipment-info-modal.component';
import { RescheduleActivityModalComponent } from './modal-popup/reschedule-activity-modal/reschedule-activity-modal.component';
import { OptionsRecommendModalComponent } from './modal-popup/options-recommend-modal/options-recommend-modal.component';
import { OptionsGetQuoteModalComponent } from './modal-popup/options-get-quote-modal/options-get-quote-modal.component';
import { OptionsGetTrialModalComponent } from './modal-popup/options-get-trial-modal/options-get-trial-modal.component';
import { DangerForPatientModalComponent } from './modal-popup/danger-for-patient-modal/danger-for-patient-modal.component';
import { StateDownModalComponent } from './modal-popup/state-down-modal/state-down-modal.component';
import { MultiCountryImpersonationModalComponent } from './modal-popup/multi-country-impersonation-modal/multi-country-impersonation-modal.component';
import { ContractDetailModalComponent } from './modal-popup/contract-detail-modal/contract-detail-modal.component';
import { ChatTermsModalComponent } from './modal-popup/chat-terms-modal/chat-terms-modal.component';
import { ChatModalComponent } from './modal-popup/chat-modal/chat-modal.component';
import { ReportingModalComponent } from './modal-popup/reporting-modal/reporting-modal.component';
import { TicketUpdateModalComponent } from './modal-popup/ticket-update-modal/ticket-update-modal.component';
import { TicketCloseModalComponent } from './modal-popup/ticket-close-modal/ticket-close-modal.component';
import { AddFavoriteModalComponent } from './modal-popup/add-favorite-modal/add-favorite-modal.component';
import { DeleteFavoriteModalComponent } from './modal-popup/delete-favorite-modal/delete-favorite-modal.component';
import { TermsAndConditionModalComponent } from './modal-popup/terms-and-condition-modal/terms-and-condition-modal.component';
import { IcCodeModalComponent } from './modal-popup/ic-code-modal/ic-code-modal.component';
import { SimulatorModalComponent } from './modal-popup/simulator-modal/simulator-modal.component';
import { PsrRequestStatusPipe } from './pipes/psr-request-status/psr-request-status.pipe';
import { CreateTicketAvailabilityAreaComponent } from './form-group/create-ticket-availability-area/create-ticket-availability-area.component';
import { TimepickerModule } from 'ngx-bootstrap';
import { ScheduleActivityModalComponent } from './modal-popup/schedule-activity-modal/schedule-activity-modal.component';
import { DatePipeWrapperPipe } from './pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { UploadUpdatePackageModalComponent } from './modal-popup/upload-update-package-modal/upload-update-package-modal.component';
import { HowToModalComponent } from '../system-updates/how-to-modal/how-to-modal.component';
import { ReportExportComponent } from '../generate-reports/components/report-export/report-export.component';
import { CreateTicketMobileAddressAreaComponent } from './form-group/create-ticket-mobile-address-area/create-ticket-mobile-address-area.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { ListComponent } from './components/list/list.component';
import { SavedFiltersComponent } from './components/saved-filters/saved-filters.component';
import { TextfieldAnimatorDirective } from './directives/textfield-animator/textfield-animator.directive';
import { DropdownCloserDirective } from './directives/dropdown-closer/dropdown-closer.directive';
import { RenameFavoriteModalComponent } from './modal-popup/rename-favorite-modal/rename-favorite-modal.component';
import { GroupButtonsComponent } from './components/group-buttons/group-buttons.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { NotificationAttachmentsComponent } from './components/notification-attachments/notification-attachments.component';
import { ExcelExportFromBeComponent } from 'app/shared/components/excel-export/excel-export-from-be.component';
import { IadvizeIntegrationDirective } from './directives/iadvize-integration/iadvize-integration.directive';
import { CalendarComponent } from '../activities/calendar/calendar.component';
import { CalendarYearComponent } from '../activities/calendar-year/calendar-year.component';
import { CollapsableDirective } from './directives/collapsable/collapsable.directive';
import { SwitchLanguageModalComponent } from './modal-popup/switch-language-modal/switch-language-modal.component';
import { SelectboxComponent } from './components/selectbox/selectbox.component';
import { TableSelectboxComponent } from './components/selectbox/table-selectbox/table-selectbox.component';
import { SelectDropdownMenuComponent } from './components/selectbox/table-selectbox/select-dropdown-menu.component';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { FilterNoScrollDirective } from './directives/filter-no-scroll/filter-no-scroll.directive';
import { TabsComponent } from './components/tabs/tabs.component';
import { ActivitiesOverviewComponent } from './components/activities-overview/activities-overview.component';
import { ContractsOverviewComponent } from './components/contracts-overview/contracts-overview.component';
import { CreateTicketComponent } from '../tickets/create-ticket/create-ticket.component';
import { ContractListItemComponent } from './components/contract-list-item/contract-list-item.component';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { DiagramModule } from '../diagram/diagram.module';
import { CreatePsrModalComponent } from './modal-popup/create-psr-modal/create-psr-modal.component';
import { CreatePsrProductAreaComponent } from './form-group/create-psr-product-area/create-psr-product-area.component';
import { CreatePsrServiceSummaryComponent } from './form-group/create-psr-service-summary/create-psr-service-summary.component';
import { CreatePsrAcceptanceAuthorizationComponent } from './form-group/create-psr-acceptance-authorization/create-psr-acceptance-authorization.component';
import { CreatePsrTemplatesComponent } from './form-group/create-psr-templates/create-psr-templates.component';
import { CreateSaModalComponent } from './modal-popup/create-sa-modal/create-sa-modal.component';
import { CreateSaoProductInformationComponent } from './form-group/create-sao-product-information/create-sao-product-information.component';
import { CreateSaoOrderSummaryComponent } from './form-group/create-sao-order-summary/create-sao-order-summary.component';
import { CreateSaoContactInformationComponent } from './form-group/create-sao-contact-information/create-sao-contact-information.component';
import { CreateSaoShippingAddressComponent } from './form-group/create-sao-shipping-address/create-sao-shipping-address.component';
import { CreateSaoBillingAddressComponent } from './form-group/create-sao-billing-address/create-sao-billing-address.component';
import { CreateSaoDownloadComponent } from './form-group/create-sao-download/create-sao-download.component';
import { CreateGroupModalComponent } from './modal-popup/create-group-modal/create-group-modal.component';
import { VerifyPublicGroupModalComponent } from './modal-popup/verify-public-group-modal/verify-public-group-modal.component';
import { TextfieldComponent } from './components/textfield/textfield.component';
import { CreateSaoEntryPageComponent } from './overlays/create-sao-entry-page/create-sao-entry-page.component';
import { HelperTextFormsDirective } from './directives/helper-text-forms/helper-text-forms.directive';
import { IntroFeatureModalComponent } from './modal-popup/intro-feature-modal/intro-feature-modal.component';
import { IntroSliderGalleryComponent } from './components/intro-slider-gallery/intro-slider-gallery.component';
import { AccordionWithEquipmentsComponent } from './components/accordion-with-equipments/accordion-with-equipments.component';
import { ActivitiesOpenClosedPipe } from './pipes/activities-open-closed/activities-open-closed.pipe';
import { SecurityDetailComponent } from './components/security-detail/security-detail.component';
import { WalkMeDirective } from './directives/walk-me/walk-me.directive';
import { LockedUploadUpdateModalComponent } from './modal-popup/locked-upload-update-modal/locked-upload-update-modal.component';
import { ExistsUploadUpdateModalComponent } from './modal-popup/exist-upload-update-modal/exists-upload-update-modal.component';
import { UploadUpdatePackageChecksumValidator } from './modal-popup/upload-update-package-modal/upload-update-package-checksum.validator';
import { UploadUpdatePackageHowtoValidator } from './modal-popup/upload-update-package-modal/upload-update-package-howto.validator';
import { UploadUpdatePackagePackageinfoValidator } from './modal-popup/upload-update-package-modal/upload-update-package-packageinfo.validator';
import { ContractEquipmentUsageComponent } from './components/contract-equipment-usage/contract-equipment-usage.component';
import { EditCustomerNameModalComponent } from './modal-popup/edit-customer-name-modal/edit-customer-name-modal.component';
import { CancelModalComponent } from './modal-popup/cancel-modal/cancel-modal.component';
import { CreateUserNotificationsPageComponent } from './overlays/create-user-notifications-page/create-user-notifications-page.component';
import { NotificationsSubscriptionModalComponent } from './modal-popup/notifications-subscription-modal/notifications-subscription-modal.component';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { TimezoneComponent } from './components/timezone/timezone.component';
import { EventLogsComponent } from './components/event-logs/event-logs.component';
import { ActivitiesEventLogsComponent } from './components/activities-event-logs/activities-event-logs.component';
import { TicketsEventLogsComponent } from './components/tickets-event-logs/tickets-event-logs.component';
import { TicketsDeliveryMilestonesComponent } from './components/tickets-delivery-milestones/tickets-delivery-milestones.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { SelectEquipmentModalComponent } from './modal-popup/select-equipment-modal/select-equipment-modal.component';
import { RegistrationModalComponent } from './modal-popup/registration-modal/registration-modal-component';
import { SpaceValidator } from './validators/space.validator';
import { GetActivityStatusColorPipe } from './pipes/get-activity-status-color/get-activity-status-color.pipe';
import { GetContractStatusColorPipe } from './pipes/get-contract-status-color/get-contract-status-color.pipe';
import { GetTicketProblemSeverityColorPipe } from './pipes/get-ticket-problem-severity-color/get-ticket-problem-severity-color.pipe';
import { GetItemAvailabilityOptionsPipe } from './pipes/get-item-availability-options/get-item-availability-options.pipe';
import { ReplaceBreakLinesWithCommaPipe } from './pipes/replace-break-lines-with-comma/replace-break-lines-with-comma.pipe';
import { EquipmentStatusPipe } from './pipes/equipment-status/equipment-status.pipe';
import { ReadMoreArraysComponent } from './components/read-more/read-more-arrays/read-more-arrays.component';
import { CreateAdvisoryModalComponent } from './modal-popup/create-advisory-modal/create-advisory-modal.component';
import { ToHtmlPipe } from './pipes/to-html.pipe';
import { ConfirmationModalComponent } from './modal-popup/confirmation-modal/confirmation-modal.component';
import { ReplaceUnderscoresWithSpacePipe } from './pipes/replace-underscores-with-space/replace-underscores-with-space.pipe';
import { EquipmentMissingModalComponent } from './modal-popup/equipment-missing-modal/equipment-missing-modal.component';
import { MetricsExportComponent } from '../service-metrics/metrics-export/metrics-export.component';
import { NestedMultiSelectboxComponent } from './components/nested-multi-selectbox/nested-multi-selectbox.component';
import { NestedMultiSelectboxTreeComponent } from './components/nested-multi-selectbox/nested-multi-selectbox-tree.component';
import { IndeterminateCheckboxPipe } from './pipes/indeterminate-checkbox/indeterminate-checkbox.pipe';
import { TooltipWithScrollbarDirective } from './directives/tooltip/tooltip-with-scrollbar.directive';
import { SearchMultiSelectboxComponent } from './components/search-multi-selectbox/search-multi-selectbox.component';
import { AdvisoryUploadFilesModalComponent } from './modal-popup/advisory-upload-files-modal/advisory-upload-files-modal.component';
import { CreateAdvisoryNotificationModalComponent } from './modal-popup/create-advisory-notification-modal/create-advisory-notification-modal.component';
import { SprAlignTextPipe } from './pipes/spr-align-text/spr-align-text.pipe';
import { SprAlignClassPipe } from './pipes/spr-align-class/spr-align-class.pipe';
import { ConfigurationTableComponent } from '../spr/configuration/table/configuration-table.component';
import { SprTableTextInputTypePipe } from './pipes/spr-table-text-input-type/spr-table-text-input-type.pipe';
import { SprTableHighlightCellPipe } from './pipes/spr-table-highlight-cell/spr-table-highlight-cell.pipe';
import { HighlightValidationPipe } from './pipes/highlight-validation/highlight-validation.pipe';
import { CreateRwfModalComponent } from './modal-popup/create-rwf-modal/create-rwf-modal.component';
import { NestedSelectboxComponent } from './components/nested-selectbox/nested-selectbox.component';
import { NestedSelectboxTreeComponent } from './components/nested-selectbox/nested-selectbox-tree.component';
import { HighlightNameValidationPipe } from './pipes/highlight-validation/highlight-name-validation.pipe';
import { SprWidgetTypeClassPipe } from './pipes/spr-widget-type-class/spr-widget-type-class.pipe';
import { SyngoVoucherRequestModalComponent } from './modal-popup/syngo-voucher-request-modal/syngo-voucher-request-modal.component';
import { DirectionArrowsComponent } from './components/direction-arrows/direction-arrows.component';
import { DiagramCalculateHeightPipe } from './pipes/diagram-calculate-height/diagram-calculate-height.pipe';
import { SprTextareaComponent } from './components/textarea/spr-textarea/spr-textarea.component';
import { ButtonRowComponent } from './components/button-row/button-row.component';
import { FilterTabsComponent } from './components/filter-tabs/filter-tabs.component';
import { CreateNewSystemUpdateModalComponent } from './modal-popup/create-new-system-update-modal/create-new-system-update-modal.component';
import { EquipmentSelectionForNewSystemUpdateComponent } from './modal-popup/create-new-system-update-modal/equipment-selection-for-new-system-update/equipment-selection-for-new-system-update.component';
import { EquipmentAddedToNewSystemUpdateComponent } from './modal-popup/create-new-system-update-modal/equipment-added-to-new-system-update/equipment-added-to-new-system-update.component';
import { SystemUpdateInformationFormComponent } from './modal-popup/create-new-system-update-modal/system-update-information-form/system-update-information-form.component';
import { CreateNewEquipmentForUpdateComponent } from './modal-popup/create-new-system-update-modal/create-new-equipment-for-update/create-new-equipment-for-update.component';
import { ChatBotDirective } from './directives/chat-bot/chat-bot.directive';

// Reactive forms

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    ModalModule,
    TimepickerModule,
    FlatpickrModule.forRoot(),
    NgWormholeModule,
    DiagramModule,
    InfiniteScrollModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule
  ],
  declarations: [
    UserNameComponent,
    CreateTicketComponent,
    ProgressIndicatorComponent,
    IframeLogoutComponent,
    ToasterComponent,
    MultiSelectboxComponent,
    SearchMultiSelectboxComponent,
    TableSelectboxComponent,
    NestedMultiSelectboxComponent,
    NestedMultiSelectboxTreeComponent,
    NestedSelectboxComponent,
    NestedSelectboxTreeComponent,
    DatePickerComponent,
    ListSorterComponent,
    ExcelExportComponent,
    ExcelExportFromBeComponent,
    EquipmentImageComponent,
    IsMyEquipmentComponent,
    NavListLinkComponent,
    ListCounterComponent,
    CalendarIconComponent,
    ActivitiesStatusMapComponent,
    IsLdCustomerComponent,
    ContactEditorComponent,
    CreateTicketDetailAreaComponent,
    CreateTicketRequestAreaComponent,
    ContactAreaComponent,
    AttachmentComponent,
    UploadComponent,
    CreateTicketListOfTicketsComponent,
    CreateTicketConfirmPatientDataComponent,
    EquipmentActvCountComponent,
    AdditionalContactsComponent,
    TicketsOverviewComponent,
    TicketsHistoryComponent,
    TicketActionsComponent,
    SafeDomPipe,
    MultiSelectPipe,
    SearchPipe,
    OperationalStatusPipe,
    MyEquipmentPipe,
    OrderByPipe,
    DateRangePipe,
    RemoveLeadingZeroPipe,
    DangerForPatientPipe,
    TicketStatusPipe,
    FileSizeFormatPipe,
    ActivitiesStatusPipe,
    ActivitiesOpenClosedPipe,
    ActivityDateDiffPipe,
    ClinicalFieldsPipe,
    GroupTypePipe,
    ContractAvailabilityPipe,
    PsrRequestStatusPipe,
    AddRootClassesDirective,
    AddOmnitureAndRouterStateNameDirective,
    ItemShowHideHandlerDirective,
    AddRemoveEquipmentToMyProfileDirective,
    GoogleMapsDirective,
    HandleLocationChangeOnBrowserBackDirective,
    DeactivateEquipmentModalComponent,
    SyngoVoucherRequestModalComponent,
    EditComponentModalComponent,
    EditEquipmentInfoModalComponent,
    RescheduleActivityModalComponent,
    DangerForPatientModalComponent,
    StateDownModalComponent,
    ContractDetailModalComponent,
    OptionsRecommendModalComponent,
    OptionsGetQuoteModalComponent,
    OptionsGetTrialModalComponent,
    MultiCountryImpersonationModalComponent,
    SwitchLanguageModalComponent,
    SelectEquipmentModalComponent,
    OptionsGetTrialModalComponent,
    LockedUploadUpdateModalComponent,
    ExistsUploadUpdateModalComponent,
    ChatTermsModalComponent,
    ChatModalComponent,
    ReportingModalComponent,
    TicketUpdateModalComponent,
    TicketCloseModalComponent,
    AddFavoriteModalComponent,
    DeleteFavoriteModalComponent,
    TermsAndConditionModalComponent,
    IcCodeModalComponent,
    ScrollTopDirective,
    PsrRequestStatusPipe,
    ScrollTopDirective,
    AddPercentPipe,
    CookiePolicyComponent,
    SimulatorCheckComponent,
    SimulatorModalComponent,
    CreateTicketAvailabilityAreaComponent,
    ScheduleActivityModalComponent,
    UploadUpdatePackageModalComponent,
    EquipmentMissingModalComponent,
    DatePipeWrapperPipe,
    EquipmentStatusPipe,
    HowToModalComponent,
    ReportExportComponent,
    CreateTicketMobileAddressAreaComponent,
    OverlayComponent,
    ReadMoreComponent,
    ReadMoreArraysComponent,
    ListComponent,
    SavedFiltersComponent,
    TextfieldAnimatorDirective,
    DropdownCloserDirective,
    RenameFavoriteModalComponent,
    GroupButtonsComponent,
    TooltipDirective,
    TooltipWithScrollbarDirective,
    CalendarComponent,
    CalendarYearComponent,
    CollapsableDirective,
    TruncatePipe,
    FilterNoScrollDirective,
    SelectboxComponent,
    TabsComponent,
    ActivitiesOverviewComponent,
    ContractsOverviewComponent,
    ContractListItemComponent,
    IadvizeIntegrationDirective,
    NotificationAttachmentsComponent,
    SearchFieldComponent,
    TimezoneComponent,
    TimePickerComponent,
    TextareaComponent,
    CreatePsrModalComponent,
    CreatePsrProductAreaComponent,
    CreatePsrServiceSummaryComponent,
    CreatePsrAcceptanceAuthorizationComponent,
    CreatePsrTemplatesComponent,
    CreateSaModalComponent,
    CreateSaoProductInformationComponent,
    CreateSaoOrderSummaryComponent,
    CreateSaoContactInformationComponent,
    CreateSaoShippingAddressComponent,
    CreateSaoBillingAddressComponent,
    CreateSaoDownloadComponent,
    CreateGroupModalComponent,
    EditCustomerNameModalComponent,
    VerifyPublicGroupModalComponent,
    TextfieldComponent,
    CreateSaoEntryPageComponent,
    HelperTextFormsDirective,
    IntroFeatureModalComponent,
    IntroSliderGalleryComponent,
    AccordionWithEquipmentsComponent,
    SecurityDetailComponent,
    WalkMeDirective,
    ChatBotDirective,
    ContractEquipmentUsageComponent,
    CancelModalComponent,
    CreateUserNotificationsPageComponent,
    NotificationsSubscriptionModalComponent,
    MonthPickerComponent,
    EventLogsComponent,
    ActivitiesEventLogsComponent,
    TicketsEventLogsComponent,
    TicketsDeliveryMilestonesComponent,
    TimelineComponent,
    SpaceValidator,
    RegistrationModalComponent,
    GetActivityStatusColorPipe,
    GetContractStatusColorPipe,
    GetTicketProblemSeverityColorPipe,
    GetItemAvailabilityOptionsPipe,
    ReplaceBreakLinesWithCommaPipe,
    ReplaceUnderscoresWithSpacePipe,
    CreateAdvisoryModalComponent,
    ToHtmlPipe,
    ConfirmationModalComponent,
    MetricsExportComponent,
    IndeterminateCheckboxPipe,
    ConfigurationTableComponent,
    AdvisoryUploadFilesModalComponent,
    SelectDropdownMenuComponent,
    CreateAdvisoryNotificationModalComponent,
    SprAlignTextPipe,
    SprAlignClassPipe,
    SprTableTextInputTypePipe,
    SprTableHighlightCellPipe,
    SprWidgetTypeClassPipe,
    HighlightValidationPipe,
    HighlightNameValidationPipe,
    CreateRwfModalComponent,
    DirectionArrowsComponent,
    DiagramCalculateHeightPipe,
    SprTextareaComponent,
    ButtonRowComponent,
    FilterTabsComponent,
    CreateNewSystemUpdateModalComponent,
    CreateNewEquipmentForUpdateComponent,
    SystemUpdateInformationFormComponent,
    EquipmentSelectionForNewSystemUpdateComponent,
    EquipmentAddedToNewSystemUpdateComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChatTermsModalComponent,
    ChatModalComponent,
    RouterModule,
    TranslateModule,
    ModalModule,
    TimepickerModule,
    NgWormholeModule,
    UserNameComponent,
    CreateTicketComponent,
    ProgressIndicatorComponent,
    IframeLogoutComponent,
    ToasterComponent,
    MultiSelectboxComponent,
    SearchMultiSelectboxComponent,
    TableSelectboxComponent,
    SelectDropdownMenuComponent,
    NestedMultiSelectboxComponent,
    NestedMultiSelectboxTreeComponent,
    NestedSelectboxComponent,
    NestedSelectboxTreeComponent,
    DatePickerComponent,
    ListSorterComponent,
    ExcelExportComponent,
    ExcelExportFromBeComponent,
    EquipmentImageComponent,
    IsMyEquipmentComponent,
    NavListLinkComponent,
    ListCounterComponent,
    CalendarIconComponent,
    ActivitiesStatusMapComponent,
    IsLdCustomerComponent,
    CookiePolicyComponent,
    DeactivateEquipmentModalComponent,
    SyngoVoucherRequestModalComponent,
    EditComponentModalComponent,
    EditEquipmentInfoModalComponent,
    RescheduleActivityModalComponent,
    OptionsRecommendModalComponent,
    OptionsGetQuoteModalComponent,
    OptionsGetTrialModalComponent,
    MultiCountryImpersonationModalComponent,
    SwitchLanguageModalComponent,
    SelectEquipmentModalComponent,
    DangerForPatientModalComponent,
    StateDownModalComponent,
    ContractDetailModalComponent,
    LockedUploadUpdateModalComponent,
    ExistsUploadUpdateModalComponent,
    ReportingModalComponent,
    TicketUpdateModalComponent,
    TicketCloseModalComponent,
    AddFavoriteModalComponent,
    DeleteFavoriteModalComponent,
    TermsAndConditionModalComponent,
    IcCodeModalComponent,
    SimulatorModalComponent,
    ScheduleActivityModalComponent,
    UploadUpdatePackageModalComponent,
    EquipmentMissingModalComponent,
    CreateTicketDetailAreaComponent,
    CreateTicketRequestAreaComponent,
    ContactAreaComponent,
    AttachmentComponent,
    UploadComponent,
    CreateTicketListOfTicketsComponent,
    CreateTicketConfirmPatientDataComponent,
    EquipmentActvCountComponent,
    AdditionalContactsComponent,
    TicketsOverviewComponent,
    TicketsHistoryComponent,
    TicketActionsComponent,
    SimulatorCheckComponent,
    SafeDomPipe,
    MultiSelectPipe,
    SearchPipe,
    OperationalStatusPipe,
    MyEquipmentPipe,
    SlicePipe,
    OrderByPipe,
    DateRangePipe,
    RemoveLeadingZeroPipe,
    DatePipe,
    DangerForPatientPipe,
    TicketStatusPipe,
    FileSizeFormatPipe,
    ActivitiesStatusPipe,
    ActivitiesOpenClosedPipe,
    ActivityDateDiffPipe,
    ClinicalFieldsPipe,
    GroupTypePipe,
    ContractAvailabilityPipe,
    PsrRequestStatusPipe,
    AddPercentPipe,
    DatePipeWrapperPipe,
    EquipmentStatusPipe,
    AddRootClassesDirective,
    AddOmnitureAndRouterStateNameDirective,
    ItemShowHideHandlerDirective,
    AddRemoveEquipmentToMyProfileDirective,
    GoogleMapsDirective,
    ScrollTopDirective,
    HandleLocationChangeOnBrowserBackDirective,
    CreateTicketAvailabilityAreaComponent,
    HowToModalComponent,
    ReportExportComponent,
    CreateTicketMobileAddressAreaComponent,
    NotificationAttachmentsComponent,
    OverlayComponent,
    ReadMoreComponent,
    ReadMoreArraysComponent,
    ListComponent,
    SavedFiltersComponent,
    TextfieldAnimatorDirective,
    DropdownCloserDirective,
    RenameFavoriteModalComponent,
    GroupButtonsComponent,
    TooltipDirective,
    TooltipWithScrollbarDirective,
    CalendarComponent,
    CalendarYearComponent,
    CollapsableDirective,
    FilterNoScrollDirective,
    TruncatePipe,
    SelectboxComponent,
    TabsComponent,
    ActivitiesOverviewComponent,
    ContractsOverviewComponent,
    TimePickerComponent,
    TextareaComponent,
    ContractListItemComponent,
    SearchFieldComponent,
    TimezoneComponent,
    DiagramModule,
    CreatePsrModalComponent,
    CreateSaModalComponent,
    CreateGroupModalComponent,
    EditCustomerNameModalComponent,
    VerifyPublicGroupModalComponent,
    TextfieldComponent,
    CreateSaoEntryPageComponent,
    HelperTextFormsDirective,
    IntroFeatureModalComponent,
    IntroSliderGalleryComponent,
    AccordionWithEquipmentsComponent,
    SecurityDetailComponent,
    WalkMeDirective,
    ContractEquipmentUsageComponent,
    ChatBotDirective,
    CancelModalComponent,
    CreateUserNotificationsPageComponent,
    NotificationsSubscriptionModalComponent,
    MonthPickerComponent,
    EventLogsComponent,
    ActivitiesEventLogsComponent,
    TicketsEventLogsComponent,
    TicketsDeliveryMilestonesComponent,
    TimelineComponent,
    RegistrationModalComponent,
    GetActivityStatusColorPipe,
    GetContractStatusColorPipe,
    GetTicketProblemSeverityColorPipe,
    GetItemAvailabilityOptionsPipe,
    CreateAdvisoryModalComponent,
    ConfirmationModalComponent,
    ToHtmlPipe,
    ReplaceUnderscoresWithSpacePipe,
    MetricsExportComponent,
    IndeterminateCheckboxPipe,
    ConfigurationTableComponent,
    AdvisoryUploadFilesModalComponent,
    CreateAdvisoryNotificationModalComponent,
    SprAlignTextPipe,
    SprAlignClassPipe,
    SprTableTextInputTypePipe,
    SprTableHighlightCellPipe,
    SprWidgetTypeClassPipe,
    HighlightValidationPipe,
    CreateRwfModalComponent,
    HighlightNameValidationPipe,
    DirectionArrowsComponent,
    DiagramCalculateHeightPipe,
    SprTextareaComponent,
    ButtonRowComponent,
    FilterTabsComponent,
    CreateNewSystemUpdateModalComponent,
    CreateNewEquipmentForUpdateComponent,
    SystemUpdateInformationFormComponent,
    EquipmentSelectionForNewSystemUpdateComponent,
    EquipmentAddedToNewSystemUpdateComponent
  ],
  providers: [
    SafeDomPipe,
    MultiSelectPipe,
    SearchPipe,
    OperationalStatusPipe,
    MyEquipmentPipe,
    SlicePipe,
    OrderByPipe,
    DateRangePipe,
    DatePipe,
    DatePipeWrapperPipe,
    EquipmentStatusPipe,
    RemoveLeadingZeroPipe,
    DangerForPatientPipe,
    TicketStatusPipe,
    FileSizeFormatPipe,
    ActivitiesStatusPipe,
    ActivitiesOpenClosedPipe,
    ActivityDateDiffPipe,
    ClinicalFieldsPipe,
    GroupTypePipe,
    ContractAvailabilityPipe,
    PsrRequestStatusPipe,
    AddPercentPipe,
    FormGroupDirective,
    NgForm,
    UploadUpdatePackageChecksumValidator,
    UploadUpdatePackageHowtoValidator,
    UploadUpdatePackagePackageinfoValidator,
    GetActivityStatusColorPipe,
    GetContractStatusColorPipe,
    GetTicketProblemSeverityColorPipe,
    ReplaceBreakLinesWithCommaPipe,
    ReplaceUnderscoresWithSpacePipe,
    IndeterminateCheckboxPipe,
    SprAlignTextPipe,
    SprAlignClassPipe,
    SprTableTextInputTypePipe,
    SprTableHighlightCellPipe,
    SprWidgetTypeClassPipe,
    HighlightValidationPipe,
    HighlightNameValidationPipe,
    DiagramCalculateHeightPipe
  ]
})
export class SharedModule {
}
