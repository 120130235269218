import { ActivitiesDetail } from '../models/activities/activities-detail';
import { NotifStatus, restEndPoint } from '../core-constants.service';
import { Activities } from '../models/activities/activities';
import { HttpParams } from '@angular/common/http';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivitiesTypes } from '../models/activities/activities-types';
import { DateUtilService } from '../utils/date-util.service';
import { WebHttpUrlEncodingCodecService } from '../utils/web-http-url-encoding-codec.service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpCacheService } from '../services/cache/http-cache.service';

@Injectable()
export class ActivitiesRestService {
  // activities
  activitiesRestName = restEndPoint + 'activities';
  activitiesTypesRestName = restEndPoint + 'activities/types';
  activitiesRescheduleRestName = restEndPoint + 'activities/:id/reschedule';
  activitiesUpdateRestName = restEndPoint + 'activities/update';
  activitiesPMScheduleRestName = restEndPoint + 'activities/:id/pmSchedule';
  activitiesDetailsRestName = restEndPoint + 'activities/:id/details';
  openActivitiesCountRestName = restEndPoint + 'activities/count/open?equipmentKey=:key';

  constructor(
    private http: CacheableHttpClient,
    private cacheService: HttpCacheService,
    private dateUtilService: DateUtilService) {
  }

  /**
   * @description get list of activities from BE (/rest/v1/activities)
   *
   * Custom encoder WebHttpUrlEncodingCodec is needed as the request param does not accept symbol '+'
   */
  getActivities(notifStatus: NotifStatus): Observable<Activities[]> {
    const timeZone = this.dateUtilService.getTimeZone();
    const httpParams = new HttpParams({encoder: new WebHttpUrlEncodingCodecService()})
      .set('tz', timeZone)
      .set('statusFilter', notifStatus);

    return this.http.get<Activities[]>(this.activitiesRestName, {params: httpParams});
  }

  /**
   * @description get types of planned activities
   */
  getActivityTypes(): Observable<ActivitiesTypes[]> {
    return this.http.get<ActivitiesTypes[]>(this.activitiesTypesRestName);
  }

  /**
   * @description reschedule an activity
   */
  reschedule(key, body) {
    const url = this.activitiesRescheduleRestName.replace(/:id/g, key);
    return this.http.post(url, body);
  }

  /**
   * @description pm schedule an activity
   * Note: US Story
   */
  pmSchedule(key, body) {
    const url = this.activitiesPMScheduleRestName.replace(/:id/g, key);
    return this.http.post(url, body);
  }

  /**
   * @description update an activity
   */
  update(body) {
    this.clearCache(this.activitiesUpdateRestName);
    return this.http.put(this.activitiesUpdateRestName, body);
  }

  /**
   * @description get closed activities from BE (/rest/v1/activities?equipmentKey=DE_012345&statusFilter=2)
   */
  getClosedActivities(key: string): Observable<Activities[]> {
    const httpParams = new HttpParams()
      .set('equipmentKey', key)
      .set('statusFilter', NotifStatus.CLOSED);

    return this.http.get<Activities[]>(this.activitiesRestName, {params: httpParams});
  }

  /**
   * @description Get the closed activities details
   */
  getActivityDetails(key: string, customerNumber: string): Observable<ActivitiesDetail> {
    const httpParams = new HttpParams()
      .set('customerNumber', customerNumber);

    const url = this.activitiesDetailsRestName.replace(/:id/g, key);
    return this.http.get<ActivitiesDetail>(url, {params: httpParams});
  }

  clearCache(restName: string) {
    if (!environment.newCacheableHttpClient) {
      this.cacheService.clearIndividualRequest(restName);
    }
    this.http.clearCache(restName);
  }

  getOpenActivitiesCount(key: string): Observable<number> {
    return this.http.get<any>(this.openActivitiesCountRestName.replace(/:key/g, key)).pipe(
      map(r => r.count)
    );
  }
}
