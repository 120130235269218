import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { Vulnerabilities } from '../../../core/models/equipment/equipment-pii-details';
import { SystemUpdatesService } from '../../../core/services/system-updates/system-updates-service';
import { UpdateStatus } from '../../../core/models/update-status';
import { forEach, isEqual } from 'lodash';
import { OverlayComponent } from '../../../shared/components/overlay/overlay.component';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { Equipment } from '../../../core/models/equipment/equipment';
import { SecurityNotifications } from '../../../core/models/securityNotifications/security-notifications';
import { SecurityNotificationsRestService } from '../../../core/rest-services/security-notifications-rest.service';
import { SecurityNotificationsService } from '../../../core/services/security-notifications/security-notifications.service';
import { filter, takeUntil } from 'rxjs/operators';
import { WindowService } from 'app/core/window.service';
import { EquipmentPiiDetailsViewModel } from '../../../core/view-models/equipment-pii-details-view-model';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { AddOmnitureAndRouterStateNameDirective } from '../../../shared/directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';
import { DownloadUtilService } from '../../../core/utils/download-util.service';

const translateKeys = [
  'SECURITY_VULNERABILITY_ASSESSMENTS',
  'SECURITY_SOFTWARE_BILL_OF_MATERIALS',
  'SECURITY_EVALUATION_ONGOING',
  'SECURITY_NO_EVALUATION_RESULTS',
  'YES',
  'NO'
];

@Component({
  selector: 'hl-equipment-security',
  templateUrl: './equipment-security.component.html'
})
export class EquipmentSecurityComponent implements OnInit, OnDestroy {
  @ViewChild('detailOverlay', {static: false})
  detailOverlay: OverlayComponent;

  config: any;
  equipmentKey: string;
  equipmentPiiDetails: EquipmentPiiDetailsViewModel;
  isLoaded: boolean;
  bomExists: boolean;
  vulnerabilitiesExists: boolean;
  updateExists: boolean;
  private _labels = {};
  equipmentItem: Equipment = null;

  securityNotificationDetail: SecurityNotifications;
  currentSelectedIndex = 0;
  paginationItems = 20;
  loadingSecurityNotification = false;
  showDownloadSbom = false;
  selectedDropDown: string = null;
  action = '';

  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  private readonly notificationRequestUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private configService: CountryConfigRestService,
    private translateService: TranslateService,
    private equipmentRestService: EquipmentRestService,
    private equipmentUtilService: EquipmentUtilService,
    private activatedRoute: ActivatedRoute,
    protected systemUpdatesService: SystemUpdatesService,
    protected securityNotificationsRestService: SecurityNotificationsRestService,
    private securityNotificationsService: SecurityNotificationsService,
    private windowService: WindowService,
    private router: Router,
    private downloadUtilService: DownloadUtilService,
    @Inject(DOCUMENT) private document: any
  ) {
  }

  get labels(): any {
    return this._labels;
  }

  get labelVulnerabilityAssessments(): string {
    if (this.equipmentPiiDetails && this.equipmentPiiDetails.vulnerabilities
      && this.equipmentPiiDetails.vulnerabilities.length) {
      return this.equipmentPiiDetails.vulnerabilities.length + ' ' + this.labels.SECURITY_VULNERABILITY_ASSESSMENTS;
    } else {
      return this.labels.SECURITY_VULNERABILITY_ASSESSMENTS;
    }
  }

  ngOnInit(): void {
    this.reset();
    this.init();

    this.translateService
      .get(translateKeys)
      .subscribe(values => (this._labels = values));

    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      this.config = config;
      this.showDownloadSbom = isEqual(config.FEATURE_TOGGLE_SBOM_DOWNLOAD, 'true');
    });

    this.route.params
      .pipe(filter(params => params && params.id))
      .subscribe(({id: equipmentKey}) => {
        this.reset();
        this.equipmentKey = equipmentKey;
        this.equipmentUtilService
          .getEquipmentPiiDetailsViewModel(this.equipmentKey)
          .subscribe(equipmentSecurityInformation => {
            this.equipmentPiiDetails = equipmentSecurityInformation;
            this.expandAndScrollToSelectedSecurityNotif(this.securityNotificationsService.securityId);

            this.isLoaded = true;
            {
              if (
                equipmentSecurityInformation &&
                equipmentSecurityInformation.billOfMaterials &&
                equipmentSecurityInformation.billOfMaterials.length
              ) {
                this.bomExists = true;
              }
            }

            if (
              equipmentSecurityInformation &&
              equipmentSecurityInformation.vulnerabilities &&
              equipmentSecurityInformation.vulnerabilities.length
            ) {
              this.vulnerabilitiesExists = true;
            }

            this.systemUpdatesService
              .getSystemUpdatesForEquipment(this.equipmentKey)
              .subscribe(viewModelResponse => {
                forEach(viewModelResponse, value => {
                  if (
                    value.updateStatus === UpdateStatus.Overdue ||
                    value.updateStatus === UpdateStatus.LongOverdue ||
                    value.updateStatus === UpdateStatus.Pending
                  ) {
                    this.updateExists = true;
                  }
                });
              });
          });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.notificationRequestUnsubscribe$.next();
    this.notificationRequestUnsubscribe$.complete();
  }

  init() {
    this.activatedRoute.params.subscribe((params: any) => {
      this.equipmentUtilService
        .getEquipment(params['id'])
        .subscribe(equipmentResponse => {
          this.equipmentItem = equipmentResponse;
          const tabParam = params['tab'];
          if (tabParam) {
            if (this.equipmentItem) {
              this.router.navigate(
                ['/equipment', this.equipmentItem.key, tabParam],
                {replaceUrl: true}
              );
            }
          }
        });
    });

    this.securityNotificationsService.closeSecurityOverlay$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this.detailOverlay) {
        this.detailOverlay.hide(null);
      }
    });

    this.securityNotificationsService.changeSecurityIdSubject$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((securityId) => {
        this.expandAndScrollToSelectedSecurityNotif(securityId);
      });
  }

  downloadWhitepaper() {
    const path = this.equipmentPiiDetails.securityWhitepaperUrl;
    const tab = this.windowService.nativeWindow.open('', 'Whitepaper');
    this.equipmentRestService.getSecurityWhitepaperUrl(path).subscribe(({url}) => {
      const fileName = path.split('/').pop().trim();
      AddOmnitureAndRouterStateNameDirective
        .triggerDocumentsDownloadTrackingFullUrl(fileName, path);
      tab.open(url, 'Whitepaper', '', true);
    });
  }

  getEvaluationResult(cvssProduct: string, evaluationResult: string) {
    return cvssProduct === null || cvssProduct === 'evaluation ongoing'
      ? this.labels.SECURITY_NO_EVALUATION_RESULTS
      : evaluationResult;
  }

  getCvssProduct(cvssProduct: string) {
    return cvssProduct === null || cvssProduct === 'evaluation ongoing'
      ? this.labels.SECURITY_EVALUATION_ONGOING
      : this.securityNotificationsService.formatCvssProduct(cvssProduct);
  }

  private reset() {
    this.isLoaded = false;
    this.bomExists = false;
    this.vulnerabilitiesExists = false;
    this.updateExists = false;
    this.equipmentPiiDetails = undefined;
  }

  openDetailOverlay(item: Vulnerabilities, index: number) {
    if (!item) {
      return;
    }
    this.setNameOfTheCurrentSelectedItem(item.name);
    this.currentSelectedIndex = index;
    this.getData(item.id);
    this.detailOverlay.show();
    this.equipmentUtilService.updateSecurityDetailStatus('gray');
  }

  getData(notificationID: string) {
    this.notificationRequestUnsubscribe$.next();
    this.loadingSecurityNotification = true;
    this.securityNotificationsRestService
      .getEquipmentNotificationByID(notificationID)
      .pipe(takeUntil(this.notificationRequestUnsubscribe$))
      .subscribe(securityNotification => {
        this.securityNotificationDetail = securityNotification;
        this.loadingSecurityNotification = false;
        this.equipmentUtilService.updateSecurityDetailStatus('gray');
      });
  }

  onClickUpdateSelectionByIndex(index: number) {
    this.currentSelectedIndex = index;
    const vulnerability = this.equipmentPiiDetails.vulnerabilities[this.currentSelectedIndex].vulnerabilityValue;
    const notificationID = vulnerability.id;
    this.setNameOfTheCurrentSelectedItem(vulnerability.name);
    this.getData(notificationID);
    this.equipmentUtilService.updateSecurityDetailStatus('gray');
  }

  setNameOfTheCurrentSelectedItem(name: string) {
    this.securityNotificationDetail = {} as SecurityNotifications;
    this.securityNotificationDetail.name = name;
  }

  expandAndScrollToSelectedSecurityNotif(securityId: string) {
    if (securityId) {
      if (this.equipmentPiiDetails.vulnerabilities) {
        this.equipmentPiiDetails.vulnerabilities = this.equipmentPiiDetails.vulnerabilities.map(value => {
          return {...value, isExpanded: value.vulnerabilityValue.id === securityId};
        });
      }
      this.scrollToSelectedSecurityNotif(securityId);
    }
  }

  private scrollToSelectedSecurityNotif(securityId: string) {
    setTimeout(() => {
      const eqItemPanel = this.document.getElementById('eq-item-panel');
      const itemToScrollTo = this.document.getElementById(securityId);

      if (eqItemPanel && itemToScrollTo) {
        let offsetTop = 0;
        let element = itemToScrollTo;
        do {
          offsetTop += element.offsetTop;
          element = ((element.offsetParent) as HTMLElement);
        }
        while (element && element.id !== 'eq-item-panel');

        const eqItemPanelClientHeight = eqItemPanel.clientHeight / 2;
        const itemToScrollToContentElement = this.document.getElementById(securityId + '_content');
        const itemToScrollToClientHeight = itemToScrollTo.clientHeight / 2 + itemToScrollToContentElement.clientHeight / 2;

        eqItemPanel.scrollTop = offsetTop - eqItemPanelClientHeight + itemToScrollToClientHeight;
        this.securityNotificationsService.securityId = null;
      }
    });
  }

  openDropDown(action: any) {
    this.selectedDropDown = this.selectedDropDown === null ? action : null;
  }

  downloadSbom(type: string) {
    const url = this.equipmentUtilService.generateDownloadSbomUrl(this.equipmentKey, type);
    this.downloadUtilService.downloadFileByUrl(this.equipmentUtilService.generateSbomFileName(this.equipmentItem.siemensId, type), url);
  }
}
