import { Injectable } from '@angular/core';
import * as momentTz from 'moment-timezone';
import * as _ from 'lodash';
import { DatePipeWrapperPipe } from '../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';

@Injectable()
export class DateUtilService {

  constructor(private datePipeWrapperPipe: DatePipeWrapperPipe) {
  }

  /**
   *
   * @description
   *
   * Get the unix time stamp.
   */
  getUTCToUxTimeStamp(input): number {
    return new Date(input).getTime();
  }

  /**
   * @param input a date in format '2016-06-29T03:00:00' or '2016-06-29'
   * @description
   * Get the date from the UTC string timestamp
   */
  getDateFromUTCString(input) {

    if (input.indexOf('T') < 0) {
      // if only yyyy-mm-dd is given, then "new Date(...)" does not
      // interpret the date as a local date, but as a date in timezone +/-0
      input = input + 'T00:00:00';
    }

    const date = new Date(input);
    date.setHours(0);

    return date;
  }

  /**
   * @description
   * Get the timezone based on user browser
   */
  getTimeZone(): string {
    const locale = momentTz.tz.guess();
    return momentTz.tz(locale).format('Z');
  }

  convertDateTimeToStringWithTimezone(dateVal: Date, timeVal: Date) {

    const dateObj = dateVal;
    const hours = timeVal.getHours();
    const min = timeVal.getMinutes();
    const sec = timeVal.getSeconds();
    dateObj.setHours(hours, min, sec);

    const dateInISOString = dateObj.toISOString();
    return dateInISOString.replace(/Z/g, this.getTimeZone());
  }

  /**
   * @description compute the proper date, using current date and rolling month from config
   *
   * @param {Date} date - current date
   * @param {string} rollingMonth - months to be subtracted
   *
   * @returns {Date} new computed date
   */
  getDateByRollingMonth(date: Date, rollingMonth: string): Date {

    const newDate = _.clone(date);
    const rollingMonthNumber = _.parseInt(rollingMonth);
    newDate.setMonth(newDate.getMonth() - rollingMonthNumber);

    return newDate;
  }

  /**
   *
   * @param {string} pattern
   * @param {Date} dateVal
   * @param {Date, optional} timeVal
   * converts given Date Objects to String, if time is given then both will be merged in one DateString
   */
  toStringWithPattern(pattern: string, dateVal: Date, timeVal?: Date): string {
    if (timeVal) {
      const dateObj = this.mergeDayTime(dateVal, timeVal);
      return this.datePipeWrapperPipe.transform(dateObj, pattern);
    } else {
      return this.datePipeWrapperPipe.transform(dateVal, pattern);
    }
  }

  /**
   *
   * @param {Date} day
   * @param {Date} time
   * Merges a given Day with a given Time and returns the merged Object
   */
  mergeDayTime(day: Date, time: Date): Date {
    const dateObj = day;
    const hours = time.getHours();
    const min = time.getMinutes();
    const sec = time.getSeconds();
    dateObj.setHours(hours, min, sec);
    return dateObj;
  }

  getBrowserTimeZone(window: any) {
    return window && typeof (window.Intl) === 'object'
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : this.getTimeZone();
  }

  now() {
    return new Date();
  }

  copy(date: Date) {
    return new Date(date.valueOf());
  }

  getDateTime(date, time): Date {
    const newDate = new Date(Date.parse(date));
    if (Date.parse(time)) {
      const newTime = new Date(Date.parse(time));
      newDate.setHours(newTime.getHours());
      newDate.setMinutes(newTime.getMinutes());
      newDate.setSeconds(0);
    } else {
      newDate.setHours(+time.toString().substr(0, 2));
      newDate.setMinutes(+time.toString().substr(3, 2));
      newDate.setSeconds(0);
    }
    return new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000);
  }
}
