import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hl-intro-slider-gallery',
  templateUrl: './intro-slider-gallery.component.html'
})
export class IntroSliderGalleryComponent implements OnInit {

  @Input() data: any;
  @Input() currentIndex: number;

  constructor() { }

  ngOnInit() {
  }
}
