import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerComponent),
  multi: true
};

@Component({
  selector: 'hl-date-picker',
  templateUrl: './date-picker.component.html',
  providers: [MULTISELECT_VALUE_ACCESSOR]
})
export class DatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  disabled = false;
  @Input()
  labelText: string;
  @Input()
  isTable = false;
  @Input()
  appendToElement: HTMLElement;

  @ViewChild('date', {static: false})
  dateInputElement: ElementRef;

  // Auto convert the timeModel value from a string to a date
  @Input()
  convertModelValue = false;

  prevArrow = '<i class="icon-arrow-left">';
  nextArrow = '<i class="icon-arrow-right">';

  /**
   * model of the datepicker AND final date, that is shown to the outside of the
   * component
   */
  dateModel: Date | null = new Date();

  /**
   * date pattern to generate the date
   */
  datePattern = 'DD.MM.YYYY';
  datePatternFlatpickr = 'd.m.Y';

  @Input()
  ngModel;

  @Input()
  minDate: Date;
  @Input()
  maxDate: Date;
  @Input()
  isRequired = false;
  @Input()
  invalidLabel: string;
  @Input()
  isInvalid: boolean;

  @Output()
  dateModelChange: EventEmitter<String> = new EventEmitter<String>();

  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(private configService: CountryConfigRestService) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.datePattern = configResponse.GENERIC_DATE_PATTERN;
      this.datePatternFlatpickr = this.convertFlatpickrPattern(
        this.datePattern
      );
    });
  }

  // called, when this component decides, that there is a new date picked or entered
  newDateSet(): void {
    this.onModelChange(this.dateModel);
    this.onModelTouched(this.dateModel);
  }

  resetDate(event: Event) {
    event.preventDefault();
    this.dateModel = null;
    this.newDateSet();
  }

  /**
   * called when typing in the input box
   * @param value
   */
  updateDateModel(value) {
    // IE issue - if input element value is not empty fill out it first
    if (_.isEmpty(value)) {
      value = this.dateInputElement.nativeElement.value;
    }

    if (_.isEmpty(value)) {
      this.dateModel = null;
      this.newDateSet();
    } else {
      let date: Date;
      let isDateValid: boolean;

      // parsing date in short format ('L'), based on given locale, e.g. for locale 'de' this means strings like '22.02.2002'
      // the 'L' stuff did not work for 'en' and 'DD/MM/YYYY' dates
      // so we are using the pattern without the locale.
      // but moment.js is not supporting 'dd' or 'yyyy' - that's why we uppercase
      const momentObj = moment(value, this.datePattern.toUpperCase(), true);

      if (momentObj.isValid()) {
        isDateValid = !((this.minDate && !momentObj.isSameOrAfter(this.minDate)) || (this.maxDate && !momentObj.isSameOrBefore(this.maxDate)));

        if (isDateValid) {
          date = momentObj.toDate();
          this.dateModel = date;
        } else {
          this.dateModel = null;
        }

        this.dateModelChange.emit(value);
        this.newDateSet();
      }
    }
  }

  onModelChange: Function = () => {
  }
  onModelTouched: Function = () => {
  }

  /**
   * called if the value of the parent component changes
   * @param value
   */
  writeValue(value: Date): void {
    if (value) {
      this.dateModel = this.convertModelValue
        ? moment(value, this.datePattern).toDate()
        : value;
      this.newDateSet();
    } else if (value == null) {
      this.dateModel = null;
      this.newDateSet();
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  convertFlatpickrPattern(momentPattern: string): string {
    let newPattern: string;
    newPattern = momentPattern.replace('MMM', 'M');
    newPattern = newPattern.replace('DD', 'd');
    newPattern = newPattern.replace('MM', 'm');
    newPattern = newPattern.replace('YYYY', 'Y');
    return newPattern;
  }
}
