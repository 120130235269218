import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'hl-create-sao-order-summary',
  templateUrl: './create-sao-order-summary.component.html'
})
export class CreateSaoOrderSummaryComponent implements OnInit {

  @Input() orderForm: FormGroup;

  constructor() {
  }

  ngOnInit() {
  }

}
