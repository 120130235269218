import { EquipmentDetailsComponent } from './equipment-details.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { WindowService } from '../core/window.service';

@NgModule({
  declarations: [
    EquipmentDetailsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot([{path: 'equipmentDetails.html', component: EquipmentDetailsComponent}])
  ],
  providers: [WindowService],
  bootstrap: [EquipmentDetailsComponent]
})
export class EquipmentDetailsModule {}
