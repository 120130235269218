import { Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { restEndPoint } from '../../../core/core-constants.service';
import { TicketsRestService } from '../../../core/rest-services/tickets-rest.service';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { TicketViewModel } from '../../../core/view-models/ticket-view-model';
import { SpaceValidator } from '../../validators/space.validator';

@Component({
  selector: 'hl-ticket-close-modal',
  templateUrl: './ticket-close-modal.component.html'
})
export class TicketCloseModalComponent extends BaseModalPopup implements OnInit, OnChanges {

  @Input() ticketItem: TicketViewModel;

  datePattern = '';
  dateTimePattern = '';
  headerLabel = '';

  showValidationMessage: boolean;
  isFormSubmitted: boolean;
  showSpinner: boolean;

  translationErrorMessage = 'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';
  // Label text for spinner
  ticketLabelInProgress = 'TICKET_UPDATE_IN_PROGRESS';

  closeTicketForm: FormGroup;
  private ticketCloseRequestText: any;

  constructor(private fb: FormBuilder,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private ticketsRestService: TicketsRestService,
    private ticketsUtilService: TicketsUtilService,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
    this.translateService.get('TICKET_CLOSE_REQUEST_TEXT').subscribe(ticketCloseRequestText => {
      this.ticketCloseRequestText = ticketCloseRequestText;
    });

    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Note: When the modal is opened second time for another item, it shows same item content as before
    // hence we call again init().
    if (changes['ticketItem'] && !changes['ticketItem'].firstChange) {
      this.init();
    }
  }

  init() {
    this.initProperties();
    this.createForm();
  }

  initProperties() {
    this.showValidationMessage = false;
    this.isFormSubmitted = false;
    this.showSpinner = false;
  }

  private createForm() {
    this.closeTicketForm = this.fb.group({
      ticketKey: [this.ticketItem.ticketKey],
      ticketNumber: [this.ticketItem.ticketNumber],
      customerNumber: [this.ticketItem.customerNumber],
      action: ['SAVE'],
      longText: ['', [Validators.required, Validators.maxLength(2000), SpaceValidator.noWhiteSpace]]
    });
  }

  ok() {
    this.isFormSubmitted = true;

    if (this.closeTicketForm.valid) {
      this.showValidationMessage = false;
      this.showSpinner = true;

      // Note:- Here our cache service, only cache the url portion without url params.
      const cacheClearUrl = restEndPoint + 'tickets/' +
        this.ticketItem.ticketKey;

      // prepend TICKET_CLOSE_REQUEST_TEXT
      const longTextWithRequestText = this.ticketCloseRequestText +
        ' - ' + this.closeTicketForm.get('longText').value;

      this.closeTicketForm.patchValue({ longText: longTextWithRequestText });

      this.ticketsRestService.closeTicket(this.closeTicketForm.value, cacheClearUrl).pipe(
        finalize(() => {
          this.showSpinner = false;
          this.hide();

          // emit that ticket has closed so listeners can react
          this.ticketsUtilService.onSuccessfulTicketUpdatedOrClosed();
        }))
        .subscribe((response) => {
          const message = {
            type: 'success',
            isBodyTranslationKey: true,
            body: 'TICKET_CLOSE_SUCCESSFUL'
          };
          this.toasterService.emitToast(message);
        });
    } else {
      this.showValidationMessage = true;
    }
  }
}
