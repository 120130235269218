import { EventEmitter, Injectable } from '@angular/core';
import { WindowService } from '../../window.service';
import { SecurityAdvisoriesRestService } from '../../rest-services/security-advisories-rest.service';
import { Observable, Subject } from 'rxjs';
import { AdvisoryAttachment, AdvisoryAttachmentUploadRequest } from '../../models/securityAdvisories/advisory-attachment';
import { SecurityAdvisory } from '../../models/security-advisory';
import { DownloadUtilService } from '../../utils/download-util.service';

@Injectable()
export class SecurityAdvisoriesService {

  advisoryUpdateSubject = new Subject();

  showCreateAdvisoryModalEmitter = new EventEmitter<SecurityAdvisory>();
  showAdvisoryUploadFilesModalEmitter = new EventEmitter<SecurityAdvisory>();

  showAdminView = true;

  constructor(
    private windowService: WindowService,
    private securityAdvisoriesRestService: SecurityAdvisoriesRestService,
    private downloadUtilService: DownloadUtilService) {
  }

  getAllSecurityAdvisories(): Observable<SecurityAdvisory[]> {
    return this.securityAdvisoriesRestService.getAllSecurityAdvisories();
  }

  getActiveSecurityAdvisories(): Observable<SecurityAdvisory[]> {
    return this.securityAdvisoriesRestService.getActiveSecurityAdvisories();
  }

  showCreateAdvisoryModal(advisory: SecurityAdvisory) {
    this.showCreateAdvisoryModalEmitter.emit(advisory);
  }

  showAdvisoryUploadFilesModal(advisory: SecurityAdvisory) {
    this.showAdvisoryUploadFilesModalEmitter.emit(advisory);
  }

  downloadDocument(id: number, documentType: string, documentName: string, languageCode: string): void {
    this.securityAdvisoriesRestService.getDownloadUrl(id, documentType, languageCode)
      .subscribe(({url}) => {
        this.downloadUtilService.downloadFileByUrl(documentName, url);
      });
  }

  getAttachmentsList(id: number, languageCode: string): Observable<AdvisoryAttachment[]> {
    if (id) {
      return this.securityAdvisoriesRestService.getSecurityAdvisoryAttachments(id, languageCode);
    }
  }

  emitAdvisoryUpdated() {
    this.advisoryUpdateSubject.next();
  }

  isAdminView(): boolean {
    return this.showAdminView;
  }

  getUploadHeaders(body): Observable<AdvisoryAttachmentUploadRequest[]> {
    return this.securityAdvisoriesRestService.getUploadHeaders(body);
  }

  uploadAttachment(url: string, attachment: File, headers) {
    return this.securityAdvisoriesRestService.uploadFileToAkamai(url, attachment, headers);
  }
}
