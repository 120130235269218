import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../../core/services/cache/cacheable-http-client';
import { HttpCacheService } from '../../core/services/cache/http-cache.service';
import { PartnerOrder } from '../models/partner-order';
import { restEndPoint } from '../../core/core-constants.service';
import { HttpResponse } from '@angular/common/http';
import { HttpCacheNewService } from '../../core/services/cache/http-cache-new.service';
import { environment } from '../../../environments/environment';

const partnerOrdersRestName = restEndPoint + 'partnerOrders';

@Injectable()
export class PartnerOrdersRestService {

  constructor(private http: CacheableHttpClient,
    private cacheService: HttpCacheService,
    private httpCacheService: HttpCacheNewService) {
  }

  getPartnerOrders(): Observable<PartnerOrder[]> {
    return this.http.get<PartnerOrder[]>(partnerOrdersRestName);
  }

  clearCache(restName: string) {
    if (!environment.newCacheableHttpClient) {
      this.cacheService.clearIndividualRequest(restName);
    }
    this.http.clearCache(restName);
  }

  modifyCacheEntry(fn: (toBeModified: HttpResponse<PartnerOrder[]>) => PartnerOrder[]) {
    environment.newCacheableHttpClient ?
      this.httpCacheService.modifyEntry(partnerOrdersRestName, fn) :
      this.cacheService.modifyEntry(partnerOrdersRestName, fn);
  }

  postPartnerOrderActions(url: string, body: any) {
    return this.http.put(url, body, {responseType: 'text'});
  }
}
