import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { SafeStyle } from '@angular/platform-browser';
import { defaultImagePath } from '../../../core/core-constants.service';
import { SafeDomPipe } from '../../pipes/safe-dom/safe-dom.pipe';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import * as _ from 'lodash';
import { EquipmentInformation } from '../../../core/models/equipment/equipment-information';
import { EquipmentListViewModel } from '../../../core/view-models/equipment-list-view-model';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, of, Subject } from 'rxjs';
import { EnvironmentConfigRestService } from '../../../core/rest-services/environment-config-rest.service';

@Component({
  selector: 'hl-equipment-image',
  templateUrl: './equipment-image.component.html'
})
export class EquipmentImageComponent implements OnInit, OnChanges, OnDestroy {

  // Note:- Either equipment or equipmentKey is always inputed and both not at the same time
  @Input() equipment: EquipmentListViewModel | EquipmentInformation; // optional
  @Input() equipmentKey: string; // optional
  @Input() large: boolean;

  // img tag attributes
  title: string;
  alt: string;
  defaultImgSrc: string;
  backgroundImageStyle: SafeStyle;

  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(private configService: CountryConfigRestService,
    private safeDomPipe: SafeDomPipe,
    private equipmentUtilService: EquipmentUtilService,
    private environmentConfigRestService: EnvironmentConfigRestService) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['equipment'] && !changes['equipment'].firstChange) {
      this.equipmentKey = undefined;
      this.init();
    }
    if (changes['equipmentKey'] && !changes['equipmentKey'].firstChange) {
      this.equipment = undefined;
      this.init();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.defaultImgSrc = defaultImagePath;
    combineLatest([
      this.configService.getSpecificCountryConfig(this.getCountry()),
      this.environmentConfigRestService.getEnvironmentConfig(),
      this.equipment ? of(this.equipment) : this.equipmentUtilService.loadEquipmentByKey(this.equipmentKey)
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(
      ([configResponse, environmentConfig, equipmentResponse]: [any, any, EquipmentInformation]) => {
        if (!!equipmentResponse) {
          this.equipment = equipmentResponse;
          this.setEquipmentImageUrl(configResponse, environmentConfig, equipmentResponse);
        }
      });
  }

  /**
   *
   * @param config
   * @param environmentConfig
   * @param equipment
   * @description set the image url and img tag properties
   */
  setEquipmentImageUrl(config, environmentConfig, equipment) {

    this.title = equipment.productName ? equipment.productName : equipment.key;
    this.alt = this.title;

    const origImgUrl = this.generateImageUrlFromConfigAndEquipmentProp(equipment, config, environmentConfig);

    // split Url to path and name, then replace : with - only in name and combine to imgSrc
    const splitPosition = origImgUrl.lastIndexOf('/') + 1;
    const path = origImgUrl.substring(0, splitPosition);
    const name = origImgUrl.substring(splitPosition).replace(/:/g, '-');
    const unsafeUrl = path + name;

    // safe background image style
    this.backgroundImageStyle = this.safeDomPipe.transform('url(' + unsafeUrl + ')', 'style');
  }

  generateImageUrlFromConfigAndEquipmentProp(imgEquipment, config, environmentConfig) {
    let url = environmentConfig.EQUIPMENT_IMAGE_REPO;
    if (imgEquipment.modality && imgEquipment.materialNumber) {
      const suffix = config.EQUIPMENT_IMAGE_SUFFIX;
      const largeKey = this.large ? config.EQUIPMENT_LARGE_INDICATOR : '';
      const trimmedMaterialNumber = imgEquipment.key;
      const trimmedMaterialNumberTrimmed = _.trimStart(trimmedMaterialNumber, '0');
      const fileName = trimmedMaterialNumberTrimmed + largeKey + suffix;
      url += fileName;
    }
    return url;
  }

  private getCountry(): string {
    return this.equipment ? this.equipment.countryCode : undefined;
  }
}
