import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Series } from './diagram';
import { BarVerticalComponent } from '@swimlane/ngx-charts';

@Component({
  selector: 'hl-diagram-grouped-bars',
  template: `
    <ngx-charts-bar-horizontal-2d *ngIf="orientation === 'horizontal'; else vertical" #chart
      [view]="view || ''" [roundDomains]="true" [gradient]="gradient" [customColors]="customColors"
      [results]="results" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
      [legendPosition]="legendPosition" [showGridLines]="showGridLines"
      [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel" [xScaleMax]="xScaleMax" [scheme]="scheme"
      [xAxisTickFormatting]="xAxisTickFormatting" [animations]="animations"
      [barPadding]="barPaddingHorizontal" [groupPadding]="groupPaddingHorizontal"
      (select)="onSelected($event)"></ngx-charts-bar-horizontal-2d>
    <ng-template #vertical>
      <ngx-charts-bar-vertical-2d #chart [view]="view || ''" [roundDomains]="true"
        [gradient]="gradient" [customColors]="customColors" [results]="results" [xAxis]="showXAxis"
        [yAxis]="showYAxis" [legend]="showLegend" [legendPosition]="legendPosition"
        [showGridLines]="showGridLines" [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
        [yScaleMax]="yScaleMax" [scheme]="scheme" [yAxisTickFormatting]="yAxisTickFormatting"
        [animations]="animations" [barPadding]="barPaddingVertical"
        [groupPadding]="groupPaddingVertical"
        (select)="onSelected($event)"></ngx-charts-bar-vertical-2d>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagramGroupedBarsComponent {
  @ViewChild('chart', {static: false})
  private chart: BarVerticalComponent;
  customColors: object[] = [];

  /**
   * The number of results to start the dynamic padding (group and bar padding)
   */
  @Input() minResultsForDynamicPadding: number;

  @Input() scheme: any;
  @Input() animations: boolean;
  @Input() orientation: string;
  @Input() showGridLines = true;
  @Input() gradient = true;
  @Input() showDataLabel = false;
  @Input() results: Series[] = [];
  /**
   * Options for a view of the diagram as array with [width,height]
   *
   * @type {[number, number]}
   * @memberof DiagramComponent
   */
  @Input() view: number[] = null;

  @Input() showXAxis = true;
  @Input() showXAxisLabel = false;
  @Input() xAxisLabel: string;
  @Input() xScaleMax: number;
  @Input() xAxisTickFormatting: any;

  @Input() showYAxis = true;
  @Input() showYAxisLabel = false;
  @Input() yAxisLabel: string;
  @Input() yScaleMax: number;
  @Input() yAxisTickFormatting: any;

  @Input() showLegend = false;
  @Input() legendPosition = 'below';

  /**
   * Emitted when any bar of a group is clicked. The data contains the name of clicked series.
   */
  @Output() onSelect: EventEmitter<{ series }> = new EventEmitter<{ series }>();

  constructor() {
  }

  get barPaddingVertical(): number {
    return this.canCalculate ? Math.ceil(this.dimension * .01) : 45;
  }

  get groupPaddingVertical(): number {
    return this.canCalculate ? Math.ceil(Math.sqrt(this.dimension) / this.getResultsLength() * 5) : 45;
  }

  private getResultsLength() {
    return this.results.length;
  }

  get barPaddingHorizontal(): number {
    const barPadding = 5;
    return this.canCalculate ?
      this.results.length > this.minResultsForDynamicPadding ?
        barPadding :
        barPadding + (this.minResultsForDynamicPadding - this.results.length) * barPadding * 0.1 :
      0;
  }

  get groupPaddingHorizontal(): number {
    const groupPadding = 20;
    return this.canCalculate ?
      this.results.length > this.minResultsForDynamicPadding ?
        groupPadding :
        groupPadding + (this.minResultsForDynamicPadding - this.results.length) * groupPadding * 0.1 :
      0;
  }

  get dimension() {
    return this.orientation === 'horizontal' ? this.chart.height : this.chart.width;
  }

  get canCalculate() {
    return this.chart && this.dimension && this.results && this.results.length && this.results[0].series && this.results[0].series.length;
  }

  onSelected($event) {
    this.onSelect.emit($event);
  }
}
