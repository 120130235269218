import { Pipe, PipeTransform } from '@angular/core';
import { TableHeaderCell } from '../../../spr/configuration/table/configuration-table.component';

@Pipe({
  name: 'sprAlignClass'
})
export class SprAlignClassPipe implements PipeTransform {
  transform(headerCell: TableHeaderCell, styleClass: string): string {
    if (styleClass && headerCell && headerCell.config && headerCell.config.regularCellTextAlign) {
      return styleClass + (styleClass.endsWith('-') ? '' : '-') + headerCell.config.regularCellTextAlign;
    }
    return '';
  }
}
