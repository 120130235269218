import { Input, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { BaseModalPopup } from '../../core/base-class/base-modal-popup';
import { ToasterService } from '../../core/component-communication-services/toaster/toaster.service';
import { AttachmentComponent } from '../../shared/form-group/attachment/attachment.component';
import { DatePipeWrapperPipe } from '../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { CloseWithdrawPartnerOrderEventService } from '../component-communication-services/close-withdraw-partner-order-event.service';
import { PartnerOrder } from '../models/partner-order';
import { PartnerOrdersRestService } from '../rest-services/partner-orders-rest.service';

const partnerOrderBEDatePattern = 'YYYY-MM-DD';

export abstract class BasePartnerOrderForm extends BaseModalPopup {

  @Input() partnerOrder: PartnerOrder;
  @ViewChild(AttachmentComponent, { static: false }) attachment: AttachmentComponent;

  // validation/error message (also like that in old implementation)
  translationErrorMessage = 'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';

  // form initially not submitted
  isFormSubmitted = false;
  showValidationMessage = false;

  modalForm: FormGroup;

  toasterService: ToasterService;
  partnerOrdersRestService: PartnerOrdersRestService;
  closeWithdrawPartnerOrderEventService: CloseWithdrawPartnerOrderEventService;
  datePipeWrapperPipe: DatePipeWrapperPipe;

  constructor(renderer: Renderer2,
              partnerOrdersRestService: PartnerOrdersRestService,
              toasterService: ToasterService,
              closeWithdrawPartnerOrderEventService: CloseWithdrawPartnerOrderEventService,
              datePipeWrapperPipe: DatePipeWrapperPipe) {
    super(renderer);
    this.toasterService = toasterService;
    this.partnerOrdersRestService = partnerOrdersRestService;
    this.closeWithdrawPartnerOrderEventService = closeWithdrawPartnerOrderEventService;
    this.datePipeWrapperPipe = datePipeWrapperPipe;
  }

  abstract getFormControl();

  abstract getActionUrl();

  abstract getToasterTranslationKey();

  init() {
    this.createForm();
  }

  ok() {
    // set the boolean flag for form submission to true for showing validation errors.
    this.isFormSubmitted = true;

    // If the form is valid, then make a request to BE
    if (this.modalForm.valid) {
      this.showValidationMessage = false;

      // show the spinner when form is submitted
      this.showSpinner = true;

      this.makeBEFormActionRequest();

    } else {
      this.showValidationMessage = true;
    }
  }

  hide() {
    this.createForm();
    if (this.attachment) {
      this.attachment.clearAttachments();
    }
    this.isFormSubmitted = false;
    super.hide();
  }

  makeBEFormActionRequest() {

    // Send only date(localized format) to BE
    if (this.modalForm.get('closureDate').value) {
      // Note:- BE SAP expect the date to be send as a string, and in this 'yyyy-MM-dd' hard coded format.
      const localizedDate = this.datePipeWrapperPipe.transform(this.modalForm.get('closureDate').value, partnerOrderBEDatePattern);
      this.modalForm.patchValue({closureDate: localizedDate});
    }

    this.partnerOrdersRestService.postPartnerOrderActions(this.getActionUrl(), this.modalForm.value).pipe(
      finalize(() => {
        this.hide();
      }))
      .subscribe(() => {
        this.closeWithdrawPartnerOrderEventService.emitPartnerOrderRemoved(this.partnerOrder);
        this.toasterService.showSuccessToaster(true, this.getToasterTranslationKey());
      });
  }

  private createForm() {
    this.modalForm = this.getFormControl();
  }
}
