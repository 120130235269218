import { HttpCacheService } from '../services/cache/http-cache.service';
import { TicketTitle } from '../models/tickets/ticket-title';
import { TicketSalutation } from '../models/tickets/ticket-salutation';
import { TicketTypes } from '../models/tickets/ticket-types';
import { HttpParams } from '@angular/common/http';
import { TicketProblemSeverity } from '../models/tickets/ticket-problem-severity';
import { NotifStatus, restEndPoint } from '../core-constants.service';
import {
  problemSeveritiesRestName,
  ticketCloseRestName,
  ticketCreateRestName,
  ticketsAllRestName,
  ticketSalutationsRestName,
  ticketsHistoryRestName,
  ticketsIndividualRestName,
  ticketTitlesRestName,
  ticketTypedModalities,
  ticketTypesRestName,
  ticketUpdateRestName,
  ticketServiceReportsRestName
} from './ticket-rest-constants.service';
import { equipmentStatusRestName } from './equipment-rest-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Ticket } from '../models/tickets/ticket';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ModalityTypeTicketTypeRel } from '../models/tickets/modality-type-ticket-type-rel';
import { TimezoneApiModel } from '../models/tickets/timezone-api-model';
import { environment } from '../../../environments/environment';
import { ClearCacheUtilService } from '../utils/clear-cache-util.service';

const timezoneAPI = 'https://timezoneapi.io/api/address/?';

@Injectable()
export class TicketsRestService {
  // fast contact
  singleTicketRestName = restEndPoint + 'tickets/single';

  constructor(private http: CacheableHttpClient,
    private cacheService: HttpCacheService,
    private clearCacheUtilService: ClearCacheUtilService) {
  }

  /**
   * @description get list of tickets from BE (/rest/v1/tickets?statusFilter=1)
   */
  getTickets(notifStatus: NotifStatus): Observable<Ticket[]> {

    const httpParams = new HttpParams().set('statusFilter', notifStatus);

    return this.http.get<Ticket[]>(ticketsAllRestName, {params: httpParams});
  }

  /**
   * @description get a single ticket from BE
   */
  getTicket(ticketKey: string, customerNumber: string, equipmentKey: string): Observable<Ticket> {
    const url = ticketsIndividualRestName.replace(/:id/, ticketKey);

    const httpParams = new HttpParams()
      .set('customerNumber', customerNumber)
      .set('equipmentKey', equipmentKey);
    return this.http.get<Ticket>(url, {params: httpParams});
  }

  /**
   * @description get ticket history for an equipment
   */
  getTicketHistory(equipmentKey: string, dateStartFilter: string, dateEndFilter: string): Observable<Ticket[]> {

    let httpParams = new HttpParams()
      .set('equipmentKey', equipmentKey)
      .set('statusFilter', '3');

    if (dateStartFilter) {
      httpParams = httpParams.set('dateStartFilter', dateStartFilter);
    }

    if (dateEndFilter) {
      httpParams = httpParams.set('dateEndFilter', dateEndFilter);
    }

    return this.http.get<Ticket[]>(ticketsHistoryRestName, {params: httpParams, cache: false});
  }

  /**
   * @description get severities for tickets
   */
  getProblemSeverities(): Observable<TicketProblemSeverity[]> {
    return this.http.get<TicketProblemSeverity[]>(problemSeveritiesRestName);
  }

  /**
   * @description get different ticket types from BE request
   * Note:- This is not the same like in TicketsUtilService
   */
  getTicketTypes(): Observable<TicketTypes[]> {
    return this.http.get<TicketTypes[]>(ticketTypesRestName);
  }

  /**
   * @description get salutation for create ticket screen and update ticket
   * Note:- It is returned by calling /rest/v1/tickets/salutations and hence it is bound to tickets and
   * not users.
   */
  getContactsSalutation(): Observable<TicketSalutation[]> {
    return this.http.get<TicketSalutation[]>(ticketSalutationsRestName);
  }

  /**
   * @description get titles for create ticket screen and update ticket
   * Note:- It is returned by calling /rest/v1/tickets/titles and hence it is bound to tickets and
   * not users.
   */
  getContactsTitle(): Observable<TicketTitle[]> {
    return this.http.get<TicketTitle[]>(ticketTitlesRestName);
  }

  postCreateTicket(body): Observable<Object> {
    // clear tickets cache -> statusFilter=1
    this.clearCache(ticketsAllRestName);

    // clear equipment status cache -> equipments/status
    this.clearCacheUtilService.clear(equipmentStatusRestName);

    return this.http.post(ticketCreateRestName, body);
  }

  updateTicket(body, cacheUrl) {

    // clear single ticket individual request
    this.clearCache(cacheUrl);
    return this.http.put(ticketUpdateRestName, body);
  }

  closeTicket(body, cacheUrl) {

    // clear single ticket individual request
    this.clearCache(cacheUrl);
    return this.http.put(ticketCloseRestName, body);
  }

  clearCache(restName: string) {
    if (!environment.newCacheableHttpClient) {
      this.cacheService.clearIndividualRequest(restName);
    }
    this.http.clearCache(restName);
  }

  getTicketTypesAndSeverityOptions(update?: boolean): Observable<ModalityTypeTicketTypeRel[]> {
    if (update) {
      const httpParams = new HttpParams()
        .set('mode', 'update');
      return this.http.get<ModalityTypeTicketTypeRel[]>(ticketTypedModalities, {params: httpParams});
    }
    return this.http.get<ModalityTypeTicketTypeRel[]>(ticketTypedModalities);
  }

  getTimezone(city: string, state: string): Observable<TimezoneApiModel> {
    const url = timezoneAPI + city + '+' + state;
    return this.http.get<TimezoneApiModel>(url);
  }

  getServiceReportsForTicket(ticketNumber: string): Observable<any[]> {
    return this.http.get<any[]>(ticketServiceReportsRestName.replace(/:id/, ticketNumber));
  }
}
