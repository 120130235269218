import { Pipe, PipeTransform } from '@angular/core';
import { NOT_AVAILABLE } from '../diagram';

@Pipe({
  name: 'getCountWithUnit'
})
export class GetCountWithUnitPipe implements PipeTransform {

  transform(count: any, unit: string): string {
    if (count === '' && unit === '') {
      return NOT_AVAILABLE;
    }
    return !!(unit) && count !== NOT_AVAILABLE ? count + unit : count;
  }
}
