import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  TemplateRef
} from '@angular/core';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import * as _ from 'lodash';
import { ConfigurationItem } from '../../../core/models/spr/basic-input-configuration/configuration-item';

export enum TableCellType {
  STRING, TEXT_INPUT, DROPDOWN, TIME, CONTENT, CALENDAR, CHECKBOX, MULTI_SELECT, TEXT_AREA
}

export enum TableRowType {
  NORMAL, DEFAULT, ADDING
}

export interface TableColumnConfig {
  headerCellTextAlign?: 'right' | 'left' | 'center';
  regularCellTextAlign?: 'right' | 'left' | 'center';
  options?: DropdownOptions[];
  calendarMinDate?: Date;
  calendarMaxDate?: Date;
  isMandatory?: boolean;
  isUnique?: boolean;
  onChange?: (row: TableRow, cell: TableCell) => void;
  disabled?: boolean;
  defaultContent?: string;
  defaultContentArray?: string[];
  sizeType?: 'wide';
  selectboxValueOrder?: boolean
}

export interface TableHeaderCell {
  title: string;
  cellType: TableCellType;
  config?: TableColumnConfig;
}

export interface TableRow {
  id: -1 | number;
  cells: TableCell[];
  default?: boolean;
  inUseInfo?: string;
}

export interface TableCell {
  content: string;
  contentArray?: string[];
  hiddenValue?: any;
  isValid?: boolean;
  disabled?: boolean;
  customOptions?: DropdownOptions[];
  isMandatory?: boolean;
}

@Component({
  selector: 'hl-configuration-table',
  templateUrl: './configuration-table.component.html'
})
export class ConfigurationTableComponent implements AfterViewInit {
  readonly stringType = TableCellType.STRING;
  readonly textInputType = TableCellType.TEXT_INPUT;
  readonly dropdownType = TableCellType.DROPDOWN;
  readonly timeType = TableCellType.TIME;
  readonly externalContentType = TableCellType.CONTENT;
  readonly calendarType = TableCellType.CALENDAR;
  readonly checkboxType = TableCellType.CHECKBOX;
  readonly multiSelectType = TableCellType.MULTI_SELECT;
  readonly textAreaType = TableCellType.TEXT_AREA;

  readonly normalRowType = TableRowType.NORMAL;
  readonly defaultRowType = TableRowType.DEFAULT;
  readonly addingRowType = TableRowType.ADDING;

  @ContentChild(TemplateRef, {static: false}) contentTemplate: TemplateRef<any>;
  @Input() defaultRow: TableRow;
  @Input() showHeader = true;
  @Input() enableDelete = false;
  @Output() onDelete: EventEmitter<TableRow> = new EventEmitter();
  @Input() enableAdd = true;
  @Input() highlightCells = false;
  showContent = false;
  viewInitialized = false;

  invalidRows: TableRow[] = [];
  addingRowActive = false;
  private addingRowChanged = false;

  kpiNameValidationList: ConfigurationItem[];

  constructor(
    private renderer: Renderer2
  ) {
  }

  private _addingRow: TableRow;

  get addingRow(): TableRow {
    return this._addingRow;
  }

  @Input()
  set addingRow(addingRow: TableRow) {
    if (addingRow && addingRow.cells && addingRow.cells.length) {
      this._addingRow = addingRow;
    } else {
      this.resetAddingRow();
    }
  }

  private _changedRows: TableRow[];

  get changedRows(): TableRow[] {
    return this._changedRows;
  }

  @Input()
  set changedRows(value: TableRow[]) {
    this._changedRows = value;
  }

  private _headers: TableHeaderCell[];

  get headers(): TableHeaderCell[] {
    return this._headers;
  }

  @Input()
  set headers(headers: TableHeaderCell[]) {
    this._headers = headers;
    this.resetAddingRow();
    this.checkShowContent();
  }

  private _rows: TableRow[];

  get rows(): TableRow[] {
    return this._rows;
  }

  @Input()
  set rows(rows: TableRow[]) {
    this._rows = rows;
    this.checkShowContent();
  }

  isRowUnchanged(row: TableRow): boolean {
    if (!row || !row.cells || !this._headers || this._headers.length !== row.cells.length) {
      return true;
    }

    return row.cells.every((cell, i) => {
      const headerCellConfig = this._headers[i] && this._headers[i].config;

      if (headerCellConfig && (headerCellConfig.defaultContent || headerCellConfig.defaultContentArray)) {
        return !!((headerCellConfig.defaultContent && cell.content === headerCellConfig.defaultContent) ||
          (headerCellConfig.defaultContentArray && _.isEqual(cell.contentArray, headerCellConfig.defaultContentArray)));
      }

      return !(!!cell.content || !_.isEmpty(cell.contentArray));
    });
  }

  ngAfterViewInit(): void {
    this.viewInitialized = true;
    this.setupHeaderForIE();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setupHeaderForIE();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick() {
    this.setupHeaderForIE();
  }

  setupHeaderForIE() {
    if (this.viewInitialized && this.isIE()) {
      this.resizeHeader();
      this.calculateTheadPosition();
    }
  }

  isIE() {
    return window.navigator.userAgent.toLowerCase().indexOf('trident') > -1;
  }

  resizeHeader() {
    _.forEach(this._headers, (cell, index) => {
      const tdElement = document.querySelector('td#td-' + index);
      const thElement = document.querySelector('th#th-' + index);

      if (tdElement.clientWidth !== thElement.clientWidth) {
        this.renderer.setStyle(thElement, 'width', tdElement.clientWidth + 'px');
        this.renderer.setStyle(thElement, 'min-width', tdElement.clientWidth + 'px');

        this.applyElWidthToEl('td#td-', 'th#th-', index);
      }

      this.applyElWidthToEl('th#th-', 'td#td-', this._headers.length);
    });
  }

  calculateTheadPosition() {
    const theadElement = document.getElementById('thead');
    const tableElement = document.getElementById('scrollableTable');
    const rect = tableElement.getBoundingClientRect();

    const scrollTopTable = window.pageYOffset || document.documentElement.scrollTop;

    this.renderer.setStyle(theadElement, 'top', rect.top + scrollTopTable - (theadElement.clientHeight) + 'px');
    this.renderer.setStyle(theadElement, 'width', tableElement.clientWidth - 36 + 'px');
  }

  applyElWidthToEl(referenceElName: string, applyElName: string, idNumber: number) {
    const referenceElement = document.querySelector(referenceElName + idNumber);
    const applyElement = document.querySelector(applyElName + idNumber);

    if (referenceElement.clientWidth !== applyElement.clientWidth) {
      this.renderer.setStyle(referenceElement, 'width', applyElement.clientWidth + 'px');
      this.renderer.setStyle(referenceElement, 'min-width', applyElement.clientWidth + 'px');
    }
  }

  checkShowContent() {
    this.showContent = this._rows && this._headers && !!this._headers.length && (
      (this.showHeader && this._rows.every(row => row && row.cells && row.cells.length === this._headers.length)) ||
      !this.showHeader
    );
  }

  delete(row: TableRow) {
    this.onDelete.emit(row);
  }

  getDropdownOptions(row: TableRow, index: number): DropdownOptions[] {
    return row && row.cells[index].customOptions ? row.cells[index].customOptions :
      (this._headers && this._headers[index] &&
        this._headers[index].config && this._headers[index].config.options) || [];
  }

  cellChange = (row: TableRow, index: number) => {
    const cell = row.cells[index];
    this.validateRegularCell(cell, index);

    this.validateInvalidUniqueCells(row, cell, index);

    const changedRowIndex = this._changedRows.indexOf(row);
    if (changedRowIndex !== -1) {
      this._changedRows.splice(changedRowIndex, 1);
    }
    this._changedRows.push(row);

    if (this._headers[index].config.isUnique) {
      this.addRowToTable();
    }

    if (this._headers[index].config && this._headers[index].config.onChange) {
      this._headers[index].config.onChange(row, cell);
    }
  }

  validateInvalidUniqueCells(row, cell, index) {
    if (this._headers[index].config.isUnique) {
      if (cell.isValid) {
        this.filterOutInvalidRowById(row.id);
      }

      _.forEach(this.invalidRows, (invalidRow) => {
        if (invalidRow.id !== row.id) {
          this.filterOutInvalidRowById(invalidRow.id);
          if (invalidRow.id === -1) {
            this.validateAddingCell(invalidRow.cells[index], index);
          } else {
            this.validateRegularCell(invalidRow.cells[index], index);
          }
        }
      });
    }
  }

  filterOutInvalidRowById(id: number) {
    this.invalidRows = this.invalidRows.filter(rowInvalid => rowInvalid.id !== id);
  }

  getAddingRowClassList(): string | string[] {
    return this.addingRowChanged ? ['table_background_white', 'bg-color-gray-tint-5'] : 'spr-table__empty-row';
  }

  addingCellChange = (row: TableRow, index: number) => {
    this.validateAddingCell(row.cells[index], index);
    this.validateInvalidUniqueCells(row, row.cells[index], index);

    this.addingRowActive = this.isAddingRowActive();
    this.addingRowChanged = !this.isRowUnchanged(this._addingRow);

    this.addRowToTable();

    if (this._headers[index].config && this._headers[index].config.onChange) {
      this._headers[index].config.onChange(row, row.cells[index]);
    }
  }

  addRowToTable() {
    if (this.enableAdd && this.isAddingRowCellsValid()) {
      this._addingRow.id = -2;
      this._rows.push(this._addingRow);
      this._changedRows.push(this._addingRow);
      this.resetAddingRow();
      this.addingRowActive = false;
    }
  }

  isAddingRowActive(): boolean {
    return !this._addingRow.cells.every((cell, i) =>
      !cell.content ||
      (this._headers[i].config.defaultContent && cell.content === this._headers[i].config.defaultContent) ||
      (this._headers[i].config.defaultContentArray &&
        cell.contentArray.length === this._headers[i].config.defaultContentArray.length &&
        cell.contentArray.every((value, index) =>
          value === this._headers[i].config.defaultContentArray[index]))
    );
  }

  isAddingRowValid(): boolean {
    return this.enableAdd && (!this.addingRowActive || this.isAddingRowCellsValid());
  }

  isAddingRowCellsValid(): boolean {
    return this._addingRow.cells.every((cell, idx) => cell.isValid || !this.isCellMandatory(cell, idx));
  }

  validateTable(kpiNameValidationList?: ConfigurationItem[]) {
    this.kpiNameValidationList = kpiNameValidationList;

    this.validateRows();
    this.validateChangedRows();
  }

  validateChangedRows() {
    if (this._changedRows) {
      this._changedRows.forEach(row => this.validateChangedRow(row));
    }
  }

  validateRows() {
    if (this._rows) {
      this._rows.forEach(row => this.validateRow(row));
    }
  }

  validateRow(row: TableRow) {
    row.cells.forEach((cell, index) => this.validateRegularCell(cell, index));
  }

  validateChangedRow(changedRow: TableRow) {
    changedRow.cells.forEach((cell, index) => this.validateRegularCell(cell, index));
  }

  validateRegularCell(cell: TableCell, index: number) {
    this.validateCell(cell, index, 1);
  }

  validateAddingCell(cell: TableCell, index: number) {
    this.validateCell(cell, index, 0, -1);
  }

  isChangedTableValid(): boolean {
    return !!this._changedRows && this._changedRows.length > 0 &&
      this._changedRows.every(row => row.cells && row.cells.every(cell => cell && cell.isValid));
  }

  getOccurrence(index: number, value: string, rowIndex?: number): number {
    const invalidRows = this._rows.filter((row) => this.isCellContentEqual(row, index, value) || this.isLocatedInExtraValidationList(value));
    let invalidAddingRow = null;
    if (this.addingRow) {
      invalidAddingRow = this.isCellContentEqual(this.addingRow, index, value) ? this.addingRow : null;
    }

    this.updateInvalidRowsValue(invalidRows, invalidAddingRow);

    return rowIndex !== -1 && invalidAddingRow ? invalidRows.length + 1 : invalidRows.length;
  }

  isCellContentEqual(row: TableRow, index: number, value): boolean {
    return row.cells[index].content.trim().toLocaleLowerCase() === value.trim().toLocaleLowerCase();
  }

  updateInvalidRowsValue(rows: TableRow[], addingRow: TableRow) {
    if (rows.length > 1 || (addingRow && rows.length > 0)) {
      _.forEach(rows, row => {
        if (this.isRowNotInvalid(row)) {
          this.invalidRows.push(row);
        }
      });

      if (addingRow && this.isRowNotInvalid(addingRow)) {
        this.invalidRows.push(addingRow);
      }
    }
  }

  isRowNotInvalid(row: TableRow): boolean {
    return _.isEmpty(this.invalidRows) ? true : !this.invalidRows.find((invalidRow) =>
      invalidRow.id === row.id);
  }

  isLocatedInExtraValidationList(value: string): boolean {
    return this.kpiNameValidationList && !!this.kpiNameValidationList.find((kpi) => kpi.name === value);
  }

  getCalendarMaxDate(headerIndex: number): Date {
    return this._headers[headerIndex].config && this._headers[headerIndex].config.calendarMaxDate;
  }

  getCalendarMinDate(headerIndex: number): Date {
    return this._headers[headerIndex].config && this._headers[headerIndex].config.calendarMinDate;
  }

  isChecked(checked: string): boolean {
    return checked === 'true';
  }

  isCellMandatory(cell: TableCell, index: number): boolean {
    return !!(cell.isMandatory !== undefined ? cell.isMandatory : (this._headers[index].config && this._headers[index].config.isMandatory));
  }

  updateCheckboxValue(row: TableRow, changeTrigger: (row: TableRow, index: number) => void, index: number) {
    const cell = row.cells[index];
    cell.content = cell.content === 'true' ? 'false' : 'true';
    changeTrigger(row, index);
  }

  updateTextAreaValue(row: TableRow, changeTrigger: (row: TableRow, index: number) => void, index: number, content, component) {
    if (component.textAreaField && component.textAreaField.nativeElement) {
      let textAreaLineHeight = 20;
      let textAreaHeight = component.textAreaField.nativeElement.scrollHeight;

      component.textAreaField.nativeElement.rows = Math.floor(textAreaHeight / textAreaLineHeight);
    }

    if (content !== undefined) {
      const cell = row.cells[index];
      cell.content = content;

      changeTrigger(row, index);
    }
  }

  private validateCell(cell: TableCell, index: number, occurrence: number, rowIndex?: number) {
    cell.isValid = ((this._headers[index].config && this._headers[index].config.isUnique) ?
      this.getOccurrence(index, cell.content, rowIndex) === occurrence : true) &&
      (!this.isCellMandatory(cell, index) || !!cell.content || (!!cell.contentArray && !!cell.contentArray.length));
  }

  private resetAddingRow() {
    this._addingRow = {
      id: -1,
      cells: this._headers ? this._headers.map((header) =>
        ({
          content: header.config && header.config.defaultContent ? header.config.defaultContent : '',
          contentArray: header.config && header.config.defaultContentArray ? [...header.config.defaultContentArray] : [],
          isMandatory: !!(header.config && header.config.isMandatory),
          disabled: !!(header.config && header.config.disabled),
          isValid: !!(header.config && (header.config.defaultContent || header.config.defaultContentArray))
        })
      ) : []
    };
    this.addingRowChanged = false;
    this.addingRowActive = false;
  }
}
