import { Logout } from '../../models/logout';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LogoutService {

  // Observable logout sources
  private logoutSource = new Subject<Logout>();

    // Observable logout streams
  logoutSource$ = this.logoutSource.asObservable();

  constructor() { }

  // Service logout commands
  emitLogout(logoutUrl: Logout) {
    this.logoutSource.next(logoutUrl);
  }

}
