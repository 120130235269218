import { OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export abstract class BaseModalPopup implements OnDestroy {
  @ViewChild('childModal', { static: false })
  childModal: ModalDirective;
  showSpinner = false;
  protected bodyId: string;
  protected readonly unsubscribe$: Subject<void> = new Subject<void>();
  private readonly unsubscribeHide$: Subject<void> = new Subject<void>();

  // this method need to be implemented by derived class
  abstract ok();

  constructor(protected renderer: Renderer2) {}

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.unsubscribeHide$.next();
    this.unsubscribeHide$.complete();
  }

  show(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.renderer.addClass(document.body, 'overflow-hidden');
    this.childModal.show();

    if (this.unsubscribeHide$.observers.length === 0) {
      this.childModal.onHide
        .pipe(takeUntil(this.unsubscribeHide$))
        .subscribe(() => {
          this.renderer.removeClass(document.body, 'overflow-hidden');
        });

      this.childModal.onHidden
        .pipe(takeUntil(this.unsubscribeHide$))
        .subscribe(() => {
          this.showSpinner = false;
        });
    }
  }

  hide() {
    this.childModal.hide();
    if (this.bodyId) {
      Array.from(document.querySelectorAll(`#${this.bodyId}`)).forEach(e => {
        if (typeof e.scrollTo !== 'undefined') {
          e.scrollTo(0, 0);
        }
      });
    }
  }
}
