import { WindowService } from '../../../core/window.service';
import { Component, Renderer2, Input } from '@angular/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';

@Component({
  selector: 'hl-simulator-modal',
  templateUrl: './simulator-modal.component.html'
})
export class SimulatorModalComponent extends BaseModalPopup {

  @Input() isRouteBackRequired: boolean;

  window = this.windowService.nativeWindow;

  constructor(renderer: Renderer2,
    private windowService: WindowService) {
    super(renderer);
  }

  ok() {
    this.hide();

    if (this.isRouteBackRequired) {
      this.window.history.back();
    }
  }
}
