import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { OverlayCommunicationService } from '../core/component-communication-services/overlay-close/overlay-communication.service';
import { CancelModalComponent } from '../shared/modal-popup/cancel-modal/cancel-modal.component';
import { NotificationsTabComponent } from './notifications-tab/notifications-tab.component';
import { MyCustomersTabComponent } from './my-customers-tab/my-customers-tab.component';
import * as _ from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { takeUntil } from 'rxjs/operators';
import { MyFiltersTabComponent } from './my-filters-tab/my-filters-tab.component';
import { TabsComponent } from '../shared/components/tabs/tabs.component';
import { UserUtilService } from 'app/core/services/user/user-util.service';
import { roles } from '../core/core-constants.service';
import { isEqual } from 'lodash';
import { WindowService } from 'app/core/window.service';

export enum LifenetUserSettings {
  NOTIFICATIONS = 'notifications',
  MY_CUSTOMERS = 'myCustomers',
  MY_FILTERS = 'myFilters'
}

const SETTINGS = 'settings';
export const LIFENET_SETTINGS_FRAGMENT = 'lifenetSettings';

@Component({
  selector: 'hl-user-settings',
  templateUrl: './user-settings.component.html'
})
export class UserSettingsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cancelModal', {static: false}) cancelModal: CancelModalComponent;
  @ViewChild(NotificationsTabComponent, {static: false}) notificationTabComponent: NotificationsTabComponent;
  @ViewChild(MyCustomersTabComponent, {static: false}) myCustomersTabComponent: MyCustomersTabComponent;
  @ViewChild(MyFiltersTabComponent, {static: false}) myFiltersTabComponent: MyFiltersTabComponent;
  @ViewChild(TabsComponent, {static: false}) tabs: TabsComponent;

  currentStateName: any;
  isActiveTab: string;
  isLoadedData = false;
  showMyCustomersTab = false;
  showMyFiltersTab = false;
  showUserSettingsInTabs = false;
  showSaveFavoriteBtn = false;

  private unsubscribe$: Subject<void> = new Subject();
  isMyInstitutionTab = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private overlayCommunicationService: OverlayCommunicationService,
    private configService: CountryConfigRestService,
    private userUtilService: UserUtilService,
    private windowService: WindowService
  ) {}

  isNotificationTabComponentChanged(): boolean {
    return (!!this.notificationTabComponent
      && !!this.notificationTabComponent.notificationsPage
      && this.notificationTabComponent.notificationsPage.isChanged);
  }

  isMyCustomersTabComponentChanged(): boolean {
    return (!!this.myCustomersTabComponent && this.myCustomersTabComponent.isChanged);
  }

  isMyFiltersTabComponentChanged(): boolean {
    return (!!this.myFiltersTabComponent && this.myFiltersTabComponent.isChanged);
  }

  ngOnInit(): void {
    this.currentStateName = SETTINGS + '-' + LifenetUserSettings.MY_CUSTOMERS;
    this.isActiveTab = LifenetUserSettings.MY_CUSTOMERS;
    this.route.fragment.subscribe(f => {
      if (f) {
        switch (true) {
          case f.endsWith(LifenetUserSettings.NOTIFICATIONS) : {
            this.currentStateName = SETTINGS + '-' + LifenetUserSettings.NOTIFICATIONS;
            this.isActiveTab = LifenetUserSettings.NOTIFICATIONS;
            break;
          }
          case f.endsWith(LifenetUserSettings.MY_CUSTOMERS) : {
            this.currentStateName = SETTINGS + '-' + LifenetUserSettings.MY_CUSTOMERS;
            this.isActiveTab = LifenetUserSettings.MY_CUSTOMERS;
            break;
          }
          case f.endsWith(LifenetUserSettings.MY_FILTERS) : {
            this.currentStateName = SETTINGS + '-' + LifenetUserSettings.MY_FILTERS;
            this.isActiveTab = LifenetUserSettings.MY_FILTERS;
            break;
          }
          default : this.currentStateName = null;
        }
      }
    });

    combineLatest([
      this.configService.getConfig(),
      this.userUtilService.checkUserRoles({impersonateUserRole: roles.impersonateUserRole, viewEquipmentRole: roles.viewEquipmentRole})
    ])
      .pipe(takeUntil(this.unsubscribe$)).subscribe(([configResponse, rolesResponse]) => {
      const myCustomerSettings = !configResponse.TOGGLE_MY_CUSTOMER_SETTINGS
        || isEqual(configResponse.TOGGLE_MY_CUSTOMER_SETTINGS, 'true');
      this.showMyFiltersTab = isEqual(configResponse.FEATURE_TOGGLE_MY_FILTERS, 'true') && rolesResponse.viewEquipmentRole;
      this.showMyCustomersTab = myCustomerSettings && !rolesResponse.impersonateUserRole;
      this.showSaveFavoriteBtn = isEqual(configResponse.FEATURE_TOGGLE_MY_INSTITUTIONS_FAVORITES, 'true');
      this.showUserSettingsInTabs = this.showMyCustomersTab || this.showMyFiltersTab;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onTabClick(state) {
    this.currentStateName = SETTINGS + '-' + state;
    const navigationExtras: NavigationExtras = {
      fragment: LIFENET_SETTINGS_FRAGMENT + '=' + state
    };
    this.router.navigate([], navigationExtras);
    this.isMyInstitutionTab = state === 'myCustomers' && this.showUserSettingsInTabs;
  }

  resetElementsInTabs(): void {
    if (this.myCustomersTabComponent) {
      this.myCustomersTabComponent.resetElements();
    }
    if (this.notificationTabComponent && this.notificationTabComponent.notificationsPage) {
      this.notificationTabComponent.notificationsPage.resetElements();
    }
  }

  shouldSaveButtonBeDisabled(): boolean {
    return !(this.isNotificationTabComponentChanged() || this.isMyCustomersTabComponentChanged()
      || this.isMyFiltersTabComponentChanged());
  }

  showDialogIfWasDataChanged(event: Event) {
    if (!this.shouldSaveButtonBeDisabled()) {
      this.cancelModal.show();
    } else {
      this.overlayCommunicationService.emitCloseOverlayAfterSave(event);
    }
  }

  reloadOrRedirectLifenetAfterSave(): void {
    if (this.myCustomersTabComponent.selectedCountry === this.myCustomersTabComponent.user.country) {
      this.reloadLifeNetHandler(this.removeFragmentFromUrl(this.router.url));
    }
  }

  private removeFragmentFromUrl(url: string) {
    return url.includes('/equipment') ? 'equipment'
      : url.includes('#' + LIFENET_SETTINGS_FRAGMENT) ? url.split('#')[0]
      : url;
  }

  private reloadLifeNetHandler(url: string) {
    this.router.navigateByUrl(url).then(() => this.windowService.nativeWindow.location.reload());
  }

  saveSetting(): void {
    if (this.isNotificationTabComponentChanged()) {
      this.notificationTabComponent.notificationsPage.save();
    }
    if (this.isMyCustomersTabComponentChanged()) {
      this.myCustomersTabComponent.save();
    }
    if (this.isMyFiltersTabComponentChanged()) {
      this.myFiltersTabComponent.save();
    }
    this.close();
  }

  close() {
    if (this.isMyFiltersTabComponentChanged()) {
      this.myFiltersTabComponent.reset();
    }
    if (this.isNotificationTabComponentChanged()) {
      this.notificationTabComponent.notificationsPage.close();
    } else {
      this.overlayCommunicationService.emitCloseOverlayAfterSave(null);
    }
  }

  shownOrHiddenComponent(state: string): boolean {
    return !_.isEqual(this.currentStateName, state);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.showUserSettingsInTabs) {
        this.tabs.initActiveItem();
      }
    }, 1000);
  }

  showAddFavoriteModal() {
    this.myCustomersTabComponent.addFavoriteModal.show();
  }
}
