import { TicketStatusPipe } from '../../shared/pipes/ticket-status/ticket-status.pipe';
import { Injectable } from '@angular/core';
import { SearchPipe } from '../../shared/pipes/search/search.pipe';
import { OperationalStatusPipe } from '../../shared/pipes/operational-status/operational-status.pipe';
import { MyEquipmentPipe } from '../../shared/pipes/my-equipment/my-equipment.pipe';
import { SlicePipe } from '@angular/common';
import { OrderByPipe } from '../../shared/pipes/order-by/order-by.pipe';
import { DateRangePipe } from '../../shared/pipes/date-range/date-range.pipe';
import { DangerForPatientPipe } from '../../shared/pipes/danger-for-patient/danger-for-patient.pipe';
import { ActivitiesStatusPipe } from '../../shared/pipes/activities-status/activities-status.pipe';
import { MultiSelectPipe } from '../../shared/pipes/multi-select/multi-select.pipe';
import { ClinicalFieldsPipe } from '../../shared/pipes/clinical-fields/clinical-fields.pipe';
import { GroupTypePipe } from '../../shared/pipes/group-type/group-type.pipe';
import { ContractAvailabilityPipe } from '../../shared/pipes/contract-availability/contract-availability.pipe';
import { EquipmentRestService } from '../rest-services/equipment-rest.service';
import { PsrRequestStatusPipe } from '../../shared/pipes/psr-request-status/psr-request-status.pipe';
import { chain, clone, cloneDeep, filter, find, forEach, isEmpty, isObject, pick, sortBy, without } from 'lodash';
import { Observable } from 'rxjs';
import { SelectOption } from '../models/select-option';
import { Modality } from '../models/equipment/modality';
import { map } from 'rxjs/operators';
import { ActivitiesOpenClosedPipe } from 'app/shared/pipes/activities-open-closed/activities-open-closed.pipe';
import { SecurityNotificationsService } from '../services/security-notifications/security-notifications.service';

@Injectable()
export class FilterUtilService {

  constructor(
    private msPipe: MultiSelectPipe,
    private searchPipe: SearchPipe,
    private operationalPipe: OperationalStatusPipe,
    private myEquipmentPipe: MyEquipmentPipe,
    private securityNotificationsService: SecurityNotificationsService,
    private slicePipe: SlicePipe,
    private orderByPipe: OrderByPipe,
    private dateRangePipe: DateRangePipe,
    private dangerForPatientPipe: DangerForPatientPipe,
    private activitiesStatusPipe: ActivitiesStatusPipe,
    private activitiesOpenClosedPipe: ActivitiesOpenClosedPipe,
    private ticketStatusPipe: TicketStatusPipe,
    private clinicalFieldsPipe: ClinicalFieldsPipe,
    private groupTypePipe: GroupTypePipe,
    private contractAvailabilityPipe: ContractAvailabilityPipe,
    private equipmentRestService: EquipmentRestService,
    private psrRequestStatusPipe: PsrRequestStatusPipe) {
  }

  /**
   * @description apply different kind of filter to result set
   *
   * @param currentResultSet | dataset to filter
   * @param value | filter object
   * @param key | filter name
   */
  applyIndividualFilter(currentResultSet, value, key) {
    switch (key) {
      case 'search':
        return this.searchPipe.transform(currentResultSet, value);
      case 'myEquipment':
        return this.myEquipmentPipe.transform(currentResultSet, value);
      case 'operationalStatus':
        return this.operationalPipe.transform(currentResultSet, value);
      case 'advancedMultiSelect':
        return this.msPipe.transform(currentResultSet, value);
      case 'advancedMultiSelectArray':
        return this.msPipe.transformArray(currentResultSet, value);
      case 'advancedMultiSelectArrayOrLogic':
        return this.msPipe.transformArray(currentResultSet, value, true);
      case 'orderBy':
        return this.orderByPipe.transform(currentResultSet, value);
      case 'limitTo':
        return this.slicePipe.transform(currentResultSet, 0, value);
      case 'dateRange':
      case 'endOfSupport':
      case 'startDateRange':
      case 'endDateRange':
        return this.dateRangePipe.transform(currentResultSet, value);
      case 'dangerForPatient':
        return this.dangerForPatientPipe.transform(currentResultSet, value);
      case 'contractAvailability':
        return this.contractAvailabilityPipe.transform(currentResultSet, value);
      case 'activitiesStatus':
        return this.activitiesStatusPipe.transform(currentResultSet, value);
      case 'activitiesOpenClosed':
        return this.activitiesOpenClosedPipe.transform(currentResultSet, value);
      case 'ticketStatus':
        return this.ticketStatusPipe.transform(currentResultSet, value);
      case 'clinicalFields':
        return this.clinicalFieldsPipe.transform(currentResultSet, value);
      case 'groupType':
        return this.groupTypePipe.transform(currentResultSet, value);
      case 'psrRequestStatus':
        return this.psrRequestStatusPipe.transform(currentResultSet, value);
      case 'ticketExpired':
        return this.filterExpired(currentResultSet, key, value);
      case 'contractTypeDescription':
      case 'updateStatusColor':
      case 'updateStatus':
      case 'label':
        return this.filter(currentResultSet, key, value);
      case 'securityNotificationSeverity':
        return this.filterSeverity(currentResultSet, value);
      case 'securityNotificationsMyEquipment':
        return this.securityNotificationsService.filterByMyEquipment(currentResultSet, value);
      default:
        return currentResultSet;
    }
  }

  filter(dataSet, key, value: string | string[]) {
    if (typeof (value) === 'string') {
      return value ? filter(dataSet, { [key]: value }) : dataSet;
    } else {
      return value && value.length ? filter(dataSet, item => value.indexOf(item[key]) !== -1) : dataSet;
    }
  }

  filterExpired(dataSet, key, value) {
    if (value.contractsStatus.length !== 1) {
      return dataSet;
    }

    const expiredStatusID = value.contractsStatus[0];

    if (value.contractExpiredId === expiredStatusID) {
      return filter(dataSet, { 'contractStatusId': expiredStatusID });
    } else {
      return filter(dataSet, item => item['contractStatusId'] !== value.contractExpiredId);
    }
  }

  filterSeverity(currentResultSet: { severity: string }[], value: string[]) {
    return isEmpty(value) ? currentResultSet
      : filter(currentResultSet, item => item && value.includes(item.severity));
  }

  /**
   * @description get the filtered list by applying single pipes/filter
   *
   * @param dataSet
   * @param filterObject
   */
  getListAfterApplyingFilterPipes(dataSet, filterObject) {
    // store result to temporary variable to be worked on
    let filteredResult = dataSet.slice();

    if (dataSet.length && isObject(filterObject)) {
      forEach(filterObject, (value, key) => {
        filteredResult = this.applyIndividualFilter(filteredResult, value, key);
      });
    }

    return filteredResult;
  }

  /**
   *
   * @param dataSet | result set
   * @param dropDownListElement | skeleton for which the dropdown options are to be generated.
   */
  computeDropdownOptions(dataSet, dropDownListElement) {

    const filteredDropDownElements: { [k: string]: SelectOption[] } = {};

    forEach(dropDownListElement, (value, key) => {

      // Note:- if the attributes value are null or '', excluded.
      filteredDropDownElements[key] = this.filterPropAndComputeOptions(dataSet, value);

    });

    return filteredDropDownElements;

  }

  /**
   * Return list of modalities contained in a given list of items.
   * @param itemList
   * @returns { Observable<SelectOption[]> }
   */
  getFilteredModalities(itemList: any[]): Observable<SelectOption[]> {
    // get unique modalities from the merged list
    const uniqueModalities = this.getListOfPropertyValuesFromListOfObject(itemList, 'modality');
    return this.equipmentRestService.getModalities().pipe(map((translatedModalities: Modality[]) => {
      const uniqueModalitiesWithTranslation: SelectOption[] = uniqueModalities.map((modailtyString) => {
        const translatedModOrEmpty = find(translatedModalities, m => m.modalityCode === modailtyString);
        const translatedMod = isEmpty(translatedModOrEmpty) || isEmpty(translatedModOrEmpty.modalityDescription) ?
          modailtyString : translatedModOrEmpty.modalityDescription;
        return {
          value: modailtyString,
          title: translatedMod
        };
      });
      return sortBy(uniqueModalitiesWithTranslation, (item) => {
        if (!isEmpty(item.title)) {
          return item.title.toLowerCase();
        }
        return '';
      });
    }));
  }

  /**
   * Return list of modalities contained in a given list of items matching with modalities in LN_Whitelist
   * @param itemList
   * @returns { Observable<SelectOption[]> }
   */
  getFilteredModalitiesFromWhitelist(itemList: any[]): Observable<SelectOption[]> {
    const uniqueModalities = this.getListOfPropertyValuesFromListOfObject(itemList, 'modality');
    return this.equipmentRestService.getModalitiesFromWhitelist().pipe(map((translatedModalities: Modality[]) => {
      const uniqueModalitiesWithTranslation: SelectOption[] = uniqueModalities
        .filter((modalityString => {
          const translatedModOrEmpty = find(translatedModalities, m => m.modalityCode === modalityString);
          return !isEmpty(translatedModOrEmpty) && !isEmpty(translatedModOrEmpty.modalityDescription);
        }))
        .map(modalityString => {
          const translatedModOrEmpty = find(translatedModalities, m => m.modalityCode === modalityString);
          return {
            value: modalityString,
            title: translatedModOrEmpty.modalityDescription
          };
        });
      return sortBy(uniqueModalitiesWithTranslation, (item) => {
        if (!isEmpty(item.title)) {
          return item.title.toLowerCase();
        }
        return '';
      });
    }));
  }

  /**
   *
   * @param dataSet |  result set
   * @param property | options property to compute options object
   */
  filterPropAndComputeOptions(dataSet, property): SelectOption[] {
    const distinctProperties = this.getListOfPropertyValuesFromListOfObject(dataSet, property);
    const optionsObj = [];
    forEach(distinctProperties, (item) => {
      const obj: { [k: string]: any } = {};
      obj.title = item;
      obj.value = item;
      optionsObj.push(obj);
    });
    return optionsObj;
  }

  /**
   *
   * @param dataSet | result set
   * @param filterProperty | property of object to get value
   */
  getListOfPropertyValuesFromListOfObject(dataSet, filterProperty) {
    const uniqProp: any[] = chain(dataSet).map(filterProperty).flatten().uniq().sortBy().value();
    return without(uniqProp, null, undefined, '');
  }

  /**
   *
   * @param item | item to which attributes from an object are to be merged.
   * @param objectToFindAndMerge | contains the search key, search value and properties to be merged.
   * @param dataSet | dataSet on which attributes are searched --> it is for e.g:- equipments/all for instance.
   * @returns {{}|*}
   */
  findPropertiesToMerge(item, objectToFindAndMerge, dataSet) {

    // filter the merge from object by findKey and findValue
    const findObj = {};
    const searchValue = item[objectToFindAndMerge.findValue];

    if (searchValue) {
      /**
       * ISHL-227
       * Remove trailing zeros in Front End.
       */
      findObj[objectToFindAndMerge.findKey] = clone(searchValue).replace(/_0+/, '_');
    } else {
      findObj[objectToFindAndMerge.findKey] = '';
    }

    const found = find(dataSet, findObj);
    if (found) {
      return pick(find(dataSet, findObj), objectToFindAndMerge.propertiesToMerge);
    } else {
      const obj = {};
      forEach(objectToFindAndMerge.propertiesToMerge, property => {
        obj[property] = '';
      });
      return cloneDeep(obj);
    }

  }

  public computeModalityOptions(items: any[], myEquipment: { isMyEquipmentChecked: boolean, myEquipmentList: string[] }) {
    const viewModelList = this.applyIndividualFilter(items, myEquipment, 'myEquipment');
    const { modalities } = this.computeDropdownOptions(viewModelList, { modalities: 'modality' });
    return modalities;
  }

}
