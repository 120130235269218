import { TicketViewModel } from '../view-models/ticket-view-model';
import { TicketsUtilService } from '../services/tickets/tickets-util.service';
import { TicketsCacheService } from '../services/cache/tickets-cache.service';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { ActivatedRoute } from '@angular/router';
import { LifeNetUtilService } from '../utils/life-net-util.service';
import { LogService } from '../services/log/log.service';
import { StringUtilService } from '../utils/string-util.service';
import { BaseItemDetailView } from './base-item-detail-view';
import { finalize } from 'rxjs/operators';
import { NotifStatus } from '../core-constants.service';
import { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

export abstract class BaseTicketDetailView extends BaseItemDetailView<TicketViewModel>
  implements OnInit, OnDestroy, OnChanges {

  viewModelTicket: TicketViewModel;

  ticketsUtilService: TicketsUtilService;
  ticketsCacheService: TicketsCacheService;

  constructor(configService: CountryConfigRestService,
    ticketsUtilService: TicketsUtilService,
    activatedRoute: ActivatedRoute,
    lifeNetUtilService: LifeNetUtilService,
    ticketsCacheService: TicketsCacheService,
    logService: LogService,
    stringUtilService: StringUtilService) {
    super(configService, lifeNetUtilService, activatedRoute, logService, stringUtilService);
    this.ticketsUtilService = ticketsUtilService;
    this.ticketsCacheService = ticketsCacheService;
  }

  ngOnInit() {
    super.init();
  }

  afterInitProperties(): void {
    this.viewModelTicket = null;
  }

  afterConfigProperties(config: any) {}

  ngOnDestroy(): void {
    super.destroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Note: When the modal is opened second time for another item, it shows same item content as before
    // hence we call again init().
    if (
      changes['closedTicketInput'] &&
      !changes['closedTicketInput'].firstChange
    ) {
      this.init();
    }
  }

  afterLoadViewModelItem(config: any): void {}

  /**
   * @param config
   * @param ticket id
   *
   * @description sets the view model for list view
   * i.e. sub tabs in tickets main navigation
   */
  setViewModelItems(config: any, id: string) {
    const notifStatus = this.getNotifStatus();

    const source$ = this.ticketsUtilService.selectedClosedTicket
      ? this.ticketsUtilService.getClosedTicketViewModel(this.ticketsUtilService.selectedClosedTicket)
      : this.ticketsUtilService.getTicketViewModel(id, notifStatus);
    source$.pipe(finalize(() => {
      this.isLoaded = true;
    }))
      .subscribe((ticketResponse) => {
        if (ticketResponse) {
          this.viewModelTicket = ticketResponse;
          this.setViewModelProperties(config, ticketResponse);
        } else {
          // do nothing - show empty details...
          this.logService.debug('no matching ticket found for id: ' + id);
          this.logService.debug('no matching ticket found and:    ' + notifStatus + ' KNOWN ISSUE!');
        }
      }, (some) => {
        this.logService.debug('what is the problem? ' + some);
      });
  }

  getNotifStatus(): NotifStatus {
    return this.ticketsCacheService.getSelectedOpenClosedStatus();
  }
}
