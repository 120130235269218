import { Injectable } from '@angular/core';
import { SortSkeleton } from '../../../shared/sorting/sort-skeleton';
import { SortDirection } from '../../../shared/sorting/sort-object';

@Injectable()
export class ContractsConstantsService {

  getSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'expirationDate',
        sortDir: SortDirection.ASC
      },
      items: [{
        title: 'CONTRACT_NAME',
        value: 'contractName'
      }, {
        title: 'CONTRACT_TYPE',
        value: 'contractTypeDescription'
      }, {
        title: 'CONTRACT_NUMBER',
        value: 'contractNumber'
      }, {
        title: 'EQUIPMENT_CONTRACT_GROUP_NUMBER',
        value: 'contractGroup'
      }, {
        title: 'GENERIC_LABEL_PRODUCT_NAME',
        value: 'productName'
      }, {
        title: 'LABEL_CONTRACT_START_DATE',
        value: 'startDate'
      }, {
        title: 'CONTRACT_EXPIRATION_DATE',
        value: 'expirationDate'
      }, {
        title: 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID',
        value: 'siemensId'
      }, {
        title: 'GENERIC_LABEL_MY_EQUIPMENT_NAME',
        value: 'myEquipmentName'
      }]
    };
  }

  getSortSkeletonNoContractGroupNum(): SortSkeleton {
    const {items, ...sortSkeleton} = this.getSortSkeleton();
    return {
      ...sortSkeleton,
      items: items.filter(item => item.title !== 'EQUIPMENT_CONTRACT_GROUP_NUMBER')
    };
  }

  getSortSkeletonForEquipment(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'expirationDate',
        sortDir: SortDirection.ASC
      },
      items: [{
        title: 'CONTRACT_NAME',
        value: 'contractName'
      }, {
        title: 'CONTRACT_TYPE',
        value: 'contractTypeDescription'
      }, {
        title: 'CONTRACT_NUMBER',
        value: 'contractNumber'
      }, {
        title: 'EQUIPMENT_CONTRACT_GROUP_NUMBER',
        value: 'contractGroup'
      }, {
        title: 'LABEL_CONTRACT_START_DATE',
        value: 'startDate'
      }, {
        title: 'CONTRACT_EXPIRATION_DATE',
        value: 'expirationDate'
      }]
    };
  }

  getSortSkeletonForEquipmentNoContractGroupNum(): SortSkeleton {
    const {items, ...sortSkeleton} = this.getSortSkeletonForEquipment();
    return {
      ...sortSkeleton,
      items: items.filter(item => item.title !== 'EQUIPMENT_CONTRACT_GROUP_NUMBER')
    };
  }
}
