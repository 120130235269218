import { Component, OnInit, Renderer2 } from '@angular/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { dateIsTodayOrInThePast } from '../../validators/no-future-date.validator';
import { DatePipeWrapperPipe } from '../../pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { SecurityAdvisoryNotificationService } from '../../../core/services/security-advisories-notifications/security-advisory-notification.service';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { MessagingCenterService } from '../../../core/services/messaging-center/messaging-center.service';
import { isEqual } from 'lodash';

@Component({
  selector: 'hl-create-advisory-notification-modal',
  templateUrl: './create-advisory-notification-modal.component.html'
})
export class CreateAdvisoryNotificationModalComponent extends BaseModalPopup implements OnInit {

  createAdvisoryNotificationForm: FormGroup;
  isFormSubmitted = false;
  DATE_FORMAT = 'DD-MM-YYYY';
  isEditAdvisoryNotification = false;
  selectedId: number;
  private advisoryNotificationCreationMessage: string;
  readonly unsubscribe$ = new Subject<void>();
  isSaveDisabled$: Observable<boolean>;

  originalFormValues: {
    headline: string,
    description: string,
    lastUpdated: string,
    activationDate: string
  };

  constructor(
    private fb: FormBuilder,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private translateService: TranslateService,
    private messagingCenterService: MessagingCenterService,
    private securityAdvisoriesNotificationsService: SecurityAdvisoryNotificationService,
    private toasterService: ToasterService,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
    this.createForm();
    this.translateService.get('LABEL_SECURITY_ADVISORY_NOTIFICATION_SUCCESS').subscribe(advisoryNotificationCreationMessage => {
      this.advisoryNotificationCreationMessage = advisoryNotificationCreationMessage;
    });

    this.securityAdvisoriesNotificationsService.showCreateAdvisoryNotificationModalEmitter
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response) {
          this.createAdvisoryNotificationForm.patchValue(response);
          this.isEditAdvisoryNotification = true;
          this.selectedId = response.id;
          this.originalFormValues = { ...this.createAdvisoryNotificationForm.getRawValue() };
          this.originalFormValues.lastUpdated = this.datePipeWrapperPipe
              .transform(this.createAdvisoryNotificationForm.controls['lastUpdated'].value, this.DATE_FORMAT);
          this.originalFormValues.activationDate = this.datePipeWrapperPipe
              .transform(this.createAdvisoryNotificationForm.controls['activationDate'].value, this.DATE_FORMAT);
        } else {
          this.createAdvisoryNotificationForm.reset();
          this.isEditAdvisoryNotification = false;
        }
      });

    this.isSaveDisabled$ = this.createAdvisoryNotificationForm.valueChanges.pipe(
        map(v => !!this.originalFormValues && this.isSaveDisabled(v))
    );
  }

  isSaveDisabled(formValue) {
    const actualFormValues = { ...formValue };
    actualFormValues.lastUpdated = this.datePipeWrapperPipe
        .transform(actualFormValues.lastUpdated, this.DATE_FORMAT);
    actualFormValues.activationDate = this.datePipeWrapperPipe
        .transform(actualFormValues.activationDate, this.DATE_FORMAT);

    return isEqual(this.originalFormValues, actualFormValues);
  }

  ok() {
    this.createOrEditAdvisoryNotification();
  }

  private createForm() {
    this.createAdvisoryNotificationForm = this.fb.group({
        headline: ['', [Validators.required]],
        description: ['', [Validators.required]],
        lastUpdated: [null, [Validators.required, dateIsTodayOrInThePast]],
        activationDate: [null, [Validators.required]]
      }
    );
  }

  show() {
    super.show();
    this.createAdvisoryNotificationForm.markAsUntouched();
  }

  createOrEditAdvisoryNotification() {
    this.isFormSubmitted = true;
    const message = {
      type: 'success',
      isBodyTranslationKey: false,
      body: this.advisoryNotificationCreationMessage
    };

    if (this.createAdvisoryNotificationForm.valid) {
      const value = Object.assign({}, this.createAdvisoryNotificationForm.value);
      const lastUpdated = this.datePipeWrapperPipe.transform(value.lastUpdated, this.DATE_FORMAT);
      const activationDate = this.datePipeWrapperPipe.transform(value.activationDate, this.DATE_FORMAT);

      if (this.isEditAdvisoryNotification) {
        value.id = this.selectedId;
      }

      this.showSpinner = true;
      const body = { ...value, lastUpdated, activationDate };
      this.securityAdvisoriesNotificationsService.createOrEditNotification(body, this.isEditAdvisoryNotification).pipe(
        finalize(() => {
          this.resetAndHide();
          this.messagingCenterService.emitRefreshMessagingCenter();
        }),
        takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.toasterService.emitToast(message);
          this.securityAdvisoriesNotificationsService.emitAdvisoryNotificationUpdated();
      });
    }
  }

  resetAndHide() {
    this.isFormSubmitted = false;
    this.createAdvisoryNotificationForm.reset();
    this.createAdvisoryNotificationForm.markAsUntouched();
    this.showSpinner = false;
    this.hide();
  }

  get headlineControl() {
    return this.createAdvisoryNotificationForm.controls['headline'];
  }

  get descriptionControl() {
    return this.createAdvisoryNotificationForm.controls['description'];
  }

  get lastUpdatedControl() {
    return this.createAdvisoryNotificationForm.controls['lastUpdated'];
  }

  get activationDateControl() {
    return this.createAdvisoryNotificationForm.controls['activationDate'];
  }
}
