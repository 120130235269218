import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export class DomainUpdateEvent {
  source: string;
  domain: [Date, Date];
}

@Injectable({
  providedIn: 'root'
})
export class EquipmentEnvironmentCommunicationService {

  domainUpdates = new ReplaySubject<DomainUpdateEvent>();

  get domainUpdates$() {
    return this.domainUpdates.asObservable();
  }

  updateDomain(event: DomainUpdateEvent) {
    this.domainUpdates.next(event);
  }
}
