export interface DataDiagram {
  name: string;
  value: number;
  color: string;
  colorMonochrome?: string;
  routerLink: string;
  queryParams: object;
  valueUnit?: string;
}

export interface GeneratedDataDiagram {
  data: {
    name: string;
    value: string;
  };
}

export interface SeriesData {
  name: any;
  value: number | any;
  secondAxis?: boolean;
}

export interface Series {
  name: any;
  series: SeriesData[];
  secondAxis?: boolean;
}

export const NOT_AVAILABLE = 'LABEL_REPORTING_NOT_AVAILABLE';
export const DURATION_UNIT = 'LABEL_DURATION_UNIT';
