import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { cloneDeep } from 'lodash';

const SELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectboxComponent),
  multi: true
};

@Component({
  selector: 'hl-selectbox',
  templateUrl: './selectbox.component.html',
  providers: [SELECT_VALUE_ACCESSOR]
})
export class SelectboxComponent implements OnChanges, ControlValueAccessor {

  isDropdownOpen = false;
  isActive = false;
  translatedOptions$: Observable<DropdownOptions[]>;
  tooltipSelected: string;

  @Input() options: DropdownOptions[]; // dropdown list options
  @Input() translateKeys?: boolean; // translate keys
  @Input() isDisabled = false;
  @Input() isRequired = false;
  @Input() isInvalid: boolean;
  @Input() label: string; // translation key
  @Input() invalidLabel: string;
  @Input() tooltip = false;
  @Input() documentOverlay = false;

  _value = '';
  @Input() set value(val: string) {
    if (val === this._value) {
      return;
    }
    if (val === '' && this.isRequired && this.options && this.options.length === 1) {
      this._value = this.options[0].value;
    } else {
      this._value = val;
    }
    this.isActive = !!this._value;
    this.valueChange.emit(this._value);
    this.onModelChange(this.value);
    this.onModelTouched();
  }
  get value() {
    return this._value;
  }
  @Output() valueChange = new EventEmitter(true);

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (this.isDropdownOpen && !this.el.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  constructor(
    private translate: TranslateService,
    protected el: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {

    const options = cloneDeep(this.options || []);
    if (changes.options && this.isRequired && options && options.length === 1) {
      this.setSelected(options[0]);
    }

    this.translatedOptions$ = this.translateKeys && options.length
      ? this.translate.get(options.map(o => o.title)).pipe(
          map(resp => options.map(o => ({
            ...o, title: resp[o.title] || o.title
          })))
        )
      : of(options);
  }

  dropdownToggle() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  setSelected(option) {
    this.value = option.value;
    if (option.title && option.title.length > 20) {
      this.tooltipSelected = option.title;
    } else {
      this.tooltipSelected = null;
    }
  }

  onModelChange: Function = (_: any) => {};
  onModelTouched: Function = () => {};

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }
}
