import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotAuthenticatedComponent } from './not-authenticated.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { NotAuthorizedComponent } from './not-authorized.component';

const routes: Routes = [
  {
    path: 'not-authenticated',
    component: NotAuthenticatedComponent,
    data: {stateName: 'not-authenticated'}
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    data: {stateName: 'page-not-found'}
  },
  {
    path: '403',
    component: NotAuthorizedComponent,
    data: {stateName: 'not-authorized'}
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class FrontPagesRoutingModule {
}
