import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ProfessionalServiceRequest } from '../../../core/models/professionalServiceRequest/professional-service-request';

@Pipe({
  name: 'psrRequestStatus'
})
export class PsrRequestStatusPipe implements PipeTransform {

  transform(dataset: ProfessionalServiceRequest[], selectedStatus: String[]): any {
    let filtered: ProfessionalServiceRequest[] = [];

    if (selectedStatus && selectedStatus.length > 0) {
      filtered = this.filterResult(dataset, selectedStatus);
    } else {
      filtered = dataset;
    }
    return filtered;
  }

  private filterResult(dataset: ProfessionalServiceRequest[], selectedStatus: String[]): ProfessionalServiceRequest[] {
    return _.filter(dataset, (psr) => _.includes(selectedStatus, psr.quoteStatus));
  }
}
