import { HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';

export class CacheableHttpRequest<T> extends HttpRequest<T> {
  cache: boolean;

  constructor(method: string, url: string, body?: T | null, init?: {
    headers?: HttpHeaders;
    reportProgress?: boolean;
    params?: HttpParams;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean;
    cache?: boolean;
  }) {
    // create base HttpReqeust
    super(method, url, body, init);

    // check if cache is set.
    // always cache if not explicitely set (default: true)
    if (init.cache === undefined) {
      this.cache = true;
    } else {
      this.cache = init.cache;
    }

  }
}

