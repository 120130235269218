import { of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheableHttpRequest } from '../models/cacheable-http-request';
import { HttpCacheService } from '../services/cache/http-cache.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '../services/log/log.service';
import { restEndPoint } from '../core-constants.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class CacheRequestInterceptor implements HttpInterceptor {

  constructor(private log: LogService, private cache: HttpCacheService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (environment.newCacheableHttpClient === true) {
      return next.handle(request);
    }

    if (request instanceof CacheableHttpRequest && request.cache) {
      const cachedResponse = this.cache.get(request);
      if (cachedResponse) {
        return of(cachedResponse);
      }

      return next.handle(request).pipe(tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.put(request, event);
        } else {
          this.cacheSelected(request);
        }
      }));
    } else {
      return next.handle(request);
    }
  }

  /**
   * For specific URLs (ticket/activity detail) puts into the cache all responses
   * incl. error responses to avoid redundant SAP calls
   * @param request
   */
  cacheSelected(request: CacheableHttpRequest<any>) {
    const cachedUrls = [
      'activities\/.*details.*\?customerNumber',
      'tickets\/.*\?customerNumber.*&equipmentKey'];
    for (const cachedUrl of cachedUrls) {
      if (request.urlWithParams.match(restEndPoint + cachedUrl)) {
        this.cache.put(request, new HttpResponse());
      }
    }
  }
}
