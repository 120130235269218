import { Component, Renderer2, Input } from '@angular/core';
import { BaseModalPopup } from '../../core/base-class/base-modal-popup';
import { Instructions } from '../../core/models/systemUpdates/instructions';

@Component({
  selector: 'hl-how-to-modal',
  templateUrl: './how-to-modal.component.html'
})
export class HowToModalComponent extends BaseModalPopup  {
  @Input() instructions: Instructions;

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  ok() {
  }

}
