import { HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';

export class FastSubject<T> extends Subject<T> {
  nextStop(value?: T) {
    if (this.closed) {
      this.next(value);
    }
    if (!this.isStopped) {
      this.isStopped = true;
      const { observers } = this;
      const len = observers.length;
      const copy = observers.slice();
      for (let i = 0; i < len; i++) {
        copy[i].next(value);
      }
    }
  }
}

export class CacheItem {
  private static cachedRequestKeySeparator = '|';

  private readonly _cacheItemKey: string;
  private _response: any;
  private readonly _onLoadedResponse: FastSubject<any> = new FastSubject();

  public static generateKey(url: string, params?: HttpParams): string {
    if (!url) {
      return null;
    }
    let stringOfAllParams = '';
    const newHttpParams = this.parseUrlParams(url, params);
    const allParamsKeys = newHttpParams.keys().concat().sort();
    allParamsKeys.forEach((singleParamKey) => {
      stringOfAllParams +=
        CacheItem.cachedRequestKeySeparator + singleParamKey +
        CacheItem.cachedRequestKeySeparator;
      stringOfAllParams += newHttpParams.getAll(singleParamKey).concat().sort();
    });
    const urlParamsIndex = url.indexOf('?');
    return (((urlParamsIndex > 0) ? url.slice(0, urlParamsIndex) : url) + stringOfAllParams);
  }

  private static parseUrlParams(url: string, params?: HttpParams): HttpParams {
    let newOptions = (params && params instanceof HttpParams) ? params : new HttpParams();
    if (!!url) {
      const urlParamsIndex = url.indexOf('?');
      const urlOptionsStringArray = (urlParamsIndex > 0) ? url.slice((urlParamsIndex + 1)).split('&').sort() : null;
      if (!!urlOptionsStringArray) {
        urlOptionsStringArray.forEach((urlOptionString) => {
          const urlOptionKey = urlOptionString.slice(0, urlOptionString.indexOf('='));
          const urlOptionValues = urlOptionString.slice((urlOptionString.indexOf('=') + 1)).split(',').sort();
          if (urlOptionValues && newOptions.has(urlOptionKey)) {
            urlOptionValues.forEach((urlOptionValue) => {
              if (!(newOptions.getAll(urlOptionKey).find(val => val === urlOptionValue))) {
                newOptions = newOptions.append(urlOptionKey, urlOptionValue);
              }
            });
          } else if (urlOptionValues) {
            urlOptionValues.forEach((urlOptionValue, index) => {
                newOptions = (index === 0) ? newOptions.set(urlOptionKey, urlOptionValue) : newOptions.append(urlOptionKey, urlOptionValue);
              }
            );
          }
        });
      }
    }
    return newOptions;
  }

  constructor(url?: string, params?: HttpParams, response?: any) {
    this._cacheItemKey = CacheItem.generateKey(url, params);
    this._response = response;
  }

  get response() {
    return this._response;
  }

  get cacheItemKey(): string {
    return this._cacheItemKey;
  }

  get responseObservable(): Observable<any> {
    if (this.isRequestFinished()) {
      return of(this._response);
    }
    return this._onLoadedResponse.asObservable();
  }

  cache(value: any) {
    this._response = value;
    this._onLoadedResponse.nextStop(value);
    this._onLoadedResponse.complete();
  }

  raiseResponseSubjectError(value?: any) {
    this._onLoadedResponse.error(value);
  }

  isRequestFinished(): boolean {
    return this._onLoadedResponse.isStopped;
  }
}
