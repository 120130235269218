import { Directive, OnDestroy, Renderer2 } from '@angular/core';
import * as _ from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { roles } from '../../../core/core-constants.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { StateService } from '../../../core/services/state.service';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { WindowService } from '../../../core/window.service';

import * as $ from 'jquery/dist/jquery.min.js';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { takeUntil } from 'rxjs/operators';

const classesToKeep = ['overflow-hidden', 'modal-open'];

enum userTypeEnum {
  INTERNAL = 'int',
  EXTERNAL = 'ext'
}

const LOGGED_IN = 'logged_in';
const LOGGED_OUT = 'logged_out';

@Directive({
  selector: '[hlAddOmnitureAndRouterStateName]'
})
export class AddOmnitureAndRouterStateNameDirective implements OnDestroy {

  static trackDownload = false;
  window = this.windowService.nativeWindow;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * @description
   * Generate the customer number in the format needed for omniture
   *
   * @param customerNumbers
   */
  static generateCustNoFormat(customerNumbers) {
    let custNumberInput = '';
    if (customerNumbers.length > 0) {
      custNumberInput = customerNumbers.join('|');
    } else {
      custNumberInput = customerNumbers[0];
    }
    return custNumberInput;
  }

  /**
   * Omniture tracking when downloading documents with added url params
   * @param filename
   * @param url
   */
  static triggerDocumentsDownloadTracking(filename: string, url: string) {
    const fullUrl = window.location.protocol + '//' + window.location.hostname + url;
    this.triggerDocumentsDownloadTrackingFullUrl(filename, fullUrl);
  }

  /**
   * Omniture tracking when downloading documents
   * @param filename
   * @param url
   */
  static triggerDocumentsDownloadTrackingFullUrl(filename: string, url: string) {
    const omnitureData = {
      'data': {
        'action': 'document.download',
        'document': [
          {
            'filename': filename,
            'url': url
          }
        ]
      },
      'callback': function(err) {
        if (err) {
          console.log('Error Omniture documents download tracking');
          console.log(err.message);
        }
      }
    };
    if (AddOmnitureAndRouterStateNameDirective.trackDownload) {
      window['ste_statistic'] = window['ste_statistic'] || [];
      window['ste_statistic'].push(omnitureData);
      $(document).trigger('ste:action', omnitureData);
    }
  }

  constructor(private windowService: WindowService,
    private userRestService: UserRestService,
    private lifeNetUtilService: LifeNetUtilService,
    private filterUtilService: FilterUtilService,
    private configService: CountryConfigRestService,
    private state: StateService,
    private renderer: Renderer2) {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.state.getActiveStateName().subscribe(stateName => {
      if (stateName) {
        const bodyElem = document.getElementsByTagName('body')[0];
        const bodyClassesToRemove = _.without(bodyElem.classList, ...classesToKeep);
        _.forEach(bodyClassesToRemove, (classToRemove) => {
          bodyElem.classList.remove(classToRemove);
        });
        this.renderer.addClass(bodyElem, stateName);

        // trigger omniture
        this.triggerOmniture(stateName);
      }
    });
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      AddOmnitureAndRouterStateNameDirective.trackDownload =
        _.isEqual(config.OMNITURE_DOWNLOAD_TRACKING_FEATURE_TOGGLE, 'true');
    });
  }

  triggerOmniture(stateName) {
    if (_.isFunction(window['ste_tracklifenet'])) {
      const user$ = this.userRestService.getUser();
      const assignedCustomers$ = this.userRestService.getAssignedCustomers();
      const userRoles$ = this.userRestService.getUserRoles();

      forkJoin(user$, assignedCustomers$, userRoles$).subscribe((responses) => {
        const userData = responses[0];
        const assignedCustomerData = responses[1];
        const userRolesData = responses[2];
        const listOfCustomers =
          this.filterUtilService.getListOfPropertyValuesFromListOfObject(assignedCustomerData, 'customerNumber');

        // check if we received also IMPERSONATE_USER role and appropriately set user type
        const checkImpersonationRole = _.find(userRolesData, {identifier: roles.impersonateUserRole});
        const userType = (!!checkImpersonationRole) ? userTypeEnum.INTERNAL : userTypeEnum.EXTERNAL;

        // check for only one customer or multiple customer
        let custNoInput = AddOmnitureAndRouterStateNameDirective.generateCustNoFormat(listOfCustomers);
        if (_.isUndefined(custNoInput)) {
          custNoInput = '';
        }
        window['ste_tracklifenet']({
          name: stateName,
          language: userData.language,
          country: userData.country,
          custno: custNoInput,
          loginstatus: LOGGED_IN,
          usertype: userType
        });
      });
    }
  }

}
