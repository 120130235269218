export interface CustomerData {
  type: CustomerNameType;
  name1: string;
  name2: string;
  name12: string;
  customName: string;
  latitude: number;
  longitude: number
}

export enum CustomerNameType {
  name1 = 'name1', name2 = 'name2', name12 = 'name12', customName = 'customName'
}
