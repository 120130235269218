import { EditComponentModalComponent } from '../../../../shared/modal-popup/edit-component-modal/edit-component-modal.component';
import { LifeNetUtilService } from '../../../../core/utils/life-net-util.service';
import { CountryConfigRestService } from '../../../../core/rest-services/country-config-rest.service';
import { EquipmentConstantsService } from '../../../../core/services/equipment/equipment-constants.service';
import { EquipmentComponentItem } from '../../../../core/models/equipment/equipment-details';
import { Equipment } from '../../../../core/models/equipment/equipment';
import { EquipmentRestService } from '../../../../core/rest-services/equipment-rest.service';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import * as _ from 'lodash';
import { EquipmentViewModel } from '../../../../core/view-models/equipment-view-model';
import { SortSkeleton } from '../../../../shared/sorting/sort-skeleton';
import { FilterUtilService } from '../../../../core/utils/filter-util.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'hl-equipment-component-list',
  templateUrl: './equipment-component-list.component.html'
})
export class EquipmentComponentListComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('editComponentModal', { static: false }) editComponentModal: EditComponentModalComponent;

  @Input() equipmentItem: Equipment;
  @Input() allowEdit: boolean;

  selectedComponent: EquipmentComponentItem;
  equipmentComponentList: EquipmentComponentItem[];
  isLoaded: boolean;
  sorter: SortSkeleton;
  datePattern: string;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private configService: CountryConfigRestService,
    private equipmentRestService: EquipmentRestService,
    private equipmentConstantsService: EquipmentConstantsService,
    public lifeNetUtilService: LifeNetUtilService,
    private filterUtilService: FilterUtilService) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // When in the same tab, item is clicked on list side, then again needs to reactivate
    if (changes['equipmentItem'] && !changes['equipmentItem'].firstChange) {
      this.unsubscribe$.next();
      this.init();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.initProperties();
    this.configurePropBasedOnCountryConfig();
  }

  initProperties() {
    this.equipmentComponentList = [];
    this.isLoaded = false;
    this.selectedComponent = null;

    this.sorter = this.equipmentConstantsService.getComponentListSortSekeleton();
  }

  configurePropBasedOnCountryConfig() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.datePattern = configResponse.GENERIC_DATE_PATTERN;
      this.loadComponentList();
    });
  }

  loadComponentList() {
    this.equipmentRestService.getEquipmentDetails(this.equipmentItem.key)
      .pipe(finalize(() => this.isLoaded = true))
      .subscribe(equipmentDetailsResponse => {
        this.equipmentComponentList = equipmentDetailsResponse.components;
        this.filterComponentList();
      });
  }

  filterComponentList() {
    const filterListObject = {
      orderBy: this.sorter.sortObject
    };

    // Get result set with pagination
    this.equipmentComponentList = this.filterUtilService.getListAfterApplyingFilterPipes(this.equipmentComponentList, filterListObject);
  }

  onSortChange(event) {
    this.sorter = event;
    this.filterComponentList();
  }

  reloadComponent() {
    this.loadComponentList();
  }

  editComponent(item: EquipmentComponentItem) {
    this.selectedComponent = _.clone(item);
    this.editComponentModal.showModal(this.selectedComponent);
  }


  getExportList = () => {
    const exportObject = _.cloneDeep(this.equipmentComponentList);
    _.forEach(exportObject, obj => {
      const propertiesToMerge = [
        'siemensId',
        'productName'
      ];
      obj = this.lifeNetUtilService.mergeObjects(this.equipmentItem, obj, propertiesToMerge);
    });
    return this.filterUtilService.getListAfterApplyingFilterPipes(
      exportObject,
      {orderBy: this.sorter.sortObject}
    );
  }

  trackByFn(index, item: EquipmentViewModel) {
    return item['key'];
  }
}
