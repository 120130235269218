import { EquipmentUtilService } from '../equipment/equipment-util.service';
import { UserRestService } from '../../rest-services/user-rest.service';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { UserUtilService } from '../user/user-util.service';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from '../../window.service';
import { GenerateReportsRestService } from '../../rest-services/generate-reports-rest.service';
import { ReportsCacheService } from '../cache/reports-cache.service';
import { DatePipeWrapperPipe } from '../../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { DropdownOptions } from '../../models/dropdown-options';
import { FilterUtilService } from '../../utils/filter-util.service';

@Injectable()
export class ReportingUtilService {

  private datePattern: string;

  constructor(private userUtilService: UserUtilService,
              private configService: CountryConfigRestService,
              private translateService: TranslateService,
              private equipmentUtilService: EquipmentUtilService,
              private userRestService: UserRestService,
              private windowService: WindowService,
              private generateReportsRestService: GenerateReportsRestService,
              private reportsCache: ReportsCacheService,
              private filterUtilService: FilterUtilService,
              private datePipeWrapperPipe: DatePipeWrapperPipe) {

  }

  /**
   * returns a list of available reports, depending on user role
   * @return {Observable<SelectOption[]>}
   */
  getReportsByUserRole(userRole): Observable<DropdownOptions[]> {
    return combineLatest([
      this.configService.getConfig(),
      this.userUtilService.checkUserRoles(userRole),
      this.generateReportsRestService.isNyuReportAllowed()
    ]).pipe(
      map(responses => {
        this.datePattern = responses[0].GENERIC_DATE_PATTERN;
        const isReportAllowed = responses[1].isRoleAllowed;
        const availableReports = responses[0].AVAILABLE_REPORTS.split(',');
        if (responses[2]) {
          availableReports.unshift('NYU_REPORT');
        }
        return this.getReports(availableReports, isReportAllowed);
      }),
      filter(reports => reports.length !== 0),
      switchMap(reports => this.translateService.get(reports.map(r => r.title)).pipe(
        map((translate: {[key: string]: string}) => reports.map(r => ({...r, title: translate[r.title]}))))
      )
    );
  }

  /**
   * Getter function to get the modalities for FE.
   * response[0] are the modalities for 'All Equipments'
   * response[1] are the modalities for 'My Equipment'
   * @returns {Observable<SelectOption[][]>}
   */
  getModalities(): Observable<any[]> {
    return forkJoin([
      this.equipmentUtilService.getEquipmentViewModelList(),
      this.equipmentUtilService.getMySiemensListAndSelectedFlag()
    ]).pipe(map(responses => {
      const equipmentList = responses[0];
      const myEquipmentList = responses[1];

      const allModalities = this.getAllEquipments(equipmentList);
      const myEquipmentModalities = this.getMyEquipments(myEquipmentList, equipmentList);
      return [allModalities, myEquipmentModalities];
    }));
  }

  /**
   * The translation function that maps the value of each modality to its respective title.
   * @param modalities: Array of modalities values that are to be translated
   * @param type: Either for 'All equipments' or 'My equipment'
   */
  getTranslatedModalities(allModalities, myModalities): Observable<any[]> {
    return this.filterUtilService.getFilteredModalitiesFromWhitelist(allModalities).pipe(map(response => {
      const allTranslatedModalities = response;

      const myStringModalities = [];
      _.forEach(myModalities, modality => {
        myStringModalities.push(modality.modality);
      });

      const myEquipmentTranslatedModalities = _.filter(allTranslatedModalities, modality => {
        return _.includes(myStringModalities, modality.value);
      });

      return [allTranslatedModalities, myEquipmentTranslatedModalities];
    }));
  }

  getReportingLinks(): Observable<any[]> {
    return combineLatest([
      this.configService.getConfig(),
      this.userRestService.getAssignedCustomers()
    ]).pipe(map(responses => this.getReportingLinksArray(responses[0], responses[1])));
  }

  openTab(report, externalReports: string | string[], reportingLink) {
    const externalReportsArray = typeof(externalReports) === 'string'
      ? externalReports.split(',')
      : externalReports;
    if (_.includes(externalReportsArray, report)) {
      this.windowService.nativeWindow.open(reportingLink, '_blank');
      return true;
    }
    return false;
  }

  getReportResponse(requestBody, reportType): Observable<any> {
    switch (reportType) {
      case 'EDUCATION_BALANCE':
        return this.generateReportsRestService.getEducationReport(requestBody);
      case 'UPTIME':
        return this.generateReportsRestService.getUptimeReport(requestBody);
      case 'PERFORMANCE_SUMMARY':
        return this.generateReportsRestService.getPerformanceSummaryReport(requestBody);
      case 'REMOTE_ACTIVITY':
        return this.generateReportsRestService.getRemoteActivityReport(requestBody);
    }
  }

  /**
   * Function that handles the call to BE to export the Reports.
   * Sends the correct object to make the request.
   * Creates blob and download link.
   * @param {string} reportType
   * @param {string} fileType
   */
  exportFiles(reportType: string, fileType: string) {
    const obj = this.getExportRequestObject(reportType);
    return this.generateReportsRestService.exportFiles(obj, reportType, fileType);
  }

  /**
   * Builds the request object body that needs to be sent to BE, to download XLS/PDF file.
   * @param {string} reportType
   */
  private getExportRequestObject(reportType: string) {
    const obj = {
      isMyEquipment: this.reportsCache.isSelectMyEquipment(),
      header: this.reportsCache.getHeaderText(),
      footer: this.reportsCache.getFooterText()
    };
    const datePattern = 'DD.MM.YYYY';
    switch (reportType) {
      case 'UPTIME':
        obj['modalityCodes'] = this.reportsCache.getModalities();
        break;
      case 'PERFORMANCE_SUMMARY':
        obj['topRecords'] = this.reportsCache.getTopRecords();
        obj['startDate'] = this.datePipeWrapperPipe.transform(new Date(this.reportsCache.getStartDate()), datePattern);
        obj['endDate'] = this.datePipeWrapperPipe.transform(new Date(this.reportsCache.getEndDate()), datePattern);
        break;
      case 'REMOTE_ACTIVITY':
        obj['modalityCodes'] = this.reportsCache.getModalities();
        obj['startDate'] = this.datePipeWrapperPipe.transform(new Date(this.reportsCache.getStartDate()), datePattern);
        obj['endDate'] = this.datePipeWrapperPipe.transform(new Date(this.reportsCache.getEndDate()), datePattern);
        break;
    }
    return obj;
  }

  private getReports(availableReports: string[], educationBalanceRenderAllowed: boolean): DropdownOptions[] {
    return availableReports
      .filter(value => educationBalanceRenderAllowed || !_.isEqual(value, 'EDUCATION_BALANCE'))
      .map(value => ({ value, title: 'LABEL_' + value }));
  }

  /**
   * For the external reports we need to open a new tab to the reporting link.
   * We get the reporting link from config service and also the external Reports as an array of strings.
   */
  private getReportingLinksArray(conf, assignedCustomers): string[] {
    let reportingLink = '';

    if (assignedCustomers.length > 0) {
      // extend link
      let reportingLinkSuffix = '?CUSTOMERS=';
      _.forEach(assignedCustomers, function (customer) {
        reportingLinkSuffix += customer.customerId + ',';
      });
      reportingLinkSuffix = _.trimEnd(reportingLinkSuffix, ',');
      reportingLink = conf.LINK_REPORTING + reportingLinkSuffix;
    } else {
      reportingLink = conf.LINK_REPORTING;
    }

    const externalReports = conf.AVAILABLE_EXTERNAL_REPORTS;
    return [reportingLink, externalReports];
  }

  /**
   * This function extracts the modalities of All Available Equipments and then calls a translation function to
   * map the title of each modality to their respective value.
   */
  private getAllEquipments(equipmentList): string[] {
    const equipments = equipmentList;
    const allModalities = [];

    _.forEach(equipments, eq => {
      if (!_.includes(allModalities, eq)) {
        allModalities.push(_.pick(eq, 'modality'));
      }
    });

    return allModalities;
  }

  /**
   * This function extracts the modalities of only the My Equipment list and then the translation function is called.
   */
  private getMyEquipments(myEquipment, equipmentList): string[] {

    const equipments = equipmentList;
    const myEquipmentsModalities = [];

    _.forEach(equipments, eq => {
      if (!_.includes(myEquipmentsModalities, eq) && _.includes(myEquipment.myEquipmentProfileList, eq.key)) {
        myEquipmentsModalities.push(_.pick(eq, 'modality'));
      }
    });

    return myEquipmentsModalities;
  }

  public getNyuReport() {
    return this.generateReportsRestService.getNyuReport();
  }
}
