import { MessageViewModel } from './message-view-model';
import { Messages } from '../models/messages';

export class MessagesViewModel {
  seenMessages: MessageViewModel[];
  unseenMessages: MessageViewModel[];

  constructor(model: Messages) {
    this.seenMessages = model.seenMessages.map(message => new MessageViewModel(message));
    this.unseenMessages = model.unseenMessages.map(message => new MessageViewModel(message));
  }
}
