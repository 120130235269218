import { intersection } from 'lodash';
import { pipe } from 'lodash/fp';
import { FilterEquipmentArgs, FilterValues, MyFiltersStatus, PREFIX_CMDB, PREFIX_SAP } from '../models/my-filters.model';
import { Equipment } from '../models/equipment/equipment';

export const getCmdbModalityCode = (equipment: Equipment) => {
  const code = equipment && equipment.cmdbEquipment && equipment.cmdbEquipment.modalityCode;
  return code ? PREFIX_CMDB + code : null;
};

export const getSapModalityCode = (equipment: Equipment) => {
  const code = equipment && equipment.modality;
  return code ? PREFIX_SAP + code : null;
};

export const filterEquipment = ([filterValues, allEquipment, myEquipment, knownModalities]: FilterEquipmentArgs) =>
  new EquipmentFilterService(filterValues, myEquipment, knownModalities).filterEquipment(allEquipment);

export class EquipmentFilterService {

  private readonly overallSwitch: boolean;
  private readonly myEquipmentSwitch: boolean;
  private readonly favoriteModalities: string[];

  constructor(
    filterValues: FilterValues,
    private readonly myEquipment: string[],
    private readonly knownModalities: string[]
  ) {
    this.overallSwitch = filterValues.overallSwitch;
    this.myEquipmentSwitch = filterValues.myEquipmentSwitch;
    this.favoriteModalities = filterValues.favoriteModalities;
  }

  filterEquipment(allEquipment: Equipment[] = []) {
    const equipment = this.overallSwitch ? this.filter(allEquipment) : allEquipment;
    return this.getStatus(equipment);
  }

  private getStatus(equipmentList: Equipment[]): MyFiltersStatus {
    return { overallSwitch: this.overallSwitch, equipment: equipmentList };
  }

  private filter(allEquipment: Equipment[]) {
    return pipe(this.filterByMyEquipment, this.filterByModalities).call(this, allEquipment);
  }

  filterByMyEquipment(equipmentList: Equipment[]) {
    return equipmentList.filter(equipment => !this.myEquipmentSwitch || this.myEquipment.indexOf(equipment.key) !== -1);
  }

  filterByModalities(equipmentList: Equipment[]) {
    return equipmentList.filter(equipment => {
      const equipmentModalityCodes = [];

      const sapCode = getSapModalityCode(equipment);
      if (sapCode && this.knownModalities.includes(sapCode)) {
        equipmentModalityCodes.push(sapCode);
      }
      const cmdbCode = getCmdbModalityCode(equipment);
      if (cmdbCode && this.knownModalities.includes(cmdbCode)) {
        equipmentModalityCodes.push(cmdbCode);
      }

      // Empty string represents a modality that has not been found on the server
      if (equipmentModalityCodes.length === 0) {
        equipmentModalityCodes.push('');
      }

      return intersection(this.favoriteModalities, equipmentModalityCodes).length !== 0;
    });
  }
}
