import { WelcomeRoutingModule } from './welcome-routing.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { WelcomeComponent } from './welcome.component';
import { FrontPagesModule } from '../front-pages/front-pages.module';
import { SelectMockUserComponent } from '../../cdmockdata/select-mock-user.component';

@NgModule({
  imports: [
    SharedModule,
    WelcomeRoutingModule,
    FrontPagesModule
  ],
  declarations: [WelcomeComponent, SelectMockUserComponent]
})
export class WelcomeModule { }
