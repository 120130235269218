import { ExcelExportDefinition } from '../../../core/models/excel-export-definition';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { ExportUtilService } from '../../../core/utils/export-util.service';
import { map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'hl-excel-export',
  templateUrl: './excel-export.component.html'
})
export class ExcelExportComponent implements OnDestroy {

  @Input() exportGenerate: () => any[];
  @Input() rowCount: number;
  @Input() exportOrderConfig: string;
  @Input() fileName: string;
  @Input() label: string;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  exportHeaderTranslationMap = {
    productName: 'GENERIC_LABEL_PRODUCT_NAME',
    siemensId: 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID',
    siemensEquipmentId: 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID',
    myEquipmentName: 'GENERIC_LABEL_MY_EQUIPMENT_NAME',
    department: 'GENERIC_LABEL_DEPARTMENT',
    street: 'GENERIC_LABEL_STREET',
    city: 'GENERIC_LABEL_CITY',
    zip: 'GENERIC_LABEL_POSTAL_CODE',
    shortText: 'TYPE',
    ticketNumber: 'GENERIC_LABEL_TICKET_NUMBER',
    plannedStart: 'PLANNED_ACTIVITIES_START_DATETIME',
    plannedEnd: 'PLANNED_ACTIVITIES_END_DATETIME',
    location: 'GENERIC_LABEL_LOCATION',
    installedOn: 'GENERIC_LABEL_INSTALLATION_DATE',
    sapAssetNumber: 'LABEL_ASSET_NUMBER',
    softwareVersion: 'SOFTWARE_VERSION',
    warrantyEnd: 'GENERIC_LABEL_END_OF_WARRANTY',
    endOfSupport: 'EQUIPMENT_END_OF_SERVICE',
    contractType: 'EQUIPMENT_CONTRACT_TYPE',
    contractGroup: 'EQUIPMENT_CONTRACT_GROUP_NUMBER',
    contractNumber: 'CONTRACT_NUMBER',  // equipment
    warrantyStart: 'GENERIC_LABEL_START_OF_WARRANTY',
    serialNumber: 'GENERIC_LABEL_SERIAL_NUMBER',
    description: 'SHORT_DESCRIPTION', // tickets
    ticketCreationTimestamp: 'CREATION_TIMESTAMP',
    status: 'GENERIC_LABEL_OPERATIONAL_STATE',
    ticketStatusDescription: 'TICKET_STATE',
    typeDescription: 'TICKET_TYPE',
    taskDescription: 'PROGRESS',
    creatorForeName: 'GENERIC_LABEL_FIRSTNAME',
    creatorLastName: 'GENERIC_LABEL_LASTNAME',
    plannedStartDatetime: 'PLANNED_START_DATE',
    dangerForPatient: 'PATIENT_SITUATION',
    problemSeverityDescription: 'GENERIC_LABEL_OPERATIONAL_STATE',
    categoryID: 'CATEGORY',
    priorityDescription: 'PRIORITY',
    moduleID: 'MODULE',
    moduleDescription: 'MODULE_DESCRIPTION',
    customerName: 'CUSTOMER_NAME',
    orderStatus: 'PARTNER_ORDER_STATUS',
    orderNumber: 'GENERIC_LABEL_ORDER_NUMBER',
    netValue: 'PARTNER_ORDER_NET_VALUE',
    dueDate: 'PARTNER_ORDER_DUE_DATE',
    activityDueDate: 'LABEL_ACTIVITY_DUE_DATE',
    orderContactNumber: 'PARTNER_ORDER_CONTACT_NUMBER',
    contractTypeDescription: 'CONTRACT_TYPE',
    contractName: 'CONTRACT_NAME',
    contractStatusDescription: 'CONTRACT_STATUS',
    expirationDate: 'CONTRACT_EXPIRATION_DATE',
    componentName: 'COMPONENT_NAME',
    componentID: 'COMPONENT_ID',
    equipmentKey: 'COMPONENT_EQUIPMENT_ID',
    componentOwnID: 'COMPONENT_OWN_ID',
    componentSerialNumber: 'GENERIC_LABEL_SERIAL_NUMBER',
    componentInstallationDate: 'GENERIC_LABEL_INSTALLATION_DATE',
    name: 'GENERIC_LABEL_OPTION_TITLE',
    optionDescription: 'GENERIC_LABEL_OPTION_DESCRIPTION', // optionsUpgrades
    disclaimer: 'GENERIC_LABEL_OPTION_DISCLAIMER',
    optionStatus: 'GENERIC_LABEL_OPTION_AVAILABILITY', // optionsUpgrades
    tlStatus: 'GENERIC_LABEL_TRIAL_AVAILABILITY',
    label: 'EXPORT_HEADER_CONTRACTS_LABEL',
    value: 'EXPORT_HEADER_CONTRACTS_VALUE',
    requestDescription: 'LABEL_PSR_SHORT_DESCRIPTION',
    quoteNumber: 'LABEL_PSR_QUOTE_NUMBER',
    modalityDescription: 'LABEL_MODALITY',
    submitDate: 'LABEL_PSR_CREATION_DATE',
    psrTypeDescription: 'LABEL_PSR_STATUS',
    pmStatus: 'STATUS',
    invoiceNumber: 'LABEL_INVOICE_NUMBER',
    invoiceStatus: 'LABEL_INVOICE_STATUS',
    creationDate: 'LABEL_INVOICE_DATE',
    invoiceType: 'LABEL_INVOICE_TYPE',
    poNumber: 'LABEL_INVOICE_PO_NUMBER',
    invoiceNetValue: 'LABEL_INVOICE_VALUE',
    updateNumber: 'SYSTEM_UPDATES_UPDATE_NUMBER',
    category: 'CATEGORY',
    title: 'SYSTEM_UPDATES_DOWNLOADS_SINGULAR',
    systemUpdateDueDate: 'LABEL_DUE_DATE',
    modality: 'LABEL_MODALITY',
    customerDescription: 'LABEL_CUSTOMER_DESCRIPTION',
    updateStatus: 'GENERIC_LABEL_UPDATE_STATUS',
    state: 'GENERIC_LABEL_STATE',
    completedDate: 'LABEL_COMPLETION_DATE',
    startDate: 'LABEL_CONTRACT_START_DATE',
    qmtext: 'PLANNED_ACTIVITY_DETAILS',
    filename: 'LABEL_FILE_NAME',
    filesize: 'LABEL_FILE_SIZE',
    cmdbMaterialNumberConsolidated: 'LABEL_MATERIAL_NUMBER',
    performedDate: 'LABEL_PERFORMED_DATE',
    flStartupDate: 'LABEL_STARTUP_DATE',
    violationHours: 'LABEL_VIOLATION_HOURS',
    citHours: 'LABEL_CIT_HOURS'
  };
  dateFormatRequiredProp = [
    'plannedStart',
    'plannedEnd',
    'installedOn',
    'warrantyEnd',
    'endOfSupport',
    'warrantyStart',
    'ticketCreationTimestamp',
    'plannedStartDatetime',
    'plannedEndDatetime',
    'dueDate',
    'expirationDate',
    'componentInstallationDate',
    'submitDate',
    'creationDate',
    'systemUpdateDueDate',
    'flStartupDate'
  ];
  EXPORT_COLUMN_ORDER_OPTIONS;
  statusMapRequiredProp = [
    'status',
    'orderStatus',
    'optionStatus',
    'pmStatus',
    'invoiceStatus',
    'category',
    'updateStatus'
  ];
  pmStatus = {
    unknown: '-1',
    due: '0',
    scheduled: '1',
    scheduledPastDueDate: '2',
    overdue: '3',
    needReschedule: '4',
    completedPerformed: '5',
    completedNotPerformed: '6'
  };
  exportTranslationMapping = {
    status: {
      0: 'EQUIPMENT_STATUS_EQUIPMENT_OK',
      1: 'EQUIPMENT_STATUS_EQUIPMENT_WITH_BREAKDOWN',
      2: 'EQUIPMENT_STATUS_EQUIPMENTS_WITH_PARTIAL_BREAKDOWN',
      3: 'EQUIPMENT_STATUS_EQUIPMENT_OK',
      '1A': 'EQUIPMENT_STATUS_NEEDS_ATTENTION_CRITICAL'
    },
    orderStatus: {
      1: 'PARTNER_ORDER_STATUS_OPEN',
      2: 'PARTNER_ORDER_STATUS_CLOSED'
    },
    optionStatus: {
      AlreadyInstalled: 'OPTION_AVAILABILITY_LEGEND_ALREADYINSTALLED',
      WithoutPrerequisites: 'OPTION_AVAILABILITY_LEGEND_WITHOUTPREREQUISITE',
      WithPrerequisites: 'OPTION_AVAILABILITY_LEGEND_WITHPREREQUISITE'
    },
    pmStatus: {
      [-1]: 'LABEL_ACTIVITY_STATUS_-1',
      0: 'LABEL_ACTIVITY_STATUS_0',
      1: 'LABEL_ACTIVITY_STATUS_1',
      2: 'LABEL_ACTIVITY_STATUS_2',
      3: 'LABEL_ACTIVITY_STATUS_3',
      4: 'LABEL_ACTIVITY_STATUS_4',
      5: 'LABEL_ACTIVITY_STATUS_5',
      6: 'LABEL_ACTIVITY_STATUS_6',
      7: 'LABEL_ACTIVITY_STATUS_7',
      8: 'LABEL_ACTIVITY_STATUS_8'
    },
    invoiceStatus: {
      1: 'LABEL_INVOICE_STATUS_1',
      2: 'LABEL_INVOICE_STATUS_2',
      3: 'LABEL_INVOICE_STATUS_3'
    }
  };

  constructor(private translateService: TranslateService,
    private configService: CountryConfigRestService,
    private exportUtilService: ExportUtilService
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getRequestBody(): Observable<any> {
    const exportOrderKey = this.exportOrderConfig;
    const fileNameKey = this.fileName;
    const exportHeaderTranslationMap: {[key: string]: string} = this.exportHeaderTranslationMap;

    return this.configService.getConfig().pipe(
      switchMap((config: {[ key: string]: string }) => {
        const exportOrder: string[] = config[exportOrderKey].split(',');
        return this.translateService.get(exportOrder.map(key => exportHeaderTranslationMap[key]))
          .pipe(
            map((translate: {[ key: string]: string }) =>
              exportOrder.map(key => translate[exportHeaderTranslationMap[key]])),
            withLatestFrom(of(config), of(exportOrder)),
          );
      }),
      map(([headers, config, exportOrder]) => {
        const dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;
        const fileName = config[fileNameKey];

        if (exportOrder && fileName && dateTimePattern) {

          const columnsToBeExported =
            this.generateExportColumnDefinition(exportOrder, dateTimePattern);

          const exportedArr =
            this.exportUtilService.getExportArray(this.exportGenerate(), headers,
              columnsToBeExported);

          return {
            headers,
            data: exportedArr
          };
        }
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  generateExportColumnDefinition(exportOrder, dateTimePattern): ExcelExportDefinition[] {

    const exportedPropertiesColumnDefinition: ExcelExportDefinition[] = [];

    _.forEach(exportOrder, (item) => {

      if (_.includes(this.dateFormatRequiredProp, item)) {

        const dateObj = {
          columnValue: item,
          dateFormat: dateTimePattern
        };
        exportedPropertiesColumnDefinition.push(dateObj);
      } else if (_.includes(this.statusMapRequiredProp, item)) {

        const statusObj = {
          columnValue: item,
          mapping: this.exportTranslationMapping[item]
        };
        exportedPropertiesColumnDefinition.push(statusObj);
      } else {
        const obj = {
          columnValue: item
        };
        exportedPropertiesColumnDefinition.push(obj);
      }
    });
    return exportedPropertiesColumnDefinition;
  }
}
