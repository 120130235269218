import { EVENT_MANAGER_PLUGINS, EventManager } from '@angular/platform-browser';
import { Inject, NgZone } from '@angular/core';

export class CustomEventManager extends EventManager {
  constructor(@Inject(EVENT_MANAGER_PLUGINS) plugins: any[], private zone: NgZone) {
    super(plugins, zone);
  }

  addGlobalEventListener(target: string, eventName: string, handler: Function): Function {

    if (eventName.endsWith('out-zone')) {
      eventName = eventName.split('.')[0];
      return this.zone.runOutsideAngular(() =>
        super.addGlobalEventListener(target, eventName, handler));
    }

    return super.addGlobalEventListener(target, eventName, handler);
  }
}
