import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { AdditionalContacts } from '../../../core/models/additional-contacts';
import { Equipment } from '../../../core/models/equipment/equipment';
import * as _ from 'lodash';

@Component({
  selector: 'hl-equipment-contact-widget',
  templateUrl: './equipment-contact-widget.component.html'
})
export class EquipmentContactWidgetComponent implements OnInit, OnChanges {
  @Input() equipment: Equipment;
  @Input() headerLabel: string;
  @Input() noItemFoundLabel: string;

  contacts: AdditionalContacts[];
  isContactsLoaded: boolean;

  constructor(private equipmentRestService: EquipmentRestService) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['equipment'] && !changes['equipment'].firstChange) {
      this.init();
    }
  }

  init() {
    this.loadContacts();
    this.isContactsLoaded = false;
  }

  loadContacts() {
    this.equipmentRestService.getEquipmentContacts(this.equipment.key).subscribe((contacts) => {
      this.contacts = [];
      _.forEach(contacts, (contact) => {
        if (contact && (contact.fullName || contact.email)) {
          this.contacts.push(contact);
        }
      });
      this.isContactsLoaded = true;
    });
  }
}
