import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DataDiagram } from '../../diagram/diagram';
import { EquipmentUtilService } from '../../core/services/equipment/equipment-util.service';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';

@Component({
  selector: 'hl-equipment-status-widget',
  templateUrl: './equipment-status-widget.component.html'
})
export class EquipmentStatusWidgetComponent implements OnInit, OnDestroy {

  diagramData: DataDiagram[] = [];
  isLoaded = false;
  totalCount = 0;

  protected readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private equipmentUtilService: EquipmentUtilService,
    private translateService: TranslateService,
    private myFiltersService: MyFiltersAdapterService) { }

  ngOnInit() {
    this.myFiltersService.filterEquipmentKeys$.pipe(
      switchMap(keys => this.loadEquipmentStatus(keys)),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadEquipmentStatus(equipmentKeys: string[]) {
    this.isLoaded = false;
    this.totalCount = 0;
    this.diagramData = [];
    return this.equipmentUtilService.loadEquipmentStatusForKeys(equipmentKeys)
      .pipe(
        switchMap(statuses => this.translateService.get(statuses.map(i => i.title))
          .pipe(map(translate => [statuses, translate]))
        ),
        tap(([statuses, translate]) => {
          this.diagramData = statuses
            .filter(item => item.status !== 'All')
            .map(item => ({
                color: item.class,
                value: item.count,
                name: translate[item.title],
                routerLink: '/equipment',
                queryParams: { operationalState: item.class, showTicketsTab: true }
              } as DataDiagram));

          // a list with 4 status result entries is returned, one for each color, and the last with the total number
          if (statuses.length === 4) {
            this.totalCount = statuses[3].count;
          }
          this.isLoaded = true;
        })
      );
  }
}
