export const temp = [
  25.83,
  25.81,
  25.74,
  25.71,
  25.8,
  25.21,
  25.21,
  25.21,
  25.21,
  25.2,
  25.2,
  25.21,
  25.18,
  25.17,
  25.17,
  25.13,
  25.12,
  25.11,
  25.1,
  25.27,
  25.27,
  25.25,
  25.25,
  25.24,
  25.24,
  25.22,
  25.2,
  25.17,
  25.25,
  25.24,
  25.24,
  25.21,
  25.21,
  25.22,
  25.21,
  25.19,
  25.19,
  25.19,
  25.15,
  25.12,
  25.27,
  25.27,
  25.28,
  25.27,
  25.27,
  25.27,
  25.25,
  25.24,
  25.24,
  25.18,
  25.18,
  25.13,
  25.09,
  25.08,
  25.17,
  25.18,
  25.17,
  25.17,
  25.17,
  25.13,
  25.11,
  25.11,
  25.04,
  24.93,
  25.44,
  25.59,
  25.97,
  25.97,
  25.99,
  26.04,
  25.98,
  26.06,
  25.99,
  26,
  26.08,
  25.83,
  25.86,
  25.81,
  25.75,
  25.86,
  25.86,
  25.86,
  25.85,
  25.85,
  25.87,
  25.88,
  25.88,
  25.89,
  25.89,
  25.89,
  25.9,
  25.87,
  25.86,
  25.84,
  25.94,
  26.01,
  26.18,
  26.24,
  26.22,
  26.4,
  26.13,
  26.04,
  26.11,
  25.84,
  25.59,
  25.55,
  25.3,
  25.3,
  25.28,
  25.29,
  25.48,
  25.48,
  25.48,
  25.48,
  25.49,
  25.48,
  25.48,
  25.47,
  25.46,
  25.49,
  25.49,
  25.48,
  25.46,
  25.49,
  25.48,
  25.47,
  25.47,
  25.41,
  25.41,
  25.39,
  25.36,
  25.76,
  25.84,
  25.89,
  26.27,
  27.15,
  29.13,
  31.02,
  30.03,
  26.05,
  25.64,
  25.72,
  25.67,
  25.42,
  25.36,
  25.3,
  25.3,
  25.27,
  25.37,
  25.47,
  25.46,
  25.46,
  25.44,
  25.41,
  25.37,
  25.39,
  25.4,
  25.66,
  25.73,
  25.76,
  25.67,
  25.37,
  25.38,
  25.52,
  25.45,
  25.35,
  25.2,
  25.21,
  25.2,
  25.17,
  25.16,
  25.12,
  25.09,
  25.07,
  24.99,
  24.9,
  24.96,
  25.1,
  25.05,
  25.04,
  25.02,
  25,
  24.66,
  25.15,
  25.15,
  25.16,
  25.28,
  25.41,
  25.42,
  25.43,
  25.45,
  25.43,
  25.38,
  25.41,
  25.48,
  25.49,
  25.59,
  25.6,
  25.66,
  25.58,
  25.57,
  25.5,
  25.74,
  25.74,
  25.73,
  25.75,
  25.84,
  25.88,
  25.83,
  25.83,
  25.76,
  25.81,
  25.77,
  25.74,
  25.74,
  25.73,
  25.74,
  25.75,
  25.76,
  25.75,
  25.76,
  25.76,
  25.68,
  25.73,
  25.71,
  25.71,
  25.68,
  25.69,
  25.68,
  25.69,
  25.69,
  25.74,
  25.75,
  25.77,
  25.72,
  25.71,
  25.71,
  25.8,
  25.77,
  25.69,
  25.68,
  25.67,
  25.65,
  25.65,
  25.56,
  25.55,
  25.51,
  25.47,
  25.44,
  25.43,
  25.41,
  25.63,
  25.64,
  25.64,
  25.63,
  25.63,
  25.62,
  25.61,
  25.61,
  25.58,
  25.57,
  25.53,
  25.43,
  25.41,
  25.36,
  25.48,
  25.57,
  25.83,
  25.85,
  25.82,
  25.62,
  25.6,
  25.47,
  25.33,
  25.21,
  25.21,
  25.21,
  25.18,
  25.09,
  25.12,
  25.11,
  25.08,
  24.96,
  25.13,
  25.07,
  25.05,
  25.01,
  24.99,
  24.78,
  24.33,
  24.68,
  25.08,
  24.41,
  24.71,
  25.09,
  25.1,
  25.08,
  25.08,
  24.98,
  25.07,
  24.98,
  24.96,
  24.88,
  24.75,
  24.59,
  24.36,
  24.28,
  24.22,
  23.82,
  23.8,
  25.2,
  25.52,
  25.88,
  25.87,
  25.91,
  25.87,
  25.64,
  25.49,
  25.51,
  25.51,
  25.5,
  25.5,
  25.52,
  25.49,
  25.49,
  25.49,
  25.51,
  25.45,
  25.42,
  25.4,
  25.32,
  25.08,
  25.5,
  25.54,
  25.5,
  25.5,
  25.49,
  25.47,
  25.97,
  26.02,
  25.87,
  25.86,
  25.94,
  25.89,
  25.96,
  25.86,
  25.86,
  25.75,
  25.72,
  25.73,
  25.66,
  25.88,
  25.93,
  25.94,
  25.95,
  25.96,
  25.96,
  25.96,
  25.95,
  25.77,
  26.08,
  26.25,
  26.62,
  26.7,
  26.64,
  26.2,
  26.16,
  26.14,
  28.11,
  30.01,
  32.56,
  30.01,
  26.8,
  24.64,
  24.64,
  24.61,
  24.6,
  24.6,
  24.6,
  24.59,
  24.59,
  24.59,
  24.58,
  24.58,
  24.58,
  24.58,
  24.57,
  24.57,
  24.58,
  24.57,
  24.57,
  24.58,
  24.57,
  24.57,
  24.56,
  24.64,
  24.62,
  24.62,
  24.6,
  24.4,
  24.36,
  24.33,
  24.27,
  24.15,
  24.15,
  24.21,
  24.22,
  24.26,
  24.27,
  24.27,
  24.28,
  24.45,
  24.47,
  24.51,
  24.56,
  24.6,
  24.64,
  24.73,
  24.76,
  24.8,
  24.82,
  24.84,
  24.81,
  24.77,
  24.67,
  24.64,
  24.6,
  24.53,
  24.51,
  24.5,
  24.68,
  24.7,
  24.75,
  24.92,
  24.95,
  24.98,
  25,
  25.01,
  25.12,
  25.22,
  25.41,
  25.4,
  25.4,
  25.7,
  25.7,
  25.7,
  25.72,
  24.93,
  24.9,
  24.84,
  24.54,
  25.28,
  25.27,
  25.26,
  25.19,
  25.11,
  24.93,
  24.72,
  24.69,
  24.81,
  24.81,
  24.83,
  24.98,
  25.01,
  25.03,
  25.09,
  25.11,
  25.13,
  25.14,
  25.18,
  25.2,
  25.15,
  25.25,
  25.62,
  25.65,
  25.65,
  25.77,
  25.79,
  25.67,
  25.67,
  25.3,
  25.29,
  25.25,
  25.25,
  25.25,
  25,
  24.83,
  24.88,
  24.97,
  24.98,
  25.01,
  25.04,
  25.18,
  25.27,
  25.31,
  25.36,
  25.44,
  25.52,
  25.51,
  25.89,
  25.88,
  26.01,
  26.17,
  26.2,
  26.92,
  26.52,
  26.57,
  26.5,
  26.36,
  26.15,
  25.63,
  25.6,
  25.55,
  25.53,
  25.49,
  25.55,
  25.55,
  25.53,
  25.71,
  25.71,
  25.71,
  25.71,
  25.7,
  25.7,
  25.69,
  25.63,
  25.62,
  25.5,
  25.6,
  25.71,
  25.72,
  25.69,
  25.8,
  25.9,
  25.43,
  25.18,
  25.05,
  24.71,
  24.37,
  24.51,
  24.56,
  24.58,
  24.63,
  24.84,
  24.85,
  24.9,
  24.94,
  24.95,
  24.95,
  24.97,
  24.96,
  24.85,
  24.79,
  25.39,
  25.44,
  25.57,
  25.43,
  23.94,
  24.03,
  23.7,
  23.58,
  23.4,
  23.36,
  23.33,
  23.18,
  23.39,
  23.39,
  23.39,
  23.39,
  23.39,
  23.39,
  23.38,
  23.38,
  23.34,
  23.34,
  23.14,
  23.32,
  23.44,
  23.44,
  23.44,
  23.43,
  23.42,
  23.42,
  23.42,
  23.42,
  23.41,
  23.38,
  23.35,
  23.34,
  23.33,
  23.28,
  23.26,
  23.16,
  23.12,
  23.3,
  23.41,
  23.43,
  23.44,
  23.45,
  23.45,
  23.44,
  23.42,
  23.4,
  23.38,
  23.32,
  23.27,
  23.46,
  23.53,
  23.53,
  23.51,
  23.52,
  23.47,
  23.44,
  23.44,
  23.43,
  23.4,
  23.35,
  23.28,
  23.46,
  23.42,
  23.39,
  23.37,
  23.3,
  23.44,
  23.51,
  23.53,
  23.85,
  23.85,
  23.83,
  23.78,
  23.74,
  23.77,
  23.75,
  23.75,
  23.84,
  23.82,
  23.8,
  23.77,
  23.77,
  23.61,
  23.57,
  23.61,
  23.8,
  23.85,
  23.83,
  23.58,
  23.88,
  23.95,
  23.99,
  23.92,
  24.02,
  23.95,
  23.93,
  23.88,
  23.84,
  23.86,
  23.79,
  23.68,
  23.71,
  23.69,
  23.69,
  23.69,
  23.69,
  23.7,
  23.69,
  23.69,
  23.68,
  23.69,
  23.67,
  23.65,
  23.62,
  23.6,
  23.57,
  23.49,
  24.38,
  24.63,
  23.48,
  23.47,
  23.44,
  23.15,
  23.12,
  22.74,
  22.7,
  22.67,
  22.67,
  22.83,
  22.86,
  22.91,
  22.91,
  23,
  23.16,
  23.15,
  23.6,
  23.66,
  23.72,
  23.71,
  23.83,
  23.77,
  23.78,
  23.72,
  23.55,
  23.5,
  23.41,
  22.9,
  22.86,
  22.84,
  22.79,
  22.75,
  22.8,
  22.73,
  22.74,
  22.7,
  22.69,
  22.68,
  22.67,
  22.67,
  22.69,
  22.72,
  22.72,
  22.72,
  22.78,
  22.93,
  22.97,
  22.98,
  22.99,
  23.01,
  22.87,
  23.28,
  23.38,
  23.44,
  23.75,
  23.85,
  23.47,
  23.5,
  23.53,
  22.64,
  22.58,
  22.5,
  22.4,
  22.29,
  22.26,
  22.26,
  22.25,
  22.25,
  22.23,
  22.22,
  22.21,
  22.16,
  22.31,
  22.35,
  22.36,
  22.35,
  22.35,
  22.34,
  22.33,
  22.33,
  22.32,
  22.28,
  22.24,
  22.23,
  22.43,
  22.41,
  22.4,
  22.38,
  22.35,
  22.34,
  22.31,
  22.3,
  22.29,
  22.26,
  22.43,
  22.42,
  22.41,
  22.4,
  22.39,
  22.38,
  22.35,
  22.35,
  22.31,
  22.33,
  22.49,
  22.5,
  22.48,
  22.48,
  22.46,
  22.46,
  22.43,
  22.51,
  22.61,
  22.6,
  22.6,
  22.6,
  22.61,
  22.6,
  22.6,
  22.6,
  22.6,
  22.59,
  22.78,
  22.8
];
