import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { CustomerUtilService } from '../../../core/services/customer/customer-util.service';
import { isEqual } from 'lodash';
import { ImpersonationCacheService } from '../../../core/services/cache/impersonation-cache.service';
import { SpaceValidator } from '../../validators/space.validator';
import { takeUntil } from 'rxjs/operators';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { CustomerData, CustomerNameType } from '../../../core/models/customer/customer-name-options';

@Component({
  selector: 'hl-edit-customer-name-modal',
  templateUrl: './edit-customer-name-modal.component.html'
})
export class EditCustomerNameModalComponent extends BaseFormModalPopup
  implements OnInit {

  private _customerId: string;
  @Input() set customerId(customerId: string) {
    this._customerId = customerId;
    this.showSpinner = true;
    this.customerUtilService.clearCustomerDataCache(customerId);
    this.show();
    this.loadCustomerData(customerId);
  }

  get customerId() {
    return this._customerId;
  }

  private _customNameLengthLimit: number;
  @Input() set customNameLengthLimit(value) {
    this._customNameLengthLimit = value;
  }

  get customNameLengthLimit() {
    return Math.min(this._customNameLengthLimit || 600, 600);
  }

  get type() {
    return this.form.get('type').value;
  }

  get customName(): string {
    return this.form.get('customName').value || '';
  }

  get latitude(): number {
    return this.form.get('latitude').value || null;
  }

  get longitude(): number {
    return this.form.get('longitude').value || null;
  }

  isFormSubmitted = false;
  isLatitudeAndLongitudeInvalid = false;
  showGeoLocation = false;
  form: FormGroup;
  customerData: CustomerData;

  constructor(private fb: FormBuilder,
    private customerUtilService: CustomerUtilService,
    private impersonationCacheService: ImpersonationCacheService,
    private countryConfigRestService: CountryConfigRestService,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
    this.showSpinner = true;
    this.showGeoLocation = false;
    this.countryConfigRestService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.showGeoLocation = isEqual(configResponse.FEATURE_TOGGLE_EDIT_GEO_COORDINATES, 'true');
    });
  }

  postFormData() {
    this.showSpinner = true;
    const newData = {...this.form.value, customName: this.customName.trim()};
    newData.latitude = newData.latitude ? parseFloat(('' + newData.latitude).replace(',', '.')) : null;
    newData.longitude = newData.longitude ? parseFloat(('' + newData.longitude).replace(',', '.')) : null;

    this.customerUtilService.postCustomerChange(this.customerId, newData).subscribe(actualCustomerName => {
      this.impersonationCacheService.clearCacheByCountry(this.customerUtilService.customerToEdit.country);
      this.impersonationCacheService.getCustomerListByCountry(this.customerUtilService.customerToEdit.country);
      this.customerUtilService.customerEdited.emit(actualCustomerName);
      this.hide();
    });
  }

  setNameType(nameTypeNumber: string) {
    this.form.get('type').patchValue(nameTypeNumber);
  }

  isSaveDisabled() {
    return !this.form || this.isCustomerNameInvalid() || (!this.didCustomerNameDataChanged() && !this.didCustomerGeoLocationChanged());
  }

  didCustomerNameDataChanged(): boolean {
    return this.type !== this.customerData.type ||
      (this.customName ? this.customName : null) !== this.customerData.customName;
  }

  isCustomerNameInvalid(): boolean {
    return this.type === CustomerNameType.customName &&
      (this.customNameLengthLimit < this.customName.length ||
        this.customName.trim().length === 0);
  }

  didCustomerGeoLocationChanged(): boolean {
    return this.latitude != this.customerData.latitude || this.longitude != this.customerData.longitude;
  }

  loadCustomerData(customerId: string) {
    this.customerUtilService.getCustomerData(customerId).subscribe(customerNameOptions => {
      this.customerData = customerNameOptions;
      this.createForm(this.customerData, );
      this.showSpinner = false;
    });
  }

  save() {
    this.isFormSubmitted = true;
    if (this.form.valid) {
      this.postFormData();
    }
  }

  private createForm(customerData: CustomerData) {
    this.form = this.fb.group({
      type: [customerData.type],
      customName: [customerData.customName],
      latitude: [customerData.latitude ? customerData.latitude : ''],
      longitude: [customerData.longitude ? customerData.longitude : '']
      }, {
        validator: [this.latitudeAndLongitudeValidator()]
      }
    );
    this.form.get('type').valueChanges.subscribe(type => {
      const customName = this.form.get('customName');
      customName.setValidators(
        type === 'customName' && [
          Validators.required, Validators.maxLength(this.customNameLengthLimit), Validators.pattern(/\S+/), SpaceValidator.noWhiteSpace]
      );
      customName.updateValueAndValidity();
    });
  }

  latitudeAndLongitudeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!this.form) {
        return {status: 'form does not exist'};
      }
      const formLatitude = '' + this.form.value['latitude'];
      const formLongitude = '' + this.form.value['longitude'];
      const latitude = parseFloat(formLatitude.replace(',', '.'));
      const longitude = parseFloat(formLongitude.replace(',', '.'));
      const latitudeValid = (latitude >= -90 && latitude <= 90) || formLatitude === '';
      const longitudeValid = (longitude >= -180 && longitude <= 180) || formLongitude === '';
      this.isLatitudeAndLongitudeInvalid = !(latitudeValid && longitudeValid);
      return this.isLatitudeAndLongitudeInvalid ? {status: 'latitude and longitude is not valid'} : null;
    };
  }

}
