import { Injectable } from '@angular/core';
import { NotifStatus } from '../../core-constants.service';
import { SelectOption } from '../../models/select-option';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { Observable } from 'rxjs';
import { SortSkeleton } from '../../../shared/sorting/sort-skeleton';
import { SortDirection } from '../../../shared/sorting/sort-object';
import { map } from 'rxjs/operators';

@Injectable()
export class TicketsConstantsService {

  constructor(private configService: CountryConfigRestService) {
  }

  getSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'ticketCreationTimestamp',
        sortDir: SortDirection.DESC
      },
      items: [{
        title: 'GENERIC_LABEL_TICKET_NUMBER',
        value: 'ticketNumber'
      }, {
        title: 'SHORT_TEXT',
        value: 'description'
      }, {
        title: 'GENERIC_LABEL_PRODUCT_NAME',
        value: 'productName'
      }, {
        title: 'TICKET_FILTER_CREATIONDATE_CATEGORY',
        value: 'ticketCreationTimestamp'
      }, {
        title: 'PATIENT_SITUATION',
        value: 'dangerForPatient'
      }, {
        title: 'GENERIC_LABEL_OPERATIONAL_STATE',
        value: 'problemSeverity'
      }, {
        title: 'GENERIC_LABEL_SIEMENS_EQUIPMENT_ID',
        value: 'siemensEquipmentId'
      }, {
        title: 'GENERIC_LABEL_MY_EQUIPMENT_NAME',
        value: 'myEquipmentName'
      }, {
        title: 'GENERIC_LABEL_CUSTOMER_NAME',
        value: 'customerName'
      }]
    };

  }

  getSortSkeletonForHistory(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'ticketCreationTimestamp',
        sortDir: SortDirection.DESC
      },
      items: [{
        title: 'GENERIC_LABEL_TICKET_NUMBER',
        value: 'ticketNumber'
      }, {
        title: 'SHORT_TEXT',
        value: 'description'
      }, {
        title: 'TICKET_FILTER_CREATIONDATE_CATEGORY',
        value: 'ticketCreationTimestamp'
      }, {
        title: 'PATIENT_SITUATION',
        value: 'dangerForPatient'
      }, {
        title: 'GENERIC_LABEL_OPERATIONAL_STATE',
        value: 'problemSeverity'
      }]
    };
  }

  getDangerForPatientsSkeleton() {
    return [
      {
        title: 'TICKET_FILTER_PATIENTSITUATIONCRITICAL_FALSE',
        value: false
      },
      {
        title: 'TICKET_FILTER_PATIENTSITUATIONCRITICAL_TRUE',
        value: true
      }
    ];
  }

  getStatusSkeleton(): Observable<SelectOption[]> {

    return this.configService.getConfig().pipe(map(response => {
      return [
        {
          title: 'TICKET_STATE_OPEN',
          value: response.STATUS_IDENTIFIER_OPEN_NOTIFICATIONS
        },
        {
          title: 'TICKET_STATE_CLOSED',
          value: response.STATUS_IDENTIFIER_CLOSED_NOTIFICATIONS
        }
      ];
    }));
  }

  getOpenCloseSkeleton(): SelectOption[] {
    return [
      {
        title: 'LABEL_OPEN_SERVICE_TICKETS',
        value: NotifStatus.OPEN
      },
      {
        title: 'LABEL_CLOSED_SERVICE_TICKETS',
        value: NotifStatus.CLOSED
      }
    ];
  }

}
