import { ActivationStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TeamplayWidgetViewModel } from '../../core/view-models/teamplay-widget-view-model';
import { DataDiagram, SeriesData } from '../../diagram/diagram';
import { ImpersonationCommunicationService } from '../../core/component-communication-services/impersonation/impersonation-communication.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamplayRestService } from '../../core/rest-services/teamplay-rest.service';
import { MyFiltersAdapterService } from '../../core/services/my-filters-adapter.service';
import { TeamplayData } from '../../core/models/teamplay-data-widget';
import { filter, map, maxBy, meanBy, minBy, uniqBy, union, sumBy } from 'lodash';

@Component({
  selector: 'hl-teamplay-widget',
  templateUrl: './teamplay-widget.component.html'
})
export class TeamplayWidgetComponent implements OnInit, OnDestroy {
  isLoaded = false;
  pieChartData: TeamplayWidgetViewModel[];
  teamplayUrl: string;
  barChartData: SeriesData[] = [];
  diagramData: DataDiagram[] = [];
  totalCount = 0;
  customColors: object[] = [];
  maxValue: number;
  myEquipmentList: string[];

  constructor(
    private teamplayRestService: TeamplayRestService,
    private translateService: TranslateService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private myFiltersService: MyFiltersAdapterService,
    private router: Router) {
  }

  private readonly unsubscribe$: Subject<void> = new Subject();

  ngOnInit() {

    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart && e.snapshot.data &&
        e.snapshot.data.stateName && e.snapshot.data.stateName.startsWith('teamplay')) {
        const win = window.open(this.teamplayUrl, '_blank');
        win.focus();
        this.router.navigateByUrl('/dashboard');
      }
    });

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.isLoaded = false;

    this.teamplayRestService.getTeamplayData().pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
      const widgetData = filter(response.teamplayData, data => this.myEquipmentList.indexOf(data.equipmentKey) !== -1);
      this.pieChartData = this.tranformDataForWidgets(widgetData);
      this.pieChartData = TeamplayWidgetViewModel.addColors(this.pieChartData);
      this.totalCount = this.pieChartData.reduce((sum, current) => sum + current.avgNumberOfExams, 0);
      this.teamplayUrl = response.teamplayLink;
      this.maxValue = Math.max(...this.pieChartData.map(field => field.highestAvgSystemTime));

      this.diagramData = this.pieChartData
        .map(item => ({
          color: item.color,
          value: item.avgNumberOfExams,
          name: '(' + Math.round(100 * item.avgNumberOfExams / this.totalCount) + '%)',
          routerLink: '/teamplay'
        } as DataDiagram));

      this.customColors = this.pieChartData.map(item => ({
        name: item.modalityName,
        value: item.color
      }));

      const tooltipLabels = [
        'TEAMPLAY_BAR_CHART_TOOLTIP_HIGHEST_SYSTEM_AVG',
        'TEAMPLAY_BAR_CHART_TOOLTIP_AVG',
        'TEAMPLAY_BAR_CHART_TOOLTIP_LOWEST_SYSTEM_AVG'
      ];

      this.translateService.get(tooltipLabels).subscribe((translations) => {
        this.barChartData = this.pieChartData.map(item => ({
          name: item.modalityName,
          value: item.avgExamDurationMin,
          extra: {
            highestAvgSystemLabel:
              translations[tooltipLabels[0]].replace(/#SYSTEM#/, item.highestAvgSystemNames[0]).replace(/#MINUTES#/, item.highestAvgSystemTime),
            avgSystemModalityLabel:
              translations[tooltipLabels[1]].replace(/#MODALITY#/, item.modalityName).replace(/#MINUTES#/, item.avgExamDurationMin),
            lowestAvgSystemLabel:
              translations[tooltipLabels[2]].replace(/#SYSTEM#/, item.lowestAvgSystemNames[0]).replace(/#MINUTES#/, item.lowestAvgSystemTime),
            highestAvgSystemTime: item.highestAvgSystemTime,
            lowestAvgSystemTime: item.lowestAvgSystemTime,
            highestAvgSystemNames: item.highestAvgSystemNames,
            lowestAvgSystemNames: item.lowestAvgSystemNames
          }

        } as SeriesData));

      });

      this.isLoaded = true;

    });

  }

  tranformDataForWidgets(data: TeamplayData[]): TeamplayWidgetViewModel[] {
    const modalities = uniqBy(data, 'modalityName').map(d => d.modalityName);
    return map(modalities, modality => {
      const modalityDataList = filter(data, ['modalityName', modality]);
      const maxExamDur = maxBy(modalityDataList, 'avgExamDurationMin');
      const minExamDur = minBy(modalityDataList, 'avgExamDurationMin');
      const widgetViewModel = new TeamplayWidgetViewModel();
      widgetViewModel.highestAvgSystemTime = Math.round(maxExamDur.avgExamDurationMin);
      widgetViewModel.highestAvgSystemNames = filter(modalityDataList, ['avgExamDurationMin', maxExamDur.avgExamDurationMin])
        .map(item => item.systemName);
      widgetViewModel.lowestAvgSystemTime = Math.round(minExamDur.avgExamDurationMin);
      widgetViewModel.lowestAvgSystemNames = filter(modalityDataList, ['avgExamDurationMin', minExamDur.avgExamDurationMin])
        .map(item => item.systemName);
      widgetViewModel.avgNumberOfExams = Math.round(sumBy(modalityDataList, 'avgNumberOfExams'));
      widgetViewModel.avgExamDurationMin = Math.round(meanBy(modalityDataList, 'avgExamDurationMin'));
      widgetViewModel.modalityName = modality;
      return widgetViewModel;
    });
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    this.impersonationCommunicationService.onCountryLanguageChange$
      .pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.teamplayRestService.clearCache();
      this.init();
    });
    this.myFiltersService.filterEquipmentKeys$.pipe(takeUntil(this.unsubscribe$)).subscribe(equipmentKeys => {
      this.myEquipmentList = equipmentKeys;
      this.init();
    });
  }

  redirectToEquipment(data) {

    this.router
      .navigate(['/equipment'], {
        queryParams: {
          equipmentSystemNames: this.getAllSystemNamesQueryParam(data)
        }
      });
  }

  getAllSystemNamesQueryParam(data) {
    const allSystemNames = union(data.extra.highestAvgSystemNames, data.extra.lowestAvgSystemNames);
    return allSystemNames.map(system => '"' + system + '"').join(', ');
  }
}
