import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserUtilService } from '../../services/user/user-util.service';
import { roles } from '../../core-constants.service';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardAuthGuardService implements CanActivate {

  private viewAdvisoryOnly = false;

  constructor(private router: Router, private userUtilService: UserUtilService) {
  }

  canActivate() {
    return this.userUtilService.checkUserRoles(roles).pipe(map(roleResp => {
      const rolesSet = Object.keys(roleResp).filter(key => roleResp[key]);
      return !(rolesSet.length === 2 && rolesSet.includes('userRole') && rolesSet.includes('securityAdvisoryViewXiaRole'));
    }), tap((result) => {
        if (!result) {
          this.router.navigate(['/advisories'], {replaceUrl: true});
          this.viewAdvisoryOnly = true;
        }
      }
    ));
  }

  get isViewAdvisoryOnly() {
    return this.viewAdvisoryOnly;
  }
}
