import { OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SortSkeleton } from '../../shared/sorting/sort-skeleton';
import { DateRange } from '../models/date-range';
import { Equipment } from '../models/equipment/equipment';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { EquipmentUtilService } from '../services/equipment/equipment-util.service';
import { FilterUtilService } from '../utils/filter-util.service';
import { LifeNetUtilService } from '../utils/life-net-util.service';

export abstract class BaseEquipmentDetailListView<T> implements OnDestroy {
  // equipment for all sub tabs
  equipmentItem: Equipment = null;
  searchInput: string;
  sortSkeleton: SortSkeleton;

  datePattern = '';
  dateTimePattern = '';

  dateRange: DateRange = {
    fromDate: null,
    toDate: null
  };

  // set a default value, but later defined from country
  numberPagination: number;
  paginationItems: number;

  isLoaded: boolean;
  isAdvancedFilterCollapsed: boolean;

  rawList: T[];
  filteredLengthWithoutPagination = 0;
  listWithoutPagination: T[] = []; // filtered list without pagination
  viewModelList: T[]; // view model list with all filtering (including pagination)

  configService: CountryConfigRestService;
  equipmentUtilService: EquipmentUtilService;
  activatedRoute: ActivatedRoute;

  selectedItem: T;
  selectedItemIndex: number;
  protected readonly unsubscribe$: Subject<void> = new Subject();

  useParentRoute = false;

  /**
   * you can set default values for your properties in this method
   */
  abstract afterInitProperties(): void;

  /**
   * you can initialize values read from the config in this method
   * @param config
   */
  abstract afterConfigProperties(config: any): void;

  /**
   * should generate the filter object, that is used to filter the
   * list (by advanced filter and search)
   * @returns {any}
   */
  abstract getFilterObject(): any;

  constructor(
    public filterUtilService: FilterUtilService,
    configService: CountryConfigRestService,
    lifeNetUtilService: LifeNetUtilService,
    equipmentUtilService: EquipmentUtilService,
    activatedRoute: ActivatedRoute
  ) {
    this.configService = configService;
    this.equipmentUtilService = equipmentUtilService;
    this.activatedRoute = activatedRoute;
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  /**
   * @description Initialize of call stack
   */
  init() {
    (this.useParentRoute ? this.activatedRoute.parent.params : this.activatedRoute.params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: any) => {
        this.equipmentUtilService
          .getEquipment(params['id'])
          .pipe(map(e => ({...e, modality: e.modalityTranslation})))
          .subscribe(equipmentResponse => {
            this.equipmentItem = equipmentResponse;
            this.initProperties();
            this.configurePropBasedOnCountryConfig();
          });
      });
  }

  destroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @description Sets the config properties
   */
  configurePropBasedOnCountryConfig() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.setConfigProperties(configResponse);
    });
  }

  /**
   * @description Initialize view properties
   */
  initProperties() {
    this.viewModelList = [];
    this.listWithoutPagination = [];
    this.filteredLengthWithoutPagination = 0;

    this.datePattern = 'DDMMYYYY';
    this.dateTimePattern = 'DDMMYYYY HH:mm';

    this.numberPagination = 20;
    this.paginationItems = 20;

    this.isLoaded = false;
    this.isAdvancedFilterCollapsed = true;

    this.rawList = [];

    // additional properties for the derived class
    this.afterInitProperties();
  }

  /**
   * @description Set the config properties for view and component
   */
  setConfigProperties(config) {
    // set the pagination size
    this.numberPagination = _.parseInt(this.getPageSizeConfig(config));
    this.paginationItems = this.numberPagination;

    // get the locale date pattern
    this.datePattern = config.GENERIC_DATE_PATTERN;
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;

    // additional config properties for derived class
    this.afterConfigProperties(config);
  }

  /**
   * @description Filtering of view model list with different filters
   */
  onAdvancedFilterChange() {
    this.paginationItems = this.numberPagination;
    const filterObject = this.getFilterObject();

    // initial value for filtered result without pagination applied
    this.listWithoutPagination = this.filterUtilService.getListAfterApplyingFilterPipes(
      this.rawList,
      filterObject
    );

    this.filteredLengthWithoutPagination = this.listWithoutPagination.length;

    // view model list including all filter, pagination as well
    this.viewModelList = this.filterUtilService.applyIndividualFilter(
      this.listWithoutPagination,
      this.paginationItems,
      'limitTo'
    );
  }

  onLoadMore() {
    const filterObject = this.getFilterObject();

    // initial value for filtered result without pagination applied
    this.listWithoutPagination = this.filterUtilService.getListAfterApplyingFilterPipes(
      this.rawList,
      filterObject
    );

    this.filteredLengthWithoutPagination = this.listWithoutPagination.length;

    // view model list including all filter, pagination as well
    this.viewModelList = this.filterUtilService.applyIndividualFilter(
      this.listWithoutPagination,
      this.paginationItems,
      'limitTo'
    );
  }

  loadMore(extraItems?: number) {
    this.paginationItems += extraItems ? extraItems : this.numberPagination;
    this.onLoadMore();
  }

  onSortChange(event: SortSkeleton) {
    this.sortSkeleton = event;
    this.onAdvancedFilterChange();
  }

  /**
   * may be overwritten, if you need another page size than defined as default
   * (which is EQUIPMENT_LIST_PAGE_SIZE)
   * @param config
   * @returns {string}
   */
  getPageSizeConfig(config): string {
    return config.EQUIPMENT_LIST_PAGE_SIZE;
  }

  getViewModelList = () => {
    return this.viewModelList;
  }

  setSearchInputFromQueryParam(router: Router) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      const searchParam = params['search'];
      if (searchParam) {
        this.searchInput = searchParam;
      }
      const tabParam = params['tab'];
      if (tabParam && this.equipmentItem) {
        router.navigate(
          ['/equipment', this.equipmentItem.key, tabParam],
          {replaceUrl: true}
        );
      }
    });
  }
}
