import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { ChatConfiguration } from '../models/chat/chat-configuration';
import { Observable } from 'rxjs';
import { restEndPoint } from '../core-constants.service';

@Injectable()
export class ChatRestService {
    chatConfigRestName = restEndPoint + 'chat/config';

  constructor(private http: CacheableHttpClient) {
  }

  getChatConfiguration(): Observable<ChatConfiguration[]> {
      return this.http.get<ChatConfiguration[]>(this.chatConfigRestName);
  }
}
