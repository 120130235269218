import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { environment } from '../../../environments/environment';
import { HttpCacheService } from '../services/cache/http-cache.service';
import { MyFiltersAdapterService } from '../services/my-filters-adapter.service';

@Injectable()
export class ClearCacheUtilService {

  constructor(
    private myFiltersService: MyFiltersAdapterService,
    private http: CacheableHttpClient,
    private cacheService: HttpCacheService
  ) {

  }

  clear(url?: string) {
    this.clearCache(url);
    this.myFiltersService.initialize();
  }

  private clearCache(url?: string) {
    if (!environment.newCacheableHttpClient) {
      if (url) {
        this.cacheService.clearIndividualRequest(url);
      } else {
        this.cacheService.clearAll();
      }
    }
    this.http.clearCache(url);
  }
}
