export class IcCode {
  status: number;
  value: number;
  productName: string;
  siemensId: string;
  modality: string;
  updateNumber: string;
  equipmentKey: string;
  installStatus: string;
  leadingZeros?: string;
  locale?: string;

  static getDefaultPhoneNumberKey() {
    return 'PHONE_NUMBER_DEFAULT';
  }

  static getIcCodeErrorTypeEqNotAssigned() {
    return 5;
  }

  public isValid(): boolean {
    function hasIntValue(field: number): boolean {
      return field > 0;
    }

    function hasStringValue(field: string): boolean {
      return !!field;
    }

    return !this.containsValidValue() ||
      (hasIntValue(this.value) && hasStringValue(this.productName) && hasStringValue(this.siemensId));
  }

  public get successful(): boolean {
    return !this.status || this.status === 0;
  }

  public getErrorMessageKey(): string {
    switch (this.status) {
      case 2:
        return 'ICCODE_ERROR_TYPE_UNAUTHORIZED';
      case 3:
        return 'ICCODE_ERROR_TYPE_PARAM_VALIDATION';
      case 4:
        return 'ICCODE_ERROR_TYPE_EQ_NOT_FOUND';
      case IcCode.getIcCodeErrorTypeEqNotAssigned():
        return 'ICCODE_ERROR_TYPE_EQ_NOT_ASSIGNED';
      default:
        return 'ICCODE_ERROR_TYPE_GENERIC';
    }
  }

  public containsValidValue(): boolean {
    return this.successful || this.status === 1;
  }

  public getPhoneNumberKey(): string {
    switch (this.modality) {
      case 'SYNGO':
        return 'PHONE_NUMBER_SYNGO';
      case 'IN_VITRO':
        return 'PHONE_NUMBER_INVITRO';
      case 'MULTI_VENDOR':
        return 'PHONE_NUMBER_MULTIVENDOR';
      case 'IN_VIVO':
      default:
        return IcCode.getDefaultPhoneNumberKey();
    }
  }

  public shouldShowTicketCreateLink() {
    return this.status !== 2 && this.status !== 3 && this.status !== 4;
  }
}
