import { User } from './core/models/user';
import { TermsAndConditionModalComponent } from './shared/modal-popup/terms-and-condition-modal/terms-and-condition-modal.component';
import { IcCodeModalComponent } from './shared/modal-popup/ic-code-modal/ic-code-modal.component';
import { ImpersonationUtilsService } from './core/utils/impersonation-utils.service';
import { LifeNetUtilService } from './core/utils/life-net-util.service';
import { UserUtilService } from './core/services/user/user-util.service';
import { WindowService } from './core/window.service';
import { EnvironmentConfigRestService } from './core/rest-services/environment-config-rest.service';
import { addedRootClasses, localeSplitter, order2order } from './core/core-constants.service';
import { AddRootClassesService } from './core/component-communication-services/add-root-classes/add-root-classes.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import { combineLatest, timer } from 'rxjs';
import { IntroFeatureModalComponent } from './shared/modal-popup/intro-feature-modal/intro-feature-modal.component';
import * as moment from 'moment';
import { UserAuditTrailRestService } from './core/rest-services/user-audit-trail-rest.service';
import { NotificationsSubscriptionModalComponent } from './shared/modal-popup/notifications-subscription-modal/notifications-subscription-modal.component';
import { CountryConfigRestService } from './core/rest-services/country-config-rest.service';
import { withLatestFrom } from 'rxjs/operators';
import { HeaderComponent } from './header.component';
import { WalkMeUtilService } from './core/utils/walk-me-util.service';
import { CacheableHttpClient } from './core/services/cache/cacheable-http-client';
import { ConfigLoaderService } from './config-loader.service';

@Component({
  selector: 'hl-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  @ViewChild('termsAndConditionModal', {static: false}) termsAndConditionModal: TermsAndConditionModalComponent;
  @ViewChild('icCodeModal', {static: false}) icCodeModal: IcCodeModalComponent;
  @ViewChild('introFeatureModal', {static: false}) introFeatureModal: IntroFeatureModalComponent;
  @ViewChild('notificationsSubscriptionModal', {static: false}) notificationsSubscriptionModal: NotificationsSubscriptionModalComponent;
  @ViewChild(HeaderComponent, {static: false}) headerComponent: HeaderComponent;

  isAuthenticatedAndLoaded = false;
  o2oSafeUrl: SafeResourceUrl;
  translationFileLoaded = false;

  private static shouldShowNotificationDialogForThisCountry(config) {
    return !config.SUBPROCESSOR_FEATURE_AVAILABLE || _.isEqual(config.SUBPROCESSOR_FEATURE_AVAILABLE, 'true');
  }

  constructor(
    private http: CacheableHttpClient,
    private translate: TranslateService,
    private addRootClassService: AddRootClassesService,
    private environmentConfigRestService: EnvironmentConfigRestService,
    private windowService: WindowService,
    private userUtilService: UserUtilService,
    private lifeNetUtilService: LifeNetUtilService,
    private impersonationUtilService: ImpersonationUtilsService,
    private walkMeUtilService: WalkMeUtilService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private auditTrailService: UserAuditTrailRestService,
    private countryConfigRestService: CountryConfigRestService,
    private configLoaderService: ConfigLoaderService
  ) {
  }

  ngOnInit() {
    const user$ = this.userUtilService.getUser();
    const config$ = this.environmentConfigRestService.getEnvironmentConfig();

    combineLatest([user$, config$]).subscribe(([userResponse, configResponse]) => {
      let locale = '';
      if (userResponse.country && userResponse.language && userResponse.countries) {
        locale =
          this.impersonationUtilService.getLocaleWithFallbackCheck(userResponse.language,
            userResponse.country, userResponse.countries);
        this.lifeNetUtilService.setConfigurationFile(userResponse.country);
        this.lifeNetUtilService.setTranslationFile(locale);

        // Those three lines are important for case when some en locale for country is missing, and en_GLN locale is used.
        const localeSplit = locale.split(localeSplitter);
        const languageCode = localeSplit[0];
        const languageCountryCode = localeSplit[1];

        locale = this.configLoaderService.getTranslateFile(locale);

        this.isAuthenticatedAndLoaded = true;
        this.addRootClassService.emitToAddRootClass(addedRootClasses.authorized);

        this.walkMeUtilService.setLanguageAndCountry(window, languageCode, languageCountryCode);
      } else {
        // this language will be used as a fallback when a translation isn't found in the current language
        locale = this.configLoaderService.getDefaultTranslateFile();
        this.translate.setDefaultLang(locale);
      }

      moment.locale(userResponse.language);

      this.translate.getTranslation(locale).subscribe(() => {
        this.translationFileLoaded = true;
        this.checkTermsNCondition(userResponse);
      });
      this.checkIfIcCodeOrLifenetSettingFragmentsWasLoaded();

      this.windowService.consoleLogEnabled = configResponse.FRONTEND_DEBUG || false;

      if (userResponse.gid.startsWith('Z') && _.isEqual(configResponse.ORDER2ORDER_FEATURE_AVAILABLE, 'true')) {
        const o2oUrl = `${configResponse.ORDER_URL}data/${order2order.ordersCountriesJsonPath}`;
        this.o2oSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(o2oUrl);

        timer(1000).subscribe(() => {
          this.o2oSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(o2oUrl + '?_=' + new Date().getTime());
        });
      } else {
        this.o2oSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
      }
    }, (error: HttpErrorResponse) => {
      // this language will be used as a fallback when a translation isn't found in the current language

      const locale = this.configLoaderService.getDefaultTranslateFile();
      this.translate.setDefaultLang(locale);

      // to handle case when user is authenticated but not authorized
      if (_.isEqual(error.status, 403)) {
        this.isAuthenticatedAndLoaded = true;
      }
    });
  }

  /**
   * Check for terms and condition modal
   */
  checkTermsNCondition(user: User) {
    if (user.termsAccepted) {
      this.addRootClassService.emitToAddRootClass(addedRootClasses.termsAndConditions);
      this.showIntroModal();
    } else {
      this.termsAndConditionModal.show();
    }
  }

  checkIfIcCodeOrLifenetSettingFragmentsWasLoaded() {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment) {
        if (this.icCodeModal.shouldShow(fragment)) {
          this.icCodeModal.show();
        }

        if (fragment.match('lifenetSettings') && this.headerComponent && !this.headerComponent.userSettingsOverlay.isShown) {
          this.headerComponent.navigate();
        }
      }
    });
  }

  showIntroModal() {
    this.userUtilService.showIntroModal().subscribe(response => {
      if (response) {
        this.introFeatureModal.show();
      } else {
        this.showNotificationsSubscription();
      }
    });
  }

  showNotificationsSubscription() {
    this.auditTrailService.showNotificationsSubscription()
      .pipe(
        withLatestFrom(this.countryConfigRestService.getConfig()))
      .subscribe(([auditTrail, config]) => {
        if (AppComponent.shouldShowNotificationDialogForThisCountry(config) && !auditTrail.initialMyNotificationsShown) {
          this.notificationsSubscriptionModal.showNotificationsOverlay = true;
          this.notificationsSubscriptionModal.show();
        }
      });
  }
}
