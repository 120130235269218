import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { HttpIgnoredErrors } from '../../../core/interceptors/http-ignored-errors';
import { Attachment } from '../../../core/models/attachment';
import { AsuUploadFileInfo, AsuUploadInfo } from '../../../core/models/systemUpdates/asu-upload-request';
import { asuUploads } from '../../../core/rest-services/asu-rest-constants.service';
import { AsuUploadRestService } from '../../../core/rest-services/asu-upload-rest.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { TextareaComponent } from '../../components/textarea/textarea.component';
import { UploadComponent } from '../../form-group/upload/upload.component';
import { DatePipeWrapperPipe } from '../../pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { ExistsUploadUpdateModalComponent } from '../exist-upload-update-modal/exists-upload-update-modal.component';
import { LockedUploadUpdateModalComponent } from '../locked-upload-update-modal/locked-upload-update-modal.component';
import { UploadUpdatePackageChecksumValidator } from './upload-update-package-checksum.validator';
import { UploadUpdatePackageHowtoValidator } from './upload-update-package-howto.validator';
import { UploadUpdatePackageMetadataFileSizeValidator } from './upload-update-package-metadata-file-size.validator';
import { UploadUpdatePackagePackageValidator } from './upload-update-package-package.validator';
import { UploadUpdatePackagePackageinfoValidator } from './upload-update-package-packageinfo.validator';
import { takeUntil } from 'rxjs/operators';
import { UploadRequest } from '../../../core/models/upload-request';

@Component({
  selector: 'hl-upload-update-package-modal',
  templateUrl: './upload-update-package-modal.component.html'
})
export class UploadUpdatePackageModalComponent extends BaseFormModalPopup implements OnInit, OnDestroy {
  @ViewChild('filePackageForm', {static: false})
  filePackageForm: UploadComponent;
  @ViewChild('filePackageInfoForm', {static: false})
  filePackageInfoForm: UploadComponent;
  @ViewChild('fileHowToForm', {static: false})
  fileHowToForm: UploadComponent;
  @ViewChild('fileChecksumForm', {static: false})
  fileChecksumForm: UploadComponent;
  @ViewChild('lockedUploadUpdateModal', {static: false})
  lockedUploadUpdateModal: LockedUploadUpdateModalComponent;
  @ViewChild('existsUploadUpdateModal', {static: false})
  existsUploadUpdateModal: ExistsUploadUpdateModalComponent;
  @ViewChild('ConsoleLog', {static: false})
  consoleLog: TextareaComponent;

  public FILE_PACKAGE = 'packageFile';
  public FILE_PACKAGE_INFO = 'packageInfoFile';
  public FILE_HOW_TO = 'howToFile';
  public FILE_CHECKSUM = 'checksumFile';

  public UPLOAD_FILE_NAME_PACKEGE_INFO = 'packageinfo.xml';
  public UPLOAD_FILE_NAME_HOW_TO = 'how-to.xml';
  private messages = ['SYSTEM_UPDATES_UPLOAD_PROCESS_LOCK_CHECK',
    'SYSTEM_UPDATES_UPLOAD_PROCESS_EXIST',
    'SYSTEM_UPDATES_UPLOAD_PROCESS_START',
    'SYSTEM_UPDATES_UPLOAD_ROLLBACK_AND_CLEAN',
    'SYSTEM_UPDATES_UPLOAD_ROLLBACK_AND_UPLOAD',
    'SYSTEM_UPDATES_UPLOAD_ERROR_CHECKSUM_VALIDATION',
    'SYSTEM_UPDATES_UPLOAD_ERROR_CONNECTION_REFUSED',
    'SYSTEM_UPDATES_UPLOAD_ERROR_FILE_CANT_BE_UPLOADED',
    'SYSTEM_UPDATES_UPLOAD_DONE',
    'SYSTEM_UPDATES_UPLOAD_FAILED',
    'SYSTEM_UPDATES_UPLOAD_UP_TO_DATE',
    'SYSTEM_UPDATES_UPLOAD_COMMIT',
    'SYSTEM_UPDATES_UPLOAD_FILE_MAX_SIZE',
    'SYSTEM_UPDATES_UPLOAD_CANCELLED',
    'SYSTEM_UPDATES_UPLOAD_SYNCHRONIZED_COMMIT'];

  activeRunningRequests: XMLHttpRequest[] = [];

  uploadForm: FormGroup;
  isFormSubmitted = false;
  uploadSuccessSpinner = false;
  showConsoleLog = false;
  pattern = '^[A-Z]{2}\\d{3}\-\\d{2}\-[A-Z]$';
  packageProgress = false;
  packageUploadProgress = 0;
  packageInfoProgress = false;
  packageInfoUploadProgress = 0;
  howToProgress = false;
  howToUploadProgress = 0;
  updateNumberDisabled = false;
  isReplacement = false;
  consoleTimeFormat: string;
  metadataMaxSize: number;
  isUpdateLockedByOtherUser = false;

  packageUploadSuccess = false;
  packageInfoUploadSuccess = false;
  howToUploadSuccess = false;

  packageUploadUpToDate = false;

  packageUploadFail = false;
  packageInfoUploadFail = false;
  howToUploadFail = false;

  packageUploadCancelled = false;
  packageInfoUploadCancelled = false;
  howToUploadCancelled = false;

  isCommitProcess = false;
  isRollbackProcess = false;

  disableCancelPackageUpload = false;
  disableCancelPackageInfoUpload = false;
  disableCancelHowToUpload = false;
  disableCancelCheckSumUpload = false;
  lockedUpdateMessage: string;
  updateExistsMessage: string;
  uploadStartedMessage: string;
  uploadCommitMessage: string;
  uploadCommitSyncMessage: string;
  errorChecksumValidationMessage: string;
  errorConnectionRefusedMessage: string;
  errorFileCantBeUploadedMessage: string;
  doneMessage: string;
  failedMessage: string;
  upToDateMessage: string;
  cancelMessage: string;
  rollbackAndCleanMessage: string;
  rollbackAndUploadMessage: string;
  maxFileSizeExceededMessage: string;
  packageHash: string;
  packageName: string;
  packageInfoHash: string;
  packageInfoName: string;
  howToHash: string;
  howToName: string;

  private static getCheckSum(fileName: string, checkSumFileHashes: AsuUploadFileInfo[]): string {
    for (const fileHash of checkSumFileHashes) {
      if (_.isEqual(fileName, fileHash.name)) {
        return fileHash.sha256;
      }
    }
  }

  private static parseCheckSumFile(lines: string[]): AsuUploadFileInfo[] {
    const checkSumFileHashes: AsuUploadFileInfo[] = [];
    for (let i = 0; i < lines.length; i++) {
      const values = lines[i].split(' *');
      if (values.length === 2) {
        checkSumFileHashes.push({'name': values[1].trim(), 'sha256': values[0].trim()});
      }
    }
    return checkSumFileHashes;
  }

  constructor(private http: HttpClient,
    private uploadService: AsuUploadRestService,
    private translate: TranslateService,
    private userRestService: UserRestService,
    private configService: CountryConfigRestService,
    private dateWrapper: DatePipeWrapperPipe,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private ignoredErrors: HttpIgnoredErrors,
    renderer: Renderer2) {
    super(renderer);
    ignoredErrors.putErrorType(asuUploads, 404);
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  init() {
    this.initFormControl();
  }

  initFormControl() {
    this.loadConfigSettings();
    this.createForm();
    this.translateMessages();
    this.cdr.detectChanges();
  }

  hide() {
    if (this.isFormSubmitted && !this.isUpdateLockedByOtherUser) {
      if (!this.isCommitProcess) {
        this.abortAllRunningRequests();
        this.setProgressToCancelled();
        this.rollbackCancelAndClean(this.getUpdateNumber());
      }
    } else {
      this.clearInput();
      super.hide();
    }
  }

  clearInput() {
    this.uploadSuccessSpinner = false;
    this.showConsoleLog = false;
    this.updateNumberDisabled = false;
    this.isCommitProcess = false;
    this.isRollbackProcess = false;
    this.resetProgressBarsToDefault();
    this.removeFilesFromUploadComponent();
    this.disableCancelForSelectedFiles(false);
    this.deleteConsoleLog();
    this.uploadForm.get('updateNumber').setValue('');
    this.uploadForm.markAsPristine();
    this.uploadForm.markAsUntouched();
    this.cdr.detectChanges();
  }

  postFormData() {
    this.isFormSubmitted = true;
    this.showConsoleLog = true;
    this.isCommitProcess = false;
    this.isRollbackProcess = false;
    this.deleteConsoleLog();
    this.cdr.detectChanges();
    this.setUploadSuccessSpinner(true);
    this.disableCancelForSelectedFiles(true);
    this.resetProgressBarsToDefault();

    const updateNumber = this.getUpdateNumber();

    const fileReader = new FileReader();
    const checksumFile = Object(this.uploadForm.get('checksumFile').value)[0]['file'];

    fileReader.readAsText(checksumFile);
    fileReader.onload = () => {
      const text = fileReader.result as string;
      const lines = text.split(/\n/);

      if (lines) {
        const checkSumFileHashes = UploadUpdatePackageModalComponent.parseCheckSumFile(lines);

        this.packageName = Object(this.uploadForm.get('packageFile').value)[0].filename;
        this.packageHash = UploadUpdatePackageModalComponent.getCheckSum(this.packageName, checkSumFileHashes);
        this.packageInfoName = Object(this.uploadForm.get('packageInfoFile').value)[0].filename;
        this.packageInfoHash = UploadUpdatePackageModalComponent.getCheckSum(this.packageInfoName, checkSumFileHashes);
        this.howToName = Object(this.uploadForm.get('howToFile').value)[0].filename;
        this.howToHash = UploadUpdatePackageModalComponent.getCheckSum(this.howToName, checkSumFileHashes);

        this.appendToConsole(this.lockedUpdateMessage);
        this.uploadService.acquireLock(updateNumber, this.getUploadInfoObject()).subscribe(lockResult => {

          const locked = lockResult.locked;
          const lockedUserGID = lockResult.userGID;

          if (locked) {
            this.lockedUploadUpdateModal.lockedUserGID = lockedUserGID;
            this.lockedUploadUpdateModal.updateNumber = updateNumber;
            this.lockedUploadUpdateModal.show();
          } else {
            this.appendToConsole(this.updateExistsMessage);
            this.checkUpdateExistStartUpload(updateNumber);
          }
        }, () => {
          this.appendToConsole(this.errorFileCantBeUploadedMessage);
          this.rollbackFailAndClean(updateNumber);
        });
      }
    };
  }

  rollbackCancelAndClean(updateNumber: string) {
    this.isRollbackProcess = true;
    this.appendToConsole(this.rollbackAndCleanMessage);
    this.uploadService.rollbackUploadCancelled(updateNumber).subscribe(() => {
      this.cancelUploadProcess();
      this.isRollbackProcess = false;
    }, () => {
      this.appendToConsole(this.errorFileCantBeUploadedMessage);
      this.cancelUploadProcess();
      this.isRollbackProcess = false;
    });
  }

  rollbackFailAndClean(updateNumber: string) {
    this.isRollbackProcess = true;
    this.appendToConsole(this.rollbackAndCleanMessage);
    this.uploadService.rollbackUploadFailed(updateNumber).subscribe(() => {
      this.cancelUploadProcess();
      this.isRollbackProcess = false;
    }, () => {
      this.appendToConsole(this.errorFileCantBeUploadedMessage);
      this.cancelUploadProcess();
      this.isRollbackProcess = false;
    });
  }

  rollbackAndUpload(updateNumber: string) {
    this.appendToConsole(this.rollbackAndUploadMessage);
    this.uploadService.rollbackUploadFailed(updateNumber).subscribe(() => {
      this.uploadService.acquireLock(updateNumber, this.getUploadInfoObject()).subscribe(() => {
        this.checkUpdateExistStartUpload(updateNumber);
      }, () => {
        this.appendToConsole(this.errorFileCantBeUploadedMessage);
        this.rollbackFailAndClean(updateNumber);
      });
    });
  }

  checkUpdateExistStartUpload(updateNumber: string) {
    this.uploadService.checkUpdateExist(updateNumber).subscribe(existResult => {

      this.isReplacement = existResult.updateExists;
      if (existResult.updateExists) {
        this.existsUploadUpdateModal.updateNumber = updateNumber;
        this.existsUploadUpdateModal.show();
      } else {
        this.startUpload(updateNumber);
      }
    }, () => {
      this.appendToConsole(this.errorFileCantBeUploadedMessage);
      this.rollbackFailAndClean(updateNumber);
    });
  }

  startUpload(updateNumber: string) {
    this.uploadService.prepareUpload(updateNumber).subscribe(() => {
      this.appendToConsole(this.uploadStartedMessage);
      const packageFile: Attachment = Object(this.uploadForm.get('packageFile').value)[0];
      if (this.isReplacement) {
        this.uploadService.getUploadInfo(updateNumber).subscribe(uploadInfo => {

          if (!_.isEqual(packageFile.filename, uploadInfo.package.name)
            || !_.isEqual(this.packageHash, uploadInfo.package.sha256)) {
            this.startFileUpload(updateNumber, this.packageHash, packageFile, packageFile.filename,
              false, 1);
          } else {
            this.setUpToDateProgress(1);
            this.packageUploadUpToDate = true;
          }
        }, error => {
          if (error['status'] === 404) {
            this.startFileUpload(updateNumber, this.packageHash, packageFile, packageFile.filename,
              false, 1);
          }
        });
      } else {
        this.startFileUpload(updateNumber, this.packageHash, packageFile, packageFile.filename,
          false, 1);
      }

      this.startFileUpload(updateNumber, this.packageInfoHash,
        Object(this.uploadForm.get('packageInfoFile').value)[0], this.UPLOAD_FILE_NAME_PACKEGE_INFO,
        true, 2);
      this.startFileUpload(updateNumber, this.howToHash,
        Object(this.uploadForm.get('howToFile').value)[0], this.UPLOAD_FILE_NAME_HOW_TO,
        true, 3);
    }, () => {
      this.appendToConsole(this.errorFileCantBeUploadedMessage);
      this.rollbackFailAndClean(updateNumber);
    });
  }

  startFileUpload(updateNumber: string, hash: string, attachment: Attachment, fileName: string,
    isMetadata: boolean, fileUpload: number) {
    let lastModified: Date = new Date((<any>attachment.file).lastModifiedDate);

    if (!lastModified) {
      lastModified = new Date(attachment.file.lastModified);
    }

    this.computeHeadersAndUpload(updateNumber, fileName, hash,
      (+lastModified).toString(), attachment.filesize, isMetadata, attachment.file, fileUpload);
  }

  computeHeadersAndUpload(updateNumber: string, fileName: string, hash: string, mTime: string,
    fileSize: number, isMetadata: boolean, file: File, fileUpload: number) {

    this.uploadService.computeHeaders(updateNumber, fileName,
      hash, mTime, fileSize.toString(), isMetadata.toString())
      .subscribe(headers => {

        this.uploadFile(this, headers, file, fileUpload).subscribe(
          () => {
            this.setUploadSuccess(fileUpload);
            this.cdr.detectChanges();

            if (this.isAllUploaded()) {
              this.isCommitProcess = true;
              this.uploadService.commitUpload(updateNumber, this.packageUploadUpToDate).subscribe(result => {
                if (result.updateCommit) {
                  this.appendToConsole(this.uploadCommitMessage);
                } else {
                  this.appendToConsole(this.uploadCommitSyncMessage);
                }

                this.setUploadSuccessSpinner(false);
                this.updateNumberDisabled = true;
                this.isCommitProcess = false;
                this.isFormSubmitted = false;
              }, () => {
                this.appendToConsole(this.errorFileCantBeUploadedMessage);
                this.rollbackFailAndClean(updateNumber);
                this.setProgressToCancelled();
              });
            }
          },
          error => {
            this.setUploadFail(fileUpload);

            if (error === 412) {
              this.appendToConsole(this.errorChecksumValidationMessage);
            } else if (error === 0) {
              this.appendToConsole(this.errorConnectionRefusedMessage);
            } else {
              this.appendToConsole(this.errorFileCantBeUploadedMessage);
            }

            this.abortAllRunningRequests();
            this.rollbackFailAndClean(updateNumber);
          });
      }, () => {
        this.appendToConsole(this.errorFileCantBeUploadedMessage);
        this.rollbackFailAndClean(updateNumber);
      });
  }

  uploadFile($scope, headers: UploadRequest, file: File, fileUpload: number): Observable<void> {
    return new Observable(observer => {
      const xhr = this.createUploadRequest(headers);

      $scope.enableUploadProgress(fileUpload, true);
      $scope.cdr.detectChanges();

      xhr.onload = function () {
        if (this['status'] === 200) {
          observer.next();
          observer.complete();
        } else {
          observer.error(this['status']);
          observer.complete();
        }

        $scope.activeRunningRequests.splice($scope.activeRunningRequests.indexOf(xhr), 1);
      };

      xhr.onerror = () => {
        observer.error(xhr.status);
        $scope.activeRunningRequests.splice($scope.activeRunningRequests.indexOf(xhr), 1);
      };

      xhr.upload.addEventListener('progress', function (event) {
        if (event.lengthComputable) {
          $scope.updateUploadProgress(fileUpload, Math.floor(event.loaded / event.total * 100));
          $scope.cdr.detectChanges();
        }
      });

      xhr.send(file);
    });
  }

  private createUploadRequest(headers: UploadRequest) {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', headers.url, true);

    for (const property in headers.headers) {
      if (headers.headers.hasOwnProperty(property)) {
        xhr.setRequestHeader(property, headers.headers[property]);
      }
    }
    this.activeRunningRequests.push(xhr);
    return xhr;
  }

  setUpToDateProgress(fileUpload: number) {
    this.enableUploadProgress(fileUpload, true);
    this.updateUploadProgress(fileUpload, 100);
    this.cdr.detectChanges();
  }

  setUploadFail(fileUpload: number) {
    if (fileUpload === 1) {
      this.packageUploadFail = true;
      this.packageInfoUploadCancelled = true;
      this.howToUploadCancelled = true;
    } else if (fileUpload === 2) {
      this.packageInfoUploadFail = true;
      this.packageUploadCancelled = true;
      this.howToUploadCancelled = true;
    } else if (fileUpload === 3) {
      this.howToUploadFail = true;
      this.packageUploadCancelled = true;
      this.packageInfoUploadCancelled = true;
    }
  }

  isAllUploaded(): boolean {
    return (this.packageUploadSuccess || this.packageUploadUpToDate) &&
      this.packageInfoUploadSuccess && this.howToUploadSuccess;
  }

  setUploadSuccess(fileUpload: number) {
    if (fileUpload === 1) {
      this.packageUploadSuccess = true;
    } else if (fileUpload === 2) {
      this.packageInfoUploadSuccess = true;
    } else if (fileUpload === 3) {
      this.howToUploadSuccess = true;
    }
  }

  updateUploadProgress(fileUpload: number, completed: number) {
    if (fileUpload === 1) {
      this.packageUploadProgress = completed;
    } else if (fileUpload === 2) {
      this.packageInfoUploadProgress = completed;
    } else if (fileUpload === 3) {
      this.howToUploadProgress = completed;
    }
  }

  setUploadSuccessSpinner(value: boolean) {
    this.uploadSuccessSpinner = value;
  }

  enableUploadProgress(fileUpload: number, value: boolean) {
    if (fileUpload === 1) {
      this.packageProgress = value;
    } else if (fileUpload === 2) {
      this.packageInfoProgress = value;
    } else if (fileUpload === 3) {
      this.howToProgress = value;
    }
  }

  getPackageUploadProgress(): string {
    if (this.packageUploadProgress === 100 && this.packageUploadSuccess && !this.packageUploadCancelled) {
      return this.doneMessage;
    } else if (this.packageUploadProgress === 100 && this.packageUploadUpToDate && !this.packageUploadCancelled) {
      return this.upToDateMessage;
    } else if (this.packageUploadCancelled) {
      return this.cancelMessage;
    } else if (this.packageUploadFail) {
      return this.failedMessage;
    }
    return this.packageUploadProgress + ' %';
  }

  getPackageInfoUploadProgress(): string {
    if (this.packageInfoUploadProgress === 100 && this.packageInfoUploadSuccess && !this.packageInfoUploadCancelled) {
      return this.doneMessage;
    } else if (this.packageInfoUploadCancelled) {
      return this.cancelMessage;
    } else if (this.packageInfoUploadFail) {
      return this.failedMessage;
    }
    return this.packageInfoUploadProgress + ' %';
  }

  getHowToUploadProgress(): string {
    if (this.howToUploadProgress === 100 && this.howToUploadSuccess && !this.howToUploadCancelled) {
      return this.doneMessage;
    } else if (this.howToUploadCancelled) {
      return this.cancelMessage;
    } else if (this.howToUploadFail) {
      return this.failedMessage;
    }
    return this.howToUploadProgress + ' %';
  }

  /**
   * disable the submit button, if no update number and checksum is provided
   * @returns {boolean}
   */
  isSubmitFormDisabled(): boolean {
    return !!((this.uploadForm.get('updateNumber').invalid && this.uploadForm.get('updateNumber').errors
        && !this.uploadForm.get('updateNumber').errors['updateExists'])
      ||
      (this.uploadForm.get('packageFile').invalid && this.uploadForm.get('packageFile').errors
        && this.uploadForm.controls['packageFile'].errors['invalidName'])
      ||
      (this.uploadForm.get('checksumFile').invalid && this.uploadForm.get('checksumFile').errors)
      ||
      (this.uploadForm.controls['packageFile'].invalid && this.uploadForm.controls['packageInfoFile'].invalid &&
        this.uploadForm.controls['howToFile'].invalid)
      ||
      !((Object.keys(this.uploadForm.get('packageFile').value).length > 0) &&
        (Object.keys(this.uploadForm.get('packageInfoFile').value).length > 0) &&
        (Object.keys(this.uploadForm.get('howToFile').value).length > 0))
      ||
      this.uploadForm.get('updateNumber').pending
      ||
      this.uploadForm.get('checksumFile').pending
      ||
      this.uploadForm.get('howToFile').pending
      ||
      this.uploadForm.get('packageInfoFile').pending
      ||
      (this.uploadForm.get('howToFile').errors && (this.uploadForm.get('howToFile').errors['validationError']
        || this.uploadForm.get('howToFile').errors['maxSizeExceeded']))
      ||
      (this.uploadForm.get('packageInfoFile').errors && (this.uploadForm.get('packageInfoFile').errors['validationError']
        || this.uploadForm.get('packageInfoFile').errors['maxSizeExceeded']))
      ||
      this.uploadSuccessSpinner
    );
  }

  /**
   * Creates reactive form
   */
  createForm() {
    const updateNumberRegEx = new RegExp('^[A-Z]{2}\\d{3}\-\\d{2}\-[A-Z]$');

    this.uploadForm = this.fb.group({
      updateNumber: ['',
        [Validators.required,
          Validators.maxLength(10),
          Validators.pattern(updateNumberRegEx)]],
      packageFile: [[], UploadUpdatePackagePackageValidator.fileNameValidator],
      packageInfoFile: [[], [Validators.required,
        UploadUpdatePackageMetadataFileSizeValidator.fileMaxSizeValidator(this.metadataMaxSize)],
        UploadUpdatePackagePackageinfoValidator.packageInfoFileValidator(this.uploadService, this.translate)],
      howToFile: [[], [Validators.required,
        UploadUpdatePackageMetadataFileSizeValidator.fileMaxSizeValidator(this.metadataMaxSize)],
        UploadUpdatePackageHowtoValidator.howToFileValidator(this.uploadService, this.translate)],
      checksumFile: [[], [Validators.required,
        UploadUpdatePackageMetadataFileSizeValidator.fileMaxSizeValidator(this.metadataMaxSize)],
        UploadUpdatePackageChecksumValidator.checkSumFileValidatorAndLoader()]
    });

    const updateNumber = this.uploadForm.controls['updateNumber'];
    const changesUN$ = updateNumber.valueChanges;

    changesUN$.subscribe(() => {
      if (this.uploadForm.controls['packageFile']) {
        this.uploadForm.controls['packageFile'].updateValueAndValidity();
      }
    });

    const changesPF$ = this.uploadForm.controls['packageFile'].valueChanges;
    changesPF$.subscribe(() => {
      if (this.uploadForm.controls['checksumFile']) {
        this.uploadForm.controls['checksumFile'].updateValueAndValidity();
      }
    });

    const changesPIF$ = this.uploadForm.get('packageInfoFile').valueChanges;
    changesPIF$.subscribe(() => {
      if (this.uploadForm.get('checksumFile')) {
        this.uploadForm.get('checksumFile').updateValueAndValidity();
      }
    });

    const changesHTF$ = this.uploadForm.get('howToFile').valueChanges;
    changesHTF$.subscribe(() => {
      if (this.uploadForm.get('checksumFile')) {
        this.uploadForm.get('checksumFile').updateValueAndValidity();
      }
    });
  }

  private translateMessages() {
    this.translate.get(this.messages).subscribe(translations => {
      for (const key in translations) {
        if (translations.hasOwnProperty(key)) {
          if (_.isEqual('SYSTEM_UPDATES_UPLOAD_PROCESS_LOCK_CHECK', key)) {
            this.lockedUpdateMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_PROCESS_EXIST', key)) {
            this.updateExistsMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_PROCESS_START', key)) {
            this.uploadStartedMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_ROLLBACK_AND_CLEAN', key)) {
            this.rollbackAndCleanMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_ROLLBACK_AND_UPLOAD', key)) {
            this.rollbackAndUploadMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_ERROR_CHECKSUM_VALIDATION', key)) {
            this.errorChecksumValidationMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_ERROR_CONNECTION_REFUSED', key)) {
            this.errorConnectionRefusedMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_ERROR_FILE_CANT_BE_UPLOADED', key)) {
            this.errorFileCantBeUploadedMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_DONE', key)) {
            this.doneMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_FAILED', key)) {
            this.failedMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_UP_TO_DATE', key)) {
            this.upToDateMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_COMMIT', key)) {
            this.uploadCommitMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_SYNCHRONIZED_COMMIT', key)) {
            this.uploadCommitSyncMessage = translations[key];
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_FILE_MAX_SIZE', key)) {
            this.maxFileSizeExceededMessage = translations[key].replace(/#SIZE#/g, this.metadataMaxSize);
          } else if (_.isEqual('SYSTEM_UPDATES_UPLOAD_CANCELLED', key)) {
            this.cancelMessage = translations[key];
          }
        }
      }
    });
  }

  getChecksumErrorMessage(): string {
    if (this.hasChecksumInvalidFilesCount()) {
      return 'SYSTEM_UPDATES_UPLOAD_CHECKSUM_FILES_COUNT_NOT_VALID';
    } else if (this.hasChecksumInvalidFileName()) {
      return 'SYSTEM_UPDATES_UPLOAD_CHECKSUM_FILE_NAME_NOT_VALID';
    } else if (this.hasChecksumInvalidContent()) {
      return 'SYSTEM_UPDATES_UPLOAD_CHECKSUM_FILE_CONTENT_NOT_VALID';
    } else if (this.hasChecksumInvalidLengthOfHash()) {
      return 'SYSTEM_UPDATES_UPLOAD_CHECKSUM_HAS_LENGTH_NOT_VALID';
    } else if (this.hasChecksumMaxFileSizeExceeded()) {
      return this.maxFileSizeExceededMessage;
    }
  }

  isChecksumFileInvalid(): boolean {
    return this.hasChecksumMaxFileSizeExceeded() || this.hasChecksumInvalidFileName()
      || this.hasChecksumInvalidContent() || this.hasChecksumInvalidFilesCount()
      || this.hasChecksumInvalidLengthOfHash();
  }

  hasChecksumInvalidFilesCount(): boolean {
    return this.uploadForm.get('checksumFile').errors
      && this.uploadForm.get('checksumFile').errors['invalidCount'];
  }

  hasChecksumInvalidFileName(): boolean {
    return this.uploadForm.get('checksumFile').errors
      && this.uploadForm.get('checksumFile').errors['invalidFile'];
  }

  hasChecksumInvalidContent(): boolean {
    return this.uploadForm.get('checksumFile').errors
      && this.uploadForm.get('checksumFile').errors['invalidContent'];
  }

  hasChecksumInvalidLengthOfHash(): boolean {
    return this.uploadForm.get('checksumFile').errors
      && this.uploadForm.get('checksumFile').errors['invalidChecksumLength'];
  }

  hasChecksumMaxFileSizeExceeded(): boolean {
    return this.uploadForm.get('checksumFile').errors
      && this.uploadForm.get('checksumFile').errors['maxSizeExceeded'];
  }

  isHowToFileInvalid(): boolean {
    return !!(this.uploadForm.get('howToFile').errors
      && (this.uploadForm.get('howToFile').errors['validationError']
        || this.uploadForm.get('howToFile').errors['maxSizeExceeded']));
  }

  getHowToValidationErrorMessage(): string {
    if (this.uploadForm.get('howToFile').errors) {
      if (this.uploadForm.get('howToFile').errors['validationError']) {
        return this.uploadForm.get('howToFile').errors['validationError'];
      } else if (this.uploadForm.get('howToFile').errors['maxSizeExceeded']) {
        return this.maxFileSizeExceededMessage;
      }
    }
    return '';
  }

  isPackageInfoFileInvalid(): boolean {
    return !!(this.uploadForm.get('packageInfoFile').errors
      && (this.uploadForm.get('packageInfoFile').errors['validationError']
        || this.uploadForm.get('packageInfoFile').errors['maxSizeExceeded']));
  }

  getPackageInfoErrorMessage(): string {
    if (this.uploadForm.get('packageInfoFile').errors) {
      if (this.uploadForm.get('packageInfoFile').errors['validationError']) {
        return this.uploadForm.get('packageInfoFile').errors['validationError'];
      } else if (this.uploadForm.get('packageInfoFile').errors['maxSizeExceeded']) {
        return this.maxFileSizeExceededMessage;
      }
    }
    return '';
  }

  isProgressIndicator(): boolean {
    return this.uploadForm.get('checksumFile').pending || this.uploadForm.get('howToFile').pending
      || this.uploadForm.get('packageInfoFile').pending || this.uploadForm.get('updateNumber').pending
      || this.uploadSuccessSpinner;

  }

  disableCancelForSelectedFiles(value: boolean) {
    this.disableCancelPackageUpload = value;
    this.disableCancelPackageInfoUpload = value;
    this.disableCancelHowToUpload = value;
    this.disableCancelCheckSumUpload = value;
  }

  private resetProgressBarsToDefault() {
    this.resetProgressBarPackage();
    this.resetProgressBarPackageInfo();
    this.resetProgressBarHowTo();
  }

  resetProgressBarsToDefaultAndClearConsole() {
    this.resetProgressBarsToDefault();
    this.deleteConsoleLog();
    this.cdr.detectChanges();
  }

  private resetProgressBarPackage() {
    this.packageProgress = false;
    this.packageUploadProgress = 0;
    this.packageUploadSuccess = false;
    this.packageUploadFail = false;
    this.packageUploadUpToDate = false;
    this.packageUploadCancelled = false;
  }

  private resetProgressBarPackageInfo() {
    this.packageInfoProgress = false;
    this.packageInfoUploadProgress = 0;
    this.packageInfoUploadSuccess = false;
    this.packageInfoUploadFail = false;
    this.packageInfoUploadCancelled = false;
  }

  private resetProgressBarHowTo() {
    this.howToProgress = false;
    this.howToUploadProgress = 0;
    this.howToUploadSuccess = false;
    this.howToUploadFail = false;
    this.howToUploadCancelled = false;
  }

  private removeFilesFromUploadComponent() {
    this.filePackageForm.removeFiles();
    this.filePackageInfoForm.removeFiles();
    this.fileHowToForm.removeFiles();
    this.fileChecksumForm.removeFiles();
  }

  private abortAllRunningRequests() {
    for (const activeRequest of this.activeRunningRequests) {
      activeRequest.abort();
    }
  }

  private formatLogMessage(message: string): string {
    return '\n[' + this.dateWrapper.transform(new Date(), this.consoleTimeFormat)
      + '] ' + message;
  }

  private loadConfigSettings() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      this.consoleTimeFormat = config.ASU_UPLOAD_CONSOLE_TIME_FORMAT;
      this.metadataMaxSize = _.parseInt(config.ASU_UPLOAD_UPDATE_METADATA_MAX_FILE_SIZE);
    });
  }

  private getUpdateNumber(): string {
    const updateNumber = this.uploadForm.get('updateNumber').value;
    if (updateNumber) {
      return updateNumber.replace(/-/gi, '/');
    } else {
      return '';
    }
  }

  private appendToConsole(message: string) {
    this.consoleLog.onAddText(this.formatLogMessage(message));
  }

  private cancelUploadProcess() {
    this.setUploadSuccessSpinner(false);
    this.disableCancelForSelectedFiles(false);
    this.isFormSubmitted = false;
    this.cdr.detectChanges();
  }

  private getUploadInfoObject(): AsuUploadInfo {
    return {
      package: {
        name: this.packageName,
        sha256: this.packageHash
      },
      packageInfo: {
        name: this.packageInfoName,
        sha256: this.packageInfoHash
      },
      howTo: {
        name: this.howToName,
        sha256: this.howToHash
      }
    };
  }

  private deleteConsoleLog() {
    if (this.consoleLog) {
      this.consoleLog.onDeleteText();
    }
  }

  setUpdateLockedByOtherUser(value: boolean) {
    this.isUpdateLockedByOtherUser = value;
  }

  // Quick fix to handle enter button on the text field to avoid open file browser
  doNothing() {
  }

  private setProgressToCancelled() {
    this.setAllUploadsCancelled(true);
    this.cdr.detectChanges();
  }

  private setAllUploadsCancelled(value: boolean) {
    this.packageUploadCancelled = value;
    this.packageInfoUploadCancelled = value;
    this.howToUploadCancelled = value;
  }
}
