import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { partnerOrderStatusOpen } from 'app/partner-orders/partner-orders.component';
import * as _ from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { configValueSplitter, roles } from '../../../core/core-constants.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { ActivitiesConstantsService } from '../../../core/services/activities/activities-constants.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { LogService } from '../../../core/services/log/log.service';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { UserUtilService } from '../../../core/services/user/user-util.service';
import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[hlItemShowHideHandler]'
})
export class ItemShowHideHandlerDirective implements OnInit, OnDestroy {

  private inputConfig: string;
  private inputRole: string;
  private inputType: string;
  private inputAction: string;
  private inputEquipmentKey: string;
  private partnerOrderStatus: string;
  private inputTicketType: string;
  private inputTicketId: string;
  private inputModality: string;
  private inputQuoteStatus: string;

  private inputActivityItem: ActivitiesViewModel;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private configService: CountryConfigRestService,
    private userUtilService: UserUtilService,
    private logService: LogService,
    private activitiesConstantsService: ActivitiesConstantsService,
    private ticketUtilService: TicketsUtilService,
    private equipmentUtilService: EquipmentUtilService) {
  }

  @Input()
  set hlItemShowHideHandler(type: string) {
    this.inputType = type;
  }

  @Input()
  set hlItemShowHideHandlerRole(role: string) {
    this.inputRole = role;
  }

  @Input()
  set hlItemShowHideHandlerConfig(config: string) {
    this.inputConfig = config;
  }

  @Input()
  set hlItemShowHideHandlerAction(action: string) {
    this.inputAction = action;
  }

  @Input()
  set hlItemShowHideHandlerEquipmentKey(key: string) {
    this.inputEquipmentKey = key;
  }

  @Input()
  set hlItemShowHideHandlerPartnerOrderStatus(partnerOrderStatus: string) {
    this.partnerOrderStatus = partnerOrderStatus;
    // update the visibility, when the status is changed (e.g. when changing the filter)
    this.partnerOrdersItemShowHandler();
  }

  @Input()
  set hlItemShowHideHandlerTicketType(key: string) {
    this.inputTicketType = key;
    // when type changed call handler again so element is added or removed.
    this.ticketItemShowHandler();
  }

  /**
   * here we have a second setter for the same property (inputTicketType),
   *  because it is used for a different purpose
   * @param {string} key
   */
  @Input()
  set hlItemShowHideHandlerTicketTypeCreate(key: string) {
    this.inputTicketType = key;
    // when type changed call handler again so element is added or removed.
    this.equipmentAndAvailabilityShowHandler();
  }

  @Input()
  set hlItemShowHideHandlerTicketId(key: string) {
    this.inputTicketId = key;
    // when typeId changed call handler again so element is added or removed
    this.ticketItemShowHandler();
  }

  @Input()
  set hlItemShowHideHandlerModality(modality: string) {
    this.inputModality = modality;
    // when modality changed call handler again so element is added or removed
    this.psrSAOOrderButtonShowHandlers();
  }

  @Input()
  set hlItemShowHideHandlerQuoteStatus(key: string) {
    this.inputQuoteStatus = key;

    // for the second time when the input changes
    this.psrStatusItemShowHandler();
  }

  @Input()
  set hlItemShowHideHandlerActivityItem(item: ActivitiesViewModel) {
    this.inputActivityItem = item;
    if (_.isEqual('rescheduleActivity', this.inputType)) {
      this.showRescheduleItemShowHandler();
    }
    if (_.isEqual('pmSchedule', this.inputType)) {
      this.pmScheduleShowHandler();
    }
    if (_.isEqual('updateActivity', this.inputType)) {
      this.updateActivityShowHandler();
    }
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {

    // show/hide menu entries based on role, reading country config and data passed.
    switch (this.inputType) {
      case 'config':
        this.showHideBasedOnConfig();
        break;
      case 'role':
        this.showHideBasedOnRole();
        break;
      case 'configAndRole':
        this.showHideBasedOnConfigAndRole();
        break;
      case 'rescheduleActivity':
        this.showRescheduleItemShowHandler();
        break;
      case 'partners':
        this.partnerOrdersItemShowHandler();
        break;
      case 'tickets':
        this.ticketItemShowHandler();
        break;
      case 'psrStatus':
        this.psrStatusItemShowHandler();
        break;
      case 'psrBtn':
      case 'saOrderBtn':
        this.psrSAOOrderButtonShowHandlers();
        break;
      case 'equipmentAndAvailability':
        this.equipmentAndAvailabilityShowHandler();
        break;
      case 'pmSchedule':
        this.pmScheduleShowHandler();
        break;
      case 'updateActivity':
        this.updateActivityShowHandler();
        break;
      default:

    }
  }

  renderTemplateOnContainer(isRenderAllowed) {
    // we should always clear - otherwise things could be shown multiple times
    this._viewContainer.clear();

    if (isRenderAllowed) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    }
  }

  showHideBasedOnConfig() {
    let isRenderAllowed = false;
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      if (_.isEqual(configResponse[this.inputConfig], 'true')) {
        isRenderAllowed = true;
      }
      this.renderTemplateOnContainer(isRenderAllowed);
    });
  }

  showHideBasedOnRole() {
    let isRenderAllowed = false;
    const rolesToCheck = {
      isRoleAllowed: roles[this.inputRole]
    };

    // Check the roles of the user
    this.userUtilService.checkUserRoles(rolesToCheck).subscribe((rolesResponse) => {
      isRenderAllowed = rolesResponse.isRoleAllowed;
      this.renderTemplateOnContainer(isRenderAllowed);
    });
  }

  /**
   * @description
   * Show/hide based on user role and on partnerOrder.orderStatus
   */
  partnerOrdersItemShowHandler() {
    let isRenderAllowed = false;
    const rolesToCheck = {
      isRoleAllowed: roles.partnerOrdersUpdateRole
    };

    this.userUtilService.checkUserRoles(rolesToCheck).subscribe((rolesResponse) => {
      isRenderAllowed = rolesResponse.isRoleAllowed;
      isRenderAllowed = isRenderAllowed && _.isEqual(this.partnerOrderStatus, partnerOrderStatusOpen);
      this.renderTemplateOnContainer(isRenderAllowed);
    });
  }

  showHideBasedOnConfigAndRole() {
    let isRenderAllowed = false;
    const rolesToCheck = {
      isRoleAllowed: roles[this.inputRole]
    };
    const userRoleCheck$ = this.userUtilService.checkUserRoles(rolesToCheck);
    const configCheck$ = this.configService.getConfig();

    combineLatest([userRoleCheck$, configCheck$]).pipe(takeUntil(this.unsubscribe$)).subscribe(responses => {
      // get user promise and set disableFlag accordingly
      if (responses[0].isRoleAllowed && (_.isEqual(responses[1][this.inputConfig], 'true'))) {
        isRenderAllowed = true;
      }
      this.renderTemplateOnContainer(isRenderAllowed);
    });
  }

  showRescheduleItemShowHandler() {
    let isRenderAllowed = false;
    const rolesToCheck = {
      checkReschedulePlannedActvRole: roles.viewReschedulePlannedActivityRole,
      checkReschedulePlannedTrngRole: roles.viewReschedulePlannedTrainingRole
    };
    const userRoleCheck$ = this.userUtilService.checkUserRoles(rolesToCheck);
    const configCheck$ = this.configService.getConfig();

    combineLatest([userRoleCheck$, configCheck$]).pipe(takeUntil(this.unsubscribe$)).subscribe(responses => {
      const activityConfigType = responses[1].PLANNED_ACTIVITY_IDENTIFIER;
      const trainingsConfigType = responses[1].TRAINING_IDENTIFIER;
      const sapSystem = responses[1].SAP_BACKEND_SYSTEM;
      const activityStatusClosed = this.activitiesConstantsService.getClosedActivityStatusForSapSystem(sapSystem);
      const splitActivityTypeArr = activityConfigType.split(configValueSplitter);
      const splitTrainingsTypeArr = trainingsConfigType.split(configValueSplitter);

      if (_.includes(splitActivityTypeArr, this.inputActivityItem.type)) {
        // assign role check value for planned activity
        isRenderAllowed = responses[0].checkReschedulePlannedActvRole;
      } else if (_.includes(splitTrainingsTypeArr, this.inputActivityItem.type)) {
        // assign role check value for training
        isRenderAllowed = responses[0].checkReschedulePlannedTrngRole;
      }

      if (_.includes(activityStatusClosed, this.inputActivityItem.activityStatus)) {
        isRenderAllowed = false;
      }
      this.renderTemplateOnContainer(isRenderAllowed);
    });
  }

  /**
   *
   * @description
   * Check whether the ticket update/close and handover should be shown based on
   * country config and role check.
   */
  ticketItemShowHandler() {
    const rolesToCheck = {
      checkCreateTicketRole: roles.createTicketRole
    };
    const userRoleCheck$ = this.userUtilService.checkUserRoles(rolesToCheck);
    const configCheck$ = this.configService.getConfig();

    combineLatest([userRoleCheck$, configCheck$]).pipe(takeUntil(this.unsubscribe$)).subscribe(responses => {
      if (_.isEqual(this.inputAction, 'save')) {
        this.checkTicketUpdateCloseRender(responses[0], responses[1]);
      } else if (_.isEqual(this.inputAction, 'convert')) {
        this.checkTicketHandoverRender(responses[0], responses[1]);
      }
    });
  }

  /**
   *
   * @param roleCheck
   * @param config
   *
   * @description
   * Check list menu item to be shown for ticket update or close,
   * based on role and country config.
   */
  checkTicketUpdateCloseRender(roleCheck, config) {
    let isRenderAllowed = false;
    const renderConfigType = config['RENDER_' + String(this.inputTicketType).toUpperCase() + '_TICKET'];

    // get user promise and set disableFlag accordingly
    if (roleCheck.checkCreateTicketRole && (_.isEqual(renderConfigType, 'true'))) {
      isRenderAllowed = true;
    }
    this.renderTemplateOnContainer(isRenderAllowed);
  }

  checkTicketHandoverRender(roleCheck, config) {
    let isRenderAllowed = false;

    /**
     * Note:- As discussed with Bastian,
     * TICKET_SELF_SERVICE_IDENTIFIER will always be one value and
     * not comma separated value.
     */
    if (roleCheck.checkCreateTicketRole
      && (_.isEqual(config.TICKET_SELF_SERVICE_IDENTIFIER, this.inputTicketId))) {
      isRenderAllowed = true;
    }
    this.renderTemplateOnContainer(isRenderAllowed);
  }

  equipmentAndAvailabilityShowHandler() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      const isRenderAllowed = this.ticketUtilService.shouldRenderSystemAvailabilityInTicketCreateByConfig(
        configResponse, this.inputModality, this.inputTicketType);
      this.renderTemplateOnContainer(isRenderAllowed);
    });
  }

  psrStatusItemShowHandler() {
    let isRenderAllowed = false;
    const allowedStatusForCancel = ['RANA', 'PROG', 'WIMG'];
    const allowedStatusForApprove = ['WIMG'];
    const allowedStatusForExtend = ['PROG'];

    switch (this.inputAction) {
      case 'cancel':
        if (_.includes(allowedStatusForCancel, this.inputQuoteStatus)) {
          isRenderAllowed = true;
        }
        break;
      case 'approve':
        if (_.includes(allowedStatusForApprove, this.inputQuoteStatus)) {
          isRenderAllowed = true;
        }
        break;
      case 'extend':
        if (_.includes(allowedStatusForExtend, this.inputQuoteStatus)) {
          isRenderAllowed = true;
        }
        break;
      default:
    }
    this.renderTemplateOnContainer(isRenderAllowed);
  }

  pmScheduleShowHandler() {
    let isRenderAllowed = false;

    // in timestamp
    const actualDate = new Date().setHours(0, 0, 0, 0);

    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      const sapSystem = configResponse.SAP_BACKEND_SYSTEM;
      const activityStatusClosed = this.activitiesConstantsService.getClosedActivityStatusForSapSystem(sapSystem);
      // first check due date is filled
      if (this.inputActivityItem.dueDate) {

        const modalityCodeSyngo = configResponse['MODALITY_CODE_SYNGO'];
        const modalityCodeInVitro = configResponse['MODALITY_CODE_INVITRO'];

        // Criteria :- 1
        const allowedByConfig = _.isEqual(configResponse['SCHEDULE_PLANNED_ACTIVITY_AVAILABLE'], 'true');

        // Criteria :- 2
        const isVivo =
          this.equipmentUtilService.isDefault(this.inputActivityItem.modality, modalityCodeSyngo, modalityCodeInVitro);

        // Criteria :- 3
        const isNotScheduled = !this.inputActivityItem.scheduled;

        // Criteria :- 4
        const isAllowedType = _.includes(configResponse['SCHEDULE_PLANNED_ACTIVITY_TYPE'], this.inputActivityItem.type);

        // Criteria :- 5
        // if actual date is before country configurable before date of due date
        const dueDate = new Date(this.inputActivityItem.dueDate);
        const daysBeforeDue = _.parseInt(configResponse['SCHEDULE_ACTIVITY_DAYS_BEFORE_DUE']);
        const dueDateDaysBefore = dueDate.setDate(dueDate.getDate() - daysBeforeDue);
        const dueDateDaysBeforeWithoutHours = new Date(dueDateDaysBefore).setHours(0, 0, 0, 0);

        const isInAllowedDateRange = (actualDate <= dueDateDaysBeforeWithoutHours);

        if (allowedByConfig && isInAllowedDateRange && isVivo && isNotScheduled && isAllowedType) {
          isRenderAllowed = true;
        }
      } else {
        this.logService.log('Due date is not filled for the current activity');
      }

      // Criteria :- 6
      // should not render for closed activities
      if (_.includes(activityStatusClosed, this.inputActivityItem.activityStatus)) {
        isRenderAllowed = false;
      }
      this.renderTemplateOnContainer(isRenderAllowed);
    });
  }

  psrSAOOrderButtonShowHandlers() {
    let isRenderAllowed = false;
    const isPSR = _.isEqual(this.inputType, 'psrBtn');
    const featureAvailableKey = isPSR ? 'PSR_FEATURE_AVAILABLE' : 'SAO_FEATURE_AVAILABLE';
    const allowedModalityKey = isPSR ? 'MODALITY_PSR_ALLOWED' : '';

    // read configuration
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      if (_.isEqual(configResponse[featureAvailableKey], 'true')) {
        const allowedModalities = isPSR ? configResponse[allowedModalityKey].split(',') : [];
        if (!isPSR || (!_.isEmpty(allowedModalities) && _.includes(allowedModalities, this.inputModality))) {
          isRenderAllowed = true;
        }
      }
      this.renderTemplateOnContainer(isRenderAllowed);
    });
  }

  updateActivityShowHandler() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      if (_.isEqual(configResponse['FEATURE_TOGGLE_UPDATE_PLANNED_ACTIVITIES'], 'true')) {
        this.showRescheduleItemShowHandler();
      } else {
        this.renderTemplateOnContainer(false);
      }
    });
  }
}
