import { WindowService } from '../../../core/window.service';
import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[hlScrollTop]'
})
export class ScrollTopDirective {

  window = this.windowService.nativeWindow;

  @HostListener('click', ['$event'])
  onClick($event) {
    $event.stopImmediatePropagation();
    if (typeof this.window.scrollTo !== 'undefined') {
      this.window.scrollTo(0, 0);
    }
  }

  constructor(private elRef: ElementRef,
    private windowService: WindowService) { }
}
