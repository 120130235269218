import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceBreakLinesWithComma'
})
export class ReplaceBreakLinesWithCommaPipe implements PipeTransform {

  transform(text: string): string | null {
    if (text) {
      return text
        .replace('&nbsp;<br>&nbsp;', '')
        .replace('<br/>', ', ');
    }
    return null;
  }

}
