import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Attachment } from '../../../core/models/attachment';

/**
 * Validates file size
 * @returns null if validation pass, otherwise returns {maxSizeExceeded: true}
 */
export class UploadUpdatePackageMetadataFileSizeValidator extends Validators {
  static fileMaxSizeValidator(maxSize: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const attachment: Attachment = control.value;

      if (attachment && Object.keys(attachment).length > 0) {
        return (Object(attachment)[0].filesize <= maxSize) ? null : {'maxSizeExceeded': true};
      }
      return null;
    };
  }
}
