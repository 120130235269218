import { TicketViewModel } from '../../../core/view-models/ticket-view-model';
import { TicketCloseModalComponent } from '../../modal-popup/ticket-close-modal/ticket-close-modal.component';
import { TicketUpdateModalComponent } from '../../modal-popup/ticket-update-modal/ticket-update-modal.component';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';

@Component({
  selector: 'hl-ticket-actions',
  templateUrl: './ticket-actions.component.html'
})
export class TicketActionsComponent implements OnInit {

  @ViewChild('updateModal', { static: false }) updateModal: TicketUpdateModalComponent;
  @ViewChild('handoverModal', { static: false }) handoverModal: TicketUpdateModalComponent;
  @ViewChild('closeModal', { static: false }) closeModal: TicketCloseModalComponent;

  @Input() viewModelTicket: TicketViewModel;

  constructor() {
  }

  ngOnInit() {
  }

  openTicketActionModal(actionType) {
    // Note: this time out is necessary to have enough time for *ngIf in HTML is rendered.
    setTimeout(() => {

      if (_.isEqual(actionType, 'convert')) {
        this.handoverModal.show();
      } else if (_.isEqual(actionType, 'update')) {
        this.updateModal.show();
      } else if (_.isEqual(actionType, 'close')) {
        this.closeModal.show();
      }
    }, 100);
  }
}
