import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { forEach } from 'lodash';
import { ImpersonationCommunicationService } from '../../core/component-communication-services/impersonation/impersonation-communication.service';
import { CountryConfigRestService } from '../../core/rest-services/country-config-rest.service';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EquipmentMissingModalComponent } from '../../shared/modal-popup/equipment-missing-modal/equipment-missing-modal.component';
import { environment } from '../../../environments/environment.prod';
import { WindowService } from '../../core/window.service';

@Component({
  selector: 'hl-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {

  @ViewChild('equipmentMissingModal', {static: false})
  equipmentMissingModal: EquipmentMissingModalComponent;

  additionalLinks: any[];
  subprocessorLinks: any[];
  copyRightLeftLabel = '';
  isConfigLoaded = false;
  isTranslated = false;

  isGdprSectionShown: boolean;
  showReportMissingEquipment = false;
  licenseTermsUrl = '';

  private readonly unsubscribe$: Subject<void> = new Subject();

  private static getTranslatedLinkKeys(translated: any, keys: string[]): any[] {
    const links: any[] = [];
    forEach(keys, (key) => {
      const link = {
        label: translated[key + '_LABEL'],
        url: translated[key + '_URL']
      };
      links.push(link);
    });
    return links;
  }

  private static getArrayFromString(object, key: string) {
    return object[key] ? object[key].split(',') : [];
  }

  constructor(private configService: CountryConfigRestService,
    private translateService: TranslateService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private windowService: WindowService) {
  }

  ngOnInit() {
    this.init();

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {

    this.additionalLinks = [];
    this.subprocessorLinks = [];

    const additionalLinksKey = 'FOOTER_FOURTH_COLUMN';
    const gdprLinksKey = 'LINKS_GDPR_SECTION';
    const licenceTermsFolder = this.windowService.nativeWindow.location.origin + '/' + (environment.version || '') + 'assets/licence-terms/';
    this.translateService.stream([additionalLinksKey, gdprLinksKey]).subscribe(translatedKeys => {

      this.additionalLinks = [];
      this.subprocessorLinks = [];

      const additionalLinksKeys = FooterComponent.getArrayFromString(translatedKeys, additionalLinksKey);
      const subprocessorLinksKeys = FooterComponent.getArrayFromString(translatedKeys, gdprLinksKey);
      const links: string[] = [];

      forEach([...additionalLinksKeys, ...subprocessorLinksKeys], (key) => {
        links.push(key + '_LABEL');
        links.push(key + '_URL');
      });

      this.translateService.get(links).subscribe(translated => {
        this.additionalLinks.push(...FooterComponent.getTranslatedLinkKeys(translated, additionalLinksKeys));
        this.subprocessorLinks.push(...FooterComponent.getTranslatedLinkKeys(translated, subprocessorLinksKeys));
        this.isTranslated = true;
      });
    });
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      const currentYear = new Date().getFullYear();
      this.copyRightLeftLabel = configResponse.COPYRIGHT_NOTICE + ' ' + currentYear;
      this.isConfigLoaded = true;
      this.isGdprSectionShown = _.isEqual(configResponse.TOGGLE_GDPR_SECTION, 'true');
      this.showReportMissingEquipment = _.isEqual(configResponse.FEATURE_TOGGLE_MISSING_EQUIPMENT_REQUEST, 'true');

      const licenseTermsUrl = configResponse.LICENSE_TERMS_URL;
      this.licenseTermsUrl = licenceTermsFolder + licenseTermsUrl;
    });
  }

  get hasGdprLinks(): boolean {
    return this.subprocessorLinks && this.subprocessorLinks.length > 0;
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    merge(
      this.impersonationCommunicationService.onCountryLanguageChange$,
      this.impersonationCommunicationService.onImpersonationChange$
    ).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.init();
    });
  }
}
