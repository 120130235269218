import * as pathToRegexp from 'path-to-regexp';
import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap, materialize, delay, dematerialize, map, tap } from 'rxjs/operators';

// models & services

import { User } from '../app/core/models/user';
import { EmailFrequency } from '../app/core/models/UserSettings';
import { ToasterService } from '../app/core/component-communication-services/toaster/toaster.service';

// rest endpoints

import { restEndPoint, configRestEndPoint } from '../app/core/core-constants.service';
import { systemUpdatesRestName, systemUpdateDetailRestName, systemUpdatesByEquipmentKey } from '../app/core/rest-services/system-updates-rest.service';
import { equipmentOptionsUpgradesRestName, equipmentDocumentsRestName, modalitiesRestName, modalitiesGBRestName } from '../app/core/rest-services/equipment-rest-constants.service';
import { psrRestName, psrRestTypes } from '../app/core/rest-services/professional-rest-constants.service';
import { customersImpersonateRestName } from '../app/core/rest-services/customer-groups-rest.service';
import { ticketsAllRestName, ticketsIndividualRestName, ticketSalutationsRestName, ticketTitlesRestName, ticketTypedModalities, problemSeveritiesRestName } from '../app/core/rest-services/ticket-rest-constants.service';

// Clickdummy mock data

import { CdEnvironmentConfig } from './cd-environment-config.mock';
import { CdUserRoles } from './cd-users.mock';
import { CdMyEquipmentProfile, CdLastViewedEquipments, CdEqDocuments } from './cd-equipment.mock';
import { CdCustomerContacts } from './cd-customers.mock';
import { CdModalityList } from './cd-modalities.mock';
import { CdPsrTypes } from './cd-professional-service-request.mock';
import { CdTicketSalutations, CdTicketTitles, CdTicketTypedModalities, CdTicketProblemSevereties } from './cd-tickets.mock';
import { EnvironmentDataMock } from './cd-mock-environment-data';
import { CdEqUsage } from './cd-equipment-usage.mock';

@Injectable()
export class MockHttpBackend implements HttpInterceptor {
  activitiesCountOpenRestReg = pathToRegexp(restEndPoint + 'activities/count/open?equipmentKey=');
  activitiesDetailsRestNameReg = pathToRegexp(restEndPoint + 'activities/:id/details');
  activitiesRestNameReg = pathToRegexp(restEndPoint + 'activities');
  addRemoveMyEquipmentsRestNameReg = pathToRegexp(restEndPoint + 'equipments/myProfile/:id');
  announcementRestNameReg = pathToRegexp(restEndPoint + 'announcement/');
  contractsIndividualRestNameReg = pathToRegexp(restEndPoint + 'contracts/:id');
  contractsRestNameReg = pathToRegexp(restEndPoint + 'contracts');
  configRestEndPointNameReg = pathToRegexp(configRestEndPoint);
  customerContactsRestNameReg = pathToRegexp(restEndPoint + 'customers/contacts');
  customersImpersonateRestNameReg = pathToRegexp(customersImpersonateRestName);
  environmentConfigRestNameReg = pathToRegexp(restEndPoint + 'environmentConfig');
  equipmentDetailsRestNameReg = pathToRegexp(restEndPoint + 'equipments/:id/details');
  equipmentDocumentsRestNameReg = pathToRegexp(equipmentDocumentsRestName);
  equipmentForMyFilterRestNameReg = pathToRegexp(restEndPoint + 'equipments/for-my-filters');
  equipmentOptionsUpgradesRestNameReg = pathToRegexp(equipmentOptionsUpgradesRestName);
  equipmentRestNameReg = pathToRegexp(restEndPoint + 'equipments');
  equipmentStatusRestNameReg = pathToRegexp(restEndPoint + 'equipments/status');
  invoicesRestNameReg = pathToRegexp(restEndPoint + 'invoices');
  isTeamplayAvailableRestNameReg = pathToRegexp(restEndPoint + 'teamplay/data/available');
  lastViewedEquipmentRestNameReg = pathToRegexp(restEndPoint + 'equipments/lastviewed');
  messagesRestNameReg = pathToRegexp(restEndPoint + 'messages');
  modalitiesGBRestNameReg = pathToRegexp(modalitiesGBRestName);
  modalitiesRestNameReg = pathToRegexp(modalitiesRestName);
  myProfileCheckedRestName = pathToRegexp(restEndPoint + 'equipments/myProfile/myProfileSelected');
  myProfileRestNameReg = pathToRegexp(restEndPoint + 'equipments/myProfile');
  postLastViewedRestNameReg = pathToRegexp(restEndPoint + 'equipments/:id/lastviewed');
  problemSeveritiesRestNameReg = pathToRegexp(problemSeveritiesRestName);
  psrRestNameReg = pathToRegexp(psrRestName);
  psrRestTypesReg = pathToRegexp(psrRestTypes);
  securityAdvisoriesActiveRestNameReg = pathToRegexp(restEndPoint + 'security-advisories/active');
  securityAdvisoriesAllRestNameReg = pathToRegexp(restEndPoint + 'security-advisories/all');
  securityNotificationsRestNameReg = pathToRegexp(restEndPoint + 'security-notifications');
  serviceMetricsAvailableRestNameReg = pathToRegexp(restEndPoint + 'service-metrics/available');
  sourceListRestNameReg = pathToRegexp(restEndPoint + 'source/list');
  systemUpdatesByEquipmentKeyReg = pathToRegexp(systemUpdatesByEquipmentKey);
  systemUpdatesDetailRestNameReg = pathToRegexp(systemUpdateDetailRestName);
  systemUpdatesRestNameReg = pathToRegexp(systemUpdatesRestName);
  teamplayRestNameReg = pathToRegexp(restEndPoint + 'teamplay/data');
  teamplayDetailRestName = pathToRegexp(restEndPoint + 'teamplay/detail/:id');
  ticketSalutationsRestNameReg = pathToRegexp(ticketSalutationsRestName);
  ticketTitlesRestNameReg = pathToRegexp(ticketTitlesRestName);
  ticketTypedModalitiesReg = pathToRegexp(ticketTypedModalities);
  ticketsAllRestNameReg = pathToRegexp(ticketsAllRestName);
  ticketsIndividualRestNameReg = pathToRegexp(ticketsIndividualRestName);
  trainingsRestReg = pathToRegexp(restEndPoint + 'trainings');
  userAssignedCustomersRestNameReg = pathToRegexp(restEndPoint + 'users/assignedCustomers');
  userLogoutRestNameReg = pathToRegexp(restEndPoint + 'users/logout');
  userRestNameReg = pathToRegexp(restEndPoint + 'users/self');
  userRolesRestNameReg = pathToRegexp(restEndPoint + 'users/self/roles');
  userSelfIntroShownRestNameReg = pathToRegexp(restEndPoint + 'users/introShown');
  userSettingsRestReg = pathToRegexp(restEndPoint + 'users/self/settings');

  unauthorized = 'Unauthorized';
  currentUser: User;
  myProfileChecked = false;

  constructor(
    private toast: ToasterService,
    private router: Router,
    private environmentData: EnvironmentDataMock
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(restEndPoint + 'equipment/environment/data-exists') >= 0) {
      return of(new HttpResponse({
        status: 200,
        body: true
      }));
    }
    if (
      request.url.indexOf(restEndPoint + 'equipment/environment/') >= 0 &&
      request.url.indexOf('options') >= 0 && request.url.indexOf(restEndPoint + 'equipment/environment/data-exists') === -1
    ) {
      return of(
        new HttpResponse({
          status: 200,
          body: {
            sensors: { ['Minilab']: 1 },
            parameters: {
              ['Temperature']: 'temperature',
              ['Humidity']: 'humidity'
            }
          }
        })
      );
    }
    if (
      request.url.indexOf(restEndPoint + 'equipment/environment/') >= 0 &&
      request.url.indexOf('data') >= 0 && request.url.indexOf(restEndPoint + 'equipment/environment/data-exists') === -1
    ) {
      this.environmentData.request = request;
      return of(
        new HttpResponse({
          status: 200,
          body: this.environmentData.getData()
        })
      );
    }
    if (
      request.url.indexOf(restEndPoint + 'logistic/deliveryMilestones/') >= 0
    ) {
      return this.getMilestones(request, next);
    }
    if (this.userRestNameReg.test(request.url)) {
      return this.getUsers(request, next);
    }

    if (this.userAssignedCustomersRestNameReg.test(request.url)) {
      return this.getAssignedCustomer(request, next);
    }
    if (request.url.indexOf('users/self/settings/mycustomers') >= 0) {
      if (request.method === 'GET') {
        return this.getAssignedCustomer(request, next);
      }
      return this.postAssignedCustomer(request, next);
    }

    // get trainings by equipment with materialnumber
    if (this.trainingsRestReg.test(request.url)) {
      return this.getTrainings(request, next);
    }

    if (
      this.securityAdvisoriesAllRestNameReg.test(request.url) ||
      this.securityAdvisoriesActiveRestNameReg.test(request.url)
    ) {
      return this.getSecurityAdvisories(request, next);
    }
    if (
      request.url.indexOf(restEndPoint + 'security-advisories/attachments') >= 0
    ) {
      return this.getSecAvAttachments(request, next);
    }
    if (
      request.url.indexOf(restEndPoint + 'security-advisories/download-url') >=
      0
    ) {
      return this.getSecAvAttachmentsUrl(request, next);
    }

    if (this.activitiesRestNameReg.test(request.url)) {
      return this.getActivities(request, next);
    }
    if (this.problemSeveritiesRestNameReg.test(request.url)) {
      return of(
        new HttpResponse({ status: 200, body: CdTicketProblemSevereties })
      );
    }
    if (this.ticketTypedModalitiesReg.test(request.url)) {
      return of(
        new HttpResponse({ status: 200, body: CdTicketTypedModalities })
      );
    }
    if (this.ticketSalutationsRestNameReg.test(request.url)) {
      return of(new HttpResponse({ status: 200, body: CdTicketSalutations }));
    }
    if (this.ticketTitlesRestNameReg.test(request.url)) {
      return of(new HttpResponse({ status: 200, body: CdTicketTitles }));
    }
    if (this.ticketsAllRestNameReg.test(request.url)) {
      return this.getTickets(request, next);
    }
    if (this.ticketsIndividualRestNameReg.test(request.url)) {
      return this.getTicket(request, next);
    }

    if (this.activitiesDetailsRestNameReg.test(request.url)) {
      return this.getActivitiesDetails(request, next);
    }

    if (
      request.url.indexOf(restEndPoint + 'equipments/security-whitepaper') >= 0
    ) {
      return this.getDownloadUrl(request);
    }

    if (this.equipmentStatusRestNameReg.test(request.url)) {
      return this.getEquipmentStatus(request, next);
    }

    if (this.equipmentRestNameReg.test(request.url) || this.equipmentForMyFilterRestNameReg.test(request.url)) {
      return this.getEquipment(request, next);
    }

    if (this.equipmentDetailsRestNameReg.test(request.url)) {
      return this.getEquipmentDetails(request, next);
    }
    if (request.url.indexOf('/rest/v1/source/technical-documentation') >= 0) {
      const param = request.url.split('/').reverse()[0];
      return this.getEqDocuments(request, next, param);
    }
    if (request.url.indexOf('/rest/v1/source/service-report') >= 0) {
      const param = request.url.split('/').reverse()[0];
      return this.getEqDocuments(request, next, param);
    }
    if (request.url.indexOf('/rest/v1/source/product-line-document') >= 0) {
      const param = request.url.split('/').reverse()[0];
      return this.getEqDocuments(request, next, param);
    }

    if (this.myProfileRestNameReg.test(request.url)) {
      return this.getMyEquipmentProfile(request, next);
    }

    if (this.contractsRestNameReg.test(request.url)) {
      return this.getContracts(request, next);
    }

    if (this.securityNotificationsRestNameReg.test(request.url)) {
      return this.getSecurityNotifications(request, next);
    }

    if (request.url.indexOf(restEndPoint + 'security-notifications') >= 0) {
      return this.getSecurityNotificationsSingle(request, next);
    }

    if (this.systemUpdatesRestNameReg.test(request.url)) {
      return this.getSystemUpdates(request, next);
    }

    if (this.systemUpdatesDetailRestNameReg.test(request.url)) {
      return this.getSystemUpdatesDetails(request, next);
    }

    if (request.url.indexOf(systemUpdatesByEquipmentKey) >= 0) {
      return this.getSystemUpdatesByEquipmentKey(request, next);
    }

    if (this.modalitiesRestNameReg.test(request.url)) {
      return this.getModalities(request, next);
    }

    if (this.invoicesRestNameReg.test(request.url)) {
      return this.getInvoices(request, next);
    }

    if (this.psrRestNameReg.test(request.url)) {
      if (request.method === 'GET') {
        return this.getProfessionalServiceRequests(request, next);
      }
      return this.getProfessionalServiceRequestsPost(request, next);
    }

    if (this.equipmentOptionsUpgradesRestNameReg.test(request.url)) {
      return this.getOptionsUpgrades(request, next);
    }
    if (this.contractsIndividualRestNameReg.test(request.url)) {
      return this.getContract(request, next);
    }
    if (
      request.url.indexOf(restEndPoint + 'contracts') >= 0 &&
      request.url.indexOf('usageData') >= 0
    ) {
      const id = request.url.split('/').reverse()[1];
      if (id.toString() === '7865600076') {
        return of(new HttpResponse({ status: 200, body: CdEqUsage }));
      }
      return of(new HttpResponse({ status: 200, body: {} }));
    }

    if (
      request.url.indexOf('.siemens-healthineers.') >= 0 &&
      request.url.indexOf('listPageData') >= 0
    ) {
      return this.getNews(request, next);
    }
    if (
      request.url.indexOf(restEndPoint + 'equipments') >= 0 &&
      request.url.indexOf('security') >= 0
    ) {
      return this.getPiisEq(request, next);
    }
    if (this.teamplayRestNameReg.test(request.url)) {
      return this.getTeamplayWidgetsDashboard(request, next);
    }
    if (this.teamplayDetailRestName.test(request.url)) {
      return this.getTeamplayUsage(request, next);
    }
    if (this.messagesRestNameReg.test(request.url)) {
      return this.getBellNotifications(request, next);
    }
    if (this.announcementRestNameReg.test(request.url)) {
      return this.getAnnouncements(request, next);
    }

    if (request.url.indexOf(restEndPoint + 'reports/nyuReport/allowed') >= 0) {
      return of(new HttpResponse({ status: 200, body: false }));
    }
    if (request.url.indexOf('.json') >= 0) {
      return next.handle(request);
    } else {
      return of(null).pipe(
        mergeMap(() => {
          const data = this.getData(request);
          if (data === undefined) {
            console.log('data undefined', request);
          }
          if (data === this.unauthorized) {
            return Observable.throw(new HttpErrorResponse({ status: 401 }));
          } else {
            return of(new HttpResponse({ status: 200, body: data }));
          }
        }),
        materialize(),
        delay(500),
        dematerialize()
      );
    }
  }

  private getDownloadUrl(req) {
    const path = req.params.map.get('path')[0];
    if (path) {
      return of(new HttpResponse({ status: 200, body: { url: path } }));
    }
    return Observable.throw(new HttpErrorResponse({ status: 401 }));
  }

  private getOptionsUpgrades(req: HttpRequest<any>, next: HttpHandler) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-options-upgrades',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const eId = req.url.match(
            this.equipmentOptionsUpgradesRestNameReg
          )[1];
          let value = [];
          const eValue = event.body.find(d => d.id === eId);
          if (eValue) {
            value = eValue.value;
          }

          return event.clone({
            body: value
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getProfessionalServiceRequests(
    req: HttpRequest<any>,
    next: HttpHandler
  ) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-professional-service-requests',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getProfessionalServiceRequestsPost(
    req: HttpRequest<any>,
    next: HttpHandler
  ) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-professional-service-requests-post',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getUsers(req: HttpRequest<any>, next: HttpHandler) {
    const newReq = req.clone({
      url: '/assets/data/mocks/users.json'
    });
    const userGid = localStorage.getItem('mockUserGid');
    if (!userGid) {
      this.router.navigate(['/welcome']);
      return of(new HttpErrorResponse({ status: 401 }));
    }
    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const user = event.body.filter(item => item.gid === userGid)[0];
          if (!user || user === undefined) {
            return new HttpErrorResponse({ status: 401, url: req.url });
          }
          const response = event.clone({
            body: user
          });
          this.setUser(user);
          return response;
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          this.unsetUser();
          this.currentUser = null;
          return this.catchErrors(event);
        }
      })
    );
  }

  private postAssignedCustomer(req, next: HttpHandler) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-assigned-customers-post',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });
    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getAssignedCustomer(req: HttpRequest<any>, next: HttpHandler) {
    const userId = localStorage.getItem('mockUserGid');
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-assigned-customers',
      setHeaders: {
        Authorization: `Bearer abcd`
      },
      setParams: {
        user: userId
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getModalities(req: HttpRequest<any>, next: HttpHandler) {
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-modalities',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getNews(req: HttpRequest<any>, next: HttpHandler) {
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-news',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getEquipment(req: HttpRequest<any>, next: HttpHandler) {
    const userId = localStorage.getItem('mockUserGid');
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-equipments',
      setHeaders: {
        Authorization: `Bearer abcd`
      },
      setParams: {
        user: userId
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getEqDocuments(req, next: HttpHandler, param: string) {
    const eqDocId = req.params.map.get('equipmentKey')[0];
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-equipments-details',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const attachDocs = event.body.attachments.find(
            item => item.id.toString() === eqDocId.toString()
          );
          const eqDocs = attachDocs
            ? attachDocs.attachments.filter(
              item => item.attachmentCat === param
            )
            : [];
          return event.clone({
            body:
              eqDocs.length > 0
                ? {
                  documents: [
                    {
                      name: param,
                      list: eqDocs
                    }
                  ]
                }
                : { documents: [] }
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getEquipmentDetails(req: HttpRequest<any>, next: HttpHandler) {
    const id = req.url.match(this.equipmentDetailsRestNameReg)[1];
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-equipments-details',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const EquipmentDetails = {
            attachments: [],
            components: []
          };
          EquipmentDetails.components = event.body.components.filter(
            d => d.equipmentKey === id.toString()
          );
          const attachDocs = event.body.attachments.filter(
            item => item.id === id
          );
          EquipmentDetails.attachments =
            attachDocs.length > 0 ? attachDocs[0].attachments : [];
          return event.clone({
            body: EquipmentDetails
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getMyEquipmentProfile(req: HttpRequest<any>, next: HttpHandler) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-my-equipment-profile',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getEquipmentStatus(req: HttpRequest<any>, next: HttpHandler) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-my-equipment-status',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getSecurityAdvisories(req: HttpRequest<any>, next: HttpHandler) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-security-advisories',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }
  private getSecAvAttachments(req, next: HttpHandler) {
    const params = req.params.map;
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-security-advisories',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });
    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const files = event.body.find(
            item => item.id.toString() === params.get('id')[0]
          );
          return event.clone({
            body: files && files.files.length > 0 ? files.files : []
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }
  private getSecAvAttachmentsUrl(req, next: HttpHandler) {
    const params = req.params.map;
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-security-advisories',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });
    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const files = event.body.find(
            item => item.id.toString() === params.get('id')[0]
          );
          const file = files.files.find(
            item => item.type === params.get('fileType')[0]
          );
          return event.clone({
            body: file ? { url: file.fullPath } : {}
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getSecurityNotifications(req: HttpRequest<any>, next: HttpHandler) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-security-notifications',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getSecurityNotificationsSingle(
    req: HttpRequest<any>,
    next: HttpHandler
  ) {
    const idSingle = req.url.split('/').reverse()[0];
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-security-notifications',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });
    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const item = event.body.find(ele => {
            return ele.id.toString() === idSingle.toString();
          });
          return event.clone({
            body: item ? item : {}
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getSystemUpdates(req: HttpRequest<any>, next: HttpHandler) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-system-updates',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getSystemUpdatesDetails(req, next: HttpHandler) {
    const params = req.params.map;
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-system-updates',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body.filter(
              item => item.content === params.get('updateNumber')[0]
            )[0]
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getSystemUpdatesByEquipmentKey(req, next: HttpHandler) {
    const params = req.params.map;
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-system-updates',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const eqKeyUpdates = req.url.split('/').reverse();
          return event.clone({
            body: event.body.filter(item =>
              item.equipmentItems.some(
                i => i.key === eqKeyUpdates[0].toString()
              )
            )
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getTrainings(req, next: HttpHandler) {
    const params = req.params.map;
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-trainings',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const training = event.body.find(
            item =>
              item.equipmentKey.toString() ===
              params.get('equipmentKey')[0].toString()
          );
          return event.clone({
            body: training.trainings
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }
  private getPiisEq(req, next: HttpHandler) {
    const equipmentKey = req.url.split('/').reverse()[1];
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-security',
      setHeaders: {
        Authorization: `Bearer abcd`
      },
      setParams: {
        equipmentKey
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getActivities(req, next: HttpHandler) {
    const userId = localStorage.getItem('mockUserGid');
    const params = req.params.map;
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-activities',
      setHeaders: {
        Authorization: `Bearer abcd`
      },
      setParams: {
        user: userId
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          let listA = event.body;
          if (params) {
            const statusFilter = params.get('statusFilter')
              ? params.get('statusFilter')[0]
              : null;
            const equipmentKeyA = params.get('equipmentKey')
              ? params.get('equipmentKey')[0]
              : null;
            if (statusFilter && statusFilter !== '3') {
              listA = listA.filter(
                item => item.activityStatus === statusFilter
              );
            }
            if (statusFilter && statusFilter === 3) {
              listA = listA;
            }
            if (equipmentKeyA) {
              listA = listA.filter(item => item.equipmentKey === equipmentKeyA);
            }
          }
          return event.clone({
            body: listA
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getActivitiesDetails(req, next: HttpHandler) {
    const userId = localStorage.getItem('mockUserGid');
    const activityId = req.url.match(this.activitiesDetailsRestNameReg)[1];
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-activities',
      setHeaders: {
        Authorization: `Bearer abcd`
      },
      setParams: {
        user: userId
      }
    });
    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const activity =
            event.body.find(a => a.ticketKey === activityId) || {};
          return event.clone({
            body: activity
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getContract(req, next: HttpHandler) {
    const contractId = req.url.match(this.contractsIndividualRestNameReg)[1];
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-contracts',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const contract =
            event.body.find(a => a.contractNumber === contractId) || {};
          return event.clone({
            body: contract.details ? contract.details : {}
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getContracts(req, next: HttpHandler) {
    const params = req.params.map;
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-contracts',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          let listC = event.body;
          if (params) {
            const contractsStatusFilter = params.get('statusFilter')
              ? params.get('statusFilter')[0]
              : null;
            if (contractsStatusFilter) {
              listC = listC.filter(item =>
                contractsStatusFilter.split(',').includes(item.contractStatusId)
              );
            }
          }
          return event.clone({
            body: listC
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getInvoices(req, next: HttpHandler) {
    const params = req.params.map;
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-invoices',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          let list = event.body;
          if (params) {
            const invoiceType = params.get('invoiceType')
              ? params.get('invoiceType')[0]
              : null;
            if (invoiceType) {
              list = list.filter(item => item.invoiceType === invoiceType);
            }
          }
          return event.clone({
            body: list
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getTicket(req, next: HttpHandler) {
    const ticketId = req.url.match(this.ticketsIndividualRestNameReg)[1];
    // const ticket = CdTicketList.find(t => t.ticketKey === ticketId);
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-tickets',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });
    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const ticket = event.body.find(t => t.ticketKey === ticketId);
          return event.clone({
            body: ticket
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getTickets(req, next: HttpHandler) {
    const params = req.params.map;
    const newReq = req.clone({
      url: 'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-tickets',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          let listT = event.body;
          if (params) {
            const equipementKey = params.get('equipmentKey')
              ? params.get('equipmentKey')[0]
              : null;
            const statusFilter = params.get('statusFilter')
              ? params.get('statusFilter')[0]
              : null;
            const dateStartFilter = params.get('dateStartFilter')
              ? params.get('dateStartFilter')[0]
              : null;
            const dateEndFilter = params.get('dateEndFilter')
              ? params.get('dateEndFilter')[0]
              : null;

            if (equipementKey) {
              listT = listT.filter(item => item.equipmentKey === equipementKey);
            }

            if (statusFilter && statusFilter !== '3') {
              listT = listT.filter(item => item.ticketStatus === statusFilter);
            }
            if (dateStartFilter && dateStartFilter !== '01.01.1970') {
              listT = listT.filter(
                item =>
                  new Date(item.ticketCreationTimestamp) >=
                  new Date(
                    dateStartFilter
                      .split('.')
                      .reverse()
                      .join('/')
                  )
              );
            }
            if (dateEndFilter && dateEndFilter !== '01.01.2070') {
              listT = listT.filter(
                item =>
                  new Date(item.ticketCreationTimestamp) <=
                  new Date(
                    dateEndFilter
                      .split('.')
                      .reverse()
                      .join('/')
                  )
              );
            }
          }

          return event.clone({
            body: listT
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getMilestones(req, next: HttpHandler) {
    const ticketId = req.url.split('/').reverse()[0];
    // const ticket = CdTicketList.find(t => t.ticketKey === ticketId);
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-parts-delivery-milestones',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });
    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const milestones = event.body.find(t => t.id === ticketId);
          return event.clone({
            body: milestones ? milestones.data : null
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getTeamplayWidgetsDashboard(req, next: HttpHandler) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-teamplay-widgets-dashboard',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getTeamplayUsage(req, next: HttpHandler) {
    const serialId = req.url.match(this.teamplayDetailRestName)[1];

    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-teamplay-usage',
      setHeaders: {
        Authorization: `Bearer abcd`
      },
      setParams: {
        serial: serialId
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getAnnouncements(req, next: HttpHandler) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/items/announcements',
      setHeaders: {
        Authorization: `Bearer abcd`
      },
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body.data.find(el => el['set_active']) || "",
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private getBellNotifications(req, next: HttpHandler) {
    const newReq = req.clone({
      url:
        'https://fleet-cms.click-solutions.com/public/_/custom/export/cd-bell-notifications',
      setHeaders: {
        Authorization: `Bearer abcd`
      }
    });

    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          return event.clone({
            body: event.body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }


  private setUser(user) {
    const userGid = localStorage.getItem('mockUserGid');
    this.currentUser = user;
  }

  private unsetUser() {
    localStorage.removeItem('mockUserGid');
  }

  private getData(request): any {
    const url = request.url;
    const params = request.params.map;

    switch (true) {
      case url.indexOf(restEndPoint + 'activities/types') >= 0:
        return [
          { typeId: '3', typeDescription: 'Update Service' },
          { typeId: '2', typeDescription: 'Maintenance' }
        ];
      case this.equipmentDocumentsRestNameReg.test(url):
        const eqId = url.match(this.equipmentDocumentsRestNameReg)[1];
        const docs = CdEqDocuments.find(d => d.id === eqId);
        if (docs.details) {
          return docs.details;
        } else {
          return {};
        }
      case this.sourceListRestNameReg.test(url):
        return [
          {
            id: 'technical-documentation',
            sourceUrl: '/rest/v1/source/technical-documentation',
            sourceImage:
              'https://lifenet.healthcare.siemens.com/static/document_images/default.jpg',
            sourceTitle: 'Technical Documentation',
            sourceDescription: ''
          },
          {
            id: 'service-report',
            sourceUrl: '/rest/v1/source/service-report',
            sourceImage:
              'https://lifenet.healthcare.siemens.com/static/document_images/default.jpg',
            sourceTitle: 'Service Report',
            sourceDescription: ''
          },
          {
            id: 'product-line-document',
            sourceUrl: '/rest/v1/source/product-line-document',
            sourceImage:
              'https://lifenet.healthcare.siemens.com/static/document_images/default.jpg',
            sourceTitle: 'Product line document',
            sourceDescription: ''
          }
        ];
      case this.serviceMetricsAvailableRestNameReg.test(url):
        return { available: false };
      // !!!!!!!
      // Tickets
      case this.lastViewedEquipmentRestNameReg.test(url):
        return CdLastViewedEquipments;
      case this.postLastViewedRestNameReg.test(url):
        return '';

      // Professional Service Requests
      // case this.psrRestNameReg.test(url):
      //   return CdProfessionalServiceRequestList;
      case this.psrRestTypesReg.test(url):
        return CdPsrTypes;

      case url.indexOf('activities/count/open') >= 0:
        return { count: 2 }; //count activities per equipment
      case this.userSettingsRestReg.test(url):
        return {
          subprocessorNotificationSubscribed: false,
          emailFrequency: EmailFrequency.NEVER,
          securityAdvisoriesSubscribed: true
        };

      // Customers
      case this.customersImpersonateRestNameReg.test(url):
        return {}; // return CdImpersonatedCustomers;
      case this.customerContactsRestNameReg.test(url):
        return CdCustomerContacts;

      // Environment Config
      case this.environmentConfigRestNameReg.test(url):
        return CdEnvironmentConfig;

      case this.modalitiesGBRestNameReg.test(url):
        const listM: string[] = [];
        CdModalityList.forEach(onjex => {
          listM.push(onjex.modalityDescription);
        });
        return listM;

      case this.myProfileCheckedRestName.test(url):
        if (request.method === 'POST') {
          this.myProfileChecked = !this.myProfileChecked;
        }
        return { checked: this.myProfileChecked };
      case this.addRemoveMyEquipmentsRestNameReg.test(url):
        const equipId = url.match(this.addRemoveMyEquipmentsRestNameReg)[1];
        if (CdMyEquipmentProfile.equipment.find(el => el === equipId)) {
          CdMyEquipmentProfile.equipment = CdMyEquipmentProfile.equipment.filter(
            el => el !== equipId
          );
        } else {
          CdMyEquipmentProfile.equipment.push(equipId);
        }
        return {};

      // User / User Roles / Logout
      case this.userRestNameReg.test(url):
        if (this.currentUser) {
          return this.currentUser;
        } else {
          return this.unauthorized;
        }
      case this.userLogoutRestNameReg.test(url):
        this.unsetUser();
        return {};
      case this.userRolesRestNameReg.test(url):
        return CdUserRoles[localStorage.getItem('mockUserGid')]
          ? CdUserRoles[localStorage.getItem('mockUserGid')]
          : {};
      case this.userSelfIntroShownRestNameReg.test(url):
        return { shown: true };
      case this.configRestEndPointNameReg.test(url):
        return { status: "DOWN" };
      case this.isTeamplayAvailableRestNameReg.test(url):
        return true;

      // Default (no data)
      default:
        console.log('No Data: ', request);
        return {};
    }
  }

  private catchErrors(event) {
    if (event instanceof HttpErrorResponse) {
      switch (event.status) {
        case 401:
          // navigate to welcome
          this.router.navigate(['/welcome']);
          break;

        case 403:
          // check for self in url to find errors from /user/self or /user/self/roles
          // then logout session and show error message
          if (_.includes(event.url, 'self')) {
            this.router.navigate(['/welcome']);
            this.toastError('FRONTEND_LOGIN_ERROR_MESSAGE_AUTHORIZATION');
          }
          break;

        case 404:
          this.toastError('FRONTEND_GENERIC_ERROR_MESSAGE');
          break;

        // default case is anything else with status 400 or 500
        default:
          // show default error message
          let errorMessageKey = 'FRONTEND_GENERIC_ERROR_MESSAGE';

          // special cases for performance report and excel report
          if (_.includes(event.url, 'AsPdf')) {
            errorMessageKey =
              'FRONTEND_SERVICE_PERFORMANCE_REPORT_ERROR_MESSAGE';
          } else if (_.includes(event.url, 'generateExcel')) {
            errorMessageKey = 'FRONTEND_EXCEL_REPORT_ERROR_MESSAGE';
          }
          this.toastError(errorMessageKey);
          break;
      }
    }
  }

  toastError(message: string) {
    const toast = {
      type: 'error',
      isBodyTranslationKey: true,
      body: message
    };
    this.toast.emitToast(toast);
  }
}

export let MockHttpBackendProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: MockHttpBackend,
  multi: true
};
