import { Modality } from '../models/equipment/modality';
import { EquipmentComponentItem, EquipmentDetails } from '../models/equipment/equipment-details';
import { EquipmentStatusForCustomer } from '../models/equipment/equipment-status';
import { LastViewedEquipment } from '../models/last-viewed-equipment';
import { EquipmentMyProfile, EquipmentMyProfileChecked } from '../models/equipment/equipment-my-profile';
import { Equipment } from '../models/equipment/equipment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  addRemoveMyEquipmentsRestName,
  allSelectableEquipments,
  equipmentDeativateRestName,
  equipmentDetailsRestName,
  equipmentDocumentsRestName,
  equipmentEnvironmentDataExistsRestName,
  equipmentEnvironmentDataRestName,
  equipmentEnvironmentOptionsRestName,
  equipmentForMyFiltersRestName,
  equipmentIndividualRestName,
  equipmentNwePortalDocumentsRestName,
  equipmentOptionsUpgradesQuoteRestName,
  equipmentOptionsUpgradesRecommendationRestName,
  equipmentOptionsUpgradesRestName,
  equipmentOptionsUpgradesTrialRestName,
  equipmentPatchDocumentsRestName,
  equipmentRestName,
  equipmentRwfWhitelisted,
  equipmentRwfWhitelistExists,
  equipmentsComponentsRestName,
  equipmentsContactsRestName,
  equipmentSimulatorCreate,
  equipmentsMobileAddressesRestName,
  equipmentsPiiDetailsRestName,
  equipmentSrsStatusCheck,
  equipmentStatusRestName,
  equipmentSyngoVoucherRestName,
  equipmentSyngoVoucherWhitelisted,
  lastViewedEquipmentRestName,
  modalitiesGBRestName,
  modalitiesRestName,
  modalitiesWhitelistRestName,
  myProfileCheckedRestName,
  myProfileRestName,
  postLastViewedRestName,
  reportMissingEquipmentRestName,
  securityWhitePaperRestname
} from './equipment-rest-constants.service';
import { LifenetSystemCustom } from '../models/optionsUpgrades/lifenet-system-custom';
import { EqDocumentType } from '../models/equipment/eq-document-type';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { AdditionalContacts } from '../models/additional-contacts';
import { EqMobileAddress } from '../models/equipment/eq-mobile-address';
import { EquipmentPiiDetails } from '../models/equipment/equipment-pii-details';
import { EquipmentViewModel } from '../view-models/equipment-view-model';
import { HttpParams } from '@angular/common/http';
import { DownloadUrl } from '../models/systemUpdates/download-url';
import { EquipmentInformation } from '../models/equipment/equipment-information';
import { environment } from '../../../environments/environment';
import { HttpCacheService } from '../services/cache/http-cache.service';
import {
  EquipmentEnvironmentData,
  EquipmentEnvironmentDataInput,
  EquipmentEnvironmentFilterOptions
} from '../models/equipment/equipment-environment';
import { SyngoVoucherInput } from '../models/equipment/documents/syngo-voucher-input';
import { EquipmentSrsStatus } from '../models/equipment/equipment-srs-status';

@Injectable()
export class EquipmentRestService {

  constructor(private http: CacheableHttpClient, private cacheService: HttpCacheService) {
  }

  private static getUrl(url: string, key: string) {
    return url.replace(/:id/g, key);
  }

  getLastViewedEquipment(): Observable<LastViewedEquipment[]> {
    return this.http.get<LastViewedEquipment[]>(lastViewedEquipmentRestName, {cache: false});
  }

  getMyProfile(): Observable<EquipmentMyProfile> {
    return this.http.get<EquipmentMyProfile>(myProfileRestName);
  }

  getMyProfileChecked(): Observable<EquipmentMyProfileChecked> {
    return this.http.get<EquipmentMyProfileChecked>(myProfileCheckedRestName, {cache: false});
  }

  getEquipmentStatus(): Observable<EquipmentStatusForCustomer[]> {
    return this.http.get<EquipmentStatusForCustomer[]>(equipmentStatusRestName);
  }

  getEquipmentSystemInformation(key: string): Observable<EquipmentInformation> {
    return this.http.get<EquipmentInformation>(equipmentRestName + '/equipmentInfo', {
      params: new HttpParams().set('equipmentKey', key)
    });
  }

  getEquipment(): Observable<Equipment[]> {
    return this.http.get<Equipment[]>(equipmentRestName);
  }

  getEquipmentDetails(key: string): Observable<EquipmentDetails> {
    return this.http.get(EquipmentRestService.getUrl(equipmentDetailsRestName, key));
  }

  postAddEquipmentToMyProfile(key): Observable<any> {
    this.clearCache(myProfileRestName);
    return this.http.post(EquipmentRestService.getUrl(addRemoveMyEquipmentsRestName, key), null);
  }

  postEditComponent(component: EquipmentComponentItem, equipmentKey: string): Observable<any> {
    this.clearEquipmentDetailsCache(equipmentKey);
    return this.http.post(EquipmentRestService.getUrl(equipmentsComponentsRestName, equipmentKey), component);
  }

  postMyProfileChecked(isChecked: boolean): Observable<Object> {
    return this.http.post(myProfileCheckedRestName, {checked: isChecked});
  }

  postLastViewedEquipment(key: string) {
    this.http.post(EquipmentRestService.getUrl(postLastViewedRestName, key), null, {
      responseType: 'text'
    }).subscribe(() => {
      // do nothing.
    });
  }

  postEditEquipment(key, body): Observable<any> {
    this.clearGetEquipmentsListCache();
    return this.http.post(EquipmentRestService.getUrl(equipmentIndividualRestName, key), body);
  }

  clearGetEquipmentsListCache() {
    this.clearCache(equipmentRestName);
    this.clearCache(equipmentRestName + '/equipmentsList');
  }

  clearEquipmentDetailsCache(key: string) {
    this.clearCache(EquipmentRestService.getUrl(equipmentDetailsRestName, key));
  }

  removeEquipmentFromMyProfile(key): Observable<any> {
    this.clearCache(myProfileRestName);
    return this.http.delete(EquipmentRestService.getUrl(addRemoveMyEquipmentsRestName, key));
  }

  deactivateEquipment(key, body) {
    return this.http.post(EquipmentRestService.getUrl(equipmentDeativateRestName, key), body);
  }

  getOptionsAndUpgrades(key: string): Observable<LifenetSystemCustom[]> {
    return this.http.get<LifenetSystemCustom[]>(EquipmentRestService.getUrl(equipmentOptionsUpgradesRestName, key));
  }

  postRecommentation(key: string, body: any): Observable<any> {
    return this.http.post(EquipmentRestService.getUrl(equipmentOptionsUpgradesRecommendationRestName, key), body);
  }

  postGetQuote(key: string, body: any): Observable<any> {
    return this.http.post(EquipmentRestService.getUrl(equipmentOptionsUpgradesQuoteRestName, key), body);
  }

  postGetTrial(key: string, body: any): Observable<any> {
    return this.http.post(EquipmentRestService.getUrl(equipmentOptionsUpgradesTrialRestName, key), body);
  }

  clearCache(restName: string) {
    if (!environment.newCacheableHttpClient) {
      this.cacheService.clearIndividualRequest(restName);
    }
    this.http.clearCache(restName);
  }

  clearEquipmentCache() {
    this.clearCache(equipmentRestName + '/equipmentInfo');
    this.clearCache(equipmentRestName + '/equipmentsList');
  }

  getDocuments(key: string): Observable<EqDocumentType[]> {
    return this.http.get<EqDocumentType[]>(EquipmentRestService.getUrl(equipmentDocumentsRestName, key));
  }

  getNwePortalDocuments(key: string): Observable<EqDocumentType[]> {
    return this.http.get<EqDocumentType[]>(EquipmentRestService.getUrl(equipmentNwePortalDocumentsRestName, key));
  }

  getPatchDocuments(key: string): Observable<EqDocumentType[]> {
    return this.http.get<EqDocumentType[]>(EquipmentRestService.getUrl(equipmentPatchDocumentsRestName, key));
  }

  requestSyngoVoucher(body: SyngoVoucherInput) {
    return this.http.post(equipmentSyngoVoucherRestName, body);
  }

  getModalities(): Observable<Modality[]> {
    return this.http.get<Modality[]>(modalitiesRestName);
  }

  getModalityGBCmdbDescriptions(): Observable<string[]> {
    return this.http.get<string[]>(modalitiesGBRestName);
  }

  getModalitiesFromWhitelist(): Observable<Modality[]> {
    return this.http.get<Modality[]>(modalitiesWhitelistRestName);
  }

  getEquipmentContacts(equipmentKey: string): Observable<AdditionalContacts[]> {
    this.clearCache(EquipmentRestService.getUrl(equipmentsContactsRestName, equipmentKey));
    return this.http.get<AdditionalContacts[]>(EquipmentRestService.getUrl(equipmentsContactsRestName, equipmentKey));
  }

  getMobileEquipmentAddresses(id): Observable<EqMobileAddress[]> {
    return this.http.get<EqMobileAddress[]>(EquipmentRestService.getUrl(equipmentsMobileAddressesRestName, id));
  }

  getEquipmentPiiDetails(key: string): Observable<EquipmentPiiDetails> {
    return this.http.get(EquipmentRestService.getUrl(equipmentsPiiDetailsRestName, key));
  }

  getAllSelectableEquipments(country: string, searchString: string): Observable<EquipmentViewModel[]> {
    return this.http.get<EquipmentViewModel[]>(allSelectableEquipments, {
      params: new HttpParams().set('country', country).set('searchString', searchString)
    });
  }

  getSecurityWhitepaperUrl(path: string): Observable<DownloadUrl> {
    const params = new HttpParams().set('path', path);
    return this.http.get<DownloadUrl>(securityWhitePaperRestname, {params});
  }

  reportMissingEquipment(body: any): Observable<any> {
    return this.http.post(reportMissingEquipmentRestName, body);
  }

  getEquipmentEnvironmentFilterOptions(equipmentKey: string) {
    return this.http.get<EquipmentEnvironmentFilterOptions>(EquipmentRestService.getUrl(equipmentEnvironmentOptionsRestName, equipmentKey));
  }

  getEquipmentEnvironmentData(equipmentKey: string, input: EquipmentEnvironmentDataInput) {
    return this.http.post<EquipmentEnvironmentData>(EquipmentRestService.getUrl(equipmentEnvironmentDataRestName, equipmentKey), input);
  }

  getEquipmentForMyFilters() {
    this.clearCache(equipmentForMyFiltersRestName);
    return this.http.get<Equipment[]>(equipmentForMyFiltersRestName, {cache: false});
  }

  getEquipmentEnvironmentDataExists(): Observable<boolean> {
    return this.http.get<boolean>(equipmentEnvironmentDataExistsRestName, {cache: false});
  }

  getRemoteWorkForceEquipmentWhiteList(): Observable<Equipment[]> {
    return this.http.get<Equipment[]>(equipmentRwfWhitelisted);
  }

  getRemoteWorkForceEquipmentWhiteListExists(): Observable<boolean> {
    return this.http.get<boolean>(equipmentRwfWhitelistExists);
  }

  checkIfEquipmentIsWhitelistedForSyngoVoucher(equipmentKey: string): Observable<boolean> {
    return this.http.get<boolean>(EquipmentRestService.getUrl(equipmentSyngoVoucherWhitelisted, equipmentKey));
  }

  getEquipmentSrsStatus(equipmentKey: string): Observable<EquipmentSrsStatus> {
    return this.http.get(equipmentSrsStatusCheck.replace(/:id/g, equipmentKey), {cache: false});
  }

  postCreateSimulatorEquipment(body): Observable<any> {
    this.clearGetEquipmentsListCache();
    return this.http.post(equipmentSimulatorCreate, body);
  }
}
