import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'hl-create-sao-billing-address',
  templateUrl: './create-sao-billing-address.component.html'
})
export class CreateSaoBillingAddressComponent implements OnInit {

  @Input() billingForm: FormGroup;
  @Input() shippingForm: FormGroup;
  @Input() isFormSubmitted: boolean;
  showBillingAddressFields: boolean;

  constructor() {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.onActiveBillingAddressChange();
  }

  onActiveBillingAddressChange() {
    this.showBillingAddressFields = _.isEqual(this.billingForm.get('isActive').value, 'true');
    if (!this.showBillingAddressFields) {
      _.forEach(this.billingForm.value, (key, value) => {
        this.billingForm.get(value).clearValidators();
        this.billingForm.get(value).updateValueAndValidity();
      });
    } else {
      _.forEach(this.billingForm.value, (key, value) => {
        this.billingForm.get(value).setValidators([Validators.required]);
        this.billingForm.get(value).updateValueAndValidity();
      });
    }
  }
}
