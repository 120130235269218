export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export interface InnerSortObject {
  sortBy: any;
  sortDir: SortDirection;
}

export interface SortObject extends InnerSortObject {
  thenSortBy?: InnerSortObject[];
}
