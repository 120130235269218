import { HttpCacheService } from '../services/cache/http-cache.service';
import { Observable } from 'rxjs';
import { restEndPoint } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Injectable } from '@angular/core';
import { CustomerGroup } from '../models/customer/customer-group';
import { environment } from '../../../environments/environment';
import { ClearCacheUtilService } from '../utils/clear-cache-util.service';

export const customersImpersonateRestName = restEndPoint + 'customers/impersonate';

@Injectable()
export class CustomerGroupsRestService {
  customerGroupsRestName = restEndPoint + 'customerGroups';
  customerGroupsEditDeleteRestName = restEndPoint + 'customerGroups/:id';

  constructor(private http: CacheableHttpClient,
    private cacheService: HttpCacheService,
    private clearCacheUtilService: ClearCacheUtilService
  ) {
  }

  getCustomerGroupsByCountry(country: string): Observable<CustomerGroup[]> {
    const requestUrl = this.customerGroupsRestName + '?country=' + country;
    return this.http.get<CustomerGroup[]>(requestUrl, {cache: false});
  }

  postGroup(body, id?: string) {
    if (id) {
      return this.postEditGroup(id, body);
    }
    return this.postCreateGroup(body);
  }

  postCreateGroup(body) {
    // clear individual cached for groups
    this.clearCache(this.customerGroupsRestName);
    return this.http.post(this.customerGroupsRestName, body);
  }

  postEditGroup(id: string, body) {
    // clear all cache
    this.clearCacheUtilService.clear();
    const url = this.customerGroupsEditDeleteRestName.replace(/:id/g, id);
    return this.http.put(url, body);
  }

  deleteGroup(id: string) {
    const url = this.customerGroupsEditDeleteRestName.replace(/:id/g, id);
    this.clearCache(this.customerGroupsRestName);
    // After deleting a group, impersonation may change, moving the customers from the deleted
    // group directly to impersonated customers. So we need to clear the impersonation cache
    // and reload the list of impersonated customers/groups
    this.clearCache(customersImpersonateRestName);

    return this.http.delete(url);
  }

  clearCache(restName: string) {
    if (!environment.newCacheableHttpClient) {
      this.cacheService.clearIndividualRequest(restName);
    }
    this.http.clearCache(restName);
  }
}
