import {
  Component,
  Renderer2,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';

@Component({
  selector: 'hl-verify-public-group-modal',
  templateUrl: './verify-public-group-modal.component.html'
})
export class VerifyPublicGroupModalComponent extends BaseModalPopup {

  @Input()
  isEditGroup: boolean;

  @Output()
  onOk = new EventEmitter<any>();

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  show() {
    super.show();
  }

  ok() {
    super.hide();
    this.onOk.emit();
  }
}
