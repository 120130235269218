import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { Component, Input, Renderer2, ViewChild } from '@angular/core';
import { CreateUserNotificationsPageComponent } from '../../overlays/create-user-notifications-page/create-user-notifications-page.component';
import { UserAuditTrailRestService } from '../../../core/rest-services/user-audit-trail-rest.service';
import { UserAuditTrail } from '../../../core/models/auditTrail/user-audit-trail';

@Component({
  selector: 'hl-notifications-subscription-modal',
  templateUrl: './notifications-subscription-modal.component.html'
})
export class NotificationsSubscriptionModalComponent extends BaseModalPopup {
  @ViewChild('notificationsOverlay', { static: false }) notificationsOverlay: CreateUserNotificationsPageComponent;
  @Input() showNotificationsOverlay = false;

  constructor(renderer: Renderer2, private auditTrailService: UserAuditTrailRestService) {
    super(renderer);
  }

  ok() {
    const userAuditTrail: UserAuditTrail = {
      initialMyNotificationsShown: true
    };

    this.notificationsOverlay.save();
    this.auditTrailService.postNotificationSubscription(userAuditTrail).subscribe(response => {
    });
    this.hide();
  }
}
