export interface EquipmentSrsStatus {
  equipmentKey: string;
  status: string;
  lastUpdate: string;
}

export enum SrsStatus {
  Undefined = 'UNDEFINED',
  Unknown = 'UNKNOWN',
  Connected = 'CONNECTED',
  NotConnected = 'NOT_CONNECTED',
  ResponseFailed = 'RESPONSE_FAILED'
}
