import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { restEndPoint } from '../core-constants.service';
import {
  ServiceMetricsAvailable,
  ServiceMetricsData,
  ServiceMetricsFilter,
  ServiceMetricsInitialWithFilter
} from '../models/service-metrics-request';
import { AllowedMetrics } from '../models/service-metrics-save';

@Injectable()
export class ServiceMetricsRestService {

  serviceMetricsAvailableRestName = restEndPoint + 'service-metrics/available';
  serviceMetricsDataRestName = restEndPoint + 'service-metrics';
  private serviceMetricsSaveDataRestName = this.serviceMetricsDataRestName + '/save-allowed-metrics';
  private serviceMetricsOrderRestName = this.serviceMetricsDataRestName + '/save-user-order';
  private serviceMetricsExportRestName = this.serviceMetricsDataRestName + '/export';
  private serviceMetricsOverrideWidgetRestName = this.serviceMetricsDataRestName + '/override-widget-name';

  constructor(private http: CacheableHttpClient) {
  }

  public isServiceMetricsAvailable(): Observable<ServiceMetricsAvailable> {
    return this.http.get(this.serviceMetricsAvailableRestName);
  }

  public getServiceMetricsInitialData(): Observable<ServiceMetricsInitialWithFilter> {
    return this.http.get<ServiceMetricsInitialWithFilter>(this.serviceMetricsDataRestName);
  }

  public getServiceMetricsData(filter: ServiceMetricsFilter): Observable<ServiceMetricsData[]> {
    return this.http.post<ServiceMetricsData[]>(this.serviceMetricsDataRestName, filter);
  }

  public getServiceMetricsDataOfGroup(filter: ServiceMetricsFilter, group: string): Observable<ServiceMetricsData[]> {
    return this.http.post<ServiceMetricsData[]>(this.serviceMetricsDataRestName + '/' + group, filter);
  }

  public saveServiceMetricsData(allowedMetrics: AllowedMetrics): Observable<any> {
    return this.http.post(this.serviceMetricsSaveDataRestName, allowedMetrics);
  }

  public saveServiceMetricsOrder(metricsOrder: string[]): Observable<any> {
    return this.http.post(this.serviceMetricsOrderRestName, metricsOrder);
  }

  public exportServiceMetrics(filter: ServiceMetricsFilter) {
    return this.http.post(this.serviceMetricsExportRestName, filter, {responseType: 'arraybuffer'});
  }

  public overrideWidgetName(metricName: string, widgetName: string): Observable<any> {
    return this.http.post(this.serviceMetricsOverrideWidgetRestName, {
      metricName,
      widgetName
    });
  }
}
