import { environment } from '../../environments/environment.prod';

export const translationPath = '../' + environment.version + 'assets/data/translations/';
export const configPathAssets = environment.version + 'assets/data/config/';
// Rest endpoint
export const restEndPoint = '/rest/v1/';
export const translationRestApi = '/rest/v1/translate/';
export const configRestEndPoint = restEndPoint + 'fe-config/';

export const localeSplitter = '_';
export const configValueSplitter = ',';
export const defaultImagePath = environment.version + 'assets/images/4-3.png';

export const addedRootClasses = {
  termsAndConditions: 'hl-tac-accepted',
  authorized: 'hl-authorized'
};

export const addEquipment = 'addEquipment';
export const removeEquipment = 'removeEquipment';

export const plannedActivityAndTraining = 'all';
export const plannedActivity = 'activity';
export const plannedTraining = 'training';

export const groupPrivate = 'PRIVATE';
export const groupPublic = 'PUBLIC';

export const mgRequestUndefined = '-1'; // as mgRequest field is validated when shown, thus should set some default value when not shown.

export const roles = {
  userRole: 'ER_LN_USER',
  viewEquipmentRole: 'ER_EQUIPMENT_VIEWEQUIPMENT',
  editEquipmentRole: 'ER_EQUIPMENT_UPDATEEQUIPMENT',
  createTicketRole: 'ER_TICKET_CREATETICKET',
  viewTicketRole: 'ER_TICKET_VIEWTICKET',
  viewEPSReports: 'ER_WEBCENTERPORTAL_VIEWEPSREPORTS',
  viewPlannedActivityRole: 'ER_PLANNEDACTIVITIES_VIEWPLANNEDACTIVITIES',
  viewPlannedTrainingRole: 'ER_PLANNEDTRAININGS_VIEWPLANNEDACTIVITIES',
  countryAdminRole: 'ER_LN_COUNTRY_ADMIN',
  masterAdminRole: 'ER_LN_MASTER_ADMIN',
  customerAdminRole: 'ER_LN_CUSTOMER_ADMIN',
  itAdminRole: 'ER_LN_IT_ADMIN',
  viewReschedulePlannedActivityRole: 'ER_PLANNEDACTIVITIES_RESCHEDULEPLANNEDACTIVITIES',
  viewReschedulePlannedTrainingRole: 'ER_PLANNEDACTIVITIES_RESCHEDULEPLANNEDACTIVITIES',
  partnerOrdersRole: 'ER_PARTNERORDER_VIEWPARTNERORDERLIST',
  partnerOrdersUpdateRole: 'ER_PARTNERORDER_UPDATEPARTNERORDER',
  viewContractRole: 'ER_CONTRACT_VIEWCONTRACT',
  impersonateUserRole: 'ER_USER_IMPERSONATEUSER',
  viewServicePartsRole: 'ER_SERVICEPARTS_VIEW',
  viewInvoicesRole: 'ER_FINANCIAL',
  asuUploadManagerRole: 'ER_LN_ASU_UPLOAD_MANAGER',
  auhAdminRole: 'ER_LN_AUH_ADMIN',
  cybersecurityDashboardRole: 'ER_LN_CYBERSECURITY_DASHBOARD',
  securityAdvisoryAuthorRole: 'ER_LN_SECURITY_ADVISORY_AUTHOR',
  securityAdvisoryViewRole: 'ER_SUBSCRIBE_TO_SECURITY_ADVISORIES',
  securityAdvisoryViewXiaRole: 'ER_VIEW_SECURITY_ADVISORIES'
};

export const contractAvailability = {
  contractAvailabilityAll: 'all',
  contractAvailabilityYes: 'yes',
  contractAvailabilityNo: 'no'
};

export enum PsrTypeEnum {
  APPR = 'APPR',
  CANC = 'CANC',
  DLVY = 'DLVY',
  PRIO = 'PRIO',
  STAT = 'STAT'
}

export enum EquipmentModalityType {
  DEFAULT = 'DEFAULT',
  INVITRO = 'INVITRO',
  SYNGO = 'SYNGO',
  MULTIVENDOR = 'MULTIVENDOR',
}

/**
 * Types of invoices
 */
export enum invoiceTypes {
  SERVICE_INVOICE = 'SERVICE',
  CONTRACT_INVOICE = 'CONTRACT',
  PSR_INVOICE = 'PROF SERVICE'
}

export enum contractTypes {
  CENTRICARE = '21'
}

/**
 * Router path defined in invoices-routing.module for each invoice type.
 * This is in direct relation with enum InvoiceTypes, e.g. If the InvoiceType is SERVICE the
 * router path should be serviceInvoice.
 */
export enum invoicePaths {
  SERVICE = 'service',
  CONTRACT = 'contract',
  PSR = 'prof'
}

// Notif parameters
export enum NotifStatus {
  OPEN = '1',
  CLOSED = '2',
  ALL = '3'
}

export enum DangerForPatients {
  TRUE = 'Y',
  FALSE = 'N',
  UNKNOWN = 'U'
}

export enum CentriCareSeverities {
  NOT_OPERATIONAL = '1',
  PARTIALLY_OPERATIONAL = '2',
  PARTIALLY_OPERATIONAL_CRITICAL = '2A',
  PARTIALLY_OPERATIONAL_NON_CRITICAL = '2B',
}

// Invoice states with colors
export const invoiceStatusColor = {
  1: 'status-green', // PAID
  2: 'status-yellow', // OPEN
  3: 'status-red' // OVERDUE
};

export const psrPreApprovalStatusOther = '20';

// order 2 order constants
export const order2order = {
  ordersImagePath: 'https://bps-healthcare.siemens.com/spe/images/flags/',
  ordersPath: 'https://bps-healthcare.siemens.com/spe/',
  ordersCountriesJsonPath: 'countries.json',
  TXTMD: 'TXTMD',
  BU: 'BU',
  NDF_RATE: 'NDF_RATE',
  ECONOMIC_INDICATOR: 'ECONOMIC_INDICATOR',
  DELIVERIES_COUNT_MATERIAL: 'DELIVERIES_COUNT_MATERIAL',
  RETURNS_COUNT_MATERIAL: 'RETURNS_COUNT_MATERIAL'
};

export const red = 'red';
export const green = 'green';
export const yellow = 'yellow';
export const gray = 'gray';
