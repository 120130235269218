import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ImpersonationCommunicationService } from './../../core/component-communication-services/impersonation/impersonation-communication.service';
import { EquipmentStatus } from '../../core/models/equipment/equipment-status';
import { EquipmentUtilService } from './../../core/services/equipment/equipment-util.service';
import { Equipment } from '../../core/models/equipment/equipment';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';

@Component({
  selector: 'hl-equipment-status-map',
  templateUrl: './equipment-status-map.component.html'
})
export class EquipmentStatusMapComponent implements OnInit, OnDestroy {

  @Input() equipment: Equipment;

  private readonly unsubscribe$ = new Subject();
  mapDataLoaded: boolean;
  equipmentStatusData: EquipmentStatus[];

  constructor(
    private equipmentUtilService: EquipmentUtilService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private myFiltersService: MyFiltersAdapterService
  ) { }

  ngOnInit() {
    this.init();
    this.impersonationCommunicationService.onCountryLanguageChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.init());
  }

  init() {
    this.myFiltersService.filterEquipmentKeys$.pipe(
      tap(() => {
        this.mapDataLoaded = false;
        this.equipmentStatusData = [];
      }),
      switchMap(keys => this.equipmentUtilService.getNativeEquipmentStatus().pipe(
        map(statuses => statuses.filter(s => keys.indexOf(s.equipmentKey) !== -1))
      )),
      tap(() => this.mapDataLoaded = true),
      takeUntil(this.unsubscribe$)
    ).subscribe(equipmentStatus => {
      this.equipmentStatusData = equipmentStatus;
      this.mapDataLoaded = true;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
