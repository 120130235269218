import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHandler,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { concatMap, filter, map, tap } from 'rxjs/operators';
import { CacheItem } from './cache-item';
import { environment } from '../../../../environments/environment';
import { HttpCacheNewService } from './http-cache-new.service';
import { CacheableHttpRequest } from '../../models/cacheable-http-request';
import { LogService } from '../log/log.service';


@Injectable()
export class CacheableHttpClient extends HttpClient {
  /* Old variables */
  onLoaded$: any[] = [];
  private requestMade: any[] = [];
  private cachedResponse: any[] = [];
  private onLoadedSource: any[] = [];
  private NEW_CLIENT_TOGGLE = false;

  private static getParams(options: any) {
    return (options && options.params && options.params instanceof HttpParams) ? options.params : undefined;
  }

  constructor(private httpHandler: HttpHandler, private cacheService: HttpCacheNewService, private logService: LogService) {
    super(httpHandler);
    this.NEW_CLIENT_TOGGLE = environment.newCacheableHttpClient;
  }

  /**
   * intermediate public get<> function
   * We do not cache all the config-<country>.json since this is a special observable case and its size does not really affect performance.
   * We let the browser handle the caching.
   * We also do not cache all the request that explicitly define { cache: false }
   */

  public get<T>(url: string, options?: any): Observable<T> {
    if (this.NEW_CLIENT_TOGGLE) {
      if (options && options.cache === false) {
        return this.getHandler(url, options).pipe(map(r => r as T));
      }
      // TODO From Map
      const calledRequest =
        this.cacheService.getFromMap(url, CacheableHttpClient.getParams(options)) ||
        this.createRequestAndCacheRequest(url, options);
      return calledRequest.responseObservable;

    }
    // TODO old version
    if (
      (url.indexOf('config-') >= 0 && url.indexOf('.json') >= 0) ||
      (options && !options.cache)
    ) {
      return this.getHandler(url, options).pipe(map(r => r as T));
    }
    if (this.requestMade && !this.requestMade[url]) {

        this.onLoadedSource[url] = this.getOrCreateLoadedSource<T>(url);
        this.onLoaded$[url] = {
          response: this.onLoadedSource[url].subject.asObservable()
        };

        this.requestMade[url] = true;

        this.invokeHandler(url, options);

        return this.onLoaded$[url].response;
      }

      if (this.cachedResponse && this.cachedResponse[url] && this.cachedResponse[url].cache) {
        return of(this.cachedResponse[url].cache);
      }
      return this.onLoaded$[url].response;
  }

  private createRequestAndCacheRequest(url: string, options: any): CacheItem {
    const params = CacheableHttpClient.getParams(options);
    const cacheRequest = this.cacheService.putToMap(url, params);

    this.invokeHandler(url, options);
    return cacheRequest;
  }

  private invokeHandler(url: string, options?: any) {
    if (this.NEW_CLIENT_TOGGLE) {
      this.getHandler(url, options).subscribe((response) => {
        const params = CacheableHttpClient.getParams(options);
        this.cacheService.putToMap(url, params, response);
      }, (error) => {
        this.logService.error('what seems to be the error?', error);
        const params = CacheableHttpClient.getParams(options);
        this.cacheService.raiseError(url, params, error);
      });
    } else {
      this.getHandler(url, options).subscribe(response => {
        this.cachedResponse[url] = {
          cache: response
        };
        this.onLoadedSource[url].subject.next(response);
        this.onLoadedSource[url].subject.complete();
      }, (error) => {
        this.logService.error('what seems to be the error?', error);
        this.onLoadedSource[url].subject.error(error);
      });
    }
  }


  /**
   * this code is retrieved from HttpClient request method, as we do need to make a get call
   * but with an CacheableHttpRequest object instead of url and options.
   * Just calling super.request with an HttpRequest instead of url and options does resolve to
   * a response (see HttpClient request code)
   */

  public getHandler<T>(url: string, options?: any): Observable<T|HttpResponse<T>> {
    let request: HttpRequest<any>;
    if (options === undefined) {
      options = {};
    }


    if (this.NEW_CLIENT_TOGGLE) {
      request = new HttpRequest('GET', url, options.body || null, {
        headers: options.headers,
        params: options.params,
        reportProgress: options.reportProgress,
        // By default, JSON is assumed to be returned for all calls.
        responseType: options.responseType || 'json',
        withCredentials: options.withCredentials
      });
    } else {
      request = new CacheableHttpRequest('GET', url, options.body || null, {
        headers: options.headers,
        params: options.params,
        reportProgress: options.reportProgress,
        // By default, JSON is assumed to be returned for all calls.
        responseType: options.responseType || 'json',
        withCredentials: options.withCredentials,
        cache: options.cache
      });
    }

    const res$ = of(request).pipe(
      concatMap(req => this.httpHandler.handle(req)),
      tap(event => {
        if (!(event instanceof HttpResponse)) {
          this.cacheService.cacheSapCallResponseOrErrorResponse(request, null);
        }
      }),
      filter(event => event instanceof HttpResponse),
      map(resp => resp as HttpResponse<T>)
    );

    // Decide which stream to return.
    switch (options.observe || 'body') {
      case 'body':
        // The requested stream is the body. Map the response stream to the response
        // body. This could be done more simply, but a misbehaving interceptor might
        // transform the response body into a different format and ignore the requested
        // responseType. Guard against this by validating that the response is of the
        // requested type.
        switch (request.responseType) {
          case 'arraybuffer':
            return res$.pipe(map(res => {
              // Validate that the body is an ArrayBuffer.
              if (res.body !== null && !(res.body instanceof ArrayBuffer)) {
                throw new Error('Response is not an ArrayBuffer.');
              }
              return res.body;
            }));
          case 'blob':
            return res$.pipe(map(res => {
              // Validate that the body is a Blob.
              if (res.body !== null && !(res.body instanceof Blob)) {
                throw new Error('Response is not a Blob.');
              }
              return res.body;
            }));
          case 'text':
            return res$.pipe(map(res => {
              // Validate that the body is a string.
              if (res.body !== null && typeof res.body !== 'string') {
                throw new Error('Response is not a string.');
              }
              return res.body;
            }));
          case 'json':
          default:
            // No validation needed for JSON responses, as they can be of any type.
            return res$.pipe(map(res => {
              return res.body;
            }));
        }
      case 'response':
        // The response stream was requested directly, so return it.
        return res$;
      default:
        // Guard against new future observe types being added.
        throw new Error('Unreachable: unhandled observe type ' + options.observe + '}');
    }
  }

  /* old method */
  private getOrCreateLoadedSource<T>(url: string): any {
    const onLoadedSource = this.onLoadedSource[url];
    const subject: Subject<T> = new Subject<T>();
    if (onLoadedSource && !onLoadedSource.subject.isStopped) {
      subject.observers.push(...onLoadedSource.subject.observers);
    }
    return { subject };
  }

  public clearCache(url?: string, options?: any): void {
    if (this.NEW_CLIENT_TOGGLE) {
      if (!!url && !!options && options.params instanceof HttpResponse) {
        this.cacheService.clearIndividualRequestWithParams(url, CacheableHttpClient.getParams(options));
      }
      if (!!url) {
        this.cacheService.clearIndividualRequest(url);
      } else {
        this.cacheService.clearAll();
      }
    } else {
      if (url) {
        if (this.requestMade[url]) {
          this.removeFromCache(url);

        }
      } else {
        for (const req in this.requestMade) {
          if (this.requestMade.hasOwnProperty(req)) {
            this.removeFromCache(req);
          }
        }
      }
    }
  }

  private removeFromCache(url: string) {
    this.requestMade[url] = false;
    if (this.cachedResponse[url]) {
      this.cachedResponse[url].cache = undefined;
    }
  }
}
