import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'equipmentStatus'
})

export class EquipmentStatusPipe implements PipeTransform {

  constructor(private equipmentUtilService: EquipmentUtilService) {
  }

  transform(equipmentKey: string): Observable<string> {
    return this.equipmentUtilService.getEquipmentStatus(equipmentKey);
  }
}
