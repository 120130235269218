import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { restEndPoint } from '../core-constants.service';

@Injectable()
export class EnvironmentConfigRestService {
    // environment
    environmentConfigRestName = restEndPoint + 'environmentConfig';

    constructor(private http: CacheableHttpClient) { }

    getEnvironmentConfig(): Observable<any> {
        return this.http.get(this.environmentConfigRestName);
    }

}
