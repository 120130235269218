import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpCancelUtilService } from '../utils/http-cancel-util.service';

@Injectable()
export class CancelRequestInterceptor implements HttpInterceptor {

    constructor(private service: HttpCancelUtilService) { }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        return next.handle(req).pipe(takeUntil(this.service.onCancelPendingRequests()));
    }
}
