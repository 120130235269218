import { Pipe, PipeTransform } from '@angular/core';
import { ColorStatusBinding } from '../../../core/models/color-status-map';
import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';
import { Activities } from '../../../core/models/activities/activities';
import { ActivitiesStatusDescriptionTranslationService } from '../../../core/services/activities/activities-status-description-translation-service';

@Pipe({
  name: 'getActivityStatusColor'
})

export class GetActivityStatusColorPipe implements PipeTransform {

  activityStatusColorD35 = {
    0: 'status-yellow',
    1: 'status-yellow',
    2: 'status-green',
    7: 'status-red',
    8: 'status-green'
  };

  activityStatusColor = {
    0: 'status-yellow',
    1: 'status-green',
    2: 'status-yellow',
    3: 'status-red',
    4: 'status-yellow'
  };

  constructor(private descriptionTranslationService: ActivitiesStatusDescriptionTranslationService) {
  }

  /**
   *
   * @param item
   *
   * @description
   * Generate the label and status color map based on pmStatus or activity status.
   * When activity is returned from the webService (in case of closed activities)
   *      - activityStatus is filled with the value from WS
   *      - pmStatus is set to -1 (default).
   * When activity is retrieved from the database
   *      - activityStatus is empty (i.e. '')
   *      - pmStatus is set from the DB.
   */

  transform(item: ActivitiesViewModel | Activities): any {
    const statusMap: { [k: string]: any } = {};
    // if item is defined
    if (item) {
      statusMap.label = this.descriptionTranslationService.getActivityStatusDescriptionTranslationKey(item);

      if (!item.pmStatus) {
        statusMap.colorClass = 'status-default';
      } else {
        statusMap.colorClass = item.sapSystem === 'D35' ? this.activityStatusColorD35[item.pmStatus]
          : this.activityStatusColor[item.pmStatus];
      }
    }
    return statusMap as ColorStatusBinding;
  }

}
