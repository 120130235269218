import { CustomerContacts } from '../models/customer/customer-contacts';
import { Observable } from 'rxjs';
import { CustomerDeletionStatus } from '../models/customer/customer-deletion-status';
import { CustomerAssignedUser } from '../models/customer/customer-assigned-user';
import { CustomerJob } from '../models/customer/customer-job';
import { CreateCustomerRequest } from '../models/customer/create-customer-request';
import { restEndPoint } from '../core-constants.service';
import { Customer } from '../models/customer/customer';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Injectable } from '@angular/core';
import { CustomerData } from '../models/customer/customer-name-options';
import { HttpCacheService } from '../services/cache/http-cache.service';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class CustomerRestService {

  // customer
  customerRestName = restEndPoint + 'customers';
  customerJobsRestName = restEndPoint + 'customers/jobs';
  customersAssignedUsersRestName = restEndPoint + 'customers/:id/assignedUsers';
  customersDeleteRestName = restEndPoint + 'customers/:id';
  customerContactsRestName = restEndPoint + 'customers/contacts';
  customerEditRestName = restEndPoint + 'customers/edit/:id';
  customerNamesInfoRestName = restEndPoint + 'customers/names/:id';
  customerDataLoadRunningRestName = restEndPoint + 'customers/dataLoadRunning';

  private static getUrl(url, customerId: string) {
    return url.replace(/:id/, customerId);
  }

  constructor(private cacheService: HttpCacheService, private http: CacheableHttpClient) {
  }

  getCustomersByCountry(country: string): Observable<Customer[]> {
    const requestUrl = this.customerRestName + '?country=' + country;
    return this.http.get<Customer[]>(requestUrl, {cache: false});
  }

  postCreateCustomers(createCustomerObject: CreateCustomerRequest): Observable<any> {
    return this.http.post(this.customerRestName, createCustomerObject);
  }

  getCustomerJobs(): Observable<CustomerJob[]> {
    return this.http.get<CustomerJob[]>(this.customerJobsRestName, {cache: false});
  }

  deleteCustomerJobs(): Observable<null> {
    return this.http.delete<null>(this.customerJobsRestName);
  }

  getAssignedUsers(customerId: string): Observable<CustomerAssignedUser[]> {
    return this.http.get<CustomerAssignedUser[]>(CustomerRestService.getUrl(this.customersAssignedUsersRestName, customerId));
  }

  deleteCustomer(customerId: string): Observable<CustomerDeletionStatus> {
    return this.http.delete<CustomerDeletionStatus>(CustomerRestService.getUrl(this.customersDeleteRestName, customerId));
  }

  getCustomerContacts(): Observable<CustomerContacts[]> {
    return this.http.get<CustomerContacts[]>(this.customerContactsRestName);
  }

  getCustomerNamesData(customerId: string): Observable<CustomerData> {
    return this.http.get<CustomerData>(CustomerRestService.getUrl(this.customerNamesInfoRestName, customerId));
  }

  getCustomerData(customerId: string): Observable<CustomerData> {
    return this.http.get<CustomerData>(CustomerRestService.getUrl(this.customerEditRestName, customerId));
  }

  postCustomerChange(customerId: string, body): Observable<any> {
    return this.http.post(CustomerRestService.getUrl(this.customerEditRestName, customerId), body, {responseType: 'text'});
  }

  clearCustomerDataCache(customerId: string) {
    this.clearCache(CustomerRestService.getUrl(this.customerEditRestName, customerId));
  }

  clearCache(restName: string) {
    if (!environment.newCacheableHttpClient) {
      this.cacheService.clearIndividualRequest(restName);
    }
    this.http.clearCache(restName);
  }

  isCustomerDataLoadRunning(customerType: string): Observable<boolean> {
    const httpParams = new HttpParams().set('customerType', customerType);
    return this.http.get<boolean>(this.customerDataLoadRunningRestName, {
      params: httpParams,
      cache: false
    });
  }
}
