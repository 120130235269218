import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Equipment } from '../../models/equipment/equipment';

/**
 * Service to enable listening in create ticket component, for changes of ticket
 * types in a child form group. Used to toggle attachments rendering.
 */
@Injectable()
export class CreateTicketEventService {

  // Observable createTicket changed ticket type sources
  private changedTicketTypeSource = new Subject<any>();

  // Observable createTicket streams
  changeTicketTypeSource$ = this.changedTicketTypeSource.asObservable();

  createTicketEmitter = new EventEmitter<any>();

  constructor() {
  }

  // Service createTicket -> (ticket create)
  emitTicketTypeChanged(ticketType: string) {
    this.changedTicketTypeSource.next(ticketType);
  }

  emitChangeEquipmentStatus(selectedEquipment: Equipment, severity: string) {
    this.createTicketEmitter.emit({ selectedEquipment, severity });
  }

}
