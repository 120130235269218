import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { Equipment } from '../../../core/models/equipment/equipment';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { takeUntil } from 'rxjs/operators';
import { green, red, yellow } from '../../../core/core-constants.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { translationCountryPrefix } from '../../../core/utils/impersonation-utils.service';
import { CustomerRestService } from '../../../core/rest-services/customer-rest.service';
import { DatePipeWrapperPipe } from '../../pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { SyngoVoucherInput } from '../../../core/models/equipment/documents/syngo-voucher-input';

@Component({
  selector: 'hl-syngo-voucher-request-modal',
  templateUrl: './syngo-voucher-request-modal.component.html'
})
export class SyngoVoucherRequestModalComponent extends BaseModalPopup
  implements OnInit {

  @Input()
  equipment: Equipment;
  isFormSubmitted = false;
  syngoVoucherForm: FormGroup;
  equipmentStatusColorMap = [];
  showValidationMessage = false;
  defaultFormValues;

  constructor(
    private fb: FormBuilder,
    private toasterService: ToasterService,
    private configService: CountryConfigRestService,
    private translateService: TranslateService,
    private customerRestService: CustomerRestService,
    private equipmentRestService: EquipmentRestService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    renderer: Renderer2
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.init();
  }

  hide() {
    this.reset();
    super.hide();
    this.syngoVoucherForm.reset(this.defaultFormValues);
  }

  reset() {
    this.isFormSubmitted = false;
    this.showValidationMessage = false;

  }

  init() {
    this.reset();
    const countryTranslation$ = this.translateService.get(
      translationCountryPrefix + this.equipment.countryCode
    );
    const config$ = this.configService.getConfig();

    const customerData$ = this.customerRestService.getCustomerNamesData(this.equipment.countryCode + '_' + this.equipment.customerNumber);

    combineLatest(config$, countryTranslation$, customerData$).pipe(takeUntil(this.unsubscribe$))
      .subscribe(([config, countryLabel, customerData]) => {
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_GREEN] = green;
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_RED] = red;
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_YELLOW] = yellow;
      this.createForm(countryLabel, customerData.name1.trim().substring(0, 30));
      this.setDefaultFormValues(countryLabel, customerData.name1.trim().substring(0, 30));
    });
  }

  ok() {
    this.isFormSubmitted = true;

    if (this.syngoVoucherForm.valid) {
      this.showSpinner = true;
      this.showValidationMessage = false;
      // call the backend rest end point
      this.equipmentRestService
        .requestSyngoVoucher(this.getRequestBody()).pipe(
        finalize(() => {
          this.hide();
        }))
        .subscribe(() => {
          this.toasterService.showSuccessToaster(
            true,
            'SYNGO_VOUCHER_REQUEST_SUCCESS'
          );
        },
          () => {
            const toast = {
              type: 'error',
              isBodyTranslationKey: true,
              body: 'FRONTEND_GENERIC_ERROR_MESSAGE'
            };
            this.toasterService.emitToast(toast);
          });
    } else {
      this.showValidationMessage = true;
    }
  }

  getRequestBody() {
    const body = {} as SyngoVoucherInput;
    body.functionalLocation = this.equipment.siemensId;
    body.productName = this.equipment.productName;
    body.eqSerialNumber = this.equipment.serialNumber;
    body.eqMaterialNumber = this.equipment.materialNumber;
    body.eqSoftwareVersion = this.equipment.softwareVersion;
    body.customerName1 = this.institutionControl.value;
    body.customerName2 = '';
    body.street = this.streetControl.value;
    body.houseNumber = this.houseNumberControl.value;
    body.zipCode = this.zipControl.value;
    body.city = this.cityControl.value;
    body.country = this.equipment.countryCode;
    body.creationDate = this.datePipeWrapperPipe.transform(new Date(), 'YYYY-MM-DD HH:mm:ss');
    return body;
  }

  private createForm(countryLabel: string, institution: string) {
    this.syngoVoucherForm = this.fb.group({
        institution: [institution, [Validators.required]],
        street: [this.equipment.street, [Validators.required]],
        houseNumber: ['', []],
        zip: [this.equipment.zip, [Validators.required]],
        city: [this.equipment.city, [Validators.required]],
        country: [countryLabel, [Validators.required]]
      }
    );
  }

  private setDefaultFormValues(countryLabel: string, institution: string) {
    this.defaultFormValues = {
      institution: institution,
      street: this.equipment.street,
      houseNumber: '',
      zip: this.equipment.zip,
      city: this.equipment.city,
      country: countryLabel
    };
  }
  

  validationCheck() {
    return this.syngoVoucherForm.valid;
  }

  get institutionControl() {
    return this.syngoVoucherForm.controls['institution'];
  }

  get streetControl() {
    return this.syngoVoucherForm.controls['street'];
  }

  get houseNumberControl() {
    return this.syngoVoucherForm.controls['houseNumber'];
  }

  get zipControl() {
    return this.syngoVoucherForm.controls['zip'];
  }

  get cityControl() {
    return this.syngoVoucherForm.controls['city'];
  }

  get countryControl() {
    return this.syngoVoucherForm.controls['country'];
  }

}
