import { Injectable } from '@angular/core';
import { ImpersonationCommunicationService } from '../../component-communication-services/impersonation/impersonation-communication.service';

@Injectable()
export class WidgetHeightCacheService {
  contentHeight = null;

  constructor(private impersonationCommunicationService: ImpersonationCommunicationService) {
    this.registerEventListener();
  }

  /**
   *
   * @description
   * Registers for Country change and Language change events emitted by ImpersonationCommunicationService.
   */
  registerEventListener() {
    this.impersonationCommunicationService.onCountryLanguageChange$.subscribe(() => {
      this.contentHeight = null;
    });

    this.impersonationCommunicationService.onImpersonationChange$.subscribe(() => {
      this.contentHeight = null;
    });
  }

  setHeight(contentHeight: number) {
    this.contentHeight = contentHeight;
  }

  getHeight(): number {
    return this.contentHeight;
  }

}
