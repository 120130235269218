import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class StringUtilService {

  constructor() {
  }

  /**
   * Requires string and allowed string length and returns the
   * truncated filename (truncates the string without the extension) and then adds its extension at the end.
   * @param {string} filename: only strings like 'filename.jpg'
   * @param {number} allowedLength: total allowed length of filename + extension
   * @returns {string}
   */
  truncateFilename(filename: string, allowedLength: number) {
    const lastIndexOfDot = filename.lastIndexOf('.');
    if (lastIndexOfDot < 0 || filename.length <= allowedLength) {
      return filename;
    }
    const extension = filename.substring(lastIndexOfDot);
    const allowedFilenameLength = allowedLength - extension.length;
    const truncatedFilename = filename.substring(0, allowedFilenameLength);
    return truncatedFilename + extension;
  }

  /**
   * checks if the single string is contained in the list
   * @param {string} type
   * @param listOfTypes - a string with comma separated entries
   * @returns {boolean}
   */
  isContainedIn(type: string, listOfTypes: string): boolean {
    return _.includes(listOfTypes.split(','), type);
  }


  /**
   *
   * @description
   * Convert BE response text with /n to lines and
   * drop if first character is empty string.
   */
  parseAndSplitInputStringToLines(input: string): string[] {

    let text = input.split('\n');
    if (text.length > 0 && _.isEqual(text[0], '')) {
      text = _.drop(text);
    }
    return text;
  }

}
