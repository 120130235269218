import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortSkeleton } from '../../shared/sorting/sort-skeleton';
import { SortDirection } from '../../shared/sorting/sort-object';

@Injectable()
export class PartnerOrdersConstantsService {

  constructor(private translateService: TranslateService) {
  }

  getSortSkeleton(): SortSkeleton {
    return {
      sortObject: {
        sortBy: 'dueDate',
        sortDir: SortDirection.ASC
      },
      items: [
        {
          title: 'PARTNER_ORDER_DUE_DATE',
          value: 'dueDate'
        },
        {
          title: 'GENERIC_LABEL_CUSTOMER_NAME',
          value: 'customerName'
        },
        {
          title: 'GENERIC_LABEL_PRODUCT_NAME',
          value: 'productName'
        }
      ]
    };
  }

  getOrderStatusSkeleton() {
    return [
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('PARTNER_ORDER_STATUS_OPEN'),
        value: '1'
      },
      {
        // tslint:disable-next-line:ban
        title: this.translateService.instant('PARTNER_ORDER_STATUS_CLOSED'),
        value: '2'
      }
    ];
  }
}

