import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { ActivitiesRestService } from '../../../core/rest-services/activities-rest.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { ActivitiesViewModel } from '../../../core/view-models/activities-view-model';

class RescheduleFormModel {
  constructor(
    public rescheduleEmailText: string
  ) {
  }
}

@Component({
  selector: 'hl-reschedule-activity-modal',
  templateUrl: './reschedule-activity-modal.component.html'
})
export class RescheduleActivityModalComponent extends BaseFormModalPopup implements OnInit {

  @Input() activitiesItem: ActivitiesViewModel;

  // store form data
  formData = new RescheduleFormModel('');
  dateTimePattern = '';

  time = new Date();

  // add validation message
  translationErrorMessage = 'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';

  constructor(private configService: CountryConfigRestService,
    private activitiesRestService: ActivitiesRestService,
    private toasterService: ToasterService,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.dateTimePattern = configResponse.GENERIC_DATE_TIME_PATTERN;
    });
  }

  postFormData() {
    this.activitiesRestService.reschedule(this.activitiesItem.ticketKey, this.formData).pipe(
      finalize(() => {
        this.isFormSubmitted = false;

        // reset form
        this.formData = new RescheduleFormModel('');

        // close modal
        this.hide();
      }))
      .subscribe(() => {
        this.showToaster(true, 'RESCHEDULE_PLANNED_ACTIVITY_EMAIL_SUCCESS');
      });
  }

  showToaster(isBodyTranslationKey, body) {
    const toast = {
      type: 'success',
      isBodyTranslationKey: isBodyTranslationKey,
      body: body
    };

    // show a success toaster message
    this.toasterService.emitToast(toast);
  }
}
