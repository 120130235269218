import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { TeamplayRestService } from '../../../core/rest-services/teamplay-rest.service';
import { Series } from '../../../diagram/diagram';
import { TranslateService } from '@ngx-translate/core';
import { TeamplayDetail } from '../../../core/models/teamplay-detail';

@Component({
  selector: 'hl-equipment-teamplay',
  templateUrl: './equipment-teamplay.component.html'
})
export class EquipmentTeamplayComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  isLoaded = false;
  noData = false;

  view: any[] = [700, 400];
  series: Series[];
  chartColors = TeamplayRestService.barChartColors;
  teamplayLink: string;
  exams: string;
  durations: string;
  translationKeys: string[] = ['DAYS', 'TITLE_TEAMPLAY_EXAMS_NUMBER_WIDGET', 'TITLE_TEAMPLAY_EXAMS_DURATION_WIDGET'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private teamplayRestService: TeamplayRestService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params: any) => {
      this.isLoaded = false;
      this.noData = false;
      this.teamplayRestService.loadEquipmentByKey(params['id']).then(equipment => {
        this.teamplayRestService.getTeamplayDetail(equipment.serialNumber)
          .subscribe(response => {
            if (response) {
              this.loadTranslationsAndData(response);
            } else {
              this.noData = true;
              this.isLoaded = true;
            }
          }, error => {
            this.noData = true;
            this.isLoaded = true;
          });
      });
    });
  }

  loadTranslationsAndData(teamplayDetail: TeamplayDetail) {
    this.translateService.get(this.translationKeys).subscribe((responses => {
      this.exams = responses.TITLE_TEAMPLAY_EXAMS_NUMBER_WIDGET;
      this.durations = responses.TITLE_TEAMPLAY_EXAMS_DURATION_WIDGET;
      const days = responses.DAYS.split(',');
      this.series = TeamplayRestService.getSeries(teamplayDetail, this.exams, this.durations, days);
      this.teamplayLink = teamplayDetail.teamplayLink;
      this.isLoaded = true;
    }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
