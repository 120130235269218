import { Component, OnInit, Renderer2 } from '@angular/core';
import { PartnerOrdersRestService } from '../../rest-services/partner-orders-rest.service';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { CloseWithdrawPartnerOrderEventService } from '../../component-communication-services/close-withdraw-partner-order-event.service';
import { FormBuilder, Validators } from '@angular/forms';
import { BasePartnerOrderForm } from '../../base-class/base-partner-order-form';
import { restEndPoint } from '../../../core/core-constants.service';
import { DatePipeWrapperPipe } from '../../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';

const partnerOrderActionWithdraw = 'STORNO';
const partnerOrderWithdrawRestName = restEndPoint + 'partnerOrders/:ticketNumber/withdraw';

@Component({
  selector: 'hl-partner-order-withdraw-modal',
  templateUrl: './partner-order-withdraw-modal.component.html'
})
export class PartnerOrderWithdrawModalComponent extends BasePartnerOrderForm implements OnInit {

  constructor(partnerOrdersRestService: PartnerOrdersRestService,
    toasterService: ToasterService,
    closeWithdrawPartnerOrderEventService: CloseWithdrawPartnerOrderEventService,
    renderer: Renderer2,
    datePipeWrapperPipe: DatePipeWrapperPipe,
    protected fb: FormBuilder) {
    super(renderer, partnerOrdersRestService, toasterService, closeWithdrawPartnerOrderEventService, datePipeWrapperPipe);
  }

  ngOnInit() {
    this.init();
  }

  getFormControl() {
    return this.fb.group({
      action: [partnerOrderActionWithdraw],
      attachments: [[]],
      commentText: ['', [Validators.required]],
      closureDate: [null]
    });

  }

  getActionUrl(): string {
    return partnerOrderWithdrawRestName.replace(/:ticketNumber/g, this.partnerOrder.ticketNumber);
  }

  getToasterTranslationKey(): string {
    return 'PARTNER_ORDER_WITHDRAW_SUCCESS';
  }
}
