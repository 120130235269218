import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { restEndPoint } from '../core-constants.service';
import { SecurityAdvisoryNotification } from '../models/security-advisory-notification';
import { HttpCacheService } from '../services/cache/http-cache.service';

@Injectable()
export class SecurityAdvisoriesNotificationsRestService {
  allRestName = restEndPoint + 'security-advisory-notifications/all';
  deleteRestName = restEndPoint + 'security-advisory-notifications/delete/:id';
  createRestName = restEndPoint + 'security-advisory-notifications/create';
  editRestName = restEndPoint + 'security-advisory-notifications/edit';

  constructor(private http: CacheableHttpClient,
    private cacheService: HttpCacheService) {
  }

  getSecurityAdvisoriesNotifications(): Observable<SecurityAdvisoryNotification[]> {
      return this.http.get<SecurityAdvisoryNotification[]>(this.allRestName);
  }

  deleteNotification(notificationId: number) {
    const url = this.deleteRestName.replace(/:id/g, notificationId.toString());
    return this.http.delete(url);
  }

  createNotification(body): Observable<any> {
    return this.http.post(this.createRestName, body);
  }

  editNotification(body): Observable<any> {
    return this.http.post(this.editRestName, body);
  }

  clearAdvisoriesNotificationCache() {
    this.cacheService.clearIndividualRequest(this.allRestName);
    this.http.clearCache(this.allRestName);
  }
}
