import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentsSourceOption } from '../models/equipment/documents/documents-source-option';
import { restEndPoint } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { EquipmentDocuments } from '../models/equipment/equipment-documents';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class DocumentSourceRestService {
  sourceListRestName = restEndPoint + 'source/list';

  constructor(private http: CacheableHttpClient) {
  }

  getDocumentsSourceOptions(equipmentKey: string): Observable<DocumentsSourceOption[]> {
    return this.http.get<DocumentsSourceOption[]>(this.sourceListRestName, {
      params: new HttpParams().set('equipmentKey', equipmentKey)
    });
  }

  getDocumentsFromSpecificSource(sourceUrl: string, key: string): Observable<EquipmentDocuments> {
    return this.http.get<EquipmentDocuments>(sourceUrl, {
      params: new HttpParams().set('equipmentKey', key)
    });
  }

  getResponseFromUrl(url: string): Observable<any> {
    return this.http.get<any>(url, {cache: false});
  }
}
