import { Pipe, PipeTransform } from '@angular/core';
import { TableHeaderCell, TableRow, TableRowType } from '../../../spr/configuration/table/configuration-table.component';

@Pipe({
  name: 'sprTableHighlightCellPipe'
})
export class SprTableHighlightCellPipe implements PipeTransform {
  transform(
    isValid: boolean,
    rowType: TableRowType,
    headerCell: TableHeaderCell,
    addingRowActive: boolean,
    enableHighlight: boolean = true,
    // tslint:disable-next-line:bool-param-default
    isMandatory?: boolean
  ): boolean {
    return rowType !== TableRowType.DEFAULT && enableHighlight && (
      rowType === TableRowType.NORMAL ||
      (rowType === TableRowType.ADDING && addingRowActive)
    ) && !!(isMandatory !== undefined ? isMandatory : (headerCell && headerCell.config && headerCell.config.isMandatory)) && !isValid;
  }
}
