import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';
import { ListIterator, Many } from 'lodash';
import { SortObject } from '../../sorting/sort-object';

@Pipe({
  name: 'orderBy',
  pure: false
})
export class OrderByPipe implements PipeTransform {

  private static getOrders(sortObject: SortObject): Many<'asc' | 'desc'> {
    const orders = [sortObject.sortDir];
    if (sortObject.thenSortBy && sortObject.thenSortBy.length > 0) {
      orders.push(...sortObject.thenSortBy.map(inner => inner.sortDir));
    }
    return orders;
  }

  private static getIteratees(sortObject: SortObject): Many<ListIterator<any, any>> {

    function getSortObject(sortBy: string, item: any) {
      let obj;
      if (!sortBy.includes('.')) {
        obj = item[sortBy];
      } else if ((/^[a-z0-9]+\.[a-z0-9]+$/i).test(sortBy)) {
        const splitSortBy = sortBy.split('.');
        obj = item[splitSortBy[0]];
        obj = obj[splitSortBy[1]];
      }
      return obj && obj.toLowerCase ? obj.toLowerCase() : obj;
    }

    const iteratees = [(item: any) => getSortObject(sortObject.sortBy, item)];
    if (sortObject.thenSortBy && sortObject.thenSortBy.length > 0) {
      iteratees.push(...sortObject.thenSortBy.map(thenSort => {
        return (item: any) => getSortObject(thenSort.sortBy, item);
      }));
    }
    return iteratees;
  }

  transform(dataset: Array<any>, sortObject: SortObject): any {
    if ((/^[a-z0-9]+\.\.[a-z0-9]+$/i).test(sortObject.sortBy)) {
      dataset.forEach(item => {
        const splitSortBy = sortObject.sortBy.split('..');
        const transformedSortObject = (
          sortObject.thenSortBy && sortObject.thenSortBy.length ?
            {
              sortBy: splitSortBy[1],
              sortDir: sortObject.sortDir,
              thenSortBy: sortObject.thenSortBy
                .filter(sortItem => (sortItem.sortBy as string).startsWith(splitSortBy[0] + '..'))
                .forEach(sortItem => sortItem.sortBy = (sortItem.sortBy as string).substring(splitSortBy[0].length + 1))
            } :
            {sortBy: splitSortBy[1], sortDir: sortObject.sortDir}
        ) as SortObject;

        item[splitSortBy[0]] = this.transform(item[splitSortBy[0]], transformedSortObject);
      });
      return dataset;
    }
    return _.orderBy(dataset, OrderByPipe.getIteratees(sortObject), OrderByPipe.getOrders(sortObject));
  }
}
