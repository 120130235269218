import { combineLatest, Subject } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { find, forEach, includes, isEqual } from 'lodash';
import { roles } from '../../../core/core-constants.service';
import { Equipment } from '../../../core/models/equipment/equipment';
import { UserUtilService } from '../../../core/services/user/user-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { SecurityApplianceOrderUtilService } from '../../../core/services/security-appliance-order/security-appliance-order-util.service';
import { CreatePsrModalComponent } from 'app/shared/modal-popup/create-psr-modal/create-psr-modal.component';
import { CreateSaModalComponent } from 'app/shared/modal-popup/create-sa-modal/create-sa-modal.component';
import { OverlayComponent } from 'app/shared/components/overlay/overlay.component';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { EquipmentInformation } from '../../../core/models/equipment/equipment-information';
import { takeUntil } from 'rxjs/operators';
import { ItemDetailViewModel } from '../../../core/view-models/item-detail-view-model';
import { TranslateService } from '@ngx-translate/core';
import { DatePipeWrapperPipe } from '../../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { BlacklistRestService } from '../../../core/rest-services/blacklist-rest.service';
import 'core-js/es7/object';
import { CreateRwfModalComponent } from '../../../shared/modal-popup/create-rwf-modal/create-rwf-modal.component';
import { SrsStatus } from '../../../core/models/equipment/equipment-srs-status';
import { EquipmentSRSRestService } from '../../equipment-srs-cache/equipment-srs-cache.service';

@Component({
  selector: 'hl-equipment-system-information',
  templateUrl: './equipment-system-information.component.html'
})
export class EquipmentSystemInformationComponent implements OnInit, OnDestroy {
  @ViewChild('createPsrModal', {static: false})
  createPsrModal: CreatePsrModalComponent;
  @ViewChild('createSaModal', {static: false})
  createSaModal: CreateSaModalComponent;
  @ViewChild('createRwfModal', {static: false})
  createRwfModal: CreateRwfModalComponent;
  @ViewChild('detailOverlay', {static: false})
  detailOverlay: OverlayComponent;
  statusColorMap: any = {};
  equipmentItem: EquipmentInformation | Equipment;
  showEquipmentComponents: boolean;
  allowEdit: boolean;
  datePattern: string;
  dateTimePattern: string;

  showEditButton: boolean;
  showEquipmentQRcode: boolean;
  showDeactivateButton: boolean;
  showCreateRwfButton: boolean;
  config: any;
  addressFields: any[];
  showSystemInformationTab: boolean;
  showModality: boolean;
  showEOS: boolean;
  showMaterialNumber: boolean;
  allowedSecurityApplianceOrders: string[] = [];
  selectedItem: string;
  epsReportLink: string;
  showSapAssetNumber: boolean;
  showGetSrsStatusBtn: boolean;
  srsConnectivityCheckFeatureToggle: boolean;
  translations: any;
  servicePartsCatalogueLink: string;
  webshopCatalogueLink: string;
  equipmentDetailFields: ItemDetailViewModel[];
  equipmentDetailFieldsPDF: ItemDetailViewModel[];
  private readonly unsubscribeConfig$: Subject<void> = new Subject<void>();
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  equipmentInformationLabelTranslationMap = {
    myEquipmentName: 'GENERIC_LABEL_MY_EQUIPMENT_NAME',
    sapAssetNumber: 'LABEL_ASSET_NUMBER',
    department: 'GENERIC_LABEL_DEPARTMENT',
    street: 'GENERIC_LABEL_STREET',
    city: 'GENERIC_LABEL_CITY',
    zip: 'GENERIC_LABEL_POSTAL_CODE',
    contractType: 'EQUIPMENT_CONTRACT_TYPE',
    softwareVersion: 'SOFTWARE_VERSION',
    serialNumber: 'GENERIC_LABEL_SERIAL_NUMBER',
    materialNumberConsolidated: 'LABEL_MATERIAL_NUMBER',
    installedOnDate: 'GENERIC_LABEL_INSTALLATION_DATE',
    warranty: 'EQUIPMENT_WARRANTY',
    modality: 'LABEL_MODALITY',
    eosDate: 'GENERIC_LABEL_END_OF_SERVICE',
    srsStatus: 'LABEL_SRS_STATUS',
    srsStatusConnected: 'SRS_STATUS_CONNECTED',
    srsStatusDisconnected: 'SRS_STATUS_DISCONNECTED',
    srsStatusUnknown: 'SRS_STATUS_UNKNOWN',
    flStartupDate: 'LABEL_STARTUP_DATE'
  };

  EQUIPMENT_NO_CONTRACT_AVAILABLE_KEY = 'EQUIPMENT_NO_CONTRACT_AVAILABLE';
  GENERIC_LABEL_NOT_AVAILABLE_KEY = 'GENERIC_LABEL_NOT_AVAILABLE';

  constructor(
    private activatedRoute: ActivatedRoute,
    private equipmentUtilService: EquipmentUtilService,
    private equipmentRestService: EquipmentRestService,
    private configService: CountryConfigRestService,
    private userUtilService: UserUtilService,
    public lifeNetUtilService: LifeNetUtilService,
    private saOrderUtilService: SecurityApplianceOrderUtilService,
    private equipmentSRSRestService: EquipmentSRSRestService,
    private translateService: TranslateService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private router: Router,
    public route: ActivatedRoute,
    public blacklistRestService: BlacklistRestService
  ) {
  }

  static checkFieldIsDate(field: string, fieldTranslationKey: string): boolean {
    return !!(
      (fieldTranslationKey && fieldTranslationKey.toLowerCase().endsWith('_date')) ||
      (field && field.toLowerCase().endsWith('date'))
    );
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.unsubscribeConfig$.next();
    this.unsubscribeConfig$.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.equipmentUtilService.srsStatusUpdateSubject
      .pipe(takeUntil(this.unsubscribe$)).subscribe(srsStatus => {
        const index = this.equipmentDetailFields.findIndex(field => field.fieldName === 'srsStatus');
        this.equipmentDetailFields[index].value = this.translateSrsStatus(srsStatus.status, srsStatus.lastUpdate);
    });

    this.equipmentUtilService.updateEquipmentDataSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (response) => {
        this.equipmentRestService.clearEquipmentCache();
        this.equipmentItem = response;
        this.selectedItem = response;
        this.configurePropBasedOnCountryConfig();
      });

    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params: any) => {
      this.equipmentUtilService
        .loadEquipmentByKey(params['id'])
        .subscribe(equipmentResponse => {
          if (!!equipmentResponse) {
            this.equipmentItem = equipmentResponse;
            this.initProperties();
            this.configurePropBasedOnCountryConfig();
            this.equipmentRestService.postLastViewedEquipment(
              this.equipmentItem.key
            );
          }
        });
    });

    const sysInfoTabElements = document.querySelectorAll('#sysInfoTab');
    if (sysInfoTabElements.length > 0 && sysInfoTabElements[0] instanceof HTMLElement) {
      (sysInfoTabElements[0] as HTMLElement).click();
    }
  }

  initProperties() {
    this.epsReportLink = '';
    this.showEquipmentComponents = false;
    this.showSystemInformationTab = false;
    this.allowEdit = false;
    this.showEditButton = false;
    this.showDeactivateButton = false;
    this.showCreateRwfButton = false;
    this.addressFields = [];
    this.showSapAssetNumber = false;
    this.showModality = false;
    this.showEOS = false;
    this.showMaterialNumber = false;
    this.showGetSrsStatusBtn = false;
    this.srsConnectivityCheckFeatureToggle = false;
  }

  checkUserRoles() {
    // object to check roles
    const rolesToCheck = {
      checkEditEquipmentRole: roles.editEquipmentRole
    };
    this.userUtilService
      .checkUserRoles(rolesToCheck)
      .subscribe(checkRolesResponse => {
        this.allowEdit = this.shouldAllowEditComponent(checkRolesResponse);

        // if role found && not ld equipment
        if (
          checkRolesResponse.checkEditEquipmentRole &&
          !this.equipmentUtilService.checkIsLdEquipment(this.equipmentItem.key)
        ) {
          this.showEditButton = true;
        }

        /**
         * Check for De-activation, ld equipment check is not needed here -> confirmed by Bastian.
         * Same role name can be used to check for deactivating.
         */
        if (checkRolesResponse.checkEditEquipmentRole && isEqual(this.config.SHOW_BUTTON_DEACTIVATE_EQUIPMENT, 'true')) {
          this.showDeactivateButton = true;
        }
      });
  }

  shouldAllowEditComponent(checkRolesResponse) {
    return checkRolesResponse.checkEditEquipmentRole &&
      !this.equipmentUtilService.checkIsLdEquipment(this.equipmentItem.key) &&
      isEqual(this.config.SHOW_EQUIPMENT_COMPONENTS_EDIT, 'true');
  }

  configurePropBasedOnCountryConfig() {
    this.emitUnsubscribeConfig();
    this.configService.getConfig()
      .pipe(takeUntil(this.unsubscribeConfig$))
      .subscribe(configResponse => {
          this.config = configResponse;
          this.epsReportLink = configResponse.LINK_EQUIPMENT_REPORT;
          const greenStatus = configResponse.EQUIPMENT_STATUS_GREEN;
          const redStatus = configResponse.EQUIPMENT_STATUS_RED;
          const yellowStatus = configResponse.EQUIPMENT_STATUS_YELLOW;
          this.statusColorMap[greenStatus] = 'green';
          this.statusColorMap[redStatus] = 'red';
          this.statusColorMap[yellowStatus] = 'yellow';
          this.datePattern = configResponse.GENERIC_DATE_PATTERN;
          this.dateTimePattern = configResponse.GENERIC_DATE_TIME_PATTERN;
          this.servicePartsCatalogueLink = configResponse.LINK_SERVICE_PARTS_CATALOG;
          this.webshopCatalogueLink = configResponse.LINK_WEBSHOP_CATALOG;

          if (isEqual(configResponse.SHOW_EQUIPMENT_COMPONENTS, 'true') && isEqual(configResponse.SHOW_SYSTEM_INFORMATION_TAB, 'true')) {
            this.showEquipmentComponents = true;
          }

          if (isEqual(configResponse.SHOW_SYSTEM_INFORMATION_TAB, 'true')) {
            this.showSystemInformationTab = true;
          }

          if (isEqual(configResponse.SAP_ASSET_NUMBER_RENDER, 'true')) {
            this.showSapAssetNumber = true;
          }

          if (isEqual(configResponse.SHOW_FILTER_MODALITY, 'true')) {
            this.showModality = true;
          }

          if (isEqual(configResponse.EQUIPMENT_END_OF_SUPPORT_SHOW, 'true')) {
            this.showEOS = true;
          }

          if (isEqual(configResponse.MATERIAL_NO_RENDER, 'true')) {
            this.showMaterialNumber = true;
          }

          if (isEqual(configResponse.TOGGLE_QRCODE_EQUIPMENT, 'true')) {
            this.showEquipmentQRcode = true;
          }

          if (isEqual(configResponse.FEATURE_TOGGLE_SRS_CONNECTION_STATUS, 'true')) {
            this.srsConnectivityCheckFeatureToggle = true;
          }

          const addressConfigurableProperties = {
            street: {
              maxLength: 60
            },
            zip: {
              maxLength: 10 // Default value but changed after reading country config
            },
            city: {
              maxLength: 40
            }
          };
          this.addressFields = this.lifeNetUtilService.getAddressFields(
            this.equipmentItem,
            configResponse.ADDRESS_FIELD_ORDER,
            addressConfigurableProperties
          );

          if (isEqual(configResponse.SAO_FEATURE_AVAILABLE, 'true')) {
            this.saOrderUtilService.getAllowedOrders().subscribe(response => {
              this.allowedSecurityApplianceOrders = response;
            });
          }

          if (this.webshopCatalogueLink) {
            this.blacklistRestService.getWebshopCatalogBlacklist().subscribe(blacklist => {
              if (blacklist.some(modality => modality === this.equipmentItem.modality ||
                modality === (this.equipmentItem.cmdbEquipment && this.equipmentItem.cmdbEquipment.modalityCode))) {
                this.webshopCatalogueLink = '';
              }
            });
          }

          this.checkUserRoles();
          this.checkWhitelistedEquipments(configResponse);
          this.afterConfigProperties(configResponse);
        });
  }

  checkWhitelistedEquipments(config) {
    this.equipmentUtilService.getRemoteWorkForceWhitelistedEquipmentViewModelList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        equipment => {
          this.showCreateRwfButton = isEqual(config.FEATURE_TOGGLE_REMOTE_WORK_FORCE, 'true') &&
            find(equipment, ['key', this.equipmentItem.key]) !== undefined;
        });
  }

  afterConfigProperties(config) {
    const configFields = config.EQUIPMENT_DETAILS_ATTRIBUTES;
    const configFieldsPDF = config.EQUIPMENT_DETAILS_PDF_ATTRIBUTES;
    if (configFields || configFieldsPDF) {
      const rolesToCheck = {isRoleAllowed: roles.viewContractRole};
      const userRole$ = this.userUtilService.checkUserRoles(rolesToCheck);
      const translation$ = this.translateService.get(Object.keys(this.equipmentInformationLabelTranslationMap)
        .map(key => this.equipmentInformationLabelTranslationMap[key])
        .concat(this.EQUIPMENT_NO_CONTRACT_AVAILABLE_KEY, this.GENERIC_LABEL_NOT_AVAILABLE_KEY));

      combineLatest([userRole$, translation$]).subscribe(([userRoles, translations]) => {
        this.translations = translations;
        this.setCacheSrsStatus();
        this.equipmentDetailFields = this.createEquipmentDetailFields(configFields, userRoles);
        this.equipmentDetailFieldsPDF = this.createEquipmentDetailFields(configFieldsPDF, userRoles);
        this.checkInitialSrsStatus();
      });
    }
  }

  createEquipmentDetailFields(configFields: string, userRoles: object): ItemDetailViewModel[] {
    const result = [];
    const contractFields = configFields.split(',');
    forEach(contractFields, (field) => {
      if (!this.checkFieldShouldBeProcessed(field, result) ||
        this.filterInformationField(field, this.equipmentItem, userRoles)) {
        return;
      }
      const value = this.getProperValueForField(field);
      if (value) {
        if (value.dateType) {
          value.value = this.datePipeWrapperPipe.transform(value.value, this.datePattern);
        }
        result.push(value);
      }
    });
    return result;
  }

  checkFieldShouldBeProcessed(field: string, result): boolean {
    return !!field && result && result.every(item => item.fieldName !== field) &&
      Object.keys(this.equipmentInformationLabelTranslationMap).some(translationKeyName => translationKeyName === field);
  }

  filterInformationField(field: string, item, userRoles): boolean {
    switch (field) {
      case 'sapAssetNumber': return !this.showSapAssetNumber;
      case 'contractType': return userRoles && !userRoles.isRoleAllowed;
      case 'softwareVersion':
        return !this.showSystemInformationTab || !EquipmentUtilService.getSoftwareVersion(item);
      case 'serialNumber': return !this.showSystemInformationTab;
      case 'materialNumberConsolidated':
        return !this.showMaterialNumber || !item.cmdbEquipment || !item.cmdbEquipment.materialNumberConsolidated;
      case 'warranty': return !item.warrantyStart;
      case 'modalityTranslation': return !this.showSystemInformationTab || !this.showModality;
      case 'eosDate': return !this.showSystemInformationTab || !this.showEOS;
      default: return false;
    }
  }

  /**
   * @param field  - field name from equipmentInformationLabelTranslationMap object
   */
  getProperValueForField(field: string): ItemDetailViewModel {
    let fieldValue;
    let noValueTranslation: string;

    switch (field) {
      case 'city':
        fieldValue = this.equipmentItem[field];
        if (this.equipmentItem && this.equipmentItem.state) {
          fieldValue = fieldValue + ', ' + this.equipmentItem.state;
        }
        break;
      case 'contractType':
        if (this.equipmentItem && this.equipmentItem.contractType) {
          fieldValue = this.equipmentItem[field];
        } else {
          fieldValue = null;
          noValueTranslation = this.EQUIPMENT_NO_CONTRACT_AVAILABLE_KEY;
        }
        break;
      case 'softwareVersion':
        fieldValue = EquipmentUtilService.getSoftwareVersion(this.equipmentItem);
        break;
      case 'materialNumberConsolidated':
        fieldValue = this.equipmentItem.cmdbEquipment.materialNumberConsolidated;
        break;
      case 'installedOnDate':
        fieldValue = this.equipmentItem.installedOn;
        break;
      case 'warranty':
        fieldValue = this.datePipeWrapperPipe.transform(this.equipmentItem.warrantyStart, this.datePattern) + ' - ' +
          this.datePipeWrapperPipe.transform(this.equipmentItem.warrantyEnd, this.datePattern);
        break;
      case 'modality':
        fieldValue = this.equipmentItem.modalityTranslation;
        break;
      case 'eosDate':
        if (this.equipmentItem.cmdbEquipment && this.equipmentItem.cmdbEquipment.eosDate) {
          fieldValue = this.equipmentItem.cmdbEquipment.eosDate;
        } else {
          fieldValue = null;
          noValueTranslation = this.GENERIC_LABEL_NOT_AVAILABLE_KEY;
        }
        break;
      case 'srsStatus':
        fieldValue = this.translateSrsStatus(this.equipmentItem.srsStatus, this.equipmentItem.srsLastUpdated);
        if (fieldValue && this.srsConnectivityCheckFeatureToggle) {
          this.showGetSrsStatusBtn = true;
        }
        break;
      default:
        fieldValue = this.equipmentItem[field];
        break;
    }

    const fieldTranslationKey = this.equipmentInformationLabelTranslationMap[field];
    const fieldTranslation = fieldTranslationKey ? this.translations[fieldTranslationKey] : undefined;

    return ((!fieldValue && !noValueTranslation) || !fieldTranslation) ? null : {
      label: fieldTranslation,
      // tslint:disable-next-line:ban
      value: (!fieldValue && noValueTranslation) ? this.translations[noValueTranslation] : fieldValue,
      dateType: (!fieldValue && noValueTranslation) ? false : EquipmentSystemInformationComponent.checkFieldIsDate(field, fieldTranslation),
      fieldName: field
    };
  }

  showDeactivateModalDialog() {
    this.equipmentUtilService.showDeactivateModalDialog(this.equipmentItem);
  }

  showEditModal() {
    this.equipmentUtilService.showEditModal(
      Object.assign({}, this.equipmentItem),
      this.addressFields
    );
  }

  allowEquipmentOrder(): boolean {
    let showSaoButton = false;
    if (this.equipmentItem && this.equipmentItem.key) {
      showSaoButton = includes(
        this.allowedSecurityApplianceOrders,
        this.equipmentItem.key
      );
    }
    return showSaoButton;
  }

  openDetailOverlay(item: string) {
    this.selectedItem = item;
    this.router.navigate([item, 'createsao'], {relativeTo: this.route});
    this.detailOverlay.show();
  }

  translateSrsStatus(srsStatus: string, srsLastUpdated: string): string {
    const lastUpdated = srsLastUpdated ?
          this.datePipeWrapperPipe.transform(srsLastUpdated, this.dateTimePattern) : null;
    switch (srsStatus) {
      case SrsStatus.Connected:
        return this.translations.SRS_STATUS_CONNECTED + '<br>' + lastUpdated;
      case SrsStatus.NotConnected:
        return this.translations.SRS_STATUS_DISCONNECTED + '<br>' + lastUpdated;
      case SrsStatus.Unknown:
        return lastUpdated ? this.translations.SRS_STATUS_UNKNOWN + '<br>' + lastUpdated : this.translations.SRS_STATUS_UNKNOWN;
      case SrsStatus.ResponseFailed:
        return this.translations.SRS_STATUS_UNKNOWN + '<br>' + lastUpdated;
      default:
        return null;
    }
  }

  isSrsStatusLoading() {
    return this.equipmentUtilService.srsStatusesLoading.includes(this.equipmentItem.key);
  }

  getSrsStatus() {
    this.equipmentUtilService.getSrsStatus(this.equipmentItem);
  }

  private emitUnsubscribeConfig() {
    if (this.unsubscribeConfig$.observers.length > 0) {
      this.unsubscribeConfig$.next();
    }
  }

  setCacheSrsStatus() {
    if (this.srsConnectivityCheckFeatureToggle) {
      const status = this.equipmentSRSRestService.getSrsEquipmentStatus(this.equipmentItem.key);
      if (status && !this.equipmentItem.srsStatus) {
        this.equipmentItem.srsStatus = status.status;
      }
    }
  }

  checkInitialSrsStatus() {
    if (this.srsConnectivityCheckFeatureToggle && !this.equipmentItem.srsLastUpdated) {
      const status = this.equipmentSRSRestService.getSrsEquipmentStatus(this.equipmentItem.key);
      this.equipmentUtilService.setInitialSrsStatusForEquipment(this.equipmentItem, status);
    }
  }
}
