export class UploadUpdatePackagePackageValidator {

  /**
   * Validates package file name against the update number. File name must be without spaces and must contain
   * valid Update Number
   * @returns null if validation pass, otherwise returns {invalidName: true}
   */
  static fileNameValidator(control) {

    const file = control.value;
    const parent = control.parent;
    if (parent && Object.keys(parent).length > 0) {
      const updateNumber = parent.controls['updateNumber'].value;
      if (file && Object.keys(file).length > 0) {
        const filename = Object(file)[0]['filename'];
        if (filename && filename.indexOf(' ') === -1) {
          if (updateNumber && filename.indexOf(updateNumber) === -1) {
            return {invalidName: true};
          }
        } else {
          return {invalidName: true};
        }
      }
    }
    return null;
  }
}
