import { CustomerConstantsService } from './services/customer/customer-constants.service';
import { AddRemoveFavoriteEventService } from './component-communication-services/add-remove-favorite-event/add-remove-favorite-event.service';
import { FavoriteRestService } from './rest-services/favorite-rest.service';
import { UpdateTicketEventService } from './component-communication-services/update-ticket-event/update-ticket-event.service';
import { ContactRestService } from './rest-services/contact-rest.service';
import { ImpersonationRestService } from './rest-services/impersonation-rest.service';
import { CalendarClickEventService } from './component-communication-services/calendar-click-event/calendar-click-event.service';
import { CalendarUtilService } from './utils/calendar-util.service';
import { StateService } from './services/state.service';
import { CustomerAdministrationActivate } from './services/customer/customer-administration.activate';
import { CustomerUtilService } from './services/customer/customer-util.service';
import { DateUtilService } from './utils/date-util.service';
import { ActivitiesConstantsService } from './services/activities/activities-constants.service';
import { ActivitiesUtilService } from './services/activities/activities-util.service';
import { ContractsConstantsService } from './services/contracts/contracts-constants.service';
import { ContractsUtilService } from './services/contracts/contracts-util.service';
import { AttachmentUtilService } from './utils/attachment-util.service';
import { TicketsConstantsService } from './services/tickets/tickets-constants.service';
import { TicketsUtilService } from './services/tickets/tickets-util.service';
import { UpdateEquipmentEventService } from './component-communication-services/update-equipment-event/update-equipment-event.service';
import { AddRemoveEquipmentEventService } from './component-communication-services/add-remove-equipment-event/add-remove-equipment-event.service';
import { GenerateExcelRestService } from './rest-services/generate-excel-rest.service';
import { CacheableHttpClient } from './services/cache/cacheable-http-client';
import { HttpCacheService } from './services/cache/http-cache.service';
import { WindowService } from './window.service';
import { LogService } from './services/log/log.service';
import { EquipmentConstantsService } from './services/equipment/equipment-constants.service';
import { ToasterService } from './component-communication-services/toaster/toaster.service';
import { EquipmentUtilService } from './services/equipment/equipment-util.service';
import { AddRootClassesService } from './component-communication-services/add-root-classes/add-root-classes.service';
import { LifeNetUtilService } from './utils/life-net-util.service';
import { LogoutService } from './component-communication-services/logout/logout.service';
import { UserUtilService } from './services/user/user-util.service';
import { NewsRestService } from './rest-services/news-rest.service';
import { CountryConfigRestService } from './rest-services/country-config-rest.service';
import { NgModule } from '@angular/core';
import { TicketsRestService } from './rest-services/tickets-rest.service';
import { EquipmentRestService } from './rest-services/equipment-rest.service';
import { UserRestService } from './rest-services/user-rest.service';
import { EnvironmentConfigRestService } from './rest-services/environment-config-rest.service';
import { ContractsRestService } from './rest-services/contracts-rest.service';
import { ActivitiesRestService } from './rest-services/activities-rest.service';
import { CustomerRestService } from './rest-services/customer-rest.service';
import { ChatUtilService } from './services/chat/chat-util.service';
import { ChatRestService } from './rest-services/chat-rest.service';
import { ImpersonationUtilsService } from './utils/impersonation-utils.service';
import { ImpersonationCommunicationService } from './component-communication-services/impersonation/impersonation-communication.service';
import { CustomerGroupsRestService } from './rest-services/customer-groups-rest.service';
import { DeleteGroupEventService } from './component-communication-services/delete-group-event/delete-group-event.service';
import { BrowserStateService } from './services/browser-state.service';
import { ProfessionalServiceRequestUtilService } from './utils/professional-service-request-util.service';
import { ProfessionalServiceRequestRestService } from './rest-services/professional-service-request-rest.service';
import { AlignHeightEventService } from './component-communication-services/align-height-event/align-height-event.service';
import { WidgetHeightCacheService } from './services/cache/widget-height-cache.service';
import { ActivitiesCacheService } from './services/cache/activities-cache.service';
import { TicketsCacheService } from './services/cache/tickets-cache.service';
import { CreateTicketEventService } from './component-communication-services/create-ticket-event/create-ticket-event.service';
import { MessagesRestService } from './rest-services/messages-rest.service';
import { SystemUpdatesConstantsService } from './services/system-updates/system-updates-constants.service';
import { SystemUpdatesRestService } from './rest-services/system-updates-rest.service';
import { SystemUpdatesService } from './services/system-updates/system-updates-service';
import { ReportsCacheService } from './services/cache/reports-cache.service';
import { ReportingUtilService } from './services/reporting/reporting-util.service';
import { ReportsConstantsService } from '../generate-reports/services/reports-constants.service';
import { GenerateReportsRestService } from './rest-services/generate-reports-rest.service';
import { NewsService } from './services/news.service';
import { IcCodeRestService } from './rest-services/ic-code-rest.service';
import { AnnouncementRestService } from './rest-services/announcement-rest.service';
import { StringUtilService } from './utils/string-util.service';
import { IadvizeChatService } from './component-communication-services/iadvize-chat/iadvize-chat.service';
import { CreatePsrTemplateEventService } from './component-communication-services/create-psr-template/create-psr-template-event.service';
import { SecurityApplianceOrderUtilService } from './services/security-appliance-order/security-appliance-order-util.service';
import { SecurityApplianceOrderRestService } from './rest-services/security-appliance-order-rest.service';
import { ImpersonationCacheService } from './services/cache/impersonation-cache.service';
import { DomUtilService } from './utils/dom-util.service';
import { SecurityNotificationsRestService } from './rest-services/security-notifications-rest.service';
import { SecurityNotificationsService } from './services/security-notifications/security-notifications.service';
import { FilterUtilService } from './utils/filter-util.service';
import { ExportUtilService } from './utils/export-util.service';
import { DownloadUtilService } from './utils/download-util.service';
import { AsuUploadRestService } from './rest-services/asu-upload-rest.service';
import { WalkMeUtilService } from './utils/walk-me-util.service';
import { UserAuditTrailRestService } from './rest-services/user-audit-trail-rest.service';
import { OverlayCommunicationService } from './component-communication-services/overlay-close/overlay-communication.service';
import { ServiceMetricsRestService } from './rest-services/service-metrics-rest.service';
import { ManageEmailsRestService } from './rest-services/manage-emails-rest.service';
import { LogisticRestService } from './rest-services/logistic-rest.service';
import { ModalityUtilService } from './utils/modality-util.service';
import { RefreshItemEventService } from './component-communication-services/refresh-item-event/refresh-item-event.service';
import { LogisticMilestonesUtilService } from './services/logistic-milestones/logistic-milestones-util.service';
import { MyCustomersRestService } from './rest-services/my-customers-rest.service';
import { SecurityAdvisoriesRestService } from './rest-services/security-advisories-rest.service';
import { SecurityAdvisoriesService } from './services/security-advisories/security-advisories-service';
import { SecurityAdvisoriesConstantsService } from './services/security-advisories/security-advisories-constants.service';
import { TrainingsRestService } from './rest-services/trainings-rest.service';
import { DocumentSourceRestService } from './rest-services/document-source-rest.service';
import { ClearCacheUtilService } from './utils/clear-cache-util.service';
import { MessagingCenterService } from './services/messaging-center/messaging-center.service';
import { CustomerImpersonationService } from '../customer-impersonation/customer-impersonation.service';
import { DocumentFilterUtilService } from './utils/document-filter-util.service';
import { BlacklistRestService } from './rest-services/blacklist-rest.service';
import { SkbDocumentGeneratorService } from './utils/skb-document-generator.service';
import { MyFiltersAdapterService } from './services/my-filters-adapter.service';
import { SecurityAdvisoryNotificationService } from './services/security-advisories-notifications/security-advisory-notification.service';
import { SecurityAdvisoriesNotificationsRestService } from './rest-services/security-advisories-notifications-rest.service';
import { ActivitiesStatusDescriptionTranslationService } from './services/activities/activities-status-description-translation-service';
import { SprSaveEvent } from './component-communication-services/spr-configuration-save-event/spr-save-event';
import { SprConfigurationSaveEvent } from './component-communication-services/spr-configuration-save-event/spr-configuration-save-event';
import { SprConfigurationRestService } from './rest-services/spr-configuration-rest.service';
import { TeamplayRestService } from './rest-services/teamplay-rest.service';
import { DragAndDropService } from './services/drag-and-drop/drag-and-drop.service';
import { SprDashboardService } from '../spr/spr-dashboard.service';

@NgModule({
  providers: [
    UserRestService,
    EquipmentRestService,
    TicketsRestService,
    ContractsRestService,
    SecurityAdvisoriesRestService,
    SecurityAdvisoriesNotificationsRestService,
    CountryConfigRestService,
    EnvironmentConfigRestService,
    NewsRestService,
    NewsService,
    UserUtilService,
    EquipmentUtilService,
    EquipmentConstantsService,
    ReportsConstantsService,
    SystemUpdatesConstantsService,
    SystemUpdatesRestService,
    SystemUpdatesService,
    IcCodeRestService,
    LogoutService,
    LifeNetUtilService,
    AddRootClassesService,
    ToasterService,
    LogService,
    WindowService,
    HttpCacheService,
    CacheableHttpClient,
    AddRemoveEquipmentEventService,
    UpdateEquipmentEventService,
    UpdateTicketEventService,
    RefreshItemEventService,
    CalendarClickEventService,
    GenerateExcelRestService,
    TicketsUtilService,
    TicketsConstantsService,
    TicketsCacheService,
    ReportsCacheService,
    AttachmentUtilService,
    ContractsUtilService,
    ContractsConstantsService,
    ActivitiesUtilService,
    ActivitiesConstantsService,
    ActivitiesRestService,
    ActivitiesCacheService,
    DateUtilService,
    DomUtilService,
    CustomerRestService,
    CustomerGroupsRestService,
    CustomerUtilService,
    CustomerAdministrationActivate,
    StateService,
    CalendarUtilService,
    ImpersonationUtilsService,
    ImpersonationCommunicationService,
    ImpersonationRestService,
    ContactRestService,
    FavoriteRestService,
    AddRemoveFavoriteEventService,
    DeleteGroupEventService,
    CustomerConstantsService,
    BrowserStateService,
    ProfessionalServiceRequestRestService,
    ProfessionalServiceRequestUtilService,
    AlignHeightEventService,
    WidgetHeightCacheService,
    CreateTicketEventService,
    MessagingCenterService,
    MessagesRestService,
    ReportingUtilService,
    GenerateReportsRestService,
    AnnouncementRestService,
    ManageEmailsRestService,
    ChatUtilService,
    ChatRestService,
    StringUtilService,
    IadvizeChatService,
    SecurityApplianceOrderUtilService,
    SecurityApplianceOrderRestService,
    CreatePsrTemplateEventService,
    ImpersonationCacheService,
    SecurityNotificationsRestService,
    SecurityNotificationsService,
    FilterUtilService,
    ExportUtilService,
    DownloadUtilService,
    WalkMeUtilService,
    AsuUploadRestService,
    UserAuditTrailRestService,
    OverlayCommunicationService,
    ServiceMetricsRestService,
    LogisticRestService,
    LogisticMilestonesUtilService,
    ModalityUtilService,
    SkbDocumentGeneratorService,
    MyCustomersRestService,
    DocumentSourceRestService,
    SecurityAdvisoriesService,
    SecurityAdvisoryNotificationService,
    SecurityAdvisoriesConstantsService,
    TrainingsRestService,
    ClearCacheUtilService,
    CustomerImpersonationService,
    DocumentFilterUtilService,
    BlacklistRestService,
    SprConfigurationSaveEvent,
    SprSaveEvent,
    SprConfigurationRestService,
    MyFiltersAdapterService,
    ActivitiesStatusDescriptionTranslationService,
    TeamplayRestService,
    DragAndDropService,
    SprDashboardService
  ]
})
export class CoreModule {
}
