import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { systemUpdateDetailRestName } from 'app/core/rest-services/system-updates-rest.service';
import { UploadUpdatePackageModalComponent } from 'app/shared/modal-popup/upload-update-package-modal/upload-update-package-modal.component';
import { clone, find, List } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseSystemUpdates } from '../core/base-class/base-system-updates';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { BrowserStateService } from '../core/services/browser-state.service';
import { EquipmentUtilService } from '../core/services/equipment/equipment-util.service';
import { LogService } from '../core/services/log/log.service';
import { StateService } from '../core/services/state.service';
import { SystemUpdatesConstantsService } from '../core/services/system-updates/system-updates-constants.service';
import { SystemUpdatesService } from '../core/services/system-updates/system-updates-service';
import { UserUtilService } from '../core/services/user/user-util.service';
import { FilterUtilService } from '../core/utils/filter-util.service';
import { LifeNetUtilService } from '../core/utils/life-net-util.service';
import { SystemUpdateBaseViewModel } from '../core/view-models/system-update-base-view-model';
import { MyFiltersAdapterService } from 'app/core/services/my-filters-adapter.service';
import { CreateNewSystemUpdateModalComponent } from '../shared/modal-popup/create-new-system-update-modal/create-new-system-update-modal.component';
import { UserRestService } from '../core/rest-services/user-rest.service';

@Component({
  selector: 'hl-system-updates',
  templateUrl: './system-updates.component.html'
})
export class SystemUpdatesComponent extends BaseSystemUpdates

  implements OnInit, OnDestroy {
  _shouldSelectActive = true;
  selectedUpdateKey: string;

  @ViewChild('createUploadModal', {static: false}) createUploadModal: UploadUpdatePackageModalComponent;
  @ViewChild('createNewUpdateModal', {static: false}) createNewUpdateModal: CreateNewSystemUpdateModalComponent;

  constructor(
    systemUpdatesConstantsService: SystemUpdatesConstantsService,
    systemUpdatesService: SystemUpdatesService,
    translateService: TranslateService,
    route: ActivatedRoute,
    router: Router,
    equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService,
    userUtilService: UserUtilService,
    private userRestService: UserRestService,
    stateService: StateService,
    logService: LogService,
    private myFiltersService: MyFiltersAdapterService,
    browserStateService: BrowserStateService,
    filterUtilService: FilterUtilService,
    public lifeNetUtilService: LifeNetUtilService
  ) {
    // base class instantiation
    super(
      systemUpdatesConstantsService,
      systemUpdatesService,
      translateService,
      route,
      router,
      equipmentUtilService,
      configService,
      userUtilService,
      stateService,
      logService,
      browserStateService,
      filterUtilService
    );
  }

  ngOnInit() {
    super.init();

    this.systemUpdatesService.onStatusUpdate.subscribe(() => {
      this.isLoaded = false;
      this.viewModelSubscription = this.loadViewModelList()
        .pipe(finalize(() => {
          this.isLoaded = true;
        }))
        .subscribe(viewModelResponse => {
          this.rawList = clone(viewModelResponse);
          this.selectedItem = this.rawList.find(
            item => item.updateNumber === this.route.snapshot.params.id
          );
          this.afterInitViewModelList();
        });
    });

    this.routeParamsSubscription = this.getRouteParams(
      this.route.root
    ).subscribe(params => {
      if (params.id && this.viewModelList && this.viewModelList.length > 0) {
        this.selectedItem = find(
          this.viewModelList,
          update => update.updateNumber === params.id
        );
      }
      // saving this one for later, until the viewModelList is loaded
      this.selectedUpdateKey = params.id;
    });

    this.myFiltersService.filterEquipmentKeys$.pipe(
      tap(() => this.isLoaded = false),
      switchMap(() => this.loadViewModelList().pipe(finalize(() =>
        this.isLoaded = true))),
      takeUntil(this.unsubscribe$)
    ).subscribe(viewModelResponse => {
      this.rawList = clone(viewModelResponse);
      this.onAdvancedFilterChange();
      this.isLoaded = true;
    });
  }

  ngOnDestroy() {
    super.destroy();
  }

  public get exportEndpoint() {
    return systemUpdateDetailRestName + '/exportExcel';
  }

  public get updateNumbers(): Observable<List<string>> {
    return of(this.listWithoutPagination.map(s => s.updateNumber));
  }

  /**
   * @description Additional properties to be initialized for derived class
   */
  afterInitProperties() {
    this.sortSkeleton = this.systemUpdatesConstantsService.getSystemUpdatesSortSkeleton();
    this.allUpdateStatuses = this.systemUpdatesConstantsService.getUpdateStatesSkeleton();
    this.checkAllStates = true;
  }

  /**
   * @description Gets messages
   */
  loadViewModelList(): Observable<SystemUpdateBaseViewModel[]> {
    return this.systemUpdatesService.getSystemUpdates();
  }

  setDisplayAndSelectedActive() {
    if (this.selectedUpdateKey && !this.selectedItem) {
      this.selectedItem = find(
        this.viewModelList,
        update => update.updateNumber === this.selectedUpdateKey
      );
    }
    super.setDisplayAndSelectedActive();
  }

  navigate() {
    if (this.selectedItem) {
      this.router.navigate(['/updates', this.selectedItem.updateNumber]);
    }
  }

  setPropertiesFromQueryParams(config: any) {
    this.routeQuerySubscription = this.route.queryParams.subscribe(params => {
      const searchTerm = params['searchTerm'];
      if (searchTerm) {
        this.selectedItem = undefined;
        this.searchInput = searchTerm;
        this.initialListMode = false;
        this.selectedUpdateStatuses = [];
        this.allUpdateStatuses.forEach(updateStatusColor => {
          this.selectedUpdateStatuses.push(updateStatusColor.value);
        });
        // sadly this method is not called by Angular when setting the searchInput field.
        // we don't know why :(
        this.onAdvancedFilterChange();
      }
    });
  }

  trackByFn(index, items: SystemUpdateBaseViewModel) {
    return items.title;
  }

  shouldSelectActive(): boolean {
    return this._shouldSelectActive;
  }

  shouldShowCreateAndEditButtons(): Observable<boolean> {
    return combineLatest([
      this.configService.getConfig(),
      this.userRestService.getAssignedCustomers()
    ]).pipe(takeUntil(this.unsubscribe$),
      map(([config, customers]) =>
        find(customers, {customerNumber: config.ASU_SIMULATOR_CUSTOMER_NUMBER}) !== undefined));
  }

  showCreateUpdateModal($event: Event, update: SystemUpdateBaseViewModel = null) {
    $event.preventDefault();
    $event.stopPropagation();
    this.onAdvancedFilterChange();
    this.systemUpdatesService.showCreateUpdateModal(update);
    this.createNewUpdateModal.show();
  }
}
