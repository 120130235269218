import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { NotAuthenticatedComponent } from './not-authenticated.component';
import { FrontPagesRoutingModule } from './front-pages-routing.module';
import { FrontPageComponent } from './front-page.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { NotAuthorizedComponent } from './not-authorized.component';

@NgModule({
  imports: [
    SharedModule,
    FrontPagesRoutingModule
  ],
  exports: [
    FrontPageComponent
  ],
  declarations: [
    FrontPageComponent,
    NotAuthenticatedComponent,
    PageNotFoundComponent,
    NotAuthorizedComponent
  ]
})
export class FrontPagesModule {
}
