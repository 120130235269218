import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { UserUtilService } from '../../../core/services/user/user-util.service';
import { takeUntil } from 'rxjs/operators';
import { DateButton } from 'angular-bootstrap-datetimepicker/dl-date-time-picker/dl-date-time-picker-date-button';

const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MonthPickerComponent),
  multi: true
};

@Component({
  selector: 'hl-month-picker',
  templateUrl: './month-picker.component.html',
  providers: [MULTISELECT_VALUE_ACCESSOR]
})
export class MonthPickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  disabled = false;
  @Input()
  labelText: string;
  @Input()
  isVisibleCloseButton = true;

  @ViewChild('month', {static: false})
  dateInputElement: ElementRef;

  @ViewChild('picker_wrapper', {static: false})
  dateMonthPickerElement: ElementRef;

  // Auto convert the timeModel value from a string to a date
  @Input()
  convertModelValue = false;

  /**
   * model of the datepicker AND final date, that is shown to the outside of the
   * component
   */
  dateModel: Date;

  /**
   * date pattern to generate the date
   */
  datePattern = 'MMM-YYYY';

  @Input()
  minDate: Date;
  @Input()
  maxDate: Date;
  @Input()
  isRequired = false;
  @Input()
  invalidLabel: string;
  @Input()
  isInvalid: boolean;
  @Input()
  customDatePattern: string;
  @Input()
  filterFunction: (date: Moment, viewName?: string) => boolean = null;

  inputLocale: string;

  closeSubscription: Subscription;
  opennedPicker = false;
  dateModelString: string = null;
  tempDate = null;

  private readonly unsubscribe$: Subject<void> = new Subject();

  ASSUME_PICKER_HEIGHT = 178;

  constructor(
    private configService: CountryConfigRestService,
    private userService: UserUtilService,
    private renderer: Renderer2,
    private _eref: ElementRef
  ) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      if (configResponse && configResponse.GENERIC_MONTH_PATTERN) {
        this.datePattern = configResponse.GENERIC_MONTH_PATTERN;
      }
    });
    this.userService.getUser().subscribe(userResponse => {
      if (userResponse && userResponse.language) {
        this.inputLocale = userResponse.language;
      }
    });
  }

  // called, when this component decides, that there is a new date picked or entered
  newDateSet(): void {
    this.onModelChange(this.dateModel);
    this.onModelTouched(this.dateModel);
  }

  resetDate(event: Event) {
    event.preventDefault();
    this.dateModel = null;
    this.dateModelString = null;
    this.tempDate = null;
    this.newDateSet();
  }

  getDatePattern(): string {
    return this.customDatePattern || this.datePattern;
  }

  /**
   * called when typing in the input box
   * @param value
   */
  updateDateModel(value) {
    if (value === null) {
      this.dateModel = null;
      this.tempDate = null;
      this.newDateSet();
    } else if (value !== undefined) {
      let date: Date;

      // parsing date in short format ('L'), based on given locale, e.g. for locale 'de' this means strings like '22.02.2002'
      // the 'L' stuff did not work for 'en' and 'DD/MM/YYYY' dates
      // so we are using the pattern without the locale.
      // but moment.js is not supporting 'dd' or 'yyyy' - that's why we uppercase
      const momentObj = moment(value, this.getDatePattern().toUpperCase(), true);
      momentObj.locale(this.inputLocale);

      if (momentObj.isValid()) {
        date = momentObj.toDate();
        if (date !== this.dateModel) {
          this.dateModel = date;
          this.tempDate = date;
          this.newDateSet();
        }
      }
    } else if (value === undefined && this.tempDate) {
      this.updateDateModel(this.tempDate);
    }
  }

  updateDateModelFromPicker(value) {
    if (value !== null && value !== undefined) {
      const momentObj = moment(value);
      momentObj.locale(this.inputLocale);

      if (momentObj.isValid() && (momentObj.format(this.getDatePattern()) !== this.dateModelString)) {
        this.dateModelString = momentObj.format(this.getDatePattern());
        this.tempDate = momentObj.toDate();
        this.newDateSet();
        this.closePicker();
      }
    } else if (value === null) {
      this.dateModelString = null;
      this.tempDate = null;
      this.newDateSet();
      this.closePicker();
    } else if (value === undefined && this.tempDate) {
      this.updateDateModel(this.tempDate);
    }
  }

  onModelChange: Function = (_: any) => {
  }
  onModelTouched: Function = () => {
  }

  /**
   * called if the value of the parent component changes
   * @param value
   */
  writeValue(value: Date): void {
    if (value === null) {
      this.dateModel = null;
      this.dateModelString = null;
      this.tempDate = null;
      this.newDateSet();
    } else if (value !== undefined) {
      this.updateDateModel(value);
      this.updateDateModelFromPicker(value);
      this.newDateSet();
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  openPicker(): void {
    if (this.opennedPicker === false) {
      const inputDOMRect = this.dateInputElement.nativeElement.getBoundingClientRect();
      const windowInnerHeight = window.innerHeight;

      // Set position to picker according to input location in the browser window
      if (windowInnerHeight > (inputDOMRect.top + inputDOMRect.height + this.ASSUME_PICKER_HEIGHT) ||
        (inputDOMRect.top - this.ASSUME_PICKER_HEIGHT) <= 0) {
        this.renderer.setStyle(this.dateMonthPickerElement.nativeElement, 'top',
          (inputDOMRect.top + inputDOMRect.height) + 'px');
        this.renderer.setStyle(this.dateMonthPickerElement.nativeElement, 'left', inputDOMRect.left + 'px');
      } else {
        this.renderer.setStyle(this.dateMonthPickerElement.nativeElement, 'top',
          (inputDOMRect.top - this.ASSUME_PICKER_HEIGHT) + 'px');
        this.renderer.setStyle(this.dateMonthPickerElement.nativeElement, 'left', inputDOMRect.left + 'px');
      }

      this.opennedPicker = true;
      this.closeSubscription = fromEvent(document, 'click').subscribe((event: any) => {
        if (!this._eref.nativeElement.contains(event.target) && this.opennedPicker) {
          this.closePicker();
        } else if (this._eref.nativeElement.contains(event.target) && this.opennedPicker) {
          // This is here to mimic "permanent" focus of input when using datepicker/flatpicker
          this.dateInputElement.nativeElement.focus();
        }
      });
    }
  }

  closePicker(): void {
    this.opennedPicker = false;
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }
  }

  pickerDateFiler = (dateButton: DateButton, viewName: string): boolean => {
    if (this.filterFunction) {
      return this.filterFunction(moment(dateButton.value), viewName);
    }
    return true;
  }
}
