import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { HttpCacheService } from '../services/cache/http-cache.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { restEndPoint } from '../core-constants.service';
import { MyCustomer } from '../models/customer/my-customer';
import { environment } from '../../../environments/environment';

@Injectable()
export class MyCustomersRestService {

  myCustomersRestName = restEndPoint + 'users/self/settings/mycustomers';

  constructor(
    private http: CacheableHttpClient,
    private cacheService: HttpCacheService) {
  }

  getMyCustomersByCountry(country: string): Observable<MyCustomer[]> {
    const url = this.myCustomersRestName + '?country=' + country;
    return this.http.get<MyCustomer[]>(url);
  }

  postMyCustomersByCountry(body, country: string): Observable<any> {
    const urlAddress = this.myCustomersRestName + '?country=' + country;
    if (!environment.newCacheableHttpClient) {
      this.cacheService.clearIndividualRequest(urlAddress);
    }
    this.http.clearCache(urlAddress);
    return this.http.post(urlAddress, body);
  }
}
