import { CountryConfigRestService } from '../../core/rest-services/country-config-rest.service';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ActivitiesViewModel } from '../../core/view-models/activities-view-model';
import { CalendarOptions } from '../../core/models/activities/calendar-options';
import { CalendarClickEventService } from '../../core/component-communication-services/calendar-click-event/calendar-click-event.service';
import { DateUtilService } from '../../core/utils/date-util.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hl-calendar',
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements OnInit, OnChanges, OnDestroy {
  // list of planned activities
  @Input()
  events: ActivitiesViewModel[];

  @Input()
  options: CalendarOptions;

  @Input()
  yearWrapper: boolean;

  @Input()
  monthSelector = true;

  @Input()
  weekSelector = false;

  @Input()
  showCurrentWeekOnly: boolean;

  @Output()
  onEventSelect: EventEmitter<string> = new EventEmitter<string>();

  minDate: Date;
  maxDate: Date;
  defaultDate;
  selectedYear;
  selectedMonth;
  selectedDay;
  selectedWeek;
  weeks = [];
  cachedWeeks = [];
  months = [];
  weekdays = [];
  showEventsForDayList = false;
  eventsForDayList: any[] = [];
  dateTimePattern = 'DD.MM.YYYY';
  showDateDay: string | null;
  weekNumber: any;
  weekdayTruncate = 1;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private calendarClickEventService: CalendarClickEventService,
    private dateUtilService: DateUtilService,
    public configService: CountryConfigRestService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Note:- We need to check for changes of events
    // for e.g. change of filter or by rendering of calendar year component
    if (changes['events']) {
      this.calculateSelectedDate();
      this.setMaxDate();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Initialize the call stack
   */
  init() {
    const currentLocale = this.translateService.currentLang;
    if (currentLocale === 'locale-zh_CN' || currentLocale === 'locale-ja_JP') {
      this.weekdayTruncate = 2;
    }

    this.months = _.clone(this.options.months) || [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    const weekdays = _.clone(this.options.weekdays) || [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

    // week starts on monday
    if (this.options.weekStart === 1) {
      weekdays.push(weekdays.shift());
    }
    this.weekdays = _.clone(weekdays);

    if (this.options.minDate) {
      this.minDate = new Date(this.options.minDate);
    }

    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.dateTimePattern = res.GENERIC_DATE_TIME_PATTERN;
    });

    this.setMaxDate();
    this.calculateSelectedDate();
    if (this.showCurrentWeekOnly) {
      this.calculateCurrentWeek();
    }
  }

  /**
   * Calendar click
   * @param event
   */
  eventClick(event) {
    this.onEventSelect.emit(event);
  }

  /**
   * Add the events for the particular date object.
   * For e.g. September 4th, 2017 has 3 planned activities, then these 3 are added for this particular
   * date.
   * @param date
   */
  bindEvent(date): void {
    if (!date || !this.events) {
      return;
    }

    date.events = [];
    _.forEach(this.events, (event: ActivitiesViewModel) => {
      let plannedDate: any;

      // US LifeNet feature
      plannedDate = this.getDisplayDate(event);

      if (
        plannedDate &&
        date.year === plannedDate.getFullYear() &&
        date.month === plannedDate.getMonth() &&
        date.day === plannedDate.getDate()
      ) {
        date.events.push(event);
      }
    });
  }

  /**
   * Check if date is allowed to be rendered.
   * @param date
   */
  allowedDate(date: any): boolean {
    if (!this.minDate && !this.maxDate) {
      return true;
    }

    const currDate = new Date(date.year, date.month, date.day);
    if (this.minDate && currDate < this.minDate) {
      return false;
    }

    return !(this.maxDate && currDate > this.maxDate);
  }

  /**
   * Check if allowed to navigate previous month
   */
  allowedPrevMonth(): boolean {
    let prevYear = null;
    let prevMonth = null;

    if (!this.minDate) {
      return true;
    }

    const currMonth = this.months.indexOf(this.selectedMonth);
    if (currMonth === 0) {
      prevYear = this.selectedYear - 1;
    } else {
      prevYear = this.selectedYear;
    }

    if (currMonth === 0) {
      prevMonth = 11;
    } else {
      prevMonth = currMonth - 1;
    }

    if (prevYear < this.minDate.getFullYear()) {
      return false;
    }

    if (prevYear === this.minDate.getFullYear()) {
      if (prevMonth < this.minDate.getMonth()) {
        return false;
      }
    }

    return true;
  }

  /**
   * Check if allowed to navigate next month
   */
  allowedNextMonth(): boolean {
    let nextYear = null;
    let nextMonth = null;
    if (!this.maxDate) {
      return true;
    }

    const currMonth = this.months.indexOf(this.selectedMonth);
    if (currMonth === 11) {
      nextYear = this.selectedYear + 1;
    } else {
      nextYear = this.selectedYear;
    }

    if (currMonth === 11) {
      nextMonth = 0;
    } else {
      nextMonth = currMonth + 1;
    }

    return (nextYear < this.maxDate.getFullYear() || (nextYear === this.maxDate.getFullYear() && nextMonth <= this.maxDate.getMonth()));
  }

  /**
   * Calculate the weeks for current month, add the events for the current month.
   */
  calculateWeeks() {
    this.weeks = [];
    let week = null;
    const daysInCurrentMonth = new Date(
      this.selectedYear,
      this.months.indexOf(this.selectedMonth) + 1,
      0
    ).getDate();
    for (let day = 1; day < daysInCurrentMonth + 1; day += 1) {
      let dayNumber = new Date(
        this.selectedYear,
        this.months.indexOf(this.selectedMonth),
        day
      ).getDay();
      const isWeekend = dayNumber === 0 || dayNumber === 6;
      if (this.options.weekStart === 1) {
        // week starts on monday
        if (dayNumber === 0) {
          dayNumber = 6;
        } else {
          dayNumber -= 1;
        }
      }
      week = week || [null, null, null, null, null, null, null];
      week[dayNumber] = {
        year: this.selectedYear,
        month: this.months.indexOf(this.selectedMonth),
        day,
        isWeekend
      };

      if (this.allowedDate(week[dayNumber])) {
        this.bindEvent(week[dayNumber]);
      } else {
        week[dayNumber].disabled = true;
      }

      if (dayNumber === 6 || day === daysInCurrentMonth) {
        this.weeks.push(week);
        week = null;
      }
    }
    this.cachedWeeks = _.clone(this.weeks);
    this.hideEventsForDay();
  }

  calculateSelectedDate() {
    if (this.options.defaultDate) {
      this.defaultDate = new Date(this.options.defaultDate);
    } else {
      this.defaultDate = new Date();
    }

    this.selectedYear = this.defaultDate.getFullYear();
    this.selectedMonth = this.months[this.defaultDate.getMonth()];
    this.selectedDay = this.defaultDate.getDate();
    this.calculateWeeks();
  }

  calculateCurrentWeekNumber(week: any[]) {
    for (const day of week) {
      if (day !== null) {
        this.weekNumber = moment(
          `${day.year}-${day.month + 1}-${day.day}`,
          'YYYY-MM-DD'
        ).isoWeek();
        return;
      }
    }
  }

  calculateCurrentWeek() {
    for (const week of this.weeks) {
      for (const day of week) {
        if (day !== null) {
          const currentDay = new Date().getDate();
          if (day.day === currentDay) {
            this.selectedWeek = this.cachedWeeks.indexOf(week);
          }
        }
      }
    }
    this.weeks = [];
    this.weeks.push(this.cachedWeeks[this.selectedWeek]);
    this.calculateCurrentWeekNumber(this.weeks[0]);
  }

  goPrevWeek() {
    if (!this.allowedPrevMonth()) {
      return;
    }
    const currIndex = this.cachedWeeks.indexOf(this.weeks[0]);

    const currIndexMonth = this.months.indexOf(this.selectedMonth);
    if (currIndex === 0) {
      if (currIndexMonth === 0) {
        this.selectedYear -= 1;
        this.selectedMonth = this.months[11];
      } else {
        this.selectedMonth = this.months[currIndexMonth - 1];
      }
      this.calculateWeeks();
      this.weeks = [];
      this.weeks.push(this.cachedWeeks[this.cachedWeeks.length - 1]);
    } else {
      this.weeks = [];
      this.weeks.push(this.cachedWeeks[currIndex - 1]);
    }
    this.calculateCurrentWeekNumber(this.weeks[0]);
    this.selectedWeek = this.cachedWeeks.indexOf(this.weeks[0]);
  }

  goNextWeek() {
    if (!this.allowedNextMonth()) {
      return;
    }
    const currIndex = this.cachedWeeks.indexOf(this.weeks[0]);
    const currIndexMonth = this.months.indexOf(this.selectedMonth);
    if (currIndex === this.cachedWeeks.length - 1) {
      if (currIndexMonth === 11) {
        this.selectedYear += 1;
        this.selectedMonth = this.months[0];
      } else {
        this.selectedMonth = this.months[currIndexMonth + 1];
      }
      this.calculateWeeks();
      this.weeks = [];
      this.weeks.push(this.cachedWeeks[0]);
    } else {
      this.weeks = [];
      this.weeks.push(this.cachedWeeks[currIndex + 1]);
    }
    this.calculateCurrentWeekNumber(this.weeks[0]);
    this.selectedWeek = this.cachedWeeks.indexOf(this.weeks[0]);
  }

  /**
   * Check if the date is current default date for the CW
   * @param date
   */
  isDefaultDate(date) {
    if (!date) {
      return;
    }
    return (
      date.year === this.defaultDate.getFullYear() &&
      date.month === this.defaultDate.getMonth() &&
      date.day === this.defaultDate.getDate()
    );
  }

  /**
   * Handle click on prev month
   */
  goPrevMonth() {
    if (!this.allowedPrevMonth()) {
      return;
    }

    const currIndex = this.months.indexOf(this.selectedMonth);
    if (currIndex === 0) {
      this.selectedYear -= 1;
      this.selectedMonth = this.months[11];
    } else {
      this.selectedMonth = this.months[currIndex - 1];
    }

    this.calculateWeeks();
  }

  /**
   * Handle click on next month
   */
  goNextMonth() {
    if (!this.allowedNextMonth()) {
      return;
    }

    const currIndex = this.months.indexOf(this.selectedMonth);
    if (currIndex === 11) {
      this.selectedYear += 1;
      this.selectedMonth = this.months[0];
    } else {
      this.selectedMonth = this.months[currIndex + 1];
    }

    this.calculateWeeks();
  }

  /**
   * maxDate is set to a latest dueDate among all the activities.
   */
  setMaxDate() {
    this.maxDate = this.defaultDate;

    _.forEach(this.events, (event: ActivitiesViewModel) => {
      let plannedDate: any;

      plannedDate = this.getDisplayDate(event);

      if (plannedDate && this.maxDate < plannedDate) {
        this.maxDate = plannedDate;
      }
    });
  }

  /**
   * Get the display date based upon the activity type.
   *
   * @param {ActivitiesViewModel} event
   * @returns {Date}
   */
  getDisplayDate(event: ActivitiesViewModel): Date {
    if (event.completedDate) {
      return this.dateUtilService.getDateFromUTCString(event.completedDate);
    } else if (!event.scheduled && event.dueDate) {
      return this.dateUtilService.getDateFromUTCString(event.dueDate);
    } else if (event.plannedStartDate) {
      return this.dateUtilService.getDateFromUTCString(event.plannedStartDate);
    } else {
      return null;
    }
  }

  showEventsForDay(week: number, day: string): void {
    this.showDateDay = day;
    const dayOfWeek = this.weeks[week].filter(item => {
      if (item && item.day === day) {
        return item;
      }
    });
    if (dayOfWeek && dayOfWeek[0].events) {
      this.showEventsForDayList = true;
      this.eventsForDayList = dayOfWeek[0].events;
    } else {
      this.hideEventsForDay(day);
    }
  }

  hideEventsForDay(day?: string): void {
    this.showEventsForDayList = false;
    this.eventsForDayList = [];
    if (day) {
      this.showDateDay = day;
    } else {
      this.showDateDay = null;
    }
  }
}
