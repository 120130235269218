import { Injectable } from '@angular/core';
import { ImpersonationRestService } from '../core/rest-services/impersonation-rest.service';
import { map } from 'rxjs/operators';
import { ImpersonatedCustomers } from '../core/models/impersonation/impersonated-customers';
import * as _ from 'lodash';
import { ImpersonationUtilsService } from '../core/utils/impersonation-utils.service';
import { Observable, ReplaySubject } from 'rxjs';
import { ImpersonationCommunicationService } from '../core/component-communication-services/impersonation/impersonation-communication.service';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { CustomerViewModel } from '../core/view-models/customer-view-model';
import { CustomerGroupViewModel } from '../core/view-models/customer-group-view-model';

@Injectable()
export class CustomerImpersonationService {
  private _impersonatedCustomersSubject = new ReplaySubject<any>();
  private _impersonatedGroupsSubject = new ReplaySubject<any>();
  private _isConfigLoadedSubject = new ReplaySubject<boolean>();

  private _impersonatedCustomers = [];
  private _impersonatedGroups = [];

  private cityColumnEnabled = false;
  private stateColumnEnabled = false;

  private _isConfigLoaded = false;

  constructor(private impersonationRestService: ImpersonationRestService,
    private impersonationUtilsService: ImpersonationUtilsService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private configService: CountryConfigRestService) {

    this.impersonationCommunicationService.onCountryLanguageChange$.subscribe(
      () => this.init()
    );

    this.impersonationCommunicationService.onCustomerRemovedChange$.subscribe(
      customer => this.removeCustomer(customer)
    );

    this.impersonationCommunicationService.onCustomerAdded$.subscribe(
      customer => this.addCustomer(customer)
    );

    this.impersonationCommunicationService.onCustomerGroupRemovedChange$.subscribe(
      customerGroup => this.removeGroup(customerGroup)
    );

    this.impersonationCommunicationService.onGroupAdded$.subscribe(
      customerGroup => this.addGroup(customerGroup)
    );

    this.init();
  }

  private init() {
    this.configService.getConfig().subscribe(configResponse => {
      this._isConfigLoaded = true;
      this._isConfigLoadedSubject.next(this._isConfigLoaded);

      if (_.isEqual(configResponse.SHOW_CITY_COLUMN, 'true')) {
        this.cityColumnEnabled = true;
      }
      if (_.isEqual(configResponse.SHOW_STATE_COLUMN, 'true')) {
        this.stateColumnEnabled = true;
      }

      this.impersonationRestService
        .getImpersonatedCustomers().pipe(
        map((response: ImpersonatedCustomers) => {
          return {
            customers: this.impersonationUtilsService.mapCustomersToViewModel(
              response.customers
            ),
            groups: this.impersonationUtilsService.mapCustomerGroupsToViewModel(
              response.customerGroups, this.cityColumnEnabled, this.stateColumnEnabled
            )
          };
        })
      ).subscribe(impersonatedLists => {
        this._impersonatedCustomers = _.cloneDeep(impersonatedLists.customers);
        this._impersonatedGroups = _.cloneDeep(impersonatedLists.groups);

        this._impersonatedCustomersSubject.next(this._impersonatedCustomers);
        this._impersonatedGroupsSubject.next(this._impersonatedGroups);
      });
    });
  }

  private addCustomer(customer: CustomerViewModel) {
    if (!_.includes(
      this._impersonatedCustomers.map(i => i.customerId), customer.customerId
    )) {
      this._impersonatedCustomers.push(customer);
      this._impersonatedCustomersSubject.next(this._impersonatedCustomers);
    }
  }

  private removeCustomer(customer: CustomerViewModel) {
    this._impersonatedCustomers = _.without(
      this._impersonatedCustomers, customer
    );

    this._impersonatedCustomersSubject.next(this._impersonatedCustomers);
  }

  private addGroup(customerGroup: CustomerGroupViewModel) {
    if (!_.includes(
      this._impersonatedGroups.map(i => i.groupId), customerGroup.groupId
    )) {
      this._impersonatedGroups.push(customerGroup);
      this._impersonatedGroupsSubject.next(this._impersonatedGroups);
    }
  }

  private removeGroup(customerGroup: CustomerGroupViewModel) {
    this._impersonatedGroups = _.without(
      this._impersonatedGroups,
      customerGroup
    );

    this._impersonatedGroupsSubject.next(this._impersonatedGroups);
  }

  get impersonatedCustomersSubject(): Observable<any> {
    return this._impersonatedCustomersSubject.asObservable();
  }

  get impersonatedGroupsSubject(): Observable<any> {
    return this._impersonatedGroupsSubject.asObservable();
  }

  get isConfigLoadedSubject(): ReplaySubject<boolean> {
    return this._isConfigLoadedSubject;
  }
}
