import { restEndPoint } from '../core-constants.service';

// equipments
export const lastViewedEquipmentRestName = restEndPoint + 'equipments/lastviewed';
export const postLastViewedRestName = restEndPoint + 'equipments/:id/lastviewed';
export const myProfileRestName = restEndPoint + 'equipments/myProfile';
export const myProfileCheckedRestName = restEndPoint + 'equipments/myProfile/myProfileSelected';
export const equipmentStatusRestName = restEndPoint + 'equipments/status';
export const equipmentRestName = restEndPoint + 'equipments';
export const addRemoveMyEquipmentsRestName = restEndPoint + 'equipments/myProfile/:id';
export const equipmentIndividualRestName = restEndPoint + 'equipments/:id';
export const equipmentDetailsRestName = restEndPoint + 'equipments/:id/details';
export const equipmentsComponentsRestName = restEndPoint + 'equipments/:id/components';
export const equipmentDeativateRestName = restEndPoint + 'equipments/:id/deactivate';
export const equipmentOptionsUpgradesRestName = restEndPoint + 'equipments/:id/optionsUpgrades';
export const equipmentOptionsUpgradesRecommendationRestName = restEndPoint + 'equipments/:id/recommendation';
export const equipmentOptionsUpgradesQuoteRestName = restEndPoint + 'equipments/:id/quote';
export const equipmentOptionsUpgradesTrialRestName = restEndPoint + 'equipments/:id/trial';
export const equipmentDocumentsRestName = restEndPoint + 'equipments/:id/documents';
export const equipmentNwePortalDocumentsRestName = restEndPoint + 'equipments/:id/nwedocuments';
export const equipmentPatchDocumentsRestName = restEndPoint + 'equipments/:id/materialDocuments';
export const equipmentSyngoVoucherRestName = restEndPoint + 'equipment/documents/syngo-voucher';
export const equipmentSyngoVoucherWhitelisted = equipmentSyngoVoucherRestName + '/:id/whitelisted';
export const equipmentsContactsRestName = restEndPoint + 'equipments/:id/contacts';
export const equipmentsMobileAddressesRestName = restEndPoint + 'equipments/:id/mobileAddresses';
export const equipmentsPiiDetailsRestName = restEndPoint + 'equipments/:id/security';
export const allSelectableEquipments = restEndPoint + 'equipments/selectable';
export const securityWhitePaperRestname = restEndPoint + 'equipments/security-whitepaper';
export const reportMissingEquipmentRestName = restEndPoint + 'equipment/missing/send';
export const equipmentEnvironmentOptionsRestName = restEndPoint + 'equipment/environment/:id/options';
export const equipmentEnvironmentDataRestName = restEndPoint + 'equipment/environment/:id/data';
export const equipmentEnvironmentDataExistsRestName = restEndPoint + 'equipment/environment/data-exists';
export const equipmentForMyFiltersRestName = restEndPoint + 'equipments/for-my-filters';
export const equipmentRwfWhitelisted = restEndPoint + 'psr/rwf/equipment/whitelist';
export const equipmentRwfWhitelistExists = restEndPoint + 'psr/rwf/equipment/whitelist-exists';
export const equipmentSrsStatusCheck = restEndPoint + 'srs/status/:id';
export const equipmentSimulatorCreate = restEndPoint + 'equipments/create';

// modalities
export const modalitiesRestName = restEndPoint + 'equipments/modalities';
export const modalitiesGBRestName = restEndPoint + 'equipments/modalities-gb';
export const modalitiesWhitelistRestName = restEndPoint + 'equipments/modalities/whitelist';
