import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseEquipmentDetailListView } from '../../../core/base-class/base-equipment-detail-list-view';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingsRestService } from '../../../core/rest-services/trainings-rest.service';
import { TrainingViewModel } from '../../../core/view-models/training';
import * as _ from 'lodash';
import { EnvironmentConfigRestService } from '../../../core/rest-services/environment-config-rest.service';

@Component({
  selector: 'hl-equipment-trainings',
  templateUrl: './equipment-trainings.component.html'
})
export class EquipmentTrainingsComponent extends BaseEquipmentDetailListView<TrainingViewModel> implements OnInit, OnDestroy {
  isLoaded: boolean;
  trainingsImageUrl: string;

  selectedDropDownList = {
    trainingSection: []
  };

  optionsDropDownList = {
    trainingSections: []
  };

  constructor(
    private trainingsRestService: TrainingsRestService,
    private router: Router,
    filterUtilService: FilterUtilService,
    configService: CountryConfigRestService,
    public lifeNetUtilService: LifeNetUtilService,
    equipmentUtilService: EquipmentUtilService,
    activatedRoute: ActivatedRoute,
    private environmentConfigRestService: EnvironmentConfigRestService) {
    super(filterUtilService,
      configService,
      lifeNetUtilService,
      equipmentUtilService,
      activatedRoute);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    super.init();
    this.environmentConfigRestService.getEnvironmentConfig().subscribe(environmentConfig => {
      this.trainingsImageUrl = environmentConfig.TRAINING_IMAGE_REPO;
    });
  }

  afterConfigProperties(config: any): void {
    if (_.isEqual(config.TOGGLE_EQUIPMENT_TRAINING_TAB, 'true')) {
      this.trainingsRestService.getEquipmentTrainings(this.equipmentItem.key)
        .subscribe(trainings => {
          this.rawList = trainings.map(training => {
            return {...training, trainingSection: training.trainingSection.split(',').map(item => item.trim())};
          });
          this.setDropdownOptions();
          this.onAdvancedFilterChange();
          this.isLoaded = true;
        });
    }
  }

  afterInitProperties(): void {
    this.isLoaded = false;
    this.setSearchInputFromQueryParam(this.router);
  }

  getFilterObject(): any {
    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: [
          'trainingAreaName'
        ]
      },
      advancedMultiSelectArrayOrLogic: this.selectedDropDownList
    };
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  private setDropdownOptions() {
    this.selectedDropDownList.trainingSection = [];
    const dropDownListStructure = {
      trainingSections: 'trainingSection'
    };

    const dropDownOptions = this.filterUtilService.computeDropdownOptions(
      this.rawList,
      dropDownListStructure
    );
    this.optionsDropDownList.trainingSections = dropDownOptions.trainingSections;
  }
}
