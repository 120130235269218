import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EquipmentRestService } from '../../../../core/rest-services/equipment-rest.service';
import { ToasterService } from '../../../../core/component-communication-services/toaster/toaster.service';
import { Equipment } from '../../../../core/models/equipment/equipment';

@Component({
  selector: 'hl-create-new-equipment-for-update',
  templateUrl: './create-new-equipment-for-update.component.html'
})
export class CreateNewEquipmentForUpdateComponent implements OnInit, OnDestroy {
  @Output()
  onNewEquipmentAdded = new EventEmitter<Equipment>();
  newEquipmentForm: FormGroup;
  isFormSubmitted: boolean;
  matAndSerialNumberConflict: boolean;
  constructor(
    private fb: FormBuilder,
    private toasterService: ToasterService,
    private equipmentRestService: EquipmentRestService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.createForm();
    this.isFormSubmitted = false;
    this.matAndSerialNumberConflict = false;
  }

  ngOnDestroy(): void {
  }

  createForm() {
    this.newEquipmentForm = this.fb.group(
      {
        productName: ['', Validators.compose([Validators.maxLength(50), Validators.required])],
        materialNumber: ['', Validators.compose([Validators.maxLength(18), Validators.required])],
        serialNumber: ['', Validators.compose([Validators.maxLength(18), Validators.required])]
      }
    );
  }

  createNewSimulatorEquipment() {
    this.isFormSubmitted = true;
    this.matAndSerialNumberConflict = false;
    if (!this.newEquipmentForm.valid) {
      return;
    }
    this.sendCreateNewEquipmentCall();
  }

  sendCreateNewEquipmentCall() {
    const equipment = this.newEquipmentForm.value;
    this.equipmentRestService.postCreateSimulatorEquipment(equipment)
      .subscribe((createdEquipment) => {
        this.onNewEquipmentAdded.emit(createdEquipment);
        this.showSuccessfulToastMessage();
      }, err => {
        this.handleErrorWhileCreatingNewEquipment(err);
      });
  }

  private showSuccessfulToastMessage() {
    const message = {
      type: 'success',
      isBodyTranslationKey: true,
      body: 'NEW_SYSTEM_UPDATE_EQUIPMENT_SUCCESSFULLY_CREATED'
    };
    this.toasterService.emitToast(message);
  }

  private handleErrorWhileCreatingNewEquipment(err: any) {
    this.matAndSerialNumberConflict = err.error && err.error.code === 409;
    if (!this.matAndSerialNumberConflict) {
      const toast = {
        type: 'error',
        isBodyTranslationKey: true,
        body: 'FRONTEND_GENERIC_ERROR_MESSAGE'
      };
      this.toasterService.emitToast(toast);
    }
  }
}
