import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../../core/base-class/base-form-modal-popup';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToHtmlPipe } from '../../pipes/to-html.pipe';

@Component({
  selector: 'hl-equipment-missing-modal',
  templateUrl: './equipment-missing-modal.component.html',
  providers: [ToHtmlPipe]
})
export class EquipmentMissingModalComponent extends BaseFormModalPopup implements OnInit {

  emailText: string;
  attachments: [];
  uploadForm: FormGroup;

  constructor(private configService: CountryConfigRestService,
    private equipmentRestService: EquipmentRestService,
    private toasterService: ToasterService,
    private fb: FormBuilder,
    private toHtmlPipe: ToHtmlPipe,
    renderer: Renderer2) {
    super(renderer);
  }

  ngOnInit() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {

    });
    this.createForm();
  }

  createForm() {
    this.uploadForm = this.fb.group({
      attachments: [[]]
    });
  }

  postFormData() {
    this.uploadForm.value.text = this.toHtmlPipe.transform(this.emailText);

    this.equipmentRestService.reportMissingEquipment(this.uploadForm.value).pipe(
      finalize(() => {
        this.isFormSubmitted = false;
        this.emailText = null;
        // close modal
        this.hide();
      }))
      .subscribe(() => {
        this.showToaster(true, 'REPORT_MISSING_EQUIPMENT_EMAIL_SUCCESS');
      },
        error => {
          this.showErrorToaster();
        }
      );
  }

  showToaster(isBodyTranslationKey, body) {
    const toast = {
      type: 'success',
      isBodyTranslationKey,
      body
    };

    // show a success toaster message
    this.toasterService.emitToast(toast);
  }

  showErrorToaster() {
    const toast = {
      type: 'error',
      isBodyTranslationKey: true,
      body: 'FRONTEND_GENERIC_ERROR_MESSAGE'
    };
    this.toasterService.emitToast(toast);
  }
}
