import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { SortSkeleton } from '../../sorting/sort-skeleton';

@Component({
  selector: 'hl-list',
  templateUrl: './list.component.html'
})
export class ListComponent<TItem> {
  @Input()
  items: TItem[];
  @Input()
  isLoaded: boolean;
  @Input()
  label: string;
  @Input()
  empty: string;
  @Input()
  isCounterAbove = false;
  @Input()
  additionalCollectionClass: string;
  @Input()
  dataCyInput: string;

  @ViewChild('createUploadModal', { static: false })
  createUploadModal;

  @Input()
  sortConfig: any = {sortObject: {}};
  @Input()
  downloadConfig: any;

  @ContentChild(TemplateRef, { static: false })
  itemTemplate;
  @Output()
  onSortOptionChange = new EventEmitter<SortSkeleton>();
  @Input()
  trackByFunction;
  @Input()
  filteredLengthWithoutPagination = 0;

  @Input()
  showUploadButton = false;

  @Input()
  onExportGenerate: (exportAll: boolean) => Array<any>;

  emitSortChanges(options: SortSkeleton) {
    this.onSortOptionChange.emit(options);
  }
}
