import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SystemUpdatesRestService } from '../../rest-services/system-updates-rest.service';
import { SystemUpdateDetailViewModel } from '../../view-models/system-update-detail-view-model';
import { CacheableHttpClient } from '../cache/cacheable-http-client';
import { SystemUpdateBaseViewModel } from '../../view-models/system-update-base-view-model';
import { map } from 'rxjs/operators';
import { DownloadUtilService } from '../../utils/download-util.service';

@Injectable()
export class SystemUpdatesService {

  onStatusUpdate = new EventEmitter();
  onEquipmentLinkClick = new EventEmitter();
  showCreateNewSystemUpdateModalEmitter = new EventEmitter<SystemUpdateBaseViewModel>();

  constructor(private http: CacheableHttpClient,
    private systemUpdatesRestService: SystemUpdatesRestService,
    private downloadUtilService: DownloadUtilService) {
  }

  getSystemUpdates(): Observable<SystemUpdateBaseViewModel[]> {
    return this.systemUpdatesRestService.getUpdates().pipe(map(response => response.map(systemUpdate =>
      new SystemUpdateBaseViewModel(systemUpdate))));
  }

  public getSystemUpdatesForEquipment(equipmentKey: string): Observable<SystemUpdateBaseViewModel[]> {
    return this.systemUpdatesRestService.getSystemUpdatesForEquipment(equipmentKey).pipe(map(response =>
      response.map(systemUpdate => new SystemUpdateBaseViewModel(systemUpdate))));
  }

  getSystemUpdateDetail(updateNumber: string): Observable<SystemUpdateDetailViewModel> {
    if (updateNumber) {
      return this.systemUpdatesRestService.getSystemUpdateDetail(updateNumber).pipe(map(systemUpdate =>
        new SystemUpdateDetailViewModel(systemUpdate)));
    }
  }

  downloadPackage(updateNumber: string , packageName: string): void {
    this.systemUpdatesRestService.getDownloadUrl(updateNumber)
      .subscribe(({url}) => {
        this.downloadUtilService.downloadFileByUrl(packageName, url);
      });
  }

  onStatusUpdateEmit() {
    this.onStatusUpdate.emit();
  }

  onEquipmentLinkClickEmit() {
    this.onEquipmentLinkClick.emit();
  }

  showCreateUpdateModal(update: SystemUpdateBaseViewModel) {
    this.showCreateNewSystemUpdateModalEmitter.emit(update);
  }
}
