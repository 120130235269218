import { Pipe, PipeTransform } from '@angular/core';
import {NOT_AVAILABLE} from '../diagram';

@Pipe({
  name: 'getTotalFontSize'
})
export class GetTotalFontSizePipe implements PipeTransform {

  transform(countWithUnit: any): any {
    let fontSizeCss = '';
    if (countWithUnit === undefined) {
      return fontSizeCss;
    }
    if (countWithUnit === NOT_AVAILABLE) {
      return 'diagram__total-number-100';
    }
    const totalLength = countWithUnit.toString().length;

    if (totalLength >= 5) {
      fontSizeCss = 'diagram__total-number-10000';
    } else if (totalLength >= 4) {
      fontSizeCss = 'diagram__total-number-1000';
    } else if (totalLength >= 3) {
      fontSizeCss = 'diagram__total-number-100';
    }

    return fontSizeCss;
  }

}
