import { ImpersonationCommunicationService } from '../component-communication-services/impersonation/impersonation-communication.service';
import { ToasterService } from '../component-communication-services/toaster/toaster.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LifeNetUtilService } from './life-net-util.service';
import { localeSplitter } from '../core-constants.service';
import * as _ from 'lodash';
import { SelectOption } from '../models/select-option';
import { Country } from '../models/country';
import { Customer } from '../models/customer/customer';
import { CustomerViewModel } from '../view-models/customer-view-model';
import { TranslateService } from '@ngx-translate/core';
import { CustomerGroup } from '../models/customer/customer-group';
import { CustomerGroupViewModel } from '../view-models/customer-group-view-model';
import { ImpersonationRestService } from '../rest-services/impersonation-rest.service';
import { FilterUtilService } from './filter-util.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { fallBackLocale } from '../languages';

export const translationCountryPrefix = 'LABEL_COUNTRY_';
export const translationLanguagePrefix = 'LABEL_LANGUAGE_';

@Injectable()
export class ImpersonationUtilsService {

  preferredCountry: string;

  constructor(private lifeNetUtils: LifeNetUtilService,
    private translateService: TranslateService,
    private router: Router,
    private toasterService: ToasterService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private impersonationRestService: ImpersonationRestService,
    private filterUtilService: FilterUtilService) {

  }

  /**
   * mapping the result list by adding the translated country label
   *
   * @param {Customer[]} custList
   * @returns {CustomerViewModel[]}
   */
  mapCustomersToViewModel = (custList: Customer[]): CustomerViewModel[] => {

    const mappedList: CustomerViewModel[] = [];

    _.forEach(custList, (customer: Customer) => {
      const custView: CustomerViewModel = {
        city: customer.city,
        country: customer.country,
        customerId: customer.customerId,
        customerName: customer.customerName,
        customerNumber: customer.customerNumber,
        // tslint:disable-next-line:ban
        countryLabel: this.translateService.instant(translationCountryPrefix + customer.country)
      };
      mappedList.push(custView);
    });

    return mappedList;
  }
  /**
   * mapping the countryLabel into the group, adding two flage for the view
   * and ordering the customer-sub-list
   * @param {CustomerGroup[]} groupList
   * @returns {CustomerGroupViewModel[]}
   */
  mapCustomerGroupsToViewModel = (groupList: CustomerGroup[], showCity = false, showState = false): CustomerGroupViewModel[] => {

    const mappedList: CustomerGroupViewModel[] = [];

    _.forEach(groupList, (group: CustomerGroup) => {
      const groupView: CustomerGroupViewModel = {
        country: group.country,
        groupId: group.groupId,
        name: group.name,
        creatorGid: group.creatorGid,
        type: group.type,
        // tslint:disable-next-line:ban
        countryLabel: this.translateService.instant(translationCountryPrefix + group.country),
        editable: false,
        toggleStatus: false,  // should be closed in the beginning
        showCity: showCity && group.customers.some(customer => !!customer.customerCity),
        showState: showState && group.customers.some(customer => !!customer.customerState),
        customers: _.cloneDeep(group.customers)
          .sort((a, b) => a.customerNumber.localeCompare(b.customerNumber))
      };

      mappedList.push(groupView);
    });

    return mappedList;
  }

  /**
   * @ngdoc method
   * @name getLocaleWithFallbackCheck
   *
   * @param lang
   * @param country
   * @param userCountries
   *
   * @description
   *
   * Get the locale value with fall back if user doesn't
   * have in allowed language, the locale from user.country and user.language.
   *
   */
  getLocaleWithFallbackCheck(lang, country, userCountries) {

    const listOfLangForCountry = this.getListOfAllowedLanguagesForUserCountry(userCountries, country);

    let locale = lang + localeSplitter + country;

    if (listOfLangForCountry) {
      // Check for country has locale in language array, if not return fallback value.
      if (!_.includes(listOfLangForCountry, locale)) {
        locale = fallBackLocale;
      }
    } else {
      locale = fallBackLocale;
    }

    return locale;
  }

  /**
   *
   * @param countries {Array | Object}
   * @param isObject {Boolean} Is input array or object
   * @returns {Array} (the title is already translated)
   *
   * @description
   * Generate country translation map for master impersonation
   */
  generateCountryTranslationMapping(countries: string[] | Country[] | Customer[] | CustomerGroup[], isObject: boolean): SelectOption[] {

    // translated countries
    let translatedCountries = [];
    let listOfCountries = [];

    if (isObject) {
      listOfCountries = this.filterUtilService.getListOfPropertyValuesFromListOfObject(countries, 'country');
    } else {
      listOfCountries = countries;
    }

    if (listOfCountries) {
      _.forEach(listOfCountries, (value) => {
        const translateObj = {
          // tslint:disable-next-line:ban
          title: this.translateService.instant(translationCountryPrefix + value),
          value
        };
        translatedCountries.push(translateObj);
      });
    }

    translatedCountries = _.orderBy(translatedCountries, ['title']);

    return translatedCountries;
  }

  getCountryTranslationMapping(countries: string[] | { country: string }[]): Observable<SelectOption[]> {
    const countryKeys: { key: string, code: string }[] = [];
    for (const country of countries) {
      const code = typeof country !== 'string' ? country.country : country;
      countryKeys.push({key: translationCountryPrefix + code, code});
    }

    return this.translateService.get(countryKeys.map(c => c.key)).pipe(
      map((translate: { [key: string]: string }) => {
        const countryOptions = countryKeys.map(({key, code}) => ({
          title: translate[key],
          value: code
        }));
        countryOptions.sort((a, b) => a.title.localeCompare(b.title));
        return countryOptions;
      })
    );
  }

  /**
   * @param dataSet | list of user countries -> /users/self
   * @param selectedCountry
   *
   * @description
   * Generate language translation map for master impersonation
   */
  generateLanguageTranslationMappingAndAddDefaultLang(dataSet,
    selectedCountry) {

    // pick the allowed languages for the user country
    const listOfLangForCountry =
      this.getListOfAllowedLanguagesForUserCountry(dataSet, selectedCountry);

    // translated languages
    const translatedLanguage = [];
    if (listOfLangForCountry) {
      _.forEach(listOfLangForCountry, value => {
        const translateObj = {title: '', value: ''};
        const lang = value.split(localeSplitter)[0]; // value -> de_DE
        translateObj.title = translationLanguagePrefix + lang.toUpperCase();
        translateObj.value = value;
        translatedLanguage.push(translateObj);
      });
    }

    /**
     * Add the default english if more than one countries.
     * Check if it already has an english locale, if not add
     * the fall back en_GLN.
     */
    if (dataSet.length > 1 && listOfLangForCountry) {
      let englishLangExisting = false;
      _.forEach(listOfLangForCountry, value => {
        const lang = value.split(localeSplitter)[0];
        if (_.isEqual(lang, 'en')) {
          englishLangExisting = true;
        }
      });

      if (!englishLangExisting) {
        translatedLanguage.push({
          title: 'LABEL_LANGUAGE_EN',
          value: fallBackLocale
        });
      }

    }

    return translatedLanguage;
  }

  /**
   * @description
   * Get the allowed languages for the country user is assigned to
   */
  getListOfAllowedLanguagesForUserCountry(countries, selectedCountry) {

    let list = [];

    const findObjectProperty = {country: selectedCountry};

    // pick the allowed languages for the user country
    const pickLanguagePropFromUserCountry =
      this.lifeNetUtils.pickPropertyFromObject(countries, findObjectProperty, 'language');

    if (pickLanguagePropFromUserCountry) {
      list = pickLanguagePropFromUserCountry;
    }
    return list;
  }

  /**
   * @param countries | list of countries for the user.
   * @param selectedCountry | selected country by user.
   * @returns selectedLocale
   *
   * @description
   * Get the default language for the country.
   *
   */
  getDefaultLanguageForCountry(countries, selectedCountry) {

    let selectedLocale = null;

    const findObjectProperty = {country: selectedCountry};

    // pick the default language for the selected country
    const defaultLanguage =
      this.lifeNetUtils.pickPropertyFromObject(countries, findObjectProperty, 'defaultLanguage');

    if (defaultLanguage) {
      selectedLocale = defaultLanguage;
    }

    return selectedLocale;
  }

  /**
   * Checks for PRIVATE/PUBLIC flag and sets the editable flag accordingly:
   * If group is a PUBLIC group, it is only editable for users with admin roles
   * If group is private, it should be owned by user (note that only groups owned
   * by this user are loaded, so another check for GID is not needed)
   */
  setItemEditableFlag(item, isUserAdmin) {

    item.editable = false;

    // check public
    if (item.type === 'PUBLIC' && isUserAdmin) {
      item.editable = true;
    }
    // check private
    if (item.type === 'PRIVATE') {
      item.editable = true;
    }
  }

  /**
   * @ngdoc method
   * @name setUserPreferenceCountry
   *
   * @description
   * Sets the users preferred country for loading customers or
   * customer groups
   */
  setUserPreferenceCountry(country: string) {
    this.preferredCountry = country;
  }

  /**
   * @ngdoc method
   * @name getUserPreferenceCountry
   *
   * @description
   * Get the preferred country for customer or customer groups loading
   */
  getUserPreferenceCountry(): string {
    return this.preferredCountry;
  }

  /**
   *
   * @param impersonatedCustomers | impersonated customers
   * @param impersonatedCustomerGroups | impersonated customer groups
   * @param toasterTimeOut | time out for showing toaster
   *
   * @description
   * On successful impersonation
   */
  onSuccessfulImpersonation() {
    this.translateService.get('IMPERSONATION_UPDATED').subscribe(title => {
      const message = {
        type: 'success',
        isBodyTranslationKey: false,
        body: title
      };

      this.toasterService.emitToast(message);
      this.router.navigate(['dashboard']);
      this.impersonationCommunicationService.emitCountryLanguageChange();
    });
  }

  /**
   *
   * @description
   * On stop impersonation
   */
  onStopImpersonation() {

    const message = {
      type: 'success',
      isBodyTranslationKey: true,
      body: 'IMPERSONATION_STOPPED'
    };

    // show a success toaster message with defined country configurable time out
    this.toasterService.emitToast(message);

    this.router.navigate(['dashboard']);
    // notify listeners for impersonation change
    this.impersonationCommunicationService.emitCountryLanguageChange();

  }

  getCustomerNumber(customerId: string) {
    return customerId ? customerId.split('_')[1] : '';
  }
}
