import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Announcement } from '../models/announcement';
import { HttpParams, HttpClient } from '@angular/common/http';
import { restEndPoint } from '../core-constants.service';
import { WindowService } from '../window.service';
import { DateUtilService } from '../utils/date-util.service';

export const announcementRestName = restEndPoint + 'announcement/';
export const announcementLastRestName = restEndPoint + 'announcement/last';
export const announcementTimeZonesRestName = restEndPoint + 'announcement/time-zones';
export const announcementCreateRestName = restEndPoint + 'announcement/create';


@Injectable()
export class AnnouncementRestService {

  constructor(private http: HttpClient,
              private dateUtilService: DateUtilService,
              private windowService: WindowService) {}

  getAnnouncement(): Observable<Announcement> {
    const hasIntl = typeof (this.windowService.nativeWindow.Intl) === 'object';
    const timeZone = hasIntl
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : this.dateUtilService.getTimeZone();

    return this.http.get<Announcement>(announcementRestName, {
      params: new HttpParams().set('timeZone', timeZone).set('date', new Date().getTime().toString())
    });
  }


  getLastAnnouncement(): Observable<Announcement> {
    return this.http.get<Announcement>(announcementLastRestName);
  }

  getLastAnnouncementByLanguage(language: string): Observable<Announcement> {
    const httpParams = new HttpParams()
      .set('language', language);
    return this.http.get<Announcement>(announcementLastRestName, {params: httpParams});
  }


  getTimeZones(): Observable<string[]> {
    return this.http.get<string[]>(announcementTimeZonesRestName);
  }

  createAnnouncement(body): Observable<any> {
    return this.http.post(announcementCreateRestName, body);
  }
}
