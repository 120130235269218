import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LogService } from '../services/log/log.service';
import { ToasterService } from '../component-communication-services/toaster/toaster.service';
import { HttpCacheService } from '../services/cache/http-cache.service';
import { HttpIgnoredErrors } from './http-ignored-errors';
import { HttpCacheNewService } from '../services/cache/http-cache-new.service';
import { environment } from '../../../environments/environment';
import { WindowService } from '../window.service';

const orderToOrderRequest = 'siemens.com/spe/data';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private window;

  constructor(private router: Router,
    private log: LogService,
    private toast: ToasterService,
    private cacheService: HttpCacheService,
    private httpCacheService: HttpCacheNewService,
    private httpIgnoredErrors: HttpIgnoredErrors,
    private windowService: WindowService
  ) {
    this.window = windowService.nativeWindow;
  }

  private catchErrors() {
    return (response: HttpErrorResponse) => {
      this.handleResponse(response);
      return throwError(response);
    };
  }

  handleResponse(response: HttpErrorResponse) {
    if (this.httpIgnoredErrors.shallBeIgnored(response)) {
      return;
    }
    this.handleResponseError(response);
  }

  private handleResponseError(response: HttpErrorResponse) {
    switch (response.status) {
      case 401:
        if (this.router.url !== '/welcome') {
          if (response.error && response.error.redirectToLogin) {
            this.window.location.href = response.error.loginUrl;
          } else {
            this.router.navigate(['/welcome']);
          }
        }
        break;

      case 403:
        // here the lifeNetUtilService cannot be used as this yields a circular dependency
        // check for self in url to find errors from /user/self or /user/self/roles
        // then logout session and show error message
        if (_.includes(response.url, 'self')) {
          // here the lifeNetUtilService cannot be used as this yields a circular dependency
          environment.newCacheableHttpClient ? this.httpCacheService.clearAll() :
            this.cacheService.clearAll();
          this.router.navigate(['/403']);
        }
        break;

      case 404:
        // handle order to order not found
        if (_.includes(response.url, orderToOrderRequest)) {
          this.log.warn('Order to order file not found');
        } else {
          this.toastError('FRONTEND_GENERIC_ERROR_MESSAGE');
        }
        break;

      // default case is anything else with status 400 or 500
      default:
        // show default error message
        let errorMessageKey = null;

        // special cases for performance report and excel report
        if (_.includes(response.url, 'AsPdf')) {
          errorMessageKey = 'FRONTEND_SERVICE_PERFORMANCE_REPORT_ERROR_MESSAGE';
        } else if (_.includes(response.url, 'generateExcel')) {
          errorMessageKey = 'FRONTEND_EXCEL_REPORT_ERROR_MESSAGE';
        }

        const handleUrls = [
          /activities\/.*\/reschedule/,
          /activities\/.*\/pmSchedule/,
          /customers/,
          /equipments\/.*\/deactivate/,
          /equipments\/.*\/components/,
          /equipments\/.*\/recommendation/,
          /equipments\/.*\/quote/,
          /equipments\/.*\/trial/,
          /favorites/,
          /iccode/,
          /messages\/markEventAsSeen/,
          /partnerOrders\/.*\/close/,
          /partnerOrders\/.*\/withdraw/,
          /psr/,
          /reports\/education/,
          /reports\/uptime/,
          /reports\/servicePerformance/,
          /reports\/remoteActivity/,
          /saOrder/,
          /system-updates\/exportExcel/,
          /tickets\/create/,
          /tickets\/update/,
          /tickets\/close/,
          /users\/self\/accept/,
          /users\/locale/
        ];

        for (const handleUrl of handleUrls) {
          if (response && response.url && response.url.match(handleUrl)) {
            errorMessageKey = 'FRONTEND_GENERIC_ERROR_MESSAGE';
          }
        }
        if (errorMessageKey !== null) {
          this.log.warn('error status: ' + response.statusText);
          this.toastError(errorMessageKey);
        }
        break;
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(this.catchErrors()));
  }

  toastError(message: string) {
    const toast = {
      type: 'error',
      isBodyTranslationKey: true,
      body: message
    };
    this.toast.emitToast(toast);
  }
}
