import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectOption } from '../../../../core/models/select-option';

@Component({
  selector: 'hl-system-update-information-form',
  templateUrl: './system-update-information-form.component.html'
})
export class SystemUpdateInformationFormComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  isFormSubmitted: boolean;
  
  @Input()
  isEditUpdate: boolean;

  @Input()
  asuStatusOptions: SelectOption[];

  ngOnInit(): void {
  }

  updateCyberSecuritySelection(value: boolean) {
    this.form.get('cyberSecurity').patchValue(value);
  }
}
