import { Injectable } from '@angular/core';
import { ViewportRuler } from '@angular/cdk/overlay';
import { CdkDrag, CdkDragMove, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';

@Injectable()
export class DragAndDropService {
  private target: CdkDropList;
  private targetIndex: number;
  private source: CdkDropList;
  private sourceIndex: number;
  private activeContainer: CdkDropList;

  constructor(private viewportRuler: ViewportRuler) {
  }

  static checkMobileUser(userNavigator): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userNavigator);
  }

  private indexOfCollectionNode = (collection, node) => Array.prototype.indexOf.call(collection, node);

  /** Determines whether an event is a touch event. */
  private isTouchEvent = (event: MouseEvent | TouchEvent): event is TouchEvent => event.type.startsWith('touch');

  private isInsideDropListClientRect = (dropList: CdkDropList, x: number, y: number) => {
    const {top, bottom, left, right} = dropList.element.nativeElement.getBoundingClientRect();
    return y >= top && y <= bottom && x >= left && x <= right;
  }

  /** Determines the point of the page that was touched by the user. */
  private getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // 'touches' will be empty for start/end events so we have to fall back to 'changedTouches'.
    const point = this.isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }

  resetProperties() {
    this.target = null;
    this.targetIndex = -1;
    this.source = null;
    this.sourceIndex = -1;
    this.activeContainer = null;
  }

  dragMoved(e: CdkDragMove, dropListGroup: Set<CdkDropList>) {
    const point = this.getPointerPositionOnPage(e.event);

    dropListGroup.forEach((dropList: CdkDropList) => {
      if (this.isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  dropListDropped(placeholder: CdkDropList, array: any[], afterMoveInArray?: () => void) {
    if (!this.target) {
      return;
    }

    const phElement = placeholder.element.nativeElement;
    const parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(array, this.sourceIndex, this.targetIndex);
      if (afterMoveInArray) {
        afterMoveInArray();
      }
    }
  }

  dropListEnterPredicate(drag: CdkDrag, drop: CdkDropList, placeholder: CdkDropList) {
    if (drop === placeholder) {
      return true;
    }

    if (drop !== this.activeContainer) {
      return false;
    }

    const phElement = placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex = this.indexOfCollectionNode(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    const dropIndex = this.indexOfCollectionNode(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
      ? dropElement.nextSibling : dropElement));

    placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }
}
