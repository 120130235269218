import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { EquipmentComponentItem } from '../../../core/models/equipment/equipment-details';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';

@Component({
  selector: 'hl-edit-component-modal',
  templateUrl: './edit-component-modal.component.html'
})
export class EditComponentModalComponent extends BaseModalPopup {

  component: EquipmentComponentItem;
  @Input() equipmentKey: string;
  @Output() onChangeComponent = new EventEmitter<any>();

  // only for simulator check
  isFormSubmitted = false;

  constructor(private equipmentRestService: EquipmentRestService,
              renderer: Renderer2) {
    super(renderer);
  }

  showModal(component: EquipmentComponentItem) {
    this.component = component;
    this.show();
  }

  ok() {
    this.showSpinner = true;
    this.equipmentRestService.postEditComponent(this.component, this.equipmentKey).pipe(
      finalize(() => {
        this.hide();
      }))
      .subscribe(() => {
        this.onChangeComponent.emit();
      });
  }
}
