import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Contact } from '../../../core/models/contact';
import * as _ from 'lodash';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ContactEditorComponent),
  multi: true
};

const VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ContactEditorComponent),
  multi: true
};

/**
 * component to edit a contact (now used in options and upgrades)
 */
@Component({
  selector: 'hl-contact-editor',
  templateUrl: './contact-editor.component.html',
  providers: [MULTISELECT_VALUE_ACCESSOR, VALIDATOR]
})
export class ContactEditorComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  @Input() disabled = false;
  contactModel: Contact;

  @Input() isFormSubmitted = false;

  emailValidationRegex = '';
  emailLength = '';
  emailValidationRegexObj: RegExp;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private configService: CountryConfigRestService) {
  }

  ngOnInit() {
    this.init();
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.setConfigProperties(configResponse);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.initProperties();
  }

  initProperties() {
    this.contactModel = {
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      contactPhone: ''
    };
  }

  setConfigProperties(config) {
    this.emailValidationRegex = config.EMAIL_VALIDATION_REGEX;
    this.emailLength = config.EMAIL_VALIDATION_LENGTH;
    this.emailValidationRegexObj = new RegExp(this.emailValidationRegex);
  }

  // called, when this component decides, that there is a contact entered
  newContactSet(): void {
    this.onModelChange(this.contactModel);
    this.onModelTouched(this.contactModel);
  }

  onSingleItemChanged() {
    this.newContactSet();
  }

  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  /**
   * called if the value of the parent component changes
   * @param value
   */
  writeValue(value: Contact): void {

    if (value) {
      this.contactModel = _.clone(value);
      this.newContactSet();
    } else if (value == null) {
      this.contactModel.contactPhone = '';
      this.contactModel.contactEmail = '';
      this.contactModel.contactFirstName = '';
      this.contactModel.contactLastName = '';

      this.newContactSet();
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validate(c: FormControl): ValidationErrors {
    if (this.contactModel.contactFirstName.trim().length > 0 &&
      this.contactModel.contactLastName.trim().length > 0 &&
      this.emailValidationRegexObj.exec( this.contactModel.contactEmail ) ) {
      // all elements are valid - so the whole is valid (indicated by not returning an error)
      return null;
    }

    return {
      invalid: {
        given: c.value
      }
    };
  }
}
