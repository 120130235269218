import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MessagingCenterService {

  private refreshMessagingCenter: Subject<void> = new Subject();

  refreshMessagingCenter$ = this.refreshMessagingCenter.asObservable();

  emitRefreshMessagingCenter() {
    this.refreshMessagingCenter.next();
  }
}
