import { Injectable } from '@angular/core';
import { WindowService } from '../window.service';
import { restEndPoint } from '../core-constants.service';
import { ToasterService } from '../component-communication-services/toaster/toaster.service';

@Injectable()
export class DownloadUtilService {

  private readonly notAvailable = 'NOT-AVAILABLE';

  constructor(
    private windowService: WindowService,
    private toast: ToasterService) {
  }

  generateBlobAndDownload(data: any, contentType: string, fileName: string) {

    const file = new Blob([data], {type: contentType});

    if (this.windowService.nativeWindow.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(file, fileName);
    } else {

      const objectUrl = this.windowService.nativeWindow.URL.createObjectURL(file);

      const downloadContainer = document.createElement('div');
      downloadContainer.setAttribute('data-tap-disabled', 'true');

      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', objectUrl);
      downloadLink.setAttribute('download', fileName);
      downloadLink.setAttribute('target', '_blank');

      downloadContainer.appendChild(downloadLink);

      document.body.appendChild(downloadContainer);

      setTimeout(() => {
        downloadLink.click();
        downloadLink.remove();
      }, null);
    }
  }

  validate(url: string, isAccessible: boolean = true) {
    if (isAccessible === undefined) {
      isAccessible = true;
    }
    if (url === this.notAvailable || !isAccessible) {
      const toast = {
        type: 'error',
        isBodyTranslationKey: true,
        body: 'FRONTEND_GENERIC_ERROR_MESSAGE'
      };
      this.toast.emitToast(toast);
      return false;
    }
    return true;
  }

  downloadFileByUrl(filename: string, url: string, isAccessible: boolean = true) {
    if (!this.validate(url, isAccessible)) {
      return;
    }
    const isIE = !!this.windowService.nativeWindow.navigator.msSaveBlob;
    const tab = isIE && this.windowService.nativeWindow.open('', 'download');

    if (isIE) {
      tab.open(url, 'download', '', true);
      return;
    }

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', filename);
    downloadLink.setAttribute('target', '_blank');
    document.body.appendChild(downloadLink);

    setTimeout(() => {
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }, null);
  }

  generateDownloadLink(type: string, attachment: { id: string, name: string, payload: string, available?: boolean }): string {
    if (attachment.available === undefined) {
      attachment.available = true;
    }
    return attachment.available ?
      restEndPoint + type + '/' + attachment.id + '/urlAttachments/' +
      encodeURIComponent(attachment.name) + '?u=' + encodeURIComponent(attachment.payload) :
      this.notAvailable;
  }
}
