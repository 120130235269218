import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'addPercent'
})
export class AddPercentPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let token = value;

    if (!_.isNaN(parseFloat(value))) {
      token = parseFloat(value).toFixed(1);
      const split = token.split('.');
      if (_.parseInt(split[1]) === 0) {
        token = split[0];
      }
    }
    return token + '%';
  }
}
