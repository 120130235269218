import { StateDownModalComponent } from '../../modal-popup/state-down-modal/state-down-modal.component';
import { DangerForPatientModalComponent } from '../../modal-popup/danger-for-patient-modal/danger-for-patient-modal.component';
import { TicketTypes } from '../../../core/models/tickets/ticket-types';
import { TicketsRestService } from '../../../core/rest-services/tickets-rest.service';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { FormGroup } from '@angular/forms';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as _ from 'lodash';
import { TicketTypeProblemSeverityRel } from '../../../core/models/tickets/modality-type-ticket-type-rel';
import { EquipmentViewModel } from '../../../core/view-models/equipment-view-model';
import { CentriCareSeverities, DangerForPatients, EquipmentModalityType, mgRequestUndefined } from '../../../core/core-constants.service';
import { CreateTicketEventService } from '../../../core/component-communication-services/create-ticket-event/create-ticket-event.service';
import { SelectOption } from '../../../core/models/select-option';
import { LogService } from '../../../core/services/log/log.service';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EquipmentDetails } from '../../../core/models/equipment/equipment-details';

@Component({
  selector: 'hl-create-ticket-detail-area',
  templateUrl: './create-ticket-detail-area.component.html'
})
export class CreateTicketDetailAreaComponent implements OnInit, OnChanges, OnDestroy {
  // we will pass in detail from Create component
  @Input()
  detailsForm: FormGroup;
  @Input()
  isFormSubmitted: boolean;
  @Input()
  equipment: EquipmentViewModel;
  @Input()
  isCentriCareActive = false;
  @Input()
  equipmentDetails: EquipmentDetails;
  @Input()
  loadingEquipmentDetails = false;
  @Output()
  operationalStateChanged = new EventEmitter();

  @ViewChild('dfpModal', { static: false })
  dfpModal: DangerForPatientModalComponent;
  @ViewChild('stateDownModal', { static: false })
  stateDownModal: StateDownModalComponent;

  showDangerForPatientsFromConfig = false;
  showDangerForPatientsFromEquipmentType = false;
  showDangerForPatientsFromTicketType = false;
  showMgRequestFromTicketTypes = false;
  overtimeAuthorizationEnabled = false;
  isDfpInfoActive = false;
  isStateDownInfoActive = false;
  showStateDownWarning = false;
  commaSeparatedAllowedDfpTicketTypes: string[] = [];
  selectedTicketType: string;

  ticketTypes: TicketTypes[] = [];
  dropdownOptions: DropdownOptions[] = [];
  components = [];
  operationalStateTickets = [];
  dfpOptions: SelectOption[] = [];
  mgRequestOptions: SelectOption[] = [];
  mgRequestTicketTypes: string[] = [];
  equipmentModalityType: string;
  selectedTicketTypeSeverityRelations: TicketTypeProblemSeverityRel[];
  maxLengthTicketDescription: string;
  maxLengthTicketShortDescription: string;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  private readonly inVitroEquipmentType = 'INVITRO';

  constructor(
    private configService: CountryConfigRestService,
    private ticketsUtilService: TicketsUtilService,
    private ticketsRestService: TicketsRestService,
    private createTicketEventService: CreateTicketEventService,
    private logService: LogService,
    private equipmentUtilService: EquipmentUtilService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Note: When the modal is opened second time for another item, it shows same item content as before
    // hence we call again init().
    if (changes['equipment'] && !changes['equipment'].firstChange) {
      this.init();
    }
    if (changes['equipmentDetails'] || changes['loadingEquipmentDetails']) {
      this.mapComponents();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.selectedTicketType = null;
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.setConfigProperties(configResponse);
    });
  }

  setConfigProperties(config: any) {
    this.maxLengthTicketDescription =
      config.MAX_LENGTH_TICKET_DETAIL_DESCRIPTION;
    this.maxLengthTicketShortDescription =
      config.MAX_LENGTH_TICKET_SHORT_DESCRIPTION;

    // check for render danger for patient
    const dangerForPatient = config.TICKET_CREATION_DANGER_FOR_PATIENT_RENDER;
    if (_.isEqual(dangerForPatient, 'true')) {
      // show the dangerForPatients -> 1st criteria
      this.showDangerForPatientsFromConfig = true;
    }

    // check the default value for danger for patient
    const defaultDanger = config.TICKET_DANGER_FOR_PATIENT_DEFAULT_VALUE;
    if (_.isEqual(defaultDanger, 'true')) {
      // set in form data correct value
      this.detailsForm.patchValue({ dangerForPatient: DangerForPatients.TRUE });
    }

    this.commaSeparatedAllowedDfpTicketTypes = _.split(
      config.DANGER_FOR_PATIENT_ALLOWED_TICKET_TYPES,
      ','
    );
    this.showStateDownWarning = _.isEqual(
      config.SHOW_TICKET_STATE_DOWN_WARNING,
      'true'
    );
    this.overtimeAuthorizationEnabled = _.isEqual(
      config.SHOW_OVERTIME_AUTHORIZATION,
      'true'
    );

    this.handleDfpBasedOnEquipmentType();
    this.handleOnSelectedTicketType();

    // should be initialized before loading the ticket types
    this.ticketTypes = [];
    this.dropdownOptions = [];
    if (this.equipment) {
      this.equipmentModalityType = this.ticketsUtilService.getEquipmentModalityType(
        this.equipment.modality,
        config
      );
    }
    // load the ticket types
    this.loadTicketTypes();

    this.setDfpOptions(config);

    // loading operational state (problem severity)
    this.operationalStateTickets = [];
    this.loadOperationalState();

    this.setMgRequestTicketTypesAndOptions(config);
  }

  /**
   * Sets the ticket types(e.g. MS, MS6) options based upon Selected modality type (e.g. DEFAULT, INVITRO) of equipment.
   * On select of ticket type it sets the ticketType and problemSeverity relations to global variable (selectedTicketTypeSeverityRelations),
   * which is later used to set problem severity options on selection of ticket type.
   *
   * @description
   * Get the ticket types mapping
   */
  loadTicketTypes() {
    if (this.equipment) {
      this.ticketsRestService
        .getTicketTypesAndSeverityOptions()
        .subscribe(options => {
          let ticketTypesAndSeverityOptions = _.find(options, {
            modalityType: this.equipmentModalityType
          });
          if (_.isEmpty(ticketTypesAndSeverityOptions)) {
            ticketTypesAndSeverityOptions = _.find(options, {
              modalityType: EquipmentModalityType.DEFAULT
            });
          }
          if (_.isEmpty(ticketTypesAndSeverityOptions)) {
            this.logService.error('Possibly modality type DEFAULT not in DB');
          }
          this.selectedTicketTypeSeverityRelations =
            ticketTypesAndSeverityOptions.ticketTypesSevRel;
          _.forEach(this.selectedTicketTypeSeverityRelations, ticketTypeRel => {
            if (ticketTypeRel.ticketType) {
              this.ticketTypes.push(ticketTypeRel.ticketType);
            }
          });
          this.loadDropdownOptions();
        });
    }
  }

  loadDropdownOptions() {
    this.dropdownOptions = this.ticketTypes.map(type => ({
        value: type.typeId,
        title: type.typeDescription
      }));
  }

  mapComponents() {
    if (this.equipment && this.equipmentDetails && this.equipmentDetails.components) {
      this.components = this.equipmentDetails.components.map(component => ({
        value: component.componentID,
        title: `${component.componentName}<br/>${component.componentSerialNumber}, ${component.componentOwnID}`
      }));
      this.components.sort((a, b) => a.title.localeCompare(b.title));
      this.components.push({value: null, title: '&nbsp;<br>&nbsp;'});
    } else {
      this.components = null;
    }
    this.changeDetector.detectChanges();
  }

  loadOperationalState() {
    if (this.equipment) {
      this.ticketsUtilService
        .getOperationalStatesAsOption(
          this.selectedTicketTypeSeverityRelations,
          this.selectedTicketType
        )
        .subscribe(problemSeverityResponse => {
          this.operationalStateTickets =
            problemSeverityResponse
              .filter(o => this.isCentriCareActive ? o.value !== CentriCareSeverities.PARTIALLY_OPERATIONAL
                : ![CentriCareSeverities.PARTIALLY_OPERATIONAL_CRITICAL,
                  CentriCareSeverities.PARTIALLY_OPERATIONAL_NON_CRITICAL].includes(o.value));
        });
    }
  }

  /**
   * @description
   * Show/Hide danger for patient based on equipment type
   * i.e. LdEquipment
   */
  handleDfpBasedOnEquipmentType() {
    if (this.equipment) {
      const isLdEquipment = this.equipmentUtilService.checkIsLdEquipment(
        this.equipment.key
      );
      this.showDangerForPatientsFromEquipmentType = !isLdEquipment;

      // set the form value to false, it is required for BE.
      if (isLdEquipment) {
        this.detailsForm.patchValue({
          dangerForPatient: DangerForPatients.FALSE
        });
      }

      // if dfp is preselected directly handle modal opening
      if (
        _.isEqual(
          this.detailsForm.get('dangerForPatient').value,
          DangerForPatients.TRUE
        )
      ) {
        this.handleDfpChange();
      }
    }
  }

  onTicketTypeChange() {
    this.handleOnSelectedTicketType();
    this.loadOperationalState();
    this.createTicketEventService.emitTicketTypeChanged(
      this.detailsForm.get('typeID').value
    );
  }

  onComponentChange() {
    if (!this.detailsForm.get('componentID').value) {
      this.detailsForm['componentText'] = null;
      return;
    }
    const selectedComponent = this.components.filter(component => component.value === this.detailsForm.get('componentID').value);
    if (selectedComponent && selectedComponent.length > 0) {
      this.detailsForm['componentText'] = selectedComponent[0].title.replace('<br/>', ', ');
    }
  }

  /**
   * called initially when the config is set and called, when the user changes the value
   * in the ticketType dropDown.
   * This method displays or hides the danger for patients depending on the selected ticket type.
   */
  handleOnSelectedTicketType() {
    this.showDangerForPatientsFromTicketType = _.includes(
      this.commaSeparatedAllowedDfpTicketTypes,
      this.detailsForm.get('typeID').value
    );
    if (!this.showDangerForPatientsFromTicketType) {
      this.detailsForm.patchValue({
        dangerForPatient: DangerForPatients.FALSE
      });
      this.handleDfpChange();
    }

    this.showMgRequestFromTicketTypes = _.includes(
      this.mgRequestTicketTypes,
      this.detailsForm.get('typeID').value
    );
    // validators required for mgRequestDropdown,
    // thus need to set with some value in some cases where it is not shown
    if (!this.showMgRequestFromTicketTypes) {
      this.detailsForm.patchValue({ mgRequest: mgRequestUndefined });
    } else {
      this.detailsForm.patchValue({ mgRequest: null });
    }
  }

  handleDfpChange() {
    // if dfp, open modal
    if (
      _.isEqual(
        this.detailsForm.get('dangerForPatient').value,
        DangerForPatients.TRUE
      )
    ) {
      this.dfpModal.show();
    } else {
      // reset dfp info
      this.isDfpInfoActive = false;
    }
  }

  setDfpInfo() {
    this.isDfpInfoActive = true;
  }

  handleStateDownChange() {
    // if state down and state down warning configured, open modal
    if (_.isEqual(this.detailsForm.get('problemSeverityID').value, '1') &&
      this.showStateDownWarning
    ) {
      this.stateDownModal.show();
    } else {
      // reset state down info
      this.isStateDownInfoActive = false;
    }
    this.operationalStateChanged.emit();
  }

  setStateDownInfo() {
    this.isStateDownInfoActive = true;
  }

  showDangerForPatient() {
    return (
      this.showDangerForPatientsFromConfig &&
      this.showDangerForPatientsFromEquipmentType &&
      this.showDangerForPatientsFromTicketType
    );
  }

  setDfpOptions(config: any) {
    this.dfpOptions = [];
    this.dfpOptions.push({
      value: DangerForPatients.TRUE,
      title: 'TICKET_FILTER_PATIENTSITUATIONCRITICAL_TRUE'
    });
    this.dfpOptions.push({
      value: DangerForPatients.FALSE,
      title: 'TICKET_FILTER_PATIENTSITUATIONCRITICAL_FALSE'
    });
    if (_.isEqual(config.DANGER_FOR_PATIENT_UNKNOWN_OPTION, 'true')) {
      this.dfpOptions.push({
        value: DangerForPatients.UNKNOWN,
        title: 'LABEL_DANGER_FOR_PATIENT_UNKNOWN'
      });
    }
  }

  setMgRequestTicketTypesAndOptions(config: any) {
    this.mgRequestOptions = [];
    const optionsAndValues = config.MG_REQUEST_TYPE_OPTION_VALUES.split(',');
    if (this.equipment && this.equipmentModalityType === this.inVitroEquipmentType) {
      const indexOfOther = optionsAndValues.findIndex(item => item === 'Other:#Other');
      if (indexOfOther > -1) {
         optionsAndValues.splice(indexOfOther, 1);
      }
    }
    _.forEach(optionsAndValues, option => {
      const optionAndValue = option.split('#');
      this.mgRequestOptions.push({
        value: optionAndValue[0],
        title: optionAndValue[1]
      });
    });

    this.mgRequestTicketTypes = config.MG_REQUEST_TICKET_TYPES.split(',');
  }

  selectDfpOption(value: any) {
    this.detailsForm.get('dangerForPatient').patchValue(value);
    this.handleDfpChange();
  }

  showOvertimeAuthorization(): boolean {
    return this.overtimeAuthorizationEnabled && !this.isCentriCareActive;
  }

  showProtectedCareHours(): boolean {
    return this.isCentriCareActive
      && _.isEqual(this.detailsForm.get('problemSeverityID').value, CentriCareSeverities.PARTIALLY_OPERATIONAL_NON_CRITICAL);
  }
}
