import { Injectable } from '@angular/core';
import { FilterUtilService } from './filter-util.service';
import { DocumentNode } from '../models/equipment/documents/document-node';
import * as _ from 'lodash';
import { DropdownOptions, NestedDropdownOption } from '../models/dropdown-options';
import { AdditionalFilterOptions, CollapsibleFilterObject } from '../../shared/filtering/filter-object';
import { DocumentFile } from '../models/equipment/documents/document-file';
import { FileSearchObject, SearchArrayLevelObject } from '../../shared/filtering/search/file-search-object';

@Injectable()
export class DocumentFilterUtilService {

  constructor(
    private filterUtilService: FilterUtilService
  ) {
  }

  private static objectHasNotEmptyProperty(objectToEvaluate: object, propertyNameToContain: string): boolean {
    return objectToEvaluate.hasOwnProperty(propertyNameToContain) && !!objectToEvaluate[propertyNameToContain];
  }

  /**
   * @description get the filtered list by applying single pipes/filter
   *
   * @param dataSet
   * @param filterObject
   * @param additionalOptions | additional options for some filtering functions that need them
   */
  getListAfterApplyingFilters(dataSet: any[], filterObject: CollapsibleFilterObject, additionalOptions?: AdditionalFilterOptions) {
    // store result to temporary variable to be worked on
    let filteredResult = dataSet.slice();

    if (dataSet && dataSet.length && _.isObject(filterObject)) {
      Object.keys(filterObject).forEach(filterKey => {
        filteredResult = this.applyIndividualFilter(filteredResult, filterObject, filterKey, additionalOptions);
      });
    }

    return filteredResult;
  }

  /**
   * @description apply different kind of filter to result set
   *
   * @param currentResultSet | dataset to filter
   * @param filterObject | filter object
   * @param key | filter name
   * @param additionalOptions | additional options for some filtering functions that need them
   */
  applyIndividualFilter(
    currentResultSet: DocumentNode[], filterObject: CollapsibleFilterObject, key: string, additionalOptions?: AdditionalFilterOptions
  ): DocumentNode[] {
    switch (key) {
      case 'fileSearch':
        return this.applyFileSearchFilter(currentResultSet, filterObject.fileSearch);
      case 'folderSelect':
        return (additionalOptions && additionalOptions.folderOptions) ?
          this.applyFolderSelectFilter(currentResultSet, filterObject.folderSelect, additionalOptions.folderOptions) :
          currentResultSet;
      case 'nestedFolderSelect':
        return (additionalOptions && additionalOptions.nestedFolderOptions) ?
          this.applyNestedFolderSelectFilter(currentResultSet, filterObject.nestedFolderSelect, additionalOptions.nestedFolderOptions) :
          currentResultSet;
      default:
        return this.filterUtilService.applyIndividualFilter(currentResultSet, filterObject[key] || {}, key);
    }
  }

  /**
   * @description apply file search filter either directly on objects in provided array or
   * deeply in arrays on specified path to the array through arrays
   *
   * @param resultSet
   * @param fileSearch
   */
  applyFileSearchFilter(resultSet: DocumentNode[], fileSearch: FileSearchObject): DocumentNode[] {
    if (fileSearch && DocumentFilterUtilService.objectHasNotEmptyProperty(fileSearch, 'searchValue')) {
      const workingSet = resultSet.slice();
      const splitSearchValues = fileSearch.searchValue.split(',');
      const shallowWorkingSet = this.applyFileSearchFilterShallowly(fileSearch, workingSet, splitSearchValues);
      const deepWorkingSet = this.applyFileSearchFilterDeeplyOnArrays(fileSearch, workingSet, splitSearchValues);
      return workingSet
        .filter(item => shallowWorkingSet.includes(item) || deepWorkingSet.some(filteredItem => filteredItem.name === item.name))
        .map(item => shallowWorkingSet.includes(item) ? item : deepWorkingSet.find(filteredItem => filteredItem.name === item.name));
    }
    return resultSet;
  }

  private applyFileSearchFilterShallowly(
    fileSearch: FileSearchObject, workingSet: DocumentNode[], splitSearchValues: string[]
  ): DocumentNode[] {
    const newWorkingSet = [];
    if (DocumentFilterUtilService.objectHasNotEmptyProperty(fileSearch, 'searchColumns')) {
      fileSearch.searchColumns.forEach(column =>
        workingSet.forEach(item => {
          if (item.hasOwnProperty(column) &&
            this.itemValueIncludesAnyOfSearchValues(
              fileSearch, splitSearchValues, item, column, fileSearch.compareFuncColumns
            ) && !newWorkingSet.includes(item)) {
            newWorkingSet.push(item);
          }
        })
      );
    }
    return newWorkingSet;
  }

  private itemValueIncludesAnyOfSearchValues(
    fileSearch, splitSearchValues: string[], item: object, column: string, compareFuncColumns?: string[]
  ): boolean {
    return (
      compareFuncColumns && compareFuncColumns.length && compareFuncColumns.some(columnName => columnName === column) &&
      DocumentFilterUtilService.objectHasNotEmptyProperty(fileSearch, 'compareFunc')
    ) ?
      splitSearchValues.some(value => !!value && !!item[column] && fileSearch.compareFunc(item[column], value)) :
      splitSearchValues.some(value => !!value && !!item[column] && item[column].toUpperCase().includes(value.toUpperCase()));
  }

  private applyFileSearchFilterDeeplyOnArrays(
    fileSearch: FileSearchObject, workingSet: DocumentNode[], splitSearchValues: string[]
  ): DocumentNode[] {
    let newWorkingSet = [];
    if (DocumentFilterUtilService.objectHasNotEmptyProperty(fileSearch, 'searchArrayLevels')) {
      let doFiltering = false;
      const clonedWorkingSet = _.cloneDeep(workingSet);
      fileSearch.searchArrayLevels.forEach((arrayLevel: SearchArrayLevelObject) => {
        if (DocumentFilterUtilService.objectHasNotEmptyProperty(arrayLevel, 'arrayPath') &&
          DocumentFilterUtilService.objectHasNotEmptyProperty(arrayLevel, 'searchColumns')) {
          const matchedObjectsWithArrayOnPath = this.getObjectsWithReferencesToArrayOnPath(arrayLevel, clonedWorkingSet);

          this.applyFileSearchFilterOnMatchedArrays(fileSearch, arrayLevel, matchedObjectsWithArrayOnPath, splitSearchValues);

          doFiltering = true;
        }
      });
      if (doFiltering) {
        newWorkingSet = clonedWorkingSet.filter(item => !!(item.list && item.list.length));
      }
    }
    return newWorkingSet;
  }

  private getObjectsWithReferencesToArrayOnPath(arrayLevel: SearchArrayLevelObject, workingSet: DocumentNode[]): any[] {
    let matchedObjectsWithArrayOnPath = workingSet;
    if (arrayLevel.arrayPath.includes('.')) {
      arrayLevel.arrayPath.slice(0, arrayLevel.arrayPath.lastIndexOf('.'))
        .split('.').forEach(arrayObjectIndex => {
        if (matchedObjectsWithArrayOnPath && matchedObjectsWithArrayOnPath.length) {
          const filteredMatchedArray = matchedObjectsWithArrayOnPath.filter(item =>
            DocumentFilterUtilService.objectHasNotEmptyProperty(item, arrayObjectIndex));
          matchedObjectsWithArrayOnPath = [];
          if (filteredMatchedArray && filteredMatchedArray.length) {
            filteredMatchedArray.forEach(item => matchedObjectsWithArrayOnPath.concat(item[arrayObjectIndex]));
          }
        }
      });
    }
    return matchedObjectsWithArrayOnPath;
  }

  private applyFileSearchFilterOnMatchedArrays(
    fileSearch: FileSearchObject, arrayLevel: SearchArrayLevelObject, matchedObjectsWithArrayOnPath: object[], splitSearchValues: string[]
  ): void {
    const lastArrayPathIndex = arrayLevel.arrayPath.includes('.') ?
      arrayLevel.arrayPath.slice(arrayLevel.arrayPath.lastIndexOf('.' + 1)) :
      arrayLevel.arrayPath;
    matchedObjectsWithArrayOnPath.forEach(item => {
      const arrayItemToFilter = (item as object)[lastArrayPathIndex].slice();
      (item as object)[lastArrayPathIndex] = [];
      arrayItemToFilter.forEach(arrayItem =>
        arrayLevel.searchColumns.forEach(column => {
          if (arrayItem.hasOwnProperty(column) &&
            this.itemValueIncludesAnyOfSearchValues(
              fileSearch, splitSearchValues, arrayItem, column, arrayLevel.compareFuncColumns) &&
            !(item as object)[lastArrayPathIndex].includes(arrayItem)) {
            (item as object)[lastArrayPathIndex].push(arrayItem);
          }
        })
      );
    });
  }

  /**
   * @description apply folder selection filter on resultSet to keep only items which path ends with specified selectedFolders
   * and filter objects in resultSet based on their list array property
   *
   * @param resultSet
   * @param selectedFolders
   * @param folderOptions
   */
  applyFolderSelectFilter(resultSet: DocumentNode[], selectedFolders: string[], folderOptions: DropdownOptions[]): DocumentNode[] {
    if (selectedFolders && folderOptions && selectedFolders.length !== 0 && selectedFolders.length !== folderOptions.length) {
      let workingSet = _.cloneDeep(resultSet);
      workingSet = this.applyFolderSelectFilterOnNodes(workingSet, selectedFolders);
      workingSet = this.applyFolderSelectFilterOnNodeLists(workingSet, selectedFolders);
      return workingSet;
    }
    return resultSet;
  }

  private getPathToMatch(document: DocumentFile, node: DocumentNode): string {
    return document.path ? (
      document.path.includes('/') ?
        document.path.slice(document.path.lastIndexOf('/') + 1) :
        document.path
    ) : node.name;
  }

  private applyFolderSelectFilterOnNodes(workingSet: DocumentNode[], filterValue: string[]): DocumentNode[] {
    return workingSet.filter(node => (node.list && node.list.length) ?
      !!node.list.find(document => filterValue.some(selectedOption => selectedOption === this.getPathToMatch(document, node))) :
      false);
  }

  private applyFolderSelectFilterOnNodeLists(workingSet: DocumentNode[], filterValue: string[]): DocumentNode[] {
    const slicedWorkingSet = workingSet.slice();
    slicedWorkingSet.forEach(node => {
      node.list = node.list.filter(document => filterValue.some(selectedOption => selectedOption === this.getPathToMatch(document, node))
      );
    });
    return slicedWorkingSet;
  }

  /**
   * @description apply folder selection filter on resultSet to keep only items which path ends with specified selectedFolders
   * and filter objects in resultSet based on their list array property
   *
   * @param resultSet
   * @param selectedFolders
   * @param folderOptions
   */
  applyNestedFolderSelectFilter(resultSet: DocumentNode[], selectedFolders: DropdownOptions[], folderOptions: NestedDropdownOption[]
  ): DocumentNode[] {
    if (selectedFolders && folderOptions && selectedFolders.length && folderOptions.length) {
      let workingSet = _.cloneDeep(resultSet);
      workingSet = workingSet
        .filter(node => selectedFolders.some(folder =>
          (folder.value.includes('/') ?
              folder.value.slice(0, folder.value.indexOf('/')) :
              folder.value
          ) === node.name));
      workingSet.forEach(node => node.list = node.list
        .filter(document => !document.path || selectedFolders.some(folder =>
          folder.value.includes('/') && (folder.value.slice(folder.value.indexOf('/') + 1) === document.path)
        )));
      return workingSet;
    }
    return resultSet;
  }
}
