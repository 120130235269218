import { AffectedEquipment } from '../models/systemUpdates/affected-equipment';
import { UpdateStatus } from '../models/update-status';
import { UpdateStatusColor } from './system-update-base-view-model';

export class AffectedEquipmentViewModel {

  public key: string;
  public siemensId: string;
  public productName: string;
  public myEquipmentName: string;
  public department: string;
  public dueDate: string;
  public updateStatus: string;

  constructor(model: AffectedEquipment) {
    this.key = model.key;
    this.siemensId = model.siemensId;
    this.productName = model.productName;
    this.myEquipmentName = model.myEquipmentName;
    this.department = model.department;
    this.dueDate = model.dueDate;
    this.updateStatus = model.updateStatus;
  }

  getEquipmentUpdateStatusColor(): UpdateStatusColor {
    switch (this.updateStatus) {
      case UpdateStatus.Pending:
        return UpdateStatusColor.Green;
      case UpdateStatus.Overdue:
        return UpdateStatusColor.Yellow;
      case UpdateStatus.LongOverdue:
        return UpdateStatusColor.Red;
      default:
        return UpdateStatusColor.Grey;
    }
  }

  getUpdateStatusKey(): string {
    return `SYSTEM_UPDATES_EQ_STATUS_${this.updateStatus}`;
  }
}
