export enum EmailFrequency {
  NEVER = 'NEVER',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export interface UserSettings {
  subprocessorNotificationSubscribed: boolean;
  emailFrequency: EmailFrequency;
  securityAdvisoriesSubscribed: boolean;
  securityAdvisoriesViewRole: boolean;
}
