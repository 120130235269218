import { Component } from '@angular/core';

@Component({
  selector: 'hl-direction-arrows',
  templateUrl: './direction-arrows.component.html'
})
export class DirectionArrowsComponent {

  constructor() {
  }
}
