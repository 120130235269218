import { Injectable } from '@angular/core';

const getWindow = (): any => window;

@Injectable()
export class WindowService {

    consoleLogEnabled = false;

    get nativeWindow(): any {
        return getWindow();
    }
}
