import { Pipe, PipeTransform } from '@angular/core';
import { contractAvailability } from '../../../core/core-constants.service';
import * as _ from 'lodash';

@Pipe({
  name: 'contractAvailability'
})
export class ContractAvailabilityPipe implements PipeTransform {

  transform(dataset: any, selectedValue: string): any {
    let filtered = dataset;

    if (_.includes(selectedValue, contractAvailability.contractAvailabilityYes)
        && _.includes(selectedValue, contractAvailability.contractAvailabilityNo)) {
      return filtered;
    }

    if (_.includes(selectedValue, contractAvailability.contractAvailabilityYes)) {
      filtered = _.filter(filtered, (item) => {
        return !_.isEmpty(item['contractType']);
      });
    }

    if (_.includes(selectedValue, contractAvailability.contractAvailabilityNo)) {
      filtered = _.filter(filtered, (item) => {
        return _.isEmpty(item['contractType']);
      });
    }
    return filtered;
  }
}
