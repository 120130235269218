import { Directive, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EnvironmentConfigRestService } from '../../../core/rest-services/environment-config-rest.service';

@Directive({
  selector: '[hlWalkMe]'
})
export class WalkMeDirective implements OnInit {

  constructor(
    private environmentConfigRestService: EnvironmentConfigRestService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document) {
  }

  ngOnInit() {
    this.environmentConfigRestService.getEnvironmentConfig().subscribe((configResponse) => {
      this.addWalkMe(configResponse.WALKME_CONFIGURATION_IDENTIFIER);
    });
  }

  addWalkMe(walkMeIdentifier: string) {
    if (!navigator.userAgent.includes('TestBot') && walkMeIdentifier && walkMeIdentifier.length > 0) {
      const script = this.renderer2.createElement('script');
      script.type = `text/javascript`;
      script.text = this.getWalkMeScript(walkMeIdentifier);
      this.renderer2.appendChild(this.document.body, script);
    }
  }

  getWalkMeScript(walkMeIdentifier: string) {
    let WALK_ME_SCRIPT = '(function() {var walkme = document.createElement(\'script\'); ';
    WALK_ME_SCRIPT += this.getWalkMeLanguageConfigurationScript();
    WALK_ME_SCRIPT += 'walkme.type = \'text/javascript\'; walkme.async = true; ';
    WALK_ME_SCRIPT += 'walkme.src = \'' + walkMeIdentifier + '\'; ';
    WALK_ME_SCRIPT += 'var s = document.getElementsByTagName(\'script\')[0]; ';
    WALK_ME_SCRIPT += 's.parentNode.insertBefore(walkme, s); ';
    WALK_ME_SCRIPT += 'window._walkmeConfig = {smartLoad:true}; })();';
    return WALK_ME_SCRIPT;
  }

  /**
   * Language setting
   */
  getWalkMeLanguageConfigurationScript() {
    let WALK_ME_GET_LANGUAGE =  'window.walkme_get_language = function (){ ';
    WALK_ME_GET_LANGUAGE +=     'if (window.walkMeLanguage) { return window.walkMeLanguage }';
    WALK_ME_GET_LANGUAGE +=     ' else {  return \'\' }';
    WALK_ME_GET_LANGUAGE +=   '}; ';
    return WALK_ME_GET_LANGUAGE;
  }

}
