import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class UpdateTicketEventService {

  // Observable updateTicket sources
  private updateTicketSource = new Subject<any>();

  // Observable updateTicket streams
  updateTicketSource$ = this.updateTicketSource.asObservable();

  constructor() { }

  // Service updateTicket -> (ticket update or ticket close)
  emitTicketUpdated() {
    this.updateTicketSource.next();
  }

}
