import { LogoutService } from '../../../core/component-communication-services/logout/logout.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hl-iframe-logout',
  templateUrl: './iframe-logout.component.html'
})
export class IframeLogoutComponent implements OnInit {

  isIFrameShown = false;
  logoutUrl: string;

  constructor(private logoutService: LogoutService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    // subscribe to subject stream when user logs out
    this.logoutService.logoutSource$.subscribe((logout) => {
      this.isIFrameShown = true;
      this.logoutUrl = logout.logoutUrl;

      // some browsers are slow, hence just hiding 1s
      setTimeout(() => {
        this.isIFrameShown = false;
      }, 500);
    });
  }
}
