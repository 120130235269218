import { Customer } from '../app/core/models/customer/customer';
import { ImpersonatedCustomers } from '../app/core/models/impersonation/impersonated-customers';
import { CustomerContacts } from '../app/core/models/customer/customer-contacts';
import { MyCustomer } from '../app/core/models/customer/my-customer';

// new last item in object
export const CdDECustomer: Customer = {
  customerId: 'DE_0034358465',
  country: 'DE',
  customerName: '   A.d.ö.R.',
  city: null,
  customerNumber: ''
};

export const CdTRCustomer: Customer = {
  customerId: 'TR_0961819266',
  country: 'TR',
  customerName: 'YÜCELEN ÖZEL SAĞLIK HİZMETLERİ AŞ',
  city: 'MERKEZ - MUĞLA',
  customerNumber: ''
};

export const CdUSCustomer: Customer = {
  customerId: 'US_0000084528',
  country: 'US',
  customerName: 'PHOENIX INDIAN MEDICAL CENTER',
  city: 'PHOENIX',
  customerNumber: ''
};

export const CdImpersonatedCustomers: ImpersonatedCustomers = {
  customers: [CdDECustomer, CdTRCustomer, CdUSCustomer],
  customerGroups: []
};

export const CdCustomerContacts: CustomerContacts[] = [
  {
    firstName: 'Richard',
    lastName: 'Roe',
    gid: 'XA002KSA',
    phone: '+49-123-1231232',
    email: 'test@email.com',
    salutation: 'ms',
    title: 'Dr.'
  },
  {
    firstName: 'Laura',
    lastName: 'Roe',
    gid: 'XA002KRZ',
    phone: '+49-321-5555555',
    email: 'laura@test.com',
    salutation: 'ms',
    title: null
  },
  {
    firstName: 'Jonathan',
    lastName: 'Doe',
    gid: 'XA0035WZ',
    phone: null,
    email: 'jonathandoe@test.com',
    salutation: null,
    title: null
  }
];

export const CdMyCustomersList: MyCustomer[] = [
  {
    customerNumber: '0SIMULATOR',
    customerName: 'Siemens Healthineers 1',
    city: 'Erlangen',
    street: 'Henri-Dunant Str. 50',
    postalCode: '91058',
    state: '',
    myCustomer: true
  },
  {
    customerNumber: '1SIMULATOR',
    customerName: 'Siemens Healthineers 2',
    city: 'Forchheim',
    street: 'Siemensstr. 3',
    postalCode: '91301',
    state: '',
    myCustomer: true
  },
  {
    customerNumber: '2SIMULATOR',
    customerName: 'Siemens Healthineers 3',
    city: 'Erlangen',
    street: 'Hartmannstr. 16',
    postalCode: '91052',
    state: '',
    myCustomer: false
  }
];
