import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TicketsRoutingModule } from './tickets-routing.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TicketsComponent } from './tickets.component';
import { TicketDetailOverviewTabComponent } from './ticket-details/ticket-detail-overview-tab/ticket-detail-overview-tab.component';
import { TicketDetailEventLogsTabComponent } from './ticket-details/ticket-detail-event-logs-tab/ticket-detail-event-logs-tab.component';
import { TicketDetailHistoryAndEventLogsComponent } from './ticket-details/ticket-detail-history-and-event-logs/ticket-detail-history-and-event-logs.component';
import { TicketDetailDeliveryMilestonesTabComponent } from './ticket-details/ticket-detail-delivery-milestones-tab/ticket-detail-delivery-milestones-tab.component';

@NgModule({
  imports: [SharedModule, TicketsRoutingModule, InfiniteScrollModule],
  declarations: [
    TicketsComponent,
    TicketDetailOverviewTabComponent,
    TicketDetailEventLogsTabComponent,
    TicketDetailHistoryAndEventLogsComponent,
    TicketDetailDeliveryMilestonesTabComponent
  ]
})
export class TicketsModule {}
