import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { CheckPermissionOrRoleService, DASHBOARD } from '../check-permission-or-role.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpdatesAuthGuardService implements CanActivate {

  constructor(private router: Router,
    private checkPermissionOrRoleService: CheckPermissionOrRoleService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkPermissionOrRoleService.showSystemUpdatesTab.pipe(
      tap((result) => {
        if (!result) {
          this.router.navigate([DASHBOARD], {replaceUrl: true});
        }
      }));
  }
}
