import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { restEndPoint } from '../core-constants.service';
import { Observable } from 'rxjs';
import { IcCode } from '../models/ic-code';
import { AsuFeedbackResult } from '../models/systemUpdates/asu-feedback-result';

const icCodeFeedbackRestName = restEndPoint + 'iccode/asuFeedback';

@Injectable()
export class IcCodeRestService {

  constructor(private http: CacheableHttpClient) {
  }

  getAsuFeedback(icCode: IcCode): Observable<AsuFeedbackResult> {
    const body = {
      equipmentKey: icCode.equipmentKey,
      updateNumber: icCode.updateNumber,
      ciUiStatus: icCode.installStatus
    };
    return this.http.post<AsuFeedbackResult>(icCodeFeedbackRestName, body);

  }
}
