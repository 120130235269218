import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseEquipmentDetailListView } from '../../../core/base-class/base-equipment-detail-list-view';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionsUpgradesViewModel } from '../../../core/view-models/options-upgrades-view-model';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { defaultImagePath } from '../../../core/core-constants.service';
import { EquipmentConstantsService } from '../../../core/services/equipment/equipment-constants.service';
import * as _ from 'lodash';
import { AvailabilityOptions } from '../../../core/models/optionsUpgrades/availability-options';

import { OptionsGetTrialModalComponent } from 'app/shared/modal-popup/options-get-trial-modal/options-get-trial-modal.component';
import { OptionsGetQuoteModalComponent } from 'app/shared/modal-popup/options-get-quote-modal/options-get-quote-modal.component';
import { OptionsRecommendModalComponent } from 'app/shared/modal-popup/options-recommend-modal/options-recommend-modal.component';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'hl-equipment-options-upgrades',
  templateUrl: './equipment-options-upgrades.component.html'
})
export class EquipmentOptionsUpgradesComponent extends BaseEquipmentDetailListView<OptionsUpgradesViewModel> implements OnInit {

  @ViewChild('getRecommendModal', { static: false })
  getRecommendModal: OptionsRecommendModalComponent;
  @ViewChild('getQuoteModal', { static: false })
  getQuoteModal: OptionsGetQuoteModalComponent;
  @ViewChild('getTrialModal', { static: false })
  getTrialModal: OptionsGetTrialModalComponent;

  optionsUpgradesTlStatusAvailable = 'Available';
  optionsUpgradesOptionStatusAlreadyInstalled = 'AlreadyInstalled';

  optionsImageUrl = '';

  defaultImgSrc = '';

  // option status
  availabilityStates: AvailabilityOptions[];

  clinicalSpecialities: any[];
  selectedClinicalSpecialities = [];

  // to be handed over to the modals
  selectedOption: OptionsUpgradesViewModel = null;

  selectedDropDownList = {
    optionStatus: []
  };

  constructor(public lifeNetUtilService: LifeNetUtilService,
    configService: CountryConfigRestService,
    equipmentUtilService: EquipmentUtilService,
    activatedRoute: ActivatedRoute,
    private router: Router,
    private equipmentRestService: EquipmentRestService,
    private equipmentConstantsService: EquipmentConstantsService,
    filterUtilService: FilterUtilService
  ) {
    super(filterUtilService, configService, lifeNetUtilService, equipmentUtilService, activatedRoute);
  }

  ngOnInit() {
    super.init();
  }

  afterInitProperties(): void {
    this.defaultImgSrc = defaultImagePath;
    this.clinicalSpecialities = this.equipmentConstantsService.getClinicalFieldSkeleton();
    this.availabilityStates = this.equipmentConstantsService.getOptionsAvailabilitySkeleton();
    this.sortSkeleton = this.equipmentConstantsService.getSortSkeletonOptionsList();
    this.setSearchInputFromQueryParam(this.router);
  }

  afterConfigProperties(config: any): void {
    this.optionsImageUrl = config.OPTIONS_IMAGE_PRE_URL;

    this.equipmentRestService.getOptionsAndUpgrades(this.equipmentItem.key)
      .pipe(finalize(() => {
        this.isLoaded = true;
        this.onAdvancedFilterChange();
      }))
      .subscribe((list) => {
        this.rawList = _.clone(this.equipmentUtilService.mergeAndExtendOptionsUpgrades(list));
      });
  }

  getFilterObject(): any {
    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: [
          'groupName'
        ]
      },
      advancedMultiSelect: this.selectedDropDownList,
      clinicalFields: this.selectedClinicalSpecialities,
      orderBy: this.sortSkeleton.sortObject
    };
  }

  getPageSizeConfig(config): string {
    return config.OPTIONS_UPGRADES_LIST_PAGE_SIZE;
  }

  showGetRecommend() {
    this.getRecommendModal.show();
  }

  showGetQuote(option: OptionsUpgradesViewModel) {
    this.selectedOption = option;
    this.getQuoteModal.show();
  }

  showGetTrial(option: OptionsUpgradesViewModel) {
    this.selectedOption = option;
    this.getTrialModal.show();
  }

  getExportList = () => {
    const exportListViewModel = _.cloneDeep(this.listWithoutPagination);
    _.forEach(exportListViewModel, item => {
      const propertiesToMerge = [
        'siemensId',
        'productName'
      ];
      item = this.lifeNetUtilService.mergeObjects(this.equipmentItem, item, propertiesToMerge);
    });
    return exportListViewModel;
  }
}
