import { ChangeDetectorRef, Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseFormModalPopup } from '../../core/base-class/base-form-modal-popup';
import { CreateTicketEventService } from '../../core/component-communication-services/create-ticket-event/create-ticket-event.service';
import { ImpersonationCommunicationService } from '../../core/component-communication-services/impersonation/impersonation-communication.service';
import { ToasterService } from '../../core/component-communication-services/toaster/toaster.service';
import { contractTypes, DangerForPatients, green, mgRequestUndefined, red, yellow } from '../../core/core-constants.service';
import { Ticket } from '../../core/models/tickets/ticket';
import { CountryConfigRestService } from '../../core/rest-services/country-config-rest.service';
import { TicketsRestService } from '../../core/rest-services/tickets-rest.service';
import { TicketsCacheService } from '../../core/services/cache/tickets-cache.service';
import { ContractsUtilService } from '../../core/services/contracts/contracts-util.service';
import { EquipmentUtilService } from '../../core/services/equipment/equipment-util.service';
import { TicketsUtilService } from '../../core/services/tickets/tickets-util.service';
import { AttachmentUtilService } from '../../core/utils/attachment-util.service';
import { DateUtilService } from '../../core/utils/date-util.service';
import { FilterUtilService } from '../../core/utils/filter-util.service';
import { LifeNetUtilService } from '../../core/utils/life-net-util.service';
import { ContractsViewModel } from '../../core/view-models/contracts-view-model';
import { EquipmentViewModel } from '../../core/view-models/equipment-view-model';
import { TicketViewModel } from '../../core/view-models/ticket-view-model';
import { WindowService } from '../../core/window.service';
import { SearchFieldComponent } from '../../shared/components/search-field/search-field.component';
import { SpaceValidator } from '../../shared/validators/space.validator';
import { CreateTicketAvailabilityAreaComponent } from '../../shared/form-group/create-ticket-availability-area/create-ticket-availability-area.component';
import { EquipmentDetails } from '../../core/models/equipment/equipment-details';
import { CENTRICARE_EQUIPMENT_STATUS_CRITICAL } from '../../core/services/equipment/equipment-constants.service';

const translateKeys = {
  TICKET_CREATE_SUCCESSFUL: 'TICKET_CREATE_SUCCESSFUL',
  TICKET_CREATE_SUCCESSFUL_DOWN_STATE: 'TICKET_CREATE_SUCCESSFUL_DOWN_STATE',
  TICKET_CREATE_SUCCESSFUL_DOWN_STATE_CCC: 'TICKET_CREATE_SUCCESSFUL_DOWN_STATE_CCC',
  LABEL_OVERTIME_AUTHORIZATION_YES: 'LABEL_OVERTIME_AUTHORIZATION_YES',
  LABEL_SYSTEM_AVAILBALE: 'LABEL_SYSTEM_AVAILBALE'
};

@Component({
  selector: 'hl-create-ticket',
  templateUrl: './create-ticket.component.html'
})
export class CreateTicketComponent extends BaseFormModalPopup
  implements OnInit {
  @Input() equipmentToSelect: string;
  showTicketCreateSystemAvailabilityString = '';
  systemAvailabilityAllowedTypesString = '';
  modalityCodesSyngoString = '';
  defaultTimezoneCode = '';
  defaultTimezoneOffset = '';
  dateTimePattern = 'DD-MM-YYYY, HH:mm';
  maxLengthForLongText: number;
  equipmentVMList: EquipmentViewModel[];
  states: Subject<EquipmentViewModel[]> = new Subject<EquipmentViewModel[]>();
  showConfidentialDataConfirmation = false;
  showTicketAttachmentByEquipment = false;
  showTicketAttachmentByType = false;
  showRequestArea = false;
  isFormSubmitted = false;
  showValidationMessage = false;
  createTicketForm: FormGroup;
  selectedEquipment: EquipmentViewModel = null;
  searchInput: any;
  window: Window;
  equipmentContractsList: ContractsViewModel[];
  equipmentStatusColorMap = [];
  equipmentTicketsList: TicketViewModel[] | Ticket[] = [];
  datePattern = 'DDMMYYYY';
  equipmentDetails: EquipmentDetails;
  loadingEquipmentDetails = false;

  translationErrorMessage =
    'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';
  ticketLabelInProgress = 'TICKET_CREATION_IN_PROGRESS';

  contractGroupNumberRender: boolean;

  @ViewChild(SearchFieldComponent, { static: false }) searchField: SearchFieldComponent;
  @ViewChild(CreateTicketAvailabilityAreaComponent, { static: false }) availabilityArea: CreateTicketAvailabilityAreaComponent;

  translate: { [key: string]: string } = {};

  isCentriCareToggleEnabled = false;
  isCentriCareActive = false;
  isSiebelSubcomponentToggle = false;

  constructor(
    private fb: FormBuilder,
    private equipmentUtilService: EquipmentUtilService,
    private ticketsRestService: TicketsRestService,
    public lifeNetUtilService: LifeNetUtilService,
    private attachmentUtilService: AttachmentUtilService,
    private configService: CountryConfigRestService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private windowService: WindowService,
    private createTicketEventService: CreateTicketEventService,
    private ticketUtilService: TicketsUtilService,
    private ticketCacheService: TicketsCacheService,
    private contractsUtilService: ContractsUtilService,
    private changeDetector: ChangeDetectorRef,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private dateUtilsService: DateUtilService,
    private filterUtilService: FilterUtilService,
    renderer: Renderer2
  ) {
    super(renderer);
    this.window = this.windowService.nativeWindow;
    this.bodyId = 'create-ticket-modal-body';
  }

  ngOnInit() {
    this.init();
  }

  /**
   * Initialize the call stack
   */
  init() {
    this.emitUnsubscribe();
    this.initFormControl();

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  postFormData() {
    throw new Error('Method not implemented.');
  }

  /**
   * Initialize reactive form for create ticket
   */
  initFormControl() {
    const translate$ = this.translateService.get(Object.keys(translateKeys));
    const config$ = this.configService.getConfig();
    combineLatest([config$, translate$]).pipe(takeUntil(this.unsubscribe$)).subscribe(([config, translate]) => {
      this.showTicketCreateSystemAvailabilityString =
        config['SHOW_TICKET_CREATE_SYSTEM_AVAILABILITY'];
      this.systemAvailabilityAllowedTypesString =
        config['SYSTEM_AVAILABILITY_ALLOWED_TYPES'];
      this.modalityCodesSyngoString = config['MODALITY_CODE_SYNGO'];
      this.defaultTimezoneCode = config['COUNTRY_DEFAULT_TIMEZONE'];
      this.defaultTimezoneOffset =
        config['COUNTRY_DEFAULT_TIMEZONE_OFFSET'];
      this.maxLengthForLongText =
        config['MAX_LENGTH_TICKET_DETAIL_DESCRIPTION'];
      this.dateTimePattern = config['GENERIC_DATE_TIME_PATTERN'];
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_GREEN] = green;
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_RED] = red;
      this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_YELLOW] = yellow;

      this.showConfidentialDataConfirmation = _.isEqual(
        config.SHOW_CONFIDENTIAL_PATIENT_DATA_SECTION,
        'true'
      );

      this.datePattern = config.GENERIC_DATE_PATTERN;

      this.translate = translate;

      this.isCentriCareToggleEnabled = _.isEqual(
        config.TOGGLE_CENTRICARE,
        'true'
      );

      this.isSiebelSubcomponentToggle = _.isEqual(
        config.TOGGLE_SIEBEL_SUBCOMPONENT_TICKET_CREATION,
        'true'
      );

      if (this.isCentriCareToggleEnabled) {
        this.equipmentStatusColorMap[CENTRICARE_EQUIPMENT_STATUS_CRITICAL] = red;
      }

      this.createForm(config);
      this.afterConfigProperties(config);
      this.changeDetector.detectChanges();
    });
  }

  showSelectedEquipment() {
    if (this.equipmentToSelect) {
      this.selectedEquipment = _.find(this.equipmentVMList, {
        key: this.equipmentToSelect
      });
      this.onSelect(this.selectedEquipment);
    } else if (this.searchField) {
      this.searchField.setFocusOnInput();
    }
  }

  preselectEquipmentInDeeplink(equipmentKey: string) {
    this.equipmentUtilService
      .getEquipmentViewModelList()
      .subscribe(equipmentVMResponse => {
        this.equipmentVMList = equipmentVMResponse;
        if (equipmentKey) {
          this.selectedEquipment = _.find(this.equipmentVMList, {
            key: equipmentKey
          });
          this.onSelect(this.selectedEquipment);
        } else if (this.searchField) {
          this.searchField.setFocusOnInput();
        }
      });
  }

  hide() {
    this.selectedEquipment = null;
    this.searchInput = null;
    if (this.searchField) {
      this.searchField.resetAutocomplete();
    }
    this.initFormControl();
    super.hide();
  }

  showModalAndSetFields(equipmentKey) {
    super.show();
    this.setPrefilledFields();
    this.selectedEquipment = _.find(this.equipmentVMList, e => e.key === equipmentKey);
  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    this.createTicketEventService.changeTicketTypeSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      ticketTypeResponse => {
        this.handleViewPropertiesBasedOnTicketType(ticketTypeResponse);
        this.changeDetector.detectChanges();
      }
    );
    this.impersonationCommunicationService.onCountryLanguageChange$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.init();
      }
    );
    this.impersonationCommunicationService.onImpersonationChange$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.init();
        this.loadViewModel();
      }
    );
  }

  createForm(config) {
    // Email Validation
    const emailRegEx = new RegExp(config.EMAIL_VALIDATION_REGEX);
    const emailLength = _.parseInt(config.EMAIL_VALIDATION_LENGTH);

    // phone validation
    const phoneRegEx = new RegExp(config.PHONE_VALIDATION_REGEX);
    const phoneLength = _.parseInt(config.PHONE_VALIDATION_LENGTH);

    this.createTicketForm = this.fb.group({
      equipmentKey: [''],
      attachments: [[]],
      details: this.fb.group({
        typeID: [null, Validators.required],
        dangerForPatient: DangerForPatients.FALSE,
        description: ['', [Validators.required, Validators.maxLength(100), SpaceValidator.noWhiteSpace]],
        problemSeverityID: [null, Validators.required],
        mgRequest: [null, Validators.required],
        longText: [
          '',
          [Validators.required, Validators.maxLength(this.maxLengthForLongText), SpaceValidator.noWhiteSpace]
        ],
        optionalAuthorizeOvertime: [''],
        protectedCareHours: [''],
        componentID: [null]
      }),
      contact: this.fb.group({
        contactEmail: [
          '',
          [
            Validators.required,
            Validators.maxLength(emailLength),
            Validators.pattern(emailRegEx)
          ]
        ],
        contactFirstName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactLastName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactPhone: [
          '',
          [
            Validators.required,
            Validators.maxLength(phoneLength),
            Validators.pattern(phoneRegEx),
            SpaceValidator.noWhiteSpace
          ]
        ],
        contactSalutation: [''],
        contactTitle: ['']
      }),
      // if a field is mandatory, then the validator has to be set later (see: CreateTicketAvailabilityAreaComponent)
      availability: this.fb.group({
        cbAvailabilityForService: [''],
        date: [null],
        time: [{value: null, disabled: true}]
      }),
      request: this.fb.group({
        feedBack: ['email'],
        ownIncidentNumber: ['']
      }),
      confirmNoPatientData: this.fb.group({
        confirmed: [
          !this.showConfidentialDataConfirmation,
          [Validators.requiredTrue]
        ]
      }),
      mobileAddress: this.fb.group({
        type: ['existing'],
        addressId: [null],
        locationName: [''],
        street: [''],
        city: [''],
        state: [''],
        zip: [''],
        tzCode: [''],
        tzOffset: ['']
      })
    });

    // abstract method
    this.loadViewModel();
  }

  /**
   *
   * @param token
   * @description Typeahead search for matching list items.
   */
  private getMatchingResult(token: string): EquipmentViewModel[] {
    const search = {
      searchValue: token,
      searchColumns: [
        'productName',
        'myEquipmentName',
        'siemensId',
        'department',
        'street',
        'city'
      ]
    };
    return this.filterUtilService.applyIndividualFilter(
      this.equipmentVMList,
      search,
      'search'
    );
  }

  /**
   * @description toggle render of attachments, based on ticket type.
   *
   * @param {string} ticketType - emitted by CreateTicketEventService
   */
  handleViewPropertiesBasedOnTicketType(ticketType: string) {
    this.attachmentUtilService
      .checkTicketTypeAttachmentRender(ticketType)
      .subscribe((showAttachment: boolean) => {
        this.showTicketAttachmentByType = showAttachment;
      });
  }

  public onSelect(item: any): void {
    this.selectedEquipment = item;
    this.contractsUtilService
      .getEquipmentContracts(this.selectedEquipment.key, false)
      .subscribe(equipmentContracts => {
        this.equipmentContractsList = equipmentContracts;
        this.removeContractGroupNumberIfNotRender();
        const activeContract = equipmentContracts.find(c => _.isEqual(c.contractStatusId, '1'));
        this.isCentriCareActive =
          this.isCentriCareToggleEnabled && activeContract && _.isEqual(activeContract.contractTypeId, contractTypes.CENTRICARE);
        this.changeDetector.detectChanges();
      });
    this.ticketUtilService
      .getTicketsByEquipmentKey(this.selectedEquipment.key)
      .subscribe((ticketsResponse) => {
        this.equipmentTicketsList = ticketsResponse;
        this.changeDetector.detectChanges();
      });
    this.onSelectedEquipmentChanged();
    this.loadEquipmentDetails(this.selectedEquipment.key);
  }

  loadEquipmentDetails(key, clearCache?) {
    if (this.isSiebelSubcomponentToggle && key && key.toUpperCase().startsWith('X')) {
      this.loadingEquipmentDetails = true;
      this.changeDetector.detectChanges();
      this.equipmentUtilService.getDetailsById(key)
        .subscribe(detailsResponse => {
          if (!detailsResponse) {
            if (!clearCache) {
              this.equipmentUtilService.clearEquipmentDetailsCache(key);
              this.loadEquipmentDetails(key, true);
            }
          } else {
            this.equipmentDetails = this.componentEqualsEquipment(detailsResponse) ? null : detailsResponse;
            this.loadingEquipmentDetails = false;
            this.changeDetector.detectChanges();
          }
        });
    } else {
      this.equipmentDetails = null;
      this.loadingEquipmentDetails = false;
      this.changeDetector.detectChanges();
    }
  }

  componentEqualsEquipment(equipmentDetails: EquipmentDetails): boolean {
    if (equipmentDetails && equipmentDetails.components && equipmentDetails.components.length === 1) {
      return this.selectedEquipment.equipmentNumber === equipmentDetails.components[0].componentID;
    }
    return false;
  }

  /** needs to be called, when the user (or by route) the selected equipment changed */
  onSelectedEquipmentChanged() {
    this.createTicketForm.patchValue({
      equipmentKey: this.selectedEquipment.key
    });
    this.handleViewPropertiesBasedOnEquipmentType(this.selectedEquipment.key);
    this.changeDetector.detectChanges();
  }

  /**
   * @description
   * Show/Hide view properties based on equipment type
   * i.e. LdEquipment
   */
  handleViewPropertiesBasedOnEquipmentType(equipmentKey) {
    const isLdEquipment = this.equipmentUtilService.checkIsLdEquipment(
      equipmentKey
    );
    this.showRequestArea = !isLdEquipment;

    this.attachmentUtilService
      .checkTicketAttachmentRenderByEquipment(equipmentKey)
      .subscribe((showAttachment: boolean) => {
        this.showTicketAttachmentByEquipment = showAttachment;
      });
  }

  /**
   * disable the submit button, if no equipment is selected or if dangerForPatient is checked
   * @returns {boolean}
   */
  isSubmitFormDisabled(): boolean {
    return (
      !this.selectedEquipment || this.loadingEquipmentDetails ||
      _.isEqual(
        this.createTicketForm.controls.details.get('dangerForPatient').value,
        DangerForPatients.TRUE
      )
    );
  }

  /**
   * @description
   * set the state params when navigating to create ticket screen from another state
   */
  initFromQueryParams() {
    this.route.queryParams.subscribe(params => {
      // get siemens equipment id
      if (params['equipmentSiemensId']) {
        // find the equipment by siemens Id
        this.selectedEquipment = _.find(this.equipmentVMList, eq => eq.siemensId === params['equipmentSiemensId']);

        this.onSelectedEquipmentChanged();
        this.changeDetector.detectChanges();
      }
    });
  }

  /**
   * Clear the input/search term field for Typeahead
   */
  clearInput() {
    this.selectedEquipment = null;
    this.searchInput = '';
    this.createTicketForm.patchValue({equipmentKey: ''});
    this.equipmentDetails = null;
    this.loadingEquipmentDetails = false;
    this.changeDetector.detectChanges();
  }

  loadViewModel(): void {
    this.equipmentUtilService
      .getEquipmentViewModelList()
      .subscribe(equipmentVMResponse => {
        this.equipmentVMList = equipmentVMResponse;
        this.states.next(this.getMatchingResult(this.searchInput));
        this.initFromQueryParams();
      });
  }

  afterConfigProperties(config: any): void {
    this.setPrefilledFields();
    if (_.isEqual(config.CONTRACT_GROUP_NUMBER_RENDER, 'true')) {
      this.contractGroupNumberRender = true;
    }
  }

  setPrefilledFields() {
    const prefilledFields = this.ticketCacheService.getPrefilledFields();
    if (prefilledFields) {
      setTimeout(() => {
        this.createTicketForm.patchValue(prefilledFields);
        this.ticketCacheService.resetPrefilledFields();
      }, 250);
    }
  }

  postSuccessData(response: any): void {
    // show create ticket success message.
    let tktCreationMessage = '';

    if (
      this.equipmentUtilService.checkIsLdEquipment(this.selectedEquipment.key) &&
      _.isEqual(
        this.createTicketForm.controls.details.get('problemSeverityID').value,
        '1'
      )
    ) {
      tktCreationMessage =
        this.translate[translateKeys.TICKET_CREATE_SUCCESSFUL_DOWN_STATE] +
        '<br/>' +
        response['ticketNumber'] +
        '<br/>' +
        this.translate[translateKeys.TICKET_CREATE_SUCCESSFUL_DOWN_STATE_CCC];
    } else {
      tktCreationMessage =
        this.translate[translateKeys.TICKET_CREATE_SUCCESSFUL] +
        '<br/>' +
        response['ticketNumber'];
    }

    const message = {
      type: 'success',
      isBodyTranslationKey: false,
      body: tktCreationMessage
    };

    // show a success toaster message with defined country configurable time out
    this.toasterService.emitToast(message);
  }

  postErrorData(): void {
    // no special treatment (the error would be displayed anyway in toaster and then onFinally
    //  triggers the route back to the calling page)
  }

  /** called, when a post to the server returned (sucessfull or not) */
  onFinally(): void {
    this.hide();
    this.isFormSubmitted = false;
  }

  /* adding additional fields to the long text and cutting it, if too long */
  getComposedLongText(): string {
    let longText = this.createTicketForm.get('details.longText').value;

    if (this.createTicketForm.get('details.optionalAuthorizeOvertime').value) {
      longText =
        this.translate[translateKeys.LABEL_OVERTIME_AUTHORIZATION_YES] +
        '..' +
        longText;
    }

    if (
      this.ticketUtilService.shouldRenderSystemAvailabilityInTicketCreate(
        this.showTicketCreateSystemAvailabilityString,
        this.systemAvailabilityAllowedTypesString,
        this.modalityCodesSyngoString,
        this.getSelectedModality(),
        this.getSelectedNotifType()
      )
    ) {
      longText =
        this.translate[translateKeys.LABEL_SYSTEM_AVAILBALE] +
        ' ' +
        this.getTransformedDateTimeValues() +
        '..' +
        longText;
    }

    if (this.createTicketForm.get('details.componentID').value) {
      longText += '\nComponent: ' + this.createTicketForm.controls['details']['componentText'];
    }

    // cut, if too long
    longText =
      longText.length <= this.maxLengthForLongText
        ? longText
        : longText.substr(0, this.maxLengthForLongText);

    return longText;
  }

  /** @return the selected ticketType or '' */
  getSelectedNotifType(): string {
    if (this.createTicketForm.get('details.typeID')) {
      return this.createTicketForm.get('details.typeID').value;
    }
    return '';
  }

  /** @return the modality of the selected equipment or '' */
  getSelectedModality(): string {
    if (this.selectedEquipment) {
      return this.selectedEquipment.modality;
    }
    return '';
  }

  /**
   * Create tickets when form is valid.
   */
  createTicket() {
    this.isFormSubmitted = true;

    // If the form is valid, then make a REST (post call) to create a ticket
    if (this.createTicketForm.valid) {
      this.showValidationMessage = false;
      this.showSpinner = true;

      // Form value being stripped for BE request input
      let strippedFormValue = _.omit(this.createTicketForm.value, [
        'confirmNoPatientData',
        'availability',
        'details.optionalAuthorizeOvertime'
      ]);

      // Detail Area long text composed with availability area
      strippedFormValue['details']['longText'] = this.getComposedLongText();

      // Detail Area short text (description) with mg
      strippedFormValue = this.appendAndOmitMgRequest(strippedFormValue);

      // Mobile Area handling
      if (this.selectedEquipment.mobile) {
        this.setFormValueWithMobileAddress(strippedFormValue);
      } else {
        this.postCreateTicket(strippedFormValue);
      }
    } else {
      this.showValidationMessage = true;
    }
  }

  /**
   * Make the BE call with form values
   * @param formValue
   */
  postCreateTicket(formValue) {
    this.ticketsRestService
      .postCreateTicket(formValue).pipe(
      finalize(() => {
        this.onFinally();
      }))
      .subscribe(
        response => {
          this.postSuccessData(response);
          this.createTicketEventService.emitChangeEquipmentStatus(this.selectedEquipment,
            formValue['details']['problemSeverityID']);
        },
        () => {
          this.postErrorData();
        }
      );
  }

  /**
   * Generate the timezone if new address and add to form request to BE
   * @param formValue
   */
  setFormValueWithMobileAddress(formValue) {
    // if only new address then we make call to timeZone API
    if (_.isEqual(formValue['mobileAddress']['type'], 'new')) {
      const city = formValue['mobileAddress']['city'];
      const state = formValue['mobileAddress']['state'];

      this.ticketUtilService.getTimezone(city, state).subscribe(
        response => {
          if (response) {
            if (_.isEqual(response.meta.code, '200')) {
              if (
                response.data.addresses &&
                response.data.addresses.length >= 1
              ) {
                const tzCode = response.data.addresses[0].datetime.offset_tzab;
                const tzOffset =
                  response.data.addresses[0].datetime.offset_hours;
                this.appendTimeZoneAndPostCreate(
                  true,
                  formValue,
                  tzCode,
                  tzOffset
                );
              } else {
                /**
                 * Here the API return empty value when it fails but with 200 status code
                 */
                this.appendTimeZoneAndPostCreate(true, formValue, '', '');
              }
            } else {
              this.appendTimeZoneAndPostCreate(true, formValue, '', '');
            }
          } else {
            this.appendTimeZoneAndPostCreate(true, formValue, '', '');
          }
        },
        () => {
          this.appendTimeZoneAndPostCreate(true, formValue, '', '');
        }
      );
    } else {
      this.appendTimeZoneAndPostCreate(false, formValue);
    }
  }

  /**
   * Append timezone and make BE request
   * @param {boolean} useDefault
   * @param {string} tzCode
   * @param {string} tzOffset
   * @param formValue
   */
  appendTimeZoneAndPostCreate(
    useDefault: boolean,
    formValue,
    tzCode?: string,
    tzOffset?: string
  ) {
    // additional check if default values added if TimeZone API fails
    // else time zone value in request would be empty string
    if (useDefault) {
      if (_.isEmpty(tzCode) || _.isEmpty(tzOffset)) {
        formValue['mobileAddress']['tzCode'] = this.defaultTimezoneCode;
        formValue['mobileAddress']['tzOffset'] = this.defaultTimezoneOffset;
      } else {
        formValue['mobileAddress']['tzCode'] = tzCode;
        formValue['mobileAddress']['tzOffset'] = tzOffset;
      }
    }
    this.postCreateTicket(formValue);
  }

  /**
   * Append the mqRequest to the short text and omit it from the form.
   * @param {{}} formValue : base form to be send in request.
   * @returns {{}} form without mgRequest field.
   */
  appendAndOmitMgRequest(formValue: {}) {
    const mgRequest = this.createTicketForm.get('details.mgRequest').value;
    if (!_.isEqual(mgRequest, mgRequestUndefined)) {
      formValue['details']['description'] =
        mgRequest +
        ' ' +
        this.createTicketForm.get('details.description').value;
    }
    return _.omit(formValue, ['details.mgRequest']);
  }

  private getTransformedDateTimeValues() {
    let transformedDateTimeValues;

    if (
      this.createTicketForm.get('availability.cbAvailabilityForService')
        .value === 'immediately'
    ) {
      transformedDateTimeValues = this.dateUtilsService.toStringWithPattern(
        this.dateTimePattern,
        new Date()
      );
    } else if (
      this.createTicketForm.get('availability.cbAvailabilityForService')
        .value === 'onDate'
    ) {
      transformedDateTimeValues = this.dateUtilsService.toStringWithPattern(
        this.dateTimePattern,
        this.createTicketForm.get('availability.date').value,
        this.createTicketForm.get('availability.time').value
      );
    }
    return transformedDateTimeValues;
  }

  /**
   * Remove contractGroupNumber property
   * form contract list items if it should not be rendered
   */
  removeContractGroupNumberIfNotRender() {
    for (const contract of this.equipmentContractsList) {
      if (!this.contractGroupNumberRender) {
        delete (contract as ContractsViewModel).contractGroup;
      }
    }
  }

  trackByFn(index, item: ContractsViewModel) {
    return item['contractId'];
  }

  onSearchInputChange(searchString: string) {
    this.states.next(this.getMatchingResult(searchString));
  }

  onOperationalStateChange() {
    if (this.availabilityArea) {
      this.availabilityArea.recheckAvailability();
    }
  }

  private emitUnsubscribe() {
    if (this.unsubscribe$.observers.length > 0) {
      this.unsubscribe$.next();
    }
  }
}
