import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';
import { Ticket } from 'app/core/models/tickets/ticket';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { DateRange } from 'app/core/models/date-range';
import { DatePipeWrapperPipe } from 'app/shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { ActivitiesViewModel } from 'app/core/view-models/activities-view-model';
import { restEndPoint } from '../../../core/core-constants.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hl-download-attachment-zip',
  templateUrl: './download-attachment-zip.component.html'
})
export class DownloadAttachmentZipComponent implements OnInit, OnChanges, OnDestroy {
  batchDownloadTicketsRestName = restEndPoint + 'batch-download/tickets';
  batchDownloadActivitiesRestName = restEndPoint + 'batch-download/activities';

  items: { key: string, type: string, status: string }[] = [];

  @Input() set tickets(value: Ticket[]) {
    this.items = value.map(({typeID: type, ticketStatus: status, ticketKey: key}) => ({key, type, status}));
    this.endpoint = this.batchDownloadTicketsRestName;
  }

  @Input() set activities(value: ActivitiesViewModel[]) {
    this.items = value.map(({type, activityStatus: status, ticketKey: key}) => ({key, type, status}));
    this.endpoint = this.batchDownloadActivitiesRestName;
  }

  @Input() equipmentKey: string;
  @Input() dateRange: DateRange;

  private allowedTicketTypes: string[] = [];
  private closedTicketStatuses: string[] = [];
  private datePattern: string;

  showButton = false;
  endpoint: string;
  keys = '';
  dateRangeFormatted = '';

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private configService: CountryConfigRestService,
    private datePipeWrapperPipe: DatePipeWrapperPipe
  ) {
  }

  ngOnInit() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      this.showButton = isEqual(config.BATCH_SERVICE_REPORT_AVAILABLE, 'true');
      if (!this.showButton) {
        return;
      }

      this.closedTicketStatuses = config.STATUS_IDENTIFIER_CLOSED_NOTIFICATIONS.split(',');
      this.allowedTicketTypes = config.ALLOWED_TICKET_TYPE_BATCH_DOWNLOAD.split(',');
      this.datePattern = config.GENERIC_DATE_PATTERN;
      this.prepareDownload();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.showButton) {
      return;
    }

    if (Object.keys(changes).every(key => changes[key].isFirstChange())) {
      return;
    }

    this.prepareDownload();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  prepareDownload() {
    this.keys = this.items
      .filter(item => this.allowedTicketTypes.indexOf(item.type) !== -1)
      .filter(item => this.closedTicketStatuses.indexOf(item.status) !== -1)
      .map(item => item.key)
      .join(',');

    const dateFromFormatted = this.formatDate(this.dateRange && this.dateRange.fromDate);
    const dateToFormatted = this.formatDate(this.dateRange && this.dateRange.toDate);
    this.dateRangeFormatted = dateFromFormatted && dateToFormatted ? `${dateFromFormatted}_${dateToFormatted}` : '';
  }

  private formatDate(date: Date | null): string {
    return date instanceof Date
      ? this.datePipeWrapperPipe.transform(date.toISOString(), this.datePattern)
      : '';
  }
}
