import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfigurationSaveEventValue } from '../../models/service-metrics-configuration-save-event';

@Injectable()
export class SprSaveEvent {

  private serviceMetricsSaveSource = new Subject<ConfigurationSaveEventValue>();

  serviceMetricsSaveSource$ = this.serviceMetricsSaveSource.asObservable();

  emitSave(configurationSaveEventValue: ConfigurationSaveEventValue) {
    this.serviceMetricsSaveSource.next(configurationSaveEventValue);
  }
}
