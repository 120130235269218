import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hl-user-name',
  templateUrl: './user-name.component.html'
})
export class UserNameComponent implements OnInit, OnDestroy {

  @Input() configKey: string;

  formattedUserName: string;
  private unsubscribe$ = new Subject<void>();

  constructor(private configService: CountryConfigRestService,
    private userRestService: UserRestService) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.loadUserAndSetUserNameFormat(configResponse[this.configKey]);
    });
  }

  loadUserAndSetUserNameFormat(headerNameOrder) {

    this.userRestService.getUser().subscribe((userResponse) => {
      this.formattedUserName = headerNameOrder.replace(/(#FIRSTNAME#)/g, userResponse.firstName)
        .replace(/(#LASTNAME#)/g, userResponse.lastName);
    });
  }
}
