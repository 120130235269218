// ASU upload update
import { restEndPoint } from '../core-constants.service';

export const computeHeaders = restEndPoint + 'asu/computeHeaders';
export const prepareUpload = restEndPoint + 'asu/prepare';
export const updateExists = restEndPoint + 'asu/exists';
export const validateHowToFile = restEndPoint + 'asu/howToValidator';
export const validatePackageInfoFile = restEndPoint + 'asu/infoValidator';
export const commitUpload = restEndPoint + 'asu/commit';
export const rollbackUpload = restEndPoint + 'asu/rollback';
export const asuUploads = 'asu/uploads/';
export const uploadInfo = restEndPoint + asuUploads + ':uiNumber';
