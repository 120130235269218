import { ErrorCode } from '../../models/error-code';
import { WindowService } from '../../window.service';
import { Injectable } from '@angular/core';

@Injectable()
export class LogService {

  window = this.windowService.nativeWindow;
  noop = () => {
  }

  constructor(private windowService: WindowService) {
  }

  private get debugFn() {
    if (this.windowService.consoleLogEnabled) {
      // tslint:disable-next-line:no-console
      return console.debug.bind(console);
    }
    return this.noop;
  }

  private get errorFn() {
    if (this.windowService.consoleLogEnabled) {
      return console.error.bind(console);
    }
    return this.noop;
  }

  private get logFn() {
    if (this.windowService.consoleLogEnabled) {
      return console.log.bind(console);
    }
    return this.noop;
  }

  private get infoFn() {
    if (this.windowService.consoleLogEnabled) {
      // tslint:disable-next-line:no-console
      return console.info.bind(console);
    }
    return this.noop;
  }

  private get warnFn() {
    if (this.windowService.consoleLogEnabled) {
      return console.warn.bind(console);
    }
    return this.noop;
  }

  /**
   * public methods to access logging. Usage: e.g this.logService.error('something', error);
   *
   * @param {string} msg | error message
   * @param {*} [errorCode] | errorCode object
   * @memberof LogService
   */
  public debug(msg: string, errorCode?: ErrorCode) {
    this.logMessage(msg, 'debugFn', errorCode);
  }

  public error(msg: string, errorCode?: ErrorCode) {
    this.logMessage(msg, 'errorFn', errorCode);
  }

  public log(msg: string, errorCode?: ErrorCode) {
    this.logMessage(msg, 'logFn', errorCode);
  }

  public info(msg: string, errorCode?: ErrorCode) {
    this.logMessage(msg, 'infoFn', errorCode);
  }

  public warn(msg: string, errorCode?: ErrorCode) {
    this.logMessage(msg, 'warnFn', errorCode);
  }

  /**
   * composes the log message and delegates to given log member property
   *
   * @param {string} msg | message to get logged
   * @param {string} loggingFn | logging Function, must match one of the properties like 'warnFn'
   * @param {*} [errorCode] | when and errorCode Object is present with a message, this message is also added
   * @memberof LogService
   */
  private logMessage(msg: string, loggingFn: string, errorCode?: ErrorCode) {
    let message = '';

    if (msg) {
      message = message + msg;
    }

    if (errorCode) {
      message = message + ' ' + errorCode.message;
    }

    this[loggingFn](message);
  }

}
