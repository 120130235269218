import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[hlCollapsable]'
})
export class CollapsableDirective {
  @Input() title = '.accordion__title';
  @Input() header = '.accordion__header';
  @Input() panel = '.accordion__panel';
  @Input() isAccordion = false;
  @Input() isCollapsible = true;
  @Output() onToggle = new EventEmitter<boolean>();

  private readonly titleAdditionContent = '.accordion__title__additional-content';

  @HostListener('keydown.arrowup', ['$event'])
  onUp(event: KeyboardEvent): void {
    const prevItem = this.getPrevItem(event.target);
    if (prevItem) {
      prevItem.focus();
    }
  }

  @HostListener('keydown.arrowdown', ['$event'])
  onDown(event: KeyboardEvent): void {
    const nextItem = this.getNextItem(event.target);
    if (nextItem) {
      nextItem.focus();
    }
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    if (
      this.eventPathContainsClass(event, this.title)
      && !this.eventPathContainsClass(event, this.titleAdditionContent)
      && this.isCollapsible
    ) {
      const element = event.target.closest(this.title);
      const isExpanded =
        JSON.parse(element && element.getAttribute('aria-expanded')) || false;

      if (this.isAccordion) {
        this.toggleAllPanels(false);
      }
      this.togglePanel(element, isExpanded);
    }
  }

  constructor(private el: ElementRef) {
  }

  private eventPathContainsClass(event: any, selector: string) {
    return event.path.find((item: Element) => item.matches && item.matches(selector));
  }

  togglePanel(el, isExpanded) {
    el.closest(this.header).nextElementSibling.setAttribute(
      'aria-hidden',
      isExpanded.toString()
    );
    el.setAttribute('aria-expanded', (!isExpanded).toString());
    this.onToggle.next(!isExpanded);
  }

  toggleAllPanels(expand: boolean) {
    for (const panel of this.el.nativeElement.querySelectorAll(this.panel)) {
      panel.setAttribute('aria-hidden', (!expand).toString());
    }

    for (const title of this.el.nativeElement.querySelectorAll(this.title)) {
      title.setAttribute('aria-expanded', expand.toString());
    }
  }

  private getPrevItem(item): any {
    let sibling = item.closest(this.header).previousElementSibling;
    if (sibling) {
      sibling = sibling.previousElementSibling;
    }
    if (sibling) {
      return sibling.querySelector(this.title);
    }

    const nodes = item.parentElement.parentElement.querySelectorAll(this.title);
    return nodes[nodes.length - 1];
  }

  private getNextItem(item): any {
    let sibling = item.closest(this.header).nextElementSibling;
    if (sibling) {
      sibling = sibling.nextElementSibling;
    }
    if (sibling) {
      return sibling.querySelector(this.title);
    }

    const nodes = item.parentElement.parentElement.querySelectorAll(this.title);
    return nodes[0];
  }
}
