import { Component, Input} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'hl-create-ticket-confirm-patient-data',
  templateUrl: './create-ticket-confirm-patient-data.component.html'
})
export class CreateTicketConfirmPatientDataComponent {

  @Input()
  confirmNoPatientData: FormGroup;
  @Input()
  isFormSubmitted: boolean;

  toggleConfirmed() {
    if (this.confirmNoPatientData.get('confirmed').value === true) {
      this.confirmNoPatientData.get('confirmed').patchValue(false);
    } else {
      this.confirmNoPatientData.get('confirmed').patchValue(true);
    }
  }
}
