import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { WindowService } from '../../../core/window.service';

@Component({
  selector: 'hl-exists-upload-update-modal',
  templateUrl: './exists-upload-update-modal.component.html'
})
export class ExistsUploadUpdateModalComponent extends BaseModalPopup {

  @Input()
  updateNumber: string;
  @Output()
  uploadUpdateExists: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  rollbackAndClean: EventEmitter<string> = new EventEmitter<string>();
  updateExistsMessage: string;
  window = this.windowService.nativeWindow;

  constructor(private translate: TranslateService,
            private windowService: WindowService,
            renderer: Renderer2) {
    super(renderer);
  }

  /**
   * @description Get update exists message with appropriate update number
   */
  getSystemUpdateExistsMessage() {
    this.translate.get('SYSTEM_UPDATES_UPLOAD_PACKAGE_EXISTS').subscribe(
      value => {
        this.updateExistsMessage = value.replace(/#UPDATE_NUMBER#/g, this.updateNumber);
      }
    );
  }

  show() {
    this.getSystemUpdateExistsMessage();
    super.show();
  }

  hide() {
    this.rollbackAndClean.emit(this.updateNumber);
    super.hide();
  }

  ok() {
    this.uploadUpdateExists.emit(this.updateNumber);
    super.hide();
  }
}
