import { Directive, OnInit } from '@angular/core';
import { IadvizeChatService } from '../../../core/component-communication-services/iadvize-chat/iadvize-chat.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ChatUtilService } from '../../../core/services/chat/chat-util.service';

@Directive({
  selector: '[hlIadvizeIntegration]'
})
export class IadvizeIntegrationDirective implements OnInit {

  iAdvizeObjectSubscription: Subscription;
  iAdvizeCustomObject: any;

  constructor(private iadvizeChatService: IadvizeChatService,
    private chatUtilService: ChatUtilService) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.subscribeEventListeners();
  }

  subscribeEventListeners() {
    this.iAdvizeObjectSubscription = this.iadvizeChatService.onIAdvizeCustomObjectBuildChange$.subscribe(iAdvizeObj => {
      const chatDiv = document.getElementsByClassName('iAdvizeChatScript')[0];
      this.iAdvizeCustomObject = iAdvizeObj;
      // we don't want the input fields twice
      this.chatUtilService.cleanUpDOM();
      this.putInputFields(chatDiv);
    });
  }

  private putInputFields(chatDiv) {
    _.forEach(this.iAdvizeCustomObject, (value, key) => {
      const s = document.createElement('input');
      s.type = 'hidden';
      s.setAttribute('class', key);
      s.value = value;
      chatDiv.appendChild(s);
    });
  }
}
