import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Customer } from '../../models/customer/customer';
import { CustomerGroup } from '../../models/customer/customer-group';
import { CustomerRestService } from '../../rest-services/customer-rest.service';
import { CustomerGroupsRestService } from '../../rest-services/customer-groups-rest.service';
import { CustomerViewModel } from '../../view-models/customer-view-model';
import * as _ from 'lodash';
import { ImpersonationUtilsService } from '../../utils/impersonation-utils.service';

export interface CustomersCache {
  customers: Customer[];
}

export interface GroupsCache {
  groups: CustomerGroup[];
}

@Injectable()
export class ImpersonationCacheService {

  customersCache: CustomersCache[] = [];
  groupsCache: GroupsCache[] = [];
  private customerRequestTriggered: boolean[] = [];
  private groupRequestTriggered: boolean[] = [];
  private onCustomersLoadedSource: Subject<Customer[]> = new Subject<Customer[]>();
  onCustomersLoaded$ = this.onCustomersLoadedSource.asObservable();

  private onGroupsLoadedSource: Subject<CustomerGroup[]> = new Subject<CustomerGroup[]>();
  onGroupsLoaded$ = this.onGroupsLoadedSource.asObservable();

  constructor(private customerRestService: CustomerRestService,
    private customerGroupRestService: CustomerGroupsRestService,
    private impersonationUtilService: ImpersonationUtilsService) {
  }

  getCustomerListByCountry(country): Observable<Customer[]> {
    if (this.customerRequestTriggered && !this.customerRequestTriggered[country]) {

      this.onCustomersLoadedSource = new Subject<Customer[]>();
      this.onCustomersLoaded$ = this.onCustomersLoadedSource.asObservable();

      this.customerRequestTriggered[country] = true;
      this.customerRestService.getCustomersByCountry(country).subscribe(customers => {
        this.customersCache[country] = {
          customers: customers
        };
        this.onCustomersLoadedSource.next(customers);
        this.onCustomersLoadedSource.complete();
      });
      return this.onCustomersLoaded$;
    }

    if (this.customersCache && this.customersCache[country] && this.customersCache[country].customers) {
      return of(this.customersCache[country].customers);
    } else {
      return this.onCustomersLoaded$;
    }
  }

  getGroupListByCountry(country): Observable<CustomerGroup[]> {
    if (this.groupRequestTriggered && !this.groupRequestTriggered[country]) {

      this.onGroupsLoadedSource = new Subject<CustomerGroup[]>();
      this.onGroupsLoaded$ = this.onGroupsLoadedSource.asObservable();

      this.groupRequestTriggered[country] = true;
      this.customerGroupRestService.getCustomerGroupsByCountry(country).subscribe(groups => {
        this.groupsCache[country] = {
          groups: groups
        };
        this.onGroupsLoadedSource.next(groups);
        this.onGroupsLoadedSource.complete();
      });
      return this.onGroupsLoaded$;
    }

    if (this.groupsCache && this.groupsCache[country] && this.groupsCache[country].groups) {
      return of(this.groupsCache[country].groups);
    } else {
      return this.onGroupsLoaded$;
    }
  }

  clearCacheByCountry(selectedCountry: string) {
    this.customerRequestTriggered[selectedCountry] = false;
    this.groupRequestTriggered[selectedCountry] = false;
  }

  getCustomerListByIds(customerIds: string[], country): CustomerViewModel[] {
    const filteredCustomerGroups = _.filter(this.customersCache[country].customers, customer => {
      return _.includes(customerIds, customer.customerId);
    });
    return this.impersonationUtilService.mapCustomersToViewModel(filteredCustomerGroups);
  }
}
