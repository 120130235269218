import { Equipment } from './equipment/equipment';

export interface FilterValues {
  overallSwitch: boolean;
  myEquipmentSwitch: boolean;
  favoriteModalities: string[];
}

export interface MyFiltersStatus {
  overallSwitch: boolean;
  equipment: Equipment[];
}

export interface MyFiltersStatusWithLoaded extends MyFiltersStatus {
  isAllDataLoaded: boolean;
}

export interface UserModality {
  modalityName: string;
  modalityCodes: string[];
}

export type FilterEquipmentArgs = [FilterValues, Equipment[], string[], string[]];

export const PREFIX_SAP = 'SAP_';
export const PREFIX_CMDB = 'CMDB_';
export const DEFAULT_USER_FILTER_VALUES = { overallSwitch: false, myEquipmentSwitch: false, favoriteModalities: [] };
