import { FilterUtilService } from '../../../core/utils/filter-util.service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, Input,
  OnInit,
  Output,
  Renderer2, ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { WindowService } from '../../../core/window.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { combineLatest, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { find, parseInt } from 'lodash';
import { SpaceValidator } from '../../validators/space.validator';
import { EquipmentViewModel } from '../../../core/view-models/equipment-view-model';
import { AttachmentComponent } from '../../form-group/attachment/attachment.component';
import { SelectOption } from '../../../core/models/select-option';
import { StartDateBeforeEndDateValidator } from '../../validators/start-date-before-end-date.validator';
import { ProfessionalServiceRequestUtilService } from '../../../core/utils/professional-service-request-util.service';
import { ProfessionalServiceRequestRestService } from '../../../core/rest-services/professional-service-request-rest.service';
import { RemoteWorkForceRequest } from '../../../core/models/professionalServiceRequest/remote-work-force-request';
import { DateUtilService } from '../../../core/utils/date-util.service';
import { DatePipeWrapperPipe } from '../../pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';

const translateKeys = {
  RWF_CREATION_IN_PROGRESS: 'RWF_CREATION_IN_PROGRESS',
  LABEL_RWF_SUCCESS: 'LABEL_RWF_SUCCESS'
};

@Component({
  selector: 'hl-create-rwf-modal',
  templateUrl: './create-rwf-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateRwfModalComponent extends BaseModalPopup
  implements OnInit, AfterViewInit {

  @Input()
  equipmentId: string;
  DATE_TIME_PATTERN = 'YYYY-MM-DD HH:mm:ss';
  DATE_PATTERN = 'YYYY-MM-DD';
  TIME_PATTERN = 'HH:mm:ss';

  createRwfForm: FormGroup;
  showValidationMessage = false;
  isFormSubmitted = false;
  window = this.windowService.nativeWindow;
  selectedEquipment: EquipmentViewModel = null;
  searchInput: any;
  states: Subject<EquipmentViewModel[]> = new Subject<EquipmentViewModel[]>();
  equipmentVMList: EquipmentViewModel[];
  equipmentStatusColorMap = [];

  rwfTermsLink: string;
  procedures: SelectOption[];
  services: SelectOption[];

  @Output()
  close = new EventEmitter();
  translate: { [key: string]: string } = {};
  config: any;

  @ViewChild('attachmentComponent', {static: false})
  attachmentComponent: AttachmentComponent;

  constructor(
    private configService: CountryConfigRestService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private windowService: WindowService,
    private equipmentUtilService: EquipmentUtilService,
    private cd: ChangeDetectorRef,
    private filterUtilService: FilterUtilService,
    private psrRestService: ProfessionalServiceRequestRestService,
    private psrUtilService: ProfessionalServiceRequestUtilService,
    private dateUtilService: DateUtilService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    renderer: Renderer2
  ) {
    super(renderer);
    this.bodyId = 'create-rwf-modal-body';
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.initFormControl();
  }

  show(event?: Event) {
    if (this.equipmentId) {
      this.onSelect(find(this.equipmentVMList, ['key', this.equipmentId]));
    }
    super.show(event);
    this.cd.detectChanges();
  }

  ok() {
    this.isFormSubmitted = false;
    this.showValidationMessage = false;
    this.clearInput();
    this.createForm();
    this.createRwfForm.markAsPristine();
    this.createRwfForm.markAsUntouched();
    this.showSpinner = false;
    this.hide();
    this.close.emit();
  }

  cancelCreateRwf() {
    this.isFormSubmitted = false;
    this.showValidationMessage = false;
    this.attachmentComponent.clearAttachments();
    this.clearInput();
    this.createForm();
    this.createRwfForm.markAsPristine();
    this.createRwfForm.markAsUntouched();
    this.showSpinner = false;
    this.hide();
    this.close.emit();
  }

  private initFormControl() {
    const config$ = this.configService.getConfig();
    const translate$ = this.translateService.get(Object.keys(translateKeys));
    combineLatest([config$, translate$]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(([config, translate]) => {
        this.config = config;
        this.translate = translate;
        this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_GREEN] =
          'green';
        this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_RED] = 'red';
        this.equipmentStatusColorMap[config.EQUIPMENT_STATUS_YELLOW] =
          'yellow';
        this.rwfTermsLink = config.RWF_TERMS_LINK;
        this.services = this.loadConfigItems(config.CREATE_RWF_SERVICES);
        this.createForm();
      });
  }

  private createForm() {
    const emailRegEx = new RegExp(this.config.EMAIL_VALIDATION_REGEX);
    const emailLength = parseInt(this.config.EMAIL_VALIDATION_LENGTH, 10);

    this.createRwfForm = this.fb.group({
      equipmentKey: [this.equipmentId ? this.equipmentId : '', Validators.required],
      service: ['', Validators.required],
      procedure: [[], Validators.required],
      shortDescription: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
      startDate: [new Date(), Validators.required],
      startTime: [new Date()],
      endDate: [new Date(), Validators.required],
      endTime: [this.initEndTime()],
      additionalDescription: [''],
      requester: this.fb.group({
        contactEmail: [
          '',
          [Validators.required, Validators.maxLength(emailLength), Validators.pattern(emailRegEx)]
        ],
        contactFirstName: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        contactLastName: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        contactPhone: ['', Validators.required],
        contactSalutation: [''],
        contactTitle: ['']
      }),
      contact: this.fb.group({
        contactEmail: [
          '',
          [Validators.required, Validators.maxLength(emailLength), Validators.pattern(emailRegEx)]
        ],
        contactFirstName: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        contactLastName: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        contactPhone: ['', Validators.required],
        contactSalutation: [''],
        contactTitle: ['']
      }),
      attachments: [],
      acceptance: ['', [Validators.requiredTrue]]
    }, {
      validators: StartDateBeforeEndDateValidator.startDateBeforeEndDateValidator(
        'startDate', 'startTime', 'endDate', 'endTime')
    });
    this.loadViewModel();
  }

  private initEndTime(): Date {
    const today = new Date();
    today.setHours(today.getHours() + 1);
    return today;
  }

  loadViewModel(): void {
    this.equipmentUtilService.getRemoteWorkForceWhitelistedEquipmentViewModelList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      equipment => {
        this.equipmentVMList = equipment;
        this.states.next(this.getMatchingResult(this.searchInput));
      });
  }

  private loadConfigItems(configString: string): SelectOption[] {
    if (configString) {
      const configItems = configString.split(',');
      return configItems.map(item => {
        return {
          title: item,
          value: item
        };
      });
    }
  }

  createRwf() {
    this.isFormSubmitted = true;

    if (this.createRwfForm.valid) {
      // Hide the message
      this.showValidationMessage = false;
      this.showSpinner = true;

      const labelRwfSuccess = this.translate[translateKeys.LABEL_RWF_SUCCESS];

      this.psrRestService.createRwf(this.createRwfBody()).pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => {
          this.ok();
        }))
        .subscribe(() => {
          const message = {
            type: 'success',
            isBodyTranslationKey: false,
            body: labelRwfSuccess
          };
          this.toasterService.emitToast(message);
          this.psrUtilService.emitRwfCreated();
        });
    } else {
      // show validation message set to true
      this.showValidationMessage = true;
    }
  }

  private createRwfBody(): RemoteWorkForceRequest {
    const rwf = Object.assign({}, this.createRwfForm.value);
    return {
      ...rwf,
      customerNumber: this.selectedEquipment.customerNumber,
      customerName: this.selectedEquipment.customerName,
      functionalLocation: this.selectedEquipment.siemensId,
      productName: this.selectedEquipment.productName,
      requesterName: rwf.requester.contactFirstName + ' ' + rwf.requester.contactLastName,
      requesterEmail: rwf.requester.contactEmail,
      requesterPhone: rwf.requester.contactPhone,
      contactName: rwf.contact.contactFirstName + ' ' + rwf.contact.contactLastName,
      contactEmail: rwf.contact.contactEmail,
      contactPhone: rwf.contact.contactPhone,
      startDate: this.datePipeWrapperPipe.transform(rwf.startDate, this.DATE_PATTERN) + ' ' +
        this.datePipeWrapperPipe.transform(rwf.startTime, this.TIME_PATTERN),
      endDate: this.datePipeWrapperPipe.transform(rwf.endDate, this.DATE_PATTERN) + ' ' +
        this.datePipeWrapperPipe.transform(rwf.endTime, this.TIME_PATTERN),
      creationDate: this.datePipeWrapperPipe.transform(new Date(), this.DATE_TIME_PATTERN)
    } as RemoteWorkForceRequest;
  }

  public onSelect(item: any): void {
    this.selectedEquipment = item;
    this.onSelectedEquipmentChanged();
  }

  onSearchInputChange(searchString: string) {
    this.states.next(this.getMatchingResult(searchString));
  }

  private getMatchingResult(token: string): EquipmentViewModel[] {
    const search = {
      searchValue: token,
      searchColumns: [
        'productName',
        'myEquipmentName',
        'siemensId',
        'department',
        'street',
        'city'
      ]
    };
    return this.filterUtilService.applyIndividualFilter(
      this.equipmentVMList,
      search,
      'search'
    );
  }

  onSelectedEquipmentChanged() {
    this.createRwfForm.patchValue({
      equipmentKey: this.selectedEquipment.key
    });
    this.procedures = this.loadConfigItems(this.config[this.selectedEquipment.modality + '_CREATE_RWF_PROCEDURES']);
    this.cd.detectChanges();
  }

  clearInput() {
    this.selectedEquipment = null;
    this.searchInput = '';
    this.createRwfForm.patchValue({equipmentKey: ''});
    this.procedures = [];
    this.cd.detectChanges();
  }
}
