import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { UserSettingsComponent } from './user-settings.component';
import { UserSettingsRoutingModule } from './user-settings-routing.module';
import { NotificationsTabComponent } from './notifications-tab/notifications-tab.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MyCustomersTabComponent } from './my-customers-tab/my-customers-tab.component';
import { MyFiltersTabComponent } from './my-filters-tab/my-filters-tab.component';

@NgModule({
  imports: [
    SharedModule,
    InfiniteScrollModule,
    UserSettingsRoutingModule
  ],
  exports: [
    UserSettingsComponent,
    NotificationsTabComponent
  ],
  declarations: [
    UserSettingsComponent,
    MyCustomersTabComponent,
    NotificationsTabComponent,
    MyFiltersTabComponent
  ],
})
export class UserSettingsModule {
}
