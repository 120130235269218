import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SprDashboardWidgets, SprWidget } from '../models/spr/configuration-spr';
import { restEndPoint } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { HttpParams } from '@angular/common/http';
import { SprDashboardFilter, SprFilter } from '../models/spr/spr-filter';

@Injectable({
  providedIn: 'root'
})
export class SprConfigurationWidgetRestService {
  private loadWidgetsRestName = restEndPoint + 'spr/graph/dashboard';
  private loadWidgetDetailsWithFilterRestName = restEndPoint + 'spr/graph/details';
  private saveWidgetsRestName = restEndPoint + 'spr/graph/save';
  private deleteWidgetRestName = restEndPoint + 'spr/graph/delete';
  private widgetOrderRestName = restEndPoint + 'spr/graph/saveOrder';
  private basedOnQuantity = restEndPoint + 'spr/based-on-quantity/:id';

  constructor(private http: CacheableHttpClient) {
  }

  loadDashboardWidgetsWithFilter(filter: SprDashboardFilter): Observable<SprDashboardWidgets> {
    return this.http.post<SprDashboardWidgets>(this.loadWidgetsRestName, filter);
  }

  loadWidgetDetailsWithFilter(widgetId: number, applyFilter: SprFilter, detailsType?: string): Observable<SprWidget> {
    return this.http.post<SprWidget>(this.loadWidgetDetailsWithFilterRestName, {
      graphId: widgetId,
      filter: applyFilter,
      detailsType
    });
  }

  saveGraphConfiguration(widget: SprWidget): Observable<any> {
    return this.http.post(this.saveWidgetsRestName, widget);
  }

  deleteSprWidget(widgetId: number): Observable<any> {
    return this.http.delete(this.deleteWidgetRestName, {params: new HttpParams().set('id', widgetId.toString())});
  }

  saveWidgetOrder(order: number[]): Observable<any> {
    return this.http.post(this.widgetOrderRestName, order);
  }

  checkBaseOnQuantity(quantityId: string): Observable<boolean> {
    return this.http.get(this.basedOnQuantity.replace(/:id/g, quantityId), {cache: false});
  }
}
