import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DiagramDoughnutComponent } from './diagram-doughnut.component';
import { DiagramBarsComponent } from './diagram-bars.component';
import { RouterModule } from '@angular/router';
import { DiagramComboComponent } from './diagram-combo.component';
import { DiagramComboVerticalComponent } from './diagram-combo-vertical.component';
import { DiagramStackedBarsComponent } from './diagram-stacked-bars.component';
import { DiagramGroupedBarsComponent } from './diagram-grouped-bars.component';
import { TranslateModule } from '@ngx-translate/core';
import { GetCountWithUnitPipe } from './get-count-with-unit-diagram/get-count-with-unit.pipe';
import { GetTotalFontSizePipe } from './get-total-font-size-diagram/get-total-font-size.pipe';
import { DiagramTimelineComponent } from './diagram-timeline.component';
import { DiagramLineDoubleAxisComponent } from './diagram-line-double-axis.component';
import { WidgetDiagramBarsComponent } from './widget-diagram-bars.component';
import { DiagramBarVerticalCandles } from './diagram-bar-vertical-candles/diagram-bar-vertical-candles.component';
import { SeriesVerticalComponent } from './diagram-bar-vertical-candles/diagram-bar-vertical-series.component';
import { DiagramBarDoubleAxisVerticalComponent } from './diagram-bar-double-axis/diagram-bar-double-axis-vertical.component';
import { DiagramBarDoubleAxisHorizontalComponent } from './diagram-bar-double-axis/diagram-bar-double-axis-horizontal.component';
import { DiagramXAxisCustomComponent } from './diagram-bar-double-axis/diagram-x-axis-custom.component';
import { DiagramBarDoubleAxisBaseComponent } from './diagram-bar-double-axis/diagram-bar-double-axis-base.component';

export { DataDiagram, GeneratedDataDiagram } from './diagram';

@NgModule({
  imports: [NgxChartsModule, RouterModule, TranslateModule],
  declarations: [
    DiagramDoughnutComponent,
    DiagramBarsComponent,
    DiagramGroupedBarsComponent,
    DiagramStackedBarsComponent,
    DiagramComboComponent,
    DiagramComboVerticalComponent,
    GetCountWithUnitPipe,
    GetTotalFontSizePipe,
    DiagramTimelineComponent,
    WidgetDiagramBarsComponent,
    DiagramLineDoubleAxisComponent,
    DiagramBarVerticalCandles,
    SeriesVerticalComponent,
    DiagramBarDoubleAxisBaseComponent,
    DiagramBarDoubleAxisVerticalComponent,
    DiagramBarDoubleAxisHorizontalComponent,
    DiagramXAxisCustomComponent
  ],
  exports: [
    DiagramDoughnutComponent,
    DiagramBarsComponent,
    DiagramGroupedBarsComponent,
    DiagramStackedBarsComponent,
    DiagramComboComponent,
    GetCountWithUnitPipe,
    GetTotalFontSizePipe,
    DiagramTimelineComponent,
    WidgetDiagramBarsComponent,
    DiagramLineDoubleAxisComponent,
    DiagramBarVerticalCandles,
    SeriesVerticalComponent,
    DiagramBarDoubleAxisBaseComponent,
    DiagramBarDoubleAxisVerticalComponent,
    DiagramBarDoubleAxisHorizontalComponent,
    DiagramXAxisCustomComponent
  ],
  providers: []
})
export class DiagramModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DiagramModule,
      providers: []
    };
  }
}
