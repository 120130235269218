import { Component, Renderer2 } from '@angular/core';
import { OptionsGetQuoteModalComponent } from '../options-get-quote-modal/options-get-quote-modal.component';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { EquipmentConstantsService } from '../../../core/services/equipment/equipment-constants.service';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import * as _ from 'lodash';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { DatePipeWrapperPipe } from '../../pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hl-options-get-trial-modal',
  templateUrl: './options-get-trial-modal.component.html'
})
export class OptionsGetTrialModalComponent extends OptionsGetQuoteModalComponent {

  termsAndConditionsLink = '';
  datePattern = '';
  minDate: Date;

  constructor(userRestService: UserRestService,
    equipmentRestService: EquipmentRestService,
    equipmentConstantsService: EquipmentConstantsService,
    toasterService: ToasterService,
    renderer: Renderer2,
    equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService,
    private datePipeWrapperPipe: DatePipeWrapperPipe) {
    super(userRestService,
      equipmentRestService,
      equipmentConstantsService,
      toasterService,
      renderer,
      equipmentUtilService,
      configService
    );
  }

  init() {
    super.init();

    const date = new Date();
    date.setDate((new Date()).getDate() + 5);
    this.minDate = date;

    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.setConfigProperties(configResponse);
    });
  }

  setConfigProperties(config) {
    this.termsAndConditionsLink = config.TERMS_AND_CONDITIONS_EXTERNAL_LINK;
    this.datePattern = config.GENERIC_DATE_PATTERN;
  }

  postFormData() {
    this.resolveObservable(this.equipmentRestService.postGetTrial(this.equipmentItem.key, this.getPreparedPostBody()));
  }

  // this is called before the post happens
  getPreparedPostBody(): object {
    let formData: any = _.clone(this.formData);
    formData.testStartDate = this.datePipeWrapperPipe.transform(formData.testStartDate, this.datePattern);

    formData = _.omit(formData, ['clinicalFields']);

    return formData;
  }
}
