import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceUnderscoresWithSpace'
})
export class ReplaceUnderscoresWithSpacePipe implements PipeTransform {

  transform(text: string): string | null {
    return text && text.replace(/_/g, ' ');
  }
}
