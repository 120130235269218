import { ItemDetailViewModel } from '../../../core/view-models/item-detail-view-model';
import { ActivatedRoute } from '@angular/router';
import { ContractsUtilService } from '../../../core/services/contracts/contracts-util.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'hl-contracts-overview',
  templateUrl: './contracts-overview.component.html'
})
export class ContractsOverviewComponent implements OnInit, OnDestroy {

  isLoaded: boolean;
  contractDetailList: ItemDetailViewModel[];
  datePattern = '';
  contractDetailFields: ItemDetailViewModel[];
  config: any;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private contractsUtilService: ContractsUtilService,
    private configService: CountryConfigRestService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.setConfigProperties(configResponse);
    });
    this.loadContractDetails();
  }

  setConfigProperties(config) {
    this.config = config;
    this.datePattern = config.GENERIC_DATE_PATTERN;
  }

  loadContractDetails() {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.isLoaded = false;
        this.contractDetailList = [];
        this.contractsUtilService.getContractsDetails(params['id']).pipe(
          finalize(() => this.isLoaded = true)
        ).subscribe((contractDetailsResponse) => {
          this.contractDetailList = contractDetailsResponse;
        });

        if (this.contractDetailFields) {
          this.contractsUtilService.reloadContractDetailFields(this.contractDetailFields);
        } else {
          this.contractDetailFields = this.contractsUtilService.getContractDetailFields(this.config);
        }
      }
    );
  }

  handleContractExport = () => {
    return this.contractsUtilService.generateContractDetailsExport(this.contractDetailFields, this.contractDetailList);
  }

  getExportListCount(): number {
    return this.contractDetailFields.length + this.contractDetailList.length;
  }
}
