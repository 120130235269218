import { Modality } from '../app/core/models/equipment/modality';
// new last Item in Object
export const CdModalityList: Modality[] = [
  {
    modalityCode: '01',
    modalityDescription: 'Angiography',
    descriptionCmdb: ''
  },
  {
    modalityCode: '02',
    modalityDescription: 'Magnetic Resonance Imaging (MRI)',
    descriptionCmdb: ''
  },
  {
    modalityCode: '03',
    modalityDescription: 'Computed Tomography (CT)',
    descriptionCmdb: ''
  },
  {
    modalityCode: '04',
    modalityDescription: 'Mammography',
    descriptionCmdb: ''
  },
  {
    modalityCode: '05',
    modalityDescription: 'Syngo Pacs',
    descriptionCmdb: ''
  },
  {
    modalityCode: '06',
    modalityDescription: 'General X-ray',
    descriptionCmdb: ''
  },
  {
    modalityCode: '07',
    modalityDescription: 'Mobile Radiography',
    descriptionCmdb: ''
  },
  {
    modalityCode: '08',
    modalityDescription: 'Life Support Systems',
    descriptionCmdb: ''
  },
  {
    modalityCode: '09',
    modalityDescription: 'Electro-Cardio Systems',
    descriptionCmdb: ''
  },
  {
    modalityCode: '10',
    modalityDescription: 'Urology/ Lithotripsy',
    descriptionCmdb: ''
  },
  {
    modalityCode: '11',
    modalityDescription: 'Syngo Dynamics',
    descriptionCmdb: ''
  },
  {
    modalityCode: '15',
    modalityDescription: 'Multi Vendor',
    descriptionCmdb: ''
  },
  {
    modalityCode: '27',
    modalityDescription: 'Hematology and Hemostasis',
    descriptionCmdb: ''
  },
  {
    modalityCode: '30',
    modalityDescription: 'Molecular Imaging PET',
    descriptionCmdb: ''
  },
  {
    modalityCode: '31',
    modalityDescription: 'Molecular Imaging SPECT',
    descriptionCmdb: ''
  },
  { modalityCode: '40', modalityDescription: 'Oncology', descriptionCmdb: '' },
  {
    modalityCode: '52',
    modalityDescription: 'Ultrasound',
    descriptionCmdb: ''
  },
  { modalityCode: '53', modalityDescription: 'Syngo.via', descriptionCmdb: '' },
  {
    modalityCode: '57',
    modalityDescription: 'Syngo Workflow',
    descriptionCmdb: ''
  },
  {
    modalityCode: '67',
    modalityDescription: 'Chemistry and Immunoassay',
    descriptionCmdb: ''
  },
  {
    modalityCode: '68',
    modalityDescription: 'Molecular and Microbiology',
    descriptionCmdb: ''
  },
  {
    modalityCode: '69',
    modalityDescription: 'Point of Care',
    descriptionCmdb: ''
  },
  {
    modalityCode: '79',
    modalityDescription: 'Syngo Knowledge Solutions/ CAD',
    descriptionCmdb: ''
  },
  {
    modalityCode: 'CKS',
    modalityDescription: 'Syngo Knowledge Solutions/ CAD',
    descriptionCmdb: ''
  },
  {
    modalityCode: 'DS-EV',
    modalityDescription: 'Syngo.via Enterprise',
    descriptionCmdb: ''
  },
  {
    modalityCode: 'DS-SH',
    modalityDescription: 'Syngo Sense/ Share',
    descriptionCmdb: ''
  },
  {
    modalityCode: 'DS-TP',
    modalityDescription: 'teamplay',
    descriptionCmdb: ''
  },
  {
    modalityCode: 'EV',
    modalityDescription: 'Syngo.via Enterprise',
    descriptionCmdb: ''
  },
  {
    modalityCode: 'RIS',
    modalityDescription: 'Syngo Workflow',
    descriptionCmdb: ''
  },
  {
    modalityCode: 'SH',
    modalityDescription: 'Syngo Sense/ Share',
    descriptionCmdb: ''
  },
  {
    modalityCode: 'SU',
    modalityDescription: 'Mobile C-arms',
    descriptionCmdb: ''
  },
  { modalityCode: 'TP', modalityDescription: 'teamplay', descriptionCmdb: '' },
  {
    modalityCode: 'VIA',
    modalityDescription: 'Syngo.via',
    descriptionCmdb: ''
  },
  {
    modalityCode: 'XPU',
    modalityDescription: 'Urology/ Lithotripsy',
    descriptionCmdb: ''
  }
];
