import { Injectable } from '@angular/core';
import { SortSkeleton } from '../../../shared/sorting/sort-skeleton';
import { SortDirection } from '../../../shared/sorting/sort-object';
import { SelectOption } from '../../models/select-option';

@Injectable()
export class SecurityAdvisoriesConstantsService {

  getAdvisoriesSortSkeleton(showAdminView: boolean): SortSkeleton {
    const sortObject = {
      sortObject: {
        sortBy: 'lastUpdated',
        sortDir: SortDirection.DESC
      },
      items: [{
        title: 'LAST_UPDATED',
        value: 'lastUpdated'
      }, {
        title: 'GENERIC_LABEL_OPTION_TITLE',
        value: 'title'
      }, {
        title: 'SECURITY_CVSS_SCORE',
        value: 'cvssScore'
      }]
    };

    const extraOption = {
      title: 'SECURITY_ADVISORY_ACTIVATION_DATE',
      value: 'activationDate'
    };

    if (showAdminView) {
      sortObject.items.push(extraOption);
    }

    return sortObject;
  }

  getActiveInactiveOptions(): SelectOption[] {
    return [
      {
        title: 'GENERIC_LABEL_ACTIVE',
        value: true
      },
      {
        title: 'GENERIC_LABEL_INACTIVE',
        value: false
      }
    ];
  }
}
