import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { FormGroup } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'hl-create-ticket-request-area',
  templateUrl: './create-ticket-request-area.component.html'
})
export class CreateTicketRequestAreaComponent implements OnInit, OnDestroy {

  @Input() requestForm: FormGroup;

  showTicketOwnIncidentNumber = false;
  showRequestArea = false;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private configService: CountryConfigRestService) {}

  ngOnInit() {
    this.configurePropBasedOnCountryConfig();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  configurePropBasedOnCountryConfig() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      // feedback requested
      if (_.isEqual(configResponse.FEEDBACK_REQUESTED_EMAIL, 'false')) {
        this.requestForm.patchValue({ feedBack: 'phone' });
      }

      // check for render ticket own incident number
      const ticketOwnIncidentNumber =
        configResponse.TICKET_SHOW_OWN_INCIDENT_NUMBER_CREATE;
      if (_.isEqual(ticketOwnIncidentNumber, 'true')) {
        // show ticket own incident number in request area
        this.showTicketOwnIncidentNumber = true;
      }

      // check to show the Request Feedback via fields
      this.showRequestArea = _.isEqual(configResponse['TICKET_SHOW_RESPONSE_AREA'], 'true');
    });
  }

  selectFeedBack(value: string) {
    this.requestForm.get('feedBack').patchValue(value);
  }
}
