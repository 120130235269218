import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { clone, concat, extend, filter, forEach, includes, isEqual, remove, startsWith } from 'lodash';
import { EqMobileAddress } from '../../models/equipment/eq-mobile-address';
import { Equipment } from '../../models/equipment/equipment';
import { EquipmentDetails } from '../../models/equipment/equipment-details';
import { EquipmentInformation } from '../../models/equipment/equipment-information';
import { EquipmentMyProfileListAndChecked } from '../../models/equipment/equipment-my-profile';
import { EquipmentStatus } from '../../models/equipment/equipment-status';
import { LifenetSystemCustom } from '../../models/optionsUpgrades/lifenet-system-custom';
import { Vblo } from '../../models/optionsUpgrades/vblo';
import { StatusCountViewModel } from '../../models/status-count-view-model';
import { EquipmentPiiDetailsViewModel, VulnerabilityViewModel } from '../../view-models/equipment-pii-details-view-model';
import { EquipmentRestService } from '../../rest-services/equipment-rest.service';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { LifeNetUtilService } from '../../utils/life-net-util.service';
import { EquipmentViewModel } from '../../view-models/equipment-view-model';
import { OptionsUpgradesViewModel } from '../../view-models/options-upgrades-view-model';
import { CENTRICARE_EQUIPMENT_STATUS_CRITICAL } from './equipment-constants.service';
import { EquipmentSrsStatus, SrsStatus } from '../../models/equipment/equipment-srs-status';
import { restEndPoint } from '../../core-constants.service';

const SBOM_FILE_NAME = 'SBOM_:siemensEquipmentId.:extension';

@Injectable()
export class EquipmentUtilService {
  showEditModalEmitter = new EventEmitter<any>();
  showDeactivateModalEmitter = new EventEmitter();
  updateSecurityDetailStatusEmitter = new EventEmitter();
  updateEquipmentDataSubject = new Subject<any>();
  srsStatusUpdateSubject = new Subject<any>();
  srsStatusesLoading = [];

  constructor(
    private equipmentRestService: EquipmentRestService,
    private countryConfigRestService: CountryConfigRestService,
    private lifeNetUtilService: LifeNetUtilService
  ) {
  }

  static getSoftwareVersion(equipment: Equipment | EquipmentInformation): string {
    return equipment.cmdbEquipment && equipment.cmdbEquipment.softwareVersion ?
      equipment.cmdbEquipment.softwareVersion :
      equipment.softwareVersion;
  }

  /**
   * Returns the view model for equipment by merging status from
   * /equipments/status to /equipments
   */
  getEquipmentViewModelList(): Observable<EquipmentViewModel[]> {
    const findObject = {
      findKey: 'equipmentKey',
      findValue: 'key',
      propertiesToMerge: ['status']
    };

    return this.lifeNetUtilService.createViewModels(
      this.getNativeEquipmentStatus(),
      this.getEquipmentRest(),
      findObject
    );
  }

  /**
   * Returns the view model for Remote Work Force whitelisted equipment by merging status from
   * /equipments/status to /equipments
   */
  getRemoteWorkForceWhitelistedEquipmentViewModelList(): Observable<EquipmentViewModel[]> {
    const findObject = {
      findKey: 'equipmentKey',
      findValue: 'key',
      propertiesToMerge: ['status']
    };

    return this.lifeNetUtilService.createViewModels(
      this.getNativeEquipmentStatus(),
      this.equipmentRestService.getRemoteWorkForceEquipmentWhiteList(),
      findObject
    );
  }

  getEquipmentRest(): Observable<Equipment[]> {
    return this.equipmentRestService.getEquipment();
  }

  getEquipment(key: string): Observable<Equipment> {
    return this.equipmentRestService
      .getEquipment()
      .pipe(map(
        equipmentArrayResponse =>
          equipmentArrayResponse.filter(equip => equip.key === key)[0]
      ));
  }

  loadEquipmentByKey(key: string): Observable<any> {
    return !!key ? this.getEquipment(key) : of(null);
  }

  loadEquipmentStatus(): Observable<StatusCountViewModel[]> {
    return this.getNativeEquipmentStatus().pipe(
      mergeMap(equipmentStatusResponse => {
        return this.filterStatusByProfile(equipmentStatusResponse).pipe(
          mergeMap(filteredEquipmentStatusResponse => {
              return this.getCustomerEquipmentStatusValues(
                '',
                filteredEquipmentStatusResponse,
                true
              );
            }
          ));
      }));
  }

  loadEquipmentStatusForKeys(equipmentKeys: string[]): Observable<StatusCountViewModel[]> {
    return this.getNativeEquipmentStatus().pipe(
      map(equipmentStatuses => equipmentStatuses.filter(status => equipmentKeys.indexOf(status.equipmentKey) !== -1)),
      mergeMap(filteredEquipmentStatusResponse =>
        this.getCustomerEquipmentStatusValues('', filteredEquipmentStatusResponse, true))
    );
  }

  filterStatusByProfile(equipmentStatusResponse: EquipmentStatus[]): Observable<EquipmentStatus[]> {
    return this.getMySiemensListAndSelectedFlag().pipe(map(
      myEquipmentListResponse => {
        if (myEquipmentListResponse.myEquipmentChecked) {
          let statusResult: EquipmentStatus[] = [];

          forEach(myEquipmentListResponse.myEquipmentProfileList, item => {
            statusResult = concat(statusResult, filter(equipmentStatusResponse, {equipmentKey: item}));
          });

          return statusResult;
        } else {
          return equipmentStatusResponse;
        }
      }
    ));
  }

  filterStatusItems(items: EquipmentStatus[], customerNumber?: string): EquipmentStatus[] {
    let statusItems: EquipmentStatus[];

    if (customerNumber) {
      statusItems = filter(items, {customerNumber}) || [];
    } else {
      statusItems = clone(items);
    }

    return statusItems;
  }

  getCustomerEquipmentStatusValues(
    customerNumber: string,
    statusItemsForCustomer: EquipmentStatus[],
    allFlag: boolean = false
  ): Observable<StatusCountViewModel[]> {
    return this.countryConfigRestService
      .getConfig()
      .pipe(map(statusConfigurationResponse => {
        let greenCount = 0;
        let yellowCount = 0;
        let redCount = 0;
        let statusItems: EquipmentStatus[];
        let statusResultList: StatusCountViewModel[];

        if (allFlag) {
          statusItems = this.filterStatusItems(statusItemsForCustomer);
        } else {
          statusItems = this.filterStatusItems(
            statusItemsForCustomer,
            customerNumber
          );
        }

        const isCentriCareToggleEnabled = isEqual(statusConfigurationResponse.TOGGLE_CENTRICARE, 'true');

        // count values:
        forEach(statusItems, item => {
          switch (item.status) {
            case statusConfigurationResponse.EQUIPMENT_STATUS_GREEN:
              greenCount++;
              break;
            case statusConfigurationResponse.EQUIPMENT_STATUS_YELLOW:
              yellowCount++;
              break;
            case statusConfigurationResponse.EQUIPMENT_STATUS_RED:
              redCount++;
              break;
            default:
              break;
          }

          if (isCentriCareToggleEnabled && item.status === CENTRICARE_EQUIPMENT_STATUS_CRITICAL) {
            redCount++;
          }
        });

        statusResultList = [
          {
            title: 'EQUIPMENT_STATUS_EQUIPMENT_WITH_BREAKDOWN',
            class: 'red',
            count: redCount,
            status: '1'
          },
          {
            title: 'EQUIPMENT_STATUS_EQUIPMENTS_WITH_PARTIAL_BREAKDOWN',
            class: 'yellow',
            count: yellowCount,
            status: '2'
          },
          {
            title: 'EQUIPMENT_STATUS_EQUIPMENT_OK',
            class: 'green',
            count: greenCount,
            status: '3'
          },
          {
            title: 'GENERIC_LABEL_ALL_SIEMENS_EQUIPMENT',
            class: 'All',
            count: greenCount + yellowCount + redCount,
            status: 'All'
          }
        ];

        return statusResultList;
      }));
  }

  getMySiemensListAndSelectedFlag(): Observable<EquipmentMyProfileListAndChecked> {
    return this.equipmentRestService
      .getMyProfileChecked()
      .pipe(
        mergeMap(checkedResponse => {
          return this.equipmentRestService
            .getMyProfile()
            .pipe(map(myProfileResponse => {
              return {
                myEquipmentProfileList: myProfileResponse.equipment,
                myEquipmentPiiProfileList: myProfileResponse.equipmentPiis,
                myEquipmentChecked: checkedResponse.checked
              };
            }));
        })
      );
  }

  getNativeEquipmentStatus(): Observable<EquipmentStatus[]> {
    return this.equipmentRestService
      .getEquipmentStatus().pipe(
        map(equipmentStatusResponse => {
          const result: EquipmentStatus[] = [];
          forEach(equipmentStatusResponse, item => {
            const customerNumber = item.customerNumber;
            forEach(item.statusList, status => {
              const newStatus = clone(status);
              newStatus.customerNumber = customerNumber;
              result.push(newStatus);
            });
          });
          return result;
        })
      );
  }

  getWorstCustomerEquipmentStatus(customerNumber, equipmentStatusData) {
    return this.getCustomerEquipmentStatusValues(
      customerNumber,
      equipmentStatusData,
      false
    ).pipe(map(equipmentStatusResultResponse => {
      let token = -1;

      if (equipmentStatusResultResponse[2].count > 0) {
        token = 0;
      }
      if (equipmentStatusResultResponse[1].count > 0) {
        token = 2;
      }
      if (equipmentStatusResultResponse[0].count > 0) {
        token = 1;
      }
      return token;
    }));
  }

  /**
   * @ngdoc method
   * @name mergeAndExtendOptionsUpgrades
   *
   * @param dataset
   *
   * @description
   * merge the handout group and vblos item.
   */
  mergeAndExtendOptionsUpgrades(
    dataset: LifenetSystemCustom[]
  ): OptionsUpgradesViewModel[] {
    const optionsAndUpgrades: OptionsUpgradesViewModel[] = [];
    if (dataset.length > 0) {
      forEach(dataset[0].vbloGroupsCustom, data => {
        forEach(data.vblos, (item: Vblo) => {
          optionsAndUpgrades.push(extend(item, data.handOutGroup));
        });
      });
    }
    return optionsAndUpgrades;
  }

  /**
   * @param id the equipment key
   *
   * @description
   * Get the details for the equipment.
   * Details contain
   * 1) component list
   * 2) document attachment
   *
   */
  getDetailsById(id: string): Observable<EquipmentDetails> {
    return this.equipmentRestService.getEquipmentDetails(id);
  }

  clearEquipmentDetailsCache(key: string) {
    this.equipmentRestService.clearEquipmentDetailsCache(key);
  }

  getMobileEquipmentAddresses(equipmentKey: string): Observable<EqMobileAddress[]> {
    return this.equipmentRestService.getMobileEquipmentAddresses(equipmentKey);
  }

  showEditModal(selectedEquipment: Equipment | EquipmentInformation, addressFields: any) {
    this.showEditModalEmitter.emit({selectedEquipment, addressFields});
  }

  showDeactivateModalDialog(selectedEquipment: Equipment | EquipmentInformation) {
    this.showDeactivateModalEmitter.emit(selectedEquipment);
  }

  updateEquipmentData(selectedEquipment: Equipment) {
    this.updateEquipmentDataSubject.next(selectedEquipment);
  }

  srsStatusUpdated(srsStatus) {
    this.srsStatusUpdateSubject.next(srsStatus);
  }

  updateSecurityDetailStatus(status: string) {
    this.updateSecurityDetailStatusEmitter.emit(status);
  }

  /**
   *
   * @param {number} modality
   * @param {string} codeListSyngo
   * checks weather Equipment Type is "Syngo"
   */
  isSyngo(modality: string, codeListSyngo: string): boolean {
    return includes(codeListSyngo.split(','), modality);
  }

  /**
   *
   * @param {number} modality
   * @param {string} codeListInvitro
   * checks weather Equipment Type is "Invitro"
   */
  isInvitro(modality: string, codeListInvitro: string): boolean {
    return includes(codeListInvitro.split(','), modality);
  }

  /**
   *
   * @param {number} modality
   * @param {string} codeListSyngo
   * @param {string} codeListInvitro
   * checks weather Equipment Type is "Default"
   */
  isDefault(modality: string, codeListSyngo: string, codeListInvitro: string): boolean {
    return !this.isSyngo(modality, codeListSyngo) && !this.isInvitro(modality, codeListInvitro);
  }

  /**
   *
   * @param {number} modality
   * @param {string} configMultiVendor
   * checks whether Equipment Type is "MultiVendor"
   */
  isMultiVendor(modality: string, configMultiVendor: string): boolean {
    return !!configMultiVendor && includes(configMultiVendor.split(','), modality);
  }

  /**
   * @param {string} equipmentKey | the given equipment key
   * @returns {boolean}
   * checks the given equipment to fulfill ld criteria
   */
  checkIsLdEquipment(equipmentKey: string) {
    return startsWith(equipmentKey, 'X');
  }

  getEquipmentPiiDetailsViewModel(key: string): Observable<EquipmentPiiDetailsViewModel> {
    return this.equipmentRestService.getEquipmentPiiDetails(key).pipe(
      map(equipmentPiiDetails => {
        let vulnerabilities = null;

        if (equipmentPiiDetails.vulnerabilities) {
          vulnerabilities = equipmentPiiDetails.vulnerabilities.map(vulnerability => {
            return {isExpanded: false, vulnerabilityValue: vulnerability} as VulnerabilityViewModel;
          });
        }

        let operatingSystem = '';
        if (equipmentPiiDetails.operatingSystems && equipmentPiiDetails.operatingSystems.length > 0) {
          const os = equipmentPiiDetails.operatingSystems[0];
          operatingSystem = (os.vendor ? os.vendor + ', ' : '') +
                             os.name +
                            (os.version ? ', ' + os.version : '');
        }

        return {...equipmentPiiDetails, vulnerabilities, operatingSystem};
      })
    );
  }

  getEquipmentStatus(equipmentKey: string): Observable<string> {
    return this.getNativeEquipmentStatus().pipe(
      map(statuses => {
        const status = statuses.find(item => item.equipmentKey === equipmentKey);
        return (!!status && status.status) || '';
      })
    );
  }

  getSrsStatus(equipmentItem) {
    this.srsStatusesLoading.push(equipmentItem.key);
    const actualSrsStatus = {
      equipmentKey: equipmentItem.key,
      status: equipmentItem.srsStatus,
      lastUpdate: equipmentItem.srsLastUpdated
    };

    this.equipmentRestService.getEquipmentSrsStatus(equipmentItem.key)
      .pipe().subscribe(response => {
      this.handleSrsStatus(response, actualSrsStatus, equipmentItem);
    });
  }

  handleSrsStatus(response: EquipmentSrsStatus, actualSrsStatus, equipmentItem) {
    if (response && response.equipmentKey === actualSrsStatus.equipmentKey) {
      if (!isEqual(response.status, SrsStatus.Undefined)) {
        actualSrsStatus.status = response.status;
        actualSrsStatus.lastUpdate = response.lastUpdate;
      }
      remove(this.srsStatusesLoading, x => x === actualSrsStatus.equipmentKey);
      equipmentItem.srsStatus = actualSrsStatus.status;
      equipmentItem.srsLastUpdated = actualSrsStatus.lastUpdate;
      this.srsStatusUpdated(actualSrsStatus);
    }
  }

  setInitialSrsStatusForEquipment(equipmentItem, status: EquipmentSrsStatus) {
    const actualSrsStatus = {
      equipmentKey: equipmentItem.key,
      status: equipmentItem.srsStatus,
      lastUpdate: equipmentItem.srsLastUpdated
    };
    this.handleSrsStatus(status, actualSrsStatus, equipmentItem);
  }

  generateDownloadSbomUrl(equipmentKey: string, type: string): string {
    return restEndPoint + 'equipments/' + equipmentKey + '/download-sbom/' + type;
  }

  generateSbomFileName(equipmentSiemensId: string, type: string): string {
    return SBOM_FILE_NAME.replace(/:siemensEquipmentId/g, equipmentSiemensId)
      .replace(/:extension/g, type === 'excel' ? 'xlsx' : type);
  }
}
