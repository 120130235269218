import { Component, EventEmitter, Output, Renderer2 } from '@angular/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';

@Component({
  selector: 'hl-danger-for-patient-modal',
  templateUrl: './danger-for-patient-modal.component.html'
})
export class DangerForPatientModalComponent extends BaseModalPopup {

  @Output() onClickOk = new EventEmitter<any>();

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  ok() {
    this.hide();
    this.onClickOk.emit();
  }
}
