import { OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseListView } from './base-list-view';
import { EquipmentUtilService } from '../services/equipment/equipment-util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserUtilService } from '../services/user/user-util.service';
import { SystemUpdatesService } from '../services/system-updates/system-updates-service';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { LogService } from '../services/log/log.service';
import { SystemUpdatesConstantsService } from '../services/system-updates/system-updates-constants.service';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '../services/state.service';
import { Subscription } from 'rxjs';
import { HowToModalComponent } from '../../system-updates/how-to-modal/how-to-modal.component';
import { UploadUpdatePackageModalComponent } from '../../shared/modal-popup/upload-update-package-modal/upload-update-package-modal.component';
import { SystemUpdateBaseViewModel } from '../view-models/system-update-base-view-model';
import { OverlayComponent } from '../../shared/components/overlay/overlay.component';
import { BrowserStateService } from '../services/browser-state.service';
import { FilterUtilService } from '../utils/filter-util.service';

export abstract class BaseSystemUpdates extends BaseListView<SystemUpdateBaseViewModel> implements OnInit, OnDestroy {

    @ViewChild('detailOverlay', { static: false }) detailOverlay: OverlayComponent;

  // update status variables
  allUpdateStatuses = [];
  selectedUpdateStatuses = [];
  checkAllStates: boolean;
  routeParamsSubscription: Subscription;
  routeQuerySubscription: Subscription;

    @ViewChild('uploadModal', { static: false }) uploadModal: UploadUpdatePackageModalComponent;
    @ViewChild('howToModal', { static: false }) howToModal: HowToModalComponent;

  constructor(
    protected systemUpdatesConstantsService: SystemUpdatesConstantsService,
    protected systemUpdatesService: SystemUpdatesService,
    protected translateService: TranslateService,
    protected route: ActivatedRoute,
    router: Router,
    equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService,
    userUtilService: UserUtilService,
    stateService: StateService,
    logService: LogService,
    browserStateService: BrowserStateService,
    filterUtilService: FilterUtilService) {

    // base class instantiation
    super(configService, equipmentUtilService, userUtilService, stateService,
      logService, router, browserStateService, filterUtilService);
  }

  abstract ngOnInit(): void;

  ngOnDestroy() {
    super.destroy();
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }
    if (this.routeQuerySubscription) {
      this.routeQuerySubscription.unsubscribe();
    }
  }

  getEmptyListUrl(): string {
    return '/updates';
  }

  /**
   * @description Initializes the list of system updates
   */
  afterInitViewModelList() {
    this.onAdvancedFilterChange();
    if (this.route.firstChild && (this.route.firstChild.snapshot.params.updateNumber || this.route.firstChild.snapshot.params.id)) {
      const key = this.route.firstChild.snapshot.params.updateNumber || this.route.firstChild.snapshot.params.id;
      const item = this.viewModelList.find(i => i.updateNumber === key);
      this.openDetailOverlay(item);
    }
  }

  openDetailOverlay(item: SystemUpdateBaseViewModel) {
    this.onClickUpdateSelectedItem(item);
    this.detailOverlay.show();
  }

  afterConfigProperties(config: any): void {
  }

  initAdvancedFilterDropDownList(): void {
  }

  setDerivedBoundPropertiesFromFavorite(favorite: any): void {
  }

  /**
   * @description Filter object with different filters
   */
  getFilterObject(): any {
    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: [
          'title',
          'updateNumber'
        ]
      },
      updateStatus: this.selectedUpdateStatuses,
      orderBy: this.sortSkeleton.sortObject,
      dateRange: {
        rangeOfDate: this.dateRange,
        propertyKey: 'title'
      }
    };
  }

  filterChanged() {
    if (this.isLoaded) {
      this.onAdvancedFilterChange();
    }
  }
}
