import { Component, Input } from '@angular/core';
import { CdGBUser, CdUsers, CdUSUser, CdArabUser } from './cd-users.mock';

@Component({
  selector: 'hl-select-mock-user',
  template: `
    <button
      class="button ln-hero-block__action"
      (click)="onChange(usUser)"
      *ngIf="!showAsMenu"
    >
      US Version
    </button>
    <button
      class="button ln-hero-block__action"
      (click)="onChange(arabUser)"
      *ngIf="!showAsMenu"
    >
    EUROPE
    </button>
    <a class="menu__item" (click)="onChange(usUser)" *ngIf="showAsMenu"
      >US Version</a
    >
    <a class="menu__item" (click)="onChange(arabUser)" *ngIf="showAsMenu"
      >EUROPE</a
    >
  `
})
export class SelectMockUserComponent {
  @Input() showAsMenu = false;

  users = CdUsers;
  usUser = CdUSUser.gid;
  gbUser = CdGBUser.gid;
  arabUser = CdArabUser.gid;

  onChange(userGid) {
    localStorage.setItem('mockUserGid', userGid);
    window.location.href = '/';
  }
}
