import { UpdateStatus } from '../models/update-status';
import { Message } from '../models/message';

export class MessageViewModel {
  readonly title: string;
  private readonly category: string;
  private readonly creationDate: string;
  private readonly dueDate: string;
  private readonly viewDate: string;
  readonly content: string;
  private readonly messageStatus: string;
  readonly messageType: string;
  readonly identifier: string;
  readonly cvssScore: string;
  readonly advisoryId: string;
  readonly lastUpdated: string;
  readonly description: string;

  constructor(model: Message) {
    this.title = model.title;
    this.category = model.category;
    this.creationDate = model.creationDate;
    this.dueDate = model.dueDate;
    this.viewDate = model.viewDate;
    this.content = model.content;
    this.messageStatus = model.status;
    this.messageType = model.messageType;
    this.identifier = model.identifier;
    this.cvssScore = model.cvssScore;
    this.advisoryId = model.advisoryId;
    this.lastUpdated = model.lastUpdated;
    this.description = model.description;
  }

  get updateStatusColor(): string {
    switch (this.messageStatus) {
      case UpdateStatus.Pending:
        return MessageStatusColor.Green;
      case UpdateStatus.Overdue:
        return MessageStatusColor.Yellow;
      case UpdateStatus.LongOverdue:
        return MessageStatusColor.Red;
    }
  }

  get categoryKey(): string {
    if (this.category) {
      return `LABEL_EVENT_CATEGORY_${this.category}`;
    }
  }

  get viewed(): string {
    return this.viewDate;
  }
}

export enum MessageStatusColor {
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red'
}
