import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateSaModalComponent } from 'app/shared/modal-popup/create-sa-modal/create-sa-modal.component';

@Component({
  selector: 'hl-create-sao-entry-page',
  templateUrl: './create-sao-entry-page.component.html',
  styles: []
})
export class CreateSaoEntryPageComponent implements OnInit {

  @ViewChild('createSaModal', { static: false })
  createSaModal: CreateSaModalComponent;
  equipmentKey: string;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    if (this.route.snapshot.params['id']) {
      this.equipmentKey = this.route.snapshot.params['id'];
    }
  }
}
