import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CountryConfigRestService } from '../../core/rest-services/country-config-rest.service';
import { AffectedEquipment } from '../../core/models/systemUpdates/affected-equipment';
import { BrowserStateService } from '../../core/services/browser-state.service';
import { SystemUpdatesService } from '../../core/services/system-updates/system-updates-service';
import { SystemUpdateDetailViewModel } from '../../core/view-models/system-update-detail-view-model';
import { UserUtilService } from 'app/core/services/user/user-util.service';
import { roles } from 'app/core/core-constants.service';
import { IcCodeRestService } from '../../core/rest-services/ic-code-rest.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, flatMap } from 'rxjs/operators';

const translateKeys = ['GENERIC_LABEL_CONTENT'];

@Component({
  selector: 'hl-system-updates-download',
  templateUrl: './system-updates-download.component.html'
})
export class SystemUpdatesDownloadComponent implements OnInit, OnDestroy {
  systemUpdateDetailViewModel: SystemUpdateDetailViewModel;
  isOpen: boolean;
  showEquipmentDropdown: boolean;
  private _labels = {};
  config: any;

  mockModalIsOpen: boolean = false;

  get labels(): any {
    return this._labels;
  }

  private subscription: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private configService: CountryConfigRestService,
    private systemUpdatesService: SystemUpdatesService,
    private icCodeRestService: IcCodeRestService,
    private browserStateService: BrowserStateService,
    private router: Router,
    private userUtilService: UserUtilService,
    private translateService: TranslateService
  ) {
  }

  get isLoaded() {
    return this.config && this.systemUpdateDetailViewModel;
  }

  ngOnInit() {
    this.subscription.add(this.setConfig());
    this.subscription.add(this.checkUserRoles());
    this.subscription.add(this.refreshSystemUpdateDetail());
    this.subscription.add(this.getLabels());
    this.isOpen = false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private setConfig() {
    return this.configService.getConfig().subscribe(config => {
      this.config = config;
    });
  }

  checkUserRoles() {
    const rolesToCheck = {
      itAdminRole: roles.itAdminRole
    };

    return this.userUtilService
      .checkUserRoles(rolesToCheck)
      .subscribe(({itAdminRole}) => {
        this.showEquipmentDropdown = !!itAdminRole;
      });
  }

  private refreshSystemUpdateDetail() {
    return this.route.params
      .pipe(
        filter(params => params && (params.updateNumber || params.id)),
        flatMap(params => {
          const updateNumber = params.updateNumber || params.id;
          // to prevent from showing details of previous update
          this.systemUpdateDetailViewModel = undefined;
          return this.systemUpdatesService.getSystemUpdateDetail(updateNumber);
        })
      ).subscribe(systemUpdateDetail => {
        this.systemUpdateDetailViewModel = systemUpdateDetail;
      });
  }

  private getLabels() {
    return this.translateService
      .get(translateKeys)
      .subscribe(values => (this._labels = values));
  }

  navigateTo(equipment: AffectedEquipment) {
    this.browserStateService.setUserNavigation();
    this.router
      .navigate(['/equipment', equipment.key, 'update'], {
        queryParams: {
          equipmentIdentifier: equipment.siemensId
        }
      })
      .then(() => {
        this.browserStateService.resetUserNavigation();
      });
  }

  showContent() {
    return this.systemUpdateDetailViewModel.packageInfo && this.systemUpdateDetailViewModel.packageInfo.content;
  }

  closeMockModal() {
    this.mockModalIsOpen = false;
  }

  downloadPackage(systemUpdatesViewModel: SystemUpdateDetailViewModel) {
    // Mock WIN10 update, updateNumer: "CT201/19/R"
    if (systemUpdatesViewModel.updateNumber === "CT201/19/R") {
      this.mockModalIsOpen = true;
      return;
    }
    this.systemUpdatesService.downloadPackage(
      systemUpdatesViewModel.updateNumber,
      systemUpdatesViewModel.packageInfo.packageName
    );
  }

  trackByFn(index, item: SystemUpdateDetailViewModel) {
    return item.updateNumber;
  }
}
