import { Component, OnInit, Input } from '@angular/core';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';

@Component({
    selector: 'hl-list-counter',
    templateUrl: './list-counter.component.html'
})
export class ListCounterComponent implements OnInit {

    @Input() filteredLength: number;
    @Input() filteredResultLengthWithoutPagination: number;
    @Input() title: string;
    @Input() dataCy: string;

    constructor(public lifeNetUtilService: LifeNetUtilService) {
    }

    ngOnInit() {
    }
}
