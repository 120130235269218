import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { XAxisTicksComponent } from '@swimlane/ngx-charts';

@Component({
  selector: 'g[diagram-x-axis-custom]',
  template: `
    <svg:g [attr.class]="xAxisClassName" [attr.transform]="transform">
      <svg:g ngx-charts-x-axis-ticks *ngIf="xScale" [trimTicks]="trimTicks" [rotateTicks]="rotateTicks" [maxTickLength]="maxTickLength"
        [tickFormatting]="tickFormatting" [tickArguments]="tickArguments" [tickStroke]="tickStroke" [scale]="xScale" [orient]="xOrient"
        [showGridLines]="showGridLines" [gridLineHeight]="dims.height" [width]="dims.width" [tickValues]="ticks"
        (dimensionsChanged)="emitTicksHeight($event)"/>
      <svg:g ngx-charts-axis-label *ngIf="showLabel" [label]="labelText" [offset]="labelOffset" [orient]="xOrient" [height]="dims.height"
        [width]="dims.width"></svg:g>
    </svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagramXAxisCustomComponent implements OnChanges {
  @Input() xScale;
  @Input() dims;
  @Input() trimTicks;
  @Input() rotateTicks = true;
  @Input() maxTickLength: number;
  @Input() tickFormatting;
  @Input() showGridLines = false;
  @Input() showLabel;
  @Input() labelText;
  @Input() ticks: any[];
  @Input() xAxisTickInterval;
  @Input() xAxisTickCount: any;
  @Input() xOrient = 'top';
  @Input() xAxisOffset = 0;

  @Output() dimensionsChanged = new EventEmitter();

  xAxisClassName = 'x axis';

  tickArguments: any;
  transform: any;
  labelOffset = 0;
  tickStroke = '#ccc';
  padding = 5;

  @ViewChild(XAxisTicksComponent, {static: false}) ticksComponent: XAxisTicksComponent;

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  update(): void {
    const offset = this.xOrient === 'top' ? -35 : this.xAxisOffset + this.padding + this.dims.height;
    this.transform = `translate(0, ${offset})`;

    if (typeof this.xAxisTickCount !== 'undefined') {
      this.tickArguments = [this.xAxisTickCount];
    }
  }

  emitTicksHeight({height}): void {
    const newLabelOffset = this.xOrient === 'top' ? 0 : height + 30;
    if (newLabelOffset !== this.labelOffset) {
      this.labelOffset = newLabelOffset;
      setTimeout(() => {
        this.dimensionsChanged.emit({height});
      }, 0);
    }
  }
}
