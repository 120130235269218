import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PartnerOrdersRestService } from './rest-services/partner-orders-rest.service';
import { PartnerOrder } from './models/partner-order';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { LifeNetUtilService } from '../core/utils/life-net-util.service';
import { PartnerOrdersConstantsService } from './services/partner-orders-constants.service';
import { Router } from '@angular/router';
import { BaseListView } from '../core/base-class/base-list-view';
import { EquipmentUtilService } from '../core/services/equipment/equipment-util.service';
import { UserUtilService } from '../core/services/user/user-util.service';
import { Observable, Subscription } from 'rxjs';
import { CloseWithdrawPartnerOrderEventService } from './component-communication-services/close-withdraw-partner-order-event.service';
import { PartnerOrderCloseModalComponent } from './modal-popup/partner-order-close-modal/partner-order-close-modal.component';
import { PartnerOrderWithdrawModalComponent } from './modal-popup/partner-order-withdraw-modal/partner-order-withdraw-modal.component';
import { StateService } from '../core/services/state.service';
import { LogService } from '../core/services/log/log.service';
import { OverlayComponent } from '../shared/components/overlay/overlay.component';
import { BrowserStateService } from '../core/services/browser-state.service';
import { FilterUtilService } from '../core/utils/filter-util.service';

export const partnerOrderStatusOpen = '1';

@Component({
    selector: 'hl-partner-orders',
    templateUrl: './partner-orders.component.html'
})
export class PartnerOrdersComponent extends BaseListView<PartnerOrder> implements OnInit, OnDestroy {

    @ViewChild('closeModal', { static: false }) closeModal: PartnerOrderCloseModalComponent;
    @ViewChild('withdrawModal', { static: false }) withdrawModal: PartnerOrderWithdrawModalComponent;
    @ViewChild('detailOverlay', { static: false }) detailOverlay: OverlayComponent;

    selectedDropDownList = {
        orderStatus: []
    };

    optionsDropDownList = {
        orderStatus: []
    };

    partnerOrderReceiver: string;
    partnerOrderSubject: string;

    partnerOrderEventSubscription: Subscription;

    // this variable will contain the partnerOrder, for which the quickMenu is opened
    tmpMenuItem: PartnerOrder;

    constructor(private partnerOrdersRestService: PartnerOrdersRestService,
        router: Router,
        configService: CountryConfigRestService,
        lifeNetUtilService: LifeNetUtilService,
        private partnerOrdersConstantsService: PartnerOrdersConstantsService,
        equipmentUtilService: EquipmentUtilService,
        userUtilService: UserUtilService,
        stateService: StateService,
        private closeWithdrawPartnerOrderEventService: CloseWithdrawPartnerOrderEventService,
        logService: LogService,
        browserStateService: BrowserStateService,
        filterUtilService: FilterUtilService) {
        super(configService, equipmentUtilService, userUtilService, stateService, logService, router,
            browserStateService, filterUtilService);

        this.sortSkeleton = partnerOrdersConstantsService.getSortSkeleton();
    }

    ngOnInit() {
        super.init();

        this.registerEventListeners();
    }

    ngOnDestroy() {
        if (this.partnerOrderEventSubscription) {
            this.partnerOrderEventSubscription.unsubscribe();
        }

        super.destroy();
    }

    getEmptyListUrl(): string {
        return '/partnerOrders';
    }


    registerEventListeners(): void {
        // in case of 'close' and 'withdraw' the partnerOrder should be removed from the list
        this.partnerOrderEventSubscription = this.closeWithdrawPartnerOrderEventService
            .removePartnerOrderSource$.subscribe((removedPartnerOrder) => {
                setTimeout(() => {
                    // possibly the utils services (which modifies the cache) is called before
                    // that's why we do the refresh after a timeout
                    this.initLoadViewModelList();
                }, 500);
            });
    }

    /**
     * initialize the possible values based on the result of the parterOrders Rest call
     * @param result
     */
    initAdvancedFilterDropDownList(): void {
        // properties to be filtered from dataset
        const dropDownListStructure = {
            orderStatus: 'orderStatus'
        };

        const dropDownOptions = this.filterUtilService.computeDropdownOptions(this.rawList, dropDownListStructure);
        this.optionsDropDownList.orderStatus = this.partnerOrdersConstantsService.getOrderStatusSkeleton();
    }

    afterInitViewModelList(): void {
        // no additional values needed - just implemented to satisfy abstract method
        this.onAdvancedFilterChange();
    }

    afterInitProperties() {

        this.partnerOrderReceiver = '';
        this.partnerOrderSubject = '';

        const now = this.getNow();
        let startDate = this.getNow().setMonth(now.getMonth() - 1);
        startDate = new Date(startDate).setDate(1);
        const toDate = this.getNow().setMonth(now.getMonth() + 3);

        this.dateRange.fromDate = new Date(startDate);
        this.dateRange.toDate = new Date(toDate);

        // preselect partner orders of status open
        this.selectedDropDownList.orderStatus.push(partnerOrderStatusOpen);
    }

    afterConfigProperties(config) {
        this.partnerOrderReceiver = config.PARTNER_ORDER_REQUEST_EMAIL_ADDRESS;
        this.partnerOrderSubject = config.PARTNER_ORDER_REQUEST_SUBJECT;
    }

    getNow(): Date {
        return new Date();
    }

    getPageSizeConfig(config): string {
        return config.OPEN_TICKETS_LIST_PAGE_SIZE;
    }

    loadViewModelList(): Observable<PartnerOrder[]> {
        return this.partnerOrdersRestService.getPartnerOrders();
    }

    navigate() {
        this.router.navigate(['/partnerOrders', this.selectedItem.ticketNumber, 'overview']);
    }

    openPartnerOrderCloseModal(partnerOrder: PartnerOrder) {
        this.tmpMenuItem = partnerOrder;
        this.closeModal.show();
    }

    openPartnerOrderWithdrawModal(partnerOrder: PartnerOrder) {
        this.tmpMenuItem = partnerOrder;
        this.withdrawModal.show();
    }

    getFilterObject(): any {
        return {
            search: {
                searchValue: this.searchInput,
                searchColumns: [
                    'productName',
                    'siemensEquipmentId',
                    'customerName',
                    'orderNumber',
                    'serialNumber',
                    'ticketNumber',
                    'city'
                ]
            },
            advancedMultiSelect: this.selectedDropDownList,
            orderBy: this.sortSkeleton.sortObject,
            dateRange: {
                rangeOfDate: this.dateRange,
                propertyKey: 'dueDate'
            }
        };
    }

    shouldSelectActive() {
        return false;
    }

    /**
     * @description
     * @param favoriteObj
     * Note: No favorites for this module
     */
    setDerivedBoundPropertiesFromFavorite(favoriteObj: any) {
    }

    /**
     * @description set properties from router query params
     */
    setPropertiesFromQueryParams(config: any) {
    }

    /**
     *
     * Note:- Cannot be moved to Base List
     * https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
     * @param index
     * @param {EquipmentViewModel} item
     * @returns {any}
     */
    trackByFn(index, item: PartnerOrder) {
        return item['ticketNumber'];
    }

    openDetailOverlay(item: PartnerOrder) {
        this.onClickUpdateSelectedItem(item);
        this.detailOverlay.show();
    }
}
