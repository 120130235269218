import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { restEndPoint } from '../core-constants.service';
import { SecurityNotifications } from '../models/securityNotifications/security-notifications';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class SecurityNotificationsRestService {
    securityNotificationsRestName = restEndPoint + 'security-notifications';
    securityNotificationByIDRestName = restEndPoint + 'security-notifications/:notificationID';

  constructor(private http: CacheableHttpClient) {
  }

  getSecurityNotifications(offset: number, limit: number): Observable<SecurityNotifications[]> {
    const httpParams = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString());

      return this.http.get<SecurityNotifications[]>(this.securityNotificationsRestName, {params: httpParams});
  }

  getEquipmentNotificationByID(notificationID: string): Observable<SecurityNotifications> {
      const url = this.securityNotificationByIDRestName.replace(/:notificationID/, notificationID);
    return this.http.get<SecurityNotifications>(url);
  }

}
