import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[hlDropdownOpener]'
})
export class DropdownCloserDirective {

  @Input() selectedItem = null;
  @Input() itemRef = null;
  @Output() onDropDownClosed = new EventEmitter<any>();

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (this.selectedItem === this.itemRef && !this.elementRef.nativeElement.contains(event.target)) {
      this.onDropDownClosed.emit(this.itemRef);
    }
  }

  constructor(private elementRef: ElementRef) {
  }
}
