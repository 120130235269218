import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataItem, Series } from '@swimlane/ngx-charts';
import { DateUtilService } from 'app/core/utils/date-util.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { identity } from 'lodash';
import { WindowService } from 'app/core/window.service';
import { EquipmentRestService } from 'app/core/rest-services/equipment-rest.service';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { EquipmentEnvironmentData } from 'app/core/models/equipment/equipment-environment';
import { EquipmentEnvironmentCommunicationService } from './equipment-environment-communication/equipment-environment-communication.service';

@Component({
  selector: 'hl-equipment-environment-chart',
  template: `
    <p hl-diagram-timeline *ngIf="results$ | async as results; else loading"
      [zoomDomain]="zoomDomain$ | async" [results]="[results]" [scheme]="{ domain: [color]}"
      [xAxis]="true" [showYAxisLabel]="true" [xAxisTickFormatting]="formatX" [yAxis]="true"
      [yAxisLabel]="results.name" [yAxisTickFormatting]="formatY" [autoScale]="true"
      [timeline]="true" [showGridLines]="true" [roundDomains]="true" [legend]="true" legendTitle=""
      [gradient]="false" [animations]="false" (domainZoomed)="domainZoomed($event)"
      class="diagram--timeline">
      <ng-template #tooltipTemplate let-model="model">
        <div class="area-tooltip-container">
          {{ model.name | datePipeWrapper: dateTimePattern }} &bull;
          <b>{{ formatY(model.value) }}</b>
        </div>
      </ng-template>
      <ng-template #seriesTooltipTemplate let-model="model">
        <div class="area-tooltip-container">
          <div *ngFor="let tooltipItem of model" class="tooltip-item">
            {{ tooltipItem.name | datePipeWrapper: dateTimePattern }} &bull;
            <b>{{ formatY(tooltipItem.value) }}</b>
          </div>
        </div>
      </ng-template>
    </p>
    <ng-template #loading>
      <hl-progress-indicator [loaded]="true"></hl-progress-indicator>
    </ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentEnvironmentChartComponent implements OnInit, OnDestroy {

  private readonly unsubscribe = new Subject();
  private source: string;
  private datePattern = 'DD/MM';
  private timePattern = 'HH:SS';

  private originalDomain: [Date, Date];

  dateTimePattern: string;
  results$: Observable<Series>;
  zoomDomain$: Observable<[Date, Date]>;

  @Input() sensorId: string;
  @Input() sensor: string;
  @Input() equipmentKey: string;
  @Input() parameter: string;
  @Input() parameterTitle: string;

  formatY: (x: any) => string = identity;

  formatX = (value: Date) => {
    const pattern = value.getHours() === 0 && value.getMinutes() === 0 ? this.datePattern : this.timePattern;
    return this.dateUtilService.toStringWithPattern(pattern, value);
  }

  get color() {
    return this.parameter && this.parameter.toUpperCase() === 'TEMPERATURE' ? '#ec6602' : '#3abfed';
  }

  constructor(
    private windowsService: WindowService,
    private equipmentRestService: EquipmentRestService,
    private dateUtilService: DateUtilService,
    private commService: EquipmentEnvironmentCommunicationService,
    private configService: CountryConfigRestService
  ) {
  }

  ngOnInit() {
    const requestTemplate = {
      sensorId: this.sensorId,
      parameter: this.parameter,
      parameterTitle: this.parameterTitle,
      timezone: this.dateUtilService.getBrowserTimeZone(this.windowsService.nativeWindow)
    };
    const equipmentKey = this.equipmentKey;

    this.results$ = this.configService.getConfig().pipe(
      tap(c => {
        this.dateTimePattern = c.GENERIC_DATE_TIME_PATTERN;
        this.datePattern = c.GENERIC_DATE_PATTERN;
        this.timePattern = c.GENERIC_TIME_PATTERN;
      }),
      map(c => {
        const displayDays = +c.EQUIPMENT_ENVIRONMENT_DISPLAY_DAYS || 30;
        const dateTo = this.dateUtilService.now();
        const dateFrom = this.dateUtilService.now();
        dateFrom.setDate(dateTo.getDate() - displayDays);
        return {dateFrom, dateTo};
      }),
      tap(({dateFrom, dateTo}) => this.originalDomain = [dateFrom, dateTo]),
      switchMap(({dateFrom, dateTo}) => {
        const request = {
          ...requestTemplate,
          dateFrom: dateFrom.getTime(),
          dateTo: dateTo.getTime()
        };
        return this.equipmentRestService.getEquipmentEnvironmentData(equipmentKey, request).pipe(
          tap(results => this.setFormat(results)),
          map(result => ({
            name: request.parameterTitle,
            series: this.getSeries(result)
          }))
        );
      }),
      takeUntil(this.unsubscribe)
    );

    const source = this.source = this.sensorId + '-' + this.parameter;
    this.zoomDomain$ = this.commService.domainUpdates$.pipe(
      filter(u => u.source !== source),
      map(u => u.domain || this.originalDomain),
      takeUntil(this.unsubscribe)
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setFormat(result: EquipmentEnvironmentData) {
    const unit = result.unit.replace('\\u00b0', '°');
    const unitFormatted = (unit.startsWith('°') ? '' : ' ') + unit;
    this.formatY = (x: any) => `${x}${unitFormatted}`;
  }

  private getSeries(result: EquipmentEnvironmentData): DataItem[] {
    return (result.data || []).map(d => ({...d, name: new Date(d.name)})) as DataItem[];
  }

  domainZoomed(domain: [Date, Date]) {
    this.commService.updateDomain({source: this.source, domain});
  }
}
