import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'hl-create-sao-shipping-address',
  templateUrl: './create-sao-shipping-address.component.html'
})
export class CreateSaoShippingAddressComponent implements OnInit {

  @Input() shippingForm: FormGroup;
  @Input() isFormSubmitted: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
