import { Injectable } from '@angular/core';
import { Activities } from '../../models/activities/activities';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class ActivitiesStatusDescriptionTranslationService {
  constructor(private translateService: TranslateService) {
  }

  /**
   * Method returns translated pmDescription for the activity
   * @param activity
   */
  getActivityStatusDescriptionTranslation(activity: Activities): Observable<string> {
    return this.translateService.get(this.getActivityStatusDescriptionTranslationKey(activity));
  }

  getActivityStatusDescriptionTranslationKey(activity: Activities): string {
    if (!activity.scheduled && activity.pmStatus === '0') {
      return 'PLANNED_ACTIVITIES_NOT_SCHEDULED';
    } else if (activity.pmStatus) {
      return 'LABEL_ACTIVITY_STATUS_' + activity.pmStatus;
    } else {
      return 'PLANNED_ACTIVITIES_NOT_SCHEDULED';
    }
  }
}
