import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { calculateViewDimensions, ViewDimensions } from '@swimlane/ngx-charts';
import { scaleBand, scaleLinear } from 'd3-scale';
import { animate, style, transition, trigger } from '@angular/animations';
import { SeriesData } from '../diagram';
import { DiagramBarDoubleAxisBaseComponent } from './diagram-bar-double-axis-base.component';

@Component({
  selector: 'hl-diagram-bar-double-axis-horizontal',
  templateUrl: './diagram-bar-double-axis-horizontal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./base-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
          transform: '*'
        }),
        animate(500, style({opacity: 0, transform: 'scale(0)'}))
      ])
    ])
  ]
})
export class DiagramBarDoubleAxisHorizontalComponent extends DiagramBarDoubleAxisBaseComponent {
  @Input() animations: boolean;
  @Input() legend = false;
  @Input() legendTitle = 'Legend';
  @Input() legendPosition = 'below';

  @Input() xMainAxisShowLabel: boolean;
  @Input() xSecondaryAxisShowLabel: boolean;
  @Input() yAxisLabel: string;
  @Input() xMainAxisLabel: string;
  @Input() xSecondaryAxisLabel: string;
  @Input() xAxis;
  @Input() yAxis;
  @Input() showYAxisLabel;
  @Input() tooltipDisabled = false;
  @Input() gradient: boolean;
  @Input() showGridLines = true;
  @Input() activeEntries: any[] = [];
  @Input() scheme: any;
  @Input() schemeType: string;
  @Input() trimXAxisTicks = true;
  @Input() trimYAxisTicks = true;
  @Input() rotateXAxisTicks = true;
  @Input() maxXAxisTickLength = 16;
  @Input() maxYAxisTickLength = 16;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() xAxisTicks: any[];
  @Input() yAxisTicks: any[];
  @Input() groupPadding = 16;
  @Input() barPadding = 8;
  @Input() roundDomains = false;
  @Input() roundEdges = true;
  @Input() xScaleMax: number;
  @Input() showDataLabel = false;
  @Input() dataLabelFormatting: any;
  @Input() noBarWhenZero = true;
  @Input('results') chartsData: any;
  @Input() formatMainAxisDates = true;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate', {static: false}) tooltipTemplate: TemplateRef<any>;

  dims: ViewDimensions;
  groupDomain: any[];
  groupScale: any;
  innerScale: any;
  valueMainScale: any;
  valueSecondaryScale: any;
  transform: string;
  margin = [10, 20, 10, 20];
  legendOptions: any;
  dataLabelMaxWidth: any = {negative: 0, positive: 0};

  dataMainAxis: SeriesData[];
  dataSecondaryAxis: SeriesData[];

  update(): void {
    this.axisScaleMax = this.xScaleMax;

    this.dataSplit();
    super.update();

    if (!this.showDataLabel) {
      this.dataLabelMaxWidth = {negative: 0, positive: 0};
    }

    this.margin = [10, 20 + this.dataLabelMaxWidth.positive, 10, 20 + this.dataLabelMaxWidth.negative];

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.xMainAxisShowLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    if (this.formatMainAxisDates) {
      this.formatDates();
    } else {
      this.copySeriesNamesToLabels();
    }

    this.updateTooltips();

    this.groupDomain = this.getGroupDomain();
    this.innerDomain = this.getInnerDomain();

    this.valuesMainDomain = this.getValueDomain(this.dataMainAxis);
    this.valueMainScale = this.getValueScale(this.valuesMainDomain);

    if (this.dataSecondaryAxis.length) {
      this.valuesSecondaryDomain = this.getValueDomain(this.dataSecondaryAxis);
      this.valueSecondaryScale = this.getValueScale(this.valuesSecondaryDomain);
      this.margin[0] += 15;
    }

    this.groupScale = this.getGroupScale();
    this.innerScale = this.getInnerScale();

    this.setColors();
    this.legendOptions = this.getLegendOptions();

    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;
  }

  getGroupScale(): any {
    const spacing = this.groupDomain.length / (this.dims.height / this.groupPadding + 1);

    return scaleBand()
      .rangeRound([0, this.dims.height])
      .paddingInner(spacing)
      .paddingOuter(spacing / 2)
      .domain(this.groupDomain);
  }

  getInnerScale(): any {
    const height = this.groupScale.bandwidth();
    const spacing = this.innerDomain.length / (height / this.barPadding + 1);

    return scaleBand().rangeRound([0, height]).paddingInner(spacing).domain(this.innerDomain);
  }

  getValueScale(valuesDomain: any): any {
    const scale = scaleLinear()
      .range([0, this.dims.width])
      .domain(valuesDomain);

    return this.roundDomains ? scale.nice() : scale;
  }

  groupTransform(group) {
    return `translate(0, ${this.groupScale(group.label)})`;
  }

  onDataLabelMaxWidthChanged(event, groupIndex) {
    if (event.size.negative) {
      this.dataLabelMaxWidth.negative = Math.max(this.dataLabelMaxWidth.negative, event.size.width);
    } else {
      this.dataLabelMaxWidth.positive = Math.max(this.dataLabelMaxWidth.positive, event.size.width);
    }
    if (groupIndex === this.results.length - 1) {
      setTimeout(() => this.update());
    }
  }
}
