import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { filter } from 'lodash';
import { FavoriteRestService } from '../../../core/rest-services/favorite-rest.service';
import { AddRemoveFavoriteEventService } from '../../../core/component-communication-services/add-remove-favorite-event/add-remove-favorite-event.service';

@Component({
  selector: 'hl-filter-tabs',
  templateUrl: './filter-tabs.component.html'
})
export class FilterTabsComponent implements OnInit, OnDestroy {

  @Input() isMyInstitutions: false;
  @Input() listType: string;

  protected readonly unsubscribe$: Subject<void> = new Subject();

  FILTER_OPTION_FILTER = 'Filter';
  FILTER_OPTION_SAVED_FILTERS = 'SavedFilters';
  selectedFilterOption = this.FILTER_OPTION_FILTER;
  numberOfFavorites$ = new ReplaySubject<string>(1);

  constructor(private favoriteRestService: FavoriteRestService,
    private addRemoveFavoriteEventService: AddRemoveFavoriteEventService) { }

  ngOnInit() {
    this.getNumberOfFavorites().subscribe(numberOfFavorites => {
      this.numberOfFavorites$.next(numberOfFavorites.toString());
    });

    this.addRemoveFavoriteEventService.addRemoveFavoriteSource$.pipe(takeUntil(this.unsubscribe$),
      switchMap(() => this.getNumberOfFavorites())).subscribe(numberOfFavorites => {
        this.numberOfFavorites$.next(numberOfFavorites.toString());
      });
  }

  getNumberOfFavorites(): Observable<number> {
    return this.favoriteRestService.getFavorites().pipe(map(favoritesResponse => {
      if (favoritesResponse) {
        return filter(favoritesResponse, {
          type: this.listType
        }).length;
      }
    }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
