import { Pipe, PipeTransform } from '@angular/core';
import { TableHeaderCell } from '../../../spr/configuration/table/configuration-table.component';

@Pipe({
  name: 'sprAlignText'
})
export class SprAlignTextPipe implements PipeTransform {
  transform(headerCell: TableHeaderCell, alignHeader: boolean = false, except: 'right' | 'left' | 'center' = null): string {
    if (headerCell && headerCell.config) {
      if (alignHeader && headerCell.config.headerCellTextAlign && except !== headerCell.config.headerCellTextAlign) {
        return headerCell.config.headerCellTextAlign;
      } else if (!alignHeader && headerCell.config.regularCellTextAlign && except !== headerCell.config.regularCellTextAlign) {
        return headerCell.config.regularCellTextAlign;
      }
    }
    return 'unset';
  }
}
