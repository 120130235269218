import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hl-ticket-detail-overview-tab',
  templateUrl: './ticket-detail-overview-tab.component.html'
})
export class TicketDetailOverviewTabComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
