import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'hl-group-buttons',
  templateUrl: './group-buttons.component.html'
})
export class GroupButtonsComponent {
  @Input()
  disabled = false;
  @Input()
  label: string;
  @Input()
  dataCy?: string;
  @Output()
  valueChange = new EventEmitter();

  constructor(private translate: TranslateService) {
  }

  private _options;

  get options(): DropdownOptions[] {
    return this._options;
  }

  @Input()
  set options(options: DropdownOptions[]) {
    this._options = options;
  }

  private _translateKeys?: boolean;

  @Input()
  set translateKeys(translateKeys: boolean) {
    this._translateKeys = translateKeys;
    this.updateTranslations();
  }

  private _allChecked?: boolean;

  get allChecked(): boolean {
    return this._allChecked;
  }

  @Input()
  set allChecked(allChecked: boolean) {
    this._allChecked = allChecked;
  }

  private _value: string[];

  get value() {
    return this._value;
  }

  @Input()
  set value(val: string[]) {
    this._value = val;
  }

  valueChanged() {
    this.valueChange.emit(this._value);
  }

  isSelected(option: any): boolean {
    if (this.allChecked) {
      this.selectAll();
      return true;
    } else {
      return this.value && this.value.indexOf(option) > -1;
    }
  }

  setSelected(event: Event, option: any) {
    event.stopPropagation();

    if (!this.value) {
      this.value = [];
    }

    const index = this.value.indexOf(option.value);
    if (index > -1) {
      this.value.splice(index, 1);
    } else {
      this.value.push(option.value);
    }
    this.value = this.value.slice();
    this.valueChange.emit(this._value);
    this.allChecked = false;
  }

  private updateTranslations() {
    if (this._options && this._translateKeys) {
      const translationKeys = this._options.map(option => option.title);
      this.translate.get(translationKeys).subscribe(res => {
        for (const option of this._options) {
          option.title = res[option.title];
        }
      });
    }
  }

  private selectAll() {
    const optionLength = this._options.length;
    if (this.value.length !== optionLength) {
      _.forEach(this._options, option => {
        if (!_.includes(this.value, option.value)) {
          this.value.push(option.value);
        }
      });
    }
  }
}
