import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { AddRootClassesService } from '../../../core/component-communication-services/add-root-classes/add-root-classes.service';
import { addedRootClasses } from '../../../core/core-constants.service';
import { UserRestService } from '../../../core/rest-services/user-rest.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { WindowService } from '../../../core/window.service';

@Component({
  selector: 'hl-terms-and-condition-modal',
  templateUrl: './terms-and-condition-modal.component.html'
})
export class TermsAndConditionModalComponent extends BaseModalPopup {

  window = this.windowService.nativeWindow;
  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  @Input() loaded: boolean;
  @Output() accepted = new EventEmitter<any>();
  _renderer: Renderer2;

  constructor(renderer: Renderer2,
              private windowService: WindowService,
              private userRestService: UserRestService,
              private lifeNetUtilService: LifeNetUtilService,
              private addRootClassService: AddRootClassesService) {
    super(renderer);
    this._renderer = renderer;
  }

  ok() {
    this.showSpinner = true;
    this.userRestService.postTermsNCondition()
      .pipe(
        finalize(() => {
          super.hide();
          this.accepted.emit();
        })
      )
      .subscribe(() => {
        this.addRootClassService.emitToAddRootClass(addedRootClasses.termsAndConditions);
      });
  }

  hide() {
    // since hl-authorized is added, we should remove it
    this.addRootClassService.emitToRemoveRootClass(addedRootClasses.authorized);
    this.lifeNetUtilService.invalidateSession();
  }
}
