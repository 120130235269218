import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Equipment } from '../../../../core/models/equipment/equipment';
import { OverlayComponent } from '../../../../shared/components/overlay/overlay.component';
import { DocumentsSourceOption } from '../../../../core/models/equipment/documents/documents-source-option';
import { defaultImagePath } from '../../../../core/core-constants.service';
import { EquipmentDocumentsWrapperComponent } from '../equipment-documents-wrapper/equipment-documents-wrapper.component';
import { switchMap } from 'rxjs/operators';
import { EquipmentUtilService } from '../../../../core/services/equipment/equipment-util.service';
import { DocumentSourceRestService } from '../../../../core/rest-services/document-source-rest.service';

@Component({
  selector: 'hl-equipment-documents-cards',
  templateUrl: './equipment-documents-cards.component.html'
})
export class EquipmentDocumentsCardsComponent implements OnInit {

  @ViewChild('documentsListOverlay', {static: false}) documentsListOverlay: OverlayComponent;

  @Input() documentsSourceOptions: DocumentsSourceOption[];
  @Input() selectedEquipment: Equipment;
  currentStateName: string;

  isLoaded = false;
  private enableOverlay = false;
  private readonly defaultImgSrc: string;

  constructor(
    private equipmentUtilService: EquipmentUtilService,
    private sourceRestService: DocumentSourceRestService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.defaultImgSrc = defaultImagePath;
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.activatedRoute.params.pipe(
      switchMap((params: any) => this.equipmentUtilService.getEquipment(params['id'])),
      switchMap((equipmentResponse: Equipment) => {
        this.selectedEquipment = equipmentResponse;
        return this.sourceRestService.getDocumentsSourceOptions(equipmentResponse.key);
      })
    ).subscribe((documentsSourceOptions: DocumentsSourceOption[]) => {
      if (documentsSourceOptions && documentsSourceOptions.length) {
        this.documentsSourceOptions = documentsSourceOptions;
        this.activatedRoute.routeConfig.children = [];

        documentsSourceOptions.forEach((option) => {
          if (!this.activatedRoute.routeConfig.children.some(route => route.path === option.id)) {
            this.activatedRoute.routeConfig.children.unshift({
              path: option.id,
              component: EquipmentDocumentsWrapperComponent,
              data: {
                stateName: this.activatedRoute.routeConfig.data.stateName + '-' + option.id,
                title: this.activatedRoute.routeConfig.data.title
              }
            });
          }
        });
      } else {
        this.documentsSourceOptions = [];
      }
    });
  }

  openOverlay(type: string) {
    this.currentStateName = this.activatedRoute.routeConfig.data.stateName + ((!!type) ? ('-' + type) : '');
    this.enableOverlay = true;
    this.router.navigate([this.router.url, type])
      .then(() => this.documentsListOverlay.show());
  }

  closeOverlay(event) {
    this.enableOverlay = false;
    this.documentsListOverlay.hide(event);
  }
}
