import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CheckPermissionOrRoleService, DASHBOARD } from './check-permission-or-role.service';
import { tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';

export abstract class GeneralRoleCanActivateTabsService implements CanActivate {

  protected abstract enableTab: ReplaySubject<boolean>;

  protected constructor(
    protected router: Router,
    protected checkPermissionOrRoleService: CheckPermissionOrRoleService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.enableTab.pipe(tap(value => !!value
      || this.router.navigate([DASHBOARD], {replaceUrl: true})));
  }
}
