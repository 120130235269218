import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { trainingsRestName } from './trainings-rest-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Training } from '../models/training';

@Injectable()
export class TrainingsRestService {

  constructor(private http: CacheableHttpClient) {
  }

  getEquipmentTrainings(equipmentKey: string): Observable<Training[]> {
    return this.http.get<Training[]>(trainingsRestName, {
      params: new HttpParams().set('equipmentKey', equipmentKey)
    });
  }

}
