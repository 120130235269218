import { Component } from '@angular/core';

@Component({
  selector: 'hl-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {

  constructor() { }

}
