import { CalendarOptions } from '../../core/models/activities/calendar-options';
import { ActivitiesViewModel } from '../../core/view-models/activities-view-model';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import * as _ from 'lodash';
import { DateUtilService } from 'app/core/utils/date-util.service';

@Component({
  selector: 'hl-calendar-year',
  templateUrl: './calendar-year.component.html'
})
export class CalendarYearComponent implements OnInit, OnChanges {
  @Input()
  events: ActivitiesViewModel[];

  @Input()
  options: CalendarOptions;

  @Input()
  yearWrapper: boolean;

  @Output()
  onEventSelect: EventEmitter<string> = new EventEmitter<string>();

  minDate = null;
  maxDate = null;
  defaultDate;
  selectedYear;
  months = [];
  isAllExpanded = false;

  constructor(private dateUtilService: DateUtilService) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['events']) {
      this.updateOptionsDate();
    }
  }

  emitEvent(event: any) {
    this.onEventSelect.emit(event);
  }

  /**
   * @ngdoc method
   * @name init
   */
  init() {
    const months = this.options.months || [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    const monthSkeleton: { [k: string]: any } = {};
    const currentDate = this.dateUtilService.now();
    const currentMonth = currentDate.getMonth();

    _.forEach(months, (monthName, monthIndex) => {
      monthSkeleton.monthName = monthName;
      monthSkeleton.monthIndex = monthIndex;
      monthSkeleton.isExpanded = false;

      // push & extend options
      monthSkeleton.options = _.clone(this.options);

      this.defaultDate = monthSkeleton.options.defaultDate
        ? this.dateUtilService.copy(monthSkeleton.options.defaultDate)
        : currentDate;

      // set date to the first of each month
      this.defaultDate.setMonth(monthIndex, 1);

      if (this.defaultDate.getMonth() === currentMonth) {
        this.defaultDate.setMonth(monthIndex, currentDate.getDate());
        monthSkeleton.isExpanded = true;
      }

      monthSkeleton.options.defaultDate = this.defaultDate;

      // push to Array
      this.months.push(_.clone(monthSkeleton));
    });

    this.isAllExpanded = false;
    this.selectedYear = this.defaultDate.getFullYear();

    if (this.options.minDate) {
      this.minDate = this.dateUtilService.copy(this.options.minDate);
    }
    if (this.options.maxDate) {
      this.maxDate = this.dateUtilService.copy(this.options.maxDate);
    }
  }

  /**
   * @ngdoc method
   * @name iterateAllMonthForToggleStates
   *
   * @description
   * iterates the months object after a month toggle event
   * sets the isAllExpanded flag based on result
   * the isAllExpanded flag is used to handle the button visibility of show/hide all
   */
  iterateAllMonthForToggleStates() {
    let countExpandedState = 0;

    // iterate all month for expanded states
    _.forEach(this.months, month => {
      countExpandedState += month.isExpanded;
    });

    // set expanded state
    if (countExpandedState === 0) {
      this.isAllExpanded = false;
    } else if (countExpandedState === 12) {
      this.isAllExpanded = true;
    }
  }

  /**
   *
   * @description
   * closes all months expanded states and resets the global scope boolean
   */
  closeAllMonths() {
    _.forEach(this.months, month => {
      month.isExpanded = false;
    });

    this.isAllExpanded = false;
  }

  /**
   * @param isOpened - false if closed | true - if opened
   * @param month - the toggled month
   */
  onToggle(isOpened: boolean, month: { [k: string]: any }) {
    month.isExpanded = isOpened;
  }

  /**
   *
   * @description
   * opens all months expanded states and sets the global scope boolean
   */
  openAllMonths() {
    _.forEach(this.months, month => {
      month.isExpanded = true;
    });

    this.isAllExpanded = true;
  }

  /**
   * @ngdoc method
   * @name allowedPrevYear
   *
   * @description
   * checks whether or not displaying data of the previous year is allowed
   */
  allowedPrevYear() {
    if (!this.minDate) {
      return true;
    }

    const prevYear = this.selectedYear - 1;

    if (prevYear < this.minDate.getFullYear()) {
      return false;
    }

    return true;
  }

  /**
   * @ngdoc method
   * @name allowedNextYear
   *
   * @description
   * checks whether or not displaying data of the next year is allowed
   */
  allowedNextYear() {
    if (!this.maxDate) {
      return true;
    }

    const nextYear = this.selectedYear + 1;

    if (nextYear > this.maxDate.getFullYear()) {
      return false;
    }

    return true;
  }

  /**
   *
   * @description
   * input trigger handler
   * subtracts 1 year of the current selected year, if the action is allowed
   */
  goPrevYear() {
    if (this.allowedPrevYear()) {
      this.selectedYear -= 1;
      this.updateOptionsDate();
    }
  }

  /**
   *
   * @description
   * input trigger handler
   * adds 1 year to the current selected year, if the action is allowed
   */
  goNextYear() {
    if (this.allowedNextYear()) {
      this.selectedYear += 1;
      this.updateOptionsDate();
    }
  }

  /**
   *
   * @description
   * updates the options date values
   */
  updateOptionsDate() {
    const tempMonth = _.clone(this.months);
    const tempEvent = _.clone(this.events);
    this.months = [];
    this.events = [];

    // update options date for month directives
    _.forEach(tempMonth, month => {
      month.options.defaultDate = this.dateUtilService.copy(
        month.options.defaultDate
      ).setFullYear(this.selectedYear);
      month.isExpanded = false;
    });

    this.isAllExpanded = false;

    // Note:- Here now the reference is changed, so that changes is reflected in calendar month component
    this.events = tempEvent;
    this.months = tempMonth;
  }
}
