import { Component, OnInit, Renderer2 } from '@angular/core';
import { PartnerOrdersRestService } from '../../rest-services/partner-orders-rest.service';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { CloseWithdrawPartnerOrderEventService } from '../../component-communication-services/close-withdraw-partner-order-event.service';
import { BasePartnerOrderForm } from '../../base-class/base-partner-order-form';
import { FormBuilder, Validators } from '@angular/forms';
import { restEndPoint } from '../../../core/core-constants.service';
import { DatePipeWrapperPipe } from '../../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';

export const partnerOrderActionClose = 'CLOSE';
const partnerOrderCloseRestName = restEndPoint + 'partnerOrders/:ticketNumber/close';
@Component({
  selector: 'hl-partner-order-close-modal',
  templateUrl: './partner-order-close-modal.component.html'
})
export class PartnerOrderCloseModalComponent extends BasePartnerOrderForm
  implements OnInit {
  constructor(
    partnerOrdersRestService: PartnerOrdersRestService,
    toasterService: ToasterService,
    closeWithdrawPartnerOrderEventService: CloseWithdrawPartnerOrderEventService,
    renderer: Renderer2,
    datePipeWrapperPipe: DatePipeWrapperPipe,
    protected fb: FormBuilder
  ) {
    super(
      renderer,
      partnerOrdersRestService,
      toasterService,
      closeWithdrawPartnerOrderEventService,
      datePipeWrapperPipe
    );
  }

  ngOnInit() {
    this.init();
  }

  getFormControl() {
    return this.fb.group({
      action: [partnerOrderActionClose],
      attachments: [[], [Validators.required]],
      commentText: [''],
      closureDate: [null, [Validators.required]]
    });
  }

  getActionUrl(): string {
    return partnerOrderCloseRestName.replace(
      /:ticketNumber/g,
      this.partnerOrder.ticketNumber
    );
  }

  getToasterTranslationKey(): string {
    return 'PARTNER_ORDER_CLOSE_SUCCESS';
  }
}
