import { combineLatest, Subject } from 'rxjs';
import { AssignedCustomer } from '../models/assigned-customer';
import { UserUtilService } from '../services/user/user-util.service';
import { UserRestService } from '../rest-services/user-rest.service';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import { EquipmentRestService } from '../rest-services/equipment-rest.service';
import { StateService } from '../services/state.service';
import { LifeNetUtilService } from '../utils/life-net-util.service';
import { BrowserStateService } from '../services/browser-state.service';
import { ServiceMetricsRestService } from '../rest-services/service-metrics-rest.service';
import { tap } from 'rxjs/operators';
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CheckPermissionOrRoleService } from '../auth-guards/check-permission-or-role.service';
import { roles } from '../core-constants.service';

export abstract class BaseMainNavRoleCheck implements OnDestroy {
  reportingLink: string;
  showReportingTab: boolean;
  showReportingLink: boolean;
  navCollapsed: boolean;
  isReportingLoaded: boolean;

  configService: CountryConfigRestService;
  userRestService: UserRestService;
  userUtilService: UserUtilService;
  equipmentRestService: EquipmentRestService;
  router: Router;
  stateService: StateService;
  lifeNetUtilService: LifeNetUtilService;
  browserStateService: BrowserStateService;
  metricsService: ServiceMetricsRestService;
  cdr: ChangeDetectorRef;

  headerTranslateLabel: string;
  currentStateName: string;
  showReportingComponent: boolean;
  showServiceMetricsComponent: boolean;
  protected readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(configService: CountryConfigRestService,
    userRestService: UserRestService,
    userUtilService: UserUtilService,
    metricsService: ServiceMetricsRestService,
    router: Router,
    stateService: StateService,
    equipmentRestService: EquipmentRestService,
    lifeNetUtilService: LifeNetUtilService,
    browserStateService: BrowserStateService,
    cdr: ChangeDetectorRef,
    protected checkPermissionOrRoleService: CheckPermissionOrRoleService) {
    this.configService = configService;
    this.userRestService = userRestService;
    this.userUtilService = userUtilService;
    this.equipmentRestService = equipmentRestService;
    this.metricsService = metricsService;
    this.router = router;
    this.stateService = stateService;
    this.lifeNetUtilService = lifeNetUtilService;
    this.browserStateService = browserStateService;
    this.cdr = cdr;
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  abstract setPropertiesFromQueryParams();

  /**
   * @description Initialize of call stack
   */
  init() {
    this.initProperties();

    this.setStateName();
    this.checkPermissionOrRoleService.loadingPermission();

    combineLatest([
      this.userUtilService.checkUserRoles(roles),
      this.configService.getConfig(),
      this.userRestService.getAssignedCustomers(),
      this.metricsService.isServiceMetricsAvailable()
    ]).pipe(tap(([checkRolesResponse, config, assignedCustomer, serviceMetrics]) => {

        if (checkRolesResponse.createTicketRole) {
          this.checkPermissionOrRoleService.createTicketRole.next(true);
          this.setPropertiesFromQueryParams();
        }

        if (checkRolesResponse.viewEPSReports && _.isEqual(config.REPORTS_RENDER, 'true')) {
          this.showReportingTab = true;

          this.checkReportingRoleAndCustomers(config, assignedCustomer, serviceMetrics);
          this.checkPermissionOrRoleService.numberOfTabs += 1;
        }
      }
    )).subscribe();
  }

  destroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @description Initialize view properties
   */
  initProperties() {
    this.reportingLink = '';
    this.navCollapsed = true;
    this.showReportingTab = false;
    this.showReportingLink = false;
    this.showReportingComponent = false;
    this.showServiceMetricsComponent = false;

    // default set to dashboard
    this.headerTranslateLabel = 'MY_DASHBOARD';
    // please check description of this method
    this.currentStateName = this.stateService.getStateNameFromWindowLocation();
  }

  setStateName() {
    this.stateService.getActiveStateName().subscribe(stateName => {
      if (stateName) {
        this.currentStateName = stateName;
      }
    });
  }

  /**
   * @description
   * Check if user has customers assigned and then set correct reporting link.
   * Otherwise, show Modal that no customers are assigned
   */
  checkReportingRoleAndCustomers(config, assignedCustomers: AssignedCustomer[], serviceMetricsAvailable: any) {
    this.showServiceMetricsComponent = false;
    this.showReportingComponent = false;
    if (assignedCustomers.length > 0) {
      if (serviceMetricsAvailable && serviceMetricsAvailable.available) {
        this.showServiceMetricsComponent = true;
      } else {
        this.showReportingComponent = _.isEqual(config.LIFENET_OWN_REPORTS_AVAILABLE, 'true');
        this.showReportingLink = !this.showReportingComponent;
        if (this.showReportingLink) {
          // extend link
          let reportingLinkSuffix = '?CUSTOMERS=';
          _.forEach(assignedCustomers, function (customer) {
            reportingLinkSuffix += customer.customerId + ',';
          });

          reportingLinkSuffix = _.trimEnd(reportingLinkSuffix, ',');
          this.reportingLink = config.LINK_REPORTING + reportingLinkSuffix;
        }
      }
    }
    this.isReportingLoaded = true;
    this.cdr.detectChanges();
  }

  /**
   *
   * @description
   * Go to the corresponding state (for e.g. equipment, tickets, etc)
   *
   * @param stateTo
   */
  stateGo(stateTo: string) {

    // translates the state to url by replacing '-' in the state with '/',
    // e.g. invoices-service to invoices/service.
    const state = this.stateService.getUrlFromStateName(stateTo);

    /**
     * if we are on same state or nested states with parent state instantiated,
     * no need to init it again.
     */
    if (!_.startsWith(this.currentStateName, stateTo)) {
      this.router.navigate([state]);
    }
  }
}
