import { DocumentsSourceOption } from '../../../../core/models/equipment/documents/documents-source-option';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DocumentFile } from '../../../../core/models/equipment/documents/document-file';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentNode } from '../../../../core/models/equipment/documents/document-node';
import { DocumentSourceRestService } from '../../../../core/rest-services/document-source-rest.service';
import { ActivatedRoute } from '@angular/router';
import { EquipmentUtilService } from '../../../../core/services/equipment/equipment-util.service';
import { Equipment } from '../../../../core/models/equipment/equipment';
import { EquipmentDocuments } from '../../../../core/models/equipment/equipment-documents';
import { NEVER, of, Subject } from 'rxjs';

@Component({
  selector: 'hl-equipment-documents-wrapper',
  templateUrl: './equipment-documents-wrapper.component.html'
})
export class EquipmentDocumentsWrapperComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  @Input() scrollableContainerClass: string;
  @Input() documentsSourceOptions: DocumentsSourceOption[] | null;
  @Input() useParentRoute = true;
  selectedEquipment: Equipment;
  isLoaded = false;
  showDocumentList = false;
  documents: DocumentFile[];
  documentNodes: DocumentNode[];
  currentSourceId: string;
  disableSimpleListToDocumentSource = ['dms-sic'];

  constructor(
    private equipmentUtilService: EquipmentUtilService,
    private sourceRestService: DocumentSourceRestService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    (this.useParentRoute ? this.activatedRoute.parent.params : this.activatedRoute.params).pipe(
      tap(() => this.isLoaded = false),
      switchMap((params: any) => this.equipmentUtilService.getEquipment(params['id'])),
      switchMap((equipmentResponse: Equipment) => {
        this.selectedEquipment = equipmentResponse;

        if (this.documentsSourceOptions && this.documentsSourceOptions.length === 1) {
          return of(this.documentsSourceOptions[0]);
        }
        return this.sourceRestService.getDocumentsSourceOptions(equipmentResponse.key).pipe(
          map((options: DocumentsSourceOption[]) => {
            const currentPath = this.activatedRoute.routeConfig.path;
            return options && options.find(option => option.id === currentPath);
          })
        );
      }),
      switchMap((selectedOption) => {
        if (!selectedOption) {
          this.setEmptyDocuments();
          return NEVER;
        }
        this.currentSourceId = selectedOption.id;
        return this.sourceRestService.getDocumentsFromSpecificSource(selectedOption.sourceUrl, this.selectedEquipment.key);
      }),
      map((equipmentDocuments: EquipmentDocuments) => equipmentDocuments.documents),
      takeUntil(this.unsubscribe$)
    ).subscribe((docs: DocumentNode[]) => {
      if (docs && docs.length > 0) {
        if (docs.length === 1 && !docs.some(d => !!d.details) && !this.disableSimpleListToDocumentSource.includes(this.currentSourceId)) {
          this.documents = docs[0].list;
          this.documentNodes = [];
          this.showDocumentList = true;
        } else {
          this.documents = [];
          this.documentNodes = docs;
          this.showDocumentList = false;
        }
        this.isLoaded = true;
      } else {
        this.setEmptyDocuments();
      }
    }, () => this.setEmptyDocuments());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setEmptyDocuments(): void {
    this.documents = [];
    this.documentNodes = [];
    this.showDocumentList = true;
    this.isLoaded = true;
  }

  shouldShowDocumentList(): boolean {
    return this.isLoaded && this.showDocumentList;
  }

  shouldShowDocumentCollapsible(): boolean {
    return this.isLoaded && !this.showDocumentList;
  }
}
