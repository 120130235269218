import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { IadvizeChatService } from '../../../core/component-communication-services/iadvize-chat/iadvize-chat.service';
import { ChatEquipmentModel } from '../../../core/models/chat/chat-equipment-model';
import { DropdownOptions } from '../../../core/models/dropdown-options';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { ChatUtilService } from '../../../core/services/chat/chat-util.service';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { LogService } from '../../../core/services/log/log.service';
import { EquipmentViewModel } from '../../../core/view-models/equipment-view-model';

@Component({
  selector: 'hl-chat-modal',
  templateUrl: './chat-modal.component.html'
})
export class ChatModalComponent extends BaseModalPopup implements OnInit, OnDestroy {

  isFormSubmitted: boolean;
  showEquipmentSelection: boolean;
  isLoaded: boolean;
  waitingForiAdvise: boolean;
  isChatStatusOnline: boolean;

  chatForm: FormGroup;
  chatList: ChatEquipmentModel[];
  availableUseCaseList: DropdownOptions[];
  availableUseCaseEquipment: EquipmentViewModel[];
  availableEquipment: Subject<EquipmentViewModel[]> = new Subject<EquipmentViewModel[]>();
  selectedEquipment: EquipmentViewModel;
  searchInput: string;
  statusColorMap = {};

  startChat: boolean;
  showStayInQueue: boolean;
  removeCustomDataOnClose: boolean;

  mutationObserver: MutationObserver;
  bodyMutationObserver: MutationObserver;

  chatLabelLoading = 'LABEL_CHAT_LOADING';
  chatSpinnerMaximumTimeout: number;
  closeOnlineChatTimeout = 5000;

  private hideSubscription: Subscription;

  constructor(renderer: Renderer2,
    private fb: FormBuilder,
    private chatUtilService: ChatUtilService,
    private iadvizeChatService: IadvizeChatService,
    private configService: CountryConfigRestService,
    private filterUtilService: FilterUtilService,
    private logService: LogService) {
    super(renderer);
  }

  ngOnInit() {
    this.init();
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((config) => {
      const greenStatus = config.EQUIPMENT_STATUS_GREEN;
      const redStatus = config.EQUIPMENT_STATUS_RED;
      const yellowStatus = config.EQUIPMENT_STATUS_YELLOW;
      this.chatSpinnerMaximumTimeout = _.parseInt(config.CHAT_MAX_LOADING_TIME) * 1000; // CHAT_MAX_LOADING_TIME is in seconds

      this.statusColorMap[greenStatus] = 'green';
      this.statusColorMap[redStatus] = 'red';
      this.statusColorMap[yellowStatus] = 'yellow';
    });
  }

  init() {
    this.cleanupMutationObserver();
    this.initProperties();
    this.initForm();
    this.registerEventListeners();
  }

  ngOnDestroy() {
    if (this.hideSubscription) {
      this.hideSubscription.unsubscribe();
    }
    this.cleanupMutationObserver();
    super.destroy();
  }

  initProperties() {
    this.isFormSubmitted = false;
    this.showEquipmentSelection = false;
    this.isLoaded = false;
    this.searchInput = '';
    this.chatForm = null;
    this.chatList = [];
    this.availableUseCaseList = [];
    this.availableUseCaseEquipment = [];
    this.startChat = false;
    this.showStayInQueue = false;
    this.removeCustomDataOnClose = true;
    this.waitingForiAdvise = true;
    this.isChatStatusOnline = false;
    this.initChatList();
  }

  /**
   * Overridden method when clicking Ok to emit
   * Here we set isFormSubmitted = true; and then do all checks needed for either validation or chat configuration.
   * this is the method that will be called after the user clicks 'Chat'
   */
  ok() {
    this.isFormSubmitted = true;
    if (this.chatForm.valid) {
      const selectedUseCase = this.chatForm.get('useCase').value;
      const chatConfig = this.chatUtilService.getChatConfigurationByUseCase(this.chatList, selectedUseCase);
      if (!_.isEmpty(selectedUseCase) && chatConfig) {
        this.chatUtilService.buildIadvizeTriggerScript();
        this.chatUtilService.getIAdvizeCustomObject(chatConfig, this.selectedEquipment).pipe(
          finalize(() => {
            this.setTimeoutActions();
          }))
          .subscribe(iAdvize => {
            this.startChat = true;
            this.iadvizeChatService.emitIAdvizeCustomObjectBuild(iAdvize);
          }, () => {
            this.logService.error('iAdvize custom object fetch error ');
          });
      }
    }
  }

  initForm() {
    this.chatForm = this.fb.group({
      useCase: ['', Validators.required],
      equipment: ['', Validators.required] // unless LifeNet Use case was selected!
    });
  }

  handleUseCaseChange() {
    this.selectUseCase();
    this.clearInput();
  }

  selectUseCase() {
    const selectedUseCase = this.chatForm.get('useCase').value;

    this.availableUseCaseEquipment = this.chatUtilService.getEquipmentByUseCase(this.chatList, selectedUseCase);
    if (this.availableUseCaseEquipment.length === 1) {
      this.selectedEquipment = this.availableUseCaseEquipment[0];
      this.handleEquipmentChange();
    }
    this.showEquipmentSelection = !(_.isEmpty(selectedUseCase) || _.isEqual(selectedUseCase, 'LIFENET'));

    this.updateChatFormValidity(selectedUseCase);
  }

  handleEquipmentChange() {
    this.chatForm.patchValue({
      equipment: this.selectedEquipment.key
    });
  }

  typeaheadOnSelect(equipment: EquipmentViewModel) {
    this.selectedEquipment = equipment;
    this.handleEquipmentChange();
  }

  clearInput() {
    this.selectedEquipment = null;
    this.searchInput = '';
    this.chatForm.patchValue({equipment: ''});
  }

  hide() {
    super.hide();
  }

  onStayInQueue() {
    this.hide();
  }

  onAgentOnline() {
    this.isChatStatusOnline = true;

    const chatGlobal = document.getElementById('idz_chatglobal');
    if (chatGlobal) {
      setTimeout(() => {
        chatGlobal.style.display = 'block';
        this.onCancel();
      }, this.closeOnlineChatTimeout);
    } else {
      const onlineIcon = document.getElementById('idzfonline');
      if (onlineIcon) {
        onlineIcon.click();
      }
    }
    this.showStayInQueue = false;
    this.removeCustomDataOnClose = false;
  }

  onAgentOffline() {
    //  show only the close button and attach the "removeInputs" also to that button
    // so that the chat popup does not popup later
    this.removeCustomDataOnClose = true;
    this.showStayInQueue = false;
  }

  onAgentBusy() {
    // show the "wait for agent" button, that closes modal, but does not remove the inputs
    this.showStayInQueue = true;
    // show the close button and attach the "removeInputs" also to that button
    this.removeCustomDataOnClose = true;
  }

  onCancel() {
    if (this.removeCustomDataOnClose) {
      this.chatUtilService.cleanUpDOM();
    }
    this.hide();
  }

  /**
   * listen to the visibility options switched by IAdvise client.
   * If one (of busy|online|offline) is made visible, then we want to hide the spinner
   */
  setMutationObserver() {
    if (this.mutationObserver) {
      const elements = document.querySelectorAll('.idzf_buttons');
      Array.from(elements).forEach((node) => {
        this.mutationObserver.observe(node, {
          attributes: true
        });
      });
    }

    if (this.bodyMutationObserver) {
      const bodyElement = document.querySelector('body');
      this.bodyMutationObserver.observe(bodyElement, {
        childList: true
      });
    }
  }

  getCloseCancelTranslateKey(): string {
    return this.waitingForiAdvise ? 'GENERIC_LABEL_CANCEL' : 'GENERIC_LABEL_CLOSE';
  }

  private initChatList() {
    this.chatUtilService.getChatEquipmentList().pipe(
      finalize(() => {
        this.isLoaded = true; // handle in case of error
      }))
      .subscribe(response => {
        this.chatList = response;
        this.availableUseCaseList = this.chatUtilService.getAvailableUseCaseList(this.chatList);
      });
  }

  private updateChatFormValidity(selectedUseCase: any) {
    if (_.isEqual(selectedUseCase, 'LIFENET')) {
      this.chatForm.get('equipment').clearValidators();
    } else {
      this.chatForm.get('equipment').setValidators([Validators.required]);
    }
    this.chatForm.get('equipment').updateValueAndValidity();
  }

  private getMatchingResult(token: string): EquipmentViewModel[] {
    const search = {
      searchValue: token,
      searchColumns: [
        'productName',
        'myEquipmentName',
        'siemensId',
        'department',
        'street',
        'city'
      ]
    };
    return this.filterUtilService.applyIndividualFilter(this.availableUseCaseEquipment, search, 'search');
  }

  registerEventListeners() {
    // to do the init stuff also, when the modal is closed by clicking beside it
    if (!this.hideSubscription && this.childModal) {
      this.hideSubscription = this.childModal.onHide.subscribe(() => {
        // we have to do it in a timeout - otherwise we will always clear the DOM
        setTimeout(() => {
          this.init();
        }, 200);
      });
    }

    if (!this.mutationObserver) {
      this.mutationObserver = new MutationObserver((mutations) => {

        mutations.forEach(mutation => {

          const styles = window.getComputedStyle(<Element>mutation.target);
          if (_.isEqual(styles.display, 'block')) {
            this.waitingForiAdvise = false;
            const buttonId = (<Element>mutation.target).attributes['id'].value;

            this.isChatStatusOnline = false;
            if (_.isEqual(buttonId, 'idzfonline')) {
              this.onAgentOnline();
            } else if (_.isEqual(buttonId, 'idzfoffline')) {
              this.onAgentOffline();
            } else if (_.isEqual(buttonId, 'idzfbusy')) {
              this.onAgentBusy();
            } else {
              this.logService.warn('unexpected button' + buttonId);
            }
          }
        });
      });
    }

    if (!this.bodyMutationObserver) {
      this.bodyMutationObserver = new MutationObserver((mutations) => {
        mutations.forEach(mutation => {
          if (mutation.addedNodes && !_.isEmpty(mutation.addedNodes)) {
            const element = <Element>mutation.addedNodes[0];
            if (element.attributes && element.attributes['id']) {
              const mutationValue = element.attributes['id'].value;
              if (_.isEqual(mutationValue, 'idz_chatglobal')) {
                if (this.isChatStatusOnline) {
                  // then do stuff
                  this.onCancel();
                }
              }
            }
          }
        });
      });
    }
  }

  private cleanupMutationObserver() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
      this.mutationObserver = null;
    }
    if (this.bodyMutationObserver) {
      this.bodyMutationObserver.disconnect();
      this.bodyMutationObserver = null;
    }
  }

  private triggerStuckSpinnerActions() {
    // after changing the display status of the offline button, our observer will trigger the this.onAgentOffline(); method.
    const idzfofflineStatus = document.getElementById('idzfoffline');
    if (idzfofflineStatus) {
      idzfofflineStatus.style.display = 'block';
    }
  }

  private setTimeoutActions() {
    setTimeout(() => {
      this.setMutationObserver();
    }, 100);

    setTimeout(() => {
      if (this.startChat && this.waitingForiAdvise) {
        this.triggerStuckSpinnerActions();
      }
    }, this.chatSpinnerMaximumTimeout);
  }

  onSearchInputChange(searchString: string) {
    this.availableEquipment.next(this.getMatchingResult(searchString));
  }
}
