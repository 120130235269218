import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { BaseEquipmentDetailListView } from '../../../../../core/base-class/base-equipment-detail-list-view';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentUtilService } from '../../../../../core/services/equipment/equipment-util.service';
import { CountryConfigRestService } from '../../../../../core/rest-services/country-config-rest.service';
import { LifeNetUtilService } from '../../../../../core/utils/life-net-util.service';
import { EquipmentRestService } from '../../../../../core/rest-services/equipment-rest.service';
import { DocumentSourceRestService } from '../../../../../core/rest-services/document-source-rest.service';
import { EquipmentConstantsService } from '../../../../../core/services/equipment/equipment-constants.service';
import { TranslateService } from '@ngx-translate/core';
import { UserUtilService } from '../../../../../core/services/user/user-util.service';
import { ToasterService } from '../../../../../core/component-communication-services/toaster/toaster.service';
import { FilterUtilService } from '../../../../../core/utils/filter-util.service';
import * as _ from 'lodash';
import { DropdownOptions, NestedDropdownOption } from '../../../../../core/models/dropdown-options';
import { DocumentFile } from '../../../../../core/models/equipment/documents/document-file';
import { DocumentNode, DocumentNodeViewModel } from '../../../../../core/models/equipment/documents/document-node';
import { DocumentFilterUtilService } from '../../../../../core/utils/document-filter-util.service';
import { SortDirection } from '../../../../../shared/sorting/sort-object';
import { CollapsibleFilterObject } from '../../../../../shared/filtering/filter-object';
import { ReplaceUnderscoresWithSpacePipe } from '../../../../../shared/pipes/replace-underscores-with-space/replace-underscores-with-space.pipe';
import { SkbDocumentGeneratorService } from '../../../../../core/utils/skb-document-generator.service';
import { DownloadUtilService } from '../../../../../core/utils/download-util.service';
import { SyngoVoucherRequestModalComponent } from '../../../../../shared/modal-popup/syngo-voucher-request-modal/syngo-voucher-request-modal.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'hl-equipment-documents-collapsible-list',
  templateUrl: './equipment-documents-collapsible-list.component.html'
})
export class EquipmentDocumentsCollapsibleListComponent extends BaseEquipmentDetailListView<DocumentNodeViewModel> implements OnInit {
  @Input() scrollableContainerClass: string;
  @Input() documentNodes: DocumentNode[];
  @Input() useParentRoute: boolean;
  @Input() currentSourceId: string;

  @ViewChild('syngoVoucher', {static: false})
  syngoVoucher: SyngoVoucherRequestModalComponent;

  folderFilterOptions: NestedDropdownOption[];
  numberOfFolderFilterOptions: number;
  selectedFolderOptions: DropdownOptions[];
  numberOfDocumentsAfterFiltering = 0;
  documentsZipFileNameSuffix;
  shouldShowSyngoVoucher = false;

  readonly ACCORDION_HEADER = '.accordion__header';
  readonly ACCORDION_TITLE = '.accordion__title';
  readonly DMS_SIC_SOURCE_ID = 'dms-sic';

  constructor(
    activatedRoute: ActivatedRoute,
    equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService,
    private router: Router,
    public lifeNetUtilService: LifeNetUtilService,
    private equipmentRestService: EquipmentRestService,
    private sourceRestService: DocumentSourceRestService,
    private equipmentConstantsService: EquipmentConstantsService,
    private translateService: TranslateService,
    private userUtilService: UserUtilService,
    private toasterService: ToasterService,
    filterUtilService: FilterUtilService,
    private documentFilterUtilService: DocumentFilterUtilService,
    private replaceUnderscoresWithSpacePipe: ReplaceUnderscoresWithSpacePipe,
    private skbDocumentGeneratorService: SkbDocumentGeneratorService,
    private downloadUtilService: DownloadUtilService
  ) {
    super(filterUtilService, configService, lifeNetUtilService, equipmentUtilService, activatedRoute);
  }

  static createGenericNestedDropdownOption(title: string, value: string): NestedDropdownOption {
    return {
      title,
      value,
      isSelected: false,
      isExpanded: false,
      children: [],
      parent: null
    };
  }

  ngOnInit() {
    super.init();
  }

  @HostListener('keydown.arrowup', ['$event'])
  onUp(event: KeyboardEvent): void {
    const prevItem = this.getPrevItem(event.target);
    if (prevItem) {
      prevItem.focus();
    }
  }

  @HostListener('keydown.arrowdown', ['$event'])
  onDown(event: KeyboardEvent): void {
    const nextItem = this.getNextItem(event.target);
    if (nextItem) {
      nextItem.focus();
    }
  }

  private getPrevItem(item): any {
    let sibling = item.closest(this.ACCORDION_HEADER).previousElementSibling;
    if (sibling) {
      sibling = sibling.previousElementSibling;
    }
    if (sibling) {
      return sibling.querySelector(this.ACCORDION_TITLE);
    }

    const nodes = item.parentElement.parentElement.querySelectorAll(this.ACCORDION_TITLE);
    return nodes[nodes.length - 1];
  }

  private getNextItem(item): any {
    let sibling = item.closest(this.ACCORDION_HEADER).nextElementSibling;
    if (sibling) {
      sibling = sibling.nextElementSibling;
    }
    if (sibling) {
      return sibling.querySelector(this.ACCORDION_TITLE);
    }

    const nodes = item.parentElement.parentElement.querySelectorAll(this.ACCORDION_TITLE);
    return nodes[0];
  }

  afterConfigProperties(config: any): void {
    this.shouldShowSyngoVoucher = _.isEqual(config.FEATURE_TOGGLE_VOUCHER, 'true');
    this.rawList = this.documentNodes.map(node => ({...node, isExpanded: false}));
    this.computeFilterOptionsFromPaths();
    this.onAdvancedFilterChange();
    this.isLoaded = true;
  }

  afterInitProperties(): void {
    this.isLoaded = false;
    this.sortSkeleton =
      this.currentSourceId === 'dms-skb' ?
        this.equipmentConstantsService.getSortSkeletonCollapsibleListSkbDocument() :
        this.equipmentConstantsService.getSortSkeletonCollapsibleListDocument();
    this.folderFilterOptions = [];
    this.numberOfDocumentsAfterFiltering = 0;
    // tslint:disable-next-line:ban
    this.documentsZipFileNameSuffix = '_' + this.translateService.instant('LABEL_FILE_DOCUMENTS') + '.zip';
    this.setSearchInputFromQueryParam(this.router);
  }

  getFilterObject(): CollapsibleFilterObject {
    return {
      fileSearch: {
        searchValue: this.searchInput,
        searchArrayLevels: [
          {
            arrayPath: 'list',
            searchColumns: [
              'path',
              'name',
              'fileExtension'
            ],
            compareFuncColumns: [
              'name'
            ]
          }
        ],
        compareFunc: (item: string, searchVal: string) =>
          this.replaceUnderscoresWithSpacePipe.transform(item).toUpperCase().includes(searchVal.toUpperCase())
      },
      nestedFolderSelect: this.selectedFolderOptions,
      orderBy: this.sortSkeleton.sortObject
    };
  }

  onAdvancedFilterChange() {
    this.paginationItems = this.numberPagination;

    this.listWithoutPagination = this.documentFilterUtilService.getListAfterApplyingFilters(
      this.rawList,
      this.getFilterObject(),
      {
        nestedFolderOptions: this.folderFilterOptions
      }
    );

    this.filteredLengthWithoutPagination = this.listWithoutPagination.length;
    this.numberOfDocumentsAfterFiltering = 0;
    this.listWithoutPagination.forEach(node => this.numberOfDocumentsAfterFiltering += node.list.length);

    this.onLoadMore();

    if (this.searchInput || (
      this.selectedFolderOptions &&
      this.selectedFolderOptions.length &&
      this.selectedFolderOptions.length !== this.numberOfFolderFilterOptions
    )) {
      this.viewModelList = this.viewModelList.filter(item => item.list && item.list.length);
      this.listWithoutPagination.forEach(item => item.isExpanded = true);
    }
  }

  onLoadMore() {
    this.viewModelList = this.filterUtilService.applyIndividualFilter(
      this.listWithoutPagination,
      this.paginationItems,
      'limitTo'
    );
  }

  loadMore(extraItems?: number) {
    if (this.paginationItems < this.filteredLengthWithoutPagination) {
      super.loadMore(extraItems);
    }
  }

  computeFilterOptionsFromPaths() {
    this.numberOfFolderFilterOptions = 0;
    const orderedNodes = _.orderBy(this.documentNodes, 'name', SortDirection.ASC);
    orderedNodes.forEach(node => {
      if (node && node.list && node.list.length) {
        this.addFilterOptionsForNode(node);
      }
    });
  }

  addFilterOptionsForNode(node) {
    if (!node.list || !node.list.length) {
      return;
    }

    const nodeOption = EquipmentDocumentsCollapsibleListComponent.createGenericNestedDropdownOption(node.name, node.name);
    this.folderFilterOptions.push(nodeOption);
    let numberOfOptions = 1;

    node.list.forEach(document => {
      if (document.path) {
        let option = nodeOption;
        const documentFolder = document.path.includes('/') ? document.path.split('/') : document.path;
        if (!document.path.includes('/') && !option.children.some(o => o.title === documentFolder)) {
          option.children.push(
            EquipmentDocumentsCollapsibleListComponent.createGenericNestedDropdownOption(documentFolder, documentFolder));
          numberOfOptions++;
        } else if (document.path.includes('/')) {
          (documentFolder as string[]).slice(0, documentFolder.length - 1).forEach(f => {
            if (option.children.some(o => o.title === f)) {
              option = option.children.find(o => o.title === f);
            } else {
              const newNode = EquipmentDocumentsCollapsibleListComponent.createGenericNestedDropdownOption(f, option.value + '/' + f);
              option.children.push(newNode);
              option = newNode;
              numberOfOptions++;
            }
          });
          const optionName = documentFolder[documentFolder.length - 1];
          if (!option.children.some(o => o.title === optionName)) {
            option.children.push(
              EquipmentDocumentsCollapsibleListComponent.createGenericNestedDropdownOption(optionName, option.value + '/' + optionName));
            numberOfOptions++;
          }
        }
      }
    });
    this.numberOfFolderFilterOptions += numberOfOptions;
  }

  getInfiniteScrollContainerClassName(): string {
    return this.scrollableContainerClass || '.equipment-docs-view-scroll';
  }

  hasMoreThanOneDocumentTypeOptions(): boolean {
    return !!(this.folderFilterOptions && this.folderFilterOptions.length && this.numberOfFolderFilterOptions > 1);
  }

  hasMoreItemsThan(numberOfItems: number): boolean {
    return !!this.viewModelList && this.viewModelList.length > numberOfItems;
  }

  showExpandAll(): boolean {
    return this.viewModelList.some(node => !node.isExpanded);
  }

  showCollapseAll(): boolean {
    return !this.showExpandAll();
  }

  collapseAll() {
    this.expandCollapsibleItems(false);
  }

  expandAll() {
    this.expandCollapsibleItems(true);
  }

  expandCollapsibleItems(status: boolean) {
    if (this.viewModelList) {
      this.viewModelList.forEach(item => item.isExpanded = status);
    }
  }

  shouldShowSearchingAndFiltering(): boolean {
    return this.currentSourceId !== 'dms-skb';
  }

  redirectToDownloadDocument(document: DocumentFile) {
    this.downloadUtilService.downloadFileByUrl(
      document.name + '.' + document.fileExtension,
      document.downloadLink,
      document.isAccessible);
  }

  openOverview(node: DocumentNodeViewModel, $event) {
    $event.preventDefault();
    this.skbDocumentGeneratorService.generate(node);
  }

  checkIfEquipmentIsWhitelistedForSyngoVoucher(): Observable<boolean> {
    return this.equipmentRestService.checkIfEquipmentIsWhitelistedForSyngoVoucher(this.equipmentItem.key);
  }

  showSyngoVoucherModalDialog() {
    this.syngoVoucher.equipment = this.equipmentItem;
    this.syngoVoucher.show();
  }

  showSyngoVoucherButton() {
    return this.checkIfEquipmentIsWhitelistedForSyngoVoucher().pipe(map(whitelist => {
      return whitelist && this.currentSourceId === this.DMS_SIC_SOURCE_ID && this.shouldShowSyngoVoucher;
    }));
  }
}
