import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApplicableFor } from '../models/spr/basic-input-configuration/configuration-applicable-for';
import { restEndPoint } from '../core-constants.service';
import { KpiNames } from '../models/spr/basic-input-configuration/kpi-names';

export enum ServiceMetricsConfigurationRestUrl {
  durations = '/rest/v1/spr/durations',
  durationsSave = '/rest/v1/spr/durations/save',
  durationsDelete = '/rest/v1/spr/durations/delete',
  quantity = '/rest/v1/spr/quantity',
  quantitySave = '/rest/v1/spr/quantity/save',
  quantityDelete = '/rest/v1/spr/quantity/delete',
  timeFrames = '/rest/v1/spr/timeFrames',
  timeFramesSave = '/rest/v1/spr/timeFrames/save',
  timeFramesDelete = '/rest/v1/spr/timeFrames/delete',
  holidays = '/rest/v1/spr/holidays',
  holidaysSave = '/rest/v1/spr/holidays/save',
  holidaysDelete = '/rest/v1/spr/holidays/delete'
}

@Injectable()
export class SprConfigurationRestService {

  private sprDataRestName = restEndPoint + 'spr';
  private sprConfigurationApplicableRestName = this.sprDataRestName + '/applicable';
  private sprLoadKpiNamesRestName = this.sprDataRestName + '/load-kpi-names';

  constructor(private http: CacheableHttpClient) {
  }

  public get<T>(urlToCall: ServiceMetricsConfigurationRestUrl): Observable<T> {
    return this.http.get<T>(urlToCall, {cache: false});
  }

  public save<T>(urlToCall: ServiceMetricsConfigurationRestUrl, tables: T): Observable<any> {
    return this.http.post(urlToCall, tables);
  }

  public delete(urlToCall: ServiceMetricsConfigurationRestUrl, rowId: number): Observable<any> {
    this.http.clearCache(urlToCall);
    return this.http.delete(urlToCall,
      {params: new HttpParams().set('id', rowId.toString())});
  }

  public getApplicable(): Observable<ApplicableFor> {
    return this.http.get<ApplicableFor>(this.sprConfigurationApplicableRestName);
  }

  public loadKpiNames(): Observable<KpiNames> {
    return this.http.get(this.sprLoadKpiNamesRestName, {cache: false});
  }
}
