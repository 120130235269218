import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProfessionalServiceRequestRestService } from '../rest-services/professional-service-request-rest.service';
import { LifeNetUtilService } from './life-net-util.service';
import { EquipmentRestService } from '../rest-services/equipment-rest.service';
import { ProfessionalServiceRequestViewModel } from '../view-models/professional-service-request-view-model';
import { PsrTypeEnum } from '../core-constants.service';
import { PsrType } from 'app/core/models/professionalServiceRequest/psr-status-type';
import * as _ from 'lodash';
import { SelectOption } from 'app/core/models/select-option';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { LineItem } from '../models/professionalServiceRequest/line-item';
import { TranslateService } from '@ngx-translate/core';
import { PsrTemplateByModality } from '../models/professionalServiceRequest/psr-template-by-modality';
import { map } from 'rxjs/operators';
import { RemoteWorkForceRequest } from '../models/professionalServiceRequest/remote-work-force-request';

/**
 * Available in core as it is used in two modules, CreateProfessionalServiceRequest and ProfessionalServiceRequest
 */
@Injectable()
export class ProfessionalServiceRequestUtilService {

  private psrCreatedSource: Subject<void> = new Subject();
  psrCreatedSource$ = this.psrCreatedSource.asObservable();

  private rwfCreatedSource: Subject<void> = new Subject();
  rwfCreatedSource$ = this.rwfCreatedSource.asObservable();

  constructor(private psrRestService: ProfessionalServiceRequestRestService,
    private lifeNetUtilService: LifeNetUtilService,
    private equipmentRestService: EquipmentRestService,
    private translateService: TranslateService) {
  }

  getPsrViewModelList(): Observable<ProfessionalServiceRequestViewModel[]> {
    const findModalityObject = {
      findKey: 'modalityCode',
      findValue: 'modality',
      propertiesToMerge: ['modalityDescription']
    };

    const findPsrTypeObject = {
      findKey: 'type',
      findValue: 'quoteStatus',
      propertiesToMerge: ['psrTypeDescription']
    };

    const psrViewModel = this.lifeNetUtilService.createViewModels(this.equipmentRestService.getModalities(),
      this.psrRestService.getPsrList(), findModalityObject);

    return this.lifeNetUtilService.createViewModels(this.getPsrDropDownValuesByType(PsrTypeEnum.STAT), psrViewModel, findPsrTypeObject);
  }

  getPsrDropDownValuesByType(type: PsrTypeEnum): Observable<PsrType[]> {
    return this.psrRestService.getPsrTypeCode(type);
  }

  convertPsrTypesToSelectOptions(type: PsrTypeEnum): Observable<SelectOption[]> {
    return this.getPsrDropDownValuesByType(type).pipe(map((psrStatusTypes: PsrType[]) => {
      return _.map(psrStatusTypes, (psrStatusType) => {
        return {
          value: psrStatusType.type,
          title: psrStatusType.psrTypeDescription
        };
      });
    }));
  }

  convertLineItemsToSelectOptions(lineItem: LineItem[]): SelectOption[] {

    const lineItemOption: SelectOption[] = [];
    _.forEach(lineItem, (item) => {
      lineItemOption.push({
        // tslint:disable-next-line:ban
        title: `${this.translateService.instant('LABEL_PSR_LINE_ITEM')} ${item.line}`,
        value: item.line
      });
    });

    return lineItemOption as SelectOption[];

  }

  getSinglePsr(param: string) {
    return this.getPsrViewModelList()
      .pipe(map(psrViewModelList => psrViewModelList.filter(psr => psr.quoteNumber.toString() === param)[0]));
  }

  filterPsrTemplates(filteredModalities: SelectOption[]): Observable<PsrTemplateByModality[]> {
    return this.psrRestService.getPsrTemplates().pipe(map(psrTemplateGroup => {
      return _.filter(psrTemplateGroup, group => {
        const findModality = _.find(filteredModalities, {value: group.modality});
        return !_.isEmpty(findModality);
      });
    }));
  }

  emitPsrCreated() {
    this.psrCreatedSource.next();
  }

  getRemoteWorkForceRequests(): Observable<RemoteWorkForceRequest[]> {
    return this.psrRestService.getRemoteWorkForceRequests();
  }

  emitRwfCreated() {
    this.rwfCreatedSource.next();
  }
}
