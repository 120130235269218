import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DropdownOptions,
  ExtendedNestedDropdownOption
} from '../../../core/models/dropdown-options';

@Component({
  selector: 'hl-nested-selectbox-tree',
  templateUrl: './nested-selectbox-tree.component.html'
})
export class NestedSelectboxTreeComponent {
  @Input() options: ExtendedNestedDropdownOption[];
  @Input() enableCollapsing: boolean;
  @Input() scrollOnExpand: boolean;
  @Input() selectedOption: DropdownOptions;
  @Output() superScrollEvent: EventEmitter<number> = new EventEmitter();
  @Output() superSelectedEvent: EventEmitter<ExtendedNestedDropdownOption> = new EventEmitter();

  isExpandable(option: ExtendedNestedDropdownOption): boolean {
    return !!(this.enableCollapsing && option && option.extendedChildren && option.extendedChildren.length);
  }

  isOnlyExpandable(option: ExtendedNestedDropdownOption): boolean {
    return !!option.disabledSelect && this.isExpandable(option);
  }

  isSelectableExpandable(option: ExtendedNestedDropdownOption): boolean {
    return !option.disabledSelect && this.isExpandable(option);
  }

  isSelected(option: ExtendedNestedDropdownOption): boolean {
    return this.selectedOption === option;
  }

  showChildren(option: ExtendedNestedDropdownOption): boolean {
    return !this.enableCollapsing || !!(this.isExpandable(option) && option.isExpanded);
  }

  toggleExpand = (event: Event, option: ExtendedNestedDropdownOption) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.enableCollapsing) {
      option.isExpanded = !option.isExpanded;

      if (!option.isExpanded && this.enableCollapsing) {
        this.shrinkChildren(option);
      }

      if (option.isExpanded && this.scrollOnExpand) {
        setTimeout(() => this.superScrollEvent.emit((
          (event.target as HTMLElement).parentElement.classList.contains('nested-selectbox__option') ?
            (event.target as HTMLElement).parentElement : (event.target as HTMLElement)
        ).offsetTop), 5);
      }
    }
  }

  private shrinkChildren(option: ExtendedNestedDropdownOption) {
    if (option.extendedChildren) {
      option.extendedChildren.forEach(child => {
        child.isExpanded = false;
        this.shrinkChildren(child);
      });
    }
  }

  onOptionClick = (event: Event, option: ExtendedNestedDropdownOption) => {
    if (event && option.disabledSelect) {
      this.toggleExpand(event, option);
    } else {
      this.stopPropagateEvent(event);
      this.superSelectedEvent.emit(option);
    }
  }

  onSelect = (event: Event, option: ExtendedNestedDropdownOption) => {
    if (this.isSelectableExpandable(option)) {
      this.stopPropagateEvent(event);
      this.superSelectedEvent.emit(option);
    }
  }

  stopPropagateEvent = (event: Event) => {
    if (event) {
      event.stopPropagation();
    }
  }
}
