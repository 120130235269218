import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImpersonationCommunicationService } from '../core/component-communication-services/impersonation/impersonation-communication.service';
import { forkJoin, Subject } from 'rxjs';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { roles } from '../core/core-constants.service';
import { UserUtilService } from '../core/services/user/user-util.service';
import * as _ from 'lodash';
import { UserRestService } from '../core/rest-services/user-rest.service';
import { takeUntil } from 'rxjs/operators';
import { TeamplayRestService } from '../core/rest-services/teamplay-rest.service';
import { CheckPermissionOrRoleService } from '../core/auth-guards/check-permission-or-role.service';

@Component({
  selector: 'hl-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit, OnDestroy {

  showEquipmentWidget = false;
  showActivityWidget = false;
  showOpenTicketsWidget = false;
  showLastViewedWidget = false;
  showNewsWidget = false;
  showAdvisoriesWidget = false;
  canWidgetsBeShown = false;
  tempCanShowActivityWidget = false;
  isSimulatorMode = false;
  showEquipmentStatusMap = false;
  teamplayDataAvailable = false;
  numberOfWidgetInRow = 0;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  static showAdvisories(config, checkRolesResponse): boolean {
    let showAdvisories = false;
    const toggleAdvisoryWidget = _.isEqual(config.TOGGLE_SECURITY_ADVISORIES_WIDGET, 'true');
    const toggleAdvisorySubscription = _.isEqual(config.FEATURE_TOGGLE_ADVISORY_SUBSCRIPTION, 'true');
    const toggleXiaViewAdvisories = _.isEqual(config.FEATURE_TOGGLE_XIA_VIEW_ADVISORIES, 'true');
    if (toggleAdvisoryWidget) {
      showAdvisories = true;
      if (toggleAdvisorySubscription || toggleXiaViewAdvisories) {
        const hasSecurityAdvisoryViewRole = checkRolesResponse.securityAdvisoryViewRole;
        const hasSecurityAdvisoryViewXiaRole = checkRolesResponse.securityAdvisoryViewXiaRole;
        if (!hasSecurityAdvisoryViewRole && !hasSecurityAdvisoryViewXiaRole) {
          showAdvisories = false;
        }
      }
    }
    return showAdvisories;
  }

  constructor(public checkPermissionOrRoleService: CheckPermissionOrRoleService,
    private userUtilService: UserUtilService,
    private configService: CountryConfigRestService,
    private teamplayRestService: TeamplayRestService,
    private impersonationCommunicationService: ImpersonationCommunicationService,
    private userRestService: UserRestService) {
  }

  ngOnInit() {
    this.init();

    // Note:- Only one time event listeners be registered
    this.registerEventListeners();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  init() {
    this.teamplayDataAvailable = false;
    this.teamplayRestService.isTeamplayAvailable().pipe(takeUntil(this.unsubscribe$))
      .subscribe((isAvailable) => {
        this.teamplayDataAvailable = isAvailable;
      });

    const rolesToCheck = {
      checkViewEquipmentRole: roles.viewEquipmentRole,
      checkViewTicketRole: roles.viewTicketRole,
      plannedActivityRole: roles.viewPlannedActivityRole,
      plannedTrainingRole: roles.viewPlannedTrainingRole,
      securityAdvisoryViewRole: roles.securityAdvisoryViewRole,
      securityAdvisoryViewXiaRole: roles.securityAdvisoryViewXiaRole
    };
    const roles$ = this.userUtilService.checkUserRoles(rolesToCheck);
    const user$ = this.userUtilService.getUser();

    forkJoin([roles$, user$])
      .subscribe((responses) => {

        this.isSimulatorMode = responses[1].simulator;

        // widget show based on country config
        this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((config) => {
          const newsRender = config.NEWS_RENDER;
          const advisoriesRender = DashboardComponent.showAdvisories(config, responses[0]);
          const activityStatusRender = config.SHOW_PLANNED_ACTIVITY_WIDGET;

          if (_.isEqual(newsRender, 'true')) {
            this.showNewsWidget = true;
            this.numberOfWidgetInRow++;
          }
          if (_.isEqual(activityStatusRender, 'true')) {
            this.tempCanShowActivityWidget = true;
            this.numberOfWidgetInRow++;
          }
          if (_.isEqual(advisoriesRender, true)) {
            this.showAdvisoriesWidget = true;
            this.numberOfWidgetInRow++;
          }
          this.canWidgetsBeShown = true;

          this.showWidgetsBasedOnUserRoles(responses[0]);
        });
      });

  }

  /**
   *
   * @description
   * Register all events which are broad casted, emitted
   */
  registerEventListeners() {
    this.impersonationCommunicationService.onCountryLanguageChange$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.init();
    });
  }

  /**
   * Check the roles of user for showing last viewed widget
   */
  showWidgetsBasedOnUserRoles(checkRolesResponse) {

    // enable if view equipment role found
    if (checkRolesResponse.checkViewEquipmentRole) {
      this.showEquipmentWidget = true;
      this.showLastViewedWidget = true;
    }

    // enable if view tickets role found
    if (checkRolesResponse.checkViewTicketRole) {
      this.showOpenTicketsWidget = true;
    }

    // enable if view planned activities or view planned activities role found
    if ((checkRolesResponse.plannedActivityRole || checkRolesResponse.plannedTrainingRole) && this.tempCanShowActivityWidget) {
      this.showActivityWidget = true;
    }

    if (checkRolesResponse.checkViewEquipmentRole) {
      this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
        if (_.isEqual(configResponse.SHOW_EQUIPMENT_STATUS_MAP, 'true')) {
          this.userRestService.getAssignedCustomers().subscribe((assignedCustomersResponse) => {
            if (assignedCustomersResponse.length > 0) {
              this.showEquipmentStatusMap = true;
            }
          });
        } else {
          this.showEquipmentStatusMap = false;
        }
      });
    }
  }

}
