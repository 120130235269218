import { LifeNetUtilService } from '../core/utils/life-net-util.service';
import { CountryConfigRestService } from '../core/rest-services/country-config-rest.service';
import { EnvironmentConfigRestService } from '../core/rest-services/environment-config-rest.service';
import { WindowService } from '../core/window.service';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import * as _ from 'lodash';
import { DOCUMENT } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { BrowserStateService } from '../core/services/browser-state.service';
import { WalkMeUtilService } from '../core/utils/walk-me-util.service';
import { environment } from '../../environments/environment.prod';
import { localeSplitter } from '../core/core-constants.service';
import { ConfigLoaderService } from '../config-loader.service';
import { RegistrationModalComponent } from '../shared/modal-popup/registration-modal/registration-modal-component';
import { IcCodeModalComponent } from '../shared/modal-popup/ic-code-modal/ic-code-modal.component';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hl-front-page',
  templateUrl: './front-page.component.html'
})
export class FrontPageComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  welcomeLanguageKeys = {
    de_DE: 'Deutsch',
    en_GB: 'English',
    es_ES: 'Español',
    fr_FR: 'Français',
    it_IT: 'Italiano',
    ja_JP: '日本語 (Japanese)',
    ko_KR: '한국어 (Korean)',
    hu_HU: 'Magyar',
    nl_BE: 'Nederlands',
    no_NO: 'Norsk',
    zh_CN: '中文 (Chinese)',
    pl_PL: 'Język polski',
    pt_PT: 'Português',
    ru_RU: 'Pуѝѝкий (Russian)',
    sk_SK: 'Slovenský',
    sv_SE: 'Svenska',
    tr_TR: 'Türkçe',
    cz_CZ: 'Čeština',
    bg_BG: 'Български'
  };
  notLoggedInAvatarImage = environment.version + 'assets/images/not-logged-in.png';

  @ViewChild('dropDownToggle', {static: false}) dropDownToggleEl: ElementRef;
  @ViewChild('dropDownLanguageToggle', {static: false}) dropDownLanguageToggleEl: ElementRef;
  @ViewChild('registerDialog', {static: false}) registerDialog: RegistrationModalComponent;
  @ViewChild('icCodeModal', {static: false}) icCodeModal: IcCodeModalComponent;

  @Input()
  showUserPanel = true;

  siemensHealthcareLink: string;
  registerLink: string;
  iccodeFragment: string;
  selectedLanguage: string;
  langCollapsed: boolean;
  copyRightLeftLabel: string;
  footersLinks: string[];

  defaultLanguage = 'en_GB';
  allowedLanguageKeys = _.keys(this.welcomeLanguageKeys);
  languages = [];
  glnFile = this.configLoaderService.getDefaultTranslateFile();

  window = this.windowService.nativeWindow;

  isDropdownOpen = false;
  isDropdownLanguageOpen = false;

  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(private windowService: WindowService,
    private environmentConfigRestService: EnvironmentConfigRestService,
    private configService: CountryConfigRestService,
    private lifeNetUtilService: LifeNetUtilService,
    private translateService: TranslateService,
    private browserStateService: BrowserStateService,
    private walkMeUtilService: WalkMeUtilService,
    private configLoaderService: ConfigLoaderService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: any) {
  }

  ngOnInit() {
    this.init();
  }

  @HostListener('document:click.out-zone', ['$event'])
  clickout(event) {
    if (this.isDropdownOpen && !this.dropDownToggleEl.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
    if (this.isDropdownLanguageOpen && !this.dropDownLanguageToggleEl.nativeElement.contains(event.target)) {
      this.isDropdownLanguageOpen = false;
    }
  }

  init() {
    this.setPreferredLanguage();
    this.initProperties();
    this.removeRedundantElements();
    this.setCopyRightLeftLabelFromGlnConfig();

    this.route.fragment.subscribe((fragment: string) => {
      if (fragment) {
        this.iccodeFragment = fragment;
      }
    });
  }

  setPreferredLanguage() {
    let preferredLanguage = this.getBrowserLanguage();
    if (!preferredLanguage) {
      preferredLanguage = this.defaultLanguage;
    }
    this.setLanguage(preferredLanguage);
  }

  getBrowserLanguage() {
    const navigator: any = this.window.navigator;
    let navigatorLanguage: string;

    // Support all browser specific properties
    const browserLanguagePropertyKeys = ['language', 'browserLanguage',
      'systemLanguage', 'userLanguage'];

    _.forEach(browserLanguagePropertyKeys, (key) => {
      if (navigator[key] && navigator[key].length) {
        navigatorLanguage = navigator[key];
      }
    });

    if (navigatorLanguage) {
      navigatorLanguage = this.getMappedLanguage(navigatorLanguage);
    }

    return navigatorLanguage;
  }

  getMappedLanguage(navigatorLanguage) {
    let mappedLanguage: string;
    const language = navigatorLanguage.replace(/[\-_]([a-zA-Z]*)/g, '');

    _.forEach(this.allowedLanguageKeys, (key) => {
      if (_.startsWith(key, language)) {
        mappedLanguage = key;
        return;
      }
    });
    return mappedLanguage;
  }

  setLanguage(language) {
    const languageSplit = language.split(localeSplitter);

    this.selectedLanguage = languageSplit[0];
    const countryCode = languageSplit[1];

    // set translation and configuration
    this.lifeNetUtilService.setTranslationFile(language).subscribe(() => {
      // We figured out (empirically), that the call to getTranslation (
      // must be called after he translation file (for the browser language) is set.
      // Otherwise the UI language is set to the default en_GB.
      this.setFooterLinks();
      if (this.registerDialog) {
        this.registerDialog.updateTranslations();
      }
    });
    this.lifeNetUtilService.setConfigurationFile(countryCode);

    // tuning properties based on country config
    this.configurePropertiesBasedOnCountryConfig();

    this.langCollapsed = true;

    this.walkMeUtilService.setLanguageAndCountry(window, this.selectedLanguage, '');
  }

  initProperties() {

    this.langCollapsed = true;
    this.languages = [];

    _.each(this.welcomeLanguageKeys, (value, key) => {
      this.languages.push({
        key,
        language: value
      });
    });

    this.environmentConfigRestService.getEnvironmentConfig().subscribe((configResponse) => {
      this.siemensHealthcareLink = configResponse.MY_HEALTHCARE_LINK;
    });
  }

  configurePropertiesBasedOnCountryConfig() {
    this.unsubscribe$.next();
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((config) => {
      // register link
      this.registerLink = config.LINK_REGISTER_WELCOME;
    });
  }

  public setFooterLinks() {
    this.translateService.getTranslation(this.glnFile).subscribe(gln => {
      this.footersLinks = gln;
    });
  }

  getFooterLink(key: string) {
    if (this.footersLinks) {
      return this.footersLinks[key];
    }
  }

  redirectLogin() {
    // redirect to login page
    this.environmentConfigRestService.getEnvironmentConfig().subscribe((configResponse) => {
      this.window.location.href = configResponse.LOGIN_URL;
    });
  }

  redirectRegister() {
    if (this.registerDialog) {
      this.registerDialog.show();
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleLanguageDropdown() {
    this.isDropdownLanguageOpen = !this.isDropdownLanguageOpen;
  }

  logout() {
    this.lifeNetUtilService.invalidateSession();
    location.reload();
  }

  /**
   * remove header, overlay, modal, etc. from welcome page if there is some
   */
  removeRedundantElements() {
    this.removeElementsWithID(['header', 'overlay-target']);
    this.removeElementsWithClassName(['overlay__container', 'modal-dialog']);
  }

  removeElementsWithID(ids: string[]) {
    for (const id of ids) {
      const element = document.getElementById(id);
      if (element !== null) {
        element.style.display = 'none';
      }
    }
  }

  removeElementsWithClassName(classNames: string[]) {
    for (const className of classNames) {
      for (const element of this.document.getElementsByClassName(className).length) {
        element.style.display = 'none';
      }
    }
  }

  setCopyRightLeftLabelFromGlnConfig() {
    this.configService.getGlnConfig().subscribe((config) => {
      // copyright
      const currentYear = new Date().getFullYear();
      this.copyRightLeftLabel = config.COPYRIGHT_NOTICE + ' ' + currentYear;
    });
  }

  ngAfterViewInit(): void {
    if (this.icCodeModal && this.icCodeModal.shouldShow(this.iccodeFragment)) {
      this.icCodeModal.show();
    }
  }

  // to avoid ExpressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
