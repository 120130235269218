import { Pipe, PipeTransform } from '@angular/core';
import { chartTypeOptions } from '../../../spr/configuration/spr-configuration-constants';

@Pipe({
  name: 'sprWidgetTypeClassPipe'
})
export class SprWidgetTypeClassPipe implements PipeTransform {
  transform(widgetType: string): string {
    const option = chartTypeOptions.find(o => o.value === widgetType);
    return option ? option.icon : '';
  }
}
