import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ContractsEquipmentUsage } from '../../../core/models/contracts/contracts-equipment-usage';
import { SeriesData } from '../../../diagram/diagram';
import { ContractsRestService } from '../../../core/rest-services/contracts-rest.service';
import { LogService } from '../../../core/services/log/log.service';

interface ChartData {
  bar: SeriesData[];
  line: { name: string, series: SeriesData[], clip?: number }[];
  domain: string[];
  scale: () => { min: number, max: number };
  contractStartDay: string;
}

@Component({
  selector: 'hl-contract-equipment-usage',
  templateUrl: './contract-equipment-usage.component.html'
})
export class ContractEquipmentUsageComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  chartData: { current: ChartData, past: ChartData };
  year: 'current' | 'past' | null = null;

  get chart(): ChartData {
    return this.chartData && this.chartData[this.year];
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private contractsRestService: ContractsRestService,
    private logService: LogService) {
  }

  ngOnInit() {
    const data$ = this.activatedRoute.params.pipe(
      switchMap(params => this.contractsRestService.getContractsEquipmentUsage(params['id'])),
      withLatestFrom(this.translate
        .get(['EQUIPMENT_USAGE_MONTHLY_LABEL', 'EQUIPMENT_USAGE_CUMULATIVE_LABEL', 'EQUIPMENT_USAGE_ALLOWANCE_LABEL'])),
      map(([data, labels]) => data.map(d => this.process(d, labels)))
    );

    this.subscription = data$.subscribe(resp => {
      if (resp && resp.length === 0) {
        this.logService.error('No chart data received');
        return;
      }

      const current = resp[resp.length - 1];
      const past = resp.length > 1 && resp[resp.length - 2];

      this.chartData = {
        current: current.bar.length && current,
        past: past.bar.length && past
      };

      if (this.chartData.current) {
        this.setYear('current');
      } else if (this.chartData.past) {
        this.setYear('past');
      } else {
        this.logService.error('No chart data to display');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setYear(value: 'current' | 'past') {
    this.year = value;
  }

  private process(data: ContractsEquipmentUsage, labels: any): ChartData {
    const { annualExamAllowanceSeries = [], cumulativeExamCountSeries = [], monthlyExamCountSeries = [] } = data;
    const values = [...annualExamAllowanceSeries, ...cumulativeExamCountSeries, ...monthlyExamCountSeries].map(s => s.value);
    const max = Math.max(...values);
    const clip = monthlyExamCountSeries.length !== cumulativeExamCountSeries.length && cumulativeExamCountSeries.length > 1
        ? Math.round((cumulativeExamCountSeries.length - 1) / (monthlyExamCountSeries.length - 1) * 100)
      : monthlyExamCountSeries.length !== cumulativeExamCountSeries.length && cumulativeExamCountSeries.length === 1 ? 1
      : 0;

    let lastCumulative = 0;
    for (let i = 0; i < monthlyExamCountSeries.length; i++) {
      if (!cumulativeExamCountSeries[i]) {
        cumulativeExamCountSeries.push({ name: monthlyExamCountSeries[i].name, value: lastCumulative });
      } else {
        lastCumulative = cumulativeExamCountSeries[i].value;
      }
    }

    const result: ChartData = {
      bar: monthlyExamCountSeries,
      line: [{
        name: labels.EQUIPMENT_USAGE_CUMULATIVE_LABEL,
        series: cumulativeExamCountSeries,
        clip: clip || undefined
      }],
      scale: () => ({ min: 0, max: Math.max(Math.round(max * 1.2), 100) }),
      domain: ['#7a162d', '#ec6602'],
      contractStartDay: data.contractStartDay
    };
    if (annualExamAllowanceSeries && annualExamAllowanceSeries.length !== 0) {
      result.line.unshift({
        name: labels.EQUIPMENT_USAGE_ALLOWANCE_LABEL,
        series: annualExamAllowanceSeries,
      });
      result.domain.splice(0, 0, '#009999');
    }

    return result;
  }
}
