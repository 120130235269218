import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModalPopup } from 'app/core/base-class/base-modal-popup';
import { ToasterService } from 'app/core/component-communication-services/toaster/toaster.service';
import { psrPreApprovalStatusOther, PsrTypeEnum } from 'app/core/core-constants.service';
import { PsrTemplateByModality } from 'app/core/models/professionalServiceRequest/psr-template-by-modality';
import { SelectOption } from 'app/core/models/select-option';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { ProfessionalServiceRequestRestService } from 'app/core/rest-services/professional-service-request-rest.service';
import { EquipmentUtilService } from 'app/core/services/equipment/equipment-util.service';
import { LifeNetUtilService } from 'app/core/utils/life-net-util.service';
import { ProfessionalServiceRequestUtilService } from 'app/core/utils/professional-service-request-util.service';
import { WindowService } from 'app/core/window.service';
import * as _ from 'lodash';
import { finalize, takeUntil } from 'rxjs/operators';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { SpaceValidator } from '../../validators/space.validator';
import { combineLatest } from 'rxjs';
import { CreatePsrTemplatesComponent } from '../../form-group/create-psr-templates/create-psr-templates.component';
import { AttachmentComponent } from '../../form-group/attachment/attachment.component';

@Component({
  selector: 'hl-create-psr-modal',
  templateUrl: './create-psr-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatePsrModalComponent extends BaseModalPopup
  implements OnInit, AfterViewInit {

  @Input()
  equipmentId: string;
  psrViewTemplates: boolean;
  filteredModalities: SelectOption[];
  templatesLoading = true;
  createPsrForm: FormGroup;
  showValidationMessage = false;
  isFormSubmitted = false;
  window = this.windowService.nativeWindow;
  preApprovalStatuses: SelectOption[];

  psrLabelInProgress = 'PSR_CREATION_IN_PROGRESS';
  psrTemplates: PsrTemplateByModality[];
  allowedPSRModalities: string[];
  @Output()
  close = new EventEmitter();
  private labelPsrSuccess: string;

  @ViewChild('psrTemplatesComponent', {static: false})
  psrTemplatesComponent: CreatePsrTemplatesComponent;

  @ViewChild('attachmentComponent', {static: false})
  attachmentComponent: AttachmentComponent;

  constructor(
    private configService: CountryConfigRestService,
    private fb: FormBuilder,
    private psrRestService: ProfessionalServiceRequestRestService,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private windowService: WindowService,
    private psrUtilService: ProfessionalServiceRequestUtilService,
    private lifenetUtilService: LifeNetUtilService,
    private equipmentUtilService: EquipmentUtilService,
    private cd: ChangeDetectorRef,
    private filterUtilService: FilterUtilService,
    renderer: Renderer2
  ) {
    super(renderer);
    this.bodyId = 'create-psr-modal-body';
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  show(event?: Event) {
    super.show(event);
    this.cd.detectChanges();
  }

  init() {
    this.initFormControl();
    this.initFromQueryParams();

    this.psrUtilService
      .convertPsrTypesToSelectOptions(PsrTypeEnum.APPR)
      .subscribe(preApprovalResponse => {
        this.preApprovalStatuses = preApprovalResponse;
      });
    this.loadPsrTemplates();
  }

  ok() {
    this.isFormSubmitted = false;
    this.showValidationMessage = false;
    this.createForm(this.allowedPSRModalities);
    this.createPsrForm.markAsPristine();
    this.createPsrForm.markAsUntouched();
    this.showSpinner = false;
    this.hide();
    this.close.emit();
  }

  cancelCreatePsr() {
    this.isFormSubmitted = false;
    this.showValidationMessage = false;
    this.psrTemplatesComponent.setSelected(null);
    this.attachmentComponent.clearAttachments();
    this.createForm(this.allowedPSRModalities);
    this.createPsrForm.markAsPristine();
    this.createPsrForm.markAsUntouched();
    this.showSpinner = false;
    this.hide();
    this.close.emit();
  }

  private initFormControl() {
    const config$ = this.configService.getConfig();
    const translate$ = this.translateService.get('LABEL_PSR_SUCCESS');
    combineLatest([config$, translate$]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(([config, labelPsrSuccess]) => {
        this.allowedPSRModalities = config.MODALITY_PSR_ALLOWED.split(',');
        this.createForm(config);
        this.labelPsrSuccess = labelPsrSuccess;
      });
  }

  private createForm(config) {
    const emailRegEx = new RegExp(config.EMAIL_VALIDATION_REGEX);
    const emailLength = _.parseInt(config.EMAIL_VALIDATION_LENGTH);

    this.createPsrForm = this.fb.group({
      product: this.fb.group({
        modality: ['', [Validators.required]],
        equipmentKey: [this.equipmentId ? this.equipmentId : '', [Validators.required]],
        selectProduct: ['true'],
        multipleProducts: [''],
        deliveryMethod: ['', [Validators.required]],
        softwareVersion: ['']
      }),
      summary: this.fb.group({
        requestDescription: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        expectedOutcome: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        // additionalDescription aka additionalInformation
        additionalDescription: [''],
        priority: [''],
        siteImpact: [''],
        purchaseOrderNumber: ['']
      }),
      contact: this.fb.group({
        contactEmail: [
          '',
          [Validators.maxLength(emailLength), Validators.pattern(emailRegEx)]
        ],
        contactFirstName: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        contactLastName: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        contactPhone: [''],
        contactSalutation: [''],
        contactTitle: ['']
      }),
      acceptance: this.fb.group({
        preApprovalStatus: ['', [Validators.required]],
        preApprovalStatusOther: [''],
        projectManager: [''],
        siemensContact: [''],
        submitterTitle: ['', [Validators.required, SpaceValidator.noWhiteSpace]],
        confirmed: ['', [Validators.requiredTrue]]
      }),
      attachments: []
    });
  }

  initFromQueryParams() {
    if (this.equipmentId) {
      this.createPsrForm.patchValue({
        product: {equipmentKey: this.equipmentId}
      });
    }
  }

  createPsr() {
    this.isFormSubmitted = true;

    // If the form is valid, then make a REST (post call) to create a PSR
    if (this.createPsrForm.valid) {
      // Hide the message
      this.showValidationMessage = false;
      this.showSpinner = true;

      let formValue = _.omit(this.createPsrForm.value, [
        'acceptance.confirmed'
      ]);
      const selectedApproval = formValue['acceptance']['preApprovalStatus'];
      if (_.isEqual(selectedApproval, psrPreApprovalStatusOther)) {
        formValue['acceptance']['preApprovalStatus'] =
          formValue['acceptance']['preApprovalStatusOther'];
      } else {
        // submit the title instead of the value
        formValue['acceptance'][
          'preApprovalStatus'
          ] = this.lifenetUtilService.pickPropertyFromObject(
          this.preApprovalStatuses,
          {value: selectedApproval},
          'title'
        );
      }

      formValue = _.omit(formValue, ['acceptance.preApprovalStatusOther']);

      this.psrRestService
        .createPsr(formValue).pipe(
        finalize(() => {
          this.ok();
        }))
        .subscribe(response => {
          const message = {
            type: 'success',
            isBodyTranslationKey: false,
            body: this.labelPsrSuccess + '<br/>' + response['psrNumber']
          };
          this.toasterService.emitToast(message);
          this.psrUtilService.emitPsrCreated();
        });
    } else {
      // show validation message set to true
      this.showValidationMessage = true;
    }
  }

  loadPsrTemplates() {
    this.equipmentUtilService
      .getEquipmentViewModelList()
      .subscribe(responses => {
        this.filterUtilService
          .getFilteredModalities(responses)
          .subscribe(modalities => {
            this.filteredModalities = _.filter(modalities, modality => {
              return _.includes(this.allowedPSRModalities, modality.value);
            });
            this.psrUtilService
              .filterPsrTemplates(this.filteredModalities)
              .subscribe(filteredPsrTemplates => {
                this.psrTemplates = filteredPsrTemplates;
                this.psrViewTemplates = !_.isEmpty(this.psrTemplates);
                this.templatesLoading = false;
                this.cd.detectChanges();
              });
          });
      });
  }
}
