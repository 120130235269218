import { Component, Renderer2, ViewChild } from '@angular/core';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { ChatModalComponent } from '../chat-modal/chat-modal.component';

@Component({
  selector: 'hl-chat-terms-modal',
  templateUrl: './chat-terms-modal.component.html'
})
export class ChatTermsModalComponent extends BaseModalPopup {

  @ViewChild('chatModal', { static: false }) chatModal: ChatModalComponent;

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  ok() {
    this.hide();
    this.chatModal.show();
  }
}
