import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../../core/services/cache/cacheable-http-client';
import { EquipmentSrsStatus } from '../../core/models/equipment/equipment-srs-status';
import { restEndPoint } from '../../core/core-constants.service';
import { find } from 'lodash';

export const loadSrsEquipmentStatuses = restEndPoint + 'srs/lastAvailable';

@Injectable()
export class EquipmentSRSRestService {
  equipmentSRSStatuses: EquipmentSrsStatus[] = [];
  constructor(private http: CacheableHttpClient) {}

  loadSrsEquipmentStatuses(): void {
    this.http.get<EquipmentSrsStatus[]>(loadSrsEquipmentStatuses, {cache: false}).subscribe(SRSStatuses => {
      this.equipmentSRSStatuses = SRSStatuses;
    });
  }

  getSrsEquipmentStatus(equipmentKey: string) {
    return find(this.equipmentSRSStatuses, ['equipmentKey', equipmentKey]);
  }

}
