import { Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseModalPopup } from '../../../core/base-class/base-modal-popup';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { NotifStatus, restEndPoint } from '../../../core/core-constants.service';
import { SelectOption } from '../../../core/models/select-option';
import { TicketTypes } from '../../../core/models/tickets/ticket-types';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { TicketsRestService } from '../../../core/rest-services/tickets-rest.service';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { AttachmentUtilService } from '../../../core/utils/attachment-util.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { TicketAttachmentViewModel } from '../../../core/view-models/ticket-attachment-view-model';
import { TicketViewModel } from '../../../core/view-models/ticket-view-model';
import { SpaceValidator } from '../../validators/space.validator';
import { ActivitiesRestService } from '../../../core/rest-services/activities-rest.service';

@Component({
  selector: 'hl-ticket-update-modal',
  templateUrl: './ticket-update-modal.component.html'
})
export class TicketUpdateModalComponent extends BaseModalPopup
  implements OnInit, OnChanges {
  @Input()
  ticketItem: TicketViewModel;
  @Input()
  ticketAction: string;

  viewModelTicket: TicketViewModel;

  datePattern = '';
  dateTimePattern = '';
  headerLabel = '';

  isLoaded: boolean;
  showValidationMessage: boolean;
  isFormSubmitted: boolean;
  showRequestArea: boolean;
  showDangerForPatients: boolean;
  showTicketOwnIncidentNumber: boolean;
  showTicketAttachmentByEquipment: boolean;
  showTicketAttachmentByTicketType: boolean;

  downloadAttachmentList: TicketAttachmentViewModel[];

  translationErrorMessage =
    'GENERIC_LABEL_CREATE_TICKET_VALIDATION_ERROR_MESSAGE';
  // Label text for spinner
  ticketLabelInProgress = 'TICKET_UPDATE_IN_PROGRESS';

  updateTicketForm: FormGroup;
  ticketUpdateAllowed: boolean;
  ticketTypes: TicketTypes[];
  ticketTypesOptions: SelectOption[];

  constructor(
    private configService: CountryConfigRestService,
    private fb: FormBuilder,
    private ticketsUtilService: TicketsUtilService,
    private lifeNetUtilService: LifeNetUtilService,
    private attachmentUtilService: AttachmentUtilService,
    private toasterService: ToasterService,
    private ticketsRestService: TicketsRestService,
    private activitiesRestService: ActivitiesRestService,
    renderer: Renderer2,
    private equipmentUtilService: EquipmentUtilService
  ) {
    super(renderer);
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Note: When the modal is opened second time for another item, it shows same item content as before
    // hence we call again init().
    if (changes['ticketItem'] && !changes['ticketItem'].firstChange) {
      this.init();
    }
  }

  init() {
    this.initProperties();
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(configResponse => {
      this.createForm(configResponse);
      if (_.isEqual(this.ticketAction, 'activity')) {
        this.prepareActivityUpdate();
      } else {
        this.loadViewModelTicket(configResponse.SAP_BACKEND_SYSTEM);
      }
    });
  }

  initProperties() {
    this.viewModelTicket = null;
    this.downloadAttachmentList = [];

    this.isLoaded = false;
    this.showValidationMessage = false;
    this.isFormSubmitted = false;
    this.showRequestArea = false;
    this.showDangerForPatients = false;
    this.showTicketOwnIncidentNumber = false;
    this.showSpinner = true;
    this.showTicketAttachmentByEquipment = false;
    this.showTicketAttachmentByTicketType = false;

    if (_.isEqual(this.ticketAction, 'update')) {
      this.headerLabel = 'UPDATE_TICKET_LABEL';
    } else if (_.isEqual(this.ticketAction, 'convert')) {
      this.headerLabel = 'CONVERT_TICKET_LABEL';
    } else if (_.isEqual(this.ticketAction, 'activity')) {
      this.headerLabel = 'UPDATE_ACTIVITY_LABEL';
    }

    this.ticketUpdateAllowed = false;
    this.ticketTypes = [];
    this.ticketTypesOptions = [];
  }

  createForm(config) {
    const emailRegEx = new RegExp(config.EMAIL_VALIDATION_REGEX);
    const emailLength = _.parseInt(config.EMAIL_VALIDATION_LENGTH);

    this.updateTicketForm = this.fb.group({
      ticketKey: [this.ticketItem.ticketKey],
      action: [String(this.ticketAction).toUpperCase()],
      ticketNumber: [''],
      typeID: [''],
      customerNumber: [''],
      attachments: [[]],
      contact: this.fb.group({
        contactEmail: [
          '',
          [Validators.maxLength(emailLength), Validators.pattern(emailRegEx)]
        ],
        contactFirstName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactLastName: ['', [Validators.required, Validators.maxLength(35), SpaceValidator.noWhiteSpace]],
        contactPhone: [''],
        contactSalutation: [''],
        contactTitle: ['']
      }),
      poNumber: [''],
      feedBack: [_.isEqual(config[this.getFeedbackConfig()], 'true') ? 'email' : ''],
      longText: ['', [Validators.required, Validators.maxLength(2000), SpaceValidator.noWhiteSpace]]
    });

    this.setConfigProperties(config);
  }

  getFeedbackConfig() {
    return this.ticketAction === 'update' ? 'ACTIVITY_SHOW_RESPONSE_AREA' : 'TICKET_SHOW_RESPONSE_AREA';
  }

  setConfigProperties(config) {
    this.datePattern = config.GENERIC_DATE_PATTERN;
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;
    this.isTicketTypeUpdateAllowed(config);

    // feedback requested
    if (_.isEqual(config.FEEDBACK_REQUESTED_EMAIL, 'false')) {
      this.updateTicketForm.patchValue({feedback: 'phone'});
    }

    if (_.isEqual(config.TICKET_DANGER_FOR_PATIENT_RENDER, 'true')) {
      this.showDangerForPatients = true;
    }

    // check for render ticket own incident number
    if (_.isEqual(config.TICKET_SHOW_OWN_INCIDENT_NUMBER_CREATE, 'true')) {
      this.showTicketOwnIncidentNumber = true;
    }

    // check for render ticket own incident number
    if (!this.equipmentUtilService.checkIsLdEquipment(this.ticketItem.ticketKey)) {
      this.showRequestArea = _.isEqual(config.TICKET_SHOW_OWN_INCIDENT_NUMBER_UPDATE, 'true');
    }
  }

  isTicketTypeUpdateAllowed(config) {
    this.ticketsUtilService
      .getAllowedTicketTypesUpdateByEquipmentKey(this.ticketItem, config, true)
      .subscribe(response => {
        this.ticketTypes = response;
        if (this.ticketTypes.length > 1) {
          this.ticketUpdateAllowed = true;
          this.updateTicketForm
            .get('typeID')
            .setValidators([Validators.required]);
        } else if (this.ticketTypes.length === 1) {
          this.updateTicketForm
            .get('typeID')
            .setValue(this.ticketTypes[0].typeId);
        } else {
          this.updateTicketForm.get('typeID').setValue(this.ticketItem.typeID);
        }
        this.ticketTypesOptions = this.ticketsUtilService.convertTicketTypesToSelectOptions(
          this.ticketTypes
        );
      });
  }

  prepareActivityUpdate() {

    // Add the ticket number and customer number for the form
    this.updateTicketForm.patchValue({
      ticketNumber: this.ticketItem.ticketNumber,
      customerNumber: this.ticketItem.customerNumber
    });

    // check for ld equipment and country configurable upload button show
    this.attachmentUtilService
      .checkActivityAttachmentRenderByEquipment(
        this.ticketItem.equipmentKey
      )
      .subscribe(res => {
        this.showTicketAttachmentByEquipment = res;
      });

    this.attachmentUtilService
      .checkActivityTypeAttachmentRender(this.ticketItem.typeID)
      .subscribe(res => {
        this.showTicketAttachmentByTicketType = res;
      });

    this.ticketUpdateAllowed = false;
    this.showRequestArea = false;

    this.showSpinner = false;
    this.isLoaded = true;
  }

  loadViewModelTicket(sapSystem: string) {
    // this method is used in update ticket, which is only possible for open tickets
    this.ticketsUtilService
      .getTicketViewModel(this.ticketItem.ticketKey, NotifStatus.OPEN).pipe(
      finalize(() => {
        this.showSpinner = false;
        this.isLoaded = true;
      }))
      .subscribe(ticketResponse => {
        this.viewModelTicket = ticketResponse;
        this.downloadAttachmentList = this.attachmentUtilService.generateDownloadAttachment(
          this.viewModelTicket.attachments, sapSystem
        );

        // Add the ticket number and customer number for the form
        this.updateTicketForm.patchValue({
          ticketNumber: this.viewModelTicket.ticketNumber,
          customerNumber: this.viewModelTicket.customerNumber
        });

        // check for ld equipment and country configurable upload button show
        this.attachmentUtilService
          .checkTicketAttachmentRenderByEquipment(
            this.viewModelTicket.equipmentKey
          )
          .subscribe(res => {
            this.showTicketAttachmentByEquipment = res;
          });

        this.attachmentUtilService
          .checkTicketTypeAttachmentRender(this.viewModelTicket.typeID)
          .subscribe(res => {
            this.showTicketAttachmentByTicketType = res;
          });
      });
  }

  ok() {
    this.isFormSubmitted = true;

    if (this.updateTicketForm.valid) {
      this.showValidationMessage = false;
      this.showSpinner = true;
      if (this.ticketAction === 'activity') {
        this.updateActivity();
        return;
      }
      // Note:- Here our cache service, only cache the url portion without url params.
      const cacheClearUrl =
        restEndPoint + 'tickets/' + this.ticketItem.ticketKey;
      this.ticketsRestService
        .updateTicket(this.updateTicketForm.value, cacheClearUrl).pipe(
        finalize(() => {
          this.hide();
          // emit that ticket has updated so listeners can react
          this.ticketsUtilService.onSuccessfulTicketUpdatedOrClosed();
        }))
        .subscribe(() => {
          this.showSuccessMessage('TICKET_UPDATE_SUCCESSFUL');
        });
    } else {
      this.showValidationMessage = true;
    }
  }

  updateActivity() {
    this.activitiesRestService.update(this.updateTicketForm.value).pipe(
      finalize(() => {
        this.hide();
      }))
      .subscribe(() => {
        this.showSuccessMessage('ACTIVITY_UPDATE_SUCCESSFUL');
      });
  }

  showSuccessMessage(body: string) {
    const message = {
      type: 'success',
      isBodyTranslationKey: true,
      body
    };
    this.toasterService.emitToast(message);
  }
}
