import { Injectable } from '@angular/core';
import { ExcelExportDefinition } from '../models/excel-export-definition';
import * as _ from 'lodash';
import { DatePipeWrapperPipe } from '../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ExportUtilService {

  constructor(
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private translateService: TranslateService) {
  }

  /**
   *
   * @description
   * Get data to be exported as an array by definitions for labels and columns
   *
   */
  getExportArray(data, labels, columns: ExcelExportDefinition[]) {

    if (!data || !labels || !columns) {
      // logService.info('Invalid data passed', data, labels, columns);
      return [];
    }
    if (labels.length !== columns.length) {
      // logService.info('Export columns length (' + columns.length + ') does not match ' +
      //  'with column labels length (' + labels.length + ')');
      return [];
    }
    let value = '';
    const exportArr = [];

    // Note: we generate the data array for export by the definitions for export columns and labels
    _.forEach(data, (item) => {
      const exportRow = [];

      _.forEach(columns, (column) => {

        value = item[column.columnValue] ? item[column.columnValue] : '';

        // Note: format or concat the value independent if it is related
        if (column && column.dateFormat && value) {
          value = this.datePipeWrapperPipe.transform(value, column.dateFormat);
        }

        if (column && column.mapping && value) {
          // tslint:disable-next-line:ban
          value = this.translateService.instant(column.mapping[value]);
        }

        exportRow.push(value);

      });

      exportArr.push(exportRow);

    });

    return exportArr;
  }
}
