import { Injectable } from '@angular/core';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { restEndPoint } from '../core-constants.service';
import { Observable } from 'rxjs';
import { TeamplayDataWidget } from '../models/teamplay-data-widget';
import { EquipmentUtilService } from '../services/equipment/equipment-util.service';
import { TeamplayDetail } from '../models/teamplay-detail';
import { round } from 'lodash';

@Injectable()
export class TeamplayRestService {
  public static barChartColors = ['#ec6602', '#009999'];
  private teamplayRestName = restEndPoint + 'teamplay/data';
  private teamplayDetailRestName = restEndPoint + 'teamplay/detail/';
  private isTeamplayAvailableRestName = restEndPoint + 'teamplay/data/available';

  public static getSeries(teamplayDetail: TeamplayDetail, exams: string, durations: string, days: any) {
    return [{
      name: days[1],
      series: [
        {
          name: exams,
          value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgNumberExamsMonthsMon)
        },
        {
          name: durations,
          value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgExamDurationMonthsMon),
          secondAxis: true
        }
      ]
    },
      {
        name: days[2],
        series: [
          {
            name: exams,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgNumberExamsMonthsTue)
          },
          {
            name: durations,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgExamDurationMonthsTue),
            secondAxis: true
          }
        ]
      },
      {
        name: days[3],
        series: [
          {
            name: exams,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgNumberExamsMonthsWed)
          },
          {
            name: durations,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgExamDurationMonthsWed),
            secondAxis: true
          }
        ]
      },
      {
        name: days[4],
        series: [
          {
            name: exams,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgNumberExamsMonthsThu)
          },
          {
            name: durations,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgExamDurationMonthsThu),
            secondAxis: true
          }
        ]
      },
      {
        name: days[5],
        series: [
          {
            name: exams,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgNumberExamsMonthsFri)
          },
          {
            name: durations,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgExamDurationMonthsFri),
            secondAxis: true
          }
        ]
      },
      {
        name: days[6],
        series: [
          {
            name: exams,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgNumberExamsMonthsSat)
          },
          {
            name: durations,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgExamDurationMonthsSat),
            secondAxis: true
          }
        ]
      },
      {
        name: days[0],
        series: [
          {
            name: exams,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgNumberExamsMonthsSun)
          },
          {
            name: durations,
            value: TeamplayRestService.getRoundedNumber(teamplayDetail.avgExamDurationMonthsSun),
            secondAxis: true
          }
        ]
      }
    ];
  }

  private static getRoundedNumber(number) {
    return number ? round(number, 1) : 0;
  }

  constructor(
    private http: CacheableHttpClient,
    private equipmentUtilService: EquipmentUtilService) {
  }

  getTeamplayData(): Observable<TeamplayDataWidget> {
    return this.http.get<TeamplayDataWidget>(this.teamplayRestName);
  }

  loadEquipmentByKey(key: string): Promise<any> {
    return this.equipmentUtilService.loadEquipmentByKey(key).toPromise();
  }

  getTeamplayDetail(serialNumber: string): Observable<any> {
    return this.http.get<any>(this.teamplayDetailRestName + serialNumber);
  }

  isTeamplayAvailable(): Observable<boolean> {
    return this.http.get<boolean>(this.isTeamplayAvailableRestName);
  }

  clearCache() {
    this.http.clearCache(this.teamplayRestName);
    this.http.clearCache(this.isTeamplayAvailableRestName);
  }
}
