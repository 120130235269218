import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { EqMobileAddress } from '../../../core/models/equipment/eq-mobile-address';
import { SelectOption } from '../../../core/models/select-option';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { EquipmentViewModel } from '../../../core/view-models/equipment-view-model';
import { DropdownOptions } from '../../../core/models/dropdown-options';

const ADDRESS_ID = 'addressId';
const US_STATES: SelectOption[] = [
  {title: 'Alabama', value: 'AL'},
  {title: 'Alaska', value: 'AK'},
  {title: 'American Samoa', value: 'AS'},
  {title: 'Arizona', value: 'AZ'},
  {title: 'Arkansas', value: 'AR'},
  {title: 'California', value: 'CA'},
  {title: 'Colorado', value: 'CO'},
  {title: 'Connecticut', value: 'CT'},
  {title: 'Delaware', value: 'DE'},
  {title: 'District Of Columbia', value: 'DC'},
  {title: 'Federated States Of Micronesia', value: 'FM'},
  {title: 'Florida', value: 'FL'},
  {title: 'Georgia', value: 'GA'},
  {title: 'Guam', value: 'GU'},
  {title: 'Hawaii', value: 'HI'},
  {title: 'Idaho', value: 'ID'},
  {title: 'Illinois', value: 'IL'},
  {title: 'Indiana', value: 'IN'},
  {title: 'Iowa', value: 'IA'},
  {title: 'Kansas', value: 'KS'},
  {title: 'Kentucky', value: 'KY'},
  {title: 'Louisiana', value: 'LA'},
  {title: 'Maine', value: 'ME'},
  {title: 'Marshall Islands', value: 'MH'},
  {title: 'Maryland', value: 'MD'},
  {title: 'Massachusetts', value: 'MA'},
  {title: 'Michigan', value: 'MI'},
  {title: 'Minnesota', value: 'MN'},
  {title: 'Mississippi', value: 'MS'},
  {title: 'Missouri', value: 'MO'},
  {title: 'Montana', value: 'MT'},
  {title: 'Nebraska', value: 'NE'},
  {title: 'Nevada', value: 'NV'},
  {title: 'New Hampshire', value: 'NH'},
  {title: 'New Jersey', value: 'NJ'},
  {title: 'New Mexico', value: 'NM'},
  {title: 'New York', value: 'NY'},
  {title: 'North Carolina', value: 'NC'},
  {title: 'North Dakota', value: 'ND'},
  {title: 'Northern Mariana Islands', value: 'MP'},
  {title: 'Ohio', value: 'OH'},
  {title: 'Oklahoma', value: 'OK'},
  {title: 'Oregon', value: 'OR'},
  {title: 'Palau', value: 'PW'},
  {title: 'Pennsylvania', value: 'PA'},
  {title: 'Puerto Rico', value: 'PR'},
  {title: 'Rhode Island', value: 'RI'},
  {title: 'South Carolina', value: 'SC'},
  {title: 'South Dakota', value: 'SD'},
  {title: 'Tennessee', value: 'TN'},
  {title: 'Texas', value: 'TX'},
  {title: 'Utah', value: 'UT'},
  {title: 'Vermont', value: 'VT'},
  {title: 'Virgin Islands', value: 'VI'},
  {title: 'Virginia', value: 'VA'},
  {title: 'Washington', value: 'WA'},
  {title: 'West Virginia', value: 'WV'},
  {title: 'Wisconsin', value: 'WI'},
  {title: 'Wyoming', value: 'WY'}
];

@Component({
  selector: 'hl-create-ticket-mobile-address-area',
  templateUrl: './create-ticket-mobile-address-area.component.html'
})
export class CreateTicketMobileAddressAreaComponent implements OnInit, OnChanges {

  @Input() mobileAddressForm: FormGroup;
  @Input() isFormSubmitted: boolean;
  @Input() equipment: EquipmentViewModel;

  isInputNewAddress: boolean;
  selectedDropdownAddressValue: string;
  mobileAddresses: EqMobileAddress[];
  localeUserInputtedFormValue: EqMobileAddress;
  states: SelectOption[] = US_STATES;
  mobileAddressesOptions: DropdownOptions[];

  constructor(private equipmentUtilService: EquipmentUtilService) {
  }

  ngOnInit() {
    this.init();
  }

  /**
   * @description
   * When the modal is opened second time for another item, it shows same item content as before
   * hence we call again init().
   * Or when the equipment is changed (removed and a new one added).
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // Note: When the modal is opened second time for another item, it shows same item content as before
    // hence we call again init().
    if (changes['equipment'] && !changes['equipment'].firstChange) {
      this.init();
    }
  }

  init() {
    this.isInputNewAddress = false;
    this.mobileAddresses = [];
    this.localeUserInputtedFormValue = this.getMobileAddressFormControlValue();
    this.handleValidatorsForMobileEquipment();
    this.setDropdownOptions();
  }

  /**
   * @description
   * Add/Remove Validators for the MobileAddresses form group.
   * If the equipment is mobile then we add Validators for all form fields except for tzCode, tzOffset and type.
   * In any other case, we remove the validators.
   */
  handleValidatorsForMobileEquipment() {
    if (this.equipment.mobile) {
      _.forEach(this.mobileAddressForm.value, (values, key) => {
        if (!_.isEqual(key, 'tzCode') && !_.isEqual(key, 'tzOffset') &&
          !_.isEqual(key, 'type')) {
          this.mobileAddressForm.get(key).setValidators([Validators.required]);
          this.mobileAddressForm.get(key).updateValueAndValidity();
        }
      });
    } else {
      _.forEach(this.mobileAddressForm.value, (values, key) => {
        this.mobileAddressForm.get(key).clearValidators();
        this.mobileAddressForm.get(key).updateValueAndValidity();
      });
    }

    this.getMobileEquipmentAddresses();
  }

  /**
   * @description
   * Making the BE rest call to get the mobile addresses for the selected equipment.
   * If we get an empty response, then we clear the addressId form field validator.
   * Also, since the default value for 'type' is existing, if we get empty response,
   * we patch with type === 'new', and we hide from HTML the whole dropdown for selecting an existing address.
   */
  getMobileEquipmentAddresses() {
    this.equipmentUtilService.getMobileEquipmentAddresses(this.equipment.key).pipe(
      finalize(() => {
        this.initMobileAddressForm();
      }))
      .subscribe(mobileAddresses => {
        this.mobileAddresses = mobileAddresses;
        this.setDropdownOptions();
      }, () => {
        this.mobileAddresses = [];
      });
  }

  initMobileAddressForm() {
    // no existing address, then input new address
    this.isInputNewAddress = _.isEmpty(this.mobileAddresses);

    // since default it is existing and then when BE response is empty then radio button should be new
    this.mobileAddressForm.patchValue({
      type: this.isInputNewAddress ? 'new' : 'existing'
    });

    if (this.isInputNewAddress) {
      this.mobileAddressForm.get(ADDRESS_ID).clearValidators();
      this.mobileAddressForm.get(ADDRESS_ID).updateValueAndValidity();
    }
  }

  /**
   * @description
   * This method is called everytime the value of the radio button in FE is changed.
   * If the current value is 'new' then we fill the form fields, with the previously inputted values.
   * If there were none, then they remain empty. Also, the addressId validator is cleared, since it's a new address.
   * Else case: we pre-select the dropdown item that was selected by the user. If there was none, then none is a selected.
   * On both cases, we save the value of either the dropdown or the new address form, so that in case of future change, those values
   * can be loaded.
   */
  onChangeRadioBtn() {
    const selectedValue = this.mobileAddressForm.get('type').value;
    this.isInputNewAddress = _.isEqual(selectedValue, 'new');

    /**
     * Scenario :- When user entered new address field and switch to dropdown and select a value
     * and then come back to new address field, then the already entered value should be filled.
     */
    if (this.isInputNewAddress) {
      this.patchFormValues(this.localeUserInputtedFormValue);
      this.mobileAddressForm.get(ADDRESS_ID).clearValidators();
    } else {
      this.mobileAddressForm.get(ADDRESS_ID).setValidators([Validators.required]);
      this.localeUserInputtedFormValue = this.getMobileAddressFormControlValue();

      // When user select a value in the dropdown and move to new address field and then come back
      // to drop down again, the form needs to patched with the previously selected dropdown value
      // and not with the user filled input value
      this.onChangeDropdownSelect();
    }
    this.mobileAddressForm.get(ADDRESS_ID).updateValueAndValidity();
  }

  getMobileAddressFormControlValue(): EqMobileAddress {
    return {
      addressId: this.mobileAddressForm.get('addressId').value,
      customerName: this.mobileAddressForm.get('locationName').value,
      street: this.mobileAddressForm.get('street').value,
      city: this.mobileAddressForm.get('city').value,
      state: this.mobileAddressForm.get('state').value,
      zip: this.mobileAddressForm.get('zip').value
    } as EqMobileAddress;
  }

  onChangeDropdownSelect() {
    if (this.selectedDropdownAddressValue) {
      this.patchFormValues(this.mobileAddresses.find(a => a.addressId.toString() === this.selectedDropdownAddressValue));
    }
  }

  getSelectedDropdownAddressString(item: EqMobileAddress) {
    return item.street + ', ' + item.city + ', '
      + item.state + ', ' + item.zip;
  }

  setDropdownOptions() {
    const dropdownOptions: DropdownOptions[] = [];
    if (this.mobileAddresses) {
      for (let i = 0; i < this.mobileAddresses.length; i++) {
        dropdownOptions[i] = {
          title: this.mobileAddresses[i].customerName + '<br/>' + this.getSelectedDropdownAddressString(this.mobileAddresses[i]),
          value: this.mobileAddresses[i].addressId.toString()
        };
      }
    }
    this.mobileAddressesOptions = dropdownOptions;
  }

  patchFormValues(item: EqMobileAddress) {
    if (item) {
      this.mobileAddressForm.patchValue({
        addressId: item.addressId,
        locationName: item.customerName,
        street: item.street,
        city: item.city,
        state: item.state,
        zip: item.zip
      });
    }
  }
}
