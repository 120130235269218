import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightNameValidation'
})
export class HighlightNameValidationPipe implements PipeTransform {

  transform(selection: string, enableHighlight: boolean = true, usedSelections: string[]): boolean {
    return enableHighlight && (!selection || usedSelections.some(value => value === selection));
  }
}
