import { DropdownOptions } from '../../core/models/dropdown-options';

export const countWhatOptionNotifications = 'notifications';
export const countWhatOptionTasks = 'tasks';

export const basedOnOptionNotification = 'notification';
export const basedOnOptionTask = 'task';
export const countryDefaultValueForApplicableFor = 'Country';

export const optionDowntime = 'Downtime';

export const startEndOutOfOptionNotification = 'notification';

export const oppositeKPIConfigurationValue = 'opposite';

export const comparisonOptions: DropdownOptions[] = [
  {title: '>', value: '>'},
  {title: '>=', value: '>='},
  {title: '<', value: '<'},
  {title: '<=', value: '<='},
  {title: '=', value: '='},
  {title: '!=', value: '!='}
];

export const comparisonOptionsWithEmptyOption: DropdownOptions[] = [
  {title: undefined, value: undefined},
  {title: '>', value: '>'},
  {title: '>=', value: '>='},
  {title: '<', value: '<'},
  {title: '<=', value: '<='},
  {title: '=', value: '='},
  {title: '!=', value: '!='}
];

export const notificationsOptions: DropdownOptions[] = [
  {title: 'MC4', value: 'MC4'},
  {title: 'MH3', value: 'MH3'},
  {title: 'MQ4', value: 'MQ4'},
  {title: 'MS1', value: 'MS1'},
  {title: 'MS2', value: 'MS2'},
  {title: 'MS3', value: 'MS3'},
  {title: 'MS6', value: 'MS6'},
  {title: 'MM2', value: 'MM2'},
  {title: 'MM3', value: 'MM3'},
  {title: 'MU2', value: 'MU2'},
  {title: 'MU3', value: 'MU3'},
  {title: 'MU6', value: 'MU6'},
  {title: 'MI2', value: 'MI2'},
  {title: 'MI3', value: 'MI3'},
  {title: 'MI6', value: 'MI6'},
  {title: 'MM', value: 'MM'},
  {title: 'MU', value: 'MU'},
  {title: 'MI', value: 'MI'}
];

export const tasksOptions: DropdownOptions[] = [
  {title: 'APPS', value: 'APPS'},
  {title: 'BGRP', value: 'BGRP'},
  {title: 'CBCI', value: 'CBCI'},
  {title: 'CC', value: 'CC'},
  {title: 'CCRR', value: 'CCRR'},
  {title: 'CREF', value: 'CREF'},
  {title: 'CRM', value: 'CRM'},
  {title: 'CTBI', value: 'CTBI'},
  {title: 'CUUP', value: 'CUUP'},
  {title: 'D3PA', value: 'D3PA'},
  {title: 'DISP', value: 'DISP'},
  {title: 'DOWN', value: 'DOWN'},
  {title: 'EMLC', value: 'EMLC'},
  {title: 'EMLI', value: 'EMLI'},
  {title: 'EMLT', value: 'EMLT'},
  {title: 'EODT', value: 'EODT'},
  {title: 'ETA', value: 'ETA'},
  {title: 'HOTS', value: 'HOTS'},
  {title: 'HQCO', value: 'HQCO'},
  {title: 'HQPM', value: 'HQPM'},
  {title: 'HQUP', value: 'HQUP'},
  {title: 'HSC', value: 'HSC'},
  {title: 'INOF', value: 'INOF'},
  {title: 'MESC', value: 'MESC'},
  {title: 'MPC', value: 'MPC'},
  {title: 'MPS', value: 'MPS'},
  {title: 'MTR', value: 'MTR'},
  {title: 'NOAP', value: 'NOAP'},
  {title: 'NVOL', value: 'NVOL'},
  {title: 'OSR', value: 'OSR'},
  {title: 'PERF', value: 'PERF'},
  {title: 'POST', value: 'POST'},
  {title: 'PROA', value: 'PROA'},
  {title: 'QHSC', value: 'QHSC'},
  {title: 'QINI', value: 'QINI'},
  {title: 'QPST', value: 'QPST'},
  {title: 'QQT', value: 'QQT'},
  {title: 'QQUP', value: 'QQUP'},
  {title: 'QRES', value: 'QRES'},
  {title: 'QRSP', value: 'QRSP'},
  {title: 'QSUB', value: 'QSUB'},
  {title: 'RCC', value: 'RCC'},
  {title: 'REQS', value: 'REQS'},
  {title: 'REQT', value: 'REQT'},
  {title: 'RESP', value: 'RESP'},
  {title: 'RINF', value: 'RINF'},
  {title: 'RPER', value: 'RPER'},
  {title: 'RSCA', value: 'RSCA'},
  {title: 'RSCT', value: 'RSCT'},
  {title: 'SMS', value: 'SMS'},
  {title: 'SYSR', value: 'SYSR'},
  {title: 'SYUP', value: 'SYUP'},
  {title: 'TRAV', value: 'TRAV'},
  {title: 'WAIT', value: 'WAIT'},
  {title: 'WIPR', value: 'WIPR'},
  {title: 'ZCCU', value: 'ZCCU'},
  {title: 'ZCSC', value: 'ZCSC'}
];

export const chartTypeOptions: DropdownOptions[] = [
  {title: 'Line chart', value: 'line', icon: 'icon-line-graph'},
  {title: 'Donut chart', value: 'donut', icon: 'icon-donut-graph'},
  {title: 'Numbers chart', value: 'number', icon: 'icon-number-graph'},
  {title: 'Bar chart', value: 'bar', icon: 'icon-bar-graph'}
];

export const chartColors = ['#329ebc', '#8b32bc', '#32bc4d'];
export const stackedChartColors = ['#009999', '#ec6602'];
export const groupedChartColors = ['#ec6602', '#009999'];

export const chartDateFormat = 'YYYY.MM';

export const mainAxisValueOptions: DropdownOptions[] = [
  {title: 'LABEL_MONTHS', value: 'months'},
  {title: 'LABEL_MODALITIES', value: 'modalities'}
];

export const graphConfigurationTypes = {
  MAIN_AXIS_TYPE: 'mainAxisType',
  GRAPH_CALCULATOR: 'graphCalculator',
  ALLOWED_CUSTOMERS: 'allowedCustomers'
};

export enum CalculatorTypes {
  LINE_CHART_AVERAGE_MONTHS = 'LINE_CHART_AVERAGE_MONTHS',
  BAR_CHART_AVERAGE_MONTHS = 'BAR_CHART_AVERAGE_MONTHS',
  BAR_CHART_AVERAGE_MONTHS_PER_MODALITY = 'BAR_CHART_AVERAGE_MONTHS_PER_MODALITY',
  NUMBER_CHART_AVERAGE_MONTHS = 'NUMBER_CHART_AVERAGE_MONTHS',
  DONUT_CHART_DASHBOARD = 'DONUT_CHART_DASHBOARD'
}

export enum ChartTypes {
  LINE = 'line',
  DONUT = 'donut',
  NUMBER = 'number',
  BAR = 'bar'
}

export enum KpiTypes {
  DURATION = 'duration',
  QUANTITY = 'quantity'
}
