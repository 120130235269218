import { combineLatest, forkJoin, Observable } from 'rxjs';
import {
  configValueSplitter,
  NotifStatus,
  plannedActivity,
  plannedActivityAndTraining,
  plannedTraining
} from '../../core-constants.service';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from '../../utils/date-util.service';
import { ActivitiesRestService } from '../../rest-services/activities-rest.service';
import { EquipmentRestService } from '../../rest-services/equipment-rest.service';
import { LifeNetUtilService } from '../../utils/life-net-util.service';
import { ActivitiesViewModel } from '../../view-models/activities-view-model';
import { Activities } from '../../models/activities/activities';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { StatusCountViewModel } from '../../models/status-count-view-model';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { SelectOption } from 'app/core/models/select-option';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { BrowserStateService } from '../browser-state.service';
import { Router } from '@angular/router';
import { GetActivityStatusColorPipe } from '../../../shared/pipes/get-activity-status-color/get-activity-status-color.pipe';
import { ActivitiesStatusDescriptionTranslationService } from './activities-status-description-translation-service';

@Injectable()
export class ActivitiesUtilService {
  private _selectedActivity: ActivitiesViewModel = null;
  private statusColorPipe: GetActivityStatusColorPipe;

  constructor(private lifeNetUtilService: LifeNetUtilService,
    private equipmentRestService: EquipmentRestService,
    private activitiesRestService: ActivitiesRestService,
    private dateUtilService: DateUtilService,
    private translateService: TranslateService,
    private countryConfigRestService: CountryConfigRestService,
    private browserStateService: BrowserStateService,
    private descriptionTranslationService: ActivitiesStatusDescriptionTranslationService,
    private router: Router) {
    this.statusColorPipe = new GetActivityStatusColorPipe(descriptionTranslationService);
  }

  get selectedActivity(): ActivitiesViewModel {
    return this._selectedActivity;
  }

  set selectedActivity(selectedActivity: ActivitiesViewModel) {
    this._selectedActivity = selectedActivity;
  }

  /**
   * @description Returns the view model for tickets by merging properties from
   * /equipment to /tickets
   * @param {number} openCloseStatusFilter : 1 equals open , 2 equals closed
   * @returns {Observable<ActivitiesViewModel[]>}
   */
  getActivitiesViewModelList(openCloseStatusFilter: NotifStatus): Observable<ActivitiesViewModel[]> {
    return this.getActivitiesViewModelListFrom(this.activitiesRestService.getActivities(openCloseStatusFilter));
  }

  getActivitiesViewModelListFrom(activities: Observable<Activities[]>): Observable<ActivitiesViewModel[]> {

    const findObject = {
      findKey: 'key',
      findValue: 'equipmentKey',
      propertiesToMerge: [
        'productName',
        'myEquipmentName',
        'siemensId',
        'department',
        'street',
        'city',
        'zip',
        'modality',
        'modalityTranslation',
        'state',
        'cmdbEquipment',
        'customerName'
      ]
    };

    return this.lifeNetUtilService.createViewModels(this.equipmentRestService.getEquipment(),
      activities, findObject);
  }

  getSingleActivity(key: string, notifStatus: NotifStatus): Observable<ActivitiesViewModel> {
    return this.getActivitiesViewModelList(notifStatus).pipe(
      map(activityViewModelListResponse => activityViewModelListResponse.filter(activity => activity.ticketKey === key)[0])
    );
  }

  /**
   * @description Get the types for activities
   */
  getActivityTypes() {
    return this.activitiesRestService.getActivityTypes().pipe(
      map(typesResponse => {
        const activityLocalTypes = [];
        _.forEach(typesResponse, (type) => {
          const obj: { [k: string]: any } = {};
          obj.title = type.typeDescription;
          obj.value = type.typeId;
          activityLocalTypes.push(obj);
        });
        return activityLocalTypes;
      })
    );
  }

  /**
   *
   * @param {String} activityType
   * @param {Object} config
   *
   * @description
   * Get the future dashboard planned activities filtered by activity type.
   * Note:-
   * Future means all activities from current time stamp.
   */
  getDashboardPlannedActv(activityType: string, config): Observable<ActivitiesViewModel[]> {
    return this.getActivitiesViewModelList(NotifStatus.OPEN).pipe(
      map(activitiesResponse =>
        this.getFuturePlannedActvBasedOnType(activitiesResponse, activityType, config)
      )
    );
  }

  /**
   *
   * @param {object} dataset | Merged dataset to be filtered
   * @param {string} type | Type to be filtered
   * @param {object} config | country config file
   *
   * @description
   * Get the future planned activities based on type.
   * Note:- type here is 'activity' or 'training'
   */
  getFuturePlannedActvBasedOnType(dataset: ActivitiesViewModel[], type: string, config): ActivitiesViewModel[] {

    if (_.isEqual(type, plannedActivity)) {
      return this.filterByFutureActivity(dataset, config.PLANNED_ACTIVITY_IDENTIFIER);
    } else if (_.isEqual(type, plannedTraining)) {
      return this.filterByFutureActivity(dataset, config.TRAINING_IDENTIFIER);
    } else if (_.isEqual(type, plannedActivityAndTraining)) {
      const activitiesAndTraining =
        [config.PLANNED_ACTIVITY_IDENTIFIER, config.TRAINING_IDENTIFIER].filter(id => id !== '').join(configValueSplitter);
      return this.filterByFutureActivity(dataset, activitiesAndTraining);
    }
  }

  /**
   *
   * @param {object} dataset | Merged dataset to be filtered
   * @param {string} activityTypes | config activity types
   * @description
   * Get the future planned activities based on type.
   */
  filterByFutureActivity(dataset: ActivitiesViewModel[], activityTypes): ActivitiesViewModel[] {

    // Get the current time stamp
    const currentDate = new Date();
    const currentDateTimeStamp = currentDate.getTime();
    const filteredDataSet = [];

    if (activityTypes) {

      const splitTypeArr = activityTypes.split(configValueSplitter);

      _.forEach(dataset, (item) => {

        if (_.includes(splitTypeArr, item.type)) {
          const plannedStartTimeStamp = this.dateUtilService.getUTCToUxTimeStamp(item.plannedStart);

          // Check if planned Start Time if future time or current date
          if (plannedStartTimeStamp && _.gte(plannedStartTimeStamp, currentDateTimeStamp)
            && item.scheduled) {
            filteredDataSet.push(item);
          }
        }

      });
    }
    return filteredDataSet;
  }

  /**
   *
   * @param {String} key | equipment key
   * @param {boolean} showClosed
   *
   * @description
   * Get activities filtered for an equipment and if show closed activities concatenate with filtered activities.
   * Note:
   * Here response is <ActivitiesViewModel[]>
   */
  getPlannedActvWithClosedActvForEquipment(key: string, showClosed: boolean): Observable<ActivitiesViewModel[]> {

    if (showClosed) {
      return this.getActivitiesViewModelListFrom(
        this.getAllActivitiesForEquipment(key).pipe(
          mergeMap(openActivitiesResponse => this.activitiesRestService.getClosedActivities(key).pipe(
            map(closedActivitiesResponse => this.addClosedActivities(openActivitiesResponse, closedActivitiesResponse))
          ))
        )
      );
    } else {
      return this.getActivitiesViewModelListFrom(this.getAllActivitiesForEquipment(key));
    }
  }

  addClosedActivities(openActivities: Activities[], closedActivities: Activities[]): Activities[] {
    closedActivities.forEach(closed => {
      if (!openActivities.some(open => open.ticketNumber === closed.ticketNumber)) {
        openActivities.push(closed);
      }
    });
    return openActivities;
  }

  showPlannedActivityStartDate(activity: ActivitiesViewModel) {
    return (activity.plannedStart && activity.scheduled) || activity.completedDate || activity.dueDate
      || (activity.plannedStart && activity.sapSystem === 'D35');
  }

  loadFilteredActivityStatus(equipmentKeys: string[]): Observable<StatusCountViewModel[]> {
    const activities$ = this.activitiesRestService.getActivities(NotifStatus.OPEN);
    const config$ = this.countryConfigRestService.getConfig().pipe(take(1));

    return forkJoin([activities$, config$]).pipe(
      filter(([activities, config]) => activities && config),
      map(([activities, config]) => {
        const filteredActivities = activities.filter(a => equipmentKeys.indexOf(a.equipmentKey) !== -1);
        return this.getActivitiesStatusValues(filteredActivities, config);
      })
    );
  }

  /**
   * Creates a List of StatusCountViewModel, where,
   * the label(from country config), the css class type and the count of each status type
   * is mapped according to statusType to a ViewModel .
   *
   * @param {Activities[]} activities
   * @param config
   * @returns {StatusCountViewModel[]}
   */
  getActivitiesStatusValues(activities: Activities[], config): StatusCountViewModel[] {
    const statusResultList: StatusCountViewModel[] = [];
    const statusOrder = config.ORDER_ACTIVITY_STATUS.split(',');

    let countAll = 0;

    activities.forEach(activity => {
      if (statusOrder.includes(activity.pmStatus)) {
        const statusColorMap = this.statusColorPipe.transform(activity);

        let pmDescription = '';
        this.translateService.get(statusColorMap.label).subscribe(translation => {
          pmDescription = translation;
        });
        const color = statusColorMap.colorClass ? statusColorMap.colorClass.split('-').pop() : null;

        if (pmDescription && color) {
          let status = statusResultList.find(statusCount => statusCount.title === pmDescription && statusCount.class === color);
          if (!status) {
            status = this.createStatusViewModel(pmDescription, color, 0, activity.pmStatus);
            statusResultList.push(status);
          }

          status.count++;
          countAll++;
        }
      }
    });
    statusResultList.push(this.createStatusViewModel('LABEL_ALL_PLANNED_ACTIVITIES', 'All', countAll, 'All'));

    return statusResultList;
  }

  /**
   * Creates Status ViewModel object
   * @param {string} title label from country config
   * @param {string} classType css class type for the status
   * @param {number} statusCount
   * @param {string} status
   * @returns {StatusCountViewModel}
   */
  createStatusViewModel(title: string, classType: string, statusCount: number, status: string): StatusCountViewModel {
    return { title, status, class: classType, count: statusCount };
  }

  /**
   * @description reads the country config for all available status and generate the dropdown options
   *
   * @returns {Observable<any[]>}
   */
  getActivityStatusDropDownOptions(notifStatus: NotifStatus): Observable<SelectOption[]> {

    const statusDropDownOption = [];

    return this.countryConfigRestService.getConfig().pipe(map((configResponse: any) => {
      let statusOrder: string[];

      switch (notifStatus) {
        case NotifStatus.OPEN:
          statusOrder = configResponse.ORDER_ACTIVITY_STATUS.split(',');
          break;
        case NotifStatus.CLOSED:
          statusOrder = configResponse.ORDER_ACTIVITY_STATUS_CLOSED.split(',');
          break;
        case NotifStatus.ALL:
          statusOrder = configResponse.ORDER_ACTIVITY_STATUS.split(',')
            .concat(configResponse.ORDER_ACTIVITY_STATUS_CLOSED.split(','));
          break;
      }

      // tslint:disable-next-line:ban
      let translate = this.translateService.instant('PLANNED_ACTIVITIES_NOT_SCHEDULED');
      statusDropDownOption.push({
        title: translate,
        value: translate
      });

      _.forEach(statusOrder, (status) => {
        if (status && status.length > 0) {
          // tslint:disable-next-line:ban
          translate = this.translateService.instant('LABEL_ACTIVITY_STATUS_' + status);
          if (!statusDropDownOption.find(item => item.title === translate)) {
            statusDropDownOption.push({
              title: translate,
              value: translate
            });
          }
        }
      });

      return statusDropDownOption;

    }));
  }

  getActivityDetails(key: string, customerNumber: string) {
    return this.activitiesRestService.getActivityDetails(key, customerNumber);
  }

  showActivityHistoryTab(config: any) {
    return _.isEqual(config.ACTIVITY_HISTORY_TAB_FEATURE_TOGGLE, 'true');
  }

  navigateToTab(key: string, state: string, route: any) {
    this.browserStateService.setUserNavigation();
    this.router
      .navigate([key, state], {relativeTo: route, replaceUrl: true})
      .then(() => {
        this.browserStateService.resetUserNavigation();
      });
  }

  /**
   *
   * @param {String} key | equipment key
   *
   * @description
   * Get activities filtered for an equipment.
   * Note:
   * Here response is <Activities[]>
   */
  private getAllActivitiesForEquipment(key: string): Observable<Activities[]> {

    return this.activitiesRestService.getActivities(NotifStatus.OPEN).pipe(map((activitiesResponse) => {
      // get the filtered equipment activity (contains all), no type check
      return _.filter(activitiesResponse, activity => activity.equipmentKey === key);
    }));
  }

  /**
   * Method returns intersection of unique statuses (pmDescriptions) allowed by filter and located in the dataset
   * @param dataset
   * @param filterActivities
   */
  getAvailableActivities(dataset: ActivitiesViewModel[], filterActivities: SelectOption[]): SelectOption[] {
    const activityStatusesInList = [];
    dataset.forEach(activity => {
      if (activityStatusesInList.indexOf(activity.pmDescription) === -1) {
        activityStatusesInList.push(activity.pmDescription);
      }
    });
    return filterActivities.filter(item => activityStatusesInList.includes(item.value));
  }

  /**
   * Method fills translated pmDescription for activities in the list.
   * @param activities
   */
  fillActivityStatusDescriptions(activities: ActivitiesViewModel[]) {
    activities.forEach(activity => {
      this.descriptionTranslationService.getActivityStatusDescriptionTranslation(activity).subscribe(
        value => activity.pmDescription = value
      );
    });
  }

  addSortDateField(activities: ActivitiesViewModel[]) {
    activities.forEach(activity => {
      if (activity.scheduled || (!activity.completedDate && !activity.dueDate && activity.plannedStart)) {
        activity.sortDate = activity.plannedStart;
      } else if (activity.dueDate && !activity.completedDate) {
        activity.sortDate = activity.dueDate;
      } else if (activity.completedDate) {
        activity.sortDate = activity.completedDate;
      }
    });
  }
}
