import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'hl-create-sao-contact-information',
  templateUrl: './create-sao-contact-information.component.html'
})
export class CreateSaoContactInformationComponent implements OnInit {

  @Input() contactITForm: FormGroup;
  @Input() isFormSubmitted: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
