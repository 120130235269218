import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService as AuthGuard } from './core/services/user/auth-guard.service';
import { PageNotFoundComponent } from './front-pages/page-not-found.component';
import { ReportingAuthGuardService } from './core/auth-guards/reporting-metrics/reporting-auth-guard.service';
import { DashboardAuthGuardService } from './core/auth-guards/dashboard/dashboard-auth-guard.service';
import { SprAuthGuardService } from './core/auth-guards/spr/spr-auth-guard.service';
import { SecurityAuthGuardService } from './core/auth-guards/security/security-auth-guard.service';
import { TeamplayWidgetComponent } from './dashboard/teamplay-widget/teamplay-widget.component';
import { ImpersonationAuthGuardService } from './core/auth-guards/impersonation/impersonation-auth-guard.service';
import { TeamplayAuthGuardService } from './core/auth-guards/teamplay/teamplay-auth-guard.service';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard', // this is required since no path after loading is ''
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {stateName: 'dashboard', title: 'MY_DASHBOARD'},
    canActivate: [DashboardAuthGuardService]
  },
  {
    path: 'activities',
    loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule), // use loadChildren for lazy loading modules
    data: {stateName: 'activities', title: 'GEPLANTE_TERMINE'}
  },
  {
    path: 'contracts',
    loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
    data: {stateName: 'contracts', title: 'CONTRACT_TITLE'}
  },
  {
    path: 'administration',
    loadChildren:
      () => import('./customer-administration/customer-administration.module').then(m => m.CustomerAdministrationModule),
    data: {stateName: 'administration', title: 'ADMINISTRATION'}
  },
  {
    path: 'partnerOrders',
    loadChildren: () => import('./partner-orders/partner-orders.module').then(m => m.PartnerOrdersModule),
    data: {stateName: 'partnerOrders', title: 'PARTNER_ORDERS'}
  },
  {
    path: 'impersonation',
    loadChildren:
      () => import('./customer-impersonation/customer-impersonation.module').then(m => m.CustomerImpersonationModule),
    data: {stateName: 'impersonation', title: 'CUSTOMER_IMPERSONATION'},
    canActivate: [ImpersonationAuthGuardService]
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
    data: {stateName: 'order', title: 'MAIN_MENU_INTERN'}
  },
  {
    path: 'psr',
    loadChildren:
      () => import('./professional-service-request/professional-service-request.module').then(m => m.ProfessionalServiceRequestModule),
    data: {stateName: 'psr', title: 'TAB_PSR'}
  },
  {
    path: 'invoices',
    loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule),
    data: {stateName: 'invoices', title: 'MAIN_MENU_INVOICES'}
  },
  {
    path: 'reporting',
    loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
    data: {stateName: 'reporting', title: 'REPORTING'},
    canActivate: [ReportingAuthGuardService]
  },
  {
    path: 'metrics',
    loadChildren: () => import('./service-metrics/service-metrics.module').then(m => m.ServiceMetricsModule),
    data: {stateName: 'service-metrics', title: 'SERVICE-METRICS'},
    canActivate: [ReportingAuthGuardService]
  },
  {
    path: 'spr',
    loadChildren: () => import('./spr/spr.module').then(m => m.SprModule),
    data: {stateName: 'spr', title: 'SERVICE-METRICS'},
    canActivate: [SprAuthGuardService]
  },
  {
    path: 'security',
    loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
    data: {stateName: 'security', title: 'SECURITY_TAB_NAME'},
    canActivate: [SecurityAuthGuardService]
  },
  {
    path: 'advisories',
    loadChildren: () => import('./security-advisories/security-advisories.module').then(m => m.SecurityAdvisoriesModule),
    data: {stateName: 'advisories', title: 'SECURITY_ADVISORIES'}
  },
  {
    path: 'teamplay',
    component: TeamplayWidgetComponent,
    data: {stateName: 'teamplay', title: 'MY_DASHBOARD'},
    canActivate: [TeamplayAuthGuardService]
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
