import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { restEndPoint } from 'app/core/core-constants.service';
import { EqAttachmentViewModel } from '../../../core/view-models/eq-attachment-view-model';
import { AddOmnitureAndRouterStateNameDirective } from '../../../shared/directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';

@Component({
  selector: 'hl-download-documents-zip',
  templateUrl: './download-documents-zip.component.html'
})
export class DownloadDocumentsZipComponent implements OnInit, OnChanges {
  downloadFieldDocumentsZipRestName = restEndPoint + 'equipments/documents/zip';

  @Input() documents: EqAttachmentViewModel[];
  @Input() equipmentKey: string;
  @Input() documentsZipFileNameSuffix: string;
  downloadZipLink = this.downloadFieldDocumentsZipRestName;
  docs2json;

  constructor() {
  }

  ngOnInit() {
    this.prepareDownload();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).every(key => changes[key].isFirstChange())) {
      return;
    }

    this.prepareDownload();
  }

  prepareDownload() {
    if (this.documents.length > 0) {
      this.docs2json = JSON.stringify(
        this.documents.map(document => {
          document.filename = this.extractTextAcceptedByAkamai(document.filename);
          return {
            link: document.link,
            docType: document.docType,
            docTypeName: document.docTypeName,
            fileExtension: document.fileExtension,
            filename: document.filename,
            lastModifiedDateTime: document.lastModifiedDateTime
          };
        })
      );
    } else {
      this.docs2json = null;
    }
  }

  triggerOmnitureTracking() {
    const documentSource =  document.URL.split('documents/');
    if (documentSource.length === 2) {
      this.downloadZipLink =  restEndPoint + `source/${documentSource[1]}/documents/zip`;
    }
    AddOmnitureAndRouterStateNameDirective
      .triggerDocumentsDownloadTracking(
        this.equipmentKey.split('_')[this.equipmentKey.split('_').length - 1]
        + this.documentsZipFileNameSuffix, this.downloadZipLink);
  }

  extractTextAcceptedByAkamai(text: string): string {
    return text.replace('(', '').replace(')', '');
  }
}
