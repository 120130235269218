import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { of, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { NotifStatus } from '../../../core/core-constants.service';
import { ActivitiesRestService } from '../../../core/rest-services/activities-rest.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { TicketsUtilService } from '../../../core/services/tickets/tickets-util.service';
import { UserUtilService } from '../../../core/services/user/user-util.service';
import { AttachmentUtilService } from '../../../core/utils/attachment-util.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { StringUtilService } from '../../../core/utils/string-util.service';
import { TicketAttachmentViewModel } from '../../../core/view-models/ticket-attachment-view-model';
import { AddOmnitureAndRouterStateNameDirective } from '../../directives/add-omniture-and-router-state-name/add-omniture-and-router-state-name.directive';
import { EquipmentRestService } from '../../../core/rest-services/equipment-rest.service';
import { ToasterService } from '../../../core/component-communication-services/toaster/toaster.service';
import { ServiceReport } from '../../../core/models/tickets/ServiceReport';
import { DownloadUtilService } from '../../../core/utils/download-util.service';
import { RefreshItemEventService } from '../../../core/component-communication-services/refresh-item-event/refresh-item-event.service';
import { LogService } from '../../../core/services/log/log.service';
import { forEach, includes, isEqual } from 'lodash';

@Component({
  selector: 'hl-notification-attachments',
  templateUrl: './notification-attachments.component.html'
})
export class NotificationAttachmentsComponent implements OnInit, OnChanges, OnDestroy {
  private _viewModel: any; // Activities | Ticket;

  isLoaded: boolean;
  showServiceReport: boolean;
  datePattern = 'DDMMYYYY';
  dateTimePattern = 'DDMMYYYY HH:mm';
  solutionText: any;
  downloadAttachmentList: TicketAttachmentViewModel[];
  downloadGeneratedAttachmentList: TicketAttachmentViewModel[];
  showSize: boolean;
  showStatus: boolean;
  attachmentNameColumnWidth: number;
  attachmentDetailsColumnWidth: number;
  closedTicketStatusList: string[];
  serviceReportList: ServiceReport[];

  private isAttachmentsLoaded: boolean;
  private isServiceReportsLoaded: boolean;

  @Input() attachmentType: string;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private configService: CountryConfigRestService,
    private attachmentUtilService: AttachmentUtilService,
    private ticketsUtilService: TicketsUtilService,
    private lifeNetUtilService: LifeNetUtilService,
    private activitiesRestService: ActivitiesRestService,
    private equipmentRestService: EquipmentRestService,
    private userUtilService: UserUtilService,
    private stringUtilService: StringUtilService,
    private toasterService: ToasterService,
    private downloadUtilService: DownloadUtilService,
    private refreshItemEventService: RefreshItemEventService,
    private logService: LogService) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get viewModel() {
    return this._viewModel;
  }

  @Input()
  set viewModel(viewModel: any) {
    this._viewModel = viewModel;
  }

  ngOnInit() {
    this.initProperties();
    this.configProperties();
  }

  configProperties() {
    this.configService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe(config => {
      this.init(config);
    });
  }

  getNotifStatus(ticketStatus: string) {
    return includes(this.closedTicketStatusList, ticketStatus) ? NotifStatus.CLOSED : NotifStatus.OPEN;
  }

  /**
   * if the activity changes, this means that the activity should already have changed.
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewModel'] && !changes['viewModel'].firstChange) {
      this.ngOnInit();
    }
  }

  init(config) {
    this.closedTicketStatusList = config.STATUS_IDENTIFIER_CLOSED_NOTIFICATIONS.split(',');
    this.dateTimePattern = config.GENERIC_DATE_TIME_PATTERN;
    this.datePattern = config.GENERIC_DATE_PATTERN;
    const sapSystem = config.SAP_BACKEND_SYSTEM;

    let viewModelDetail$ = of(this.viewModel);

    if (isEqual(this.attachmentType, 'activity')) {
      viewModelDetail$ = this.activitiesRestService.getActivityDetails(this.viewModel.ticketKey, this.viewModel.customerNumber);
    } else if (!this.viewModel['attachments']) {
      viewModelDetail$ = this.ticketsUtilService.getTicketViewModel(
        this.viewModel.ticketKey, this.getNotifStatus(this.viewModel.ticketStatus));
    }

    viewModelDetail$
      .pipe(finalize(() => {
        this.isAttachmentsLoaded = true;

        this.isLoaded = this.isAttachmentsLoaded && this.isServiceReportsLoaded;
      }))
      .subscribe((response) => {
        if (response) {
          this.getAttachmentLists(response, sapSystem);

          this.calculateColumnWidth();

          // set and parse solutionText for line breaks
          if (response.solutionText) {

            this.solutionText =
              this.stringUtilService.parseAndSplitInputStringToLines(response.solutionText);
          }
          this.getServiceReportData(config);
        }
      });

    this.refreshItemEventService.refreshItemSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.refreshViewModel();
    }, (some) => {
      this.logService.debug('what is the problem? 11' + some);
    });
  }

  initProperties() {
    this.isLoaded = false;
    this.isAttachmentsLoaded = false;
    this.isServiceReportsLoaded = false;
    this.solutionText = [];
    this.downloadAttachmentList = [];
    this.downloadGeneratedAttachmentList = [];
    this.showServiceReport = false;
    this.showSize = false;
    this.showStatus = false;
    this.attachmentNameColumnWidth = 8;
    this.attachmentDetailsColumnWidth = 2;
    this.closedTicketStatusList = [];
    this.serviceReportList = [];
  }

  private calculateColumnWidth() {
    forEach(this.downloadAttachmentList, item => {
      if (item.size > 0) {
        this.showSize = true;
      }
      if (item.status) {
        this.showStatus = true;
      }
      if (this.showSize && this.showStatus) {
        return;
      }
    });
    if ((this.showSize && !this.showStatus) || (!this.showSize && this.showStatus)) {
      this.attachmentNameColumnWidth = 10;
      this.attachmentDetailsColumnWidth = 2;
    } else if (!this.showSize && !this.showStatus) {
      this.attachmentNameColumnWidth = 12;
      this.attachmentDetailsColumnWidth = 0;
    } else {
      this.attachmentNameColumnWidth = 8;
      this.attachmentDetailsColumnWidth = 2;
    }
  }

  private getServiceReportData(config) {
    this.showServiceReport =
      this.ticketsUtilService.isServiceReportRender(config.SERVICE_REPORT_RENDER_VIVO,
        config.SERVICE_REPORT_RENDER_VITRO, this.viewModel.equipmentKey);

    if (this.showServiceReport) {
      if (isEqual(config.TOGGLE_NWE_SERVICE_REPORT_FOR_SPP, 'true') && isEqual(config.SAP_BACKEND_SYSTEM, 'SPP')) {
        this.ticketsUtilService.getAvailableServiceReportsForSpp(this.viewModel.ticketKey,
          this.viewModel.customerNumber,
          this.viewModel.ticketNumber
        ).subscribe(response => {
          this.serviceReportList = response;

          this.isServiceReportsLoaded = true;
          this.isLoaded = this.isAttachmentsLoaded && this.isServiceReportsLoaded;
        });
      }
    }
    this.isServiceReportsLoaded = true;
    this.isLoaded = this.isAttachmentsLoaded && this.isServiceReportsLoaded;
  }

  private getAttachmentLists(viewModelDetail: any, sapSystem: string) {
    const attachmentList = this.attachmentUtilService.generateDownloadAttachment(
      viewModelDetail.attachments, sapSystem, this.viewModel.ticketKey);
    forEach(attachmentList, attachment => {
      if (this.attachmentUtilService.isTicketCustomerAttachmentType(attachment.attachmentType)) {
        this.downloadAttachmentList.push(attachment);
      } else {
        this.downloadGeneratedAttachmentList.push(attachment);
      }
    });
  }

  downloadFile(filename: string, url: string) {
    AddOmnitureAndRouterStateNameDirective
      .triggerDocumentsDownloadTracking(filename, url);
    this.downloadUtilService.downloadFileByUrl(filename, url);
  }

  isActivity(): boolean {
    return isEqual(this.attachmentType, 'activity');
  }

  trackByFn(index, item: TicketAttachmentViewModel) {
    return item['attachmentName'];
  }

  refreshViewModel() {
    this.unsubscribe$.next();

    if (this.isActivity()) {
      this.isLoaded = false;
      const url = this.activitiesRestService.activitiesDetailsRestName.replace(/:id/g, this.viewModel.ticketKey);
      this.activitiesRestService.clearCache(url);
      this.ngOnInit();
    }
  }
}
