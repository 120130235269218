export class NewsViewModel {
  public url: string;
  public title: string;
  public description: string;
  public date: string;

  constructor(url: string, title: string, description: string, date: string) {
    this.url = url;
    this.title = title;
    this.description = description;
    this.date = date;
  }
}
