import { Component, ElementRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import {
  DropdownOptions,
  ExtendedNestedDropdownOption
} from '../../../core/models/dropdown-options';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectboxComponent } from '../selectbox/selectbox.component';
import { TranslateService } from '@ngx-translate/core';

const SELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NestedSelectboxComponent),
  multi: true
};

@Component({
  selector: 'hl-nested-selectbox',
  templateUrl: './nested-selectbox.component.html',
  providers: [SELECT_VALUE_ACCESSOR]
})
export class NestedSelectboxComponent extends SelectboxComponent {
  _valueObject: DropdownOptions;
  searchValue: string;

  @Input() enableSearch = false;
  @Input() enableCollapsing = true;
  @Input() expandOnSearch = true;
  @Input() collapseOnOpen = true;
  @Input() scrollOnExpand = true;
  @Input() closeOnSelect = true;
  @Input() isRequired = false;
  @Input() resetOnSelect = false;
  @Input() resetOnOpen = false;
  @Input() options: ExtendedNestedDropdownOption[];

  @Input() set valueObject(value: DropdownOptions) {
    this._valueObject = value;
    this.isActive = !!value;
  }

  @Output() valueObjectChange: EventEmitter<DropdownOptions> = new EventEmitter<DropdownOptions>();

  constructor(
    translate: TranslateService,
    public el: ElementRef
  ) {
    super(translate, el);
  }

  dropdownToggle() {
    if (!this.isDropdownOpen) {
      if (this.resetOnOpen) {
        this.resetValues();
      }
      if (this.enableCollapsing && this.collapseOnOpen) {
        this.collapseChildren(this.options);
      }
    }
    super.dropdownToggle();
  }

  private collapseChildren(options: ExtendedNestedDropdownOption[]) {
    if (options) {
      options.forEach(o => {
        o.isExpanded = false;
        this.collapseChildren(o.extendedChildren);
      });
    }
  }

  resetValues() {
    if (this.enableSearch) {
      this.searchValue = undefined;
      this.setNestedHidden(this.options, false);
    }
  }

  acceptSetSelected = (option: DropdownOptions) => {
    this.valueObject = option === this._valueObject ? undefined : option;
    this.valueObjectChange.emit(option);
    if (this.closeOnSelect) {
      this.dropdownToggle();
    }
    if (this.resetOnSelect) {
      this.resetValues();
    }
  }

  expandScroll = (positionToScroll: number) => {
    if (this.el && (this.el.nativeElement as HTMLElement).getElementsByTagName('ul').length && positionToScroll > 0) {
      (this.el.nativeElement as HTMLElement).getElementsByTagName('ul')[0].scrollTop = positionToScroll;
    }
  }

  filterOptions(searchValue: string) {
    if (searchValue !== null && searchValue !== undefined && typeof searchValue === 'string') {
      const splitValues = searchValue.split(',');
      this.filterChildrenOptions(splitValues, this.options);
      if (this.enableCollapsing && this.expandOnSearch) {
        this.setNestedExpanded(this.options, !!searchValue);
      }
    }
  }

  filterChildrenOptions(splitSearchValues: string[], children: ExtendedNestedDropdownOption[]): boolean {
    let containsSearchValue = false;
    children.forEach(child => {
      const isSearched = !child.disabledSelect && splitSearchValues.some(v => child.title.toLowerCase().includes(v.toLowerCase()));
      const childrenContainsSearchedValue = !!child.extendedChildren &&
        this.filterChildrenOptions(splitSearchValues, child.extendedChildren);
      child.hidden = !isSearched && !childrenContainsSearchedValue;
      if (!child.hidden) {
        if (!containsSearchValue) {
          containsSearchValue = true;
        }
        if (isSearched) {
          this.setNestedHidden(child.extendedChildren, false);
        }
      }
    });
    return containsSearchValue;
  }

  setNestedHidden(children: ExtendedNestedDropdownOption[], hidden: boolean) {
    if (children) {
      children.forEach(ch => {
        ch.hidden = hidden;
        this.setNestedHidden(ch.extendedChildren, hidden);
      });
    }
  }

  setNestedExpanded(children: ExtendedNestedDropdownOption[], expanded: boolean) {
    if (children) {
      children.forEach(ch => {
        if (ch.extendedChildren && ch.extendedChildren.length) {
          ch.isExpanded = expanded;
          this.setNestedExpanded(ch.extendedChildren, expanded);
        }
      });
    }
  }
}
