import { Component, OnInit, ViewChild } from '@angular/core';
import { EquipmentUtilService } from '../../../core/services/equipment/equipment-util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemUpdatesService } from '../../../core/services/system-updates/system-updates-service';
import { StateService } from '../../../core/services/state.service';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { SystemUpdatesConstantsService } from '../../../core/services/system-updates/system-updates-constants.service';
import { UserUtilService } from '../../../core/services/user/user-util.service';
import { Observable } from 'rxjs';
import { LogService } from '../../../core/services/log/log.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseSystemUpdates } from '../../../core/base-class/base-system-updates';
import { SystemUpdateBaseViewModel } from '../../../core/view-models/system-update-base-view-model';
import { OverlayComponent } from '../../../shared/components/overlay/overlay.component';
import { Equipment } from '../../../core/models/equipment/equipment';
import { BrowserStateService } from '../../../core/services/browser-state.service';
import { FilterUtilService } from '../../../core/utils/filter-util.service';
import { LifeNetUtilService } from '../../../core/utils/life-net-util.service';
import { mergeMap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'hl-equipment-system-updates',
  templateUrl: './equipment-system-updates.component.html'
})
export class EquipmentSystemUpdatesComponent extends BaseSystemUpdates implements OnInit {

    @ViewChild('detailOverlay', { static: false }) detailOverlay: OverlayComponent;
  equipmentItem: Equipment = null;
  selectedIndex: number;
  private isStatusSelected = false;
  activatedRoute: ActivatedRoute;

  constructor(systemUpdatesConstantsService: SystemUpdatesConstantsService,
    browserStateService: BrowserStateService,
    filterUtilService: FilterUtilService,
    systemUpdatesService: SystemUpdatesService,
    translateService: TranslateService,
    route: ActivatedRoute,
    router: Router,
    equipmentUtilService: EquipmentUtilService,
    configService: CountryConfigRestService,
    userUtilService: UserUtilService,
    stateService: StateService,
    logService: LogService,
    activatedRoute: ActivatedRoute,
    public lifeNetUtilService: LifeNetUtilService) {

    // base class instantiation
    super(systemUpdatesConstantsService, systemUpdatesService,
      translateService, route, router, equipmentUtilService, configService, userUtilService, stateService,
      logService, browserStateService, filterUtilService);
    this.activatedRoute = activatedRoute;
  }

  ngOnInit() {
    super.init();
    this.getRouteParams(this.route).subscribe(() => super.init());
    this.route.params.pipe(
      switchMap((params: any) => this.equipmentUtilService.getEquipment(params['id'])),
      mergeMap((equipmentResponse) => {
        this.equipmentItem = equipmentResponse;
        return this.activatedRoute.queryParams;
      })
    ).subscribe((queryParams: any) => {
      const tabParam = queryParams['tab'];
      if (tabParam && this.equipmentItem) {
        this.router.navigate(
          ['/equipment', this.equipmentItem.key, tabParam],
          {replaceUrl: true}
        );
      }
    });
  }

  /**
   * @description Additional properties to be initialized for derived class
   */
  afterInitProperties() {
    this.sortSkeleton = this.systemUpdatesConstantsService.getEquipmentSysUpdatesSortSkeleton();
    this.allUpdateStatuses = this.systemUpdatesConstantsService.getEquipmentUpdateStatusesSkeleton();
    this.checkAllStates = false;
    if (!this.isStatusSelected && this.allUpdateStatuses.length >= 3) {
      this.selectedUpdateStatuses = [
        this.allUpdateStatuses[0].value,
        this.allUpdateStatuses[1].value,
        this.allUpdateStatuses[2].value
      ];
      this.isStatusSelected = true;
    }
    this.activatedRoute.queryParams.subscribe((params: any) => {
      const searchParam = params['search'];
      if (searchParam) {
        this.searchInput = searchParam;
        this.selectedUpdateStatuses = [];
      }
    });
  }

  /**
   * @description Gets messages
   */
  loadViewModelList(): Observable<SystemUpdateBaseViewModel[]> {
    return this.systemUpdatesService.getSystemUpdatesForEquipment(this.route.snapshot.params.id);
  }

  navigate() {
  }

  setPropertiesFromQueryParams(config: any) {
  }

  openDetailOverlay(item: SystemUpdateBaseViewModel) {
    if (!item) {
      return;
    }
    const index = this.viewModelList.findIndex(i => i.updateNumber === item.updateNumber);
    this.onClickUpdateSelectionByIndex(index);
    this.detailOverlay.show();
  }

  onClickUpdateSelectionByIndex(index: number) {
    this.selectedItem = this.viewModelList[index];
    this.selectedIndex = index;
    this.browserStateService.setUserNavigation();
    this.router.navigate(['/equipment', this.equipmentItem.key, 'update', this.selectedItem.updateNumber]).then(() => {
      this.browserStateService.resetUserNavigation();
    });
  }

  trackByFn(index, item) {
    return item['categoryKey'];
  }
}
