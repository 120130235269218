import { Injectable } from '@angular/core';

@Injectable()
export class BrowserStateService {

  isUserNavigation = false;

  constructor() {
  }

  setUserNavigation(): void {
    this.isUserNavigation = true;
  }

  resetUserNavigation(): void {
    this.isUserNavigation = false;
  }

  getUserNavigation(): boolean {
    return this.isUserNavigation;
  }
}
