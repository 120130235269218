import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'operationalStatus'
})
export class OperationalStatusPipe implements PipeTransform {

  transform(dataset: any, operationalStatus?: any): any {

    /**
     * Check if dataset has status value because rest service for /equipments/status
     * return empty list and then when merged with /equipments/all,
     * status attribute will be "" (empty string)
     */
    const checkStatusAttributeList =
      this.getListOfUniqueStatusFromList(dataset, 'status');

    // check if there is at least one item in list, status is already merged.
    if (dataset.length > 0 && checkStatusAttributeList.length > 0 && operationalStatus && operationalStatus.status.length > 0) {
      //  Note: operationalState.status value can be for e.g: ['red', 'yellow', 'green']
      return this.filterResult(dataset, operationalStatus);
    } else {
      // do nothing; return the full object
      return dataset;
    }
  }

  filterResult(dataset, operationalStatus) {
    const filtered = [];

    let supportedStatuses;

    // assign status mapping from country config
    if (operationalStatus.config) {
      supportedStatuses = {
        green: operationalStatus.config.green,
        red: operationalStatus.config.red,
        yellow: operationalStatus.config.yellow,
        redCritical: operationalStatus.config.redCritical
      };
    }

    _.filter(dataset, element => {
      _.forEach(operationalStatus.status, value => {
        if (!!supportedStatuses[value] && _.isEqual(supportedStatuses[value], element['status'])) {
          filtered.push(element);
        }
      });
    });
    return filtered;
  }

  getListOfUniqueStatusFromList(dataSet, filterProperty) {
    let uniqProp: any;
    uniqProp = _.chain(dataSet).map(filterProperty).uniq().sortBy().value();
    return _.without(uniqProp, null, undefined, '');
  }
}
