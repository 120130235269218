import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserUtilService } from './user-util.service';
import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public userUtilService: UserUtilService,
              public router: Router) {}

    canActivate() {
      return this.userUtilService.getUser()
        .pipe(
          map(user => !!user),
          tap(isAuthenticated => {
            this.router.navigate(isAuthenticated? ['/404'] : ['/welcome']);
          }),
          catchError(() => {
            this.router.navigate(['/welcome']);
            return of(false);
          })
        );
    }
}
