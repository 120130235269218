import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EquipmentRoutingModule } from './equipment-routing.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EquipmentComponent } from './equipment.component';
import { EquipmentActivitiesComponent } from './equipment-detail/equipment-activities/equipment-activities.component';
import { EquipmentTicketHistoryComponent } from './equipment-detail/equipment-ticket-history/equipment-ticket-history.component';
import { EquipmentSystemInformationComponent } from './equipment-detail/equipment-system-information/equipment-system-information.component';
import { EquipmentComponentListComponent } from './equipment-detail/equipment-system-information/equipment-component-list/equipment-component-list.component';
import { EquipmentContractsComponent } from './equipment-detail/equipment-contracts/equipment-contracts.component';
import { EquipmentOptionsUpgradesComponent } from './equipment-detail/equipment-options-upgrades/equipment-options-upgrades.component';
import { EquipmentDocumentsSimpleListComponent } from './equipment-detail/equipment-documents/equipment-documents-wrapper/equipment-documents-simple-list/equipment-documents-simple-list.component';
import { EquipmentContactWidgetComponent } from './equipment-detail/equipment-contact-widget/equipment-contact-widget.component';
import { EquipmentSystemUpdatesComponent } from './equipment-detail/equipment-system-updates/equipment-system-updates.component';
import { EquipmentSecurityComponent } from './equipment-detail/equipment-security/equipment-security.component';
import { MyEquipmentSwitchComponent } from './my-equipment-switch/my-equipment-switch.component';
import { MyEquipmentSwitchService } from './my-equipment-switch/my-equipment-switch.service';
import { SystemUpdatesModule } from '../system-updates/system-updates.module';
import { DownloadAttachmentZipComponent } from './equipment-detail/download-attachment-zip/download-attachment-zip.component';
import { DownloadDocumentsZipComponent } from './equipment-detail/download-documents-zip/download-documents-zip.component';
import { EquipmentTrainingsComponent } from './equipment-detail/equipment-trainings/equipment-trainings.component';
import { EquipmentDocumentsTabComponent } from './equipment-detail/equipment-documents/equipment-documents-tab.component';
import { EquipmentDocumentsCardsComponent } from './equipment-detail/equipment-documents/equipment-documents-cards/equipment-documents-cards.component';
import { EquipmentDocumentsOverlayComponent } from './equipment-detail/equipment-documents/equipment-documents-overlay/equipment-documents-overlay.component';
import { EquipmentDocumentsWrapperComponent } from './equipment-detail/equipment-documents/equipment-documents-wrapper/equipment-documents-wrapper.component';
import { EquipmentDocumentsCollapsibleListComponent } from './equipment-detail/equipment-documents/equipment-documents-wrapper/equipment-documents-collapsible-list/equipment-documents-collapsible-list.component';
import { EquipmentEnvironmentComponent } from './equipment-detail/equipment-environment/equipment-environment.component';
import { EquipmentEnvironmentChartComponent } from './equipment-detail/equipment-environment/equipment-environment-chart.component';
import { UserSettingsModule } from '../user-settings/user-settings.module';
import { MyFiltersSwitchComponent } from './my-filters-switch/my-filters-switch.component';
import { EquipmentRestService } from 'app/core/rest-services/equipment-rest.service';
import { HttpCancelUtilService } from 'app/core/utils/http-cancel-util.service';
import { CoreModule } from 'app/core/core.module';
import { OpenQrCodePdfComponent } from './equipment-detail/open-qr-code-pdf/open-qr-code-pdf.component';
import { EquipmentTeamplayComponent } from './equipment-detail/equipment-teamplay/equipment-teamplay.component';
import { EquipmentSRSRestService } from './equipment-srs-cache/equipment-srs-cache.service';

@NgModule({
  imports: [
    SharedModule,
    EquipmentRoutingModule,
    SystemUpdatesModule,
    InfiniteScrollModule,
    UserSettingsModule
  ],
  declarations: [
    EquipmentComponent,
    EquipmentActivitiesComponent,
    EquipmentTicketHistoryComponent,
    EquipmentSystemInformationComponent,
    EquipmentComponentListComponent,
    EquipmentContractsComponent,
    EquipmentOptionsUpgradesComponent,
    EquipmentDocumentsTabComponent,
    EquipmentDocumentsSimpleListComponent,
    EquipmentDocumentsCollapsibleListComponent,
    EquipmentDocumentsWrapperComponent,
    EquipmentDocumentsCardsComponent,
    EquipmentDocumentsOverlayComponent,
    EquipmentContactWidgetComponent,
    EquipmentSystemUpdatesComponent,
    EquipmentSecurityComponent,
    MyEquipmentSwitchComponent,
    DownloadAttachmentZipComponent,
    DownloadDocumentsZipComponent,
    OpenQrCodePdfComponent,
    EquipmentTrainingsComponent,
    EquipmentEnvironmentComponent,
    EquipmentEnvironmentChartComponent,
    MyFiltersSwitchComponent,
    EquipmentTeamplayComponent
  ],
  providers: [
    MyEquipmentSwitchService, EquipmentSRSRestService
  ],
  exports: [MyEquipmentSwitchComponent, MyFiltersSwitchComponent],
  entryComponents: [EquipmentDocumentsWrapperComponent]
})
export class EquipmentModule {
}
