import { ActivitiesViewModel } from '../../view-models/activities-view-model';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class CalendarClickEventService {

  // Observable calendar event sources
  private calendarEventSource = new Subject<ActivitiesViewModel>();

  // Observable calendar event streams
  calendarEventSource$ = this.calendarEventSource.asObservable();

  constructor() { }

  // Service emit calendar event
  emitCalendarClick(event: ActivitiesViewModel) {
    this.calendarEventSource.next(event);
  }

}
