import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PartnerOrder } from '../models/partner-order';

@Injectable()
export class CloseWithdrawPartnerOrderEventService {

  // Observable remove partner orders
  private removePartnerOrderSource = new Subject<PartnerOrder>();

  // Observable remove partner orders streams
  removePartnerOrderSource$ = this.removePartnerOrderSource.asObservable();

  constructor() { }

  // Service removePartnerOrder commands
  emitPartnerOrderRemoved(partnerOrder: PartnerOrder) {
    this.removePartnerOrderSource.next(partnerOrder);
  }
}
