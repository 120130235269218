import {
  psrRestName,
  psrRestTypes,
  psrRwfRestName,
  psrTemplateRestName
} from './professional-rest-constants.service';
import { PsrTypeEnum } from '../core-constants.service';
import { CacheableHttpClient } from '../services/cache/cacheable-http-client';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfessionalServiceRequest } from '../models/professionalServiceRequest/professional-service-request';
import { HttpParams } from '@angular/common/http';
import { PsrType } from 'app/core/models/professionalServiceRequest/psr-status-type';
import { HttpCacheService } from '../services/cache/http-cache.service';
import { PsrTemplateByModality } from '../models/professionalServiceRequest/psr-template-by-modality';
import { environment } from '../../../environments/environment';
import { RemoteWorkForceRequest } from '../models/professionalServiceRequest/remote-work-force-request';

@Injectable()
export class ProfessionalServiceRequestRestService {

  constructor(private http: CacheableHttpClient,
    private cacheService: HttpCacheService) {
  }

  /**
   * Makes http post request to create a psr
   */
  createPsr(body) {
    if (!environment.newCacheableHttpClient) {
      this.cacheService.clearIndividualRequest(psrRestName);
    }
    this.http.clearCache(psrRestName);
    return this.http.post(psrRestName, body);
  }

  /**
   * Makes http request and returns an Professional Service request list
   *
   * @returns {Observable<ProfessionalServiceRequest[]>}
   */
  getPsrList(): Observable<ProfessionalServiceRequest[]> {
    return this.http.get<ProfessionalServiceRequest[]>(psrRestName);
  }

  /**
   * Makes http request and returns an Professional Service request list
   *
   * @returns {Observable<ProfessionalServiceRequest[]>}
   */
  getPsrTemplates(): Observable<PsrTemplateByModality[]> {
    return this.http.get<PsrTemplateByModality[]>(psrTemplateRestName);
  }

  /**
   * Makes http request and returns an observable of  PsrType array
   *
   * @returns {PsrType[]}
   */
  getPsrTypeCode(type: PsrTypeEnum): Observable<PsrType[]> {
    const httpParams = new HttpParams().set('type', type);
    return this.http.get(psrRestTypes, {params: httpParams});
  }

  postPsrActions(url: string, body: any) {

    return this.http.post(url, body);
  }

  getRemoteWorkForceRequests(): Observable<RemoteWorkForceRequest[]> {
    return this.http.get<RemoteWorkForceRequest[]>(psrRwfRestName);
  }
  
  createRwf(body) {
    if (!environment.newCacheableHttpClient) {
      this.cacheService.clearIndividualRequest(psrRwfRestName);
    }
    this.http.clearCache(psrRwfRestName);
    return this.http.post(psrRwfRestName, body);
  }
}
