import { Injectable } from '@angular/core';
import { WindowService } from '../../window.service';
import { SecurityApplianceOrderRestService } from '../../rest-services/security-appliance-order-rest.service';
import { SecurityApplianceOrder } from '../../models/securityApplianceOrders/security-appliance-order';
import { SecurityApplianceOrderAddress } from '../../models/securityApplianceOrders/security-appliance-order-address';
import * as _ from 'lodash';
import { ToasterService } from '../../component-communication-services/toaster/toaster.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class SecurityApplianceOrderUtilService {
  constructor(
    private windowService: WindowService,
    private saoRestService: SecurityApplianceOrderRestService,
    private toasterService: ToasterService,
    private router: Router
  ) {}

  createSao(formValue: any): Observable<any> {
    const shippingAddress: SecurityApplianceOrderAddress = {
      receiverName: formValue.shipping.receiverName,
      customerName: formValue.shipping.customerName,
      department: formValue.shipping.department,
      building: formValue.shipping.building,
      street: formValue.shipping.street,
      postalCode: formValue.shipping.postalCode,
      city: formValue.shipping.city
    };

    let billingAddress: SecurityApplianceOrderAddress = null;
    if (_.isEqual(formValue.billing.isActive, 'true')) {
      billingAddress = {
        receiverName: formValue.billing.receiverName,
        customerName: formValue.billing.customerName,
        department: formValue.billing.department,
        building: formValue.billing.building,
        street: formValue.billing.street,
        postalCode: formValue.billing.postalCode,
        city: formValue.billing.city
      };
    }

    const saoRequest: SecurityApplianceOrder = {
      equipmentKey: formValue.product.equipmentKey,
      customerNumber: formValue.product.customerNumber,
      ipAddress: formValue.product.ipAddress,
      ipAddressFirewall: formValue.product.ipAddressFirewall,
      srsConnectionReference: formValue.product.srsConnection,
      targetPorts: formValue.product.targetPorts,
      managedSecurityService: formValue.order.additionalOption,
      itAdminName: formValue.contactIT.itAdministratorName,
      itAdminSiemensContact: formValue.contactIT.siemensContact,
      itAdminEmail: formValue.contactIT.itAdministratorEmail,
      itAdminPhone: formValue.contactIT.itAdministratorPhone,
      billingAddress: billingAddress,
      shippingAddress: shippingAddress,
      contact: formValue.contact
    };

    return this.saoRestService.createSAO(saoRequest);
  }

  back(equipmentKey: string) {
    this.router.navigate(['/equipment', equipmentKey, 'info']);
  }

  getAllowedOrders() {
    return this.saoRestService.getAllowedOrders();
  }
}
