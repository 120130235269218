import { AbstractControl } from '@angular/forms';
import { Attachment } from '../../../core/models/attachment';
import * as _ from 'lodash';

export class UploadUpdatePackageChecksumValidator {

  static checkSumFileValidatorAndLoader() {

    return (control: AbstractControl) => {
      const checksumFile = control.value;
      const parent = control.parent;

      if (checksumFile && Object.keys(checksumFile).length > 0
        && parent && Object.keys(parent).length > 0) {

        const packageFile = parent.controls['packageFile'].value;
        const packageInfoFile = parent.controls['packageInfoFile'].value;
        const howToFile = parent.controls['howToFile'].value;

        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          let success = true;
          const file = Object(checksumFile)[0]['file'];
          fileReader.readAsText(file);
          fileReader.onload = () => {
            const text = <string>fileReader.result;

            const lines = this.getNonEmptyLines(text.split(/\n/));
            if (lines && lines.length === 3) {
              const checksumFileNames: Array<string> = [];
              for (let i = 0; i < lines.length; i++) {
                const values = lines[i].split(' *');

                if (values && values.length === 2) {
                  const checksum = values[0].trim();
                  const checksumFileName = values[1].trim();

                  checksumFileNames.push(checksumFileName);

                  if (!checksum || checksum.length !== 64) {
                    resolve({invalidChecksumLength: true});
                    success = false;
                    break;
                  }
                } else {
                  resolve({invalidContent: true});
                }
              }

              if ((Object.keys(checksumFileNames).length !== 3) || !this.isValidFileNameInChecksum(checksumFileNames, packageFile)
                || !this.isValidFileNameInChecksum(checksumFileNames, packageInfoFile)
                || !this.isValidFileNameInChecksum(checksumFileNames, howToFile)) {
                resolve({invalidFile: true});
                success = false;
              }
            } else {
              resolve({invalidCount: true});
            }

            if (success) {
              resolve(null);
            }
          };
        });
      } else {
        return null;
      }
    };
  }

  private static isValidFileNameInChecksum(checksumFileNames: Array<string>, file: Attachment): boolean {
    if (Object.keys(file).length > 0) {
      const fileName = Object(file)[0]['filename'];
      for (const checksumFileName of checksumFileNames) {
        if (_.isEqual(checksumFileName, fileName.trim())) {
          return true;
        }
      }
    } else {
      return true;
    }
    return false;
  }

  private static getNonEmptyLines(text: string[]): string[] {
    const nonEmptyLines: string[] = [];
    for (const line of text) {
      if (line && line.trim().length > 0) {
        nonEmptyLines.push(line);
      }
    }
    return nonEmptyLines;
  }
}
