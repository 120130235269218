import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class StartDateBeforeEndDateValidator {

  static startDateBeforeEndDateValidator(startDateName, startTimeName, endDateName, endTimeName): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const startDate = control.value[startDateName];
      const startTime = control.value[startTimeName];
      const endDate = control.value[endDateName];
      const endTime = control.value[endTimeName];
      return startDate && endDate &&
      this.isStartDateBeforeEndDate(startDate, startTime, endDate, endTime) ? null :
        { status: 'end date time is lesser or equal start time' };
    };
  }

  private static isStartDateBeforeEndDate(startDate, startTime, endDate, endTime): boolean {
    const announcementStartTime = this.getDateTime(startDate, startTime).getTime();
    const announcementEndTime = this.getDateTime(endDate, endTime).getTime();
    return !isNaN(announcementStartTime) && !isNaN(announcementEndTime) &&
      announcementStartTime < announcementEndTime;
  }

  private static getDateTime(date, time) {
    const newDate = new Date(Date.parse(date));
    if (Date.parse(time)) {
      const newTime = new Date(Date.parse(time));
      newDate.setHours(newTime.getHours());
      newDate.setMinutes(newTime.getMinutes());
      newDate.setSeconds(0);
    } else {
      newDate.setHours(+time.toString().substr(0, 2));
      newDate.setMinutes(+time.toString().substr(3, 2));
      newDate.setSeconds(0);
    }
    return new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000);
  }
}
