export class AdvisoryUploadFileValidator {
  static pdfFileValidator(control) {
    let result = null;
    const pdfRegex = new RegExp(/^[\w,\s-.]+\.pdf/);
    const files = control.value;
      if (files && Object.keys(files).length > 0) {
        files.forEach(file => {
          if (!pdfRegex.test(file['filename'])) {
            result = {invalidName: true};
          }
        });
    }
    return result;
  }

  static txtFileValidator(control) {
    let result = null;
    const txtRegex = new RegExp(/^[\w,\s-.]+\.txt/);
    const files = control.value;
      if (files && Object.keys(files).length > 0) {
        files.forEach(file => {
          if (!txtRegex.test(file['filename'])) {
            result = {invalidName: true};
          }
        });
    }
    return result;
  }
}
