import { Injectable } from '@angular/core';
import { NotifStatus } from '../../core-constants.service';

@Injectable()
export class TicketsCacheService {

  private openClosedStatus: NotifStatus = NotifStatus.OPEN;
  private prefilledFields: object;

  constructor() {
  }

  /**
   * @description sets the selected status of the open / closed dropdown
   *
   * @param {NotifStatus} openClosedStatus
   */
  setSelectedOpenClosedStatus(openClosedStatus: NotifStatus) {
    this.openClosedStatus = openClosedStatus;
  }

  /**
   * @description retrieve the currently selected status for open / closed dropdown
   *
   * @returns {NotifStatus} openClosedStatus
   */
  getSelectedOpenClosedStatus(): NotifStatus {
    return this.openClosedStatus;
  }

  setPrefilledFields(fields: {
    details: { problemSeverityID: string, longText: string, dangerForPatients: string, description: string }
  }) {
    this.prefilledFields = fields;
  }

  getPrefilledFields(): object {
    return this.prefilledFields;
  }

  resetPrefilledFields(): void {
    this.prefilledFields = undefined;
  }
}
