import { Injectable } from '@angular/core';
import { GeneralRoleCanActivateTabsService } from '../general-role-can-activate-tabs.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CheckPermissionOrRoleService } from '../check-permission-or-role.service';
import { Observable, ReplaySubject } from 'rxjs';
import { TeamplayRestService } from '../../rest-services/teamplay-rest.service';

@Injectable({
  providedIn: 'root'
})
export class TeamplayAuthGuardService extends GeneralRoleCanActivateTabsService {

  isTeamplayAvailable = new ReplaySubject<boolean>(1);

  constructor(router: Router, checkPermissionOrRoleService: CheckPermissionOrRoleService, private teamplayRestService: TeamplayRestService) {
    super(router, checkPermissionOrRoleService);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.teamplayRestService.isTeamplayAvailable().subscribe((isAvailable) => this.isTeamplayAvailable.next(isAvailable));
    return super.canActivate(route, state);
  }

  protected enableTab: ReplaySubject<boolean> = this.checkPermissionOrRoleService.showTeamplayWidget && this.isTeamplayAvailable;
}
