import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { restEndPoint } from 'app/core/core-constants.service';
import { EquipmentInformation } from '../../../core/models/equipment/equipment-information';
import { Equipment } from '../../../core/models/equipment/equipment';
import { ItemDetailViewModel } from '../../../core/view-models/item-detail-view-model';
import { WindowService } from '../../../core/window.service';

@Component({
  selector: 'hl-open-qr-code-pdf',
  templateUrl: './open-qr-code-pdf.component.html'
})
export class OpenQrCodePdfComponent implements OnInit, OnChanges {
  openQRquickLinkRest = restEndPoint + 'equipmentPdf/:productName-qr.pdf';
  openQRquickLink;

  @Input() equipmentDetails: ItemDetailViewModel[];
  @Input() equipmentItem: EquipmentInformation | Equipment;
  equipmentDetailsToJSON;

  constructor(private windowService: WindowService) {
  }

  ngOnInit() {
    this.prepareQRcodePdfRequest();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).every(key => changes[key].isFirstChange())) {
      return;
    }

    this.prepareQRcodePdfRequest();
  }

  prepareQRcodePdfRequest() {
    const encodedHashtag = '%23';
    if (this.equipmentItem) {
      const productName: string = this.equipmentItem.productName.replace('#', encodedHashtag).replace(/\//g, '_');
      this.openQRquickLink = this.openQRquickLinkRest.replace(':productName', productName);
    }
    if (this.equipmentDetails && this.equipmentDetails.length > 0) {
      this.equipmentDetailsToJSON = this.windowService.nativeWindow.btoa(unescape(encodeURIComponent(JSON.stringify(
        this.equipmentDetails.map(document => {
          return {
            label: document.label,
            value: document.value
          };
        })
      ))));
    } else {
      this.equipmentDetailsToJSON = null;
    }
  }

}
