import { Injectable } from '@angular/core';
import { NotifStatus } from '../../core-constants.service';

@Injectable()
export class ActivitiesCacheService {
  private openClosedStatus: NotifStatus = NotifStatus.OPEN;
  private isCalendarVisible = false;
  private _isCalendarMonthView = true;
  private isCalendarYearView = false;
  private _isCalendarWeekView = false;
  private country: string;
  private customerNumber: string;

  constructor() {}

  setSelectedOpenClosedStatus(openClosedStatus: NotifStatus) {
    this.openClosedStatus = openClosedStatus;
  }

  getSelectedOpenClosedStatus(): NotifStatus {
    return this.openClosedStatus;
  }

  setCalendarVisible(isCalendarVisible: boolean) {
    this.isCalendarVisible = isCalendarVisible;
  }

  getCalendarVisible(): boolean {
    return this.isCalendarVisible;
  }

  getCalendarMonthView(): boolean {
    return this._isCalendarMonthView;
  }

  setCalendarMonthView(isCalendarMonthView: boolean) {
    this._isCalendarMonthView = isCalendarMonthView;
  }

  setCalendarYearView(isCalendarYearView: boolean) {
    return (this.isCalendarYearView = isCalendarYearView);
  }

  getCalendarYearView(): boolean {
    return this.isCalendarYearView;
  }

  setCurrentCountry(country: string) {
    this.country = country;
  }

  getCurrentCountry(): string {
    return this.country;
  }

  getCalendarWeekView(): boolean {
    return this._isCalendarWeekView;
  }

  setCalendarWeekView(calendarWeekView: boolean) {
    this._isCalendarWeekView = calendarWeekView;
  }

  getCustomerNumber(): string {
    return this.customerNumber;
  }

  setCustomerNumber(value: string) {
    this.customerNumber = value;
  }
}
