import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgModule } from '@angular/core';
import { SystemUpdatesComponent } from './system-updates.component';
import { SharedModule } from '../shared/shared.module';
import { SystemUpdatesDownloadComponent } from './system-updates-download/system-updates-download.component';
import { SystemUpdatesRoutingModule } from './system-updates-routing.module';

@NgModule({
  imports: [SharedModule, SystemUpdatesRoutingModule, InfiniteScrollModule],
  declarations: [SystemUpdatesComponent, SystemUpdatesDownloadComponent],
  exports: [SystemUpdatesDownloadComponent]
})
export class SystemUpdatesModule {}
