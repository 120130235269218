import { Directive, Inject, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isEqual } from 'lodash';
import { CountryConfigRestService } from '../../../core/rest-services/country-config-rest.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TicketsRestService } from '../../../core/rest-services/tickets-rest.service';

@Directive({
  selector: '[hlChatBot]'
})
export class ChatBotDirective implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private countryConfigRestService: CountryConfigRestService,
    private renderer2: Renderer2,
    private ngZone: NgZone,
    private ticketsRestService: TicketsRestService,
    @Inject(DOCUMENT) private document) {
  }

  ngOnInit() {
    this.countryConfigRestService.getConfig().pipe(takeUntil(this.unsubscribe$)).subscribe((configResponse) => {
      this.addChatBot(configResponse);
    });
    window['createTicketComponentRef'] = { component: this, zone: this.ngZone, createTicket: (ticket) => this.createTicket(ticket), };
  }

  addChatBot(configResponse) {
    if (isEqual(configResponse.INBENTA_CHATBOT_FEATURE_TOGGLE , 'true')) {
      this.insertChatbotScript();
    }
  }

  insertChatbotScript() {
    const script = this.renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src = 'inbenta-conf.min.js';
    this.renderer2.appendChild(this.document.body, script);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    window['createTicketComponentRef'] = null;
  }

  createTicket(ticket) {
    console.log('creating ticket by chatbot');
    console.log(ticket);
    this.ticketsRestService.postCreateTicket(ticket).subscribe(
      response => {
        console.log('success response (creating ticket by chatbot)');
        console.log(response);
      },
      error => {
        console.log('error response (creating ticket by chatbot)');
        console.log(error);
      });
  }
}
