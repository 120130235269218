import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ChatRestService } from '../../rest-services/chat-rest.service';
import { ChatConfiguration } from '../../models/chat/chat-configuration';
import { ChatEquipmentModel } from '../../models/chat/chat-equipment-model';
import { EquipmentUtilService } from '../equipment/equipment-util.service';
import { ContractsUtilService } from '../contracts/contracts-util.service';
import * as _ from 'lodash';
import { EquipmentViewModel } from '../../view-models/equipment-view-model';
import { ContractsViewModel } from '../../view-models/contracts-view-model';
import { SelectOption } from '../../models/select-option';
import { UserUtilService } from '../user/user-util.service';
import { DatePipeWrapperPipe } from '../../../shared/pipes/date-pipe-wrapper/date-pipe-wrapper.pipe';
import { EnvironmentConfigRestService } from '../../rest-services/environment-config-rest.service';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable()
export class ChatUtilService {

  constructor(private chatRestService: ChatRestService,
    private equipmentUtilService: EquipmentUtilService,
    private contractsUtilService: ContractsUtilService,
    private userUtilService: UserUtilService,
    private datePipeWrapperPipe: DatePipeWrapperPipe,
    private environmentConfigService: EnvironmentConfigRestService,
    private configService: CountryConfigRestService,
              private translateService: TranslateService) {
  }

  /**
   * Util method, returns only the ChatConfiguration array retrieved from BE
   * @returns {Observable<ChatConfiguration[]>}
   */
  getChatConfiguration(): Observable<ChatConfiguration[]> {
    return this.chatRestService.getChatConfiguration();
  }

  /**
   * The method that returns a ChatEquipmentModel[].
   * This interface is a map that connects each Chat Use Case with their respective equipment list.
   * To filter out the valid equipments that can belong to this list, we need to check, depending on the UseCase, the modalities that
   * are allowed, and/or if the equipment needs to have a valid contract.
   * After those checks are made, the chatModel local object is returned as an observable.
   * @returns {Observable<ChatEquipmentModel[]>}
   */
  getChatEquipmentList(): Observable<ChatEquipmentModel[]> {
    const chatConfig$ = this.getChatConfiguration();
    const equipments$ = this.equipmentUtilService.getEquipmentViewModelList();
    const contracts$ = this.contractsUtilService.getContractsViewModelList(false);
    return forkJoin(
      chatConfig$,
      equipments$,
      contracts$
    ).pipe(map(responses => {
      const chatConfigList = responses[0];
      const equipmentsList = responses[1];
      const contractsList = responses[2];
      const chatModel = [];
      _.forEach(chatConfigList, config => {
        const filteredEquipmentsList = this.getChatEquipmentListByUseCase(config, equipmentsList, contractsList);
        chatModel.push({
          chatConfig: config,
          equipmentList: filteredEquipmentsList
        });
      });
      return chatModel;
    }));
  }

  /**
   * This method returns only the UseCases to the FE that are available to a user.
   * The LIFENET usecase is always available, but all the other use cases, depend on having an equipment in their EquipmentList.
   * For example, if a useCase requires its equipments to have valid contracts, and the user has none,
   * then the equipment list will be empty, and so the particular UseCase will be omitted from the returned list.
   * @param {ChatEquipmentModel[]} chatEquipmentModel
   * @returns {SelectOption[]}
   */
  getAvailableUseCaseList(chatEquipmentModel: ChatEquipmentModel[]): SelectOption[] {
    const availableUseCaseList = [];
    if (chatEquipmentModel) {
      _.forEach(chatEquipmentModel, chatModel => {
        if (chatModel.chatConfig.useCase &&
          (_.isEqual(chatModel.chatConfig.useCase, 'LIFENET') || !_.isEmpty(chatModel.equipmentList))) {
          availableUseCaseList[chatModel.chatConfig.displayOrder] = {
            // tslint:disable-next-line:ban
            title: this.translateService.instant('LABEL_CHAT_' + chatModel.chatConfig.useCase),
            value: chatModel.chatConfig.useCase
          };
        }
      });
    }
    return _.compact(availableUseCaseList);
  }

  /**
   * Returns the Equipment List depending on each UseCase we pass as a parameter.
   * @param {ChatEquipmentModel[]} chatEquipmentModel
   * @param {string} useCase
   * @returns {EquipmentViewModel[]}
   */
  getEquipmentByUseCase(chatEquipmentModel: ChatEquipmentModel[], useCase: string): EquipmentViewModel[] {
    let equipmentList = [];
    if (chatEquipmentModel && useCase) {
      equipmentList = _.filter(chatEquipmentModel, item => {
        return _.isEqual(item.chatConfig.useCase, useCase);
      });
      if (!_.isEmpty(equipmentList)) {
        equipmentList = equipmentList[0].equipmentList;
      }
    }
    return equipmentList;
  }

  /**
   * Depending on the values we get from the BE/DB, we create a custom iAdvize Object.
   * name, email, phone are always filled, but all the other fields, are dependend on the values that the flags have/ are returned
   * in the ChatConfiguration object.
   * @param {ChatConfiguration} chatConfig
   * @param {EquipmentViewModel} selectedEquipment
   * @returns {Observable<any>}
   */
  getIAdvizeCustomObject(chatConfig: ChatConfiguration, selectedEquipment: EquipmentViewModel): Observable<any> {
    const user$ = this.userUtilService.getUser();
    const contracts$ = this.contractsUtilService.getContractsViewModelList(false);
    return forkJoin(
      user$,
      contracts$
    ).pipe(map(responses => {
      const user = responses[0];
      const contractsList = responses[1];
      const iAdvizeObject = {};
      if (chatConfig) {
        iAdvizeObject['cust_name'] = user.lastName;
        iAdvizeObject['cust_firstname'] = user.firstName;
        iAdvizeObject['cust_email'] = user.email;
        iAdvizeObject['cust_phonenumber'] = user.phone;
        if (chatConfig.skillPrefix) {
          iAdvizeObject['agent_skill'] = chatConfig.skill + '_' + selectedEquipment.modality;
        } else {
          iAdvizeObject['agent_skill'] = chatConfig.skill;
        }
        if (chatConfig.fillEquipment) {
          iAdvizeObject['cust_fl'] = selectedEquipment.siemensId;
          let equipmentContract = _.filter(contractsList, {contractNumber: selectedEquipment.contractNumber});
          if (_.isEmpty(equipmentContract)) {
            equipmentContract = _.filter(contractsList, {equipmentKey: selectedEquipment.key});
          }
          if (!_.isEmpty(equipmentContract)) {
            iAdvizeObject['cust_contract_type'] = equipmentContract[0].contractTypeDescription;
            iAdvizeObject['cust_contract_exp'] = this.datePipeWrapperPipe.transform(equipmentContract[0].expirationDate, 'YYYY-MM-DD');
          }
        }
        if (_.isEqual(chatConfig.useCase, 'NTR')) {
          iAdvizeObject['cust_sub_topic'] = chatConfig.subTopic;
        }
        if (chatConfig.fillSoldTo) {
          iAdvizeObject['cust_sold_to'] = selectedEquipment.soldTo;
        }
      }
      return iAdvizeObject;
    }));
  }

  /**
   * Returns the whole ChatConfiguration object of a particular useCase.
   * @param {ChatEquipmentModel[]} chatEquipmentModel
   * @param {string} useCase
   * @returns {ChatConfiguration}
   */
  getChatConfigurationByUseCase(chatEquipmentModel: ChatEquipmentModel[], useCase: string): ChatConfiguration {
    const filteredChatConfig = _.filter(chatEquipmentModel, item => {
      return _.isEqual(item.chatConfig.useCase, useCase);
    });
    if (!_.isEmpty(filteredChatConfig)) {
      return filteredChatConfig[0].chatConfig;
    }
    return null;
  }

  getAvailableIAdvizeCustomObjectKeys(): string[] {
    return [
      'cust_name',
      'cust_firstname',
      'cust_email',
      'cust_phonenumber',
      'agent_skill',
      'cust_fl',
      'cust_contract_type',
      'cust_contract_exp',
      'cust_sub_topic',
      'cust_sold_to'
    ];
  }

  // on the DOM)
  cleanUpDOM() {
    const chatDiv = document.getElementsByClassName('iAdvizeChatScript')[0];
    const DOMLeftover = document.getElementsByClassName('agent_skill');
    const iAdvizeKeys = this.getAvailableIAdvizeCustomObjectKeys();

    if (DOMLeftover.length > 0) {

      const inputFieldsDOM = [];
      _.forEach(document.getElementsByTagName('input'), input => {
        inputFieldsDOM.push(input.className);
      });

      if (!_.isEmpty(inputFieldsDOM)) {

        _.forEach(inputFieldsDOM, className => {
          if (_.includes(iAdvizeKeys, className)) {
            const input = document.getElementsByClassName(className)[0];
            chatDiv.removeChild(input);
          }
        });
      }

      const iAdvizeContent = document.getElementsByName('iAdvizeContent')[0];
      if (iAdvizeContent) {
        chatDiv.removeChild(iAdvizeContent);
      }

      const iAdvizeTrigger = document.getElementsByName('iAdvizeTrigger')[0];
      if (iAdvizeTrigger) {
        chatDiv.removeChild(iAdvizeTrigger);
      }
    }
  }

  triggerChatScripts() {
    this.configService.getConfig().subscribe(countryConfig => {
      const isChatAvailable = _.isEqual(countryConfig.CHAT_FEATURE_AVAILABLE, 'true');
      if (isChatAvailable) {
        this.appendScripts();
      }
    });
  }

  /**
   * making sure that the scripts will be triggered/put into the DOM after the
   * iAdvizeChatScript is put into DOM. Using timeout with 5ms
   * @param {string} sid
   */
  appendScripts() {
    const chatDiv = document.getElementsByClassName('iAdvizeChatScript')[0];
    if (chatDiv) {
      chatDiv.appendChild(this.buildIadvizeContentScript());
    } else {
      this.appendScriptsInTimeout();
    }
  }

  appendScriptsInTimeout() {
    setTimeout(() => {
      this.appendScripts();
    }, 5);
  }

  buildIadvizeContentScript() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = this.generateContent();
    s.setAttribute('name', 'iAdvizeContent');
    return s;
  }

  buildIadvizeTriggerScript() {
    this.environmentConfigService.getEnvironmentConfig().subscribe(envConfig => {
      const sid = envConfig.CHAT_ACCOUNT_KEY;
      const chatDiv = document.getElementsByClassName('iAdvizeChatScript')[0];
      const run = document.createElement('script');
      run.type = 'text/javascript';
      run.innerHTML = this.generateExecScript(sid);
      run.setAttribute('name', 'iAdvizeTrigger');
      chatDiv.appendChild(run);
    });
  }

  generateContent() {
    return `
        var device = 'desktop';
        var regex = new RegExp("(android|iphone|ipad|blackberry|symbian|symbianos|" +
        "symbos|netfront|model-orange|javaplatform|iemobile|windows phone|samsung|htc|" +
        "opera mobile|opera mobi|opera mini|presto|huawei|blazer|bolt|doris|fennec|" +
        "gobrowser|iris|maemo browser|mib|cldc|minimo|semc-browser|skyfire|teashark|" +
        "teleca|uzard|uzardweb|meego|nokia|bb10|playbook)", "gi");
        if (navigator.userAgent.match(regex)) {
          if (
            ((screen.width >= 480) && (screen.height >= 800)) ||
            ((screen.width >= 800) && (screen.height >= 480)) ||
            navigator.userAgent.match(/ipad/gi)) {
              device = 'tablet';
          } else {
            device = 'mobile';
          }
        } else {
          device = 'desktop';
        }
        var idzCustomData = {
          "device": device
        };`;
  }

  // this method has initially been placed inside the component - but it is also used
  // by the directive (anyway it is cleaning up the stuff, that the directive adds)
  // so I moved the methid to the service (which is little strange, because it operates

  generateExecScript(sid: string) {
    return `
        (function () {
          var idz = document.createElement('script');
          idz.type = 'text/javascript';
          idz.async = true;
          idz.src = document.location.protocol + '/' + '/' + 'halc.iadvize.com/iadvize.js?sid=${sid}';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(idz, s);
        })();
        `;
  }

  /**
   * Creates and returns an EquipmentViewModel array, that contains only the equipments that have the same modalities as the
   * codes contained in the allowedModalities array we pass as a parameter
   * @param {string[]} allowedModalitiesList
   * @param {EquipmentViewModel[]} equipmentsList
   * @returns {EquipmentViewModel[]}
   */
  private filterEquipmentListByModality(allowedModalitiesList: string[], equipmentsList: EquipmentViewModel[]): EquipmentViewModel[] {
    let filteredEquipmentList = equipmentsList;
    if (allowedModalitiesList && !_.isEmpty(allowedModalitiesList)) {
      filteredEquipmentList = _.filter(equipmentsList, item => {
        return (_.indexOf(allowedModalitiesList, item.modality) >= 0);
      });
    }
    return filteredEquipmentList;
  }

  /**
   * Searches the ContractsViewModel array to find contracts that are mapped to their respective equipment.
   * If there's an equipment and a contract with the same contract number, then this equipment has a valid contract.
   * Since the rest call ignores the historic contracts, all the contracts we get are the active.
   * @param {boolean} requireContract
   * @param {EquipmentViewModel[]} filteredEquipmentsList
   * @param {ContractsViewModel[]} contractsList
   * @returns {EquipmentViewModel[]}
   */
  private filterEquipmentListByValidContracts(requireContract: boolean,
    filteredEquipmentsList: EquipmentViewModel[],
    contractsList: ContractsViewModel[]): EquipmentViewModel[] {
    let filterEquipments = filteredEquipmentsList;
    if (requireContract) {
      filterEquipments = _.filter(filterEquipments, item => {
        if (item && item.contractNumber) {
          const filteredContract = _.filter(contractsList, {contractNumber: item.contractNumber});
          return !_.isEmpty(filteredContract);
        }
        return false;
      });
    }
    return filterEquipments;
  }

  /**
   * The base method that filters out all equipments, preparing the list, for sending it to the FE.
   * Both checks for modalities and valid contracts are handled here.
   * @param {ChatConfiguration} config
   * @param {EquipmentViewModel[]} equipmentsList
   * @param {ContractsViewModel[]} contractsList
   * @returns {EquipmentViewModel[]}
   */
  private getChatEquipmentListByUseCase(config: ChatConfiguration, equipmentsList: EquipmentViewModel[],
    contractsList: ContractsViewModel[]): EquipmentViewModel[] {
    let filteredEquipmentsList = [];
    if (_.isEqual(config.useCase, 'LIFENET')) {
      return [];
    }
    filteredEquipmentsList = this.filterEquipmentListByModality(config.modalities, equipmentsList);
    filteredEquipmentsList = this.filterEquipmentListByValidContracts(config.requireContract, filteredEquipmentsList, contractsList);
    return filteredEquipmentsList;
  }
}
